import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { withCoreContext } from 'app/core/core.context';

import { SidebarComponent } from './sidebar.component';

const mapStateToProps = ({
  users: { isApproved, role },
  venues: { venueMassageContracts, isFetchingMassageContracts },
}) => ({
  isApproved,
  venueMassageContracts,
  role,
  isFetchingMassageContracts,
});

export const SidebarContainer = compose(
  withRouter,
  withCoreContext,
  injectIntl,
  connect(mapStateToProps),
)(SidebarComponent);
