// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { ButtonComponent, TileComponent, TooltipComponent } from 'app/shared';
import { checkPermissions } from 'app/utils';
// $FlowFixMe
import { ReactComponent as CommuterIcon } from 'assets/icons/commuter.svg';
// $FlowFixMe
import { ReactComponent as CultureIcon } from 'assets/icons/culture.svg';
// $FlowFixMe
import { ReactComponent as ExerciseIcon } from 'assets/icons/exercise.svg';
// $FlowFixMe
import { ReactComponent as LunchIcon } from 'assets/icons/lunch.svg';
// $FlowFixMe
import { ReactComponent as MassageIcon } from 'assets/icons/massage.svg';

import type { Props } from './venues-list-tile.component.types';
import styles from './venues-list-tile.module.scss';

export class VenuesListTileComponent extends PureComponent<Props> {
  render() {
    const serviceTypeIcon = benefit => {
      const styles = { width: 25, height: 25 };

      return {
        commuter: <CommuterIcon style={styles} />,
        culture: <CultureIcon style={styles} />,
        exercise: <ExerciseIcon style={styles} />,
        lunch: <LunchIcon style={styles} />,
        massage: <MassageIcon style={styles} />,
      }[benefit];
    };

    const getMouseoverTranslation = benefit => {
      if (benefit) {
        return benefit.toUpperCase();
      }
      return '';
    };

    return (
      <TileComponent className={classNames({ [styles.pending]: this.props.tile.pending })}>
        <h3>{this.props.tile.name}</h3>

        <div className="row">
          <div className="col">
            <FormattedMessage id="VENUES.CITY" tagName="small" />
            {this.props.tile.city}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <FormattedMessage id="VENUES.SERVICE_TYPE" tagName="small" />
            <TooltipComponent
              trigger={['hover']}
              overlay={this.props.intl.formatMessage({
                id: `VENUES.SERVICE_TYPE_POPOVER.${getMouseoverTranslation(this.props.tile.benefit)}`,
              })}
              placement={'top'}
            >
              <div>{serviceTypeIcon(this.props.tile.benefit)}</div>
            </TooltipComponent>
          </div>
        </div>

        {this.props.tile.pending || (
          <div className="buttons">
            {checkPermissions(this.props.permissions, ['venueUsers', 'read']) && (
              <ButtonComponent theme="outline-secondary" to={`/venues/${this.props.tile.id}/users`} size="small">
                <FormattedMessage id="VENUES.USERS" /> <i className="fi fi-man-user" />
              </ButtonComponent>
            )}

            {checkPermissions(this.props.permissions, ['venues', 'update']) && (
              <ButtonComponent theme="outline-secondary" to={`/venues/${this.props.tile.id}/edit`} size="small">
                <i className="fi fi-pencil-edit-button" />
              </ButtonComponent>
            )}

            {checkPermissions(this.props.permissions, ['venues', 'update']) && (
              <TooltipComponent
                trigger={['hover']}
                overlay={this.props.intl.formatMessage({ id: 'VENUES.DELETE_VENUE_BUTTON_TOOLTIP' })}
                placement={'top'}
              >
                <div>
                  <ButtonComponent
                    theme="outline-secondary"
                    onClick={() => {
                      this.props.onOpenTermiationModal({
                        venueId: this.props.tile.id,
                        contractTerminationDate: this.props.tile.contractTerminationDate,
                      });
                    }}
                    size="small"
                  >
                    <i className="fi fi-cross-remove-sign" />
                  </ButtonComponent>
                </div>
              </TooltipComponent>
            )}
          </div>
        )}

        {this.props.tile.pending && (
          <div className="buttons">
            <FormattedMessage id="VENUES.PENDING" tagName="strong" />
          </div>
        )}
      </TileComponent>
    );
  }
}
