// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, FormSection } from 'redux-form';

import { ConnectedRadiosComponent } from 'app/shared';

import { OrdersCreateInvoicingOptionsContainer } from './options/orders-create-invoicing-options.container';

import type { Props } from './orders-create-invoicing.component.types';

export class OrdersCreateInvoicingComponent extends Component<Props> {
  render() {
    const invoicingOptions = (this.props.invoicingOptions || []).filter(option => option.available).map(option => ({
      id: option.id,
      name: <FormattedMessage id={`INVOICING.${option.id}.NAME`} />,
    }));

    return (
      <FormSection name="invoicing">
        <Field
          name="invoicingMethod"
          component={ConnectedRadiosComponent}
          label={this.props.intl.formatMessage({ id: 'ORDERS.INVOICING_METHOD' })}
          options={invoicingOptions}
          required
        />

        <OrdersCreateInvoicingOptionsContainer form={this.props.form} />
      </FormSection>
    );
  }
}
