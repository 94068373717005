// @flow

import React, { Component } from 'react';

import { OrdersListTablePlaceholder } from './table/orders-list-table.placeholder';
import { OrdersListTilePlaceholder } from './tile/orders-list-tile.placeholder';

import { ListComponent } from 'app/shared/list/list.component';

import type { Props } from './orders-list.placeholder.types';

export class OrdersListPlaceholder extends Component<Props> {
  static defaultProps = {
    orders: [...new Array(5)],
  };

  render() {
    return (
      <ListComponent list={this.props.orders} table={OrdersListTablePlaceholder} tile={OrdersListTilePlaceholder} />
    );
  }
}
