import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { push, replace } from 'react-router-redux';

import { withLocale } from 'app/app.context';

import { fetchCustomer } from 'app/redux/customers/customers.actions';
import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';
import { confirmOrder, createInvoice, createConcreteOrder, fetchOrder } from 'app/redux/orders/orders.actions';
import { fetchSubscription } from 'app/redux/subscriptions/subscriptions.actions';

import { SubscriptionOrderComponent } from './subscription-order.component';

const mapStateToProps = (
  {
    customers: { entities: customers },
    dictionaries,
    orders: { entities: orders },
    subscriptions: { entities: subscriptions },
    users: { business, current },
  },
  {
    match: {
      params: { order, subscription },
    },
  },
) => ({
  business,
  initialValues:
    !customers[business] || !orders[order] || orders[order].dueAmount === 0
      ? undefined
      : {
          invoicing: {
            invoicingMethod: '',
            invoiceName: customers[business].name,
            invoiceEmail: current.personal.email,
            invoiceReference: customers[business].invoicing.defaultInvoicingReference,
            eInvoiceAddress: customers[business].invoicing.eInvoiceAddress,
            eInvoiceIdentifier: customers[business].invoicing.eInvoiceDeliveryIdentifier,
          },
          invoicingAddress:
            orders[order].type === 'voucher'
              ? {
                  company: customers[business].name,
                  ...customers[business].addresses.delivery,
                }
              : undefined,
        },
  loading: !orders[order] || !subscriptions[subscription] || !dictionaries['invoicing-options'] || !customers[business],
  order: orders[order],
  subscription: subscriptions[subscription],
});

const scrollUp = () => window.scrollTo(0, 0);

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { order, subscription, type },
    },
    locale,
  },
) => ({
  fetchCustomer: customer => dispatch(fetchCustomer(customer)),
  fetchDictionary: () => dispatch(fetchDictionary('invoicing-options')),
  fetchOrder: () => dispatch(fetchOrder(order)),
  fetchSubscription: () => dispatch(fetchSubscription(subscription)),
  onConfirm: order =>
    dispatch(confirmOrder(order, type === 'emoney' ? 'saldo' : 'order_banknotes', locale)).then(scrollUp),
  onCreate: () =>
    dispatch(createConcreteOrder(subscription))
      .then(({ payload: { data: { id } } }) =>
        dispatch(
          push({
            pathname: `/subscriptions/${type}/${subscription}/order/${id}`,
            state: {
              keepScrollPosition: true,
            },
          }),
        ),
      )
      .catch(({ errors: { _error } }) =>
        dispatch(
          replace({
            pathname: `/subscriptions/${type}/${subscription}/beneficiaries`,
            state: {
              error: {
                type: 'CREATE_FAILED',
                payload: _error,
              },
            },
          }),
        ),
      ),
  onSubmit: (order, body) => dispatch(createInvoice(order, body)).then(scrollUp),
  onSubmitFail: scrollUp,
});

export const SubscriptionOrderContainer = compose(
  withLocale,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'order-subscription',
  }),
)(SubscriptionOrderComponent);
