// @flow

import React, { PureComponent } from 'react';
import MediaQuery from 'react-responsive';

import { BreadcrumbsComponent } from 'app/core';

import type { Props } from './header.component.types';
import styles from './header.module.scss';

export class HeaderComponent extends PureComponent<Props> {
  render() {
    return (
      <header className={styles.header}>
        <div className={styles.top}>
          <BreadcrumbsComponent breadcrumbs={this.props.breadcrumbs} />
          <MediaQuery minWidth={992}>{this.props.button}</MediaQuery>
        </div>
        <div className={styles.title}>{this.props.children}</div>
        <MediaQuery maxWidth={991}>{this.props.button}</MediaQuery>
      </header>
    );
  }
}
