import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { withPermissions } from 'app/common';

import { logoutUser } from 'app/redux/users/users.actions';

import { SidebarSettingsComponent } from './sidebar-settings.component';

const mapStateToProps = ({ users: { isApproved } }) => ({
  isApproved,
});

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch(logoutUser()),
});

export const SidebarSettingsContainer = compose(
  withRouter,
  injectIntl,
  withPermissions,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SidebarSettingsComponent);
