// @flow

import React, { Component } from 'react';

import { FormattedMessage } from 'react-intl';

import { CardComponent } from 'app/shared';

import { ListComponent } from 'app/shared/list/list.component';

import { SubscriptionsListTablePlaceholder } from './table/subscriptions-list-table.placeholder';

import { SubscriptionsListTilePlaceholder } from './tile/subscriptions-list-tile.placeholder';

import type { Props } from './subscriptions-list.placeholder.types';

export class SubscriptionsListPlaceholder extends Component<Props> {
  static defaultProps = {
    subscriptions: [...new Array(6)],
  };

  render() {
    return (
      <CardComponent>
        <div className="card-header">
          <h4 className="card-title">
            <FormattedMessage id={`SUBSCRIPTION.${this.props.type}.LIST`} />
          </h4>
        </div>
        <div className="card-body">
          <ListComponent
            list={this.props.subscriptions}
            table={SubscriptionsListTablePlaceholder}
            tile={SubscriptionsListTilePlaceholder}
          />
        </div>
      </CardComponent>
    );
  }
}
