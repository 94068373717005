// @flow

import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import {
  AlertComponent,
  ButtonComponent,
  CardComponent,
  ConnectedFileComponent,
  ConnectedInputComponent,
  ConnectedRadioComponent,
  ConnectedSelectComponent,
  ModalComponent,
} from 'app/shared';

import { CREATE_TYPE } from './initiate-subscription.container';
import { SubscriptionsInitiateErrorsComponent } from './errors/subscriptions-initiate-errors.component';
import { HelpLinkContainer } from 'app/shared/help-link/help-link.container';

import { importStatus } from 'app/redux/subscriptions/subscriptions.reducer';

import type { Props } from './initiate-subscription.component.types';
import styles from './initiate-subscription.module.scss';
import { API_URL } from 'app/constants';

export class InitiateSubscriptionComponent extends Component<Props> {
  componentDidMount() {
    !this.props.subscriptions && this.props.fetchAllSubscriptions(this.props.business);
  }

  componentDidUpdate(prevProps: Props) {
    if (
      (prevProps.business !== this.props.business && !this.props.subscriptions) ||
      prevProps.match.params.type !== this.props.match.params.type
    ) {
      this.props.fetchAllSubscriptions(this.props.business);
    }
  }

  render() {
    const subscriptions = (this.props.subscriptions || []).map(({ id, title }) => ({ id, name: title }));
    const importProcessing = this.props.submitting || this.props.importStatus === importStatus.PENDING;
    const importFailed = this.props.importStatus === importStatus.FAILED;

    return (
      <CardComponent header={false}>
        <div className="card-header">
          {!!this.props.location.state &&
            !!this.props.location.state.success && (
              <AlertComponent type="success">
                {this.props.location.state.success.type === 'CANCEL_ORDER' && (
                  <FormattedMessage id="ORDER_HISTORY.CANCEL_SUCCESS" />
                )}
              </AlertComponent>
            )}

          <h4 className="card-title">
            <FormattedMessage id="SUBSCRIPTION.CREATE_NEW_LIST_TITLE" />
          </h4>
        </div>
        <div className="card-body">
          <form onSubmit={this.props.handleSubmit}>
            <div className="row">
              <div className="col-lg-12">
                <Field
                  name="title"
                  component={ConnectedInputComponent}
                  required={this.props.role === CREATE_TYPE.CLONE}
                  label={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.LABEL.TITLE' })}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex flex-wrap">
                <div className="align-self-end mr-3">
                  <Field
                    name="role"
                    component={ConnectedRadioComponent}
                    option={{
                      id: CREATE_TYPE.NEW,
                      name: this.props.intl.formatMessage({ id: 'SUBSCRIPTION.OPTIONS.SUBSCRIPTION_SYSTEM' }),
                    }}
                    theme="simple"
                    required
                  />
                </div>
                <div className="align-self-end">
                  <Field
                    name="role"
                    component={ConnectedRadioComponent}
                    disabled={!subscriptions.length}
                    option={{
                      id: CREATE_TYPE.CLONE,
                      name: this.props.intl.formatMessage({ id: 'SUBSCRIPTION.OPTIONS.EXISTING_LIST' }),
                    }}
                    theme="simple"
                  />
                </div>
                {this.props.type === 'voucher' && (
                  <div className="align-self-end ml-3">
                    <Field
                      name="role"
                      component={ConnectedRadioComponent}
                      option={{
                        id: CREATE_TYPE.IMPORT,
                        name: this.props.intl.formatMessage({ id: 'SUBSCRIPTION.OPTIONS.IMPORT' }),
                      }}
                      theme="simple"
                    />
                  </div>
                )}
              </div>
            </div>
            {this.props.role === CREATE_TYPE.CLONE && (
              <div className="row">
                <div className="col-12">
                  <Field
                    name="subscriptionId"
                    component={ConnectedSelectComponent}
                    options={subscriptions}
                    label={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.LABEL.CLONE_LIST' })}
                    required
                  />
                </div>
              </div>
            )}
            {this.props.role === CREATE_TYPE.IMPORT && (
              <>
                <AlertComponent type="light" className={styles.import}>
                  <FormattedMessage
                    id="SUBSCRIPTION.INSTRUCTION_BOX.IMPORT_DESC"
                    tagName="p"
                    values={{
                      link: (
                        <a href={`${API_URL || ''}/downloads/public/subscriptions/csv`}>
                          <FormattedMessage id="CORE.HERE" />
                        </a>
                      ),
                    }}
                  />
                  <FormattedMessage id="SUBSCRIPTION.INSTRUCTION_BOX.IMPORT_REMINDER" tagName="p" />
                </AlertComponent>
                <div className="row">
                  <div className="col-12">
                    <Field
                      name="file"
                      type="file"
                      component={ConnectedFileComponent}
                      label={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.LABEL.FILE' })}
                      required
                    />
                  </div>
                </div>
                <Field
                  name="beneficiaries"
                  component={SubscriptionsInitiateErrorsComponent}
                  locale={this.props.intl.locale}
                />
                {importProcessing && (
                  <ModalComponent title={<FormattedMessage id="SUBSCRIPTION.IMPORT_PROCESSING" />} isClosingBlocked />
                )}
                {importFailed && (
                  <ModalComponent
                    title={<FormattedMessage id="SUBSCRIPTION.IMPORT_FAILED" />}
                    onClose={this.props.onClose}
                  />
                )}
              </>
            )}
            <div className={styles.submit}>
              <MediaQuery minWidth={1200}>
                <HelpLinkContainer />
              </MediaQuery>
              <ButtonComponent theme="primary" loading={this.props.submitting} size="large">
                <FormattedMessage id="SUBSCRIPTION.CREATE_LIST" /> <i className="fi fi-right-arrow-forward" />
              </ButtonComponent>
            </div>
            <MediaQuery maxWidth={1199}>
              <HelpLinkContainer />
            </MediaQuery>
          </form>
        </div>
      </CardComponent>
    );
  }
}
