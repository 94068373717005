// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { currencyFormatter } from 'app/utils';

import { AgreementsAcceptanceContainer } from 'app/agreements/acceptance/agreements-acceptance.container';
import { ButtonComponent, ButtonsWrapperComponent, SummaryFieldComponent, TableComponent } from 'app/shared';

import type { Props, State } from './cards-beneficiaries-order-confirm.component.types';

import styles from './cards-beneficiaries-order-confirm.module.scss';

export class CardsBeneficiariesOrderConfirmComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      terms: {
        onChange: () => this.setState(prev => ({ terms: { ...prev.terms, value: !prev.terms.value } })),
        value: false,
      },
    };
  }

  render() {
    const product = this.props.products.find(product => product.id === 'VISA_CARD');

    return (
      <>
        <div className="card-header">
          <h4 className="card-title">
            <FormattedMessage id="CARDS.ORDER.DETAILS" />
          </h4>
        </div>
        <div className="card-body">
          <TableComponent className="table-striped">
            <tbody>
              <tr>
                <td className={styles.type}>
                  <FormattedMessage id="PRODUCT.VISA_CARD" />
                </td>

                <td>{product && currencyFormatter.format(product.amount)}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <FormattedMessage id="ORDERS.ORDER_TOTAL" />
                </td>
                <td>{product && currencyFormatter.format(product.amount)}</td>
              </tr>
            </tfoot>
          </TableComponent>
        </div>

        <div className="card-header card-header--between">
          <h4 className="card-title">
            <FormattedMessage id="ORDERS.INVOICING_DETAILS" />
          </h4>
        </div>

        <div className="card-body">
          <div className="row">
            <div className="col-lg-6">
              <SummaryFieldComponent>
                <FormattedMessage id="ORDERS.INVOICING_METHOD" tagName="strong" />
                <FormattedMessage id={`INVOICING.${this.props.values.invoicing.invoicingMethod}.NAME`} />
              </SummaryFieldComponent>
            </div>
            <div className="col-lg-6">
              <SummaryFieldComponent>
                <FormattedMessage id="ORDERS.ORDER_REFERENCE" tagName="strong" />
                {this.props.values.invoicing.invoiceReference}
              </SummaryFieldComponent>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <SummaryFieldComponent>
                <FormattedMessage id="ORDERS.OFFICIAL_NAME" tagName="strong" />
                {this.props.values.invoicing.invoiceName}
              </SummaryFieldComponent>
            </div>
            <div className="col-lg-6">
              <SummaryFieldComponent>
                <FormattedMessage id="ORDERS.EMAIL" tagName="strong" />
                {this.props.values.invoicing.invoiceEmail}
              </SummaryFieldComponent>
            </div>
          </div>
          {this.props.values.invoicing.invoicingMethod === 'EINVOICE' && (
            <div className="row">
              <div className="col-lg-6">
                <SummaryFieldComponent>
                  <FormattedMessage id="ORDERS.DELIVERY_IDENTIFIER" tagName="strong" />
                  {this.props.values.invoicing.eInvoiceIdentifier}
                </SummaryFieldComponent>
              </div>
              <div className="col-lg-6">
                <SummaryFieldComponent>
                  <FormattedMessage id="ORDERS.DELIVERY_ADDRESS" tagName="strong" />
                  {this.props.values.invoicing.eInvoiceAddress}
                </SummaryFieldComponent>
              </div>
            </div>
          )}
        </div>

        <>
          <div className="card-header card-header--between">
            <h4 className="card-title">
              <FormattedMessage id="ORDERS.DELIVERY_DETAILS" />
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6">
                <SummaryFieldComponent>
                  <FormattedMessage id="ORDERS.STREET_ADDRESS" tagName="strong" />
                  {this.props.values.invoicingAddress.address}
                </SummaryFieldComponent>
              </div>
              <div className="col-lg-6">
                <SummaryFieldComponent>
                  <FormattedMessage id="ORDERS.ZIP_CODE" tagName="strong" />
                  {this.props.values.invoicingAddress.zipCode}
                </SummaryFieldComponent>
              </div>
              <div className="col-lg-6">
                <SummaryFieldComponent>
                  <FormattedMessage id="ORDERS.CITY" tagName="strong" />
                  {this.props.values.invoicingAddress.city}
                </SummaryFieldComponent>
              </div>
            </div>
          </div>
        </>

        <div className="card-footer">
          <AgreementsAcceptanceContainer input={this.state.terms} name="saldo" />

          <ButtonsWrapperComponent>
            <ButtonComponent theme="outline-secondary" size="large" onClick={this.props.onBack}>
              <i className="fi fi-go-back-left-arrow" /> <FormattedMessage id="CORE.BACK" />
            </ButtonComponent>

            <ButtonComponent
              theme="primary"
              size="large"
              className="ml-auto"
              disabled={!this.state.terms.value}
              loading={this.props.submitting}
            >
              <FormattedMessage id="CORE.CONFIRM" />
            </ButtonComponent>
          </ButtonsWrapperComponent>
        </div>
      </>
    );
  }
}
