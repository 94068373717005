import { handleActions } from 'redux-actions';

import * as types from './metabase-download-pdf.action-types';

const defaultState = {
  options: null,
  hasError: false,
  isLoading: false,
};

export const metabaseDataReducer = handleActions(
  {
    [types.FETCH_METABASE_DATA_REQUEST]: state => ({
      ...state,
      isLoading: true,
    }),
    [types.FETCH_METABASE_DATA_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        options: payload,
        hasError: false,
        isLoading: false,
      };
    },
    [types.FETCH_METABASE_DATA_FAILURE]: state => {
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    },
  },
  defaultState,
);
