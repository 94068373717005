import { RSAA } from 'redux-api-middleware';
import { refreshToken } from 'app/redux/users/users.actions';

import { isObject } from 'lodash';

const buffer = [];

export const errorsMiddleware = store => next => action => {
  const apiMiddleware = action[RSAA];

  if (!apiMiddleware) {
    if (!action.error || !(action.payload instanceof Error)) {
      return next(action);
    }

    if (403 === action.payload.status && 'Authentication Required' === action.payload.response.message) {
      const token = localStorage.getItem('refreshToken');

      if (token) {
        store.dispatch(refreshToken(token)).then(refreshAction => {
          if (!refreshAction.error) {
            const promises = buffer.map(action => store.dispatch(action));
            buffer.length = 0;

            return Promise.all(promises);
          }

          return next(action);
        });

        return;
      }
    }

    return next(action);
  }

  if (apiMiddleware.headers && !!apiMiddleware.headers['X-AUTH-TOKEN']) {
    return next({
      [RSAA]: {
        ...apiMiddleware,
        types: [
          apiMiddleware.types[0],
          apiMiddleware.types[1],
          {
            ...(isObject(apiMiddleware.types[2]) ? apiMiddleware.types[2] : {}),
            type: isObject(apiMiddleware.types[2]) ? apiMiddleware.types[2].type : apiMiddleware.types[2],
            meta: (action, state, res) => {
              if (403 === res.status) {
                buffer.push(action);
              }

              if (isObject(apiMiddleware.types[2])) {
                return apiMiddleware.types[2].meta;
              }
            },
          },
        ],
      },
    });
  }

  return next(action);
};
