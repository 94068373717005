import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';

import { RegisterServiceComponent } from './register-service.component';

import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';
import { registerValidate, register } from 'app/redux/register/register.actions';

const getAcceptedPayments = (state = { payments: [] }) =>
  state.payments.reduce((acc, next) => (next.alwaysEnabled ? [...acc, next.id] : acc), []);

const mapStateToProps = state => ({
  initialValues: state.dictionaries['services']
    ? {
        acceptedPayments: getAcceptedPayments(state.dictionaries['services']),
        benefit: null,
        billing: {
          invoicingMethod: false,
        },
        services: [],
      }
    : undefined,
  loading: !state.dictionaries['services'],
  settlements: state.dictionaries['settlements'],
});

const mapDispatchToProps = dispatch => ({
  onValidate: data => {
    let body = { ...data };
    body.venue = {
      ...body.venue,
      billingAddress: data.company.billingAddress,
    };
    return dispatch(registerValidate(body, 'service-provider'));
  },
  onSubmit: data => {
    let body = { ...data };
    body.venue = {
      ...body.venue,
      billingAddress: data.company.billingAddress,
    };
    return dispatch(register(body, 'service-provider'));
  },
  onSubmitFail: () => window.scrollTo(0, 0),
  fetchServiceProviderDictionary: () => dispatch(fetchDictionary('services', true)),
});

export const RegisterServiceContainer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'register-service',
  }),
)(RegisterServiceComponent);
