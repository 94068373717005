import { fork, put, select, takeEvery } from 'redux-saga/effects';
import { push, getLocation, goBack } from 'react-router-redux';

import { FETCH_CURRENT_USER_SUCCESS, APPROVE_TERMS_SUCCESS } from 'app/redux/users/users.action-types';
import { REDIRECTS } from 'app/redux/users/users.saga';

import get from 'lodash/get';

export function* afterFetchCurrentUserSuccess({
  payload: {
    data: { terms = [] },
  },
}) {
  const unapproved = terms.find(term => !term.approved && term.required);
  const location = yield select(getLocation);
  
  if (unapproved) {
    if (get(location, 'state.back')) {
      yield put(goBack());
    } else {
      yield put(push(`/terms/${unapproved.key}`));
    }
  }
}

export const userDetails = ({ users: { current, role } }) => ({ current, role });

export function* afterApproveTermsSuccess() {
  const {
    current: { terms },
    role,
  } = yield select(userDetails);

  const unapproved = terms.find(term => !term.approved && term.required);
  yield put(push(unapproved ? `/terms/${unapproved.key}` : REDIRECTS[role]));
}

export function* agreementsSaga() {
  yield fork(takeEvery, FETCH_CURRENT_USER_SUCCESS, afterFetchCurrentUserSuccess);
  yield fork(takeEvery, APPROVE_TERMS_SUCCESS, afterApproveTermsSuccess);
}
