// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { AccountDetailsContainer } from 'app/accounts/details/account-details.container';

import type { Props } from './beneficiary-account-details.component.types';

export class BeneficiaryAccountDetailsComponent extends Component<Props> {
  componentDidMount() {
    this.props.fetchBeneficiary();
  }

  render() {
    return (
      <AccountDetailsContainer
        match={this.props.match}
        breadcrumbs={
          !!this.props.beneficiary && [
            { icon: 'multiple-users-silhouette', to: `/beneficiaries` },
            { name: <FormattedMessage id="NAV.MANAGEMENT.COMPANY_BENEFICIARY_SEARCH" />, to: `/beneficiaries` },
            {
              name: `${this.props.beneficiary.firstName} ${this.props.beneficiary.lastName}`,
              to: `/beneficiaries/${this.props.beneficiary.id}`,
            },
            { name: <FormattedMessage id="NAV.ACCOUNT.ACCOUNTS_INFORMATION" /> },
          ]
        }
      />
    );
  }
}
