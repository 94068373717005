import { compose } from 'redux';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { get } from 'lodash';

import { withAuthorization, withPermissions } from 'app/common';

import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';
import { fetchUsers } from 'app/redux/users/users.actions';
import { fetchVenue } from 'app/redux/venues/venues.actions';
import { USER_ROLES } from 'app/constants';

import { VenueUsersComponent } from './venue-users.component';

const roles = {
  [USER_ROLES.ROLE_SERVICE_PROVIDER_MANAGER]: [
    { visible: true, editable: true, name: 'ROLE_VENUE_MANAGER' },
    { visible: true, editable: true, name: 'ROLE_VENUE_CASHIER' },
    { visible: true, editable: true, name: 'ROLE_VENUE_ACCOUNTANT' },
  ],
  [USER_ROLES.ROLE_VENUE_MANAGER]: [{ visible: true, editable: true, name: 'ROLE_VENUE_CASHIER' }],
};

const mapStateToProps = (
  { dictionaries, users: { business, entities: users, role }, venues: { entities: venues } },
  {
    match: {
      params: { id },
    },
  },
) => ({
  business,
  loading: !venues[id] || !users[id] || !dictionaries['user-roles/venue'],
  users: get(users, [id, 'list']),
  venue: venues[id],
  userManagementRoles: roles[role] || undefined,
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { id },
    },
  },
) => ({
  changeVenue: venue => dispatch(replace(`/venues/${venue}/users`)),
  fetchDictionary: () => dispatch(fetchDictionary('user-roles/venue')),
  fetchUsers: () => dispatch(fetchUsers(id, 'venue')),
  fetchVenue: () => dispatch(fetchVenue(id)),
});

export const VenueUsersContainer = compose(
  withAuthorization(['venueUsers', 'read']),
  withPermissions,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(VenueUsersComponent);
