import { RSAA } from 'redux-api-middleware';

export const authMiddleware = store => next => action => {
  const apiMiddleware = action[RSAA];

  if (!apiMiddleware) {
    return next(action);
  }

  const { isPublic, ...rest } = apiMiddleware;

  if (isPublic) {
    return next({
      [RSAA]: rest,
    });
  }

  const { accessToken, business, subBusiness } = store.getState().users;

  return next({
    [RSAA]: {
      ...rest,
      headers: {
        ...apiMiddleware.headers,
        ...(accessToken ? { 'X-AUTH-TOKEN': accessToken } : {}),
        ...(business ? { 'X-AUTH-BUSINESS': business } : {}),
        ...(subBusiness ? { 'X-AUTH-SUB-BUSINESS': subBusiness.id } : {}),
      },
    },
  });
};
