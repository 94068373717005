// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { AlertComponent, ButtonComponent, CardComponent, GroupComponent, PlaceholderComponent } from 'app/shared';

import type { Props } from './banknotes-order.placeholder.types';

export class BanknotesOrderPlaceholder extends Component<Props> {
  render() {
    const invoiceColumnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    return (
      <CardComponent header={false}>
        <div className="card-header">
          <h4 className="card-title">
            <FormattedMessage id="BANKNOTES.BREADCRUMB" />
          </h4>
        </div>
        <div className="card-body">
          <AlertComponent type="light" className="text-left">
            <FormattedMessage id="BANKNOTES.DESC" />
          </AlertComponent>

          <>
            <div className="row">
              <div className="col-12 col-md-4">
                <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.PRODUCT' })} required>
                  <PlaceholderComponent height={40} radius={8} color="light" />
                </GroupComponent>
              </div>
              <div className="col-12 col-md-4">
                <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.NUMBER_OF_BOOKS' })} required>
                  <PlaceholderComponent height={40} radius={8} color="light" />
                </GroupComponent>
              </div>
              <div className="col-6 col-md-3">
                <GroupComponent label={this.props.intl.formatMessage({ id: 'CORE.TOTAL' })} required>
                  <PlaceholderComponent height={40} radius={8} color="light" />
                </GroupComponent>
              </div>
            </div>
            <ButtonComponent size="medium" theme="outline-secondary">
              <FormattedMessage id="ORDERS.ADD_ANOTHER_ROW" />
            </ButtonComponent>
          </>
        </div>
        <div className="card-header card-header--between">
          <h4 className="card-title">
            <FormattedMessage id="ORDERS.INVOICING_DETAILS" />
          </h4>
        </div>
        <div className="card-body">
          <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.INVOICING_METHOD' })} required>
            <PlaceholderComponent height={24} radius={8} color="light" />
          </GroupComponent>

          <div className="row">
            <div className={invoiceColumnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.OFFICIAL_NAME' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
            <div className={invoiceColumnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.EMAIL' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
          <div className="row">
            <div className={invoiceColumnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.ORDER_REFERENCE' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
        </div>
      </CardComponent>
    );
  }
}
