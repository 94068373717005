// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { dateFormatter, decimalFormatter } from 'app/utils';

import { ButtonComponent, TileComponent } from 'app/shared';

import { SubscriptionBeneficiariesStatusComponent } from 'app/subscriptions/beneficiaries/status/subscription-beneficiaries-status.component';

import type { Props } from './my-subscriptions-list-tile.component.types';
import styles from 'app/subscriptions/beneficiaries/tile/subscription-beneficiaries-tile.module.scss';

export class MySubscriptionsListTileComponent extends Component<Props> {
  render() {
    return (
      <TileComponent>
        <div className={styles.heading}>
          <SubscriptionBeneficiariesStatusComponent status={this.props.tile.status} />
          <h3>{`${this.props.tile.company}: ${this.props.tile.title}`}</h3>
        </div>

        <div className="row">
          {this.props.tile.benefitOptions.map(benefit => (
            <div className="col-md-6 mb-2" key={benefit.id}>
              <FormattedMessage id={`PRODUCT.${benefit.id}`} tagName="small" />
              {decimalFormatter.format(benefit.amount)}
            </div>
          ))}
          <div className="col-md-6 mb-2">
            <FormattedMessage id="MY_SUBSCRIPTIONS.LIST_HEADERS.DATE" tagName="small" />
            {dateFormatter(this.props.tile.createdAt)}
          </div>
        </div>

        {this.props.tile.editable && (
          <div className="buttons">
            <ButtonComponent
              theme="outline-secondary"
              size="small"
              to={{
                pathname: `${this.props.url}/${this.props.tile.id}`,
                state: {
                  keepScrollPosition: true,
                },
              }}
            >
              <i className="fi fi-pencil-edit-button" />
            </ButtonComponent>
          </div>
        )}
      </TileComponent>
    );
  }
}
