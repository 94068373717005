// @flow

import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import { TitleComponent } from 'app/auth/title/title.component';
import { BackComponent } from 'app/auth/back/back.component';
import { ButtonComponent, ConnectedInputComponent, InfoComponent, ExternalLinkComponent } from 'app/shared';
import classNames from 'classnames';

import type { Props, State } from './request-password.component.types';
import styles from './request-password.module.scss';

const recoveryLinkDict = {
  en: process.env.REACT_APP_RECOVERY_LINK_EN,
  fi: process.env.REACT_APP_RECOVERY_LINK_FI,
  sv: process.env.REACT_APP_RECOVERY_LINK_SV,
};

export class RequestPasswordComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      submitted: false,
    };
  }

  onSubmit = ({ username }: { username: string }) =>
    this.props.onSubmit(username).then(() => this.setState({ submitted: true, username }));

  render() {
    const { handleSubmit, submitting, intl } = this.props;
    const recoveryLink = recoveryLinkDict[intl.locale || 'en'];

    return (
      <section>
        <MediaQuery maxWidth={991}>
          <BackComponent to="/login">
            <FormattedMessage id="AUTH.BACK_TO_LOGIN_SHORT" />
          </BackComponent>
        </MediaQuery>
        <TitleComponent withBackOption noMarginBottom>
          <FormattedMessage id="AUTH.REQUEST_PASSWORD" />
        </TitleComponent>
        {!this.state.submitted ? (
          <>
            <MediaQuery minWidth={992}>
              <BackComponent to="/login" withMargin>
                <FormattedMessage id="AUTH.BACK_TO_LOGIN_SHORT" />
              </BackComponent>
            </MediaQuery>
            <form onSubmit={handleSubmit(this.onSubmit)} noValidate>
              <div className={classNames(styles.submit, styles.requestPasswordSubmit)}>
                <Field
                  name="username"
                  type="email"
                  label={this.props.intl.formatMessage({ id: 'AUTH.EMAIL' })}
                  placeholder={this.props.intl.formatMessage({ id: 'AUTH.EMAIL_PLACEHOLDER' })}
                  component={ConnectedInputComponent}
                  large
                />
                <ButtonComponent theme="primary" size="large" loading={submitting}>
                  {this.props.intl.formatMessage({ id: 'AUTH.REQUEST' })}
                </ButtonComponent>
              </div>
              {recoveryLink && (
                <ExternalLinkComponent href={recoveryLink} target="_self">
                  <FormattedMessage id="AUTH.RECOVERY_LINK" />
                  <i className={classNames('fi fi-right-arrow-forward', styles.recoveryLinkIcon)} />
                </ExternalLinkComponent>
              )}
            </form>
          </>
        ) : (
          <div>
            <InfoComponent type="success" titleId="AUTH.TITLE_SENT_REQUEST_PASSWORD">
              <FormattedMessage
                id="AUTH.SENT_REQUEST_PASSWORD"
                values={{ email: <strong>{this.state.username}</strong> }}
              />
            </InfoComponent>

            {recoveryLink && (
              <InfoComponent type="alert" useWithoutTitle>
                <FormattedMessage
                  id="AUTH.TITLE_DID_NOT_RECEIVE_RECOVERY_EMAIL"
                  values={{
                    emailRecovery: (
                      <ExternalLinkComponent href={recoveryLink} target="_self">
                        <strong className={styles.recoveryLink}>
                          <FormattedMessage id={'AUTH.RECOVER_CREDENTIALS'} />
                        </strong>
                      </ExternalLinkComponent>
                    ),
                  }}
                />
              </InfoComponent>
            )}

            <MediaQuery minWidth={992}>
              <BackComponent to="/login">
                <FormattedMessage id="AUTH.BACK_TO_LOGIN" />
              </BackComponent>
            </MediaQuery>
          </div>
        )}
      </section>
    );
  }
}
