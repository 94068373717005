// @flow

import React, { Component } from 'react';

import type { Props } from './info.component.types';
import styles from './info.module.scss';

export class InfoComponent extends Component<Props> {
  render() {
    return this.props.open ? <div className={styles.info}>{this.props.children}</div> : null;
  }
}
