import { RSAA } from 'redux-api-middleware';

export const trackingMiddleware = () => next => action => {
  const apiMiddleware = action[RSAA];

  if (!apiMiddleware) {
    return next(action);
  }

  return next({
    [RSAA]: {
      ...apiMiddleware,
      headers: {
        ...apiMiddleware.headers,
        'X-Request-Id': crypto.randomUUID(),
      },
    },
  });
};
