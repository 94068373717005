// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { AlertComponent, PlaceholderComponent, TableComponent } from 'app/shared';

import type { Props } from './subscription-order.placeholder.types';

export class SubscriptionOrderPlaceholder extends Component<Props> {
  static defaultProps = {
    products: [...new Array(5)],
  };

  render() {
    return (
      <div className="card-body">
        <AlertComponent type="light">
          <FormattedMessage id="SUBSCRIPTION.ORDER.CHARGING_INFO" />
        </AlertComponent>
        <TableComponent className="table-striped">
          <tbody>
            {this.props.products.map((_, product) => (
              <tr key={product}>
                <td>
                  <PlaceholderComponent width={product % 2 === 0 ? 150 : 200} />
                </td>
                <td>
                  <PlaceholderComponent width={100} color="purple" />
                </td>
              </tr>
            ))}
          </tbody>
        </TableComponent>
      </div>
    );
  }
}
