// @flow

import React, { Component } from 'react';

import { PaymentsMobileFormContainer } from './form/payments-mobile-form.container';
import { PaymentsMobileSummaryContainer } from './summary/payments-mobile-summary.container';

import type { Props, State } from './payments-mobile.types';

export class PaymentsMobileComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      step: 'FORM',
    };
  }

  onSubmit = ({ code }: { code: string }) =>
    this.props.onSubmit({ code: code.replace(/\s/g, '') }).then(({ payload: { data: { accountOwner } } }) =>
      this.setState({ step: 'SUMMARY' }, () => this.props.change('name', accountOwner)),
    );

  onConfirm = ({ code, amount, message }: { code: string, amount: string, message: string }) =>
    this.props
      .onConfirm({ amount, message, code: code.replace(/\s/g, '') })
      .then(({ payload: { data } }) => this.props.onConfirmSuccess(data));

  render() {
    return (
      <>
        {this.state.step === 'FORM' && (
          <>
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
              <PaymentsMobileFormContainer submitting={this.props.submitting} />
            </form>
          </>
        )}

        {this.state.step === 'SUMMARY' && (
          <form onSubmit={this.props.handleSubmit(this.onConfirm)}>
            <PaymentsMobileSummaryContainer
              submitting={this.props.submitting}
              onBack={() => this.setState({ step: 'FORM' }, () => this.props.reset())}
              error={this.props.submitFailed && this.props.error}
            />
          </form>
        )}
      </>
    );
  }
}
