// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { PlaceholderComponent, SummaryFieldComponent } from 'app/shared';

export class AccountTransactionsInformationPlaceholder extends Component<any> {
  render() {
    return (
      <div className="row">
        <div className="col-sm-6">
          <SummaryFieldComponent equalFormHeights>
            <FormattedMessage id="TRANSACTIONS.TYPE" tagName="strong" />
            <PlaceholderComponent color="light" width={80} />
          </SummaryFieldComponent>
        </div>
        <div className="col-lg-6">
          <SummaryFieldComponent equalFormHeights>
            <FormattedMessage id="TRANSACTIONS.ACCOUNT_NUMBER" tagName="strong" />
            <PlaceholderComponent color="light" width={100} />
          </SummaryFieldComponent>
        </div>
        <div className="col-lg-6">
          <SummaryFieldComponent equalFormHeights>
            <FormattedMessage id="TRANSACTIONS.BALANCE" tagName="strong" />
            <PlaceholderComponent color="light" width={80} />
          </SummaryFieldComponent>
        </div>
      </div>
    );
  }
}
