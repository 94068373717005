// @flow

import React, { Component } from 'react';

import uniqueId from 'lodash/uniqueId';

import type { Props } from './connected-toggle.component.types';
import styles from './connected-toggle.module.scss';

export class ConnectedToggleComponent extends Component<Props> {
  render() {
    const id = uniqueId('toggle_');

    return (
      <div className={styles.toggle}>
        <label className={styles.label} htmlFor={id}>
          {this.props.label}
          {!!this.props.helpText && <small>{this.props.helpText}</small>}
        </label>
        <input
          type="checkbox"
          {...this.props.input}
          checked={this.props.input.value}
          className={styles.input}
          id={id}
        />
        <label className={styles.slider} htmlFor={id} />
      </div>
    );
  }
}
