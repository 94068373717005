// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent, ModalComponent } from 'app/shared';

import type { Props, State } from './confirm.component.types';

export class ConfirmComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  onConfirm = () => {
    this.setState(
      { loading: true },
      () =>
        this.props.confirmation
          .onConfirm()
          .then(() => {
            this.onClose();
            this.setState({ loading: false });
          })
          .catch(() => this.setState({ loading: false })), // TODO: Handle error here
    );
  };

  onCancel = () => {
    if (this.props.confirmation.onCancel) {
      this.props.confirmation.onCancel();
    }

    this.onClose();
  };

  onClose = () => {
    if (this.props.confirmation.onClose) {
      this.props.confirmation.onClose();
    }

    this.props.onClose();
  };

  render() {
    if (!this.props.confirmation) {
      return null;
    }

    const submitButton = (
      <ButtonComponent theme="primary" size="medium" onClick={this.onConfirm} loading={this.state.loading}>
        <FormattedMessage id="CORE.CONFIRM" />
      </ButtonComponent>
    );

    return (
      <ModalComponent onClose={this.onCancel} submitButton={submitButton} small>
        <h4 className="modal-title">
          <FormattedMessage id={this.props.confirmation.title} values={this.props.confirmation.titleValues} />
        </h4>
      </ModalComponent>
    );
  }
}
