// @flow

import React, { Component } from 'react';

import { CardComponent, PlaceholderComponent } from 'app/shared';

import type { Props } from './payments-token-code.placeholder.types';

export class PaymentsTokenCodePlaceholder extends Component<Props> {
  render() {
    return (
      <CardComponent>
        <div className="card-header">
          <h4 className="card-title">
            <PlaceholderComponent width={400} />
          </h4>
          <div className={this.props.styles.description}>
            <PlaceholderComponent width={150} />
          </div>
        </div>
        <div className="card-body">
          <div className={this.props.styles.qrContainer}>
            <div className={this.props.styles.qr}>
              <PlaceholderComponent width={200} height={200} radius={0} />
            </div>
            <div className={this.props.styles.details}>
              <div className="mt-3 mb-2">
                <PlaceholderComponent width={200} height={30} />
              </div>
              <div className="mb-3">
                <PlaceholderComponent width={120} height={20} />
              </div>
              <div className="mb-2">
                <PlaceholderComponent width={150} height={40} color="purple" />
              </div>
            </div>
          </div>
        </div>
      </CardComponent>
    );
  }
}
