import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { fetchBeneficiary } from 'app/redux/beneficiaries/beneficiaries.actions';

import { BeneficiaryAccountDetailsComponent } from './beneficiary-account-details.component';

const mapStateToProps = (
  { beneficiaries: { entities: beneficiaries } },
  {
    match: {
      params: { beneficiary },
    },
  },
) => ({
  beneficiary: get(beneficiaries, [beneficiary, 'personal']),
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { beneficiary },
    },
  },
) => ({
  fetchBeneficiary: () => dispatch(fetchBeneficiary(beneficiary)),
});

export const BeneficiaryAccountDetailsContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(BeneficiaryAccountDetailsComponent);
