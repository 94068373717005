// @flow

import React, { Component } from 'react';
import { Field } from 'redux-form';
import classNames from 'classnames';

import {
  ButtonComponent,
  CardComponent,
  ConnectedInputComponent,
  ConnectedToggleComponent,
  ConnectedPhoneNumberInputComponent,
} from 'app/shared';

import type { BodyType, Props, State } from './personal.component.types';
import styles from './personal.module.scss';

export class PersonalComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      submitted: false,
    };
  }

  onSubmit = (body: BodyType) =>
    this.props
      .onSubmit(body)
      .then(() => this.setState({ submitted: true }))
      .then(() => setTimeout(() => this.setState({ submitted: false }), 5000));

  render() {
    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    return (
      <CardComponent>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <div className="card-header">
            <h4 className="card-title">{this.props.intl.formatMessage({ id: 'PROFILE.PERSONAL_INFORMATION' })}</h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className={columnClassNames}>
                <Field
                  name="firstName"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'PROFILE.FIRST_NAME' })}
                  addon={this.props.intl.formatMessage({ id: 'PROFILE.CONTACT_CUSTOMER_SERVICE' })}
                  disabled
                />
              </div>
              <div className={columnClassNames}>
                <Field
                  name="lastName"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'PROFILE.LAST_NAME' })}
                  addon={this.props.intl.formatMessage({ id: 'PROFILE.CONTACT_CUSTOMER_SERVICE' })}
                  disabled
                />
              </div>
              <div className={columnClassNames}>
                <Field
                  name="ssn"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'PROFILE.SSN' })}
                  addon={this.props.intl.formatMessage({ id: 'PROFILE.CONTACT_CUSTOMER_SERVICE' })}
                  disabled
                />
              </div>
              <div className={columnClassNames}>
                <Field
                  name="email"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'PROFILE.EMAIL' })}
                  required
                />
              </div>
              <div className={columnClassNames}>
                <Field
                  name="mobilePhone"
                  component={ConnectedPhoneNumberInputComponent}
                  label={this.props.intl.formatMessage({ id: 'PROFILE.MOBILE_PHONE' })}
                />
              </div>
              <div className={columnClassNames}>
                <Field
                  name="workPhone"
                  component={ConnectedPhoneNumberInputComponent}
                  label={this.props.intl.formatMessage({ id: 'PROFILE.WORK_PHONE' })}
                />
              </div>
            </div>
            <div className={styles.marketing}>
              <Field
                name="marketing"
                component={ConnectedToggleComponent}
                normalize={v => !!v}
                label={this.props.intl.formatMessage({ id: 'PROFILE.MARKETING' })}
                helpText={this.props.intl.formatMessage({ id: 'PROFILE.MARKETING_INFO' })}
              />
            </div>
          </div>

          <div className="card-footer">
            <div className={styles.submit}>
              {this.state.submitted && (
                <>
                  <span className={classNames('fi fi-check', styles.check)} />
                  <p className={styles.message}>{this.props.intl.formatMessage({ id: 'PROFILE.UPDATE_SUCCESS' })}</p>
                </>
              )}

              <ButtonComponent theme="primary" loading={this.props.submitting} size="large">
                {this.props.intl.formatMessage({ id: 'CORE.SAVE' })}
              </ButtonComponent>
            </div>
          </div>
        </form>
      </CardComponent>
    );
  }
}
