// @flow

import React, { Component } from 'react';

// $FlowFixMe
import { ReactComponent as Logo } from 'assets/images/logo.svg';

import styles from './aside.module.scss';

export class AsideComponent extends Component<any> {
  render() {
    return (
      <aside className={styles.container}>
        <div className={styles.logo}>
          <Logo />
        </div>
      </aside>
    );
  }
}
