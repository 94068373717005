// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { HeaderComponent } from 'app/core';
import { ButtonComponent, ModalComponent } from 'app/shared';

import { BeneficiaryDetailsAccountsContainer } from './accounts/beneficiary-details-accounts.container';
import { BeneficiaryDetailsPersonalContainer } from './personal/beneficiary-details-personal.container';
import { BeneficiaryDetailsSubscriptionsContainer } from './subscriptions/beneficiary-details-subscriptions.container';

import type { Props, State } from './beneficiary-details.component.types';

export class BeneficiaryDetailsComponent extends Component<Props, State> {
  state = {
    isConfirmationVisible: false,
  };

  componentDidMount() {
    this.props.fetchBeneficiary();
  }

  render() {
    return (
      <>
        <HeaderComponent
          breadcrumbs={[
            { icon: 'multiple-users-silhouette', to: `/beneficiaries` },
            { name: <FormattedMessage id="NAV.MANAGEMENT.COMPANY_BENEFICIARY_SEARCH" />, to: `/beneficiaries` },
            ...(this.props.loading
              ? []
              : [
                  {
                    name: `${this.props.beneficiary.firstName} ${this.props.beneficiary.lastName}`,
                  },
                ]),
          ]}
          button={
            <ButtonComponent
              size="medium"
              loading={this.props.loading || this.props.deleting}
              theme="white"
              onClick={this.confirmBeneficiaryDeletion}
            >
              <FormattedMessage id="CORE.DELETE" />
            </ButtonComponent>
          }
        >
          <FormattedMessage id="BENEFICIARIES.BENEFICIARY_DETAILS.TITLE" tagName="h2" />
          {this.state.isConfirmationVisible && (
            <ModalComponent
              title={this.props.intl.formatMessage({ id: 'BENEFICIARIES.CONFIRMATION_DELETE.TITLE' })}
              onClose={this.closeConfirmationModal}
              submitButton={
                <ButtonComponent theme="primary" size="medium" onClick={this.props.onDeleteBeneficiary}>
                  <FormattedMessage id="CORE.DELETE" />
                </ButtonComponent>
              }
            >
              <FormattedMessage id="BENEFICIARIES.CONFIRMATION_DELETE.DESCRIPTION" tagName="p" />
            </ModalComponent>
          )}
        </HeaderComponent>

        {this.props.beneficiary === null || (
          <BeneficiaryDetailsPersonalContainer
            beneficiary={this.props.match.params.beneficiary}
            sidebarCollapsed={this.props.sidebarCollapsed}
          />
        )}

        <BeneficiaryDetailsSubscriptionsContainer beneficiary={this.props.match.params.beneficiary} />

        <BeneficiaryDetailsAccountsContainer beneficiary={this.props.match.params.beneficiary} />
      </>
    );
  }

  confirmBeneficiaryDeletion = () => {
    this.setState({ isConfirmationVisible: true });
  };

  closeConfirmationModal = () => {
    this.setState({ isConfirmationVisible: false });
  };
}
