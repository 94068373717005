// @flow

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import type { Props } from './back.component.types';
import styles from './back.module.scss';

export class BackComponent extends Component<Props> {
  render() {
    const backClassNames = classNames(styles.back, {
      [styles.withMargin]: this.props.withMargin,
    });

    return (
      <Link to={this.props.to} className={backClassNames}>
        <i className="fi fi-go-back-left-arrow" /> {this.props.children}
      </Link>
    );
  }
}
