// @flow

import React, { Component } from 'react';
import { SingleDatePicker } from 'react-dates';
import classNames from 'classnames';
import moment from 'moment';
import { isInclusivelyBeforeDay } from 'react-dates';

import uniqueId from 'lodash/uniqueId';

import { ConnectedGroupComponent } from 'app/shared';

import type Moment from 'moment';
import type { Props, State } from './connected-datepicker.component.types';
import './connected-datepicker.scss';
import { CalendarCloseButtonComponent } from '../../calendar-close-button/calendar-close-button.component';

export class ConnectedDatePickerComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      focused: null,
    };
  }

  onFocusChange = ({ focused }: { focused: true | null }) => {
    this.setState({ focused });

    if (this.props.onFocusChange) {
      this.props.onFocusChange(focused);
    }
    // $FlowFixMe
    this.props.input.onFocus();
  };

  onDatesChange = (date: Moment) => this.props.input.onChange(date);

  closeCalendarHandler: Function = () => {
    this.onFocusChange({ focused: null });
  };

  render() {
    const wrapperClassNames = classNames({
      'DateRangeWrapper--focused': !!this.state.focused,
      'is-invalid': this.props.meta.submitFailed && !!this.props.meta.error,
    });

    const id = uniqueId('datepicker_');

    return (
      <ConnectedGroupComponent id={id} {...this.props}>
        <div className={wrapperClassNames}>
          <SingleDatePicker
            date={
              this.props.input.value
                ? moment(this.props.input.value)
                : this.props.initialDate
                  ? this.props.initialDate
                  : null
            }
            focused={this.state.focused}
            onFocusChange={this.onFocusChange}
            onDateChange={this.onDatesChange}
            placeholder={this.props.intl.formatMessage({ id: 'CORE.DATE' })}
            customInputIcon={<i className="fi fi-time" />}
            navPrev={<i className="fi fi-go-back-left-arrow" />}
            navNext={<i className="fi fi-right-arrow-forward" />}
            displayFormat="DD.MM.YYYY"
            numberOfMonths={1}
            isOutsideRange={date =>
              this.props.disableDatesOlderThanToday ? isInclusivelyBeforeDay(date, moment().subtract(1, 'day')) : null
            }
            hideKeyboardShortcutsPanel
            enableOutsideDays
            daySize={48}
            calendarInfoPosition="top"
            renderCalendarInfo={() => <CalendarCloseButtonComponent onClick={this.closeCalendarHandler} />}
            {...this.props.datePicker}
          />
        </div>
      </ConnectedGroupComponent>
    );
  }
}
