// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import pick from 'lodash/pick';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import uniqueId from 'lodash/uniqueId';

import { ConnectedGroupComponent } from 'app/shared';

import type { Props, State } from './connected-phone-number-input.component.types.js.flow';
import styles from '../connected-input/connected-input.module.scss';

export class ConnectedPhoneNumberInputComponent extends Component<Props, State> {
  static defaultProps = {
    mask: {},
  };
  state = {
    phone: this.props.input.value || '',
    dialCode: '+358',
  };

  handleChange = (value: string, data: { dialCode: string }) => {
    this.setState({ phone: `+${value}`, dialCode: `+${data.dialCode}` }, () => {
      if (this.state.phone.length <= this.state.dialCode.length) {
        this.props.input.onChange('');
      } else {
        this.props.input.onChange(this.state.phone);
      }
    });
  };

  render() {
    const id = uniqueId('input_');
    const isInvalid = this.props.meta.error;
    const isSubmitFailed = this.props.meta.submitFailed;

    const inputGroupClassNames = classNames('input-group', {
      'is-invalid': isInvalid,

      [styles.focus]: this.props.meta.active,
      [styles.dark]: this.props.dark,
    });

    return (
      <ConnectedGroupComponent id={id} {...this.props}>
        <div className={inputGroupClassNames} data-name={this.props.input.name}>
          <PhoneInput
            inputStyle={{
              width: '100%',
              height: '41px',
              fontSize: '1rem',
              paddingTop: '9px',
              paddingBottom: '9px',
              transition: 'none',
              marginBottom: '15px',
            }}
            {...this.props.mask}
            {...pick(this.props, ['disabled', 'onClick', 'pattern', 'placeholder', 'readOnly', 'type', 'maxLength'])}
            inputRef={this.props.inputRef}
            country={'fi'}
            enableLongNumbers={20}
            enableSearch={true}
            preferredCountries={['fi']}
            inputProps={{
              id,
              autoComplete: 'new-password',
            }}
            onChange={this.handleChange}
            value={this.props.input.value || this.state.phone}
          />
        </div>

        {isInvalid &&
          !isSubmitFailed && (
            <div className={styles.invalidInputMessage}>
              <FormattedMessage id={isInvalid} />
            </div>
          )}
      </ConnectedGroupComponent>
    );
  }
}
