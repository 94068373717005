// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import { AlertComponent } from 'app/shared';

import { SubscriptionSubscribeFormBenefitsRadioComponent } from './radio/subscription-subscribe-form-benefits-radio.component';

import type { Props } from './subscription-subscribe-form-benefits.component.types';

export class SubscriptionSubscribeFormBenefitsComponent extends Component<Props> {
  render() {
    return (
      <>
        <div className="card-header card-header--between">
          <h4 className="card-title">
            <FormattedMessage id="SUBSCRIPTION.SUBSCRIBE.BENEFIT_OPTIONS" />
          </h4>
        </div>
        <div className="card-body">
          <AlertComponent type="light" className="text-left">
            <FormattedMessage
              id="SUBSCRIPTION.SUBSCRIBE.BENEFIT_OPTIONS_INSTRUCTION"
              values={{ company: this.props.company }}
              tagName="p"
            />
          </AlertComponent>

          <Field
            name="benefitOption"
            component={SubscriptionSubscribeFormBenefitsRadioComponent}
            intl={this.props.intl}
            multipliers={this.props.multipliers}
            options={this.props.options}
            recurring={!!this.props.distributionDay}
            type={this.props.type}
          />
        </div>
      </>
    );
  }
}
