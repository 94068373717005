// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import get from 'lodash/get';

import { currencyFormatter } from 'app/utils';

import type { Props, State } from './subscription-beneficiaries-editable.component.types';
import styles from './subscription-beneficiaries-editable.module.scss';

export class SubscriptionBeneficiariesEditableComponent extends Component<Props, State> {
  input: ?HTMLInputElement;

  constructor(props: Props) {
    super(props);

    this.state = {
      editing: false,
    };
  }

  onClick = () =>
    this.props.disabled ||
    this.setState({ editing: true }, () => {
      if (this.input) {
        this.input.focus();
      }

      setTimeout(() => {
        if (this.input) {
          this.input.selectionStart = this.input.selectionEnd = 10000;
        }
      }, 0);
    });

  onSubmit = (event: SyntheticInputEvent<HTMLInputElement | HTMLFormElement>) => {
    event.preventDefault();

    if (this.input) {
      this.props
        .onSubmit(this.input.value.replace(/,/g, '.'))
        .then(this.closeEditing)
        .catch(response => this.setState({ error: get(response, this.props.errorPath), invalid: true }));
    }
  };

  closeEditing = () => this.setState({ editing: false, error: undefined, invalid: false });

  render() {
    if (this.state.editing) {
      const inputClassNames = classNames('form-control', styles.input, {
        'is-invalid': this.state.invalid,
      });

      return (
        <form className={styles.group} onSubmit={this.onSubmit}>
          <input className={inputClassNames} defaultValue={this.props.value} ref={input => (this.input = input)} />
          <button onClick={this.onSubmit}>
            <i className="fi fi-correct-symbol text-success" />
          </button>

          <button onClick={this.closeEditing}>
            <i className="fi fi-cross-remove-sign text-danger" />
          </button>

          {!!this.state.error && (
            <div className={classNames('invalid-feedback', styles.feedback)}>
              <FormattedMessage id={this.state.error} />
            </div>
          )}
        </form>
      );
    }

    return (
      <span className={classNames(styles.editable, { [styles.ordered]: this.props.disabled })} onClick={this.onClick}>
        {isNaN(this.props.value) ? (
          '-'
        ) : this.props.type === 'VOUCHER' ? (
          <>
            <FormattedMessage id="CORE.BOOKS" values={{ books: this.props.value }} />
            {` (${currencyFormatter.format(this.props.value * 10 * this.props.multiplier)})`}
          </>
        ) : (
          currencyFormatter.format(this.props.value)
        )}
        <i className="fi fi-pencil-edit-button" />
      </span>
    );
  }
}
