// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Switch } from 'react-router-dom';

import { TransactionDetailsContainer } from 'app/transactions/details/transaction-details.container';
import { AccountTransactionsPlaceholder } from 'app/accounts/transactions/placeholder/account-transactions.placeholder';
import { AccountTransactionsTableComponent } from 'app/accounts/transactions/table/account-transactions-table.component';
import { AccountTransactionsTileComponent } from 'app/accounts/transactions/tile/account-transactions-tile.component';

import { ListComponent } from 'app/shared/list/list.component';
import { CardComponent, LoadMoreComponent } from 'app/shared';

import type { Props } from './account-details-transactions.component.types';

export class AccountDetailsTransactionsComponent extends Component<Props> {
  render() {
    return (
      <>
        <CardComponent>
          <div className="pt-5">
            {this.props.loading && <AccountTransactionsPlaceholder />}

            {this.props.loading || (
              <>
                <div className="card-header card-header--between">
                  <h4 className="card-title">
                    <FormattedMessage
                      id="TRANSACTIONS.COUNTER"
                      values={{ count: this.props.transactions.length, total: this.props.meta.total }}
                    />
                  </h4>
                </div>
                <div className="card-body">
                  <ListComponent
                    list={this.props.transactions}
                    table={AccountTransactionsTableComponent}
                    tile={AccountTransactionsTileComponent}
                    props={{
                      pathname: this.props.location.pathname,
                      permissions: this.props.permissions,
                    }}
                  />

                  {this.props.transactions.length < this.props.meta.total && (
                    <LoadMoreComponent onClick={this.props.onLoadMore} fetching={this.props.fetching} />
                  )}
                </div>
              </>
            )}
          </div>
        </CardComponent>

        <Switch>
          <Route
            path={`${this.props.match.url}/:transaction`}
            render={props => (
              <TransactionDetailsContainer
                number={this.props.match.params.number}
                pathname={this.props.match.url}
                {...props}
              />
            )}
          />
        </Switch>
      </>
    );
  }
}
