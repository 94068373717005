// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import classNames from 'classnames';

import { HeaderComponent } from 'app//core';
import {
  AlertComponent,
  ButtonComponent,
  CardComponent,
  ConnectedInputComponent,
  ConnectedTextareaComponent,
  SummaryFieldComponent,
} from 'app/shared';
import { currencyFormatter } from 'app/utils';

import type { Props } from './payments-mobile-summary.component.types';
import styles from './payments-mobile-summary.module.scss';

export class PaymentsMobileSummaryComponent extends Component<Props> {
  render() {
    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    return (
      <div>
        <HeaderComponent
          breadcrumbs={[
            {
              icon: 'hand-graving-smartphone',
              onClick: this.props.onBack,
            },
            {
              name: <FormattedMessage id="MOBILE_PAYMENT.CONFIRM" />,
            },
          ]}
        >
          <FormattedMessage id="SIDEBAR.MOBILE_PAYMENT" tagName="h2" />
        </HeaderComponent>

        <CardComponent>
          <div className="card-header">
            {this.props.error && (
              <AlertComponent type="danger">
                <FormattedMessage id={this.props.error} />
              </AlertComponent>
            )}
            <h4 className="card-title">
              <FormattedMessage id="MOBILE_PAYMENT.CONFIRM" />
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <SummaryFieldComponent>
                  <FormattedMessage id="MOBILE_PAYMENT.ACCOUNT_BALANCE" tagName="strong" />
                  {currencyFormatter.format(this.props.payment.balance)}
                </SummaryFieldComponent>
              </div>
              <div className="col-md-4">
                <SummaryFieldComponent>
                  <FormattedMessage id="MOBILE_PAYMENT.TOKEN_VENUE" tagName="strong" />
                  <FormattedMessage id={`PAYMENT.ITEM.${this.props.payment.item}`} />
                </SummaryFieldComponent>
              </div>
              <div className="col-md-4">
                <SummaryFieldComponent>
                  <FormattedMessage id="MOBILE_PAYMENT.CURRENT_VENUE" tagName="strong" />
                  {this.props.business.name}
                </SummaryFieldComponent>
              </div>
            </div>
            <div className="row">
              <div className={columnClassNames}>
                <Field
                  name="name"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'MOBILE_PAYMENT.OWNER' })}
                  placeholder={this.props.intl.formatMessage({ id: 'MOBILE_PAYMENT.OWNER' })}
                  disabled
                />
              </div>
              <div className={columnClassNames}>
                <Field
                  name="amount"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'MOBILE_PAYMENT.AMOUNT' })}
                  placeholder={this.props.intl.formatMessage({ id: 'MOBILE_PAYMENT.AMOUNT' })}
                  addon={this.props.intl.formatMessage({ id: 'MOBILE_PAYMENT.AMOUNT_ADDON' })}
                  required
                />
              </div>
              <div className="col-lg-12">
                <Field
                  name="message"
                  component={ConnectedTextareaComponent}
                  label={this.props.intl.formatMessage({ id: 'MOBILE_PAYMENT.MESSAGE' })}
                  placeholder={this.props.intl.formatMessage({ id: 'MOBILE_PAYMENT.MESSAGE' })}
                  addon={this.props.intl.formatMessage({ id: 'MOBILE_PAYMENT.MESSAGE_ADDON' })}
                />
              </div>
            </div>
          </div>
          <div className={classNames('card-footer', styles.footer)}>
            <ButtonComponent onClick={this.props.onBack} theme="outline-secondary" size="large">
              <i className="fi fi-go-back-left-arrow" /> <FormattedMessage id="CORE.BACK" />
            </ButtonComponent>
            <ButtonComponent theme="primary" size="large" loading={this.props.submitting}>
              <FormattedMessage id="CORE.CONFIRM" /> <i className="fi fi-right-arrow-forward" />
            </ButtonComponent>
          </div>
        </CardComponent>
      </div>
    );
  }
}
