// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, FieldArray, FormSection } from 'redux-form';

import {
  ButtonComponent,
  ConnectedInputComponent,
  ConnectedSelectComponent,
  ModalComponent,
  ConnectedPhoneNumberInputComponent,
} from 'app/shared';

import { AddBeneficiaryBenefitsComponent } from './benefits/add-beneficiary-benefits.component';
import { AddBeneficiaryFiltersContainer } from './filters/add-beneficiary-filters.container';

import type { Props } from './add-beneficiary.component.types';
import styles from './add-beneficiary.module.scss';

export class AddBeneficiaryComponent extends Component<Props> {
  componentDidMount() {
    if (this.props.type === 'emoney') {
      this.props.onFiltersClear();
    }
  }

  render() {
    return (
      <>
        <ModalComponent
          title={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.BENEFICIARIES.ADD' })}
          onClose={this.props.onClose}
          submitButton={
            <ButtonComponent theme="primary" size="medium" loading={this.props.submitting} onClick={this.props.submit}>
              <FormattedMessage id="CORE.ADD" />
            </ButtonComponent>
          }
        >
          <>
            {this.props.type === 'emoney' && (
              <AddBeneficiaryFiltersContainer
                onChange={this.props.onFiltersChange}
                onClear={this.props.onFiltersClear}
              />
            )}
            <form>
              {this.props.type === 'voucher' && (
                <FormSection name="beneficiary" className="row">
                  <div className="col-6">
                    <Field
                      name="firstName"
                      component={ConnectedInputComponent}
                      label={this.props.intl.formatMessage({ id: 'PROFILE.FIRST_NAME' })}
                      required
                    />
                  </div>
                  <div className="col-6">
                    <Field
                      name="lastName"
                      component={ConnectedInputComponent}
                      label={this.props.intl.formatMessage({ id: 'PROFILE.LAST_NAME' })}
                      required
                    />
                  </div>
                  <div className="col-6">
                    <Field
                      name="email"
                      component={ConnectedInputComponent}
                      label={this.props.intl.formatMessage({ id: 'PROFILE.EMAIL' })}
                    />
                  </div>
                  <div className="col-6">
                    <Field
                      name="mobilePhone"
                      component={ConnectedPhoneNumberInputComponent}
                      label={this.props.intl.formatMessage({ id: 'PROFILE.MOBILE_PHONE' })}
                    />
                  </div>
                </FormSection>
              )}
              <div className="row">
                {this.props.type === 'emoney' && (
                  <div className="col-12">
                    <Field
                      name="beneficiaryId"
                      component={ConnectedSelectComponent}
                      options={this.props.filteredBeneficiaries || this.props.beneficiaries}
                      label={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.LABEL.BENEFICIARY' })}
                      required
                    />
                    {this.props.loadingUsers && (
                      <p className={styles.loadingMessage}>
                        <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.LOADING" />
                      </p>
                    )}
                  </div>
                )}

                <FieldArray
                  name="benefits"
                  component={AddBeneficiaryBenefitsComponent}
                  props={{ benefitOptions: this.props.benefitOptions, type: this.props.type, intl: this.props.intl }}
                />
                <div className="col-6">
                  <Field
                    name="deductibleAmount"
                    icon="euro-symbol"
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.BENEFICIARIES.EXCESS' })}
                  />
                </div>
              </div>
            </form>
          </>
        </ModalComponent>
      </>
    );
  }
}
