import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';

import { registerValidate, register } from 'app/redux/register/register.actions';

import { RegisterCompanyComponent } from './register-company.component';

const mapStateToProps = () => ({
  initialValues: {
    company: {
      officialAddress: {},
      billingAddress: {},
      deliveryAddress: {},
    },
  },
});

const mapDispatchToProps = dispatch => ({
  onValidate: body => dispatch(registerValidate(body, 'company')),
  onSubmit: body => dispatch(register(body, 'company')),
  onSubmitFail: () => window.scrollTo(0, 0),
});

export const RegisterCompanyContainer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'register-company',
  }),
)(RegisterCompanyComponent);
