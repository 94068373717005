// @flow

import React, { Component } from 'react';

import { AccountTransactionsTablePlaceholder } from './table/account-transactions-table.placeholder';
import { AccountTransactionsTilePlaceholder } from './tile/account-transactions-tile.placeholder';

import { ListComponent } from 'app/shared/list/list.component';
import { PlaceholderComponent } from 'app/shared';

import type { Props } from './account-transactions.placeholder.types';

export class AccountTransactionsPlaceholder extends Component<Props> {
  static defaultProps = {
    transactions: [...new Array(5)],
  };

  render() {
    return (
      <>
        <div className="card-header card-header--between">
          <h4 className="card-title">
            <PlaceholderComponent width={150} />
          </h4>
        </div>
        <div className="card-body">
          <ListComponent
            list={this.props.transactions}
            table={AccountTransactionsTablePlaceholder}
            tile={AccountTransactionsTilePlaceholder}
          />
        </div>
      </>
    );
  }
}
