import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { withCoreContext } from 'app/core/core.context';
import { withSidebarContext } from 'app/core/sidebar/sidebar.context';

import { SidebarItemComponent } from './sidebar-item.component';

export const SidebarItemContainer = compose(
  withRouter,
  withSidebarContext,
  withCoreContext,
)(SidebarItemComponent);
