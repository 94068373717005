// @flow

import React, { Component } from 'react';
import { Field } from 'redux-form';

import { ButtonComponent, ConnectedInputComponent } from 'app/shared';

import type { Props } from './account-details-return.component.types';
import styles from './account-details-return.module.scss';

export class AccountDetailsReturnComponent extends Component<Props> {
  render() {
    return (
      <div className={styles.container}>
        <form onSubmit={this.props.handleSubmit}>
          <Field
            name="amount"
            component={ConnectedInputComponent}
            parse={v => v.replace(/,/g, '.')}
            label={this.props.intl.formatMessage({ id: 'ACCOUNT_INFORMATION.SUM' })}
            inputRef={this.props.inputRef}
            icon="euro-symbol"
            button={
              <ButtonComponent theme="primary" size="small">
                {this.props.intl.formatMessage({ id: 'ACCOUNT_INFORMATION.ACCEPT' })}
              </ButtonComponent>
            }
          />
        </form>
      </div>
    );
  }
}
