// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { USER_ROLES } from '../../constants';
import styles from './info-box.module.scss';
import type { Props } from './info-box.types.js';

export function InfoBoxComponent(props: Props) {
  const isUserRoleSPManager = props.role === USER_ROLES.ROLE_SERVICE_PROVIDER_MANAGER;
  const undefinedUserRole = !props.role;

  if (undefinedUserRole || !props.showInfoBox || (props.intl.locale === 'fi' && !isUserRoleSPManager)) return null;

  function renderInfoText() {
    if (isUserRoleSPManager) {
      return (
        <>
          <FormattedMessage id="CORE.INFO_BOX.VENUE.TEXT" tagName="span" />{' '}
          <a href={props.intl.messages['CORE.INFO_BOX.VENUE.LINK']}>
            <FormattedMessage id="CORE.INFO_BOX.VENUE.LINK_TEXT" />
          </a>
        </>
      );
    }
    return (
      <>
        <FormattedMessage id="CORE.INFO_BOX.COMMON.TEXT" tagName="span" />{' '}
        <a href={props.intl.messages['CORE.INFO_BOX.COMMON.LINK']}>
          <FormattedMessage id="CORE.INFO_BOX.COMMON.LINK_TEXT" />
        </a>
      </>
    );
  }

  return (
    <div className={styles.infoBox}>
      <button className={styles.close} onClick={props.onClose}>
        <span className="fi fi-error" />
      </button>
      {renderInfoText()}
    </div>
  );
}
