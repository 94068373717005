import { compose } from 'redux';
import { connect } from 'react-redux';

import { CoreComponent } from './core.component';

import { fetchPermissions } from 'app/redux/permissions/permissions.actions';
import { fetchVenueContracts } from 'app/redux/venues/venues.actions';

const mapStateToProps = ({ permissions, users: { isAuthenticated, role, business } }) => ({
  authenticated: !!isAuthenticated,
  loading: !permissions,
  role,
  business,
});

const mapDispatchToProps = dispatch => ({
  fetchPermissions: () => dispatch(fetchPermissions()),
  fetchVenueContracts: () => dispatch(fetchVenueContracts({ benefitType: 'MASSAGE' })),
});

export const CoreContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(CoreComponent);
