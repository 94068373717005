import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { BenefitTableComponent } from './benefit-table.component';

const mapStateToProps = (
  { dictionaries },
  {
    match: {
      params: { type },
    },
  },
) => ({
  productsAmount: dictionaries[`products/${type}`].products.length,
});

export const BenefitTableContainer = compose(
  withRouter,
  connect(mapStateToProps),
)(BenefitTableComponent);
