// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent, NoResultsContainer, TableComponent } from 'app/shared';
import { currencyFormatter, dateFormatter } from 'app/utils';

import type { Props } from './settlements-list-table.component.types';

export class SettlementsListTableComponent extends Component<Props> {
  render() {
    return (
      <TableComponent className="table-striped">
        <thead>
          <tr>
            <FormattedMessage id="SETTLEMENTS.LIST.HEADERS.SETTLEMENT_ID" tagName="th" />
            <FormattedMessage id="SETTLEMENTS.LIST.HEADERS.DATE" tagName="th" />
            <FormattedMessage id="SETTLEMENTS.LIST.HEADERS.INTERVAL" tagName="th" />
            <FormattedMessage id="SETTLEMENTS.LIST.HEADERS.GROSS_AMOUNT" tagName="th" />
            <th />
          </tr>
        </thead>
        <tbody>
          {!!this.props.list.length || (
            <tr>
              <td colSpan={5}>
                <NoResultsContainer searchable={false} />
              </td>
            </tr>
          )}
          {this.props.list.map(settlement => (
            <tr key={settlement.id}>
              <td>{settlement.erpId}</td>
              <td>{dateFormatter(settlement.processed)}</td>
              <td>
                {dateFormatter(settlement.fromDate)} - {dateFormatter(settlement.toDate)}
              </td>
              <td>{currencyFormatter.format(settlement.amount)}</td>
              <td className="text-right">
                <ButtonComponent theme="secondary" size="small" onClick={() => this.props.onDownload(settlement.id)}>
                  <FormattedMessage id="SETTLEMENTS.BREAKDOWN" />
                </ButtonComponent>
              </td>
            </tr>
          ))}
        </tbody>
      </TableComponent>
    );
  }
}
