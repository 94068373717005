import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';

import { BanknotesOrderFormComponent } from './banknotes-order-form.component';

const mapStateToProps = ({ dictionaries, form }) => ({
  products: get(dictionaries, ['products/banknote', 'products']),
  form: form && form['order-banknotes'],
});

export const BanknotesOrderFormContainer = compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps),
)(BanknotesOrderFormComponent);
