import { handleActions } from 'redux-actions';

import * as types from './orders.action-types';

const defaultState = {
  entities: {},
};

export const ordersReducer = handleActions(
  {
    [types.CREATE_SPECIFIC_ORDER_SUCCESS]: (state, { payload: { data } = {} }) => ({
      ...state,
      entities: {
        ...state.entities,
        ...(data ? { [data.id]: data } : {}),
      },
    }),
    [types.FETCH_ORDERS_REQUEST]: state => ({
      ...state,
      list: undefined,
    }),
    [types.FETCH_ORDERS_SUCCESS]: (state, { payload: { data: list, meta } }) => ({
      ...state,
      list,
      meta,
    }),
    [types.FETCH_ORDER_REQUEST]: (state, { meta: { order } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [order]: undefined,
      },
    }),
    [types.CREATE_ORDER_SUCCESS]: (state, { payload: { data } }) => ({
      ...state,
      orderData: data,
    }),
    [types.FETCH_ORDER_SUCCESS]: (state, { meta: { order }, payload: { data: entity } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [order]: entity,
      },
    }),
    [types.CANCEL_ORDER_REQUEST]: (state, { meta: { order } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [order]: {
          ...state.entities[order],
          canceling: true,
        },
      },
    }),
    [types.CANCEL_ORDER_SUCCESS]: (state, { meta: { order } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [order]: {
          ...state.entities[order],
          canceling: false,
        },
      },
      list: state.list ? state.list.filter(e => e.id !== order) : undefined,
    }),
    [types.CANCEL_ORDER_FAILURE]: (state, { meta: { order } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [order]: {
          ...state.entities[order],
          canceling: false,
        },
      },
    }),
  },
  defaultState,
);
