// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Switch } from 'react-router-dom';

import { TransactionDetailsContainer } from '../details/transaction-details.container';

import { TransactionsLatestPlaceholder } from './placeholder/transactions-latest.placeholder';
import { TransactionsLatestTableComponent } from './table/transactions-latest-table.component';
import { TransactionsLatestTileComponent } from './tile/transactions-latest-tile.component';

import { HeaderComponent } from 'app/core';
import { ListComponent } from 'app/shared/list/list.component';
import { CardComponent } from 'app/shared';

import type { Props } from './transactions-latest.component.types';

export class TransactionsLatestComponent extends Component<Props> {
  componentDidMount() {
    this.props.fetchLatestTransactions();
  }

  render() {
    return (
      <>
        <HeaderComponent breadcrumbs={[{ icon: 'transactionhistory' }]}>
          <FormattedMessage id="SIDEBAR.LATEST_TRANSACTIONS" tagName="h2" />
        </HeaderComponent>

        <CardComponent>
          {this.props.loading && <TransactionsLatestPlaceholder />}

          {this.props.loading || (
            <div className="card-body">
              <ListComponent
                list={this.props.transactions}
                table={TransactionsLatestTableComponent}
                tile={TransactionsLatestTileComponent}
                props={{
                  permissions: this.props.permissions,
                }}
              />
            </div>
          )}
        </CardComponent>

        <Switch>
          <Route
            path={`${this.props.match.url}/:account/:transaction`}
            render={props => <TransactionDetailsContainer pathname={this.props.match.url} {...props} />}
          />
        </Switch>
      </>
    );
  }
}
