// @flow

import React, { Component } from 'react';
import Sticky from 'react-stickynode';
import classNames from 'classnames';

import { SidebarAccountContainer } from './account/sidebar-account.container';
import { SidebarGeneralContainer } from './general/sidebar-general.container';
import { SidebarSettingsContainer } from './settings/sidebar-settings.container';
import { SidebarUserContainer } from './user/sidebar-user.container';
import { SidebarManegementContainer } from '../../core/sidebar/management/sidebar-management.container';
import { USER_ROLES } from 'app/constants';

import { SidebarContext } from './sidebar.context';

import type { Props } from './sidebar.component.types';
import styles from './sidebar.module.scss';

export class SidebarComponent extends Component<Props> {
  element: ?HTMLDivElement;
  tooltipsContainer: ?HTMLDivElement;
  sticky: React.createRef<typeof Component> = React.createRef();

  handleCollapseClick = () => {
    this.props.toggleSidebar();

    // update width of Sticky component
    setTimeout(() => {
      this.sticky.current.fix(0);
      this.sticky.current.updateInitialDimension();
    }, 0);
  };

  render() {
    return (
      <SidebarContext.Provider value={this.tooltipsContainer}>
        <div className={classNames(styles.sidebar, { [styles.collapsed]: this.props.sidebarCollapsed })}>
          <Sticky bottomBoundary={`.${styles.sidebar}`} innerZ={100} ref={this.sticky}>
            <div className={styles.container}>
              <div className={styles.tooltipsContainer} ref={c => (this.tooltipsContainer = c)} />

              <nav>
                <button className={styles.collapse} onClick={this.handleCollapseClick}>
                  <i className="fi fi-expand" />
                </button>

                <SidebarUserContainer />

                {this.props.isApproved && (
                  <>
                    <SidebarAccountContainer />
                    <SidebarGeneralContainer />
                    {this.props.isFetchingMassageContracts ||
                      (this.props.venueMassageContracts &&
                        !this.props.venueMassageContracts.length &&
                        this.props.role === USER_ROLES.ROLE_VENUE_MANAGER) || <SidebarManegementContainer />}
                  </>
                )}
                <SidebarSettingsContainer />
              </nav>

              <div id="portal-root" />
            </div>
          </Sticky>
        </div>
      </SidebarContext.Provider>
    );
  }
}
