// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent, NoResultsContainer, TableComponent } from 'app/shared';
import { currencyFormatter, dateFormatter } from 'app/utils';
import { Link } from 'react-router-dom';

import type { Props } from './reports-list-table.component.types';
import styles from './reports-list-table.module.scss';

export class ReportsListTableComponent extends Component<Props> {
  render() {
    return (
      <TableComponent className="table-striped">
        <thead>
          <tr>
            <FormattedMessage id="SALDO_REPORTS.SUBSCRIPTION_LIST" tagName="th" />
            <FormattedMessage id="SALDO_REPORTS.BENEFIT_OPTIONS" tagName="th" />
            <FormattedMessage id="SALDO_REPORTS.BENEFICIARIES" tagName="th" />
            <th className="text-right">
              <FormattedMessage id="SALDO_REPORTS.DEPOSIT_AMOUNT" />
            </th>
            <th className="text-right">
              <FormattedMessage id="SALDO_REPORTS.BALANCE" />
            </th>
            <th className="text-right">
              <FormattedMessage id="SALDO_REPORTS.USAGE_REPORT" />
            </th>
          </tr>
        </thead>
        <tbody>
          {!!this.props.list.length || (
            <tr>
              <td colSpan={6}>
                <NoResultsContainer searchable={false} />
              </td>
            </tr>
          )}
          {this.props.list.map(report => (
            <React.Fragment key={report.id}>
              <tr className={styles.mainRow}>
                <td colSpan={2}>
                  <Link to={`/subscriptions/emoney/${report.id}`}>{report.name}</Link>
                </td>
                <td colSpan={3}>{report.beneficiariesNumber} pcs</td>
                <td className="text-right">
                  <ButtonComponent
                    size="small"
                    theme="secondary"
                    to={{
                      pathname: `/reports/${report.distributionId}`,
                      search: this.props.location.search,
                      state: {
                        keepScrollPosition: true,
                      },
                    }}
                  >
                    <FormattedMessage id="SALDO_REPORTS.SHOW_REPORT" />
                  </ButtonComponent>
                </td>
              </tr>
              {report.benefits.map((benefit, index) => (
                <tr className={styles.childRow} key={`${report.id}-${index}`}>
                  <td>
                    <span>{dateFormatter(benefit.dateFrom)}</span>
                    <span> - </span>
                    <span>{dateFormatter(benefit.dateTo)}</span>
                  </td>
                  <td colSpan={2}>
                    <FormattedMessage id={`PAYMENT.ITEM.${benefit.name}`} />
                  </td>
                  <td className="text-right">{currencyFormatter.format(benefit.deposit)}</td>
                  <td className="text-right">{currencyFormatter.format(benefit.balance)}</td>
                  <td />
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </TableComponent>
    );
  }
}
