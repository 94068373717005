import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { fetchAccounts } from 'app/redux/accounts/accounts.actions';

import { MyAccountsListComponent } from './my-accounts-list.component';

const mapStateToProps = ({
  accounts: {
    beneficiary: { list: accounts },
  },
}) => ({
  accounts,
  loading: !accounts,
});

const mapDispatchToProps = dispatch => ({
  fetchAccounts: () => dispatch(fetchAccounts('beneficiary')),
});

export const MyAccountsListContainer = compose(
  injectIntl,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(MyAccountsListComponent);
