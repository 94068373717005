// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { currencyFormatter } from 'app/utils';

import { AgreementsAcceptanceContainer } from 'app/agreements/acceptance/agreements-acceptance.container';
import { ButtonComponent, ButtonsWrapperComponent, SummaryFieldComponent, TableComponent } from 'app/shared';

import type { Props, State } from './banknotes-order-confirm.component.types';
import styles from './banknotes-order-confirm.module.scss';

export class BanknotesOrderConfirmComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      terms: {
        onChange: () => this.setState(prev => ({ terms: { ...prev.terms, value: !prev.terms.value } })),
        value: false,
      },
    };
  }

  invoicingOptions = (this.props.invoicingOptions || []).filter(
    option => option.id === this.props.values.invoicing.invoicingMethod,
  )[0];

  formattedProducts = this.props.values.products.map(({ amount, id }) => {
    const product = this.props.products.find(product => product.id === id);
    return {
      quantity: Number(amount),
      amount: product ? product.amount * Math.floor(Number(amount.replace(',', '.'))) * 10 : 0,
      id,
      tax: product ? product.tax : 0,
    };
  });

  totalQuantity = this.formattedProducts.reduce((prev, { quantity }) => (prev += quantity), 0);
  totalAmount = this.formattedProducts.reduce((prev, { amount }) => (prev += amount), 0);
  additionalFees = (() => {
    const smoothie = this.formattedProducts.filter(product => product.id === 'SMOOTHIE_VOUCHER')[0];
    const smoothieTax = smoothie && smoothie.tax ? smoothie.amount * (smoothie.tax / 100) : 0;
    const tax =
      this.invoicingOptions.price && this.invoicingOptions.tax
        ? this.invoicingOptions.price * this.invoicingOptions.tax
        : 0;

    return this.invoicingOptions.price + tax + smoothieTax;
  })();

  render() {
    return (
      <>
        <div className="card-header">
          <h4 className="card-title">
            <FormattedMessage id="ORDERS.ORDER_DETAILS" />
          </h4>
        </div>
        <div className="card-body">
          <TableComponent className="table-striped">
            <thead>
              <tr className={styles.hederRow}>
                <FormattedMessage id="ORDERS.PRODUCT" tagName="th" />
                <FormattedMessage id="ORDERS.QUANTITY" tagName="th" />
                <FormattedMessage id="ORDERS.AMOUNT" tagName="th" />
              </tr>
            </thead>
            <tbody>
              {this.formattedProducts.map((product, index) => (
                <tr key={index}>
                  <td>
                    <FormattedMessage id={`PRODUCT.${product.id}`} />
                  </td>
                  <td>
                    {product.quantity} <small>x 10</small>
                  </td>
                  <td className={styles.amounts}>
                    {product.id === 'SMOOTHIE_VOUCHER' && product.tax
                      ? currencyFormatter.format(product.amount + product.amount * (product.tax / 100))
                      : currencyFormatter.format(product.amount)}
                  </td>
                </tr>
              ))}
              <tr>
                <td>
                  <FormattedMessage id="ORDERS.DELIVERY_FEE" />
                </td>
                <td />
                <td className={styles.amounts}>{currencyFormatter.format(this.invoicingOptions.price || 0)}</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage
                    id="ORDERS.DELIVERY_FEE_TAX"
                    values={{ taxRate: this.invoicingOptions.tax ? `(${this.invoicingOptions.tax * 100}%)` : '' }}
                  />
                </td>
                <td />
                <td className={styles.amounts}>
                  {currencyFormatter.format(
                    this.invoicingOptions.price && this.invoicingOptions.tax
                      ? this.invoicingOptions.price * this.invoicingOptions.tax
                      : 0,
                  )}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <FormattedMessage id="ORDERS.ORDER_TOTAL" />
                </td>
                <td />
                <td className={styles.amounts}>{currencyFormatter.format(this.totalAmount + this.additionalFees)}</td>
              </tr>
            </tfoot>
          </TableComponent>
        </div>

        <div className="card-header card-header--between">
          <h4 className="card-title">
            <FormattedMessage id="ORDERS.INVOICING_DETAILS" />
          </h4>
        </div>

        <div className="card-body">
          <div className="row">
            <div className="col-lg-6">
              <SummaryFieldComponent>
                <FormattedMessage id="ORDERS.INVOICING_METHOD" tagName="strong" />
                <FormattedMessage id={`INVOICING.${this.props.values.invoicing.invoicingMethod}.NAME`} />
              </SummaryFieldComponent>
            </div>
            <div className="col-lg-6">
              <SummaryFieldComponent>
                <FormattedMessage id="ORDERS.ORDER_REFERENCE" tagName="strong" />
                {this.props.values.invoicing.invoiceReference}
              </SummaryFieldComponent>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <SummaryFieldComponent>
                <FormattedMessage id="ORDERS.OFFICIAL_NAME" tagName="strong" />
                {this.props.values.invoicing.invoiceName}
              </SummaryFieldComponent>
            </div>
            <div className="col-lg-6">
              <SummaryFieldComponent>
                <FormattedMessage id="ORDERS.EMAIL" tagName="strong" />
                {this.props.values.invoicing.invoiceEmail}
              </SummaryFieldComponent>
            </div>
          </div>
          {this.props.values.invoicing.invoicingMethod === 'EINVOICE' && (
            <div className="row">
              <div className="col-lg-6">
                <SummaryFieldComponent>
                  <FormattedMessage id="ORDERS.DELIVERY_IDENTIFIER" tagName="strong" />
                  {this.props.values.invoicing.eInvoiceIdentifier}
                </SummaryFieldComponent>
              </div>
              <div className="col-lg-6">
                <SummaryFieldComponent>
                  <FormattedMessage id="ORDERS.DELIVERY_ADDRESS" tagName="strong" />
                  {this.props.values.invoicing.eInvoiceAddress}
                </SummaryFieldComponent>
              </div>
            </div>
          )}
        </div>

        <>
          <div className="card-header card-header--between">
            <h4 className="card-title">
              <FormattedMessage id="ORDERS.DELIVERY_DETAILS" />
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6">
                <SummaryFieldComponent>
                  <FormattedMessage id="ORDERS.STREET_ADDRESS" tagName="strong" />
                  {this.props.values.invoicingAddress.address}
                </SummaryFieldComponent>
              </div>
              <div className="col-lg-6">
                <SummaryFieldComponent>
                  <FormattedMessage id="ORDERS.ZIP_CODE" tagName="strong" />
                  {this.props.values.invoicingAddress.zipCode}
                </SummaryFieldComponent>
              </div>
              <div className="col-lg-6">
                <SummaryFieldComponent>
                  <FormattedMessage id="ORDERS.CITY" tagName="strong" />
                  {this.props.values.invoicingAddress.city}
                </SummaryFieldComponent>
              </div>
            </div>
          </div>
        </>

        <div className="card-footer">
          <AgreementsAcceptanceContainer input={this.state.terms} name="order_banknotes" />

          <ButtonsWrapperComponent>
            <ButtonComponent theme="outline-secondary" size="large" onClick={this.props.onBack}>
              <i className="fi fi-go-back-left-arrow" /> <FormattedMessage id="CORE.BACK" />
            </ButtonComponent>

            <ButtonComponent
              theme="primary"
              size="large"
              className="ml-auto"
              disabled={!this.state.terms.value}
              loading={this.props.submitting}
            >
              <FormattedMessage id="CORE.CONFIRM" />
            </ButtonComponent>
          </ButtonsWrapperComponent>
        </div>
      </>
    );
  }
}
