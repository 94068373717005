// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { currencyFormatter } from 'app/utils';

import {
  AlertComponent,
  ButtonComponent,
  ButtonsWrapperComponent,
  TableComponent,
  VouchersAmountComponent,
} from 'app/shared';

import { OrdersCreateInvoicingContainer } from 'app/orders/create/form/invoicing/orders-create-invoicing.container';
import { OrdersCreateDeliveryContainer } from 'app/orders/create/form/delivery/order-create-delivery.container';

import type { Props } from './subscription-order-summary.component.types';
import styles from './subscription-order-summary.module.scss';

export class SubscriptionOrderSummaryComponent extends Component<Props> {
  render() {
    const isInvoicingMethodMissing =
      this.props.form && this.props.form.values && !this.props.form.values.invoicing.invoicingMethod;

    return (
      <>
        {this.props.order.dueAmount === 0 && (
          <div className="card-body">
            <TableComponent className="table-striped">
              <thead>
                <tr className={styles.hederRow}>
                  <FormattedMessage id="ORDERS.PRODUCT" tagName="th" />
                  <FormattedMessage id="ORDERS.QUANTITY" tagName="th" />
                  <FormattedMessage id="ORDERS.AMOUNT" tagName="th" />
                </tr>
              </thead>
              <tbody>
                {this.props.order.products.map((product, index) => [
                  <tr key={index}>
                    <td className={styles.type}>
                      <FormattedMessage id={`PRODUCT.${product.id}`} />
                      {product.amount < 0 && (
                        <FormattedMessage id="SUBSCRIPTION.ORDER.CHARGE_FROM_COMPANY_ACCOUNT" tagName="small" />
                      )}
                    </td>
                    <td className={classNames(styles.type, 'align-middle')}>
                      1 x {currencyFormatter.format(product.amount)}
                      {(this.props.order.type === 'voucher' || this.props.order.type === 'banknote') && (
                        <small>
                          <VouchersAmountComponent amount={product.amount} id={product.id} />
                        </small>
                      )}
                    </td>
                    <td className={styles.amounts}>{currencyFormatter.format(product.amount)}</td>
                  </tr>,
                ])}
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <FormattedMessage id="ORDERS.ORDER_TOTAL" />
                  </td>
                  <td />
                  <td className={styles.amounts}>{currencyFormatter.format(this.props.orderTotal)}</td>
                </tr>
              </tfoot>
            </TableComponent>
          </div>
        )}
        {this.props.match.params.type === 'voucher' && (
          <div className="card-body">
            <AlertComponent type="light">
              <span className="font-weight-bold">
                <FormattedMessage id="CORE.NOTE" />!
              </span>{' '}
              <FormattedMessage id="SUBSCRIPTION.ORDER.VALID_UNTIL" />
            </AlertComponent>
          </div>
        )}
        <div className="card-header card-header--between">
          <h4 className="card-title">
            <FormattedMessage id="ORDERS.INVOICING_DETAILS" />
          </h4>
        </div>
        <div className="card-body">
          {this.props.order.dueAmount === 0 && (
            <AlertComponent type="light">
              <FormattedMessage id="SUBSCRIPTION.ORDER.NO_INVOICE" />
            </AlertComponent>
          )}

          {this.props.order.dueAmount > 0 && (
            <OrdersCreateInvoicingContainer form="order-subscription" type="subscriptionOrders" />
          )}
        </div>

        {this.props.match.params.type === 'voucher' && (
          <>
            <div className="card-header card-header--between">
              <h4 className="card-title">
                <FormattedMessage id="ORDERS.DELIVERY_DETAILS" />
              </h4>
            </div>
            <div className="card-body">
              <OrdersCreateDeliveryContainer />
            </div>
          </>
        )}
        <div className="card-footer">
          <ButtonsWrapperComponent>
            <ButtonComponent
              theme="outline-secondary"
              size="large"
              to={`/subscriptions/${this.props.match.params.type}`}
            >
              <i className="fi fi-go-back-left-arrow" /> <FormattedMessage id="SUBSCRIPTION.BACK_TO_LIST" />
            </ButtonComponent>
            <ButtonComponent
              theme="link"
              size="large"
              className="ml-3"
              onClick={this.props.onCancel}
              loading={this.props.order.canceling}
            >
              <FormattedMessage id="ORDERS.CANCEL_ORDER" />
            </ButtonComponent>

            <ButtonComponent
              theme="primary"
              size="large"
              className="ml-auto"
              loading={this.props.submitting}
              disabled={isInvoicingMethodMissing}
            >
              <FormattedMessage id={this.props.order.dueAmount === 0 ? 'CORE.CONFIRM' : 'CORE.NEXT'} />
            </ButtonComponent>
          </ButtonsWrapperComponent>
        </div>
      </>
    );
  }
}
