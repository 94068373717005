// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { HeaderComponent } from 'app/core';
import { AlertComponent, ButtonComponent, CardComponent, SummaryFieldComponent, TableComponent } from 'app/shared';
import { checkPermissions, currencyFormatter, dateFormatter } from 'app/utils';

import { AccountDetailsPlaceholder } from './placeholder/account-details.placeholder';
import { AccountDetailsReturnContainer } from './return/account-details-return.container';
import { AccountDetailsTransactionsContainer } from './transactions/account-details-transactions.container';

import type { Props, State } from './account-details.component.types';
import styles from './account-details.module.scss';

export class AccountDetailsComponent extends Component<Props, State> {
  input: ?HTMLInputElement;

  constructor(props: Props) {
    super(props);

    this.state = {
      isReturnOpened: false,
    };
  }

  componentDidMount() {
    this.props.fetchAccount();
  }

  onReturnClick = () =>
    this.setState(
      prev => ({ isReturnOpened: !prev.isReturnOpened }),
      () => {
        if (this.state.isReturnOpened) {
          if (this.input) {
            this.input.focus();
          }
        }
      },
    );

  render() {
    if (this.props.loading) {
      return <AccountDetailsPlaceholder />;
    }

    const { accountNumber, balance, item, transfers } = this.props.account;

    return (
      <>
        <HeaderComponent
          breadcrumbs={
            this.props.breadcrumbs
              ? [...this.props.breadcrumbs, { name: <FormattedMessage id={`PAYMENT.ITEM.${item}`} /> }]
              : [
                  { icon: 'account-balance', to: '/accounts' },
                  { name: <FormattedMessage id="NAV.ACCOUNT.ACCOUNTS_INFORMATION" />, to: '/accounts' },
                  { name: <FormattedMessage id={`PAYMENT.ITEM.${item}`} /> },
                ]
          }
        >
          <FormattedMessage id="ACCOUNT_INFORMATION.TITLE" tagName="h2" />
        </HeaderComponent>

        <CardComponent>
          <div className="card-header">
            {!!this.props.location.state &&
              !!this.props.location.state.success && (
                <AlertComponent type="success">
                  {this.props.location.state.success.type === 'RETURN_MADE' && (
                    <FormattedMessage id="ACCOUNT_INFORMATION.RETURN_SUCCESS" />
                  )}
                </AlertComponent>
              )}

            <h4 className="card-title">
              <FormattedMessage id="ACCOUNT_INFORMATION.BALANCE" />
            </h4>
          </div>
          <div className="card-body">
            <div className={styles.return}>
              <SummaryFieldComponent>
                <FormattedMessage id={`PRODUCT.${item}`} tagName="strong" />
                {currencyFormatter.format(balance)}
              </SummaryFieldComponent>

              {checkPermissions(this.props.permissions, ['accountReturn', 'create']) && (
                <>
                  <ButtonComponent theme="danger" className="ml-3 mt-2" onClick={this.onReturnClick}>
                    <FormattedMessage id="ACCOUNT_INFORMATION.RETURN" />
                  </ButtonComponent>

                  {this.state.isReturnOpened && (
                    <AccountDetailsReturnContainer
                      accountNumber={accountNumber}
                      inputRef={c => (this.input = c)}
                      onClose={() => this.setState({ isReturnOpened: false })}
                    />
                  )}
                </>
              )}
            </div>

            <TableComponent className="table-striped">
              <thead>
                <tr>
                  <FormattedMessage id="ACCOUNTS.VALID" tagName="th" />
                  <FormattedMessage id="ACCOUNTS.BALANCE" tagName="th" />
                </tr>
              </thead>
              <tbody>
                {transfers.map((transfer, index) => (
                  <tr key={index}>
                    <td>
                      {dateFormatter(transfer.from)} - {dateFormatter(transfer.to)}
                    </td>
                    <td>{currencyFormatter.format(transfer.balance)}</td>
                  </tr>
                ))}
              </tbody>
            </TableComponent>
          </div>
        </CardComponent>

        {checkPermissions(this.props.permissions, ['beneficiaryAccounts', 'read']) && (
          <AccountDetailsTransactionsContainer />
        )}
      </>
    );
  }
}
