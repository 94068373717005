// @flow

import React, { Component } from 'react';
import Tooltip from 'rc-tooltip';

import type { Props } from './tooltip.component.types';
import 'rc-tooltip/assets/bootstrap.css';
import './tooltip.scss';

export class TooltipComponent extends Component<Props> {
  render() {
    return <Tooltip transitionName="tooltip" trigger={['hover', 'click']} {...this.props} />;
  }
}
