import { compose } from 'redux';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { arrayPush } from 'redux-form';

import { withCoreContext } from 'app/core/core.context';

import { BenefitRowComponent } from './benefit-row.component';
import { formValueSelector } from 'redux-form';

const mapStateToProps = (
  { dictionaries, form },
  {
    fields: { name },
    form: formName,
    index,
    match: {
      params: { type },
    },
  },
) => {
  const values = formValueSelector(formName)({ form }, name);
  const benefits = values.map(el => el.id);
  const amount = values[index].amount;

  return {
    amount,
    benefits,
    products: dictionaries[`products/${type}`].products,
    multiplier: get(dictionaries[`products/${type}`].multipliers, [benefits[index]]),
  };
};

const mapDispatchToProps = (dispatch) => ({
  addField: (form, field) => dispatch(arrayPush(form, field, {})),
});

export const BenefitRowContainer = compose(
  withRouter,
  injectIntl,
  withCoreContext,
  connect(mapStateToProps, mapDispatchToProps),
)(BenefitRowComponent);
