// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { ButtonComponent, CardComponent } from 'app/shared';
import { currencyFormatter } from 'app/utils';

import { PaymentsTokenCodePlaceholder } from './placeholder/payments-token-code.placeholder';
import { CounterComponent } from '../counter/counter.component';

import type { Props, State } from './payments-token-code.component.types';
import styles from './payments-token-code.module.scss';

export class PaymentsTokenCodeComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  onTokenExpired = () => this.setState({ expired: true });

  onTokenDownload = () => this.setState({ downloaded: true });

  regenerateToken = () => {
    this.props.fetchPaymentToken(this.props.selected.accountNumber);
    this.setState({ expired: false });
  };

  renderTokenString = (hash: string) => hash.replace(/\B(?=(\d{4})+(?!\d))/g, ' ');

  render() {
    if (this.props.loading) {
      return <PaymentsTokenCodePlaceholder styles={styles} />;
    }

    const { hash, qrCode } = this.props.token;

    return (
      <CardComponent>
        <div className="card-header">
          <h4 className="card-title">
            <FormattedMessage id="MOBILE_PAYMENT_TOKEN.CODE.TITLE" />{' '}
            <FormattedMessage id={`PAYMENT.ITEM.${this.props.selected.item}`} />
          </h4>
          <p className={styles.description}>
            <FormattedMessage id="MOBILE_PAYMENT.AMOUNT" />:{' '}
            <strong className="text-success">{currencyFormatter.format(this.props.selected.balance)}</strong>
          </p>
        </div>
        <div className="card-body">
          <div className={styles.qrContainer}>
            {this.state.downloaded || (
              <>
                <div className={classNames(styles.qr, { [styles.expired]: this.state.expired })}>
                  <img className="img-fluid" src={qrCode} alt="qr-code" />
                </div>
                <div className={styles.details}>
                  <p className={styles.token}>
                    {this.state.expired ? (
                      <FormattedMessage id="MOBILE_PAYMENT_TOKEN.CODE.EXPIRED" />
                    ) : (
                      this.renderTokenString(hash)
                    )}
                  </p>
                  <p className={styles.tokenTime}>
                    {this.state.expired ? (
                      <FormattedMessage id="MOBILE_PAYMENT_TOKEN.CODE.EXPIRED_DESC" />
                    ) : (
                      <span>
                        {this.props.intl.formatMessage({ id: 'MOBILE_PAYMENT_TOKEN.CODE.VALID' })}:{' '}
                        <CounterComponent time={600} onFinish={this.onTokenExpired} />
                      </span>
                    )}
                  </p>
                  <div className={styles.buttons}>
                    {this.state.expired && (
                      <ButtonComponent onClick={this.regenerateToken} theme="primary" size="medium" className="mx-2">
                        <FormattedMessage id="MOBILE_PAYMENT_TOKEN.CODE.GENERATE_NEW" />
                      </ButtonComponent>
                    )}

                    <ButtonComponent
                      onClick={() =>
                        this.props.onDownload(this.props.selected.accountNumber).then(this.onTokenDownload)
                      }
                      className="mx-2"
                      theme="secondary"
                      size="medium"
                    >
                      <FormattedMessage id="MOBILE_PAYMENT_TOKEN.CODE.PRINT_VOUCHER" />
                    </ButtonComponent>
                  </div>
                </div>
              </>
            )}
            {this.state.downloaded && (
              <div className={styles.downloaded}>
                <p className={styles.token}>
                  <FormattedMessage id="MOBILE_PAYMENT_TOKEN.CODE.PRINT_INFO" />
                </p>
                <p className={styles.tokenTime}>
                  <FormattedMessage id="MOBILE_PAYMENT_TOKEN.CODE.PRINT_DESCRIPTION" />
                </p>
              </div>
            )}
          </div>
          <ButtonComponent theme="outline-secondary" size="large" onClick={this.props.onBack}>
            <i className="fi fi-go-back-left-arrow" /> <FormattedMessage id="CORE.BACK" />
          </ButtonComponent>
        </div>
      </CardComponent>
    );
  }
}
