import { handleActions } from 'redux-actions';

import { FETCH_DICTIONARY_SUCCESS, FETCH_DICTIONARY_FAILURE } from './dictionaries.action-types';

const defaultState = {};

export const dictionariesReducer = handleActions(
  {
    [FETCH_DICTIONARY_SUCCESS]: (state, { meta: { path }, payload: { data: dictionary } }) => ({
      ...state,
      [path]:
        path === 'products/voucher'
          ? {
              ...dictionary,
              multipliers: dictionary.products.reduce((prev, next) => ({ ...prev, [next.id]: next.amount }), {}),
            }
          : dictionary,
    }),
    [FETCH_DICTIONARY_FAILURE]: (state, { meta: { path } }) => ({
      ...state,
      [path]: {},
    }),
  },
  defaultState,
);
