import { compose } from 'redux';
import { connect } from 'react-redux';

import { withAuthorization } from 'app/common';

import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';

import { AddVenueComponent } from './add-venue.component';

const mapStateToProps = ({ dictionaries }) => ({
  loading: !dictionaries.services,
});

const mapDispatchToProps = dispatch => ({
  fetchDictionary: () => dispatch(fetchDictionary('services', true)),
});

export const AddVenueContainer = compose(
  withAuthorization(['venues', 'create']),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(AddVenueComponent);
