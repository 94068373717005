// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, Fields } from 'redux-form';
import classNames from 'classnames';

import { AccountTransactionsInformationComponent } from 'app/accounts/transactions/information/account-transactions-information.component';

import { ButtonComponent, ConnectedDateRangeComponent, ConnectedSelectComponent } from 'app/shared';

import type Moment from 'moment';
import type { Props, State } from './account-transactions-filters.component.types';
import styles from 'app/transactions/company/filters/transactions-company-filters.module.scss';

export class AccountTransactionsFiltersComponent extends Component<Props, State> {
  static defaultProps = {
    types: [],
  };

  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  toggle = (state: any) => this.setState({ isCalendarOpened: !!state });
  parser = (value?: Moment) => (value ? value.format('YYYY-MM-DD') : null);

  render() {
    const rowClassNames = classNames('row', styles.row);

    const backdropClassNames = classNames('DateRangeBackdrop', {
      'DateRangeBackdrop--active': this.state.isCalendarOpened,
    });

    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    return (
      <>
        <div className="card-header">
          <div className="d-flex align-items-center justify-content-xl-between">
            <h4 className="card-title">
              <FormattedMessage id="TRANSACTIONS.SEARCH" />
            </h4>
            <ButtonComponent
              theme="outline-secondary"
              size="medium"
              className="ml-4 ml-xl-0"
              onClick={this.props.onClear}
            >
              <FormattedMessage id="CORE.CLEAR_FILTERS" />
            </ButtonComponent>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={this.props.handleSubmit}>
            <div className={rowClassNames}>
              <div className={columnClassNames}>
                <div className={rowClassNames}>
                  <div className="col-12">
                    <Fields
                      names={['minDate', 'maxDate']}
                      relativeNames={['minDate', 'maxDate']}
                      component={ConnectedDateRangeComponent}
                      onFocusChange={this.toggle}
                      label={this.props.intl.formatMessage({ id: 'TRANSACTIONS.TRANSACTION_DATE' })}
                      parse={this.parser}
                      intl={this.props.intl}
                    />
                  </div>
                  <div className="col-12">
                    <Field
                      name="type"
                      component={ConnectedSelectComponent}
                      label={this.props.intl.formatMessage({ id: 'TRANSACTIONS.TRANSACTION_TYPE' })}
                      options={this.props.types.map(type => ({
                        id: type,
                        name: this.props.intl.formatMessage({ id: `TRANSACTION_TYPE.${type}` }),
                      }))}
                      none
                    />
                  </div>
                </div>
              </div>
              <div className={columnClassNames}>
                <AccountTransactionsInformationComponent
                  account={this.props.account.account}
                  loading={this.props.account.loading}
                />
              </div>
            </div>
            <div className={backdropClassNames} />
          </form>
        </div>
      </>
    );
  }
}
