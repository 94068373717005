// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { isBusinessDifferent } from 'app/utils';

import { ActivationCardsPlaceholder } from './placeholder/activation-cards.placeholder';
import { ActivationCardsTableComponent } from './table/activation-cards-table.component';
import { ActivationCardsTileComponent } from './tile/activation-cards-tile.component';

import { HeaderComponent } from 'app/core';
import { ListComponent } from 'app/shared/list/list.component';
import { CardComponent } from 'app/shared/index';

import type { Props } from './activation-cards.component.types';

export class ActivationCardsComponent extends Component<Props> {
  componentDidMount() {
    this.props.fetchActivationCards();
  }

  componentDidUpdate(prevProps: Props) {
    if (isBusinessDifferent(prevProps, this.props)) {
      this.props.fetchActivationCards();
    }
  }

  render() {
    return (
      <>
        <HeaderComponent breadcrumbs={[{ icon: 'credit-card' }]}>
          <FormattedMessage id="SIDEBAR.ACTIVATION_CARDS" tagName="h2" />
        </HeaderComponent>

        <CardComponent>
          <div className="card-header">
            <h4 className="card-title">
              <FormattedMessage id="CARDS.LIST" />
            </h4>
          </div>
          <div className="card-body">
            {this.props.loading && <ActivationCardsPlaceholder />}
            {this.props.loading || (
              <ListComponent
                list={this.props.cards}
                table={ActivationCardsTableComponent}
                tile={ActivationCardsTileComponent}
                searchable={false}
              />
            )}
          </div>
        </CardComponent>
      </>
    );
  }
}
