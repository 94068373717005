// @flow

import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import moment from 'moment';
import classNames from 'classnames';

import { Field, Fields, FieldArray, FormSection } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import {
  AlertComponent,
  ButtonComponent,
  ButtonsWrapperComponent,
  ConnectedDateRangeComponent,
  ConnectedInputComponent,
  ConnectedSelectComponent,
  ConnectedTextareaComponent,
} from 'app/shared';

import { SubscriptionNavContainer } from 'app/subscriptions/nav/subscription-nav.container';

import { BenefitsContainer } from './benefits/benefits.container';
import { InfoComponent } from './info/info.component';
import { QuestionsContainer } from './questions/questions.container';
import { HelpLinkContainer } from 'app/shared/help-link/help-link.container';

import type { Props, State } from './subscription-form.component.types';

import styles from './subscription-form.module.scss';
import { stepsDictionary } from 'app/utils/subscription/subscription.util';

const maxMessageLength = 3000;

export class SubscriptionFormComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      additionalQuestionsInfo: false,
      basicInformationInfo: false,
      isCalendarOpened: false,
      subscriptionPeriodInfo: false,
      step: 0,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { intl, formValues, initialValues } = this.props;

    if (prevProps.intl.locale !== intl.locale && formValues.description === prevProps.initialValues.description) {
      this.props.initialize({ ...formValues, description: initialValues.description });
    }
  }

  getDaysOption = () =>
    ([...new Array(28)].map((_, i) => ({ id: i + 1, name: i + 1 })): { id: number, name: number }[]);

  getMonthsOption = () => moment.months().map((name, i) => ({ id: i + 1, name }));

  onInfoToggle = (name: string) => this.setState(state => ({ [name]: !state[name] }));

  onCalendarToggle = (state: any) => this.setState({ isCalendarOpened: !!state });

  onChangeStep = (id: number) => this.setState({ step: id });

  render() {
    const { stepsValuesValid } = this.props;
    const { step } = this.state;

    const steps = stepsDictionary[this.props.match.params.type];

    const backdropClassNames = classNames('DateRangeBackdrop', {
      'DateRangeBackdrop--active': this.state.isCalendarOpened,
    });

    const columnDateRangeClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    const columnSelectsClassNames = classNames('col-6', 'col-xl-4', {
      'col-lg-3': this.props.sidebarCollapsed,
    });

    const distributionDayOptions = [
      {
        id: null,
        name: this.props.intl.formatMessage({ id: 'SUBSCRIPTION.PLACEHOLDER.DISTRIBUTION_DAY' }),
      },
      ...this.getDaysOption(),
      {
        id: 'L',
        name: this.props.intl.formatMessage({ id: 'SUBSCRIPTION.PLACEHOLDER.LAST_DAY' }),
      },
    ];

    const exceptOnMonthOptions = [
      {
        id: null,
        name: this.props.intl.formatMessage({ id: 'SUBSCRIPTION.PLACEHOLDER.NO_MONTH' }),
      },
      ...this.getMonthsOption(),
    ];

    return (
      <form onSubmit={this.props.handleSubmit} className={styles.form} noValidate>
        <div className="card-header">
          <SubscriptionNavContainer edit={!!this.props.subscription} />

          {steps.map((stepEl, index) => (
            <ButtonComponent
              key={index}
              theme={steps[step] === stepEl ? 'link-tab--active' : 'link-tab'}
              size="small"
              onClick={() => this.onChangeStep(index)}
              disabled={
                (!stepsValuesValid[steps[step]] && index > step) ||
                (stepsValuesValid[steps[step]] && index > step + 1 && this.props.form === 'create-subscription')
              }
            >
              <FormattedMessage id={`SUBSCRIPTION.SECTION_NAV.${stepEl}`} />
            </ButtonComponent>
          ))}
        </div>

        {this.props.submitFailed && (
          <div className="card-header">
            <AlertComponent type="danger">
              <FormattedMessage id="VALIDATION.SUBSCRIPTION_FORM_ERROR" tagName="p" />
            </AlertComponent>
          </div>
        )}

        {steps[step] === 'BASIC_INFORMATION' && (
          <>
            <div className="card-header">
              {!!this.props.location.state &&
                !!this.props.location.state.information && (
                  <AlertComponent type="info">
                    <FormattedMessage id={this.props.location.state.information.id} />
                  </AlertComponent>
                )}

              <h4 className="card-title">
                <FormattedMessage id="SUBSCRIPTION.SECTION_TITLES.BASIC_INFORMATION" />
                <i
                  className={classNames('fi fi-info-sign', styles.sign)}
                  onClick={() => this.onInfoToggle('basicInformationInfo')}
                />
              </h4>
            </div>

            <InfoComponent open={this.state.basicInformationInfo}>
              <FormattedMessage id="SUBSCRIPTION.SECTION_TITLES.BASIC_INFORMATION" tagName="strong" />
              <FormattedMessage id="SUBSCRIPTION.SECTION_DESC.BASIC_INFORMATION" tagName="p" />
            </InfoComponent>

            <div className="card-body">
              <div className="row">
                <div className="col-lg-12">
                  <Field
                    name="title"
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.LABEL.TITLE' })}
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <Field
                    name="description"
                    component={ConnectedTextareaComponent}
                    label={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.LABEL.MESSAGE_TO_EMPLOYEES' })}
                    maxLength={maxMessageLength}
                    showCounter={true}
                    required
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {steps[step] === 'SUBSCRIPTION_PERIOD' && (
          <>
            <div className="card-header">
              <h4 className="card-title">
                <FormattedMessage id="SUBSCRIPTION.SECTION_TITLES.SUBSCRIPTION_PERIOD" />
                <i
                  className={classNames('fi fi-info-sign', styles.sign)}
                  onClick={() => this.onInfoToggle('subscriptionPeriodInfo')}
                />
              </h4>
            </div>

            <InfoComponent open={this.state.subscriptionPeriodInfo}>
              <FormattedMessage id="SUBSCRIPTION.SECTION_TITLES.SUBSCRIPTION_PERIOD" tagName="strong" />
              <FormattedMessage id="SUBSCRIPTION.SECTION_DESC.SUBSCRIPTION_PERIOD" tagName="p" />
            </InfoComponent>

            <div className="card-body">
              <AlertComponent type="light" className="text-left">
                <FormattedMessage id="SUBSCRIPTION.INSTRUCTION_BOX.SUBSCRIPTION_PERIOD" tagName="p" />
              </AlertComponent>

              <FormSection name="subscription">
                <div className="row">
                  <div className={columnDateRangeClassNames}>
                    <Fields
                      names={['subscriptionFrom', 'subscriptionTo']}
                      component={ConnectedDateRangeComponent}
                      relativeNames={['subscriptionFrom', 'subscriptionTo']}
                      onFocusChange={this.onCalendarToggle}
                      label={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.LABEL.SUBSCRIPTION_PERIOD' })}
                      parse={value => (value ? value.format('YYYY-MM-DD') : null)}
                      intl={this.props.intl}
                      required
                    />
                  </div>
                </div>
              </FormSection>
            </div>
          </>
        )}

        {steps[step] === 'RECURRING' && (
          <>
            <div className="card-header">
              <h4 className="card-title">
                <FormattedMessage id="SUBSCRIPTION.SECTION_TITLES.RECURRING" />
                <i
                  className={classNames('fi fi-info-sign', styles.sign)}
                  onClick={() => this.onInfoToggle('subscriptionPeriodInfo')}
                />
              </h4>
            </div>

            <InfoComponent open={this.state.subscriptionPeriodInfo}>
              <FormattedMessage id="SUBSCRIPTION.SECTION_TITLES.RECURRING" tagName="strong" />
              <FormattedMessage id="SUBSCRIPTION.SECTION_DESC.RECURRING" tagName="p" />
            </InfoComponent>

            <div className="card-body">
              <AlertComponent type="light" className="text-left">
                <FormattedMessage id="SUBSCRIPTION.INSTRUCTION_BOX.RECURRING" tagName="p" />
              </AlertComponent>

              <FormSection name="subscription">
                <div className="row">
                  <div className={columnSelectsClassNames}>
                    <Field
                      name="distributionDay"
                      component={ConnectedSelectComponent}
                      options={distributionDayOptions}
                      label={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.LABEL.DISTRIBUTION_DAY' })}
                      placeholder={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.PLACEHOLDER.DISTRIBUTION_DAY' })}
                    />
                  </div>
                  <div className={columnSelectsClassNames}>
                    <Field
                      name="exceptMonth"
                      component={ConnectedSelectComponent}
                      options={exceptOnMonthOptions}
                      label={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.LABEL.EXCEPT_MONTH' })}
                      placeholder={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.PLACEHOLDER.NO_MONTH' })}
                    />
                  </div>
                </div>
              </FormSection>
            </div>
          </>
        )}

        {steps[step] === 'BENEFIT_OPTION' && (
          <FieldArray
            component={BenefitsContainer}
            name="benefitOptions"
            props={{
              sidebarCollapsed: this.props.sidebarCollapsed,
              onCalendarToggle: this.onCalendarToggle,
            }}
          />
        )}

        {steps[step] === 'ADDITIONAL_QUESTIONS' && (
          <>
            <div className="card-header">
              <h4 className="card-title">
                <FormattedMessage id="SUBSCRIPTION.SECTION_TITLES.ADDITIONAL_QUESTIONS" />
                <i
                  className={classNames('fi fi-info-sign', styles.sign)}
                  onClick={() => this.onInfoToggle('additionalQuestionsInfo')}
                />
              </h4>
            </div>

            <InfoComponent open={this.state.additionalQuestionsInfo}>
              <FormattedMessage id="SUBSCRIPTION.SECTION_TITLES.ADDITIONAL_QUESTIONS" tagName="strong" />
              <FormattedMessage id="SUBSCRIPTION.SECTION_DESC.ADDITIONAL_QUESTIONS" tagName="p" />
            </InfoComponent>

            <div className="card-body mb-0">
              <AlertComponent type="light" className="text-left mb-0">
                <FormattedMessage id="SUBSCRIPTION.SECTION_DESC.ADDITIONAL_QUESTIONS_VISIBLE" />
                <FormattedMessage id="SUBSCRIPTION.LABEL.ANSWER_OPTIONS_ADDON" tagName="p" />
              </AlertComponent>
            </div>

            <FieldArray
              component={QuestionsContainer}
              name="questions"
              props={{
                sidebarCollapsed: this.props.sidebarCollapsed,
              }}
            />
          </>
        )}

        <div className="card-footer">
          <ButtonsWrapperComponent>
            {step === 0 && (
              <ButtonComponent
                theme="outline-secondary"
                size="large"
                to={`/subscriptions/${this.props.match.params.type}`}
              >
                <i className="fi fi-go-back-left-arrow" /> <FormattedMessage id="SUBSCRIPTION.BACK_TO_LIST" />
              </ButtonComponent>
            )}
            {step !== 0 && (
              <ButtonComponent theme="outline-secondary" size="large" onClick={() => this.onChangeStep(step - 1)}>
                <i className="fi fi-go-back-left-arrow" /> <FormattedMessage id="CORE.BACK" />
              </ButtonComponent>
            )}
            {!!this.props.subscription && (
              <ButtonComponent
                theme="link"
                size="large"
                to={`/subscriptions/${this.props.match.params.type}/${this.props.subscription.id}`}
                className="ml-3"
              >
                <FormattedMessage id="CORE.CANCEL" />
              </ButtonComponent>
            )}

            {step === steps.length - 1 && (
              <ButtonComponent
                theme="primary"
                size="large"
                loading={this.props.submitting}
                className="ml-auto"
                disabled={!stepsValuesValid[steps[step]]}
              >
                <FormattedMessage id="CORE.SAVE" /> <i className="fi fi-right-arrow-forward" />
              </ButtonComponent>
            )}
            {step !== steps.length - 1 && (
              <MediaQuery minWidth={1200}>
                {matches =>
                  matches ? (
                    <div className={classNames(styles.changeStepBlock, 'ml-auto')}>
                      <HelpLinkContainer />
                      <ButtonComponent
                        theme="primary"
                        size="large"
                        onClick={() => this.onChangeStep(step + 1)}
                        className="ml-auto"
                        disabled={!stepsValuesValid[steps[step]]}
                      >
                        <FormattedMessage id="SUBSCRIPTION.NEXT_STEP" /> <i className="fi fi-right-arrow-forward" />
                      </ButtonComponent>
                    </div>
                  ) : (
                    <ButtonComponent
                      theme="primary"
                      size="large"
                      onClick={() => this.onChangeStep(step + 1)}
                      className="ml-auto"
                      disabled={!stepsValuesValid[steps[step]]}
                    >
                      <FormattedMessage id="SUBSCRIPTION.NEXT_STEP" /> <i className="fi fi-right-arrow-forward" />
                    </ButtonComponent>
                  )
                }
              </MediaQuery>
            )}
          </ButtonsWrapperComponent>
          <MediaQuery maxWidth={1199}>
            <HelpLinkContainer />
          </MediaQuery>
        </div>

        <div className={backdropClassNames} />
      </form>
    );
  }
}
