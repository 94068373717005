import React from 'react';
import { HeaderComponent } from 'app/core';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';

import { CardComponent } from 'app/shared';
import { VenueSelectContainer } from '../venue-select/venue-select.container';
import { REDIRECTS } from 'app/redux/users/users.saga';
import { hasAccess } from 'app/sms-payment/utils/has-access';
// $FlowFixMe
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import styles from 'app/sms-payment/sms-payment-info/sms-payment-info.module.scss';
import type { Props } from './sms-payment-info.types';

export const SmsPaymentInfo = (props: Props) => {
  const { role, intl } = props;
  // swedish translation for url is still missing
  // this will handle if we show url link at all
  // remove once swedish translation exists
  // there is also truthy chain, to protect against tests
  // tests doesnt have intl.messages but the actual implementation does
  const urlTranslationExists = intl && intl.messages && intl.messages['SMS_PAYMENT.SECTION_2.URL'];

  if (!hasAccess(role)) {
    return (
      <Redirect
        to={{
          pathname: role && REDIRECTS[role] ? REDIRECTS[role] : '/',
        }}
      />
    );
  }

  return (
    <div>
      <HeaderComponent breadcrumbs={[{ icon: 'desktop-payment' }]} />
      <div className={styles.howToPayTitle}>
        <FormattedMessage id={'SMS_PAYMENT.HEADER'} tagName="h2" />
      </div>

      <CardComponent>
        <div className="card-header">
          <FormattedMessage id={'SMS_PAYMENT.SECTION_1.TITLE'} tagName="h4" />
        </div>
        <div className={styles.howToPayCardBody}>
          <FormattedMessage
            id={'SMS_PAYMENT.SECTION_1.TEXT'}
            tagName="p"
            values={{
              linebreak: <br />,
            }}
          />
        </div>
        <div className="card-header">
          <FormattedMessage id={'SMS_PAYMENT.SECTION_2.TITLE'} tagName="h4" />
        </div>
        <div className={styles.howToPayCardBody}>
          <FormattedMessage id={'SMS_PAYMENT.SECTION_2.TEXT_1'} tagName="p" />
          {urlTranslationExists && (
            <div className={styles.externalLinkContainer}>
              <a
                href={intl.formatMessage({ id: 'SMS_PAYMENT.SECTION_2.URL' })}
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage({ id: 'SMS_PAYMENT.SECTION_2.LINK_TEXT' })}
              </a>
              <ArrowRight style={{ marginLeft: '7px' }} />
            </div>
          )}
        </div>
        <div className="card-header">
          <FormattedMessage id={'SMS_PAYMENT.SECTION_3.TITLE'} tagName="h4" />
        </div>
        <div className={styles.howToPayCardBody}>
          <FormattedMessage id={'SMS_PAYMENT.SECTION_3.TEXT_1'} tagName="p" />
          <FormattedMessage id={'SMS_PAYMENT.SECTION_3.TEXT_2'} tagName="p" />
        </div>
        <VenueSelectContainer convertIdTo="shortcode" />
      </CardComponent>
    </div>
  );
};
