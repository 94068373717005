import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, change, reset } from 'redux-form';
import { injectIntl } from 'react-intl';
import { get } from 'lodash';

import { withCoreContext } from 'app/core/core.context';

import { BenefitCalculatorComponent } from './benefit-calculator.component';
import { calculateDeductibleAmount } from './benefit-calculator.util';

const mapStateToProps = ({ form }, { fieldName }) => {
  const subscriptionId = get(form, 'edit-subscription.values.id');
  const benefitOptionId = fieldName.split(/[[\]]/)[1];

  return {
    initialValues: {
      role: 1,
    },
    form: `benefit-calculator-${subscriptionId}-${benefitOptionId}`,
  };
};

const mapDispatchToProps = (dispatch, { formName, fieldName, index, onSave }) => ({
  onSubmit: ({ role, amount, multiplier }) =>
    Promise.all([
      dispatch(
        change(
          formName,
          `${fieldName.replace('.benefits', '')}.deductibleAmount`,
          calculateDeductibleAmount(role, amount, multiplier),
        ),
      ),
      dispatch(change(formName, `${fieldName}[${index}].amount`, (amount * multiplier).toFixed(2))),
    ]).then(() => onSave()),
  clearCalculator: form => dispatch(reset(form)),
});

export const BenefitCalculatorContainer = compose(
  injectIntl,
  withCoreContext,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    destroyOnUnmount: false,
  }),
)(BenefitCalculatorComponent);
