// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { HeaderComponent } from 'app/core';
import { UsersListContainer } from 'app/common/users';
import { isBusinessDifferent } from 'app/utils';

import { VenueUsersPlaceholder } from './placeholder/venue-users.placeholder';

import type { Props } from './venue-users.component.types';

export class VenueUsersComponent extends Component<Props> {
  componentDidMount() {
    this.props.fetchDictionary();
    this.props.fetchUsers();
    this.props.fetchVenue();
  }

  componentDidUpdate(prevProps: Props) {
    if (isBusinessDifferent(prevProps, this.props)) {
      this.props.changeVenue(this.props.business);

      this.props.fetchVenue();
      this.props.fetchUsers();
    }
  }

  render() {
    if (this.props.loading) {
      return <VenueUsersPlaceholder />;
    }

    const venue = this.props.venue.venue;

    return (
      <>
        <HeaderComponent
          breadcrumbs={[
            {
              icon: 'business',
              to: '/venues',
            },
            {
              name: venue.name,
              to: '/venues',
            },
            {
              name: <FormattedMessage id="VENUES.USERS" />,
            },
          ]}
        >
          <FormattedMessage id="VENUES.USERS_TITLE" values={{ venue: venue.name }} tagName="h2" />
        </HeaderComponent>

        <UsersListContainer
          userManagementRoles={this.props.userManagementRoles}
          context="venue"
          customer={venue.id}
          users={this.props.users}
        />
      </>
    );
  }
}
