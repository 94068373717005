export const getDefaultRole = (defaultRoles, businesses, permissions) => {
  const currentBusiness = Number(localStorage.getItem('currentBusiness'));
  if (permissions) {
    let permissionRoles = Object.keys(permissions).map(key => ({
      businessId: key,
      defaultRole: Object.keys(permissions[key])[0],
    }));
    if (permissionRoles.length > 0) {
      const businessRoles = permissionRoles.find(e => Number(e.businessId) === currentBusiness);
      return businessRoles ? businessRoles.defaultRole : permissionRoles[0].defaultRole;
    }
  }
  if (defaultRoles) {
    const businessRoles = defaultRoles.find(e => e.businessId === currentBusiness);
    return businessRoles ? businessRoles.defaultRole : defaultRoles[0].defaultRole;
  } else {
    return businesses.length > 0
      ? currentBusiness
        ? businesses.find(e => e.id === currentBusiness).defaultRole
        : businesses[0].defaultRole
      : [];
  }
};
