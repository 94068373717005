import { handleActions } from 'redux-actions';

import * as types from './permissions.action-types';

const defaultState = null;

export const permissionsReducer = handleActions(
  {
    [types.FETCH_PERMISSIONS_SUCCESS]: (state, { payload: { data } }) => data,
    [types.FETCH_PERMISSIONS_FAILURE]: () => ({}),
  },
  defaultState,
);
