import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { withCoreContext } from 'app/core/core.context';

import { fetchSubscription } from 'app/redux/subscriptions/subscriptions.actions';

import { SubscriptionDetailsComponent } from './subscription-details.component';
import { injectIntl } from 'react-intl';

const mapStateToProps = (
  { subscriptions: { entities: subscriptions } },
  {
    match: {
      params: { subscription },
    },
  },
) => ({
  loading: !subscriptions[subscription],
  subscription: subscriptions[subscription],
  totalBeneficiaries: get(subscriptions[subscription], 'summary.beneficiaries.total', 0),
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { subscription },
    },
  },
) => ({
  fetchSubscription: () => dispatch(fetchSubscription(subscription, true)),
});

export const SubscriptionDetailsContainer = compose(
  withCoreContext,
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SubscriptionDetailsComponent);
