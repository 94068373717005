// @flow

import React, { Component } from 'react';

import { MySubscriptionsListTablePlaceholder } from './table/my-subscriptions-list-table.placeholder';
import { MySubscriptionsListTilePlaceholder } from './tile/my-subscriptions-list-tile.placeholder';

import { ListComponent } from 'app/shared/list/list.component';

import type { Props } from './my-subscriptions-list.placeholder.types';

export class MySubscriptionsListPlaceholder extends Component<Props> {
  static defaultProps = {
    subscriptions: [...new Array(5)],
  };

  render() {
    return (
      <ListComponent
        list={this.props.subscriptions}
        table={MySubscriptionsListTablePlaceholder}
        tile={MySubscriptionsListTilePlaceholder}
      />
    );
  }
}
