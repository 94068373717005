import { RSAA } from 'redux-api-middleware';

import * as types from './cards.action-types';

export const fetchCards = () => ({
  [RSAA]: {
    types: [types.FETCH_CARDS_REQUEST, types.FETCH_CARDS_SUCCESS, types.FETCH_CARDS_FAILURE],
    endpoint: '/cards',
    method: 'GET',
  },
});

export const changeCardStatus = (id, status) => ({
  [RSAA]: {
    types: [
      types.CHANGE_CARD_STATUS_REQUEST,
      {
        type: types.CHANGE_CARD_STATUS_SUCCESS,
        meta: {
          id,
          active: status === 'enabled',
        },
      },
      types.CHANGE_CARD_STATUS_FAILURE,
    ],
    endpoint: `/cards/${id}/${status}`,
    method: 'POST',
  },
});

export const fetchActivationCards = () => ({
  [RSAA]: {
    types: [
      types.FETCH_ACTIVATION_CARDS_REQUEST,
      types.FETCH_ACTIVATION_CARDS_SUCCESS,
      types.FETCH_ACTIVATION_CARDS_FAILURE,
    ],
    endpoint: '/venues/cards',
    method: 'GET',
  },
});
