// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { HeaderComponent } from 'app/core';

import { OrdersCreateFormContainer } from './form/orders-create-form.container';
import { OrdersCreateSummaryContainer } from './summary/orders-create-summary.container';

import type { Props, State, BodyProps } from './orders-create.component.types';

export class OrdersCreateComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      step: 'FORM',
    };
  }

  componentDidMount() {
    this.props.fetchAccounts();
    this.props.fetchCustomer(this.props.business);
    this.props.fetchDictionary();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.business !== this.props.business) {
      this.props.fetchCustomer(this.props.business);
    }
  }

  onSubmit = (body: BodyProps) =>
    this.props.onSubmit(body).then(order => this.setState({ order: order.payload.data.id, step: 'SUMMARY' }));

  onConfirm = () => this.props.onConfirm(this.state.order).then(this.props.onConfirmSuccess);

  render() {
    return (
      <>
        <HeaderComponent
          breadcrumbs={[
            {
              icon: 'discount-voucher',
            },
          ]}
        >
          <FormattedMessage id="ORDERS.CREATE_ORDER_TITLE" tagName="h2" />
        </HeaderComponent>

        {this.state.step === 'FORM' && (
          <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <OrdersCreateFormContainer submitting={this.props.submitting} />
          </form>
        )}

        {this.state.step === 'SUMMARY' && (
          <form onSubmit={this.props.handleSubmit(this.onConfirm)}>
            <OrdersCreateSummaryContainer
              cancelPath="/accounts/company"
              terms="saldo"
              order={this.state.order}
              submitting={this.props.submitting}
              onBack={() => this.setState({ step: 'FORM' })}
            />
          </form>
        )}
      </>
    );
  }
}
