// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { PlaceholderComponent, TileComponent } from 'app/shared';

export class MySubscriptionsListTilePlaceholder extends Component<any> {
  render() {
    return (
      <TileComponent>
        <h3>
          <PlaceholderComponent width={120} height={27} />
        </h3>
        <div className="row">
          <div className="col-md-6 mb-2">
            <FormattedMessage id="MY_SUBSCRIPTIONS.LIST_HEADERS.LIST" tagName="small" />
            <PlaceholderComponent width={150} />
          </div>
          <div className="col-md-6 mb-2">
            <FormattedMessage id="MY_SUBSCRIPTIONS.LIST_HEADERS.BENEFITS" tagName="small" />
            <PlaceholderComponent width={150} />
          </div>
          <div className="col-md-6 mb-2">
            <FormattedMessage id="MY_SUBSCRIPTIONS.LIST_HEADERS.DATE" tagName="small" />
            <PlaceholderComponent width={150} />
          </div>
        </div>
      </TileComponent>
    );
  }
}
