// @flow

import React, { PureComponent } from 'react';
import classNames from 'classnames';

import type { Props } from './alert.component.types';
import styles from './alert.module.scss';

export class AlertComponent extends PureComponent<Props> {
  render() {
    const alertClassNames = classNames('alert', `alert-${this.props.type}`, styles.alert, this.props.className);

    return <div className={alertClassNames}>{this.props.children}</div>;
  }
}
