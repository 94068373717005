// @flow

import React, { Component } from 'react';
import { Field, FormSection } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { upperFirst } from 'lodash';

import { HeaderComponent } from 'app/core';
import {
  AlertComponent,
  ButtonComponent,
  CardComponent,
  ConnectedInputComponent,
  ConnectedPhoneNumberInputComponent,
} from 'app/shared';
import { AddressSectionContainer, PersonalSectionContainer } from 'app/register/shared';

import type { Props, State } from './register-company-form.component.types';

export class RegisterCompanyFormComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isBillingAddressOpen: false,
      isDeliveryAddressOpen: false,
    };
  }

  onBillingAddressToggle = () =>
    this.setState(prev => ({
      isBillingAddressOpen: !prev.isBillingAddressOpen,
    }));

  onDeliveryAddressToggle = () =>
    this.setState(prev => ({
      isDeliveryAddressOpen: !prev.isDeliveryAddressOpen,
    }));

  render() {
    const { isBillingAddressOpen, isDeliveryAddressOpen } = this.state;

    return (
      <section>
        <HeaderComponent
          breadcrumbs={[
            {
              icon: 'home-icon-silhouette',
            },
            {
              name: <FormattedMessage id="REGISTER.TITLE" />,
            },
          ]}
        >
          <FormattedMessage id="REGISTER.TITLE" tagName="h2" />
          <FormattedMessage id="REGISTER.DESCRIPTION" tagName="p" />
        </HeaderComponent>
        <CardComponent>
          <div className="card-header">
            <AlertComponent type="light" className="text-left">
              <FormattedMessage id="REGISTER.INSTRUCTION_BOX.COMPANY_DETAILS" tagName="p" />
            </AlertComponent>
            <h4 className="card-title">
              <FormattedMessage id="REGISTER.SECTION_TITLES.BASIC_INFORMATION" />
            </h4>
          </div>
          <FormSection name="company">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6">
                  <Field
                    name="name"
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'REGISTER.COMPANY_NAME' })}
                    placeholder={this.props.intl.formatMessage({ id: 'REGISTER.COMPANY_NAME' })}
                    required
                    normalize={upperFirst}
                  />
                </div>
                <div className="col-lg-6">
                  <Field
                    name="nameExtra"
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'REGISTER.COMPANY_NAME_SECOND' })}
                    placeholder={this.props.intl.formatMessage({ id: 'REGISTER.COMPANY_NAME_SECOND' })}
                    normalize={upperFirst}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <Field
                    name="businessId"
                    required
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'REGISTER.BUSINESS_ID' })}
                    placeholder={this.props.intl.formatMessage({ id: 'REGISTER.BUSINESS_ID' })}
                  />
                </div>
                <div className="col-lg-6">
                  <Field
                    name="phone"
                    required
                    component={ConnectedPhoneNumberInputComponent}
                    label={this.props.intl.formatMessage({ id: 'REGISTER.PHONE' })}
                    placeholder={this.props.intl.formatMessage({ id: 'REGISTER.PHONE' })}
                  />
                </div>
              </div>
            </div>
            <AddressSectionContainer name="officialAddress" title="REGISTER.SECTION_TITLES.OFFICIAL_ADDRESS" />
            <AddressSectionContainer
              name="billingAddress"
              onToggle={this.onBillingAddressToggle}
              open={isBillingAddressOpen}
              optional
              title="REGISTER.SECTION_TITLES.BILLING_ADDRESS"
              withAddressee
            />
            <AddressSectionContainer
              name="deliveryAddress"
              onToggle={this.onDeliveryAddressToggle}
              open={isDeliveryAddressOpen}
              optional
              title="REGISTER.SECTION_TITLES.DELIVERY_ADDRESS"
            />
          </FormSection>
          <PersonalSectionContainer name="personal">
            <h4 className="card-title">
              <FormattedMessage id="REGISTER.SECTION_TITLES.PERSONAL_INFORMATION" />
            </h4>
            <AlertComponent type="light" className="text-left mt-4 mb-0">
              <FormattedMessage id="REGISTER.INSTRUCTION_BOX.PERSONAL_INFORMATION_INFO" tagName="p" />
            </AlertComponent>
          </PersonalSectionContainer>
          <div className="card-footer text-right">
            <ButtonComponent theme="primary" size="large" loading={this.props.submitting}>
              <FormattedMessage id="CORE.NEXT" /> <i className="fi fi-right-arrow-forward" />
            </ButtonComponent>
          </div>
        </CardComponent>
      </section>
    );
  }
}
