// @flow

import React, { Component } from 'react';
import { Field, FieldArray } from 'redux-form';

import { AlertComponent, ButtonComponent, ConnectedInputComponent, ModalComponent } from 'app/shared';

import type { FieldArrayProps } from 'redux-form/es/index';
import type { Props } from './subscription-beneficiaries-modify.component.types';

export class SubscriptionBeneficiariesModifyComponent extends Component<Props> {
  benefitsRenderer = (({ fields }: FieldArrayProps) => (
    <>
      {fields.map((field, index) => (
        <div className="col-lg-6" key={index}>
          <Field
            name={`${field}.amount`}
            component={ConnectedInputComponent}
            label={this.props.intl.formatMessage({ id: `PRODUCT.${this.props.benefitOptions[index]}` })}
            icon="euro-symbol"
          />
        </div>
      ))}
    </>
  ): Function);

  render() {
    return (
      <>
        <ModalComponent
          title={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.BENEFICIARIES.MODIFY_ALL' })}
          subtitle={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.BENEFICIARIES.MODIFY_ALL_INFO' })}
          onClose={this.props.onClose}
          form={{ onSubmit: this.props.handleSubmit }}
          submitButton={
            <ButtonComponent theme="primary" size="medium" loading={this.props.submitting}>
              {this.props.intl.formatMessage({ id: 'CORE.SAVE' })}
            </ButtonComponent>
          }
        >
          <AlertComponent type="secondary">
            <p>{this.props.intl.formatMessage({ id: 'SUBSCRIPTION.BENEFICIARIES.ATTENTION' })}</p>
          </AlertComponent>

          <div className="row">
            <FieldArray name="benefits" component={this.benefitsRenderer} />
            <div className="col-lg-6">
              <Field
                name="deductibleAmount"
                component={ConnectedInputComponent}
                label={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.BENEFICIARIES.EXCESS' })}
                icon="euro-symbol"
              />
            </div>
          </div>
        </ModalComponent>
      </>
    );
  }
}
