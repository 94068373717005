import { RSAA } from 'redux-api-middleware';

import * as types from './yearly-limits.action-types';

export const fetchYearlyLimits = customer => ({
  [RSAA]: {
    types: [types.FETCH_YEARLY_LIMITS_REQUEST, types.FETCH_YEARLY_LIMITS_SUCCESS, types.FETCH_YEARLY_LIMITS_FAILURE],
    endpoint: `/customers/${customer}/yearly-limit`,
    method: 'GET',
  },
});

export const updateYearlyLimits = (customer, body) => ({
  [RSAA]: {
    types: [types.UPDATE_YEARLY_LIMITS_REQUEST, types.UPDATE_YEARLY_LIMITS_SUCCESS, types.UPDATE_YEARLY_LIMITS_FAILURE],
    endpoint: `/customers/${customer}/yearly-limit`,
    method: 'PATCH',
    body,
  },
});
