import { handleActions } from 'redux-actions';

import * as types from './yearly-limits.action-types';

const defaultState = {
  entities: [],
};

export const yearlyLimitsReducer = handleActions(
  {
    [types.FETCH_YEARLY_LIMITS_REQUEST]: state => ({
      ...state,
      entities: [],
    }),
    [types.FETCH_YEARLY_LIMITS_SUCCESS]: (state, { payload: { data } }) => ({
      ...state,
      entities: data,
    }),
  },
  defaultState,
);
