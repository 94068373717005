// @flow

import React, { Component } from 'react';
import { Field } from 'redux-form';
import { Redirect } from 'react-router-dom';

import { ButtonComponent, ConnectedCheckboxesComponent, ModalComponent } from 'app/shared';
import { displayInfoTextForSuitableRole } from 'app/utils/display-info-text-for-suitable-role/display-info-text-for-suitable-role.util';
import { USER_ROLES } from 'app/constants';

import type { BodyType, Props, State } from './users-edit-roles.component.types';

export class UsersEditRolesComponent extends Component<Props, State> {
  state = {
    firstName: this.props.user.firstName,
    lastName: this.props.user.lastName,
  };

  onSubmit = ({ roles }: BodyType) =>
    this.props.onSubmit(this.props.roles, roles).then(() => `${this.state.firstName} ${this.state.lastName}`);

  render() {
    if (this.props.initialValues.roles[0] === USER_ROLES.ROLE_VENUE_ACCOUNTANT) {
      return <Redirect to={'/'} />;
    }

    return (
      <ModalComponent
        title={this.props.intl.formatMessage({ id: 'VENUES.EDIT_ROLES' })}
        onClose={this.props.onClose}
        form={{ onSubmit: this.props.handleSubmit(this.onSubmit) }}
        submitButton={
          <ButtonComponent theme="primary" size="medium" loading={this.props.submitting}>
            {this.props.intl.formatMessage({ id: 'CORE.SAVE' })}
          </ButtonComponent>
        }
      >
        <Field
          name="roles"
          theme="solid"
          options={this.props.roles}
          component={ConnectedCheckboxesComponent}
          label={this.props.intl.formatMessage({ id: 'VENUES.ROLES' })}
          disableEdit
          displayInfoText={role => displayInfoTextForSuitableRole(role, this.props.intl.formatMessage)}
        />
      </ModalComponent>
    );
  }
}
