import { fork, select, takeEvery } from 'redux-saga/effects';
import { getFormValues } from 'redux-form';
import URLSearchParams from '@ungap/url-search-params';

import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

import { FETCH_DOWNLOAD_TOKEN_SUCCESS } from './downloads.action-types';
import { API_URL } from 'app/constants';

export function* download({
  meta: { filters, locale, path },
  payload: {
    data: { token },
  },
}) {
  const searchParams = new URLSearchParams();

  searchParams.append('token', token);
  searchParams.append('lang', locale);

  if (filters) {
    const params = isObject(filters) ? filters : yield select(getFormValues(filters));

    if (params) {
      Object.keys(params)
        .filter(key => !!params[key])
        .forEach(key => {
          if (isArray(params[key])) {
            params[key].forEach(inner => searchParams.append(`${key}[]`, inner));
          } else {
            searchParams.append(key, params[key]);
          }
        });
    }
  }

  yield window.location.assign(`${API_URL}/downloads/${path}?${searchParams}`);
}

export function* downloadsSaga() {
  yield fork(takeEvery, FETCH_DOWNLOAD_TOKEN_SUCCESS, download);
}
