import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { BenefitsComponent } from './benefits.component';

const mapStateToProps = ({ dictionaries, users }) => ({
  industries: dictionaries.services.industries,
  users,
});

export const BenefitsContainer = compose(
  injectIntl,
  connect(mapStateToProps),
)(BenefitsComponent);
