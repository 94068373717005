import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';

import { OrdersCreateFormComponent } from './orders-create-form.component';

const mapStateToProps = ({
  customers: { entities: customers },
  dictionaries: { 'products/emoney': products },
  users: { business },
}) => ({
  products: (products || { products: null }).products,
  loading: !products || !customers[business],
  type: 'company',
});

const mapDispatchToProps = dispatch => ({
  fetchDictionary: () => dispatch(fetchDictionary('products/emoney')),
  onBack: () => dispatch(push('/accounts/company')),
  onCancel: () => dispatch(push('/accounts/company')),
});

export const OrdersCreateFormContainer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(OrdersCreateFormComponent);
