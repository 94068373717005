import GoogleAnalytics from 'react-ga';
import { fork, put, race, select, take, takeEvery } from 'redux-saga/effects';
import { goBack, getLocation, push } from 'react-router-redux';
import { stopSubmit } from 'redux-form';
import { getDefaultRole } from 'app/utils';

import get from 'lodash/get';

import {
  FETCH_CURRENT_USER_FAILURE,
  FETCH_CURRENT_USER_SUCCESS,
  LOGIN_SUCCESS,
  LOGIN_WITH_TOKEN_SUCCESS,
  LOGOUT_REQUEST,
  REFRESH_TOKEN_FAILURE,
  REFRESH_TOKEN_SUCCESS,
  CHANGE_BUSINESS,
  CHANGE_ROLE,
  APPROVE_TERMS_SUCCESS,
} from './users.action-types';
import { fetchCurrentUser, fetchContractData } from './users.actions';
import { fetchDictionary } from '../dictionaries/dictionaries.actions';

export const REDIRECTS = {
  ROLE_COMPANY_BENEFICIARY: '/accounts',
  ROLE_COMPANY_BUYER: '/subscriptions/emoney',
  ROLE_SERVICE_PROVIDER_MANAGER: '/venues',
  ROLE_VENUE_MANAGER: '/transactions/company',
  ROLE_MUNICIPAL_VENUE_MANAGER: '/coupons/charge',
  ROLE_VENUE_CASHIER: '/transactions/company',
  ROLE_MUNICIPAL_CONTRACT_MANAGER: '/coupons',
  ROLE_MUNICIPAL_FINANCIAL_MANAGER: '/coupons',
  ROLE_MUNICIPAL_BUYER: '/coupons',
  ROLE_MUNICIPAL_DISTRIBUTOR: '/coupons',
};

export function* afterUserLogin(action) {
  try {
    yield localStorage.setItem('accessToken', action.payload.data.accessToken);
    yield localStorage.setItem('refreshToken', action.payload.data.refreshToken);
    yield localStorage.removeItem('currentBusiness');
  } catch (err) {}

  yield put(fetchCurrentUser());

  const { success, failure } = yield race({
    success: take(FETCH_CURRENT_USER_SUCCESS),
    failure: take(FETCH_CURRENT_USER_FAILURE),
  });

  if (success) {
    const {
      payload: {
        defaultRoles,
        data: { terms = [], businesses },
      },
    } = success;

    const defaultRole = getDefaultRole(defaultRoles, businesses);

    const unapproved = terms.find(term => !term.approved && term.required);

    if (!unapproved) {
      const location = yield select(getLocation);

      if (get(location, 'state.back')) {
        yield put(goBack());
      } else if (REDIRECTS[defaultRole]) {
        yield put(push(REDIRECTS[defaultRole]));
      } else {
        yield put(push('/profile'));
      }
    }
  } else if (failure) {
    yield put(
      stopSubmit('login', {
        username: 'VALIDATION.INVALID_USERNAME_PASSWORD_COMBINATION',
        password: 'VALIDATION.INVALID_USERNAME_PASSWORD_COMBINATION',
      }),
    );
  }
}

export function* afterFetchCurrentUserSuccess({
  payload: {
    data: {
      userId,
      personal: { email, firstName, lastName },
    },
  },
}) {
  if (window.Intercom) {
    const { business, businesses } = yield select(getUserBusinesses);
    const { role } = yield select(getUserDefaultRole);
    const selectedBusiness = businesses.find(b => b.id === business);

    yield window.Intercom('update', {
      user_id: userId,
      name: `${firstName} ${lastName}`,
      email: email,
      customer_role: role,
      company: {
        company_id: selectedBusiness.id,
        name: selectedBusiness.name,
      },
    });
  }
  if (process.env.REACT_APP_GA) {
    yield GoogleAnalytics.set({ userId });
  }
}

export function* afterUserLogoutRequest({ meta: { redirect, state } }) {
  try {
    yield localStorage.removeItem('accessToken');
    yield localStorage.removeItem('refreshToken');
    yield localStorage.removeItem('currentBusiness');
    yield localStorage.removeItem('sidebarCollapsed');

    if (window.Intercom) {
      yield window.Intercom('shutdown');
      yield window.Intercom('boot');
    }

    if (redirect) {
      yield put(
        push({
          state,
          pathname: '/login',
        }),
      );
    }
  } catch (err) {}
}

export function* afterTokenRefreshSuccess(action) {
  try {
    yield localStorage.setItem('accessToken', action.payload.data.accessToken);
    yield localStorage.setItem('refreshToken', action.payload.data.refreshToken);
  } catch (err) {}
}

export function* afterTokenRefreshFailure() {
  try {
    yield localStorage.removeItem('accessToken');
    yield localStorage.removeItem('refreshToken');
    yield localStorage.removeItem('currentBusiness');
    yield localStorage.removeItem('sidebarCollapsed');

    yield put(
      push({
        pathname: '/login',
      }),
    );
  } catch (err) {}
}

const getUserDefaultRole = ({ users: { role } }) => ({ role });

export function* defaultRoleRedirect() {
  const { role } = yield select(getUserDefaultRole);

  if (window.Intercom) {
    const { business, businesses } = yield select(getUserBusinesses);
    const selectedBusiness = businesses.find(b => b.id === business);

    yield window.Intercom('update', {
      customer_role: role,
      company: {
        company_id: selectedBusiness.id,
        name: selectedBusiness.name,
      },
    });
  }

  if (REDIRECTS[role]) {
    yield put(push(REDIRECTS[role]));
  } else {
    yield put(push('/profile'));
  }
}

const getUserBusinesses = ({
  users: {
    business,
    current: { businesses },
  },
}) => ({ business, businesses });

export function* defaultFetchContractData() {
  const { business, businesses } = yield select(getUserBusinesses);
  const { role } = yield select(getUserDefaultRole);
  const businessId = business || get(businesses[0], 'id', null);
  if (businessId && role === 'ROLE_SERVICE_PROVIDER_MANAGER') yield put(fetchContractData(businessId));
}

export function* getInvoicingOptions() {
  const { business, businesses } = yield select(getUserBusinesses);
  const businessType = businesses.find(b => b.id === business).type;

  if (businessType === 'TYPE_COMPANY') yield put(fetchDictionary('invoicing-options', false, true));
}

export function* usersSaga() {
  yield fork(takeEvery, [LOGIN_SUCCESS, LOGIN_WITH_TOKEN_SUCCESS], afterUserLogin);
  yield fork(takeEvery, FETCH_CURRENT_USER_SUCCESS, afterFetchCurrentUserSuccess);
  yield fork(takeEvery, REFRESH_TOKEN_SUCCESS, afterTokenRefreshSuccess);
  yield fork(takeEvery, REFRESH_TOKEN_FAILURE, afterTokenRefreshFailure);
  yield fork(takeEvery, LOGOUT_REQUEST, afterUserLogoutRequest);
  yield fork(takeEvery, CHANGE_BUSINESS, defaultRoleRedirect);
  yield fork(takeEvery, CHANGE_BUSINESS, getInvoicingOptions);
  yield fork(takeEvery, [FETCH_CURRENT_USER_SUCCESS, CHANGE_BUSINESS, APPROVE_TERMS_SUCCESS], defaultFetchContractData);
  yield fork(takeEvery, CHANGE_ROLE, defaultRoleRedirect);
}
