import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import { withCoreContext } from 'app/core/core.context';

import { BanknotesOrderPlaceholder } from './banknotes-order.placeholder';

export const BanknotesOrderPlaceholderContainer = compose(
  injectIntl,
  withCoreContext,
)(BanknotesOrderPlaceholder);
