// @flow

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { MySubscriptionsContainer } from 'app/subscriptions/my/my-subscriptions.container';

import type { Props } from './beneficiary.component.types';

export class BeneficiaryComponent extends Component<Props> {
  render() {
    return (
      <Switch>
        <Route path={`${this.props.match.url}/subscriptions`} component={MySubscriptionsContainer} />
      </Switch>
    );
  }
}
