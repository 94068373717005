// @flow

import React, { PureComponent } from 'react';
import classNames from 'classnames';

import type { Props } from './card.component.types';
import styles from './card.module.scss';

export class CardComponent extends PureComponent<Props> {
  static defaultProps = {
    header: true,
  };

  render() {
    return (
      <div
        className={classNames('card', styles.card, this.props.className, {
          [styles['no-header']]: !this.props.header,
        })}
      >
        {this.props.children}
      </div>
    );
  }
}
