export const FETCH_CONTRACTS_REQUEST = 'contracts/FETCH_CONTRACTS_REQUEST';
export const FETCH_CONTRACTS_SUCCESS = 'contracts/FETCH_CONTRACTS_SUCCESS';
export const FETCH_CONTRACTS_FAILURE = 'contracts/FETCH_CONTRACTS_FAILURE';

export const CREATE_CONTRACT_REQUEST = 'contracts/CREATE_CONTRACT_REQUEST';
export const CREATE_CONTRACT_SUCCESS = 'contracts/CREATE_CONTRACT_SUCCESS';
export const CREATE_CONTRACT_FAILURE = 'contracts/CREATE_CONTRACT_FAILURE';

export const EDIT_CONTRACT_REQUEST = 'contracts/EDIT_CONTRACT_REQUEST';
export const EDIT_CONTRACT_SUCCESS = 'contracts/EDIT_CONTRACT_SUCCESS';
export const EDIT_CONTRACT_FAILURE = 'contracts/EDIT_CONTRACT_FAILURE';

export const TERMINATE_CONTRACT_REQUEST = 'contracts/TERMINATE_CONTRACT_REQUEST';
export const TERMINATE_CONTRACT_SUCCESS = 'contracts/TERMINATE_CONTRACT_SUCCESS';
export const TERMINATE_CONTRACT_FAILURE = 'contracts/TERMINATE_CONTRACT_FAILURE';

export const CREATE_CONTRACT_BATCH_REQUEST = 'contracts/CREATE_CONTRACT_BATCH_REQUEST';
export const CREATE_CONTRACT_BATCH_SUCCESS = 'contracts/CREATE_CONTRACT_BATCH_SUCCESS';
export const CREATE_CONTRACT_BATCH_FAILURE = 'contracts/CREATE_CONTRACT_BATCH_FAILURE';
