import { RSAA } from 'redux-api-middleware';

import * as types from './customers.action-types';

export const fetchCustomer = customer => ({
  [RSAA]: {
    types: [
      {
        type: types.FETCH_CUSTOMER_REQUEST,
        meta: { customer },
      },
      types.FETCH_CUSTOMER_SUCCESS,
      types.FETCH_CUSTOMER_FAILURE,
    ],
    endpoint: `/customers/${customer}`,
    method: 'GET',
  },
});

export const fetchCurrentCustomer = () => ({
  [RSAA]: {
    types: [
      types.FETCH_CURRENT_CUSTOMER_REQUEST,
      types.FETCH_CURRENT_CUSTOMER_SUCCESS,
      types.FETCH_CURRENT_CUSTOMER_FAILURE,
    ],
    endpoint: '/customers/business',
    method: 'GET',
  },
});

export const updateCurrentCustomer = body => ({
  [RSAA]: {
    types: [
      types.UPDATE_CURRENT_CUSTOMER_REQUEST,
      types.UPDATE_CURRENT_CUSTOMER_SUCCESS,
      types.UPDATE_CURRENT_CUSTOMER_FAILURE,
    ],
    endpoint: '/customers/business',
    method: 'PATCH',
    body,
  },
});

export const terminateContract = (customerId, body) => ({
  [RSAA]: {
    types: [types.TERMINATE_CONTRACT_REQUEST, types.TERMINATE_CONTRACT_SUCCESS, types.TERMINATE_CONTRACT_FAILURE],
    endpoint: `/customers/${customerId}`,
    method: 'PATCH',
    body,
  },
});
