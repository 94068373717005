// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { SplashComponent } from 'app/core';
import { RegisterSuccessComponent } from 'app/register/shared';
import { ExternalLinkComponent } from 'app/shared';

import { RegisterServiceFormContainer } from './form/register-service-form.container';
import { RegisterServiceSummaryContainer } from './summary/register-service-summary.container';

import type { BodyType, Props, State } from './register-service.component.types';

export class RegisterServiceComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      step: 'FORM',
    };
  }

  componentDidMount() {
    this.props.fetchServiceProviderDictionary();
  }

  changeStep = (step: string) => this.setState({ step }, () => window.scrollTo(0, 0));

  transformFormBody = (body: BodyType) => {
    const { eInvoiceAddress, eInvoiceIdentifier, invoiceEmail, invoicingMethod } = body.billing;
    return {
      ...body,
      billing: invoicingMethod
        ? {
            eInvoiceAddress,
            eInvoiceIdentifier,
            invoicingMethod,
          }
        : {
            invoiceEmail,
            invoicingMethod,
          },
    };
  };

  onSubmit = (body: BodyType) =>
    this.props.onValidate(this.transformFormBody(body)).then(() => this.changeStep('SUMMARY'));

  onConfirm = (body: BodyType) =>
    this.props
      .onSubmit(this.transformFormBody(body))
      .then(() => this.changeStep('SUCCESS'))
      .then(() => this.props.reset());

  render() {
    if (this.props.loading) {
      return <SplashComponent />;
    }

    return (
      <>
        {this.state.step === 'FORM' && (
          <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <RegisterServiceFormContainer
              pathname={this.props.location.pathname}
              submitting={this.props.submitting}
              isValid={this.props.valid}
            />
          </form>
        )}
        {this.state.step === 'SUMMARY' && (
          <form onSubmit={this.props.handleSubmit(this.onConfirm)}>
            <RegisterServiceSummaryContainer submitting={this.props.submitting} changeStep={this.changeStep} />
          </form>
        )}
        {this.state.step === 'SUCCESS' && (
          <RegisterSuccessComponent title="REGISTER.SUCCESS_SERVICE_TITLE">
            <FormattedMessage
              id="REGISTER.SUCCESS_SERVICE_DESCRIPTION"
              values={{
                link1: (
                  <ExternalLinkComponent
                    target="_blank"
                    href={this.props.intl.formatMessage({ id: 'REGISTER.SUCCESS_SERVICE_DESCRIPTION_LINK_1.URL' })}
                  >
                    <FormattedMessage id="REGISTER.SUCCESS_SERVICE_DESCRIPTION_LINK_1.TEXT" />
                  </ExternalLinkComponent>
                ),
                link2: (
                  <ExternalLinkComponent
                    target="_blank"
                    href={this.props.intl.formatMessage({ id: 'REGISTER.SUCCESS_SERVICE_DESCRIPTION_LINK_2.URL' })}
                  >
                    <FormattedMessage id="REGISTER.SUCCESS_SERVICE_DESCRIPTION_LINK_2.TEXT" />
                  </ExternalLinkComponent>
                ),
              }}
            />
          </RegisterSuccessComponent>
        )}
      </>
    );
  }
}
