// @flow

import React, { PureComponent } from 'react';

import { HeaderComponent } from 'app/core';
import { UsersListContainer } from 'app/common/users';
import { PlaceholderComponent } from 'app/shared';

import type { Props } from './users.placeholder.types';

export class UsersPlaceholder extends PureComponent<Props> {
  static defaultProps = {
    users: [...new Array(5)],
  };

  render() {
    return (
      <>
        <HeaderComponent breadcrumbs={[{ icon: 'multiple-users-silhouette' }]}>
          <h2>
            <PlaceholderComponent width={200} height={30} color="purple" />
          </h2>
        </HeaderComponent>

        <UsersListContainer loading={true} />
      </>
    );
  }
}
