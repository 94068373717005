// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { HeaderComponent } from 'app/core';
import { CardComponent } from 'app/shared';
import { SummaryFieldComponent, SummaryButtonsComponent } from 'app/register/shared';
import { BenefitContainer } from 'app/common/venues/benefit/benefit.container';
import { AgreementsAcceptanceContainer } from 'app/agreements/acceptance/agreements-acceptance.container';

import type { Props, State } from './register-service-summary.component.types';

export class RegisterServiceSummaryComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      terms: {
        onChange: () => this.setState(prev => ({ terms: { ...prev.terms, value: !prev.terms.value } })),
        value: false,
      },
    };
  }
  renderVenueAddressFields = () => {
    const { venue } = this.props;

    return (
      <>
        {venue.address.address && (
          <SummaryFieldComponent className="col-lg-6">
            <FormattedMessage id="REGISTER.VENUE.STREET_ADDRESS" tagName="h3" />
            <h4>{venue.address.address}</h4>
          </SummaryFieldComponent>
        )}
        {venue.address.zipCode && (
          <SummaryFieldComponent className="col-lg-6">
            <FormattedMessage id="REGISTER.VENUE.ZIP_CODE" tagName="h3" />
            <h4>{venue.address.zipCode}</h4>
          </SummaryFieldComponent>
        )}
        {venue.address.city && (
          <SummaryFieldComponent className="col-lg-6">
            <FormattedMessage id="REGISTER.VENUE.CITY" tagName="h3" />
            <h4>{venue.address.city}</h4>
          </SummaryFieldComponent>
        )}
      </>
    );
  };
  getPayments = () =>
    this.props.acceptedPayments
      .reduce((prev, next) => {
        const payment = this.props.payments.find(el => el.id === next);
        if (payment) {
          const id = payment.name;
          return [...prev, this.props.intl.formatMessage({ id: `PAYMENT.${id}.NAME` })];
        } else {
          return prev;
        }
      }, [])
      .join(', ');

  getServices = () =>
    this.props.services
      .reduce((prev, next) => {
        const service = this.props.servicesDictionary[this.props.benefit].find(el => el.id === next);

        if (service) {
          return [...prev, service.name];
        } else {
          return prev;
        }
      }, [])
      .join(', ');

  render() {
    const {
      acceptedPayments,
      bankAccount,
      benefit,
      company,
      changeStep,
      responsiblePerson,
      services,
      submitting,
      venue,
      settlement,
    } = this.props;

    return (
      <section>
        <HeaderComponent
          breadcrumbs={[
            {
              icon: 'home-icon-silhouette',
            },
            {
              name: <FormattedMessage id="REGISTER.TITLE" />,
              onClick: () => changeStep('FORM'),
            },
            {
              name: <FormattedMessage id="REGISTER.SUMMARY" />,
            },
          ]}
        >
          <FormattedMessage id="REGISTER.SUMMARY_TITLE" tagName="h2" />
        </HeaderComponent>
        <CardComponent>
          <div className="card-header">
            <h4 className="card-title">
              <FormattedMessage id="REGISTER.SECTION_TITLES.EMPLOYEE_BENEFITS" />
            </h4>
          </div>
          <div className="card-body">
            <BenefitContainer selected={benefit} />
          </div>
          <div className="card-header card-header--between">
            <h4 className="card-title">
              <FormattedMessage id="REGISTER.SECTION_TITLES.GENERAL_COMPANY" />
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="REGISTER.OFFICIAL_NAME" tagName="h3" />
                <h4>{company.name}</h4>
              </SummaryFieldComponent>
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="REGISTER.BUSINESS_ID" tagName="h3" />
                <h4>{company.businessId}</h4>
              </SummaryFieldComponent>
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="REGISTER.ADDRESS" tagName="h3" />
                <h4>{`${company.address.city}, ${company.address.address}, ${company.address.zipCode}`}</h4>
              </SummaryFieldComponent>
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="REGISTER.PHONE" tagName="h3" />
                <h4>{company.phone}</h4>
              </SummaryFieldComponent>
              {company.maraNum && (
                <SummaryFieldComponent className="col-lg-6">
                  <FormattedMessage id="REGISTER.MARA_NUM" tagName="h3" />
                </SummaryFieldComponent>
              )}
            </div>
          </div>
          <div className="card-header card-header--between">
            <h4 className="card-title">
              <FormattedMessage id="REGISTER.SECTION_TITLES.BANK_ACCOUNT" />
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              {bankAccount && (
                <>
                  <SummaryFieldComponent className="col-lg-6">
                    <FormattedMessage id="REGISTER.ACCOUNT_NUMBER" tagName="h3" />
                    <h4>{bankAccount.iban}</h4>
                  </SummaryFieldComponent>
                  <SummaryFieldComponent className="col-lg-6">
                    <FormattedMessage id="REGISTER.SWIFT" tagName="h3" />
                    <h4>{bankAccount.swift}</h4>
                  </SummaryFieldComponent>
                </>
              )}
            </div>
          </div>
          <div className="card-header card-header--between">
            <h4 className="card-title">
              <FormattedMessage id="REGISTER.SECTION_TITLES.PERSON_RESPONSIBLE" />
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="PROFILE.FIRST_NAME" tagName="h3" />
                <h4>{responsiblePerson.firstName}</h4>
              </SummaryFieldComponent>
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="PROFILE.LAST_NAME" tagName="h3" />
                <h4>{responsiblePerson.lastName}</h4>
              </SummaryFieldComponent>
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="PROFILE.MOBILE_PHONE" tagName="h3" />
                <h4>{responsiblePerson.mobilePhone}</h4>
              </SummaryFieldComponent>
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="PROFILE.EMAIL" tagName="h3" />
                <h4>{responsiblePerson.email}</h4>
              </SummaryFieldComponent>
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="PROFILE.WORK_PHONE" tagName="h3" />
                <h4>{responsiblePerson.workPhone || '-'}</h4>
              </SummaryFieldComponent>
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="REGISTER.MARKETING" tagName="h3" />
                <h4>
                  {responsiblePerson.marketing ? (
                    <FormattedMessage id="REGISTER.SELECTED_MARKETING" />
                  ) : (
                    <FormattedMessage id="REGISTER.NOT_SELECTED_MARKETING" />
                  )}
                </h4>
              </SummaryFieldComponent>
            </div>
          </div>
          <div className="card-header card-header--between card-header--with-line card-header--with-icon">
            <i className="fi fi-business" />
            <h4 className="card-title">
              <FormattedMessage id="REGISTER.SECTION_TITLES.VENUE_GENERAL" />
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="REGISTER.VENUE.NAME" tagName="h3" />
                <h4>{venue.name}</h4>
              </SummaryFieldComponent>
              {venue.address && this.renderVenueAddressFields()}

              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="REGISTER.VENUE.WEBSITE_ADDRESS" tagName="h3" />
                <h4>{venue.website || '-'}</h4>
              </SummaryFieldComponent>
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="REGISTER.VENUE.EMAIL" tagName="h3" />
                <h4>{venue.email || '-'}</h4>
              </SummaryFieldComponent>
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="REGISTER.VENUE.PHONE" tagName="h3" />
                <h4>{venue.phone || '-'}</h4>
              </SummaryFieldComponent>
              <SummaryFieldComponent className="col-lg-12">
                <FormattedMessage id="REGISTER.VENUE.DESCRIPTION" tagName="h3" />
                <h4>{venue.description || '-'}</h4>
              </SummaryFieldComponent>
            </div>
          </div>
          <div className="card-header card-header--between">
            <h4 className="card-title">
              <FormattedMessage id="REGISTER.SECTION_TITLES.DELIVERY_ADDRESS" />
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              <SummaryFieldComponent className="col-lg-12">
                <FormattedMessage id="REGISTER.STREET_ADDRESS" tagName="h3" />
                <h4>{venue.deliveryAddress.address || '-'}</h4>
              </SummaryFieldComponent>
              <SummaryFieldComponent className="col-lg-12">
                <FormattedMessage id="REGISTER.ZIP_CODE" tagName="h3" />
                <h4>{venue.deliveryAddress.zipCode || '-'}</h4>
              </SummaryFieldComponent>
              <SummaryFieldComponent className="col-lg-12">
                <FormattedMessage id="REGISTER.CITY" tagName="h3" />
                <h4>{venue.deliveryAddress.city || '-'}</h4>
              </SummaryFieldComponent>
            </div>
          </div>
          <div className="card-header card-header--between">
            <h4 className="card-title">
              <FormattedMessage id="REGISTER.SECTION_TITLES.ACCEPTED_MEANS_PAYMENT" />
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              <SummaryFieldComponent className="col-lg-12">
                <FormattedMessage id="REGISTER.SELECTED" tagName="h3" values={{ amount: acceptedPayments.length }} />
                <h4>{this.getPayments()}</h4>
              </SummaryFieldComponent>
            </div>
          </div>
          <div className="card-header card-header--between">
            <h4 className="card-title">
              <FormattedMessage id="VENUES.SETTLEMENT_INFORMATION" />
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              <SummaryFieldComponent className="col-lg-12">
                <FormattedMessage id="VENUES.SETTLEMENT_FREQUENCY" tagName="h3" />
                <h4>{this.props.intl.formatMessage({ id: `SETTLEMENT.${settlement}` })}</h4>
              </SummaryFieldComponent>
            </div>
          </div>
          <div className="card-header card-header--between">
            <h4 className="card-title">
              <FormattedMessage id="REGISTER.SECTION_TITLES.VENUE_CATEGORY" />
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              <SummaryFieldComponent className="col-lg-12">
                <FormattedMessage id="REGISTER.SELECTED" tagName="h3" values={{ amount: services.length }} />
                <h4>{this.getServices()}</h4>
              </SummaryFieldComponent>
            </div>
          </div>
          <div className="card-body card-body--between">
            <AgreementsAcceptanceContainer input={this.state.terms} name="service_provider" />

            <SummaryButtonsComponent
              changeStep={changeStep}
              disabled={!this.state.terms.value}
              submitting={submitting}
            />
          </div>
        </CardComponent>
      </section>
    );
  }
}
