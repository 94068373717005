import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { withCoreContext } from 'app/core/core.context';

import { PaymentsMobileSummaryComponent } from './payments-mobile-summary.component';

const mapStateToProps = ({ payments: { mobilePayment: payment }, users: { business, current } }) => ({
  payment,
  business: current.businesses.find(x => x.id === business),
});

export const PaymentsMobileSummaryContainer = compose(
  injectIntl,
  withCoreContext,
  connect(mapStateToProps),
)(PaymentsMobileSummaryComponent);
