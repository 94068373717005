// @flow

import React, { Component } from 'react';
import { Field, FormSection } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { omit, pick } from 'lodash';
import moment from 'moment';

import {
  ButtonComponent,
  ConnectedInputComponent,
  ConnectedSelectComponent,
  AlertComponent,
  ConnectedPhoneNumberInputComponent,
  ModalComponent,
  ConnectedDatePickerComponent,
} from 'app/shared';
import { USER_ROLES } from 'app/constants';

import type { Props, State } from './customer-information-form.component.types';
import styles from 'app/profile/personal/personal.module.scss';

export class CustomerInformationFormComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      submitted: false,
      isTerminationModalOpen: false,
      isTerminationDateModalOpen: false,
      submittingContractTermination: false,
      terminationContractError: null,
    };
  }

  invoicingMethods = this.props.invoicingMethods.map(({ id, name }) => ({
    id,
    name: this.props.intl.formatMessage({ id: `REGISTER.INVOICE_METHOD_OPTION.${name}` }),
  }));

  onSubmit = (body: Object) =>
    this.props
      .onSubmit(omit(body, ['contractTerminationDate']))
      .then(() => this.setState({ submitted: true }))
      .then(() => setTimeout(() => this.setState({ submitted: false }), 5000));

  onTerminateContractSubmit = (body: Object) => {
    const terminationDate = body.contractTerminationDate
      ? pick(body, 'contractTerminationDate')
      : { contractTerminationDate: moment().format('YYYY-MM-DD') };

    this.setState({ submittingContractTermination: true });
    this.props.onTerminateContract(this.props.customer.id, terminationDate).then(res => {
      if (res.error && res.payload.name !== 'InternalError') {
        this.setState({
          submittingContractTermination: false,
          terminationContractError: this.props.intl.formatMessage({ id: 'TRANSACTIONS.GENERATE_XLS_ERROR' }),
        });
        return;
      }
      this.setState({ submittingContractTermination: false, isTerminationDateModalOpen: false });
      this.props.fetchCustomer();
      this.props.onSuccessTermination();
    });
  };

  shouldDisplayTerminateButton = () =>
    !this.props.customer.contractTerminated &&
    !this.props.customer.contractTerminationDate &&
    this.props.role === USER_ROLES.ROLE_SERVICE_PROVIDER_MANAGER;

  render() {
    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    return (
      <>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <div className="card-header">
            <h4 className="card-title">{this.props.intl.formatMessage({ id: 'CUSTOMERS.GENERAL_INFORMATION' })}</h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className={columnClassNames}>
                <Field
                  name="name"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'CUSTOMERS.NAME' })}
                  addon={this.props.intl.formatMessage({ id: 'PROFILE.CONTACT_CUSTOMER_SERVICE' })}
                  required
                  disabled
                />
              </div>
              {(this.props.customer.type === 'TYPE_COMPANY' || this.props.customer.type === 'TYPE_MUNICIPAL') && (
                <div className={columnClassNames}>
                  <Field
                    name="nameExtra"
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'CUSTOMERS.NAME_2' })}
                    addon={this.props.intl.formatMessage({ id: 'PROFILE.CONTACT_CUSTOMER_SERVICE' })}
                    required
                    disabled
                  />
                </div>
              )}
              <div className={columnClassNames}>
                <Field
                  name="businessId"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'CUSTOMERS.BUSINESS_ID' })}
                  addon={this.props.intl.formatMessage({ id: 'PROFILE.CONTACT_CUSTOMER_SERVICE' })}
                  required
                  disabled
                />
              </div>
              <div className={columnClassNames}>
                <Field
                  name="phone"
                  component={ConnectedPhoneNumberInputComponent}
                  label={this.props.intl.formatMessage({ id: 'CUSTOMERS.PHONE' })}
                  required
                />
              </div>
            </div>
          </div>

          {(this.props.customer.type === 'TYPE_COMPANY' || this.props.customer.type === 'TYPE_SERVICE_PROVIDER') && (
            <>
              <div className="card-header card-header--between">
                <h4 className="card-title">{this.props.intl.formatMessage({ id: 'CUSTOMERS.OFFICIAL_ADDRESS' })}</h4>
              </div>
              <FormSection name="addresses.post">
                <div className="card-body">
                  <div className="row">
                    <div className={columnClassNames}>
                      <Field
                        name="address"
                        component={ConnectedInputComponent}
                        label={this.props.intl.formatMessage({ id: 'CUSTOMERS.STREET_ADDRESS' })}
                        required
                      />
                    </div>
                    <div className={columnClassNames}>
                      <Field
                        name="zipCode"
                        component={ConnectedInputComponent}
                        label={this.props.intl.formatMessage({ id: 'CUSTOMERS.ZIP_CODE' })}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className={columnClassNames}>
                      <Field
                        name="city"
                        component={ConnectedInputComponent}
                        label={this.props.intl.formatMessage({ id: 'CUSTOMERS.CITY' })}
                        required
                      />
                    </div>
                  </div>
                </div>
              </FormSection>
            </>
          )}

          {this.props.customer.type === 'TYPE_COMPANY' && (
            <>
              <div className="card-header card-header--between">
                <h4 className="card-title">{this.props.intl.formatMessage({ id: 'CUSTOMERS.BILLING_ADDRESS' })}</h4>
              </div>
              <FormSection name="addresses.billing">
                <div className="card-body">
                  <div className="row">
                    <div className={columnClassNames}>
                      <Field
                        name="addressee"
                        component={ConnectedInputComponent}
                        label={this.props.intl.formatMessage({ id: 'CUSTOMERS.ADDRESSEE' })}
                      />
                    </div>
                    <div className={columnClassNames}>
                      <Field
                        name="address"
                        component={ConnectedInputComponent}
                        label={this.props.intl.formatMessage({ id: 'CUSTOMERS.BILLING_ADDRESS' })}
                        required
                      />
                    </div>
                    <div className={columnClassNames}>
                      <Field
                        name="addressExtra"
                        component={ConnectedInputComponent}
                        label={this.props.intl.formatMessage({ id: 'CUSTOMERS.BILLING_ADDRESS_2' })}
                      />
                    </div>
                    <div className={columnClassNames}>
                      <Field
                        name="zipCode"
                        component={ConnectedInputComponent}
                        label={this.props.intl.formatMessage({ id: 'CUSTOMERS.ZIP_CODE' })}
                        required
                      />
                    </div>
                    <div className={columnClassNames}>
                      <Field
                        name="city"
                        component={ConnectedInputComponent}
                        label={this.props.intl.formatMessage({ id: 'CUSTOMERS.CITY' })}
                        required
                      />
                    </div>
                  </div>
                </div>
              </FormSection>
            </>
          )}

          {this.props.customer.type === 'TYPE_COMPANY' && (
            <>
              <div className="card-header card-header--between">
                <h4 className="card-title">{this.props.intl.formatMessage({ id: 'CUSTOMERS.DELIVERY_ADDRESS' })}</h4>
              </div>
              <FormSection name="addresses.delivery">
                <div className="card-body">
                  <div className="row">
                    <div className={columnClassNames}>
                      <Field
                        name="address"
                        component={ConnectedInputComponent}
                        label={this.props.intl.formatMessage({ id: 'CUSTOMERS.DELIVERY_ADDRESS' })}
                      />
                    </div>
                    <div className={columnClassNames}>
                      <Field
                        name="zipCode"
                        component={ConnectedInputComponent}
                        label={this.props.intl.formatMessage({ id: 'CUSTOMERS.ZIP_CODE' })}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className={columnClassNames}>
                      <Field
                        name="city"
                        component={ConnectedInputComponent}
                        label={this.props.intl.formatMessage({ id: 'CUSTOMERS.CITY' })}
                      />
                    </div>
                  </div>
                </div>
              </FormSection>
            </>
          )}

          {this.props.customer.type === 'TYPE_SERVICE_PROVIDER' && (
            <>
              <div className="card-header card-header--between">
                <h4 className="card-title">{this.props.intl.formatMessage({ id: 'CUSTOMERS.BANKING_INFORMATION' })}</h4>
              </div>
              <FormSection name="bankingInformation">
                <div className="card-body">
                  <div className="row">
                    <div className={columnClassNames}>
                      <Field
                        name="iban"
                        component={ConnectedInputComponent}
                        label={this.props.intl.formatMessage({ id: 'REGISTER.ACCOUNT_NUMBER' })}
                        required={!!this.props.swift}
                      />
                    </div>
                    <div className={columnClassNames}>
                      <Field
                        name="swift"
                        required={!!this.props.iban}
                        component={ConnectedSelectComponent}
                        options={this.props.swiftCodesOptions}
                        label={this.props.intl.formatMessage({ id: 'REGISTER.SWIFT' })}
                        placeholder={this.props.intl.formatMessage({ id: 'REGISTER.SWIFT' })}
                      />
                    </div>
                  </div>
                  <AlertComponent type="light" className={classNames(styles.infoAlert, 'text-left')}>
                    <FormattedMessage id="CUSTOMERS.BANKING_INFORMATION_TEXT" />
                  </AlertComponent>
                </div>
              </FormSection>
            </>
          )}

          {this.props.customer.type === 'TYPE_SERVICE_PROVIDER' && (
            <>
              <div className="card-header card-header--between">
                <h4 className="card-title">
                  <FormattedMessage id="REGISTER.SECTION_TITLES.BILLING_INFORMATION" />
                </h4>
              </div>
              <div className="card-body">
                <FormSection name="invoicing">
                  <div className="row">
                    <div className="col-lg-6">
                      <Field
                        name="defaultInvoicingMethod"
                        component={ConnectedSelectComponent}
                        options={this.invoicingMethods}
                        none={true}
                        label={this.props.intl.formatMessage({ id: 'REGISTER.INVOICE_METHOD' })}
                        placeholder={this.props.intl.formatMessage({ id: 'REGISTER.INVOICE_METHOD' })}
                      />
                    </div>
                    {this.props.formValues.defaultInvoicingMethod === 'INVOICING_METHOD_EMAIL' && (
                      <div className="col-lg-6">
                        <Field
                          name="defaultInvoicingEmail"
                          component={ConnectedInputComponent}
                          label={this.props.intl.formatMessage({ id: 'REGISTER.INVOICE_METHOD_EMAIL' })}
                          placeholder={this.props.intl.formatMessage({ id: 'REGISTER.INVOICE_METHOD_EMAIL' })}
                        />
                      </div>
                    )}
                    {this.props.formValues.defaultInvoicingMethod === 'INVOICING_METHOD_ONLINE' && (
                      <>
                        <div className="col-lg-6">
                          <Field
                            name="eInvoiceDeliveryIdentifier"
                            component={ConnectedSelectComponent}
                            options={this.props.eInvoiceOperators}
                            label={this.props.intl.formatMessage({ id: 'REGISTER.E_INVOICE_ID' })}
                            placeholder={this.props.intl.formatMessage({ id: 'REGISTER.E_INVOICE_ID' })}
                          />
                        </div>
                        <div className="col-lg-6">
                          <Field
                            name="eInvoiceAddress"
                            component={ConnectedInputComponent}
                            label={this.props.intl.formatMessage({ id: 'REGISTER.E_INVOICE_ADDRESS' })}
                            placeholder={this.props.intl.formatMessage({ id: 'REGISTER.E_INVOICE_ADDRESS' })}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </FormSection>
              </div>
            </>
          )}

          {this.props.customer.type === 'TYPE_COMPANY' && (
            <>
              <div className="card-header card-header--between">
                <h4 className="card-title">{this.props.intl.formatMessage({ id: 'CUSTOMERS.BILLING_INFORMATION' })}</h4>
              </div>
              <FormSection name="invoicing">
                <div className="card-body">
                  <div className="row">
                    <div className={columnClassNames}>
                      <Field
                        name="eInvoiceDeliveryIdentifier"
                        component={ConnectedSelectComponent}
                        options={this.props.eInvoiceOperators}
                        label={this.props.intl.formatMessage({ id: 'REGISTER.E_INVOICE_ID' })}
                        placeholder={this.props.intl.formatMessage({ id: 'REGISTER.E_INVOICE_ID' })}
                      />
                    </div>
                    <div className={columnClassNames}>
                      <Field
                        name="eInvoiceAddress"
                        component={ConnectedInputComponent}
                        label={this.props.intl.formatMessage({ id: 'CUSTOMERS.INVOICE_ADDRESS' })}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className={columnClassNames}>
                      <Field
                        name="defaultInvoicingReference"
                        component={ConnectedInputComponent}
                        label={this.props.intl.formatMessage({ id: 'CUSTOMERS.CUSTOMER_REFERENCE' })}
                      />
                    </div>
                  </div>
                </div>
              </FormSection>
            </>
          )}

          <div className="card-footer">
            <div className={styles.submit}>
              {this.state.submitted && <span className={classNames('fi fi-check', styles.check)} />}

              {this.state.submitted && (
                <p className={styles.message}>{this.props.intl.formatMessage({ id: 'PROFILE.UPDATE_SUCCESS' })}</p>
              )}

              <ButtonComponent theme="primary" loading={this.props.submitting} size="large">
                {this.props.intl.formatMessage({ id: 'CORE.SAVE' })}
              </ButtonComponent>
            </div>
          </div>
        </form>

        {this.shouldDisplayTerminateButton() && (
          <div className="card-footer">
            <div className={styles.submit}>
              <ButtonComponent
                theme="light"
                size="large"
                onClick={() => this.setState({ isTerminationModalOpen: true })}
              >
                {this.props.intl.formatMessage({ id: 'CUSTOMERS.CONTRACT_TERMINATION_CANCEL' })}
              </ButtonComponent>
            </div>
          </div>
        )}

        {this.state.isTerminationModalOpen && (
          <ModalComponent
            title={this.props.intl.formatMessage({ id: 'CUSTOMERS.CONTRACT_TERMINATION_ARE_YOU_SURE' })}
            onClose={() => this.setState({ isTerminationModalOpen: false })}
            submitButton={
              <ButtonComponent
                theme="primary"
                size="medium"
                onClick={() => this.setState({ isTerminationModalOpen: false, isTerminationDateModalOpen: true })}
              >
                {this.props.intl.formatMessage({ id: 'CUSTOMERS.CONTRACT_TERMINATION_SET_DATE_BUTTON' })}
              </ButtonComponent>
            }
          >
            {this.props.intl.formatMessage({ id: 'CUSTOMERS.CONTRACT_TERMINATION_WARNING_TEXT' })}
          </ModalComponent>
        )}

        {this.state.isTerminationDateModalOpen && (
          <ModalComponent
            title={this.props.intl.formatMessage({ id: 'CUSTOMERS.CONTRACT_TERMINATION_CHOOSE_DATE' })}
            onClose={() => this.setState({ isTerminationDateModalOpen: false, terminationContractError: null })}
            form={{ onSubmit: this.props.handleSubmit(this.onTerminateContractSubmit) }}
            submitButton={
              <ButtonComponent theme="primary" size="medium" loading={this.state.submittingContractTermination}>
                {this.props.intl.formatMessage({ id: 'CORE.CONFIRM' })}
              </ButtonComponent>
            }
          >
            {this.state.terminationContractError && (
              <p className={styles.error}>{this.state.terminationContractError}</p>
            )}
            <Field
              name="contractTerminationDate"
              initialDate={moment()}
              disableDatesOlderThanToday
              component={ConnectedDatePickerComponent}
              label={this.props.intl.formatMessage({ id: 'CORE.DATE' })}
              parse={value => (value ? value.format('YYYY-MM-DD') : null)}
              intl={this.props.intl}
            />
          </ModalComponent>
        )}
      </>
    );
  }
}
