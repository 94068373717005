import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';

import { withClear } from 'app/common';
import { withCoreContext } from 'app/core/core.context';

import { AccountTransactionsFiltersComponent } from './account-transactions-filters.component';

export const AccountTransactionsFiltersContainer = compose(
  injectIntl,
  withCoreContext,
  reduxForm({
    form: 'filter-account-transactions',
  }),
  withClear,
)(AccountTransactionsFiltersComponent);
