// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import pad from 'zero-fill';

import { CONTINUE_ORDER_WHITELIST } from '../orders-list.component';

import { ButtonComponent, NoResultsContainer, TableComponent } from 'app/shared';
import { currencyFormatter, dateFormatter } from 'app/utils';

import type { Props } from './orders-list-table.component.types';

export class OrdersListTableComponent extends Component<Props> {
  render() {
    return (
      <TableComponent className="table-striped">
        <thead>
          <tr>
            <FormattedMessage id="ORDER_HISTORY.NUMBER" tagName="th" />
            <FormattedMessage id="ORDER_HISTORY.DATE" tagName="th" />
            <th className="text-right">
              <FormattedMessage id="ORDER_HISTORY.BENEFITS" />
            </th>
            <FormattedMessage id="ORDER_HISTORY.STATUS" tagName="th" />
            <th />
          </tr>
        </thead>
        <tbody>
          {!!this.props.list.length || (
            <tr>
              <td colSpan={5}>
                <NoResultsContainer searchable={false} />
              </td>
            </tr>
          )}
          {this.props.list.map(order => (
            <tr key={order.id}>
              <td>
                <Link
                  to={{
                    pathname: `/orders/${order.id}`,
                    state: {
                      keepScrollPosition: true,
                    },
                  }}
                >
                  {pad(7, order.id)}
                </Link>
              </td>
              <td>
                <small>{dateFormatter(order.orderDate)}</small>
              </td>
              <td className="text-right">
                <strong>{currencyFormatter.format(order.totalAmount)}</strong>
              </td>
              <td>
                <small className="text-uppercase">
                  <FormattedMessage id={`STATUS.ORDER.${order.status}`} />
                </small>
              </td>
              <td className="text-right">
                {order.status === 'STATUS_OPEN' &&
                  CONTINUE_ORDER_WHITELIST.includes(order.type) && (
                    <ButtonComponent
                      theme="outline-secondary"
                      size="small"
                      to={`/subscriptions/${order.type}/${order.subscriptionId}/order/${order.id}`}
                    >
                      <i className="fi fi-pencil-edit-button" />
                    </ButtonComponent>
                  )}
              </td>
            </tr>
          ))}
        </tbody>
      </TableComponent>
    );
  }
}
