// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { HeaderComponent } from 'app/core';

import { CardComponent } from 'app/shared';

import { CardsBeneficiariesOrderPlaceholderContainer } from './placeholder/cards-beneficiaries-order.placeholder.container';

import { CardsBeneficiariesOrderConfirmContainer } from './confirm/cards-beneficiaries-order-confirm.container';
import { CardsBeneficiariesOrderSummaryComponent } from './summary/cards-beneficiaries-order-summary.component';

import type { BodyType, Props, State, Step } from './cards-beneficiaries-order.component.types';

export class CardsBeneficiariesOrderComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      step: 'SUMMARY',
    };
  }

  componentDidMount() {
    this.props.fetchBeneficiary();
    this.props.fetchCustomer(this.props.business);
    this.props.fetchDictionaries();
  }

  changeStep = (step: Step) => {
    this.setState({ step });
    window.scrollTo(0, 0);
  };

  onValidate = (body: BodyType) => this.props.onValidate(body).then(() => this.changeStep('CONFIRM'));

  render() {
    return (
      <>
        <HeaderComponent
          breadcrumbs={[
            {
              icon: 'credit-card',
            },
            {
              name: <FormattedMessage id="CARDS.BENEFICIARY_ORDER.TITLE" />,
            },
          ]}
        >
          <FormattedMessage id="CARDS.BENEFICIARY_ORDER.TITLE" tagName="h2" />
        </HeaderComponent>
        <CardComponent>
          {this.props.loading && (
            <CardsBeneficiariesOrderPlaceholderContainer beneficiaryId={this.props.match.params.beneficiary} />
          )}

          {this.props.loading || (
            <>
              {this.state.step === 'SUMMARY' && (
                <form onSubmit={this.props.handleSubmit(this.onValidate)}>
                  <CardsBeneficiariesOrderSummaryComponent
                    beneficiary={this.props.beneficiary}
                    submitting={this.props.submitting}
                  />
                </form>
              )}
              {this.state.step === 'CONFIRM' && (
                <form onSubmit={this.props.handleSubmit}>
                  <CardsBeneficiariesOrderConfirmContainer
                    onBack={() => this.changeStep('SUMMARY')}
                    submitting={this.props.submitting}
                  />
                </form>
              )}
            </>
          )}
        </CardComponent>
      </>
    );
  }
}
