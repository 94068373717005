// @flow

import React, { Component } from 'react';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import {
  AlertComponent,
  ButtonComponent,
  GroupComponent,
  ConnectedInputComponent,
  ConnectedSelectComponent,
  TooltipComponent,
} from 'app/shared';
import { currencyFormatter, shouldFieldArrayUpdate } from 'app/utils';

import type { Props, State } from './orders-create-products.component.types';
import styles from './orders-create-products.module.scss';

export class OrdersCreateProductsComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      initial: true,
    };
  }

  componentDidMount() {
    this.setState({ initial: false });
  }

  shouldComponentUpdate = shouldFieldArrayUpdate;

  createOptions = (index: number) => {
    const fields = this.props.products.filter((_, idx) => idx !== index);

    return this.props.accounts.filter(account => !fields.some(field => field.id === account.id));
  };

  getSummaryCost = ({ amount, id }: { amount: string, id: string }) => {
    const product = this.props.banknotesProducts.find(product => product.id === id);

    if (product && amount) {
      const productAmount = Number(amount.replace(',', '.'));
      return currencyFormatter.format(product.amount * 10 * productAmount || 0);
    } else {
      return currencyFormatter.format(0);
    }
  };

  render() {
    return (
      <>
        {this.props.meta.submitFailed &&
          !!this.props.meta.error && (
            <AlertComponent type="danger">
              <FormattedMessage id={this.props.meta.error} />
            </AlertComponent>
          )}
        {this.props.fields.map((field, index) => (
          <div className="row" key={index}>
            <div className="col-12 col-md-4">
              <Field
                name={`${field}.id`}
                component={ConnectedSelectComponent}
                options={this.createOptions(index)}
                label={this.props.intl.formatMessage({ id: 'ORDERS.PRODUCT' })}
                required
              />
            </div>
            <div className="col-12 col-md-4">
              <Field
                name={`${field}.amount`}
                component={ConnectedInputComponent}
                label={this.props.intl.formatMessage({
                  id: this.props.banknotesProducts ? 'ORDERS.NUMBER_OF_BOOKS' : 'ORDERS.AMOUNT',
                })}
                icon={this.props.banknotesProducts ? 'stack' : 'euro-symbol'}
                button={
                  this.props.banknotesProducts && (
                    <TooltipComponent
                      placement="top"
                      overlay={this.props.intl.formatMessage({ id: 'ORDERS.BOOKS_DESC' })}
                      defaultVisible={this.state.initial}
                    >
                      <span className="badge badge-light mr-2">× 10</span>
                    </TooltipComponent>
                  )
                }
                required
              />
            </div>
            {this.props.banknotesProducts && (
              <div className="col-6 col-md-3">
                <GroupComponent label={this.props.intl.formatMessage({ id: 'CORE.TOTAL' })}>
                  <strong className={styles.summary}>{this.getSummaryCost(this.props.fields.get(index))}</strong>
                </GroupComponent>
              </div>
            )}
            {this.props.fields.length > 1 && (
              <div className={classNames('col', styles.remove)}>
                <button onClick={() => this.props.fields.remove(index)}>
                  <i className="fi fi-cross-remove-sign" />
                </button>
              </div>
            )}
          </div>
        ))}

        {this.props.fields.length < this.props.accounts.length && (
          <ButtonComponent size="medium" theme="outline-secondary" onClick={() => this.props.fields.push({})}>
            <FormattedMessage id="ORDERS.ADD_ANOTHER_ROW" />
          </ButtonComponent>
        )}
      </>
    );
  }
}
