import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, Fields } from 'redux-form';
import classNames from 'classnames';

import {
  ButtonComponent,
  ConnectedInputComponent,
  ConnectedDateRangeComponent,
  ModalComponent,
  AlertComponent,
} from 'app/shared';

import type { Props } from './yearly-report.component.types';

export class YearlyReportComponent extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isCalendarOpened: false,
    };
  }

  onCalendarToggle = (state: any) => this.setState({ isCalendarOpened: !!state });

  render() {
    const submitButton = (
      <ButtonComponent theme="primary" size="medium" loading={this.props.submitting}>
        {this.props.intl.formatMessage({ id: 'SALDO_REPORTS.YEARLY_REPORT.SUBMIT' })}
      </ButtonComponent>
    );

    const columnClassNames = classNames('col-12', 'col-xl-6');

    return (
      <ModalComponent
        title={this.props.intl.formatMessage({ id: 'SALDO_REPORTS.YEARLY_REPORT.MODAL_TITLE' })}
        onClose={this.props.onClose}
        form={{ onSubmit: this.props.handleSubmit }}
        submitButton={submitButton}
      >
        <AlertComponent type="light" className="text-left">
          <FormattedMessage id="SALDO_REPORTS.YEARLY_REPORT.MODAL_SUBTITLE" tagName="p" />
        </AlertComponent>
        <div className="row">
          <div className={columnClassNames}>
            <Fields
              names={['startDate', 'endDate']}
              component={ConnectedDateRangeComponent}
              relativeNames={['startDate', 'endDate']}
              onFocusChange={this.onCalendarToggle}
              label={this.props.intl.formatMessage({ id: 'SALDO_REPORTS.YEARLY_REPORT.CALENDAR_LABEL' })}
              parse={value => (value ? value.format('YYYY-MM-DD') : null)}
              intl={this.props.intl}
              required
            />
          </div>
          <div className={columnClassNames}>
            <Field
              name="recipientEmail"
              type="email"
              label={this.props.intl.formatMessage({ id: 'SALDO_REPORTS.YEARLY_REPORT.EMAIL_LABEL' })}
              placeholder={this.props.intl.formatMessage({ id: 'AUTH.EMAIL_PLACEHOLDER' })}
              component={ConnectedInputComponent}
              required
            />
          </div>
        </div>
        <FormattedMessage id="SALDO_REPORTS.YEARLY_REPORT.MODAL_INFO" tagName="small" />
      </ModalComponent>
    );
  }
}
