// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { GroupComponent, PlaceholderComponent } from 'app/shared';

import type { Props } from './venue-form.placeholder.types';

export class VenueFormPlaceholder extends PureComponent<Props> {
  render() {
    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    return (
      <form>
        <div className="card-header">
          <h4 className="card-title">
            <FormattedMessage id="VENUES.GENERAL_INFORMATION" />
          </h4>
        </div>
        <div className="card-body">
          <div className="row">
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'VENUES.NAME' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'VENUES.WEBSITE' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
          <div className="row">
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'VENUES.GENERAL_EMAIL_ADDRESS' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'VENUES.INVOICE_EMAIL' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
          <div className="row">
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'VENUES.PHONE' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <GroupComponent label={this.props.intl.formatMessage({ id: 'VENUES.DESCRIPTION' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
        </div>
        <div className="card-header card-header--between">
          <h4 className="card-title">
            <FormattedMessage id="VENUES.OFFICIAL_ADDRESS" />
          </h4>
        </div>
        <div className="card-body">
          <div className="row">
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'VENUES.STREET_ADDRESS' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'VENUES.ZIP_CODE' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'VENUES.CITY' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
        </div>
        <div className="card-header card-header--between">
          <h4 className="card-title">
            <FormattedMessage id="VENUES.BILLING_ADDRESS" />
          </h4>
        </div>
        <div className="card-body">
          <div className="row">
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'VENUES.STREET_ADDRESS' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'VENUES.ZIP_CODE' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'VENUES.CITY' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
        </div>
        <div className="card-header card-header--between">
          <h4 className="card-title">
            <FormattedMessage id="VENUES.DELIVERY_ADDRESS" />
          </h4>
        </div>
        <div className="card-body">
          <div className="row">
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'VENUES.STREET_ADDRESS' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'VENUES.ZIP_CODE' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'VENUES.CITY' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
        </div>
        <div className="card-header card-header--between">
          <h4 className="card-title">
            <FormattedMessage id="VENUES.BANK_ACCOUNT" />
          </h4>
        </div>
        <div className="card-body">
          <div className="row">
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'VENUES.BANK_IBAN' })}>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'VENUES.BANK_SWIFT' })}>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
        </div>
        <div className="card-footer text-right">
          <PlaceholderComponent width={120} height={40} radius={0} color="primary" />
        </div>
      </form>
    );
  }
}
