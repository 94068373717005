// @flow

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { TransactionsCompanyContainer } from './company/transactions-company.container';
import { TransactionsLatestContainer } from './latest/transactions-latest.container';
import { TransactionsVisaContainer } from './visa/transactions-visa.container';

import type { Props } from './transactions.component.types';

export class TransactionsComponent extends Component<Props> {
  render() {
    return (
      <Switch>
        <Route path={`${this.props.match.url}/company`} component={TransactionsCompanyContainer} />
        <Route path={`${this.props.match.url}/latest`} component={TransactionsLatestContainer} />
        <Route path={`${this.props.match.url}/visa`} component={TransactionsVisaContainer} />
      </Switch>
    );
  }
}
