import { LOGOUT_SUCCESS, LOGOUT_FAILURE } from 'app/redux/users/users.action-types';
import { TOGGLE_TIMEOUT } from 'app/redux/timeout/timeout.action-types';
import { toggleTimeout } from 'app/redux/timeout/timeout.actions';

let timeout;

export const timeoutMiddleware = store => next => action => {
  const {
    timeout: isActive,
    users: { business, isAuthenticated, meta },
  } = store.getState();

  const defaultTimeout = 1200;

  if (action.type === LOGOUT_SUCCESS || action.type === LOGOUT_FAILURE || isAuthenticated) {
    clearTimeout(timeout);
  }

  if (isAuthenticated) {
    if ((action.type !== TOGGLE_TIMEOUT && !isActive) || (action.type === TOGGLE_TIMEOUT && action.payload === false)) {
      const timeoutTimeframe = (meta.session.timeouts[business] || defaultTimeout) * 1000;

      timeout = setTimeout(() => store.dispatch(toggleTimeout(true)), timeoutTimeframe);
    }
  }

  return next(action);
};
