import { handleActions } from 'redux-actions';
import * as types from './timeout.action-types';

const defaultState = false;

export const timeoutReducer = handleActions(
  {
    [types.TOGGLE_TIMEOUT]: (state, { payload }) => payload,
  },
  defaultState,
);
