// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, FieldArray } from 'redux-form';
import classNames from 'classnames';

import {
  AlertComponent,
  ButtonComponent,
  ButtonsWrapperComponent,
  CardComponent,
  ConnectedInputComponent,
} from 'app/shared';

import { OrdersCreateInvoicingContainer } from './invoicing/orders-create-invoicing.container';
import { OrdersCreatePlaceholderContainer } from './placeholder/orders-create.placeholder.container';
import { OrdersCreateProductsContainer } from './products/orders-create-products.container';

import type { Props } from './orders-create-form.component.types';

export class OrdersCreateFormComponent extends Component<Props> {
  componentDidMount() {
    this.props.fetchDictionary();
  }

  render() {
    const cardBodyClassNames = classNames('card-body', {
      'pt-0': this.props.type === 'municipal',
    });

    return (
      <>
        {this.props.loading && (
          <OrdersCreatePlaceholderContainer
            onBack={this.props.onBack}
            onCancel={this.props.onCancel}
            type={this.props.type}
          />
        )}

        {this.props.loading || (
          <CardComponent>
            <div className="card-header">
              {!!this.props.location &&
                !!this.props.location.state &&
                !!this.props.location.state.success && (
                  <AlertComponent type="success">
                    {this.props.location.state.success.type === 'ORDER_CREATED' && (
                      <FormattedMessage id="ORDERS.ORDER_SUCCESS" />
                    )}
                  </AlertComponent>
                )}
              {this.props.type !== 'municipal' && (
                <h4 className="card-title">
                  <FormattedMessage id="ORDERS.ORDER_BALANCE" />
                </h4>
              )}
            </div>
            <div className={cardBodyClassNames}>
              {this.props.type === 'company' && (
                <AlertComponent type="light" className="text-left">
                  <FormattedMessage id="ORDERS.ORDER_BALANCE_DESC" />
                </AlertComponent>
              )}

              {this.props.type === 'company' &&
                !!this.props.products && (
                  <FieldArray
                    name="products"
                    component={OrdersCreateProductsContainer}
                    accounts={this.props.products.map(({ id }) => ({
                      id,
                      name: this.props.intl.formatMessage({ id: `PAYMENT.ITEM.${id}` }),
                    }))}
                  />
                )}

              {this.props.type === 'municipal' && (
                <div className="row">
                  <div className="col-lg-6 col-xl-4">
                    <Field
                      name="amount"
                      component={ConnectedInputComponent}
                      label={this.props.intl.formatMessage({ id: 'ORDERS.SERVICE_COUPON_EMONEY' })}
                      icon="euro-symbol"
                      required
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="card-header card-header--between">
              <h4 className="card-title">
                <FormattedMessage id="ORDERS.INVOICING_DETAILS" />
              </h4>
            </div>
            <div className="card-body">
              <OrdersCreateInvoicingContainer form="create-order" type={`${this.props.type}Orders`} />
            </div>
            <div className="card-footer">
              <ButtonsWrapperComponent>
                <ButtonComponent theme="outline-secondary" size="large" onClick={this.props.onBack}>
                  <i className="fi fi-go-back-left-arrow" /> <FormattedMessage id="CORE.BACK" />
                </ButtonComponent>
                <ButtonComponent theme="link" size="large" onClick={this.props.onCancel} className="ml-3">
                  <FormattedMessage id="ORDERS.CANCEL_ORDER" />
                </ButtonComponent>

                <ButtonComponent theme="primary" size="large" loading={this.props.submitting} className="ml-auto">
                  <FormattedMessage id="CORE.NEXT" />
                </ButtonComponent>
              </ButtonsWrapperComponent>
            </div>
          </CardComponent>
        )}
      </>
    );
  }
}
