import React from 'react';
import { FormattedMessage } from 'react-intl';

import { TableComponent, PlaceholderComponent } from 'app/shared';

export class YearlyLimitsPlaceholder extends React.Component {
  render() {
    return (
      <div className="card-body">
        <TableComponent className="table-striped">
          <thead>
            <tr>
              <FormattedMessage id="YEARLY_LIMITS.BENEFIT_OPTION" tagName="th" />
              <FormattedMessage id="YEARLY_LIMITS.VALUE_IN_EURO" tagName="th" />
            </tr>
          </thead>
          <tbody>
            {Array(5)
              .fill()
              .map((item, index) => (
                <tr key={index}>
                  <td>
                    <PlaceholderComponent color="light" />
                  </td>
                  <td>
                    <PlaceholderComponent color="light" />
                  </td>
                </tr>
              ))}
          </tbody>
        </TableComponent>
      </div>
    );
  }
}
