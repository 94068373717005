// @flow

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { BeneficiaryDetailsContainer } from './details/beneficiary-details.container';
import { BeneficiaryAccountDetailsContainer } from './details/accounts/details/beneficiary-account-details.container';
import { BeneficiariesListContainer } from './list/beneficiaries-list.container';

import type { Props } from './beneficiaries.component.types';

export class BeneficiariesComponent extends Component<Props> {
  render() {
    return (
      <Switch>
        <Route
          path={`${this.props.match.url}/:beneficiary/accounts/:number`}
          component={BeneficiaryAccountDetailsContainer}
        />
        <Route path={`${this.props.match.url}/:beneficiary`} component={BeneficiaryDetailsContainer} />
        <Route path={this.props.match.url} component={BeneficiariesListContainer} />
      </Switch>
    );
  }
}
