import { RSAA } from 'redux-api-middleware';

import * as types from './accounts.action-types';

export const fetchAccounts = type => ({
  [RSAA]: {
    types: [
      {
        type: types.FETCH_ACCOUNTS_REQUEST,
        meta: { type },
      },
      {
        type: types.FETCH_ACCOUNTS_SUCCESS,
        meta: { type },
      },
      {
        type: types.FETCH_ACCOUNTS_FAILURE,
        meta: { type },
      },
    ],
    endpoint: `/accounts/${type}`,
    method: 'GET',
  },
});

export const fetchAccount = accountNumber => ({
  [RSAA]: {
    types: [
      {
        type: types.FETCH_ACCOUNT_REQUEST,
        meta: { accountNumber },
      },
      {
        type: types.FETCH_ACCOUNT_SUCCESS,
        meta: { accountNumber },
      },
      types.FETCH_ACCOUNT_FAILURE,
    ],
    endpoint: `/accounts/${accountNumber}`,
    method: 'GET',
  },
});

export const fetchTransactions = (accountNumber, params, municipal) => ({
  [RSAA]: {
    types: [
      {
        type: types.FETCH_TRANSACTIONS_REQUEST,
        meta: { accountNumber, params, shouldReset: !params || !params.offset },
      },
      {
        type: types.FETCH_TRANSACTIONS_SUCCESS,
        meta: { accountNumber, shouldMerge: params && params.offset },
      },
      {
        type: types.FETCH_TRANSACTIONS_FAILURE,
        meta: { accountNumber },
      },
    ],
    endpoint: municipal ? `/transactions/${accountNumber}` : `/transactions/account/${accountNumber}`,
    method: 'GET',
  },
});

export const returnEmoney = (accountNumber, body) => ({
  [RSAA]: {
    types: [
      types.RETURN_EMONEY_REQUEST,
      {
        type: types.RETURN_EMONEY_SUCCESS,
        meta: {
          accountNumber,
          amount: body.amount,
        },
      },
      types.RETURN_EMONEY_FAILURE,
    ],
    endpoint: `/accounts/${accountNumber}/return`,
    method: 'POST',
    body,
  },
});
