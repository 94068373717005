import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';

import { LoginWithoutPasswordComponent } from './login-without-password.component';

import { generateMagicLink } from 'app/redux/users/users.actions';

const mapDispatchToProps = dispatch => ({
  onSubmit: username => dispatch(generateMagicLink(username)),
});

export const LoginWithoutPasswordContainer = compose(
  injectIntl,
  connect(
    null,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'login-without-password',
  }),
)(LoginWithoutPasswordComponent);
