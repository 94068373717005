import { compose } from 'redux';
import { connect } from 'react-redux';

import { withAuthorization } from 'app/common';

import { changeCardStatus, fetchCards } from 'app/redux/cards/cards.actions';
import { updateConfirmation } from 'app/redux/view/view.actions';

import { CardsListComponent } from './cards-list.component';

const mapStateToProps = ({ cards: { list: cards } }) => ({
  cards,
  loading: !cards,
});

const mapDispatchToProps = dispatch => ({
  fetchCards: () => dispatch(fetchCards()),
  onActivate: card =>
    dispatch(
      updateConfirmation({
        onConfirm: () => dispatch(changeCardStatus(card, 'enabled')),
        title: 'CARDS.CONFIRM_STATUS_UPDATE',
      }),
    ),
  onDisable: card =>
    dispatch(
      updateConfirmation({
        onConfirm: () => dispatch(changeCardStatus(card, 'disabled')),
        title: 'CARDS.CONFIRM_STATUS_UPDATE',
      }),
    ),
});

export const CardsListContainer = compose(
  withAuthorization(['beneficiaryCards', 'read']),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(CardsListComponent);
