// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { AlertComponent, ButtonComponent, CardComponent, GroupComponent, PlaceholderComponent } from 'app/shared';

import type { Props } from './orders-create.placeholder.types';

export class OrdersCreatePlaceholder extends Component<Props> {
  render() {
    const invoiceColumnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    const cardBodyClassNames = classNames('card-body', {
      'pt-0': this.props.type === 'municipal',
    });

    return (
      <CardComponent>
        {this.props.type !== 'municipal' && (
          <div className="card-header">
            <h4 className="card-title">
              <FormattedMessage id="ORDERS.ORDER_BALANCE" />
            </h4>
          </div>
        )}
        <div className={cardBodyClassNames}>
          {this.props.type === 'company' && (
            <>
              <AlertComponent type="light" className="text-left">
                <FormattedMessage id="ORDERS.ORDER_BALANCE_DESC" />
              </AlertComponent>
              <div className="row">
                <div className="col-6 col-xl-4">
                  <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.PRODUCT' })} required>
                    <PlaceholderComponent height={40} radius={8} color="light" />
                  </GroupComponent>
                </div>
                <div className="col-md-4 col-xl-3">
                  <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.AMOUNT' })} required>
                    <PlaceholderComponent height={40} radius={8} color="light" />
                  </GroupComponent>
                </div>
              </div>
              <ButtonComponent size="medium" theme="outline-secondary">
                <FormattedMessage id="ORDERS.ADD_ANOTHER_ROW" />
              </ButtonComponent>
            </>
          )}

          {this.props.type === 'municipal' && (
            <div className="row">
              <div className="col-lg-6 col-xl-4">
                <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.SERVICE_COUPON_EMONEY' })} required>
                  <PlaceholderComponent height={40} radius={8} color="light" />
                </GroupComponent>
              </div>
            </div>
          )}
        </div>
        <div className="card-header card-header--between">
          <h4 className="card-title">
            <FormattedMessage id="ORDERS.INVOICING_DETAILS" />
          </h4>
        </div>
        <div className="card-body">
          <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.INVOICING_METHOD' })} required>
            <PlaceholderComponent height={24} radius={8} color="light" />
          </GroupComponent>

          <div className="row">
            <div className={invoiceColumnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.OFFICIAL_NAME' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
            <div className={invoiceColumnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.EMAIL' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
          <div className="row">
            <div className={invoiceColumnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.ORDER_REFERENCE' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="row">
            <div className="col-md-6">
              <ButtonComponent theme="outline-secondary" size="large" onClick={this.props.onBack}>
                <i className="fi fi-go-back-left-arrow" /> <FormattedMessage id="CORE.BACK" />
              </ButtonComponent>

              <ButtonComponent theme="link" size="large" onClick={this.props.onCancel} className="ml-3">
                <FormattedMessage id="ORDERS.CANCEL_ORDER" />
              </ButtonComponent>
            </div>
            <div className="col-md-6 text-right">
              <ButtonComponent theme="primary" size="large">
                <FormattedMessage id="CORE.NEXT" /> <i className="fi fi-right-arrow-forward" />
              </ButtonComponent>
            </div>
          </div>
        </div>
      </CardComponent>
    );
  }
}
