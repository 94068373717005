// @flow

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { OrdersCreateContainer } from './create/orders-create.container';
import { OrdersListContainer } from './list/orders-list.container';

import type { Props } from './orders.component.types';

export class OrdersComponent extends Component<Props> {
  render() {
    return (
      <Switch>
        <Route path={`${this.props.match.url}/create`} component={OrdersCreateContainer} />
        <Route path={this.props.match.url} component={OrdersListContainer} />
      </Switch>
    );
  }
}
