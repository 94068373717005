// @flow

import React, { Component } from 'react';

import { PlaceholderComponent, TileComponent } from 'app/shared/index';

export class ActivationCardsTilePlaceholder extends Component<any> {
  render() {
    return (
      <TileComponent>
        <h3>
          <PlaceholderComponent width={120} height={27} />
        </h3>
      </TileComponent>
    );
  }
}
