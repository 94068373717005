// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { HeaderComponent } from 'app/core';
import { UsersListContainer } from 'app/common/users';
import { PlaceholderComponent } from 'app/shared';

import type { Props } from './venue-users.placeholder.types';

export class VenueUsersPlaceholder extends PureComponent<Props> {
  static defaultProps = {
    users: [...new Array(5)],
  };

  render() {
    return (
      <>
        <HeaderComponent
          breadcrumbs={[
            {
              icon: 'business',
              to: '/venues',
            },
            {
              name: <FormattedMessage id="NAV.VENUE.VENUE_LIST" />,
              to: '/venues',
            },
            {
              name: <FormattedMessage id="VENUES.USERS" />,
            },
          ]}
        >
          <h2>
            <PlaceholderComponent width={200} height={31} color="purple" />
          </h2>
        </HeaderComponent>

        <UsersListContainer context="venue" loading={true} />
      </>
    );
  }
}
