import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import { withPermissions } from 'app/common';

import { SidebarAccountComponent } from './sidebar-account.component';

export const SidebarAccountContainer = compose(
  injectIntl,
  withPermissions,
)(SidebarAccountComponent);
