// @flow

import React, { Component } from 'react';

import { CardsListTablePlaceholder } from './table/cards-list-table.placeholder';
import { CardsListTilePlaceholder } from './tile/cards-list-tile.placeholder';

import { ListComponent } from 'app/shared/list/list.component';

import type { Props } from './cards-list.placeholder.types';

export class CardsListPlaceholder extends Component<Props> {
  static defaultProps = {
    cards: [...new Array(5)],
  };

  render() {
    return <ListComponent list={this.props.cards} table={CardsListTablePlaceholder} tile={CardsListTilePlaceholder} />;
  }
}
