import { compose } from 'redux';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';

import { CardsBeneficiariesOrderConfirmComponent } from './cards-beneficiaries-order-confirm.component';

const mapStateToProps = ({ dictionaries, form }) => ({
  products: dictionaries['products/card'].products,
  values: getFormValues('order-beneficiary-card')({ form }),
});

export const CardsBeneficiariesOrderConfirmContainer = compose(connect(mapStateToProps))(
  CardsBeneficiariesOrderConfirmComponent,
);
