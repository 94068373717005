// @flow

import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { addLocaleData, IntlProvider } from 'react-intl';

import GoogleAnalytics from 'react-ga';
import moment from 'moment';
import flatten from 'flat';

// #if process.env.REACT_APP_SENTRY_DSN
import * as Sentry from '@sentry/browser';
// #endif

import en from 'react-intl/locale-data/en';
import fi from 'react-intl/locale-data/fi';
import sv from 'react-intl/locale-data/sv';

import 'moment/locale/fi';
import 'moment/locale/sv';

import { ErrorComponent, SplashComponent } from 'app/core';

import { CoreContainer } from 'app/core/core.container';
import { AuthComponent } from 'app/auth/auth.component';
import { SubscriptionSubscribeContainer } from 'app/subscriptions/subscribe/subscription-subscribe.container';
import { RegisterComponent } from 'app/register/register.component';
import { PrintableReportContainer } from 'app/reports/printable-report/printable-report.container';

import { AppContext } from './app.context';

import type { Props, State, HandleErrorInfo } from './app.component.types';

addLocaleData([...en, ...fi, ...sv]);

export class AppComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      crashed: false,
      locale: {
        // $FlowFixMe
        value: localStorage.getItem('language') || 'fi',
        update: this.updateLocale,
      },
    };

    moment.locale(this.state.locale.value);
  }

  componentDidMount() {
    this.props.fetchCurrentUser();

    this.props.fetchTranslations('en');
    this.props.fetchTranslations('fi');
    this.props.fetchTranslations('sv');
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.location !== prevProps.location &&
      !(prevProps.location.state && prevProps.location.state.keepScrollPosition)
    ) {
      window.scrollTo(0, 0);
    }
  }

  componentDidCatch(error: Error, extra: HandleErrorInfo) {
    this.setState({ crashed: true });

    if (process.env.REACT_APP_SENTRY_DSN) {
      Sentry.withScope(scope => {
        scope.setExtra('debug', false);
        Sentry.captureException(error, { extra });
      });
    }
  }

  updateLocale = (locale: 'en' | 'fi' | 'sv') =>
    this.setState(
      (prev: State) => ({
        ...prev,
        locale: {
          ...prev.locale,
          value: locale,
        },
      }),
      () => {
        try {
          localStorage.setItem('language', locale);
        } catch (err) {}

        moment.locale(locale);
        window.scrollTo(0, 0);

        GoogleAnalytics.event({
          category: 'Settings',
          action: 'Changed language',
          label: locale,
        });
      },
    );

  render() {
    if (this.props.loading) {
      return <SplashComponent />;
    }

    const messages = flatten(this.props.translations[this.state.locale.value]);
    const crashed = this.state.crashed || this.props.error;

    return (
      <AppContext.Provider value={this.state.locale}>
        <IntlProvider locale={this.state.locale.value} messages={messages}>
          <>
            {crashed && <ErrorComponent />}
            {crashed || (
              <Switch>
                <Redirect from="/web-service/subscribe/:hash" to="/subscribe/:hash" />
                <Route path="/register" component={RegisterComponent} />
                <Route path="/(login|request-password|new-password|login-without-password)" component={AuthComponent} />
                <Route path="/subscribe/:hash" component={SubscriptionSubscribeContainer} />
                <Route path="/print-report" component={PrintableReportContainer} />
                <Route path="/" component={CoreContainer} />
              </Switch>
            )}
          </>
        </IntlProvider>
      </AppContext.Provider>
    );
  }
}
