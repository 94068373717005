import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import { withPermissions } from 'app/common';

import { SidebarGeneralComponent } from './sidebar-general.component';

export const SidebarGeneralContainer = compose(
  injectIntl,
  withPermissions,
  connect(({ users: { role } }) => ({
    role,
  })),
)(SidebarGeneralComponent);
