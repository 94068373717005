import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import { withCoreContext } from 'app/core/core.context';

import { OrdersCreateDeliveryComponent } from './order-create-delivery.component';

export const OrdersCreateDeliveryContainer = compose(
  injectIntl,
  withCoreContext,
)(OrdersCreateDeliveryComponent);
