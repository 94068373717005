// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { isBusinessDifferent } from 'app/utils';

import { AccountsListPlaceholder } from './placeholder/accounts-list.placeholder';
import { AccountsListTableComponent } from 'app/accounts/list/table/accounts-list-table.component';
import { AccountsListTileComponent } from 'app/accounts/list/tile/accounts-list-tile.component';

import { HeaderComponent } from 'app/core';
import { ListComponent } from 'app/shared/list/list.component';
import { AlertComponent, ButtonComponent, CardComponent } from 'app/shared';

import type { Props } from './accounts-list.component.types';

export class AccountsListComponent extends Component<Props> {
  componentDidMount() {
    this.props.fetchAccounts();
  }

  componentDidUpdate(prevProps: Props) {
    if (isBusinessDifferent(prevProps, this.props)) {
      this.props.fetchAccounts();
    }
  }

  render() {
    return (
      <>
        <HeaderComponent
          breadcrumbs={[{ icon: 'account-balance' }]}
          button={
            <ButtonComponent to="/orders/create" size="large" theme="white">
              <FormattedMessage id="ACCOUNTS.ORDER_BALANCE" />
            </ButtonComponent>
          }
        >
          <FormattedMessage id="SIDEBAR.CUSTOMER_ACCOUNTS" tagName="h2" />
        </HeaderComponent>

        {this.props.loading && <AccountsListPlaceholder />}

        {this.props.loading || (
          <CardComponent>
            <div className="card-header">
              {!!this.props.location.state &&
                !!this.props.location.state.success && (
                  <AlertComponent type="success">
                    {this.props.location.state.success.type === 'ORDER_CREATED' && (
                      <FormattedMessage id="ORDERS.ORDER_SUCCESS" />
                    )}
                  </AlertComponent>
                )}

              <h4 className="card-title">
                <FormattedMessage id="ACCOUNTS.ACCOUNTS_LIST" />
              </h4>
            </div>
            <div className="card-body">
              <ListComponent
                list={this.props.accounts}
                table={AccountsListTableComponent}
                tile={AccountsListTileComponent}
              />
            </div>
          </CardComponent>
        )}
      </>
    );
  }
}
