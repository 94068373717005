// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { Field } from 'redux-form';

import { ConnectedInputComponent, ConnectedSelectComponent } from 'app/shared';
import { upperFirst } from 'lodash';
import { SIZE_SELECT_OPTIONS } from 'app/constants';

import type { Props } from './new-contract-filters.component.types';

export class NewContractFiltersComponent extends Component<Props> {
  UNSAFE_componentWillMount() {
    this.props.history.push({ search: '' });
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.business !== this.props.business) {
      this.props.reset();
    }
  }

  render() {
    const benefitTypeOptions = [{ id: 'MASSAGE', name: <FormattedMessage id="BENEFITS.MASSAGE.TITLE" /> }];

    return (
      <form onSubmit={this.props.handleSubmit} className="mt-4">
        <div className="form-row">
          <div className="col-md-6">
            <Field
              name="keyword"
              component={ConnectedInputComponent}
              placeholder={this.props.intl.formatMessage({ id: 'CONTRACTS.VENUE_SEARCH_PLACEHOLDER' })}
              icon="magnifying-glass"
              normalize={upperFirst}
            />
          </div>
          <div className="col-md-6">
            <Field
              name="location"
              component={ConnectedInputComponent}
              placeholder={this.props.intl.formatMessage({ id: 'CONTRACTS.LOCATION_SEARCH_PLACEHOLDER' })}
              icon="magnifying-glass"
              normalize={upperFirst}
            />
          </div>
          <div className="col-md-6">
            <Field name="benefitType" options={benefitTypeOptions} component={ConnectedSelectComponent} />
          </div>
          <div className="col-md-6">
            <Field name="size" options={SIZE_SELECT_OPTIONS} component={ConnectedSelectComponent} />
          </div>
        </div>
      </form>
    );
  }
}
