// @flow

import React, { Component } from 'react';
import { Field, FormSection } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { upperFirst } from 'lodash';

import { ButtonComponent, ConnectedInputComponent } from 'app/shared';

import type { Props } from './address-section.comonent.types';

export class AddressSectionComponent extends Component<Props> {
  render() {
    const { optional, title, open, name, withAddressee } = this.props;

    return (
      <FormSection name={name}>
        <div className="card-header card-header--between">
          <h4 className="card-title">
            <FormattedMessage id={title} />
          </h4>
          {optional && (
            <p>
              <FormattedMessage id={!open ? 'REGISTER.DIFFERENT_ADDRESS' : 'REGISTER.SAME_ADDRESS'} />{' '}
              <ButtonComponent onClick={this.props.onToggle} theme="outline-secondary" triangle={!open ? 'down' : 'up'}>
                <FormattedMessage id="CORE.CLICK_HERE" />
              </ButtonComponent>
            </p>
          )}
        </div>
        <div className="card-body">
          {(!optional || (optional && open)) && (
            <div className="row">
              {withAddressee && (
                <div className="col-lg-6">
                  <Field
                    name="addressee"
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'REGISTER.ADDRESSEE' })}
                    placeholder={this.props.intl.formatMessage({ id: 'REGISTER.ADDRESSEE' })}
                    required={!optional}
                    normalize={upperFirst}
                  />
                </div>
              )}
              <div className="col-lg-6">
                <Field
                  name="address"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'REGISTER.STREET_ADDRESS' })}
                  placeholder={this.props.intl.formatMessage({ id: 'REGISTER.STREET_ADDRESS' })}
                  required={!optional}
                  normalize={upperFirst}
                />
              </div>
              <div className="col-lg-6">
                <Field
                  name="zipCode"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'REGISTER.ZIP_CODE' })}
                  placeholder={this.props.intl.formatMessage({ id: 'REGISTER.ZIP_CODE' })}
                  required={!optional}
                />
              </div>
              <div className="col-lg-6">
                <Field
                  name="city"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'REGISTER.CITY' })}
                  placeholder={this.props.intl.formatMessage({ id: 'REGISTER.CITY' })}
                  required={!optional}
                  normalize={upperFirst}
                />
              </div>
            </div>
          )}
        </div>
      </FormSection>
    );
  }
}
