import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'redux';
import { reduxForm, formValueSelector } from 'redux-form';
import moment from 'moment';

import { fetchReport } from 'app/redux/reports/reports.actions';

import { ReportsDetailsComponent } from './reports-details.component';

const selector = formValueSelector('report-details');

const mapStateToProps = (
  { form, reports: { entities: reports } },
  {
    match: {
      params: { report },
    },
  },
) => ({
  loading: !reports[report],
  report: reports[report],
  initialValues: {
    startDate: moment()
      .startOf('year')
      .format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  },
  startDate: selector({ form }, 'startDate'),
  endDate: selector({ form }, 'endDate'),
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { report },
    },
    location: { search },
  },
) => ({
  fetchReport: () => dispatch(fetchReport(report)),
  onFilterChange: query => dispatch(fetchReport(report, query)),
  onClose: () =>
    dispatch(
      push({
        pathname: '/reports',
        search,
        state: {
          keepScrollPosition: true,
        },
      }),
    ),
});

export const ReportsDetailsContainer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'report-details',
  }),
)(ReportsDetailsComponent);
