// @flow

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { withRouter } from 'react-router-dom';

import type { ComponentType } from 'react';
import type { Props } from './with-clear.hoc.types';

const mapDispatchToProps = (dispatch, { location: { pathname } }) => ({
  onClear: () => dispatch(push(pathname)),
});

export const withClear = (WrappedComponent: ComponentType<any>) =>
  compose(
    withRouter,
    connect(
      null,
      mapDispatchToProps,
    ),
  )(
    class Clear extends Component<Props> {
      onClear = () => {
        this.props.onClear({});
        this.props.initialize({});
      };

      render() {
        return <WrappedComponent {...this.props} onClear={this.onClear} />;
      }
    },
  );
