// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent } from 'app/shared';

import type { Props } from './load-more.component.types';

export class LoadMoreComponent extends Component<Props> {
  render() {
    return (
      <div className="mt-5 text-center">
        <ButtonComponent theme="secondary" size="medium" onClick={this.props.onClick} loading={this.props.fetching}>
          <FormattedMessage id="CORE.LOAD_MORE" />
        </ButtonComponent>
      </div>
    );
  }
}
