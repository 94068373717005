// @flow

import React, { Component } from 'react';

import { HeaderComponent } from 'app/core';
import { CardComponent, PlaceholderComponent } from 'app/shared';

import { VenueFormPlaceholderContainer } from 'app/venues/form/placeholder/venue-form.placeholder.container';

export class EditVenuePlaceholder extends Component<any> {
  render() {
    return (
      <div>
        <HeaderComponent
          breadcrumbs={[
            {
              icon: 'business',
              to: '/venues',
            },
          ]}
        >
          <h2>
            <PlaceholderComponent width={200} height={31} color="purple" />
          </h2>
        </HeaderComponent>

        <CardComponent>
          <VenueFormPlaceholderContainer />
        </CardComponent>
      </div>
    );
  }
}
