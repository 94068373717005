// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { CardComponent, AccordionComponent, StoreLinksComponent } from 'app/shared';

import { SmartumPayHelpContainer } from '../smartum-pay-help/smartum-pay-help.container';
import type { Props } from './faq.component.types';
import styles from '../smartum-pay-info.module.scss';

export class FaqComponent extends Component<Props> {
  faqArray = [];

  constructor(props: Props) {
    super(props);

    this.faqArray = [
      {
        title: 'SMARTUMPAY.FAQ.FAQ_1.QUESTION',
        content: (
          <>
            <FormattedMessage id="SMARTUMPAY.FAQ.FAQ_1.ANSWER" tagName="p" />
            <StoreLinksComponent />
          </>
        ),
      },
      {
        title: 'SMARTUMPAY.FAQ.FAQ_2.QUESTION',
        content: <FormattedMessage id="SMARTUMPAY.FAQ.FAQ_2.ANSWER" tagName="p" />,
      },
    ];
  }

  render() {
    const { locale } = this.props.intl;
    const faqArray = locale !== 'fi' ? this.faqArray.slice(0, 3) : this.faqArray;

    return (
      <div className={styles.howToPay}>
        <div className={styles.howToPayTitle}>
          <FormattedMessage id="SMARTUMPAY.FAQ.TITLE" tagName="h2" />
        </div>
        <CardComponent>
          <div className={styles.howToPayCardBody}>
            {faqArray.map(item => (
              <AccordionComponent title={item.title} key={item.title}>
                {item.content}
              </AccordionComponent>
            ))}

            <SmartumPayHelpContainer />
          </div>
        </CardComponent>
      </div>
    );
  }
}
