// @flow

import React, { PureComponent } from 'react';
import classNames from 'classnames';

import { FormattedMessage } from 'react-intl';

import { ModalContentComponent } from 'app/shared/modal/content/modal-content.component';
import { ButtonComponent } from 'app/shared';

import type { Props } from './timeout.component.types';
import styles from 'app/shared/modal/modal.module.scss';

export class TimeoutComponent extends PureComponent<Props> {
  timeout: TimeoutID;

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.isActive && this.props.isActive) {
      this.timeout = setTimeout(this.props.onCancel, this.props.decisionTimeframe);
    }

    if (prevProps.isActive && !this.props.isActive) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    if (!this.props.isActive) {
      return null;
    }

    return (
      <div className="modal-backdrop">
        <div className={classNames('modal', styles.modal)}>
          <div className={classNames('modal-dialog', styles.confirm)}>
            <ModalContentComponent onClose={this.props.onCancel}>
              <div className={classNames('modal-content', styles.content)}>
                <div className="modal-body">
                  <h4 className="modal-title">
                    <FormattedMessage id="AUTH.CONTINUE_SESSION" />
                  </h4>
                </div>
                <div className={classNames('modal-footer', styles.footer)}>
                  <ButtonComponent theme="link" onClick={this.props.onCancel} size="medium">
                    <FormattedMessage id="CORE.NO" />
                  </ButtonComponent>
                  <ButtonComponent theme="primary" size="medium" onClick={this.props.onConfirm}>
                    <FormattedMessage id="CORE.YES" />
                  </ButtonComponent>
                </div>
              </div>
            </ModalContentComponent>
          </div>
        </div>
      </div>
    );
  }
}
