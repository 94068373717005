import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { BenefitComponent } from './benefit.component';

const mapStateToProps = ({ dictionaries }) => ({
  industries: dictionaries.services.industries,
});

export const BenefitContainer = compose(
  injectIntl,
  connect(mapStateToProps),
)(BenefitComponent);
