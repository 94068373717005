// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { CardComponent, ExternalLinkComponent } from 'app/shared';

import type { Props } from './mobile-app-info.component.types';
import styles from '../smartum-pay-info.module.scss';

const smartumPayDownloadDict = {
  en: process.env.REACT_APP_SMARTUMPAY_DOWNLOAD_INSTRUCTIONS_EN,
  fi: process.env.REACT_APP_SMARTUMPAY_DOWNLOAD_INSTRUCTIONS_FI,
  sv: process.env.REACT_APP_SMARTUMPAY_DOWNLOAD_INSTRUCTIONS_SV,
};

export class MobileAppInfoComponent extends Component<Props> {
  render() {
    const {
      intl: { locale },
    } = this.props;

    return (
      <div className={styles.howToPay}>
        <CardComponent>
          <div className={styles.howToPayCardBody}>
            <>
              <FormattedMessage id="SMARTUMPAY.MOBILE_APP_INFO.TEXT" tagName="p" />
              <ExternalLinkComponent href={smartumPayDownloadDict[locale]} target="_blank">
                <FormattedMessage id="SMARTUMPAY.MOBILE_APP_INFO.LINK_TEXT" />
              </ExternalLinkComponent>
            </>
          </div>
        </CardComponent>
      </div>
    );
  }
}
