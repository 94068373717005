import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import { withAppContext } from 'app/app.context';

import { LanguageSwitcherComponent } from './language-switcher.component';

export const LanguageSwitcherContainer = compose(
  withAppContext,
  injectIntl,
)(LanguageSwitcherComponent);
