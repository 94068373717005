// @flow

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import type { Props } from './button.component.types';
import styles from './button.module.scss';

export class ButtonComponent extends Component<Props> {
  render() {
    const buttonClassNames = classNames(
      'btn',
      `btn-${this.props.theme}`,
      styles.button,
      styles[this.props.theme],
      this.props.className,
      {
        [styles.loading]: this.props.loading,
        [styles[this.props.size]]: !!this.props.size,
      },
    );

    if (this.props.to) {
      return (
        <Link to={this.props.to} className={buttonClassNames}>
          {this.props.children}
        </Link>
      );
    }

    return (
      <button
        type={this.props.onClick ? 'button' : 'submit'}
        onClick={!this.props.disabled ? this.props.onClick : undefined}
        disabled={this.props.loading || this.props.disabled}
        className={buttonClassNames}
      >
        {this.props.children}

        {this.props.triangle && (
          <span className={classNames(styles.triangle, [styles[`triangle--${this.props.triangle}`]])} />
        )}
      </button>
    );
  }
}
