import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { push } from 'react-router-redux';
import { injectIntl } from 'react-intl';

import { withAuthorization } from 'app/common';

import { fetchAccounts } from 'app/redux/accounts/accounts.actions';
import { fetchCustomer } from 'app/redux/customers/customers.actions';
import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';
import { createOrder, confirmOrder } from 'app/redux/orders/orders.actions';

import { OrdersCreateComponent } from './orders-create.component';

const mapStateToProps = ({
  accounts: {
    company: { list: accounts },
  },
  customers: { entities: customers },
  users: { business, current },
}) => ({
  business,
  initialValues:
    !accounts || !customers[business]
      ? undefined
      : {
          invoicing: {
            invoicingMethod: 'EMAIL',
            invoiceName: customers[business].name,
            invoiceEmail: current.personal.email,
            invoiceReference: customers[business].invoicing.defaultInvoicingReference,
            eInvoiceAddress: customers[business].invoicing.eInvoiceAddress,
            eInvoiceIdentifier: customers[business].invoicing.eInvoiceDeliveryIdentifier,
          },
          products: [{}],
        },
});

const mapDispatchToProps = (dispatch, { intl: { locale } }) => ({
  fetchAccounts: () => dispatch(fetchAccounts('company')),
  fetchCustomer: customer => dispatch(fetchCustomer(customer)),
  fetchDictionary: () => dispatch(fetchDictionary('invoicing-options')),
  onConfirm: order => dispatch(confirmOrder(order, 'saldo', locale)),
  onConfirmSuccess: () =>
    dispatch(
      push({
        pathname: '/accounts/company',
        state: {
          success: {
            type: 'ORDER_CREATED',
          },
        },
      }),
    ),
  onSubmit: body => dispatch(createOrder('company', body)),
  onSubmitFail: () => window.scrollTo(0, 0),
  onSubmitSuccess: () => window.scrollTo(0, 0),
});

export const OrdersCreateContainer = compose(
  injectIntl,
  withAuthorization(['companyOrders', 'create']),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'create-order',
  }),
)(OrdersCreateComponent);
