// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { CardComponent, TableComponent, ProTip } from 'app/shared';
import ContractsPlaceholder from '../placeholder/contracts.placeholder';
import type { Props } from './new-contract.component.types';
import styles from './new-contract.module.scss';
import { NewContractFiltersContainer } from './new-contract-filters/new-contract-filters.container';
import { Link, Route, Switch } from 'react-router-dom';
import { CreateNewContractContainer } from './create-new-contract/create-new-contract.container';
import { getFormValues } from 'redux-form';

export class NewContractComponent extends Component<Props> {
  componentDidMount() {
    this.props.clearVenues();
  }

  render() {
    const { benefitType } = getFormValues('new-contract-filters')({ form: this.props.availableForms }) || {};

    const { loading, venues, intl, role } = this.props;
    const noResults = loading || !venues.length || !benefitType;

    return (
      <>
        <CardComponent>
          <div className="card-header">
            <h4 className="card-title">
              <FormattedMessage id="CONTRACTS.MAKE_NEW_CONTRACT" />
            </h4>
            <NewContractFiltersContainer />
          </div>
          <div className="card-body" style={{ overflowX: 'auto' }}>
            <TableComponent className="table-striped">
              <thead>
                <tr>
                  <FormattedMessage id="CONTRACTS.VENUE" tagName="th" />
                  <FormattedMessage id="CONTRACTS.ADDRESS" tagName="th" />
                  <FormattedMessage id="CONTRACTS.PHONE" tagName="th" />
                  <FormattedMessage id="CONTRACTS.BENEFIT" tagName="th" />
                </tr>
              </thead>
              <tbody>
                {!noResults &&
                  venues.map(({ id, name, address, phoneNumber, startDate, endDate, status }) => {
                    return (
                      <tr key={id}>
                        <td>{name}</td>
                        <td>{address}</td>
                        <td>{phoneNumber}</td>
                        <td>{intl.formatMessage({ id: `BENEFITS.${benefitType}.TITLE` })}</td>
                        <td>
                          <Link
                            to={{
                              pathname: `${this.props.match.url}/create-contract/${id}`,
                              state: { name, benefitType, phoneNumber, address, keepScrollPosition: true },
                              search: this.props.location.search,
                            }}
                            className={styles.newContract}
                          >
                            <FormattedMessage id="CONTRACTS.MAKE_A_CONTRACT" />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                {noResults && <ContractsPlaceholder />}
              </tbody>
            </TableComponent>
            <div className={styles.footer}>
              <div className={styles.pagination}>{this.props.pagination}</div>
              <span className={styles.buttons}>
                <button className="btn btn-primary" size="medium" onClick={() => this.props.history.push('/contracts')}>
                  {this.props.intl.formatMessage({ id: 'CONTRACTS.VIEW_CONTRACTS' })}
                </button>
                {!noResults && (
                  <button
                    className={classNames('btn btn-primary', styles.batchButton)}
                    size="medium"
                    onClick={() =>
                      this.props.history.push({
                        pathname: `${this.props.match.url}/create-contract/batch`,
                        state: { isBatch: true, keepScrollPosition: true },
                        search: this.props.location.search,
                      })
                    }
                  >
                    {this.props.intl.formatMessage({ id: 'CONTRACTS.CONTRACT_BATCH' })}
                  </button>
                )}
              </span>
            </div>
            <ProTip role={role} />
          </div>
        </CardComponent>
        <Switch>
          <Route
            path={`${this.props.match.url}/create-contract/:vendorId`}
            render={props => <CreateNewContractContainer business={this.props.business} {...props} />}
          />
          <Route
            path={`${this.props.match.url}/create-contract/batch`}
            render={props => <CreateNewContractContainer business={this.props.business} {...props} />}
          />
        </Switch>
      </>
    );
  }
}
