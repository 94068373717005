import { handleActions } from 'redux-actions';

import * as types from './metabase.action-types';

const defaultState = {
  url: '',
  hasError: false,
};

export const metabaseReducer = handleActions(
  {
    [types.FETCH_DASHBOARD_REQUEST]: state => ({
      ...state,
      url: '',
      hasError: false,
    }),
    [types.FETCH_DASHBOARD_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        url: payload,
        hasError: false,
      };
    },
    [types.FETCH_DASHBOARD_FAILURE]: state => {
      return {
        ...state,
        url: '',
        hasError: true,
      };
    },
  },
  defaultState,
);
