import { handleActions } from 'redux-actions';

import * as types from './contracts.action-types';

const defaultState = {
  entities: {},
};

export const contractsReducer = handleActions(
  {
    [types.FETCH_CONTRACTS_REQUEST]: state => ({
      ...state,
      list: undefined,
    }),
    [types.FETCH_CONTRACTS_SUCCESS]: (state, { payload: { data: list, meta } }) => ({
      ...state,
      list,
      meta,
    }),
    [types.CREATE_CONTRACT_FAILURE]: state => ({
      ...state,
    }),
    [types.CREATE_CONTRACT_BATCH_FAILURE]: state => ({
      ...state,
    }),
  },
  defaultState,
);
