import { handleActions } from 'redux-actions';
import * as types from './accounts.action-types';
import moment from 'moment';

const defaultState = {
  beneficiary: {},
  company: {},
  entities: {},
};

export const accountsReducer = handleActions(
  {
    [types.FETCH_ACCOUNTS_REQUEST]: (state, { meta: { type } }) => ({
      ...state,
      [type]: {
        ...state[type],
        list: undefined,
        loading: true,
      },
    }),
    [types.FETCH_ACCOUNTS_SUCCESS]: (state, { meta: { type }, payload: { data: list } }) => ({
      ...state,
      [type]: {
        ...state[type],
        list,
        loading: false,
      },
    }),
    [types.FETCH_ACCOUNTS_FAILURE]: (state, { meta: { type } }) => ({
      ...state,
      [type]: {
        ...state[type],
        loading: false,
      },
    }),
    [types.FETCH_ACCOUNT_REQUEST]: (state, { meta: { accountNumber } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [accountNumber]: {
          loading: true,
        },
      },
    }),
    [types.FETCH_ACCOUNT_SUCCESS]: (state, { meta: { accountNumber }, payload: { data: entity } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [accountNumber]: entity,
      },
    }),
    [types.FETCH_TRANSACTIONS_REQUEST]: (state, { meta: { accountNumber, shouldReset } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [accountNumber]: {
          ...state.entities[accountNumber],
          ...(shouldReset
            ? { transactions: undefined }
            : { transactions: { ...state.entities[accountNumber].transactions, fetching: true } }),
        },
      },
    }),
    [types.FETCH_TRANSACTIONS_SUCCESS]: (
      state,
      { payload: { data: list, meta }, meta: { accountNumber, shouldMerge } },
    ) => ({
      ...state,
      entities: {
        ...state.entities,
        [accountNumber]: {
          ...state.entities[accountNumber],
          transactions: {
            meta,
            fetching: false,
            list: {
              transactions: shouldMerge
                ? [...state.entities[accountNumber].transactions.list.transactions, ...list.transactions]
                : list.transactions,
              totalAmount: shouldMerge
                ? state.entities[accountNumber].transactions.list.totalAmount + list.totalAmount
                : list.totalAmount,
            },
          },
        },
      },
    }),
    [types.RETURN_EMONEY_SUCCESS]: (state, { meta: { accountNumber, amount } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [accountNumber]: {
          ...state.entities[accountNumber],
          balance: state.entities[accountNumber].balance - amount,
          transfers: state.entities[accountNumber].transfers.map(
            transfer =>
              moment().isBetween(moment(transfer.from), moment(transfer.to), 'days', '[]')
                ? {
                    ...transfer,
                    balance: transfer.balance - amount,
                  }
                : transfer,
          ),
        },
      },
    }),
  },
  defaultState,
);
