// @flow

import React, { Component } from 'react';

import { ActivationCardsTablePlaceholder } from './table/activation-cards-table.placeholder';
import { ActivationCardsTilePlaceholder } from './tile/activation-cards-tile.placeholder';

import { ListComponent } from 'app/shared/list/list.component';

import type { Props } from './activation-cards.placeholder.types';

export class ActivationCardsPlaceholder extends Component<Props> {
  static defaultProps = {
    cards: [...new Array(5)],
  };

  render() {
    return (
      <ListComponent
        list={this.props.cards}
        table={ActivationCardsTablePlaceholder}
        tile={ActivationCardsTilePlaceholder}
      />
    );
  }
}
