// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import { AlertComponent, ButtonComponent, ModalComponent, ConnectedSelectComponent } from 'app/shared';
import { SubscriptionSubscribeFormBenefitsRadioComponent } from 'app/subscriptions/subscribe/form/benefits/radio/subscription-subscribe-form-benefits-radio.component';

import { createExceededError } from 'app/subscriptions/subscribe/subscription-subscribe.util';

import type { BodyProps, Props, State } from './beneficiary-details-subscriptions-edit.component.types';

export class BeneficiaryDetailsSubscriptionsEditComponent extends Component<Props, State> {
  state = { error: null, isConfirmationVisible: false };

  onSubmit = (body: BodyProps) =>
    this.props
      .onSubmit({
        ...(({ benefits, deductibleAmount }) => ({
          benefits: benefits || [],
          deductibleAmount,
        }))(this.props.subscription.subscriptionBenefitOptions.find(({ id }) => id === body.benefitOption) || {}),
        override: true,
        status: this.props.beneficiary ? body.status : 'NEW',
      })
      .then(this.props.onClose)
      .catch(({ errors }) => {
        const exceededError = createExceededError(errors, this.props.intl);

        this.setState({ error: exceededError });
      });

  onRemove = () =>
    this.props
      .onSubmit({
        status: 'DELETED',
      })
      .then(this.props.onClose);

  onClickRemove = () => {
    this.setState({ isConfirmationVisible: true });
  };

  onCloseConfirmation = () => {
    this.setState({ isConfirmationVisible: false });
  };

  render() {
    const options = [
      { id: 'NEW', name: this.props.intl.formatMessage({ id: 'SUBSCRIPTION.BENEFICIARIES.STATUSES.NEW' }) },
      { id: 'APPROVED', name: this.props.intl.formatMessage({ id: 'SUBSCRIPTION.BENEFICIARIES.STATUSES.APPROVED' }) },
      { id: 'DECLINED', name: this.props.intl.formatMessage({ id: 'SUBSCRIPTION.BENEFICIARIES.STATUSES.DECLINED' }) },
    ];

    if (!this.props.subscription) {
      return null;
    }

    return (
      <>
        {!this.state.isConfirmationVisible && (
          <ModalComponent
            title={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.SUBSCRIBE.BENEFIT_OPTIONS' })}
            onClose={this.props.onClose}
            form={{ onSubmit: this.props.handleSubmit(this.onSubmit) }}
            submitButton={
              <ButtonComponent theme="primary" size="medium" loading={this.props.submitting}>
                <FormattedMessage id="CORE.SAVE" />
              </ButtonComponent>
            }
            additionalButton={
              <ButtonComponent
                theme="danger"
                size="medium"
                disabled={this.props.submitting}
                onClick={this.onClickRemove}
              >
                <FormattedMessage id="CORE.DELETE" />
              </ButtonComponent>
            }
          >
            <AlertComponent type="light" className="text-left">
              <FormattedMessage
                id="SUBSCRIPTION.SUBSCRIBE.BENEFIT_OPTIONS_INSTRUCTION"
                values={{ company: this.props.subscription.company }}
                tagName="p"
              />
            </AlertComponent>

            {this.state.error && (
              <AlertComponent type="danger" className="text-left">
                <FormattedMessage id={this.state.error.id} values={this.state.error} tagName="p" />
              </AlertComponent>
            )}

            <Field
              name="benefitOption"
              component={SubscriptionSubscribeFormBenefitsRadioComponent}
              intl={this.props.intl}
              options={this.props.subscription.subscriptionBenefitOptions}
              type={this.props.subscription.type}
              recurring={this.props.subscription.recurring}
              disabled={this.props.submitting}
            />
            <div className="row mt-3">
              {this.props.beneficiary && (
                <div className="col-12">
                  <Field
                    name="status"
                    component={ConnectedSelectComponent}
                    options={options}
                    label={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.BENEFICIARIES.STATUS' })}
                    disabled={this.props.submitting}
                  />
                </div>
              )}
            </div>
          </ModalComponent>
        )}

        {this.state.isConfirmationVisible && (
          <ModalComponent
            title={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.SUBSCRIBE.DELETE_CONFIRMATION_TITLE' })}
            onClose={this.onCloseConfirmation}
            submitButton={
              <ButtonComponent theme="danger" size="medium" loading={this.props.submitting} onClick={this.onRemove}>
                <FormattedMessage id="CORE.DELETE" />
              </ButtonComponent>
            }
          >
            <FormattedMessage id="SUBSCRIPTION.SUBSCRIBE.DELETE_CONFIRMATION_MESSAGE" />
          </ModalComponent>
        )}
      </>
    );
  }
}
