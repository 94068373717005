// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

import type { Props } from './summary-field.component.types';
import styles from './summary-field.module.scss';

export class SummaryFieldComponent extends Component<Props> {
  render() {
    const summaryFieldClassNames = classNames(styles.field, this.props.className, {
      [styles.equalFormHeights]: this.props.equalFormHeights,
    });

    return <div className={summaryFieldClassNames}>{this.props.children}</div>;
  }
}
