import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { downloadUserData } from 'app/redux/downloads/downloads.actions';

import { ProfileComponent } from './profile.component';

const mapStateToProps = ({ users: { business } }) => ({
  business,
});

const mapDispatchToProps = (dispatch, { intl: { locale } }) => ({
  onDownload: () => dispatch(downloadUserData(locale)),
});

export const ProfileContainer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ProfileComponent);
