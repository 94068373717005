import { RSAA } from 'redux-api-middleware';

import * as types from './terms.action-types';

const fetchTermsRSAA = name => ({
  [RSAA]: {
    types: [types.FETCH_TERMS_REQUEST, { type: types.FETCH_TERMS_SUCCESS, meta: { name } }, types.FETCH_TERMS_FAILURE],
    endpoint: `/pages/public/terms/${name}`,
    method: 'GET',
  },
});

const shouldFetchTerms = ({ agreements: { terms } }, name) => !terms[name];

export const fetchTerms = name => (dispatch, getState) => {
  if (shouldFetchTerms(getState(), name)) {
    return dispatch(fetchTermsRSAA(name));
  } else {
    return Promise.resolve();
  }
};
