// @flow

import { compose } from 'redux';
import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';

import { withLocale } from 'app/app.context';

import { SelectAllComponent } from './select-all.component';

const mapStateToProps = (state, { field, form }) => ({
  values: formValueSelector(form)(state, field),
});

const mapDispatchToProps = (dispatch, { field, form }) => ({
  onChange: (index, value) => dispatch(change(form, `${field}[${index}].active`, value)),
});

export const SelectAllContainer = compose(
  withLocale,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SelectAllComponent);
