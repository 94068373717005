// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { ListComponent } from 'app/shared/list/list.component';
import { ButtonComponent, CardComponent } from 'app/shared';
import { checkPermissions } from 'app/utils';

import { VenuesListTablePlaceholder } from './table/venues-list-table.placeholder';
import { VenuesListTilePlaceholder } from './tile/venues-list-tile.placeholder';

import type { Props } from './venues-list.placeholder.types';

export class VenuesListPlaceholder extends PureComponent<Props> {
  static defaultProps = {
    venues: [...new Array(5)],
  };

  render() {
    return (
      <CardComponent>
        <div className="card-header">
          <div className="d-flex align-items-center justify-content-xl-between">
            <h4 className="card-title col p-0">
              <FormattedMessage id="VENUES.YOUR_VENUES" />
            </h4>

            {checkPermissions(this.props.permissions, ['venues', 'create']) && (
              <ButtonComponent theme="secondary" size="medium" className="ml-4 ml-xl-0" to="/venues/add">
                <FormattedMessage id="VENUES.ADD_NEW_VENUE" />
              </ButtonComponent>
            )}
          </div>
        </div>
        <div className="card-body">
          <ListComponent list={this.props.venues} table={VenuesListTablePlaceholder} tile={VenuesListTilePlaceholder} />
        </div>
      </CardComponent>
    );
  }
}
