import { connect } from 'react-redux';

import get from 'lodash/get';

import { ConfirmComponent } from './confirm.component';

import { updateConfirmation } from 'app/redux/view/view.actions';

const mapStateToProps = ({ view: { confirmation } }) => ({
  confirmation,
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(updateConfirmation(null)),
  dispatch,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...(get(stateProps, 'confirmation.shouldDispatch')
    ? {
        ...stateProps,
        confirmation: {
          ...stateProps.confirmation,
          onConfirm: () => Promise.resolve(dispatchProps.dispatch(stateProps.confirmation.onConfirm)),
          onCancel: () => Promise.resolve(dispatchProps.dispatch(stateProps.confirmation.onCancel)),
        },
      }
    : stateProps),
  ...dispatchProps,
});

export const ConfirmContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(ConfirmComponent);
