import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { withRouter } from 'react-router-dom';

import { withCoreContext } from 'app/core/core.context';

import { editSubscription } from 'app/redux/subscriptions/subscriptions.actions';

import { SubscriptionFormComponent } from '../form/subscription-form.component';

const selector = formValueSelector('edit-subscription');

const checkBenefitOptionsValid = state => {
  const benefitOptions = selector(state, 'benefitOptions');
  let benefitOptionsValid = false;

  if (benefitOptions) {
    benefitOptionsValid = !benefitOptions
      .map(option => {
        const benefitsValid = !option.benefits.map(benefit => !!benefit.id && !!benefit.amount).includes(false);
        return benefitsValid && !!option.title;
      })
      .includes(false);
  }

  return benefitOptionsValid;
};

const checkQuestionsValid = state => {
  const questions = selector(state, 'questions');
  let questionsValid = false;

  if (questions) {
    questionsValid = !questions.map(question => !!question.question).includes(false);
  }

  return questionsValid;
};

const mapStateToProps = (state, { subscription }) => ({
  initialValues: subscription,
  stepsValuesValid: {
    BASIC_INFORMATION: !!selector(state, 'title') && !!selector(state, 'description'),
    SUBSCRIPTION_PERIOD:
      !!selector(state, 'subscription.subscriptionFrom') && !!selector(state, 'subscription.subscriptionTo'),
    RECURRING: true,
    BENEFIT_OPTION: checkBenefitOptionsValid(state),
    ADDITIONAL_QUESTIONS: !selector(state, 'questions') || checkQuestionsValid(state),
  },
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { type },
    },
    subscription: { id },
  },
) => ({
  onSubmit: body => dispatch(editSubscription(id, body)),
  onSubmitFail: () => window.scrollTo(0, 0),
  onSubmitSuccess: () =>
    dispatch(
      push({
        pathname: `/subscriptions/${type}/${id}`,
      }),
    ),
});

export const SubscriptionFormContainer = compose(
  withRouter,
  injectIntl,
  withCoreContext,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({ form: 'edit-subscription' }),
)(SubscriptionFormComponent);
