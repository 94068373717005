// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { PlaceholderComponent, SummaryFieldComponent } from 'app/shared';

import type { Props } from './beneficiary-details-personal.placeholder.types';

export class BeneficiaryDetailsPersonalPlaceholder extends Component<Props> {
  render() {
    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    return (
      <div className="card-body">
        <div className="row">
          <div className={columnClassNames}>
            <SummaryFieldComponent>
              <FormattedMessage id="PROFILE.FIRST_NAME" tagName="strong" />
              <PlaceholderComponent width={120} />
            </SummaryFieldComponent>
          </div>
          <div className={columnClassNames}>
            <SummaryFieldComponent>
              <FormattedMessage id="PROFILE.LAST_NAME" tagName="strong" />
              <PlaceholderComponent width={120} />
            </SummaryFieldComponent>
          </div>
          <div className={columnClassNames}>
            <SummaryFieldComponent>
              <FormattedMessage id="PROFILE.SSN" tagName="strong" />
              <PlaceholderComponent width={120} />
            </SummaryFieldComponent>
          </div>
          <div className={columnClassNames}>
            <SummaryFieldComponent>
              <FormattedMessage id="PROFILE.EMAIL" tagName="strong" />
              <PlaceholderComponent width={120} />
            </SummaryFieldComponent>
          </div>
          <div className={columnClassNames}>
            <SummaryFieldComponent>
              <FormattedMessage id="PROFILE.MOBILE_PHONE" tagName="strong" />
              <PlaceholderComponent width={120} />
            </SummaryFieldComponent>
          </div>
          <div className={columnClassNames}>
            <SummaryFieldComponent>
              <FormattedMessage id="PROFILE.WORK_PHONE" tagName="strong" />
              <PlaceholderComponent width={120} />
            </SummaryFieldComponent>
          </div>
          <div className={columnClassNames}>
            <SummaryFieldComponent>
              <FormattedMessage id="REGISTER.MARKETING" tagName="strong" />
              <PlaceholderComponent width={120} />
            </SummaryFieldComponent>
          </div>
        </div>
      </div>
    );
  }
}
