import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { withAuthorization } from 'app/common';
import { withPagination } from 'app/transactions/company/with-pagination/with-pagination.hoc';

import { fetchTransactions, setCancelledGenerateStatus } from 'app/redux/transactions/transactions.actions';

import { onGenerateTransactionsReport } from '../utils/generate-transactions-report/generate-transactions-report.util';

import { TransactionsCompanyComponent } from './transactions-company.component';

const mapStateToProps = ({ transactions: { company, generateStatus }, permissions, users: { business, role } }) => ({
  business,
  loading: !company,
  meta: company && company.meta,
  transactions: company && company.list && company.list.transactions,
  totalAmount: company && company.list && company.list.totalAmount,
  fetching: company && company.fetching,
  permissions: permissions[business][role] || {},
  generateStatus,
});

const mapDispatchToProps = (dispatch, { intl: { locale } }) => ({
  onClose: () => dispatch(setCancelledGenerateStatus()),
  fetchTransactions: params => dispatch(fetchTransactions('company', { ...params, limit: 10000 })),
  onDownload: business => onGenerateTransactionsReport(dispatch, 'company', business, locale),
});

export const TransactionsCompanyContainer = compose(
  withAuthorization(['companyTransactions', 'read']),
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  withPagination,
)(TransactionsCompanyComponent);
