// @flow

import { Component } from 'react';
import onClickOutside from 'react-onclickoutside';

import type { Props } from './modal-content.component.types';

export const ModalContentComponent = onClickOutside(
  class extends Component<Props> {
    handleClickOutside = evt =>
      document.documentElement && document.documentElement.clientWidth - 17 > evt.clientX && !this.props.isClosingBlocked && this.props.onClose();

    render() {
      return this.props.children;
    }
  },
);
