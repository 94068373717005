import URLSearchParams from '@ungap/url-search-params';

export const searchParamsSelector = (query, params) => {
  const searchParams = new URLSearchParams(query);

  searchParams.delete('page');

  for (const param in params) {
    if (params.hasOwnProperty(param)) {
      searchParams.set(param, params[param]);
    }
  }

  return searchParams.toString();
};
