import { handleActions } from 'redux-actions';

import * as types from './venues.action-types';

const defaultState = {
  entities: {},
  allVenues: [],
};

export const venuesReducer = handleActions(
  {
    [types.FETCH_VENUES_SUCCESS]: (state, { payload: { data: list } }) => ({
      ...state,
      list,
    }),
    [types.FETCH_AVAILABLE_VENUES_REQUEST]: state => ({
      ...state,
      list: undefined,
    }),
    [types.FETCH_AVAILABLE_VENUES_SUCCESS]: (state, { payload: { data: list, meta } }) => ({
      ...state,
      list,
      meta,
    }),
    [types.FETCH_ALL_AVAILABLE_VENUES_REQUEST]: state => ({
      ...state,
      allVenues: undefined,
    }),
    [types.FETCH_ALL_AVAILABLE_VENUES_SUCCESS]: (state, { payload: { data: list, meta } }) => ({
      ...state,
      allVenues: list,
    }),
    [types.FETCH_VENUE_REQUEST]: (state, { meta: { venue } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [venue]: undefined,
      },
    }),
    [types.FETCH_VENUE_SUCCESS]: (state, { payload: { data: entity } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [entity.venue.id]: entity,
      },
    }),
    [types.EDIT_VENUE_SUCCESS]: (state, { meta: { venue, id } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [id]: {
          ...venue,
        },
      },
    }),

    [types.FETCH_VENUE_CONTRACTS_REQUEST]: state => ({
      ...state,
      list: undefined,
    }),
    [types.FETCH_VENUE_CONTRACTS_SUCCESS]: (state, { payload: { data: list, meta } }) => ({
      ...state,
      list,
      meta,
    }),
    [types.FETCH_VENUE_MASSAGE_CONTRACTS_REQUEST]: state => ({
      ...state,
      list: undefined,
      isFetchingMassageContracts: true,
    }),
    [types.FETCH_VENUE_MASSAGE_CONTRACTS_SUCCESS]: (state, { payload: { data: venueMassageContracts } }) => ({
      ...state,
      venueMassageContracts,
      isFetchingMassageContracts: false,
    }),
    [types.CLEAR_VENUES]: state => ({
      ...state,
      list: undefined,
    }),
    [types.CLEAR_ALL_VENUES]: state => ({
      ...state,
      allVenues: undefined,
    }),
  },
  defaultState,
);
