// @flow

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { SmartumPayInfoContainer } from './smartum-pay-info/smartum-pay-info.container';

export class SmartumPayComponent extends Component<any> {
  render() {
    return (
      <Switch>
        <Route path={this.props.match.url} component={SmartumPayInfoContainer} />
      </Switch>
    );
  }
}
