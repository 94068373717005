import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import VenueSelect from './venue-select.component';
import { setError } from 'app/redux/error/error.actions';
import { fetchVenues } from 'app/redux/venues/venues.actions';

const mapStateToProps = ({ venues: { list: venues } }) => ({
  venues: venues ? venues.filter(v => !v.pending) : venues,
  loading: !venues,
});

const mapDispatchToProps = dispatch => ({
  setError: () => dispatch(setError()),
  fetchVenues: () => dispatch(fetchVenues()),
});

export const VenueSelectContainer = compose(injectIntl, connect(mapStateToProps, mapDispatchToProps))(VenueSelect);
