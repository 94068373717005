// @flow

import React, { Component } from 'react';
import { Field, FormSection } from 'redux-form';
import classNames from 'classnames';

import { ConnectedInputComponent } from 'app/shared';

import type { Props } from './order-create-delivery.component.types';

export class OrdersCreateDeliveryComponent extends Component<Props> {
  render() {
    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    return (
      <FormSection name="invoicingAddress">
        <div className="row">
          <div className={columnClassNames}>
            <Field
              name={this.props.beneficiary ? 'beneficiary' : 'company'}
              component={ConnectedInputComponent}
              label={
                this.props.beneficiary
                  ? this.props.intl.formatMessage({ id: 'BENEFICIARIES.LABEL.NAME' })
                  : this.props.intl.formatMessage({ id: 'ORDERS.COMPANY' })
              }
              disabled
              required
            />
          </div>
          <div className={columnClassNames}>
            <Field
              name="address"
              component={ConnectedInputComponent}
              label={this.props.intl.formatMessage({ id: 'ORDERS.STREET_ADDRESS' })}
              required
            />
          </div>
          <div className={columnClassNames}>
            <Field
              name="zipCode"
              component={ConnectedInputComponent}
              label={this.props.intl.formatMessage({ id: 'ORDERS.ZIP_CODE' })}
              required
            />
          </div>
          <div className={columnClassNames}>
            <Field
              name="city"
              component={ConnectedInputComponent}
              label={this.props.intl.formatMessage({ id: 'ORDERS.CITY' })}
              required
            />
          </div>
        </div>
      </FormSection>
    );
  }
}
