// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { NoResultsContainer, TableComponent } from 'app/shared/index';

import { SubscriptionBeneficiariesStatusComponent } from 'app/subscriptions/beneficiaries/status/subscription-beneficiaries-status.component';

import type { Props } from './activation-cards-table.component.types';

export class ActivationCardsTableComponent extends Component<Props> {
  render() {
    return (
      <TableComponent className="table-striped">
        <thead>
          <tr>
            <FormattedMessage id="CARDS.STATUS" tagName="th" />
            <FormattedMessage id="CARDS.NUMBER" tagName="th" />
            <FormattedMessage id="CARDS.VALID_THRU" tagName="th" />
          </tr>
        </thead>
        <tbody>
          {!!this.props.list.length || (
            <tr>
              <td colSpan={3}>
                <NoResultsContainer searchable={false} />
              </td>
            </tr>
          )}
          {this.props.list.map(card => (
            <tr key={card.id}>
              <td>
                <SubscriptionBeneficiariesStatusComponent status={card.active ? 'APPROVED' : 'DECLINED'} />
              </td>
              <td>{card.cardNumber}</td>
              <td>{card.validTo}</td>
            </tr>
          ))}
        </tbody>
      </TableComponent>
    );
  }
}
