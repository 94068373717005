import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';

import { BeneficiaryDetailsPersonalComponent } from './beneficiary-details-personal.component';

const mapStateToProps = ({ beneficiaries: { entities: beneficiaries } }, { beneficiary }) => ({
  beneficiary: get(beneficiaries, [beneficiary, 'personal']),
  loading: !get(beneficiaries, [beneficiary, 'personal']),
});

export const BeneficiaryDetailsPersonalContainer = compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps),
)(BeneficiaryDetailsPersonalComponent);
