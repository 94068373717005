import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { reduxForm, initialize } from 'redux-form';

import { fetchPublicSubscription, subscribe } from 'app/redux/subscriptions/subscriptions.actions';
import { updateProfile } from 'app/redux/users/users.actions';

import { SubscriptionSubscribeComponent } from './subscription-subscribe.component';

const mapStateToProps = (
  { subscriptions: { entities: subscriptions }, users: { current, isAuthenticated, hasAccount } },
  {
    match: {
      params: { hash },
    },
  },
) => ({
  isAuthenticated,
  hasAccount: hasAccount || false,
  loading: !subscriptions[hash],
  subscription: subscriptions[hash],
  initialValues: !!subscriptions[hash]
    ? {
        address:
          subscriptions[hash].type === 'EMONEY'
            ? {
                isHomeDelivery: true,
                ...(isAuthenticated ? current.personal.address : {}),
              }
            : undefined,
        answers: subscriptions[hash].questions
          ? subscriptions[hash].questions.map(subscription => ({
              questionId: subscription.id,
            }))
          : [],
        beneficiary: isAuthenticated && subscriptions[hash].type === 'EMONEY' ? current.personal : undefined,
        benefitOption: subscriptions[hash].benefitOptions ? subscriptions[hash].benefitOptions[0].id : null,
      }
    : undefined,
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { hash },
    },
  },
) => ({
  fetchSubscription: () => dispatch(fetchPublicSubscription(hash)),
  onSubmitAuthenticated: body =>
    dispatch(subscribe(hash, body))
      .then(() => dispatch(updateProfile('personal-info', body.beneficiary)))
      .then(data => dispatch(initialize('personal-information', data.payload.data.personal))),
  onSubmitUnauthenticated: body => dispatch(subscribe(hash, body)),
  onSubmitSuccess: () => window.scrollTo(0, 0),
});

export const SubscriptionSubscribeContainer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'subscribe',
    onSubmitFail: () => window.scrollTo(0, 0),
  }),
)(SubscriptionSubscribeComponent);
