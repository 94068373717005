import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { push } from 'react-router-redux';
import { injectIntl } from 'react-intl';

import { withCoreContext } from 'app/core/core.context';
import { withPermissions } from 'app/common';

import { addVenue } from 'app/redux/venues/venues.actions';
import { fillValues } from 'app/redux/register/register.actions';

import { VenueFormComponent } from 'app/venues/form/venue-form.component';

import { getAvailableBenefits } from 'app/utils/benefits/benefits.util';

const selector = formValueSelector('add-venue');

const mapStateToProps = state => {
  const benefits = getAvailableBenefits({ industries: state.dictionaries.services.industries, users: state.users });

  return {
    dictionary: state.dictionaries.services,
    benefit: benefits[0].id,
    initialValues: {
      acceptedPayments: state.dictionaries.services.payments.filter(x => x.alwaysEnabled).map(x => x.id),
      benefit: benefits[0].id,
      services: [],
      venue: {
        settlement: state.dictionaries.services.settlements[0].id,
        settlementAmount: 5,
      },
    },
    userRole: state.users.role,
    type: 'company',
    paymentMethods:
      state.dictionaries.services.industries.find(element => element.id === (selector(state, 'benefit') || 'exercise'))
        .payments || state.dictionaries.services.payments,
  };
};

const mapDispatchToProps = dispatch => ({
  onSubmit: body => dispatch(addVenue(body)).then(() => body.venue.name),
  onSubmitFail: () => window.scrollTo(0, 0),
  onSubmitSuccess: name =>
    dispatch(
      push({
        pathname: '/venues',
        state: {
          success: {
            type: 'ADD_NEW_VENUE',
            payload: name,
          },
        },
      }),
    ),
  fillValues: (from, to) =>
    dispatch(
      fillValues({
        form: 'add-venue',
        from,
        to,
      }),
    ),
});

export const VenueFormContainer = compose(
  injectIntl,
  withCoreContext,
  withPermissions,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({ form: 'add-venue' }),
)(VenueFormComponent);
