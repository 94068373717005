// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { CardComponent, TableComponent, ButtonComponent, NoResultsContainer } from 'app/shared';
import { currencyFormatter } from 'app/utils';

import { PaymentsTokenAccountsPlaceholder } from './placeholder/payments-token-accounts.placeholder';

import type { Account } from '../payments-token.component.types';
import type { Props } from './payments-token-accounts.component.types';
import styles from './payments-token-accounts.module.scss';

export class PaymentsTokenAccountsComponent extends Component<Props> {
  componentDidMount() {
    this.props.fetchPaymentsAccounts();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.business !== this.props.business) {
      this.props.fetchPaymentsAccounts();
    }
  }

  onAccountSelect = (account: Account) => {
    this.props.fetchPaymentToken(account.accountNumber);
    this.props.onAccountSelect(account);
  };

  render() {
    return (
      <CardComponent>
        <div className="card-header">
          <h4 className="card-title">
            <FormattedMessage id="MOBILE_PAYMENT_TOKEN.TITLE" />
          </h4>
        </div>
        <div className="card-body">
          {this.props.loading && <PaymentsTokenAccountsPlaceholder styles={styles} />}

          {this.props.loading || (
            <TableComponent className="table-striped">
              <thead>
                <tr>
                  <FormattedMessage id="MOBILE_PAYMENT_TOKEN.BENEFIT_TYPE" tagName="th" />
                  <th />
                </tr>
              </thead>
              <tbody>
                {!this.props.accounts.length ? (
                  <tr>
                    <td colSpan="2">
                      <NoResultsContainer searchable={false} />
                    </td>
                  </tr>
                ) : (
                  this.props.accounts.map(account => (
                    <tr className={styles.row} key={account.accountNumber}>
                      <td>
                        <FormattedMessage id={`PAYMENT.ITEM.${account.item}`} />
                        <span className={styles.amount}>
                          <FormattedMessage id="MOBILE_PAYMENT.AMOUNT" />:{' '}
                          <strong className={classNames({ 'text-success': account.active })}>
                            {currencyFormatter.format(account.balance)}
                          </strong>
                        </span>
                      </td>
                      <td className={styles.icons}>
                        <ButtonComponent
                          theme="outline-secondary"
                          size="small"
                          className={styles.button}
                          onClick={() => this.onAccountSelect(account)}
                          disabled={!account.active || !account.chargeable}
                        >
                          <FormattedMessage id="MOBILE_PAYMENT_TOKEN.QR_CODE" />{' '}
                          <i className="fi fi-right-arrow-forward" />
                        </ButtonComponent>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </TableComponent>
          )}
        </div>
      </CardComponent>
    );
  }
}
