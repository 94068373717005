import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';
import URLSearchParams from '@ungap/url-search-params';

import { SubscriptionBeneficiariesFiltersComponent } from './subscription-beneficiaries-filters.component';
import { searchParamsSelector } from 'app/utils/search-params-selector/search-params-selector.util';

const initialValuesSelector = query => {
  const searchParams = new URLSearchParams(query);

  return {
    phrase: searchParams.get('phrase') || '',
    size: searchParams.has('size') ? Number(searchParams.get('size')) : 100,
    status: searchParams.get('status'),
  };
};

const mapStateToProps = (_, { location: { search } }) => ({
  initialValues: initialValuesSelector(search),
});

const mapDispatchToProps = (dispatch, { location: { search } }) => ({
  onSubmit: body =>
    dispatch(
      push({
        search: searchParamsSelector(search, body),
        state: {
          keepScrollPosition: true,
        },
      }),
    ),
});

export const SubscriptionBeneficiariesFiltersContainer = compose(
  withRouter,
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'filter-subscription-beneficiaries',
  }),
)(SubscriptionBeneficiariesFiltersComponent);
