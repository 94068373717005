export const FETCH_BENEFICIARY_REQUEST = 'beneficiaries/FETCH_BENEFICIARY_REQUEST';
export const FETCH_BENEFICIARY_SUCCESS = 'beneficiaries/FETCH_BENEFICIARY_SUCCESS';
export const FETCH_BENEFICIARY_FAILURE = 'beneficiaries/FETCH_BENEFICIARY_FAILURE';

export const FETCH_BENEFICIARIES_REQUEST = 'beneficiaries/FETCH_BENEFICIARIES_REQUEST';
export const FETCH_BENEFICIARIES_SUCCESS = 'beneficiaries/FETCH_BENEFICIARIES_SUCCESS';
export const FETCH_BENEFICIARIES_FAILURE = 'beneficiaries/FETCH_BENEFICIARIES_FAILURE';

export const FETCH_BENEFICIARY_ACCOUNTS_REQUEST = 'beneficiaries/FETCH_BENEFICIARY_ACCOUNTS_REQUEST';
export const FETCH_BENEFICIARY_ACCOUNTS_SUCCESS = 'beneficiaries/FETCH_BENEFICIARY_ACCOUNTS_SUCCESS';
export const FETCH_BENEFICIARY_ACCOUNTS_FAILURE = 'beneficiaries/FETCH_BENEFICIARY_ACCOUNTS_FAILURE';

export const FETCH_BENEFICIARY_CARDS_REQUEST = 'beneficiaries/FETCH_BENEFICIARY_CARDS_REQUEST';
export const FETCH_BENEFICIARY_CARDS_SUCCESS = 'beneficiaries/FETCH_BENEFICIARY_CARDS_SUCCESS';
export const FETCH_BENEFICIARY_CARDS_FAILURE = 'beneficiaries/FETCH_BENEFICIARY_CARDS_FAILURE';

export const CHANGE_BENEFICIARY_CARD_STATUS_REQUEST = 'beneficiaries/CHANGE_BENEFICIARY_CARD_STATUS_REQUEST';
export const CHANGE_BENEFICIARY_CARD_STATUS_SUCCESS = 'beneficiaries/CHANGE_BENEFICIARY_CARD_STATUS_SUCCESS';
export const CHANGE_BENEFICIARY_CARD_STATUS_FAILURE = 'beneficiaries/CHANGE_BENEFICIARY_CARD_STATUS_FAILURE';

export const FETCH_BENEFICIARY_SUBSCRIPTIONS_REQUEST = 'beneficiaries/FETCH_BENEFICIARY_SUBSCRIPTIONS_REQUEST';
export const FETCH_BENEFICIARY_SUBSCRIPTIONS_SUCCESS = 'beneficiaries/FETCH_BENEFICIARY_SUBSCRIPTIONS_SUCCESS';
export const FETCH_BENEFICIARY_SUBSCRIPTIONS_FAILURE = 'beneficiaries/FETCH_BENEFICIARY_SUBSCRIPTIONS_FAILURE';

export const EDIT_BENEFICIARY_SUBSCRIPTION_REQUEST = 'beneficiaries/EDIT_BENEFICIARY_SUBSCRIPTION_REQUEST';
export const EDIT_BENEFICIARY_SUBSCRIPTION_SUCCESS = 'beneficiaries/EDIT_BENEFICIARY_SUBSCRIPTION_SUCCESS';
export const EDIT_BENEFICIARY_SUBSCRIPTION_FAILURE = 'beneficiaries/FETCH_BENEFICIARY_SUBSCRIPTIONS_FAILURE';

export const UPDATE_ACCOUNT_STATUS_REQUEST = 'beneficiaries/UPDATE_ACCOUNT_STATUS_REQUEST';
export const UPDATE_ACCOUNT_STATUS_SUCCESS = 'beneficiaries/UPDATE_ACCOUNT_STATUS_SUCCESS';
export const UPDATE_ACCOUNT_STATUS_FAILURE = 'beneficiaries/UPDATE_ACCOUNT_STATUS_FAILURE';

export const DELETE_BENEFICIARY_REQUEST = 'beneficiaries/DELETE_BENEFICIARY_REQUEST';
export const DELETE_BENEFICIARY_SUCCESS = 'beneficiaries/DELETE_BENEFICIARY_SUCCESS';
export const DELETE_BENEFICIARY_FAILURE = 'beneficiaries/DELETE_BENEFICIARY_FAILURE';

export const FILTER_BENEFICIARIES_REQUEST = 'beneficiaries/FILTER_BENEFICIARIES_REQUEST';
export const FILTER_BENEFICIARIES_SUCCESS = 'beneficiaries/FILTER_BENEFICIARIES_SUCCESS';
export const FILTER_BENEFICIARIES_FAILURE = 'beneficiaries/FILTER_BENEFICIARIES_FAILURE';
