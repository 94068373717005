import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { cancelOrder, fetchOrder } from 'app/redux/orders/orders.actions';

import { OrderDetailsComponent } from './order-details.component';

const mapStateToProps = (
  { orders: { entities: orders } },
  {
    match: {
      params: { order },
    },
  },
) => ({
  loading: !orders[order],
  order: orders[order],
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { order },
    },
  },
) => ({
  fetchOrder: () => dispatch(fetchOrder(order)),
  onCancel: () =>
    dispatch(cancelOrder(order)).then(() =>
      dispatch(
        push({
          pathname: '/orders',
          state: {
            success: {
              type: 'CANCEL_ORDER',
            },
          },
        }),
      ),
    ),
  onClose: () =>
    dispatch(
      push({
        pathname: '/orders',
        state: {
          keepScrollPosition: true,
        },
      }),
    ),
});

export const OrderDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderDetailsComponent);
