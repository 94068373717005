// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { SettlementsListPlaceholder } from './placeholder/settlements-list.placeholder';
import { SettlementsListTableComponent } from './table/settlements-list-table.component';
import { SettlementsListTileComponent } from './tile/settlements-list-tile.component';

import { HeaderComponent } from 'app/core';
import { CardComponent, ListComponent } from 'app/shared';
import { isBusinessDifferent } from 'app/utils';

import type { Props } from './settlements-list.component.types';

export class SettlementsListComponent extends Component<Props> {
  componentDidUpdate(prevProps: Props) {
    if (isBusinessDifferent(prevProps, this.props)) {
      this.props.fetch(this.props.business);
    }
  }

  render() {
    return (
      <>
        <HeaderComponent breadcrumbs={[{ icon: 'settlements' }]}>
          <FormattedMessage id="NAV.ADDITIONAL.SETTLEMENTS" tagName="h2" />
        </HeaderComponent>
        <CardComponent>
          <div className="card-body">
            {this.props.loading && <SettlementsListPlaceholder />}
            {this.props.loading || (
              <>
                <ListComponent
                  list={this.props.settlements}
                  table={SettlementsListTableComponent}
                  tile={SettlementsListTileComponent}
                  searchable={false}
                  props={{
                    onDownload: this.props.onDownload,
                  }}
                />
                {this.props.settlements.length ? this.props.pagination : null}
              </>
            )}
          </div>
        </CardComponent>
      </>
    );
  }
}
