// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import moment from 'moment';

import { VenuesListPlaceholder } from './placeholder/venues-list.placeholder';
import styles from './venues-list.module.scss';

import { HeaderComponent } from 'app/core';
import {
  AlertComponent,
  ButtonComponent,
  CardComponent,
  ListComponent,
  ModalComponent,
  ConnectedDatePickerComponent,
  ConnectedInputComponent,
  LoaderFixedComponent,
} from 'app/shared';
import { checkPermissions } from 'app/utils';

import { VenuesListTableContainer } from './table/venues-list-table.container';
import { VenuesListTileContainer } from './tile/venues-list-tile.container';

import type { Props, State } from './venues-list.component.types';

export class VenuesListComponent extends Component<Props, State> {
  state = {
    isTerminationModalOpen: false,
    isTerminationDateModalOpen: false,
    venueId: null,
    submittingContractTermination: false,
    terminationContractError: null,
    contractTerminationDate: null,
    searchValue: '',
    terminationMessage: null,
  };

  componentDidMount() {
    this.props.fetchVenues();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.business !== this.props.business) {
      this.props.clearVenues();
      this.props.fetchVenues();
    }
  }

  onTerminateContractSubmit = (body: Object) => {
    const terminationDate = body.contractTerminationDate
      ? body
      : { contractTerminationDate: moment().format('YYYY-MM-DD') };

    this.setState({ submittingContractTermination: true });

    this.props.onTerminateContract(this.state.venueId, terminationDate).then(res => {
      if (res.error && res.payload.name !== 'InternalError') {
        this.setState({
          submittingContractTermination: false,
          terminationContractError: this.props.intl.formatMessage({ id: 'TRANSACTIONS.GENERATE_XLS_ERROR' }),
        });
        return;
      }
      window.scrollTo(0, 0);

      this.setState({
        submittingContractTermination: false,
        isTerminationDateModalOpen: false,
        terminationMessage: {
          type: 'SUCCESS',
          text: this.props.intl.formatMessage({ id: 'VENUES.DELETE_VENUE_SUCCESS' }),
        },
      });
    });
  };

  render() {
    // Prevent the refinement from invalidating
    const venues =
      this.props.loading ||
      this.props.venues.filter(venue => venue.name.toLowerCase().includes(this.state.searchValue.toLowerCase().trim()));

    return (
      <>
        <HeaderComponent breadcrumbs={[{ icon: 'business' }]}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormattedMessage id="VENUES.VENUES_LIST" tagName="h2" />
            <Field
              name="search"
              component={ConnectedInputComponent}
              intl={this.props.intl}
              placeholder={this.props.intl.formatMessage({ id: 'VENUES.SEARCH_VENUES' })}
              onChange={e => this.setState({ searchValue: e.target.value })}
              value={this.state.searchValue}
            />
          </div>
        </HeaderComponent>

        {this.state.terminationMessage && (
          <AlertComponent type={this.state.terminationMessage.type === 'SUCCESS' ? 'success' : 'danger'}>
            {this.state.terminationMessage.text}
          </AlertComponent>
        )}

        {this.props.loading && <VenuesListPlaceholder permissions={this.props.permissions} />}
        {this.props.loading && <LoaderFixedComponent />}

        {this.props.loading || (
          <CardComponent>
            <div className="card-header">
              {!!this.props.location.state &&
                !!this.props.location.state.success && (
                  <AlertComponent type="success">
                    {this.props.location.state.success.type === 'ADD_NEW_VENUE' && (
                      <FormattedMessage
                        id="VENUES.ADD_NEW_VENUE_SUCCESS"
                        values={{ name: <strong>{this.props.location.state.success.payload}</strong> }}
                      />
                    )}
                    {this.props.location.state.success.type === 'EDIT_VENUE' && (
                      <FormattedMessage
                        id="VENUES.EDIT_VENUE_SUCCESS"
                        values={{ name: <strong>{this.props.location.state.success.payload}</strong> }}
                      />
                    )}
                  </AlertComponent>
                )}

              {checkPermissions(this.props.permissions, ['venues', 'create']) && (
                <div className="text-right">
                  <ButtonComponent
                    theme="secondary"
                    size="medium"
                    className="ml-4 ml-xl-0"
                    to={`${this.props.match.url}/add`}
                  >
                    <FormattedMessage id="VENUES.ADD_NEW_VENUE" />
                  </ButtonComponent>
                </div>
              )}
            </div>
            <div className="card-body">
              <ListComponent
                list={venues}
                table={props => (
                  <VenuesListTableContainer
                    {...props}
                    onOpenTermiationModal={({ venueId, contractTerminationDate }) =>
                      this.setState({ isTerminationModalOpen: true, venueId, contractTerminationDate })
                    }
                  />
                )}
                tile={props => (
                  <VenuesListTileContainer
                    {...props}
                    onOpenTermiationModal={({ venueId, contractTerminationDate }) =>
                      this.setState({ isTerminationModalOpen: true, venueId, contractTerminationDate })
                    }
                  />
                )}
              />
            </div>
          </CardComponent>
        )}

        {this.state.isTerminationModalOpen && (
          <ModalComponent
            title={this.props.intl.formatMessage({ id: 'VENUES.CONTRACT_TERMINATION_ARE_YOU_SURE' })}
            onClose={() => this.setState({ isTerminationModalOpen: false })}
            submitButton={
              <ButtonComponent
                theme="primary"
                size="medium"
                onClick={() => this.setState({ isTerminationModalOpen: false, isTerminationDateModalOpen: true })}
              >
                {this.props.intl.formatMessage({ id: 'VENUES.CONTRACT_TERMINATION_SET_DATE_BUTTON' })}
              </ButtonComponent>
            }
          >
            {this.props.intl.formatMessage({ id: 'VENUES.CONTRACT_TERMINATION_WARNING_TEXT' })}
          </ModalComponent>
        )}

        {this.state.isTerminationDateModalOpen && (
          <ModalComponent
            title={this.props.intl.formatMessage({ id: 'VENUES.CONTRACT_TERMINATION_CHOOSE_DATE' })}
            onClose={() =>
              this.setState({ isTerminationDateModalOpen: false, venueId: null, terminationContractError: null })
            }
            form={{ onSubmit: this.props.handleSubmit(this.onTerminateContractSubmit) }}
            submitButton={
              <ButtonComponent theme="primary" size="medium" loading={this.state.submittingContractTermination}>
                {this.props.intl.formatMessage({ id: 'CORE.CONFIRM' })}
              </ButtonComponent>
            }
          >
            {this.state.terminationContractError && (
              <p className={styles.error}>{this.state.terminationContractError}</p>
            )}
            <Field
              name="contractTerminationDate"
              initialDate={this.state.contractTerminationDate ? moment(this.state.contractTerminationDate) : moment()}
              disableDatesOlderThanToday
              component={ConnectedDatePickerComponent}
              label={this.props.intl.formatMessage({ id: 'CORE.DATE' })}
              parse={value => (value ? value.format('YYYY-MM-DD') : null)}
              intl={this.props.intl}
            />
          </ModalComponent>
        )}
      </>
    );
  }
}
