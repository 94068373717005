import { compose } from 'redux';
import { connect } from 'react-redux';

import { withAuthorization } from 'app/common';

import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';

import { CreateSubscriptionComponent } from './create-subscription.component';

const mapStateToProps = (
  { dictionaries },
  {
    match: {
      params: { type },
    },
  },
) => ({
  loading: !dictionaries[`products/${type}`],
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { type },
    },
  },
) => ({
  fetchDictionary: () => dispatch(fetchDictionary(`products/${type}`)),
});

export const CreateSubscriptionContainer = compose(
  withAuthorization(['subscription', 'create']),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(CreateSubscriptionComponent);
