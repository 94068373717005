// FIXME: Add flow after moving card to separate component

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import moment from 'moment';

import {
  AlertComponent,
  ButtonComponent,
  ButtonsWrapperComponent,
  CardComponent,
  SummaryFieldComponent,
  ExternalLinkComponent,
} from 'app/shared';
import { currencyFormatter, dateFormatter } from 'app/utils';

import { SubscriptionNavContainer } from 'app/subscriptions/nav/subscription-nav.container';
import { SubscriptionShareComponent } from 'app/subscriptions/share/subscription-share.component';
import { SubscriptionDetailsPlaceholder } from './placeholder/subscription-details.placeholder';

import type { Props } from './subscription-details.component.types';
import styles from './subscription-details.module.scss';

export class SubscriptionDetailsComponent extends Component<Props> {
  componentDidMount() {
    this.props.fetchSubscription();
  }

  getMonthName = (monthNumber: number) => moment.months()[monthNumber - 1];

  render() {
    const { params } = this.props.match;
    const columnClassNames = classNames('col-md-12 col-xl-4', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    const footerStyles = classNames('card-footer', {
      [styles.detailsFooter]: this.props.totalBeneficiaries === 0,
    });

    const questions = this.props.loading ? [] : this.props.subscription.questions;

    const disabled = this.props.loading || this.props.subscription.status === 'ORDERED';

    return (
      <>
        <CardComponent header={false}>
          {this.props.loading && <SubscriptionDetailsPlaceholder />}

          {this.props.loading || (
            <>
              <div className="card-header">
                <SubscriptionNavContainer />

                {this.props.location.state &&
                  this.props.location.state.removedSubscriptionsNumber > 0 && (
                    <AlertComponent type="info">
                      <FormattedMessage
                        id="SUBSCRIPTION.DETAILS.REMOVED_SUBSCRIBERS"
                        values={{ count: this.props.location.state.removedSubscriptionsNumber }}
                      />
                    </AlertComponent>
                  )}
                <h4 className="card-title">
                  <FormattedMessage id="SUBSCRIPTION.SECTION_TITLES.SUMMARY" />
                </h4>
              </div>
              <div className="card-body">
                <AlertComponent type="light" className="text-left">
                  <FormattedMessage id="SUBSCRIPTION.DETAILS.INFO" />
                </AlertComponent>

                <AlertComponent type="light" className="text-left">
                  <FormattedMessage
                    id="SUBSCRIPTION.DETAILS.INFO_EXT"
                    values={{
                      link: (
                        <ExternalLinkComponent
                          href={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.DETAILS.INFO_EXT_LINK' })}
                          target="_blank"
                        >
                          <FormattedMessage id="SUBSCRIPTION.DETAILS.INFO_EXT_LINK_TEXT" />
                        </ExternalLinkComponent>
                      ),
                    }}
                  />
                </AlertComponent>

                <SubscriptionShareComponent intl={this.props.intl} subscription={this.props.subscription} />
                <h4>
                  <FormattedMessage id="SUBSCRIPTION.DETAILS.TITLE" values={{ name: this.props.subscription.title }} />
                </h4>
                <SummaryFieldComponent>
                  <FormattedMessage id="SUBSCRIPTION.LABEL.DESCRIPTION" tagName="strong" />
                  {this.props.subscription.description}
                </SummaryFieldComponent>
                <div className="row">
                  <div className={columnClassNames}>
                    <SummaryFieldComponent>
                      <FormattedMessage id="SUBSCRIPTION.SECTION_TITLES.SUBSCRIPTION_PERIOD" tagName="strong" />
                      {dateFormatter(this.props.subscription.subscription.subscriptionFrom)} -{' '}
                      {dateFormatter(this.props.subscription.subscription.subscriptionTo)}
                    </SummaryFieldComponent>
                  </div>
                  {!!this.props.subscription.subscription.distributionDay && (
                    <div className={columnClassNames}>
                      <SummaryFieldComponent>
                        <FormattedMessage id="SUBSCRIPTION.DETAILS.RECURS" tagName="strong" />
                        <FormattedMessage
                          id="SUBSCRIPTION.DETAILS.DAY_OF_MONTH"
                          values={{ day: this.props.subscription.subscription.distributionDay }}
                        />
                        {!!this.props.subscription.subscription.exceptMonth && (
                          <>
                            {' '}
                            <FormattedMessage
                              id="SUBSCRIPTION.DETAILS.EXCEPT"
                              values={{
                                month: this.getMonthName(this.props.subscription.subscription.exceptMonth),
                              }}
                            />
                          </>
                        )}
                      </SummaryFieldComponent>
                    </div>
                  )}
                </div>
                <div className="row">
                  {this.props.subscription.benefitOptions.map(option => (
                    <div className="col-md-6" key={option.id}>
                      <SummaryFieldComponent>
                        <strong>
                          <FormattedMessage id="SUBSCRIPTION.SECTION_TITLES.BENEFIT_OPTION" />: {option.title}
                        </strong>
                        {option.benefits.map(benefit => (
                          <div key={benefit.id}>
                            <FormattedMessage id={`PRODUCT.${benefit.id}`} />:{' '}
                            <span className="font-weight-bold">
                              {params.type === 'emoney' ? (
                                currencyFormatter.format(benefit.amount)
                              ) : (
                                <FormattedMessage id="CORE.BOOKS" values={{ books: benefit.amount }} />
                              )}
                            </span>
                          </div>
                        ))}
                        {!!option.deductibleAmount && (
                          <div>
                            <FormattedMessage id="SUBSCRIPTION.DETAILS.EXCESS" />:{' '}
                            <span className="font-weight-bold">
                              {currencyFormatter.format(option.deductibleAmount)}
                            </span>
                          </div>
                        )}
                      </SummaryFieldComponent>
                    </div>
                  ))}
                </div>
                {!!questions.length && (
                  <h4 className="card-title mb-4">
                    <FormattedMessage id="SUBSCRIPTION.SECTION_TITLES.ADDITIONAL_INFORMATION" />
                  </h4>
                )}
                {questions.map(question => (
                  <SummaryFieldComponent key={question.id}>
                    <strong>{question.question}</strong>
                    <div>
                      <FormattedMessage id="SUBSCRIPTION.LABEL.ANSWER_OPTIONS" />: {question.answers}
                    </div>
                    <div>{question.description}</div>
                  </SummaryFieldComponent>
                ))}
              </div>
            </>
          )}

          <div className={footerStyles}>
            <ButtonsWrapperComponent>
              <ButtonComponent theme="outline-secondary" size="large" to={`/subscriptions/${params.type}`}>
                <i className="fi fi-go-back-left-arrow" /> <FormattedMessage id="SUBSCRIPTION.BACK_TO_LIST" />
              </ButtonComponent>

              {disabled || (
                <>
                  <ButtonComponent
                    size="large"
                    theme="outline-secondary"
                    to={`/subscriptions/${params.type}/${params.subscription}/edit`}
                    className="ml-3"
                  >
                    <FormattedMessage id="CORE.EDIT_ORDER" />
                  </ButtonComponent>
                </>
              )}
              {this.props.totalBeneficiaries > 0 && (
                <ButtonComponent
                  theme="primary"
                  size="large"
                  className="ml-auto"
                  to={`/subscriptions/${params.type}/${params.subscription}/beneficiaries`}
                >
                  <FormattedMessage id="SUBSCRIPTION.TABS.BENEFICIARIES" />
                  <i className="fi fi-right-arrow-forward" />
                </ButtonComponent>
              )}
            </ButtonsWrapperComponent>
            {!this.props.loading &&
              this.props.totalBeneficiaries === 0 && (
                <div className={styles.noBeneficiariesInfo}>
                  <FormattedMessage id="SUBSCRIPTION.WAITING_FOR_EMPLOYEES" tagName="p" />
                </div>
              )}
          </div>
        </CardComponent>
      </>
    );
  }
}
