// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Switch } from 'react-router-dom';

import { UsersAddContainer, UsersEditRolesContainer } from 'app/common/users';
import { AlertComponent, ButtonComponent, CardComponent, ListComponent } from 'app/shared';
import { checkPermissions } from 'app/utils';

import { UsersListPlaceholder } from './placeholder/users-list.placeholder';
import { UsersListTableContainer } from './table/users-list-table.container';
import { UsersListTileContainer } from './tile/users-list-tile.container';

import type { Props } from './users-list.component.types';

export class UsersListComponent extends Component<Props> {
  render() {
    if (this.props.loading) {
      return (
        <UsersListPlaceholder
          match={this.props.match}
          context={this.props.context}
          permissions={this.props.permissions}
        />
      );
    }

    const { users } = this.props;

    return (
      <>
        <CardComponent>
          <div className="card-header">
            {!!this.props.location.state &&
              !!this.props.location.state.success && (
                <AlertComponent type="success">
                  {this.props.location.state.success.type === 'ADD_USER' && (
                    <FormattedMessage
                      id="USERS.ADD_NEW_USER_SUCCESS"
                      values={{ name: <strong>{this.props.location.state.success.payload}</strong> }}
                    />
                  )}
                  {this.props.location.state.success.type === 'EDIT_USER_ROLES' && (
                    <FormattedMessage
                      id="USERS.EDIT_ROLES_SUCCESS"
                      values={{ name: <strong>{this.props.location.state.success.payload}</strong> }}
                    />
                  )}
                </AlertComponent>
              )}

            <div className="d-flex align-items-center justify-content-xl-between">
              <h4 className="card-title">
                <FormattedMessage id="USERS.STATS" values={{ users: this.props.users.length }} />
              </h4>

              {checkPermissions(this.props.permissions, [`${this.props.context}Users`, 'create']) && (
                <ButtonComponent
                  theme="secondary"
                  size="medium"
                  className="ml-4 ml-xl-0"
                  to={`${this.props.match.url}/add`}
                >
                  <FormattedMessage id="USERS.ADD_NEW_USER" />
                </ButtonComponent>
              )}
            </div>
          </div>
          <div className="card-body">
            <ListComponent
              list={users}
              table={UsersListTableContainer}
              tile={UsersListTileContainer}
              props={{ context: this.props.context }}
            />
          </div>
        </CardComponent>

        <Switch>
          <Route
            path={`${this.props.match.url}/add`}
            render={() => (
              <UsersAddContainer
                context={this.props.context}
                customer={this.props.customer}
                pathname={this.props.match.url}
                userManagementRoles={this.props.userManagementRoles}
              />
            )}
          />

          <Route
            path={`${this.props.match.url}/:user/roles`}
            render={({
              match: {
                params: { user },
              },
            }) => (
              <UsersEditRolesContainer
                context={this.props.context}
                customer={this.props.customer}
                pathname={this.props.match.url}
                userManagementRoles={this.props.userManagementRoles}
                user={Number(user)}
              />
            )}
          />
        </Switch>
      </>
    );
  }
}
