// @flow

import React, { PureComponent } from 'react';
import classNames from 'classnames';

import type { Props } from './placeholder.component.types';
import styles from './placeholder.module.scss';

export class PlaceholderComponent extends PureComponent<Props> {
  render() {
    const placeholderClassNames = classNames(styles.placeholder, {
      [styles[this.props.color]]: !!this.props.color,
    });

    return (
      <span
        className={placeholderClassNames}
        style={{ borderRadius: this.props.radius, height: this.props.height, maxWidth: this.props.width }}
      />
    );
  }
}
