// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent, CardComponent, ListComponent, PlaceholderComponent } from 'app/shared';
import { checkPermissions } from 'app/utils';

import { UsersListTablePlaceholder } from './table/users-list-table.placeholder';
import { UsersListTilePlaceholder } from './tile/users-list-tile.placeholder';

import type { Props } from './users-list.placeholder.types';

export class UsersListPlaceholder extends PureComponent<Props> {
  static defaultProps = {
    users: [...new Array(5)],
  };

  render() {
    return (
      <CardComponent>
        <div className="card-header">
          <div className="d-flex align-items-center justify-content-xl-between" style={{ minHeight: 40 }}>
            <h4 className="card-title col p-0">
              <PlaceholderComponent width={150} />
            </h4>

            {checkPermissions(this.props.permissions, [`${this.props.context}Users`, 'create']) && (
              <ButtonComponent
                theme="secondary"
                size="medium"
                className="ml-4 ml-xl-0"
                to={`${this.props.match.url}/add`}
              >
                <FormattedMessage id="USERS.ADD_NEW_USER" />
              </ButtonComponent>
            )}
          </div>
        </div>
        <div className="card-body">
          <ListComponent list={this.props.users} table={UsersListTablePlaceholder} tile={UsersListTilePlaceholder} />
        </div>
      </CardComponent>
    );
  }
}
