import { compose } from 'redux';
import { connect } from 'react-redux';

import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';
import { fetchSubscription } from 'app/redux/subscriptions/subscriptions.actions';

import { EditSubscriptionComponent } from './edit-subscription.component';

const mapStateToProps = (
  { dictionaries, subscriptions: { entities: subscriptions } },
  {
    match: {
      params: { subscription, type },
    },
  },
) => ({
  loading: !dictionaries[`products/${type}`] || !subscriptions[subscription],
  subscription: subscriptions[subscription],
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { subscription, type },
    },
  },
) => ({
  fetchDictionary: () => dispatch(fetchDictionary(`products/${type}`)),
  fetchSubscription: () => dispatch(fetchSubscription(subscription, true)),
});

export const EditSubscriptionContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(EditSubscriptionComponent);
