import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redux';
import { get } from 'lodash';

import { withAuthorization } from 'app/common';
import { withCoreContext } from 'app/core/core.context';

import { fetchBeneficiary, deleteBeneficiary } from 'app/redux/beneficiaries/beneficiaries.actions';

import { BeneficiaryDetailsComponent } from './beneficiary-details.component';

const mapStateToProps = (
  { beneficiaries: { entities: beneficiaries, deleting } },
  {
    match: {
      params: { beneficiary },
    },
  },
) => ({
  beneficiary: get(beneficiaries, [beneficiary, 'personal']),
  loading: !get(beneficiaries, [beneficiary, 'personal']),
  deleting,
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { beneficiary },
    },
  },
) => ({
  fetchBeneficiary: () => dispatch(fetchBeneficiary(beneficiary)),
  onDeleteBeneficiary: () =>
    dispatch(deleteBeneficiary(beneficiary)).then(({ meta: { name } }) =>
      dispatch(
        push({
          pathname: '/beneficiaries/',
          state: {
            success: {
              type: 'BENEFICIARY_DELETED',
              name,
            },
          },
        }),
      ),
    ),
});

export const BeneficiaryDetailsContainer = compose(
  withAuthorization(['companyBeneficiaries', 'read']),
  withCoreContext,
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(BeneficiaryDetailsComponent);
