// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ModalComponent, PlaceholderComponent } from 'app/shared';

import type { Props } from './reports-details.placeholder.types';

const ChartPlaceholder = () => (
  <>
    <div className="row pt-2">
      <div className="col-12 text-center mb-2">
        <PlaceholderComponent width={320} height={30} color="primary" />
      </div>
    </div>
    <div className="row pb-2 align-items-center">
      <div className="col-12" />
      <div className="col-12 text-center">
        <PlaceholderComponent width={250} height={250} radius={175} />
      </div>
      <div className="col-12 mt-2 d-flex align-items-center justify-content-center">
        <div style={{ width: 170, marginRight: 16 }}>
          <PlaceholderComponent width={10} height={10} /> <PlaceholderComponent width={150} height={10} />
        </div>
        <div style={{ width: 160 }}>
          <PlaceholderComponent width={10} height={10} /> <PlaceholderComponent width={130} height={10} />
        </div>
      </div>
    </div>
  </>
);

export class ReportsDetailsPlaceholder extends Component<Props> {
  render() {
    return (
      <ModalComponent onClose={this.props.onClose} title={<FormattedMessage id="SALDO_REPORTS.REPORT_DETAILS" />}>
        <div className="mb-3">
          <PlaceholderComponent width={720} height={40} radius={3} />
        </div>
        <ChartPlaceholder />
        <hr />
        <ChartPlaceholder />
      </ModalComponent>
    );
  }
}
