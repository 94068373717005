// @flow

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

import { FormattedMessage } from 'react-intl';

import { ModalContentComponent } from './content/modal-content.component';

import { ButtonComponent } from 'app/shared';

import type { Props } from './modal.component.types';
import styles from './modal.module.scss';

export class ModalComponent extends Component<Props> {
  portal: ?HTMLElement;
  element: HTMLDivElement;

  constructor(props: Props) {
    super(props);

    this.portal = document.getElementById('modal-root');
    this.element = document.createElement('div');
  }

  componentDidMount() {
    if (document.body) {
      document.body.classList.add('modal-open');
    }

    if (document.body && !this.props.isClosingBlocked) {
      document.addEventListener('keydown', this.onKeyDown);
    }

    if (this.portal) {
      this.portal.appendChild(this.element);
    }
  }

  componentWillUnmount() {
    if (document.body) {
      document.body.classList.remove('modal-open');
    }

    document.removeEventListener('keydown', this.onKeyDown);

    if (this.portal) {
      this.portal.removeChild(this.element);
    }
  }

  onKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === 27 && this.props.onClose) {
      this.props.onClose();
    }
  };

  contentRenderer = () => {
    const dialogClassNames = classNames('modal-dialog', {
      [styles.small]: this.props.small,
    });

    return (
      <div className="modal-backdrop">
        <div className={classNames('modal', styles.modal)}>
          <div className={dialogClassNames}>
            <ModalContentComponent onClose={this.props.onClose} isClosingBlocked={this.props.isClosingBlocked}>
              <div className={classNames('modal-content', styles.content)}>
                {!!this.props.title && (
                  <div className={classNames('modal-header', styles.header)}>
                    <h4
                      className={classNames('modal-title', styles.title, this.props.titleCenter && styles.titleCenter)}
                    >
                      {this.props.title}
                    </h4>
                    {this.props.subtitle && <p className={styles.subtitle}>{this.props.subtitle}</p>}
                  </div>
                )}
                <div className="modal-body">{this.props.children}</div>
                <div className={classNames('modal-footer', styles.footer)}>
                  {this.props.additionalButton && (
                    <div className={styles.additionalButton}>{this.props.additionalButton}</div>
                  )}
                  {this.props.changeButtonsOrder && this.props.submitButton}
                  {!this.props.isClosingBlocked && (
                    <ButtonComponent
                      theme={this.props.submitButton && !this.props.changeButtonsOrder ? 'link' : 'outline-secondary'}
                      onClick={this.props.onClose}
                      size="medium"
                    >
                      <FormattedMessage id="CORE.CLOSE" />
                    </ButtonComponent>
                  )}
                  {!this.props.changeButtonsOrder && this.props.submitButton}
                </div>
              </div>
            </ModalContentComponent>
          </div>
        </div>
      </div>
    );
  };

  render() {
    // TODO: Remove outer div when portals are supported
    return (
      <div>
        {ReactDOM.createPortal(
          this.props.form ? <form {...this.props.form}>{this.contentRenderer()}</form> : this.contentRenderer(),
          this.element,
        )}
      </div>
    );
  }
}
