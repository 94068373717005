import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { get } from 'lodash';
import URLSearchParams from '@ungap/url-search-params';

import { withPagination } from 'app/common';

import { download } from 'app/redux/downloads/downloads.actions';
import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';
import {
  editBeneficiary,
  editBeneficiaries,
  fetchBeneficiaries as fetchSubscriptionBeneficiaries,
  fetchSubscription,
} from 'app/redux/subscriptions/subscriptions.actions';
import { fetchBeneficiaries } from 'app/redux/beneficiaries/beneficiaries.actions';

import { SubscriptionBeneficiariesComponent } from './subscription-beneficiaries.component';

const getParams = search => {
  const searchParams = new URLSearchParams(search);
  return {
    size: searchParams.get('size') || 100,
    page: searchParams.get('page') || 1,
  };
};

const mapStateToProps = (
  {
    beneficiaries,
    dictionaries,
    subscriptions: { beneficiaries: subscriptionBeneficiaries, entities: subscriptions },
    users: { business },
  },
  {
    match: {
      params: { subscription, type },
    },
  },
) => ({
  beneficiaries: get(subscriptionBeneficiaries, [subscription, 'list']),
  loading:
    !subscriptionBeneficiaries[subscription] ||
    !subscriptions[subscription] ||
    !get(beneficiaries, [business]) ||
    !dictionaries[`products/${type}`],
  meta: get(subscriptionBeneficiaries, [subscription, 'meta']),
  subscription: subscriptions[subscription],
  beneficiariesList: get(beneficiaries, [business, 'list']),
});

const mapDispatchToProps = (
  dispatch,
  {
    intl: { locale },
    match: {
      params: { subscription, type },
    },
    location: { search },
  },
) => ({
  fetch: params => dispatch(fetchSubscriptionBeneficiaries(subscription, params)),
  fetchCompanyBeneficiaries: () => dispatch(fetchBeneficiaries({ size: 5000 })),
  fetchDictionary: () => dispatch(fetchDictionary(`products/${type}`)),
  fetchSubscription: () => dispatch(fetchSubscription(subscription, true)),
  onBulkEdit: body => dispatch(editBeneficiaries(subscription, body)),
  onDownload: () =>
    dispatch(download(`subscriptions/${subscription}/beneficiaries`, locale, 'filter-subscription-beneficiaries')),
  onEdit: (beneficiary, body) =>
    dispatch(editBeneficiary(subscription, beneficiary, body)).then(() =>
      dispatch(fetchSubscriptionBeneficiaries(subscription, getParams(search))),
    ),
});

export const SubscriptionBeneficiariesContainer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withPagination,
)(SubscriptionBeneficiariesComponent);
