// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Switch } from 'react-router-dom';

import { HeaderComponent } from 'app//core';
import { ListComponent } from 'app/shared/list/list.component';
import { AlertComponent, CardComponent } from 'app/shared';
import { OrderDetailsContainer } from 'app/orders/details/order-details.container';

import { OrdersListPlaceholder } from './placeholder/orders-list.placeholder';
import { OrdersListTableComponent } from './table/orders-list-table.component';
import { OrdersListTileComponent } from './tile/orders-list-tile.component';

import type { Props } from './orders-list.component.types';

export const CONTINUE_ORDER_WHITELIST = ['emoney', 'voucher'];

export class OrdersListComponent extends Component<Props> {
  // componentDidMount() {
  //   this.props.fetchOrders();
  // }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.business !== this.props.business) {
      this.props.fetch();
    }
  }

  render() {
    return (
      <>
        <HeaderComponent breadcrumbs={[{ icon: 'history' }]}>
          <FormattedMessage id="SIDEBAR.ORDER_HISTORY" tagName="h2" />
        </HeaderComponent>

        <CardComponent>
          <div className="card-header">
            {!!this.props.location.state &&
              !!this.props.location.state.success && (
                <AlertComponent type="success">
                  {this.props.location.state.success.type === 'CANCEL_ORDER' && (
                    <FormattedMessage id="ORDER_HISTORY.CANCEL_SUCCESS" />
                  )}

                  {this.props.location.state.success.type === 'BANKNOTES.ORDER.SUCCESS' && (
                    <FormattedMessage id="BANKNOTES.ORDER.SUCCESS" />
                  )}
                </AlertComponent>
              )}

            <h4 className="card-title">
              <FormattedMessage id="ORDER_HISTORY.TITLE" />
            </h4>
          </div>
          <div className="card-body">
            {this.props.loading && <OrdersListPlaceholder />}
            {this.props.loading || (
              <ListComponent
                list={this.props.orders}
                table={OrdersListTableComponent}
                tile={OrdersListTileComponent}
                searchable={false}
              />
            )}
            {this.props.pagination}
          </div>
        </CardComponent>

        <Switch>
          <Route path={`${this.props.match.url}/:order`} render={props => <OrderDetailsContainer {...props} />} />
        </Switch>
      </>
    );
  }
}
