// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent, TileComponent } from 'app/shared';
import { currencyFormatter, dateFormatter } from 'app/utils';

import type { Props } from './settlements-list-tile.component.types';

export class SettlementsListTileComponent extends Component<Props> {
  render() {
    return (
      <TileComponent>
        <h3>{this.props.tile.erpId}</h3>

        <div className="row">
          <div className="col">
            <FormattedMessage id="SETTLEMENTS.LIST.HEADERS.DATE" tagName="small" />
            {dateFormatter(this.props.tile.processed)}
          </div>
          <div className="col">
            <FormattedMessage id="SETTLEMENTS.LIST.HEADERS.GROSS_AMOUNT" tagName="small" />
            {currencyFormatter.format(this.props.tile.amount)}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <FormattedMessage id="SETTLEMENTS.LIST.HEADERS.INTERVAL" tagName="small" />
            {dateFormatter(this.props.tile.fromDate)} - {dateFormatter(this.props.tile.toDate)}
          </div>
        </div>
        <div className="buttons">
          <ButtonComponent theme="secondary" size="small" onClick={() => this.props.onDownload(this.props.tile.id)}>
            <FormattedMessage id="SETTLEMENTS.BREAKDOWN" />
          </ButtonComponent>
        </div>
      </TileComponent>
    );
  }
}
