import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';

import { NewPasswordComponent } from './new-password.component';

import { resetPassword } from 'app/redux/users/users.actions';

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { passwordToken },
    },
  },
) => ({
  onSubmit: body => dispatch(resetPassword(passwordToken, body)),
});

export const NewPasswordContainer = compose(
  injectIntl,
  connect(
    null,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'new-password',
  }),
)(NewPasswordComponent);
