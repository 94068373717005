// @flow

import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { push } from 'react-router-redux';
import get from 'lodash/get';

import { addUser } from 'app/redux/users/users.actions';
import { USER_ROLES } from 'app/constants';

import { UsersAddComponent } from './users-add.component';

const getRoles = (dictionaries, context, users) => {
  const userRole = get(users, 'role');
  const rolesFilteredByVisibility = [USER_ROLES.ROLE_SERVICE_PROVIDER_MANAGER, USER_ROLES.ROLE_COMPANY_BUYER];

  return dictionaries[`user-roles/${context}`].filter(role => {
    if (rolesFilteredByVisibility.includes(userRole)) {
      return role.visible;
    }
    return role.editable;
  });
};

const mapStateToProps = ({ dictionaries, users }, { context, userManagementRoles }) => ({
  initialValues: {
    role: ((userManagementRoles || getRoles(dictionaries, context, users))[0] || {}).name,
  },
  roles: userManagementRoles || getRoles(dictionaries, context, users),
});

const mapDispatchToProps = (dispatch, { context, customer, pathname }) => ({
  onClose: () => dispatch(push(pathname)),
  onSubmit: body => dispatch(addUser(customer, context, body)).then(() => `${body.firstName} ${body.lastName}`),
  onSubmitSuccess: name =>
    dispatch(
      push({
        pathname,
        state: {
          success: {
            type: 'ADD_USER',
            payload: name,
          },
        },
      }),
    ),
});

export const UsersAddContainer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({ form: 'add-user' }),
)(UsersAddComponent);
