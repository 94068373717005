// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { PlaceholderComponent, TableComponent } from 'app/shared';

import type { Props } from './orders-list-table.placeholder.types';

export class OrdersListTablePlaceholder extends Component<Props> {
  render() {
    return (
      <TableComponent className="table-striped">
        <thead>
          <tr>
            <FormattedMessage id="ORDER_HISTORY.NUMBER" tagName="th" />
            <FormattedMessage id="ORDER_HISTORY.DATE" tagName="th" />
            <th className="text-right">
              <FormattedMessage id="ORDER_HISTORY.BENEFITS" />
            </th>
            <FormattedMessage id="ORDER_HISTORY.STATUS" tagName="th" />
            <th />
          </tr>
        </thead>
        <tbody>
          {this.props.list.map((_, card) => (
            <tr key={card}>
              <td>
                <PlaceholderComponent width={100} />
              </td>
              <td>
                <PlaceholderComponent width={120} />
              </td>
              <td className="text-right">
                <PlaceholderComponent width={card % 2 === 0 ? 150 : 200} />
              </td>
              <td>
                <PlaceholderComponent width={80} />
              </td>
              <td />
            </tr>
          ))}
        </tbody>
      </TableComponent>
    );
  }
}
