import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { MyAccountsListTileComponent } from './my-accounts-list-tile.component';

const mapStateToProps = ({ permissions, users: { business, role } }) => ({
  permissions: permissions[business][role],
});

export const MyAccountsListTileContainer = compose(
  injectIntl,
  connect(mapStateToProps),
)(MyAccountsListTileComponent);
