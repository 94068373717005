// @flow

import React, { Component } from 'react';
import { DateRangePicker } from 'react-dates';
import { START_DATE, END_DATE } from 'react-dates/constants';
import classNames from 'classnames';
import moment from 'moment';

import uniqueId from 'lodash/uniqueId';
import get from 'lodash/get';

import { ConnectedGroupComponent } from 'app/shared';

import type { DatesType, FocusedInputType, Props, State } from './connected-date-range.component.types';
import type { FieldProps } from 'redux-form/es';
import { CalendarCloseButtonComponent } from '../../calendar-close-button/calendar-close-button.component';
import './connected-date-range.scss';

export class ConnectedDateRangeComponent extends Component<Props, State> {
  onFocusChange = (startDate?: FieldProps, endDate?: FieldProps) => (focusedInput: FocusedInputType) => {
    this.setState({ focusedInput });
    this.props.onFocusChange(focusedInput);

    if (focusedInput === START_DATE) {
      // $FlowFixMe
      startDate.input.onFocus();
    }
    if (focusedInput === END_DATE) {
      // $FlowFixMe
      endDate.input.onFocus();
    }
  };

  onDatesChange = (startDate: FieldProps, endDate: FieldProps) => (dates: DatesType) => {
    startDate.input.onChange(dates.startDate);
    endDate.input.onChange(dates.endDate);

    if (this.props.onDatesChange && dates.startDate && dates.endDate) {
      this.props.onDatesChange(dates);
    }
  };

  closeCalendarHandler: Function = () => {
    this.setState({ focusedInput: null });
    this.onFocusChange()(null);
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      focusedInput: null,
    };
  }

  render() {
    const startDate = get(this.props, this.props.relativeNames[0]);
    const endDate = get(this.props, this.props.relativeNames[1]);

    const id = uniqueId('date_range_');
    const startDateId = uniqueId('start_date_id_');
    const endDateId = uniqueId('end_date_id_');
    const meta = {
      active: startDate.meta.active || endDate.meta.active,
      asyncValidating: startDate.meta.asyncValidating || endDate.meta.asyncValidating,
      autofilled: startDate.meta.autofilled || endDate.meta.autofilled,
      dirty: startDate.meta.dirty || endDate.meta.dirty,
      dispatch: startDate.meta.dispatch,
      error: startDate.meta.error || endDate.meta.error,
      form: startDate.meta.form,
      invalid: startDate.meta.invalid || endDate.meta.invalid,
      pristine: startDate.meta.pristine || endDate.meta.pristine,
      submitting: startDate.meta.submitting || endDate.meta.submitting,
      submitFailed: startDate.meta.submitFailed,
      touched: startDate.meta.touched || endDate.meta.touched,
      valid: startDate.meta.valid || endDate.meta.valid,
      visited: startDate.meta.visited || endDate.meta.visited,
    };
    const invalid = meta.submitFailed && meta.error;

    const wrapperClassNames = classNames({
      'DateRangeWrapper--focused': !!this.state.focusedInput,
      'is-invalid': invalid,
    });

    return (
      <ConnectedGroupComponent id={id} label={this.props.label} required={this.props.required} meta={meta}>
        <div className={wrapperClassNames}>
          <DateRangePicker
            startDateId={startDateId}
            endDateId={endDateId}
            startDate={startDate.input.value ? moment(startDate.input.value) : null}
            endDate={endDate.input.value ? moment(endDate.input.value) : null}
            focusedInput={this.state.focusedInput}
            onFocusChange={this.onFocusChange(startDate, endDate)}
            onDatesChange={this.onDatesChange(startDate, endDate)}
            customArrowIcon={<i className="fi fi-right-arrow-forward" />}
            startDatePlaceholderText={this.props.intl.formatMessage({ id: 'CORE.START_DATE' })}
            endDatePlaceholderText={this.props.intl.formatMessage({ id: 'CORE.END_DATE' })}
            customInputIcon={<i className="fi fi-time" />}
            navPrev={<i className="fi fi-go-back-left-arrow" />}
            navNext={<i className="fi fi-right-arrow-forward" />}
            displayFormat="DD.MM.YYYY"
            isOutsideRange={() => false}
            hideKeyboardShortcutsPanel
            enableOutsideDays
            minimumNights={0}
            daySize={48}
            anchorDirection={this.props.anchorDirection}
            keepOpenOnDateSelect={true}
            calendarInfoPosition="top"
            renderCalendarInfo={() => <CalendarCloseButtonComponent onClick={this.closeCalendarHandler} />}
          />
        </div>
      </ConnectedGroupComponent>
    );
  }
}
