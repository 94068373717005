// @flow

import React, { Component } from 'react';

import { SidebarGroupComponent, SidebarItemContainer, SidebarTitleComponent } from 'app/core/sidebar/shared';
import { checkPermissions } from 'app/utils';

import type { Props } from './sidebar-account.component.types';

export class SidebarAccountComponent extends Component<Props> {
  render() {
    if (
      !checkPermissions(
        this.props.permissions,
        ['beneficiaryAccounts', 'read'],
        ['beneficiaryCards', 'read'],
        ['beneficiaryLatestTransactions', 'read'],
        ['beneficiarySubscriptions', 'read'],
        ['municipalOrders', 'read'],
        ['companyTransactions', 'read'],
        ['municipalBeneficiaryTransactions', 'read'],
        ['municipalTransactions', 'read'],
        ['visaTransactions', 'read'],
        ['municipalAccount', 'read'],
      )
    ) {
      return null;
    }

    return (
      <>
        <SidebarTitleComponent>{this.props.intl.formatMessage({ id: 'NAV.ACCOUNT.TITLE' })}</SidebarTitleComponent>
        <SidebarGroupComponent>
          {checkPermissions(this.props.permissions, ['beneficiaryAccounts', 'read']) && (
            <SidebarItemContainer
              icon="account-balance"
              name={this.props.intl.formatMessage({ id: 'NAV.ACCOUNT.ACCOUNTS_INFORMATION' })}
              to="/accounts"
            />
          )}

          {checkPermissions(this.props.permissions, ['beneficiaryLatestTransactions', 'read']) && (
            <SidebarItemContainer
              icon="transactionhistory"
              name={this.props.intl.formatMessage({ id: 'NAV.ACCOUNT.LATEST_TRANSACTIONS' })}
              to="/transactions/latest"
            />
          )}

          {checkPermissions(this.props.permissions, ['beneficiarySubscriptions', 'read']) && (
            <SidebarItemContainer
              icon="subscription"
              name={this.props.intl.formatMessage({ id: 'NAV.ACCOUNT.SUBSCRIPTIONS' })}
              to="/beneficiary/subscriptions"
            />
          )}

          {checkPermissions(this.props.permissions, ['municipalOrders', 'read']) && (
            <SidebarItemContainer
              icon="account-balance"
              name={this.props.intl.formatMessage({ id: 'NAV.ACCOUNT.ORDER_EMONEY' })}
              to="/orders/create/emoney"
            />
          )}

          {checkPermissions(this.props.permissions, ['companyTransactions', 'read']) && (
            <SidebarItemContainer
              icon="history"
              name={this.props.intl.formatMessage({ id: 'NAV.HISTORY.COMPANY_TRANSACTIONS' })}
              to="/transactions/company"
            />
          )}

          {checkPermissions(this.props.permissions, ['municipalTransactions', 'read']) && (
            <SidebarItemContainer
              icon="history"
              name={this.props.intl.formatMessage({ id: 'NAV.HISTORY.MUNICIPAL_TRANSACTIONS' })}
              to="/transactions/municipal"
            />
          )}

          {checkPermissions(this.props.permissions, ['visaTransactions', 'read']) && (
            <SidebarItemContainer
              icon="history"
              name={this.props.intl.formatMessage({ id: 'NAV.HISTORY.VISA_TRANSACTIONS' })}
              to="/transactions/visa"
            />
          )}

          {checkPermissions(this.props.permissions, ['municipalAccount', 'read']) && (
            <SidebarItemContainer
              icon="history"
              name={this.props.intl.formatMessage({ id: 'NAV.MANAGEMENT.CUSTOMER_ACCOUNTS' })}
              to="/accounts/municipal"
            />
          )}

          {checkPermissions(this.props.permissions, ['municipalBeneficiaryTransactions', 'read']) && (
            <SidebarItemContainer
              icon="history"
              name={this.props.intl.formatMessage({ id: 'NAV.MANAGEMENT.MUNICIPAL_BENEFICIARY_TRANSACTIONS' })}
              to="/transactions/municipal-beneficiary"
            />
          )}

          {checkPermissions(this.props.permissions, ['', 'read']) && ( //to show, change empty string to 'venueSettlements'
            <SidebarItemContainer
              icon="settlements"
              name={this.props.intl.formatMessage({ id: 'NAV.ADDITIONAL.SETTLEMENTS' })}
              to="/settlements"
            />
          )}
        </SidebarGroupComponent>
      </>
    );
  }
}
