import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Fields } from 'redux-form';

import { ButtonComponent, ConnectedDateRangeComponent, ModalComponent, AlertComponent } from 'app/shared';

import type { Props } from './edit-contract.component.types';
import { getFormValues } from 'redux-form';
import styles from './edit-contract.module.scss';

export class EditContractComponent extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isMakeNewContractOpen: false,
      isConfirmationVisible: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.state &&
      this.props.location.state &&
      (prevProps.location.state.fail !== this.props.location.state.fail ||
        prevProps.location.state.success !== this.props.location.state.success)
    ) {
      this.onCloseConfirmation();
    }
  }

  onClickRemove = () => {
    this.setState({ isConfirmationVisible: true });
  };

  onCloseConfirmation = () => {
    this.setState({ isConfirmationVisible: false });
  };

  onCalendarToggle = (state: any) => this.setState({ isMakeNewContractOpen: !!state });

  render() {
    const dates = getFormValues('edit-contract')({ form: this.props.editForm }) || {};
    const {
      location: { state },
      intl,
      onClose,
      handleSubmit,
      submitting,
    } = this.props;

    const success = !!state && !!state.success;
    const fail = !!state && !!state.fail;

    const submitButton = (
      <ButtonComponent theme="primary" size="medium" loading={submitting} disabled={!dates.startDate}>
        {intl.formatMessage({ id: 'CORE.CONFIRM' })}
      </ButtonComponent>
    );

    const additionalButton = (
      <ButtonComponent theme="link" onClick={this.onClickRemove}>
        <FormattedMessage id="CONTRACTS.TERMINATE_CONTRACT" />
      </ButtonComponent>
    );

    return (
      <>
        {!this.state.isConfirmationVisible && (
          <ModalComponent
            title={<FormattedMessage id="CONTRACTS.EDIT_CONTRACT" />}
            onClose={onClose}
            form={{ onSubmit: handleSubmit }}
            submitButton={!success && !fail && submitButton}
            titleCenter
            additionalButton={!success && !fail && additionalButton}
          >
            {success && (
              <AlertComponent type="success">
                <FormattedMessage id="CONTRACTS.SUCCESS_TITLE" tagName="h5" />
              </AlertComponent>
            )}
            {fail && (
              <AlertComponent type="danger">
                <FormattedMessage id="CONTRACTS.FAIL_TITLE" tagName="h5" />
                <FormattedMessage id="CONTRACTS.FAIL_MESSAGE" />
              </AlertComponent>
            )}
            {state &&
              !success &&
              !fail && (
                <div className={styles.subtitle}>
                  {state.venue}
                  <br />
                  {state.address} | {state.phoneNumber}
                  <br />
                  <FormattedMessage
                    id="CONTRACTS.BENEFIT_TYPE"
                    values={{ type: intl.formatMessage({ id: `BENEFITS.${state.benefitType}.TITLE` }) }}
                  />
                </div>
              )}
            {!success &&
              !fail && (
                <Fields
                  names={['startDate', 'endDate']}
                  component={ConnectedDateRangeComponent}
                  relativeNames={['startDate', 'endDate']}
                  onFocusChange={this.onCalendarToggle}
                  label={intl.formatMessage({ id: 'CONTRACTS.PERIOD' })}
                  parse={value => (value ? value.format('YYYY-MM-DD') : null)}
                  intl={intl}
                  required
                />
              )}
          </ModalComponent>
        )}
        {this.state.isConfirmationVisible && (
          <ModalComponent
            title={this.props.intl.formatMessage({ id: 'CONTRACTS.DELETE_CONFIRMATION_TITLE' })}
            onClose={this.onCloseConfirmation}
            form={{ onSubmit: handleSubmit(this.props.onRemove) }}
            submitButton={
              <ButtonComponent theme="danger" size="medium" loading={submitting}>
                <FormattedMessage id="CORE.DELETE" />
              </ButtonComponent>
            }
          >
            <FormattedMessage id="CONTRACTS.DELETE_CONFIRMATION_MESSAGE" />
          </ModalComponent>
        )}
      </>
    );
  }
}
