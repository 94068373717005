import React, { Component } from 'react';

export default class ContractsPlaceholder extends Component {
  render() {
    return Array.from({ length: 3 }).map((_, index) => (
      <tr key={index}>
        <td />
        <td />
        <td />
        <td />
        <td />
      </tr>
    ));
  }
}
