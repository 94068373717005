// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { PlaceholderComponent, TileComponent } from 'app/shared';

export class BeneficiariesListTilePlaceholder extends Component<any> {
  render() {
    return (
      <TileComponent>
        <h3>
          <PlaceholderComponent width={150} height={27} />
        </h3>

        <div className="row">
          <div className="col">
            <FormattedMessage id="BENEFICIARIES.LIST_HEADERS.EMAIL" tagName="small" />
            <PlaceholderComponent width={100} height={18} />
          </div>
          <div className="col">
            <FormattedMessage id="BENEFICIARIES.LIST_HEADERS.SSN" tagName="small" />
            <PlaceholderComponent width={100} height={18} />
          </div>
        </div>
      </TileComponent>
    );
  }
}
