// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import isEqual from 'lodash/isEqual';

import { HeaderComponent } from 'app/core';
import { AlertComponent, CardComponent } from 'app/shared';

import { EditVenuePlaceholder } from './placeholder/edit-venue.placeholder';
import { VenueFormContainer } from './venue-form.container';

import type { Props } from './edit-venue.component.types';
import styles from './edit-venue.module.scss';

export class EditVenueComponent extends Component<Props> {
  componentDidMount() {
    this.props.fetchDictionary();
    this.props.fetchVenue();
  }

  shouldComponentUpdate(nextProps: Props) {
    return (
      this.props.business !== nextProps.business ||
      !isEqual(this.props.location.state, nextProps.location.state) ||
      !isEqual(this.props.venue, nextProps.venue) ||
      !isEqual(this.props.intl.locale, nextProps.intl.locale)
    );
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.business !== this.props.business) {
      this.props.changeVenue(this.props.business);
    }

    if (prevProps.location.pathname !== this.props.location.pathname && !this.props.venue) {
      this.props.fetchDictionary();
      this.props.fetchVenue();
    }
  }

  render() {
    if (this.props.loading) {
      return <EditVenuePlaceholder />;
    }

    return (
      <>
        <HeaderComponent
          breadcrumbs={[
            {
              icon: 'business',
              to: '/venues',
            },
            {
              name: <FormattedMessage id="VENUES.EDIT_VENUE" values={{ name: this.props.venue.venue.name }} />,
            },
          ]}
        >
          <FormattedMessage id="VENUES.EDIT_VENUE" values={{ name: this.props.venue.venue.name }} tagName="h2" />

          {this.props.venue &&
            this.props.venue.venue.contractTerminationDate && (
              <p className={styles.terminationCotractText}>
                <FormattedMessage
                  id="VENUES.CONTRACT_TERMINATION_STATUS_TEXT"
                  values={{
                    date: this.props.venue.venue.contractTerminated
                      ? this.props.venue.venue.contractTerminated
                      : this.props.venue.venue.contractTerminationDate,
                  }}
                />
                <span className={styles.terminationCotractStatus}>
                  {this.props.venue.venue.contractTerminated
                    ? this.props.intl.formatMessage({ id: 'VENUES.CONTRACT_TERMINATION_PROCESSED' })
                    : this.props.intl.formatMessage({ id: 'VENUES.CONTRACT_TERMINATION_IN_PROGRESS' })}
                </span>
              </p>
            )}
        </HeaderComponent>

        <CardComponent>
          {!!this.props.location.state && (
            <div className="card-header">
              {!!this.props.location.state.success && (
                <AlertComponent type="success" className="mb-0">
                  {this.props.location.state.success.type === 'EDIT_VENUE' && (
                    <FormattedMessage
                      id="VENUES.EDIT_VENUE_SUCCESS"
                      values={{ name: <strong>{this.props.location.state.success.payload}</strong> }}
                    />
                  )}
                </AlertComponent>
              )}
              {!!this.props.location.state.error && (
                <AlertComponent type="danger" className="mb-0">
                  {this.props.location.state.error.type === 'VALIDATION_ERROR' && (
                    <FormattedMessage id="VALIDATION.FORM_ERROR" />
                  )}
                </AlertComponent>
              )}
            </div>
          )}

          <VenueFormContainer venue={this.props.venue} module={this.props.module} />
        </CardComponent>
      </>
    );
  }
}
