import { RSAA } from 'redux-api-middleware';
import { change, getFormValues } from 'redux-form';
import { createAction } from 'redux-actions';

import * as types from './subscriptions.action-types';

export const createSubscription = body => ({
  [RSAA]: {
    types: [types.CREATE_SUBSCRIPTION_REQUEST, types.CREATE_SUBSCRIPTION_SUCCESS, types.CREATE_SUBSCRIPTION_FAILURE],
    endpoint: '/subscriptions',
    method: 'POST',
    body,
  },
});

export const editSubscription = (subscription, body) => ({
  [RSAA]: {
    types: [types.EDIT_SUBSCRIPTION_REQUEST, types.EDIT_SUBSCRIPTION_SUCCESS, types.EDIT_SUBSCRIPTION_FAILURE],
    endpoint: `/subscriptions/${subscription}`,
    method: 'PUT',
    body: {
      ...body,
      link: `${window.location.origin}/subscribe/${body.hash}`,
    },
  },
});

export const cloneSubscription = ({ subscriptionId, title }) => ({
  [RSAA]: {
    types: [types.CLONE_SUBSCRIPTION_REQUEST, types.CLONE_SUBSCRIPTION_SUCCESS, types.CLONE_SUBSCRIPTION_FAILURE],
    endpoint: `/subscriptions/${subscriptionId}/clone`,
    method: 'POST',
    body: {
      title,
    },
  },
});

export const importSubscription = ({ description, file, title }) => {
  const body = new FormData();

  body.append('description', description);
  body.append('file', file);
  body.append('title', title);

  return {
    [RSAA]: {
      types: [types.IMPORT_SUBSCRIPTION_REQUEST, types.IMPORT_SUBSCRIPTION_SUCCESS, types.IMPORT_SUBSCRIPTION_FAILURE],
      endpoint: '/subscriptions/csv',
      method: 'POST',
      body,
    },
  };
};

export const checkImportSubscription = id => ({
  [RSAA]: {
    types: [
      types.CHECK_IMPORT_SUBSCRIPTION_REQUEST,
      types.CHECK_IMPORT_SUBSCRIPTION_SUCCESS,
      types.CHECK_IMPORT_SUBSCRIPTION_FAILURE,
    ],
    endpoint: `/subscriptions/csv/status/${id}`,
    method: 'GET',
  },
});

export const setCancelledImportStatus = createAction(types.SET_CANCELLED_IMPORT_STATUS);

export const fetchSubscriptions = (type, params) => (dispatch, getState) => {
  const {
    users: { business },
  } = getState();

  return dispatch({
    [RSAA]: {
      types: [
        {
          type: types.FETCH_SUBSCRIPTIONS_REQUEST,
          meta: { business, type, params },
        },
        {
          type: types.FETCH_SUBSCRIPTIONS_SUCCESS,
          meta: { business, type },
        },
        types.FETCH_SUBSCRIPTIONS_FAILURE,
      ],
      endpoint: `/subscriptions/${type}`,
      method: 'GET',
    },
  });
};

export const fetchAllSubscriptions = (type, business) => dispatch =>
  dispatch({
    [RSAA]: {
      types: [
        {
          type: types.FETCH_ALL_SUBSCRIPTIONS_REQUEST,
          meta: { business, type },
        },
        {
          type: types.FETCH_ALL_SUBSCRIPTIONS_SUCCESS,
          meta: { business, type },
        },
        types.FETCH_ALL_SUBSCRIPTIONS_FAILURE,
      ],
      endpoint: `/subscriptions/${type}/all`,
      method: 'GET',
    },
  });

const fetchSubscriptionRSAA = subscription => ({
  [RSAA]: {
    types: [
      {
        type: types.FETCH_SUBSCRIPTION_REQUEST,
        meta: { subscription },
      },
      {
        type: types.FETCH_SUBSCRIPTION_SUCCESS,
        meta: { subscription },
      },
      types.FETCH_SUBSCRIPTION_FAILURE,
    ],
    endpoint: `/subscriptions/${subscription}`,
    method: 'GET',
  },
});

const shouldFetchSubscription = ({ subscriptions: { entities } }, subscription) => !entities[subscription];

export const fetchSubscription = (subscription, force = false) => (dispatch, getState) => {
  if (force || shouldFetchSubscription(getState(), subscription)) {
    return dispatch(fetchSubscriptionRSAA(subscription));
  } else {
    return Promise.resolve();
  }
};

export const fetchBeneficiaries = (subscription, params) => (dispatch, getState) => {
  const filters = getFormValues('filter-subscription-beneficiaries')(getState());

  return dispatch({
    [RSAA]: {
      types: [
        {
          type: types.FETCH_BENEFICIARIES_REQUEST,
          meta: {
            subscription,
            params: {
              size: 10,
              ...filters,
              ...params,
            },
          },
        },
        {
          type: types.FETCH_BENEFICIARIES_SUCCESS,
          meta: { subscription },
        },
        types.FETCH_BENEFICIARIES_FAILURE,
      ],
      endpoint: `/subscriptions/${subscription}/beneficiaries`,
      method: 'GET',
    },
  });
};

export const editBeneficiary = (subscription, beneficiary, body) => ({
  [RSAA]: {
    types: [
      {
        type: types.EDIT_BENEFICIARY_REQUEST,
        meta: {
          beneficiary,
          subscription,
        },
      },
      {
        type: types.EDIT_BENEFICIARY_SUCCESS,
        meta: {
          beneficiary,
          body,
          subscription,
        },
      },
      {
        type: types.EDIT_BENEFICIARY_FAILURE,
        meta: {
          beneficiary,
          subscription,
        },
      },
    ],
    endpoint: `/subscriptions/beneficiaries/${beneficiary}`,
    method: 'PATCH',
    body,
  },
});

export const addBeneficiary = (subscription, body) => (dispatch, getState) => {
  const { size } = getFormValues('filter-subscription-beneficiaries')(getState());

  return dispatch({
    [RSAA]: {
      types: [
        types.ADD_BENEFICIARY_REQUEST,
        {
          type: types.ADD_BENEFICIARY_SUCCESS,
          meta: {
            size,
            subscription,
          },
        },
        types.ADD_BENEFICIARY_FAILURE,
      ],
      endpoint: `/subscriptions/${subscription}/beneficiaries`,
      method: 'POST',
      body,
    },
  });
};

export const editBeneficiaries = (subscription, body) => ({
  [RSAA]: {
    types: [
      {
        type: types.EDIT_BENEFICIARIES_REQUEST,
        meta: {
          subscription,
        },
      },
      {
        type: types.EDIT_BENEFICIARIES_SUCCESS,
        meta: {
          body,
          subscription,
        },
      },
      {
        type: types.EDIT_BENEFICIARIES_FAILURE,
        meta: {
          subscription,
        },
      },
    ],
    endpoint: `/subscriptions/${subscription}/beneficiaries/batch`,
    method: 'PATCH',
    body,
  },
});

export const fetchPublicSubscription = subscription => ({
  [RSAA]: {
    types: [
      {
        type: types.FETCH_PUBLIC_SUBSCRIPTION_REQUEST,
        meta: { subscription },
      },
      {
        type: types.FETCH_PUBLIC_SUBSCRIPTION_SUCCESS,
        meta: { subscription },
      },
      types.FETCH_PUBLIC_SUBSCRIPTION_FAILURE,
    ],
    endpoint: `/subscriptions/public/${subscription}`,
    method: 'GET',
    isPublic: true,
  },
});

export const subscribe = (hash, body) => (dispatch, getState) => {
  const {
    subscriptions: {
      entities: { [hash]: subscription },
    },
  } = getState();

  return dispatch({
    [RSAA]: {
      types: [types.SUBSCRIBE_REQUEST, types.SUBSCRIBE_SUCCESS, types.SUBSCRIBE_FAILURE],
      endpoint: `/subscribe/${hash}`,
      method: 'POST',
      isPublic: subscription.type === 'VOUCHER',
      body,
    },
  });
};

export const changeSubscribeDelivery = (isHomeDelivery, hash) => (dispatch, getState) => {
  const {
    subscriptions: {
      entities: { [hash]: subscription },
    },
    users: { current, isAuthenticated },
  } = getState();

  if (isHomeDelivery) {
    return dispatch(change('subscribe', 'address', isAuthenticated ? current.personal.address : {}));
  } else {
    return dispatch(
      change('subscribe', 'address', {
        company: subscription.company.name,
        ...subscription.company.address,
      }),
    );
  }
};

export const sendEmails = (body, link, title) => ({
  [RSAA]: {
    types: [types.SEND_EMAILS_REQUEST, types.SEND_EMAILS_SUCCESS, types.SEND_EMAILS_FAILURE],
    endpoint: '/subscriptions/email',
    method: 'POST',
    body: {
      ...body,
      link,
      title,
    },
  },
});
