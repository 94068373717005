// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { currencyFormatter } from 'app/utils';

import { AgreementsAcceptanceContainer } from 'app/agreements/acceptance/agreements-acceptance.container';
import {
  AlertComponent,
  ButtonComponent,
  ButtonsWrapperComponent,
  SummaryFieldComponent,
  TableComponent,
} from 'app/shared';

import type { Props, State } from './subscription-order-confirm.component.types';
import styles from './subscription-order-confirm.module.scss';

export class SubscriptionOrderConfirmComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      terms: {
        onChange: () => this.setState(prev => ({ terms: { ...prev.terms, value: !prev.terms.value } })),
        value: false,
      },
    };
  }

  render() {
    const feeKey = this.props.order.type === 'voucher' ? 'DELIVERY_FEE' : 'INVOICING_FEE';
    const feeTaxKey = this.props.order.type === 'voucher' ? 'DELIVERY_FEE_TAX' : 'INVOICING_FEE_TAX';

    return (
      <>
        {this.props.chargedProducts &&
          this.props.chargedProducts.length > 0 && (
            <>
              <div className="card-body">
                <TableComponent className="table-striped">
                  <thead>
                    <tr className={styles.hederRow}>
                      <FormattedMessage id="ORDERS.PRODUCT" tagName="th" />
                      <FormattedMessage id="ORDERS.AMOUNT" tagName="th" />
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.chargedProducts.map((product, index) => [
                      <tr key={index}>
                        <td className={styles.type}>
                          <FormattedMessage id={`PRODUCT.${product.id}`} />
                          {product.amount < 0 && (
                            <FormattedMessage id="SUBSCRIPTION.ORDER.CHARGE_FROM_COMPANY_ACCOUNT" tagName="small" />
                          )}
                        </td>
                        <td className={classNames(styles.type, styles.amounts)}>
                          <span>{currencyFormatter.format(product.totalAmount)}</span>
                          <small>{currencyFormatter.format(product.amount)}</small>
                        </td>
                      </tr>,
                    ])}
                  </tbody>
                </TableComponent>
              </div>

              <div className={styles.invoiceHeader}>
                <h4 className="card-title">
                  <FormattedMessage id="ORDERS.INVOICE" />
                </h4>
              </div>
            </>
          )}

        <div className="card-body">
          <TableComponent className="table-striped">
            <thead>
              <tr className={styles.hederRow}>
                <FormattedMessage id="ORDERS.PRODUCT" tagName="th" />
                <FormattedMessage id="ORDERS.QUANTITY" tagName="th" />
                <FormattedMessage id="ORDERS.AMOUNT" tagName="th" />
              </tr>
            </thead>
            <tbody>
              {this.props.order.invoiceProducts.map((product, index) => [
                <tr key={index}>
                  <td className={styles.type}>
                    <FormattedMessage id={`PRODUCT.${product.id}`} />
                    {product.amount < 0 && (
                      <FormattedMessage id="SUBSCRIPTION.ORDER.CHARGE_FROM_COMPANY_ACCOUNT" tagName="small" />
                    )}
                  </td>

                  <td className={styles.amount}>
                    {this.props.order.type === 'emoney' && <div>1 x {currencyFormatter.format(product.amount)}</div>}
                    {this.props.order.type === 'voucher' && (
                      <div>
                        {product.quantity} x {currencyFormatter.format(product.unitAmount)}
                      </div>
                    )}
                    {product.commission > 0 && (
                      <>
                        <FormattedMessage
                          id="SUBSCRIPTION.ORDER.COMMISSION"
                          values={{
                            commission: currencyFormatter.format(product.commission),
                            commissionRate: `(${product.commissionRate}%)`,
                          }}
                          tagName="small"
                        />
                        <br />
                      </>
                    )}
                    {product.tax > 0 && (
                      <FormattedMessage
                        id="SUBSCRIPTION.ORDER.COMMISSION_TAX"
                        values={{ tax: currencyFormatter.format(product.tax), taxRate: `(${product.taxRate}%)` }}
                        tagName="small"
                      />
                    )}
                  </td>

                  <td className={styles.amounts}>{currencyFormatter.format(product.total)}</td>
                </tr>,
              ])}
              {this.props.order.type === 'voucher' && (
                <>
                  <tr>
                    <td className={styles.amount}>
                      <FormattedMessage id="ORDERS.NAMING_FEE" />
                      {this.props.showNamingFeeInfo && (
                        <>
                          <br />
                          <FormattedMessage
                            id="ORDERS.MIN_NAMING_FEE"
                            values={{
                              minNamingFee: currencyFormatter.format(this.props.order.minNamingFee),
                            }}
                            tagName="small"
                          />
                        </>
                      )}
                    </td>
                    <td className={styles.amount}>
                      {this.props.order.totalQuantity} x {currencyFormatter.format(this.props.order.unitNamingFee)}
                    </td>
                    <td className={styles.amounts}>
                      {this.props.order.namingFee && currencyFormatter.format(this.props.order.namingFee)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage
                        id="ORDERS.NAMING_FEE_TAX"
                        values={{
                          taxRate: this.props.order.namingFeeTaxRate ? `(${this.props.order.namingFeeTaxRate}%)` : '',
                        }}
                      />
                    </td>
                    <td />
                    <td className={styles.amounts}>
                      {this.props.order.namingFeeTax && currencyFormatter.format(this.props.order.namingFeeTax)}
                    </td>
                  </tr>
                </>
              )}
              <tr>
                <td>
                  <FormattedMessage id={`ORDERS.${feeKey}`} />
                </td>
                <td />
                <td className={styles.amounts}>{currencyFormatter.format(this.props.order.managementFee)}</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage
                    id={`ORDERS.${feeTaxKey}`}
                    values={{ taxRate: this.props.order.taxRate ? `(${this.props.order.taxRate}%)` : '' }}
                  />
                </td>
                <td />
                <td className={styles.amounts}>{currencyFormatter.format(this.props.order.tax)}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <FormattedMessage id="ORDERS.ORDER_TOTAL" />
                </td>
                <td />
                <td className={styles.amounts}>{currencyFormatter.format(this.props.order.grossTotalAmount)}</td>
              </tr>
            </tfoot>
          </TableComponent>
        </div>
        <div className="card-header card-header--between">
          {this.props.match.params.type === 'voucher' && (
            <AlertComponent type="light">
              <span className="font-weight-bold">
                <FormattedMessage id="CORE.NOTE" />!
              </span>{' '}
              <FormattedMessage id="SUBSCRIPTION.ORDER.VALID_UNTIL" />
            </AlertComponent>
          )}

          <h4 className="card-title">
            <FormattedMessage id="ORDERS.INVOICING_DETAILS" />
          </h4>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-6">
              <SummaryFieldComponent>
                <FormattedMessage id="ORDERS.INVOICING_METHOD" tagName="strong" />
                <FormattedMessage id={`INVOICING.${this.props.values.invoicing.invoicingMethod}.NAME`} />
              </SummaryFieldComponent>
            </div>
            <div className="col-lg-6">
              <SummaryFieldComponent>
                <FormattedMessage id="ORDERS.ORDER_REFERENCE" tagName="strong" />
                {this.props.values.invoicing.invoiceReference}
              </SummaryFieldComponent>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <SummaryFieldComponent>
                <FormattedMessage id="ORDERS.OFFICIAL_NAME" tagName="strong" />
                {this.props.values.invoicing.invoiceName}
              </SummaryFieldComponent>
            </div>
            <div className="col-lg-6">
              <SummaryFieldComponent>
                <FormattedMessage id="ORDERS.EMAIL" tagName="strong" />
                {this.props.values.invoicing.invoiceEmail}
              </SummaryFieldComponent>
            </div>
          </div>
          {this.props.values.invoicing.invoicingMethod === 'EINVOICE' && (
            <div className="row">
              <div className="col-lg-6">
                <SummaryFieldComponent>
                  <FormattedMessage id="ORDERS.DELIVERY_IDENTIFIER" tagName="strong" />
                  {this.props.values.invoicing.eInvoiceIdentifier}
                </SummaryFieldComponent>
              </div>
              <div className="col-lg-6">
                <SummaryFieldComponent>
                  <FormattedMessage id="ORDERS.DELIVERY_ADDRESS" tagName="strong" />
                  {this.props.values.invoicing.eInvoiceAddress}
                </SummaryFieldComponent>
              </div>
            </div>
          )}
        </div>
        {!!this.props.values.invoicingAddress && (
          <>
            <div className="card-header card-header--between">
              <h4 className="card-title">
                <FormattedMessage id="ORDERS.DELIVERY_DETAILS" />
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6">
                  <SummaryFieldComponent>
                    <FormattedMessage id="ORDERS.STREET_ADDRESS" tagName="strong" />
                    {this.props.values.invoicingAddress.address}
                  </SummaryFieldComponent>
                </div>
                <div className="col-lg-6">
                  <SummaryFieldComponent>
                    <FormattedMessage id="ORDERS.ZIP_CODE" tagName="strong" />
                    {this.props.values.invoicingAddress.zipCode}
                  </SummaryFieldComponent>
                </div>
                <div className="col-lg-6">
                  <SummaryFieldComponent>
                    <FormattedMessage id="ORDERS.CITY" tagName="strong" />
                    {this.props.values.invoicingAddress.city}
                  </SummaryFieldComponent>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="card-footer">
          <AgreementsAcceptanceContainer
            input={this.state.terms}
            name={this.props.match.params.type === 'emoney' ? 'saldo' : 'order_banknotes'}
          />

          <ButtonsWrapperComponent>
            <ButtonComponent theme="outline-secondary" size="large" onClick={this.onBack}>
              <i className="fi fi-go-back-left-arrow" /> <FormattedMessage id="CORE.BACK" />
            </ButtonComponent>
            <ButtonComponent
              theme="link"
              size="large"
              className="ml-3"
              onClick={this.props.onCancel}
              loading={this.props.order.canceling}
            >
              <FormattedMessage id="ORDERS.CANCEL_ORDER" />
            </ButtonComponent>

            <ButtonComponent
              theme="primary"
              size="large"
              className="ml-auto"
              disabled={!this.state.terms.value}
              loading={this.props.submitting}
            >
              <FormattedMessage id="CORE.CONFIRM" />
            </ButtonComponent>
          </ButtonsWrapperComponent>
        </div>
      </>
    );
  }

  onBack = () => {
    this.props.onCreate().then(() => this.props.onBack());
  };
}
