import { handleActions } from 'redux-actions';
import * as types from './view.action-types';

const defaultState = {
  businessChangeLocked: false,
  showFloatingBar: true,
  showInfoBox: false,
};

export const viewReducer = handleActions(
  {
    [types.LOCK_BUSINESS_CHANGE]: state => ({
      ...state,
      businessChangeLocked: true,
    }),
    [types.UNLOCK_BUSINESS_CHANGE]: state => ({
      ...state,
      businessChangeLocked: false,
    }),
    [types.UPDATE_CONFIRMATION]: (state, { payload: confirmation }) => ({
      ...state,
      confirmation,
    }),
    [types.CLOSE_FLOATING_BAR]: state => ({
      ...state,
      showFloatingBar: false,
    }),
    [types.CLOSE_INFO_BOX]: state => ({
      ...state,
      showInfoBox: false,
    }),
  },
  defaultState,
);
