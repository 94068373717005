// @flow
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { AlertComponent, PlaceholderComponent, SummaryFieldComponent } from 'app/shared';

import { SubscriptionNavContainer } from 'app/subscriptions/nav/subscription-nav.container';

import type { Props } from './subscription-details.placeholder.types';

export class SubscriptionDetailsPlaceholder extends Component<Props> {
  render() {
    const columnClassNames = classNames('col-md-12 col-xl-4', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    return (
      <>
        <div className="card-header">
          <SubscriptionNavContainer />

          <AlertComponent type="light">
            <FormattedMessage id="SUBSCRIPTION.DETAILS.INFO" />
          </AlertComponent>

          <h4 className="card-title">
            <PlaceholderComponent width={400} height={23} />
          </h4>
        </div>
        <div className="card-body">
          <SummaryFieldComponent>
            <FormattedMessage id="SUBSCRIPTION.LABEL.DESCRIPTION" tagName="strong" />
            <PlaceholderComponent width={600} />
          </SummaryFieldComponent>
          <div className="row">
            <div className={columnClassNames}>
              <SummaryFieldComponent>
                <FormattedMessage id="SUBSCRIPTION.SECTION_TITLES.SUBSCRIPTION_PERIOD" tagName="strong" />
                <PlaceholderComponent width={100} /> - <PlaceholderComponent width={100} />
              </SummaryFieldComponent>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <SummaryFieldComponent>
                <strong>
                  <FormattedMessage id="SUBSCRIPTION.SECTION_TITLES.BENEFIT_OPTION" />:
                </strong>
                <div>
                  <PlaceholderComponent width={200} />
                </div>
              </SummaryFieldComponent>
            </div>
          </div>
        </div>
      </>
    );
  }
}
