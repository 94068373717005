// @flow

import React, { Component } from 'react';

import type { Props } from './external-link.component.types.js';

export class ExternalLinkComponent extends Component<Props> {
  render() {
    return (
      <a href={this.props.href} target={this.props.target} rel="noopener noreferrer">
        {this.props.children}
      </a>
    );
  }
}
