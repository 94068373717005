// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, Fields } from 'redux-form';
import MediaQuery from 'react-responsive';

import isEqual from 'lodash/isEqual';

import { ButtonComponent, ConnectedCalculatorInputComponent, ConnectedSelectComponent } from 'app/shared';
import { currencyFormatter } from 'app/utils';

import { BenefitCalculatorContainer } from './calculator/benefit-calculator.container';

import type { Props, State } from './benefit-row.component.types';
import styles from './benefit-row.module.scss';

export class BenefitRowComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return (
      !isEqual(nextProps.intl, this.props.intl) ||
      !isEqual(nextProps.benefits, this.props.benefits) ||
      !isEqual(nextProps.amount, this.props.amount) ||
      !isEqual(nextState.open, this.state.open)
    );
  }

  createOptions = () => {
    const products = this.props.products.map(({ id }) => ({
      name: this.props.intl.formatMessage({ id: `PRODUCT.${id}` }),
      id,
    }));

    return products.filter(e => !this.props.benefits.some((benefit, i) => benefit === e.id && i !== this.props.index));
  };

  onBenefitRowRemove = () => {
    this.setState({ open: false });
    this.props.fields.remove(this.props.index);
  };

  onOptionChange = (e: Event | any, newOption: string, option: string) => {
    if (this.state.open && newOption !== option) {
      this.setState({ open: false });
    }
  };

  isMoreThanOneBenefitOption = () => this.props.fields.length > 1;

  onKeyPress = (e: KeyboardEvent) => {
    if (e.keyCode === 13 && this.props.fields.length < this.props.products.length) {
      this.props.addField(this.props.form, this.props.fields.name);
    }
  };

  render() {
    return (
      <div className={styles.container}>
        <div className="col-4">
          <Field
            name={`${this.props.field}.id`}
            component={ConnectedSelectComponent}
            options={this.createOptions()}
            onChange={this.onOptionChange}
          />
        </div>
        <div className="col-4" onKeyDown={this.onKeyPress} tabIndex="0">
          <Fields
            names={[`${this.props.field}.amount`, `${this.props.field}.id`]}
            component={ConnectedCalculatorInputComponent}
            onButtonClick={() => this.setState(prev => ({ open: !prev.open }))}
            type={this.props.match.params.type}
            isOpen={this.state.open}
            required
          />
        </div>
        <div className="col-4 d-flex justify-content-between align-items-center mt-1">
          {this.props.match.params.type === 'voucher' && (
            <strong className={styles.value}>
              {currencyFormatter.format(this.props.amount * 10 * this.props.multiplier || 0)}
            </strong>
          )}
          {this.isMoreThanOneBenefitOption() && (
            <MediaQuery minWidth={992}>
              {matches =>
                matches ? (
                  <ButtonComponent theme="outline-secondary" size="small" onClick={() => this.onBenefitRowRemove()}>
                    <FormattedMessage id="CORE.REMOVE" />
                  </ButtonComponent>
                ) : (
                  <i className="fi fi-cross-remove-sign" onClick={() => this.onBenefitRowRemove()} />
                )
              }
            </MediaQuery>
          )}
        </div>

        {this.state.open && (
          <BenefitCalculatorContainer
            formName={this.props.form}
            fieldName={this.props.fields.name}
            index={this.props.index}
            onSave={() => this.setState({ open: false })}
            amount={this.props.amount}
          />
        )}
      </div>
    );
  }
}
