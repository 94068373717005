// @flow

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { SettlementsListContainer } from './list/settlements-list.container';

import type { Props } from './settlements.component.types';

export class SettlementsComponent extends Component<Props> {
  render() {
    return (
      <Switch>
        <Route path={this.props.match.url} component={SettlementsListContainer} exact />
      </Switch>
    );
  }
}
