import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';

import { withClear } from 'app/common';
import { withCoreContext } from 'app/core/core.context';

import { TransactionsCompanyFiltersComponent } from './transactions-company-filters.component';
import { getFirstAndLastDatesOfCurrentMonth } from '../../utils/get-first-date-last-date-of-current-month/get-first-date-last-date-of-current-month.util';

const mapStateToProps = ({ users: { business } }) => {
  const { firstDate, lastDate } = getFirstAndLastDatesOfCurrentMonth();

  return {
    business,
    initialValues: {
      minDate: firstDate,
      maxDate: lastDate,
      size: 10,
    },
  };
};

export const TransactionsCompanyFiltersContainer = compose(
  injectIntl,
  withCoreContext,
  connect(mapStateToProps),
  reduxForm({
    form: 'filter-company-transactions',
  }),
  withClear,
)(TransactionsCompanyFiltersComponent);
