// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

import { ConnectedGroupComponent, TooltipComponent } from 'app/shared';

import type { Props } from './connected-radio.component.types';
import styles from './connected-radio.module.scss';

export class ConnectedRadioComponent extends Component<Props> {
  render() {
    const radioClassNames = classNames(styles.radio, {
      [styles.selected]: this.props.input.value === this.props.option.id,
      [styles.invalid]: this.props.meta.touched && !!this.props.meta.error,
      [styles.disabled]: this.props.disabled,

      [styles[this.props.theme]]: !!this.props.theme,
    });

    return (
      <ConnectedGroupComponent {...this.props}>
        <label className={radioClassNames}>
          <span className={styles.optionNameWrapper}>
            {this.props.option.name}
            {this.props.infoText && (
              <span className={styles.infoWrapper}>
                <TooltipComponent overlay={this.props.infoText} placement="top">
                  <span className={styles.info} />
                </TooltipComponent>
              </span>
            )}
          </span>
          <input
            type="radio"
            {...this.props.input}
            checked={this.props.input.value === this.props.option.id}
            disabled={this.props.disabled}
            onChange={() => this.props.input.onChange(this.props.option.id)}
          />

          <span className={styles.input} />
        </label>
      </ConnectedGroupComponent>
    );
  }
}
