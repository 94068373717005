// @flow

import { toLower, upperFirst } from 'lodash/string';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, FormSection } from 'redux-form';
import classNames from 'classnames';
import get from 'lodash/get';

import {
  ButtonComponent,
  ConnectedCheckboxesComponent,
  ConnectedInputComponent,
  ConnectedSelectComponent,
  ConnectedTextareaComponent,
  ConnectedToggleComponent,
  AlertComponent,
  ConnectedPhoneNumberInputComponent,
} from 'app/shared';
import { ConnectedUrlComponent } from 'app/shared/form/connected-url-input/connected-url-input.component';

import { BenefitsContainer } from 'app/common/venues/benefits/benefits.container';
import { USER_ROLES } from 'app/constants';

import type { Props, State } from './venue-form.component.types';
import styles from './venue-form.module.scss';

export class VenueFormComponent extends PureComponent<Props, State> {
  state = {
    selectedBenefit: this.props.benefit,
    addBankAccountOpen: false,
    settlementReference: this.props.initialValues.venue.settlementReference || '',
    settlementMessage: this.props.initialValues.venue.settlementMessage || '',
  };

  onBenefitChange = (event: any, value: string) => {
    this.setState({ selectedBenefit: value });
  };

  translateServices = (services: { id: string, name: string }[]): { id: string, name: string }[] => {
    return services.map(service => ({
      ...service,
      name: this.props.intl.formatMessage({ id: `VENUES.SERVICE.${service.name}`, defaultMessage: service.name }),
    }));
  };

  render() {
    const { dictionary, initialValues } = this.props;
    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    const settlementOptions = dictionary.settlements.map(({ id, name }) => ({
      id,
      name: this.props.intl.formatMessage({ id: `SETTLEMENT.${name}` }),
    }));

    const swiftCodesOptions = Object.entries(get(dictionary, ['swiftCodes'], [])).map(([id, name]) => ({
      id,
      name,
    }));

    const isEditVenueMode = !!this.props.venue;

    const paymentMethods = !isEditVenueMode
      ? this.props.paymentMethods.filter(item => !item.legacy)
      : this.props.paymentMethods;

    const onlyForRoleServiceProviderManager = this.props.userRole === USER_ROLES.ROLE_SERVICE_PROVIDER_MANAGER;

    const onlyForRoleVenueManager = isEditVenueMode && this.props.userRole === USER_ROLES.ROLE_VENUE_MANAGER;

    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className="card-header">
          <h4 className="card-title">
            <FormattedMessage id="VENUES.GENERAL_INFORMATION" />
          </h4>
        </div>
        <div className="card-body">
          <AlertComponent type="light" className={classNames(styles.infoAlert, 'text-left')}>
            <FormattedMessage id="VENUES.GENERAL_INFORMATION_TEXT" />
          </AlertComponent>

          <FormSection name="venue">
            <div className="row">
              <div className={columnClassNames}>
                <Field
                  name="name"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'VENUES.NAME' })}
                  placeholder={this.props.intl.formatMessage({ id: 'VENUES.NAME' })}
                  disabled={!onlyForRoleServiceProviderManager}
                  normalize={upperFirst}
                  required
                />
              </div>
              <div className={columnClassNames}>
                <Field
                  name="website"
                  component={ConnectedUrlComponent}
                  label={this.props.intl.formatMessage({ id: 'VENUES.WEBSITE' })}
                  placeholder={this.props.intl.formatMessage({ id: 'VENUES.WEBSITE' })}
                  normalize={toLower}
                />
              </div>
            </div>
            <div className="row">
              <div className={columnClassNames}>
                <Field
                  name="email"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'VENUES.GENERAL_EMAIL_ADDRESS' })}
                  placeholder={this.props.intl.formatMessage({ id: 'VENUES.GENERAL_EMAIL_ADDRESS' })}
                  normalize={toLower}
                />
              </div>
              <div className={columnClassNames}>
                <Field
                  name="billingEmail"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'VENUES.INVOICE_EMAIL' })}
                  placeholder={this.props.intl.formatMessage({ id: 'VENUES.INVOICE_EMAIL' })}
                  normalize={toLower}
                />
              </div>
              <div className={columnClassNames}>
                <Field
                  name="phone"
                  component={ConnectedPhoneNumberInputComponent}
                  label={this.props.intl.formatMessage({ id: 'VENUES.PHONE' })}
                  placeholder={this.props.intl.formatMessage({ id: 'VENUES.PHONE' })}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Field
                  name="description"
                  component={ConnectedTextareaComponent}
                  label={this.props.intl.formatMessage({ id: 'VENUES.DESCRIPTION' })}
                  addon={this.props.intl.formatMessage({ id: 'VENUES.DESCRIPTION_ADDON' })}
                />
              </div>
            </div>
            {this.props.type === 'municipal' && (
              <>
                <h4 className="card-title my-4">
                  <FormattedMessage id="VENUES.CONTACT_PERSON" />
                </h4>
                <div className="row">
                  <div className={columnClassNames}>
                    <Field
                      name="contactName"
                      component={ConnectedInputComponent}
                      label={this.props.intl.formatMessage({ id: 'VENUES.CONTACT_PERSON_NAME' })}
                      placeholder={this.props.intl.formatMessage({ id: 'VENUES.CONTACT_PERSON_NAME' })}
                      required
                      normalize={upperFirst}
                    />
                  </div>
                  <div className={columnClassNames}>
                    <Field
                      name="contactPhone"
                      component={ConnectedPhoneNumberInputComponent}
                      label={this.props.intl.formatMessage({ id: 'VENUES.CONTACT_PERSON_PHONE' })}
                      placeholder={this.props.intl.formatMessage({ id: 'VENUES.CONTACT_PERSON_PHONE' })}
                      required
                    />
                  </div>
                </div>
              </>
            )}
          </FormSection>
        </div>
        <FormSection name="venue">
          <div className="card-header card-header--between">
            <h4 className="card-title">
              <FormattedMessage id="VENUES.OFFICIAL_ADDRESS" />
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className={columnClassNames}>
                <Field
                  name="address.address"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'VENUES.STREET_ADDRESS' })}
                  placeholder={this.props.intl.formatMessage({ id: 'VENUES.STREET_ADDRESS' })}
                  required
                  normalize={upperFirst}
                />
              </div>
              <div className={columnClassNames}>
                <Field
                  name="address.zipCode"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'VENUES.ZIP_CODE' })}
                  placeholder={this.props.intl.formatMessage({ id: 'VENUES.ZIP_CODE' })}
                  required
                />
              </div>
              <div className={columnClassNames}>
                <Field
                  name="address.city"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'VENUES.CITY' })}
                  placeholder={this.props.intl.formatMessage({ id: 'VENUES.CITY' })}
                  required
                  normalize={upperFirst}
                />
              </div>
            </div>
          </div>
        </FormSection>
        <FormSection name="venue">
          <div className="card-header card-header--between">
            <h4 className="card-title">
              <FormattedMessage id="VENUES.DELIVERY_ADDRESS" />
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className={columnClassNames}>
                <Field
                  name="deliveryAddress.address"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'VENUES.STREET_ADDRESS' })}
                  placeholder={this.props.intl.formatMessage({ id: 'VENUES.STREET_ADDRESS' })}
                  required
                  normalize={upperFirst}
                />
              </div>
              <div className={columnClassNames}>
                <Field
                  name="deliveryAddress.zipCode"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'VENUES.ZIP_CODE' })}
                  placeholder={this.props.intl.formatMessage({ id: 'VENUES.ZIP_CODE' })}
                  required
                />
              </div>
              <div className={columnClassNames}>
                <Field
                  name="deliveryAddress.city"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'VENUES.CITY' })}
                  placeholder={this.props.intl.formatMessage({ id: 'VENUES.CITY' })}
                  required
                  normalize={upperFirst}
                />
              </div>
            </div>
            <ButtonComponent
              onClick={() => this.props.fillValues('venue.address', 'venue.deliveryAddress')}
              theme="outline-secondary"
            >
              <FormattedMessage id="VENUES.FILL_VALUES" />
            </ButtonComponent>
          </div>
        </FormSection>

        {(onlyForRoleServiceProviderManager || onlyForRoleVenueManager) && (
          <FormSection name="venue">
            <div className="card-header card-header--between">
              <h4 className="card-title">
                <FormattedMessage id="VENUES.SETTLEMENT_INFORMATION" />
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className={columnClassNames}>
                  <Field
                    name="settlement"
                    component={ConnectedSelectComponent}
                    options={settlementOptions}
                    label={this.props.intl.formatMessage({ id: 'VENUES.SETTLEMENT_FREQUENCY' })}
                    required
                  />
                </div>
                <div className={columnClassNames}>
                  <Field
                    name="settlementAmount"
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'VENUES.SETTLEMENT_MINIMUM_SUM' })}
                    placeholder={this.props.intl.formatMessage({ id: 'VENUES.SETTLEMENT_MINIMUM_SUM' })}
                    required
                  />
                </div>
                <div className={columnClassNames}>
                  <Field
                    name="settlementReference"
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'VENUES.SETTLEMENT_REFERENCE' })}
                    placeholder={this.props.intl.formatMessage({ id: 'VENUES.SETTLEMENT_REFERENCE' })}
                    disabled={this.state.settlementMessage}
                    onChange={e => this.setState({ settlementReference: e.target.value })}
                    type="number"
                  />
                </div>
                <div className={columnClassNames}>
                  <Field
                    name="settlementMessage"
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'VENUES.SETTLEMENT_MESSAGE' })}
                    placeholder={this.props.intl.formatMessage({ id: 'VENUES.SETTLEMENT_MESSAGE' })}
                    disabled={this.state.settlementReference}
                    onChange={e => this.setState({ settlementMessage: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </FormSection>
        )}

        {onlyForRoleServiceProviderManager &&
          isEditVenueMode &&
          !initialValues.venue.bankAccount.iban && !initialValues.venue.bankAccount.swift && (
            <div className="card-body card-body--between">
              <button
                type="button"
                className={styles.button}
                onClick={() => this.setState({ addBankAccountOpen: !this.state.addBankAccountOpen })}
              >
                {this.state.addBankAccountOpen ? '-' : '+'}{' '}
                {this.props.intl.formatMessage({ id: 'VENUES.ADD_ACCOUNT' })}
              </button>
            </div>
          )}

        {((onlyForRoleServiceProviderManager &&
          isEditVenueMode &&
          initialValues.venue.bankAccount.iban && initialValues.venue.bankAccount.swift) ||
          this.state.addBankAccountOpen) && (
          <>
            <div className="card-header card-header--between">
              <h4 className="card-title">{this.props.intl.formatMessage({ id: 'CUSTOMERS.BANKING_INFORMATION' })}</h4>
            </div>
            <FormSection name="venue">
              <div className="card-body">
                <div className="row">
                  <div className={columnClassNames}>
                    <Field
                      required={!!this.props.swift}
                      name="bankAccount.iban"
                      component={ConnectedInputComponent}
                      label={this.props.intl.formatMessage({ id: 'REGISTER.ACCOUNT_NUMBER' })}
                      placeholder={this.props.intl.formatMessage({ id: 'REGISTER.ACCOUNT_NUMBER' })}
                    />
                  </div>
                  <div className={columnClassNames}>
                    <Field
                      required={!!this.props.iban}
                      name="bankAccount.swift"
                      component={ConnectedSelectComponent}
                      options={swiftCodesOptions}
                      label={this.props.intl.formatMessage({ id: 'REGISTER.SWIFT' })}
                      placeholder={this.props.intl.formatMessage({ id: 'REGISTER.SWIFT' })}
                      none
                    />
                  </div>
                </div>
                <AlertComponent type="light" className={classNames(styles.infoAlert, 'text-left')}>
                  <FormattedMessage id="VENUES.BANKING_INFORMATION_TEXT" />
                </AlertComponent>
              </div>
            </FormSection>
          </>
        )}

        {onlyForRoleVenueManager && (
          <>
            <div className="card-header card-header--between">
              <h4 className="card-title">{this.props.intl.formatMessage({ id: 'CUSTOMERS.BANKING_INFORMATION' })}</h4>
            </div>
            <FormSection name="venue">
              <div className="card-body">
                <div className="row">
                  <div className={columnClassNames}>
                    <Field
                      name="bankAccount.iban"
                      component={ConnectedInputComponent}
                      label={this.props.intl.formatMessage({ id: 'REGISTER.ACCOUNT_NUMBER' })}
                      placeholder={this.props.intl.formatMessage({ id: 'REGISTER.ACCOUNT_NUMBER' })}
                    />
                  </div>
                  <div className={columnClassNames}>
                    <Field
                      name="bankAccount.swift"
                      component={ConnectedSelectComponent}
                      options={swiftCodesOptions}
                      label={this.props.intl.formatMessage({ id: 'REGISTER.SWIFT' })}
                      placeholder={this.props.intl.formatMessage({ id: 'REGISTER.SWIFT' })}
                      none
                    />
                  </div>
                </div>
                <AlertComponent type="light" className={classNames(styles.infoAlert, 'text-left')}>
                  <FormattedMessage id="VENUES.BANKING_INFORMATION_TEXT" />
                </AlertComponent>
              </div>
            </FormSection>
          </>
        )}

        {!isEditVenueMode && (
          <>
            <div className="card-header card-header--between">
              <h4 className="card-title">{this.props.intl.formatMessage({ id: 'CUSTOMERS.BANKING_INFORMATION' })}</h4>
            </div>
            <FormSection name="venue">
              <div className="card-body">
                <div className="row">
                  <div className={columnClassNames}>
                    <Field
                      name="bankAccount.iban"
                      component={ConnectedInputComponent}
                      label={this.props.intl.formatMessage({ id: 'REGISTER.ACCOUNT_NUMBER' })}
                      placeholder={this.props.intl.formatMessage({ id: 'REGISTER.ACCOUNT_NUMBER' })}
                    />
                  </div>
                  <div className={columnClassNames}>
                    <Field
                      name="bankAccount.swift"
                      component={ConnectedSelectComponent}
                      options={swiftCodesOptions}
                      label={this.props.intl.formatMessage({ id: 'REGISTER.SWIFT' })}
                      placeholder={this.props.intl.formatMessage({ id: 'REGISTER.SWIFT' })}
                      none
                    />
                  </div>
                </div>
                <AlertComponent type="light" className={classNames(styles.infoAlert, 'text-left')}>
                  <FormattedMessage id="VENUES.BANKING_INFORMATION_TEXT" />
                </AlertComponent>
              </div>
            </FormSection>
          </>
        )}

        {this.props.type === 'company' && (
          <>
            {!isEditVenueMode && (
              <>
                <div className="card-header card-header--between">
                  <h4 className="card-title">
                    <FormattedMessage id="VENUES.EMPLOYEE_BENEFITS" />
                  </h4>
                </div>
                <div className="card-body">
                  <Field
                    name="benefit"
                    onChange={this.onBenefitChange}
                    component={BenefitsContainer}
                    locale={this.props.intl.locale}
                  />
                </div>
              </>
            )}
            <div className="card-header card-header--between">
              <h4 className="card-title">
                <FormattedMessage id="VENUES.SELECT_SERVICES" />
              </h4>
            </div>
            <div className="card-body">
              <Field
                name="services"
                component={ConnectedCheckboxesComponent}
                options={this.translateServices(dictionary.services[this.state.selectedBenefit] || [])}
                translate={false}
              />
            </div>
            <div className="card-header card-header--between">
              <h4 className="card-title">
                <FormattedMessage id="VENUES.ACCEPTED_MEANS_OF_PAYMENT" />
              </h4>
            </div>
            <div className="card-body">
              <Field
                name="acceptedPayments"
                disableEdit={isEditVenueMode}
                theme="extended"
                locale={this.props.intl.locale}
                options={paymentMethods.map(payment => ({
                  ...payment,
                  name: `PAYMENT.${payment.name}.NAME`,
                  description: `PAYMENT.${payment.description}.DESCRIPTION`,
                }))}
                isOptionInput={true}
                selectedBenefit={this.state.selectedBenefit}
                component={ConnectedCheckboxesComponent}
              />
            </div>
          </>
        )}
        {isEditVenueMode || (
          <FormSection name="admin">
            <div className="card-header card-header--between">
              <h4 className="card-title">
                <FormattedMessage id="VENUES.ADMINISTRATOR_USER" />
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className={columnClassNames}>
                  <Field
                    name="firstName"
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'PROFILE.FIRST_NAME' })}
                    placeholder={this.props.intl.formatMessage({ id: 'PROFILE.FIRST_NAME' })}
                    required
                    normalize={upperFirst}
                  />
                </div>
                <div className={columnClassNames}>
                  <Field
                    name="lastName"
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'PROFILE.LAST_NAME' })}
                    placeholder={this.props.intl.formatMessage({ id: 'PROFILE.LAST_NAME' })}
                    required
                    normalize={upperFirst}
                  />
                </div>
              </div>
              <div className="row">
                <div className={columnClassNames}>
                  <Field
                    name="email"
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'PROFILE.EMAIL' })}
                    placeholder={this.props.intl.formatMessage({ id: 'PROFILE.EMAIL' })}
                    required
                    normalize={toLower}
                  />
                </div>
                <div className={columnClassNames}>
                  <Field
                    name="mobilePhone"
                    component={ConnectedPhoneNumberInputComponent}
                    label={this.props.intl.formatMessage({ id: 'PROFILE.MOBILE_PHONE' })}
                    placeholder={this.props.intl.formatMessage({ id: 'PROFILE.MOBILE_PHONE' })}
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className={columnClassNames}>
                  <Field
                    name="workPhone"
                    component={ConnectedPhoneNumberInputComponent}
                    label={this.props.intl.formatMessage({ id: 'PROFILE.WORK_PHONE' })}
                    placeholder={this.props.intl.formatMessage({ id: 'PROFILE.WORK_PHONE' })}
                  />
                </div>
              </div>
              <div>
                <Field
                  name="marketing"
                  component={ConnectedToggleComponent}
                  format={v => !!v}
                  label={this.props.intl.formatMessage({ id: 'PROFILE.MARKETING' })}
                  helpText={this.props.intl.formatMessage({ id: 'PROFILE.MARKETING_INFO' })}
                />
              </div>
            </div>
          </FormSection>
        )}
        <div className="card-footer text-right">
          <ButtonComponent theme="primary" size="large" loading={this.props.submitting}>
            <FormattedMessage id="CORE.SAVE" /> <i className="fi fi-right-arrow-forward" />
          </ButtonComponent>
        </div>
      </form>
    );
  }
}
