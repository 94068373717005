import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import { withCoreContext } from 'app/core/core.context';

import { VenueFormPlaceholder } from './venue-form.placeholder';

export const VenueFormPlaceholderContainer = compose(
  injectIntl,
  withCoreContext,
)(VenueFormPlaceholder);
