// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import MediaQuery from 'react-responsive';
import classNames from 'classnames';

import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';

import { ButtonComponent, ConnectedGroupComponent, TooltipComponent } from 'app/shared';

import type { Props } from './connected-calculator-input.component.types';
import styles from 'app/shared/form/connected-input/connected-input.module.scss';

export class ConnectedCalculatorInputComponent extends Component<Props> {
  render() {
    const id = uniqueId('input_');

    const amount = get(this.props, this.props.names[0]);
    const benefit = get(this.props, this.props.names[1]);

    const isInvalid = amount.meta.submitFailed && amount.meta.error;

    const inputClassNames = classNames('form-control', styles.input, styles.withIcon, {
      'is-invalid': isInvalid,

      [styles.withAddon]: benefit.input.value === 'LUNCH_EMONEY' || this.props.type === 'voucher',
    });

    const inputGroupClassNames = classNames('input-group', {
      'is-invalid': isInvalid,
    });

    const addonClassNames = classNames('input-group-text', styles.button, {
      [styles.arrow]: this.props.isOpen,
    });

    return (
      <ConnectedGroupComponent id={id} {...amount}>
        <span className="input-group-prepend">
          <span className={classNames('input-group-text', styles.icon)}>
            <i className={`fi fi-${this.props.type === 'emoney' ? 'euro-symbol' : 'stack'}`} />
          </span>
        </span>
        <div className={inputGroupClassNames}>
          <input
            id={id}
            {...amount.input}
            type="number"
            min="0"
            max="200000"
            step={this.props.type === 'emoney' ? 0.01 : 1}
            className={inputClassNames}
          />

          {this.props.type === 'voucher' && (
            <span className="input-group-append">
              <span className={addonClassNames}>
                <TooltipComponent placement="top" overlay={<FormattedMessage id="ORDERS.BOOKS_DESC" />}>
                  <span className="badge badge-light mr-2">× 10</span>
                </TooltipComponent>
              </span>
            </span>
          )}

          {benefit.input.value === 'LUNCH_EMONEY' && (
            <span className="input-group-append">
              <span className={addonClassNames}>
                <ButtonComponent theme="outline-secondary" size="small" onClick={this.props.onButtonClick}>
                  <MediaQuery minWidth={992}>
                    {matches =>
                      matches ? (
                        this.props.isOpen ? (
                          <FormattedMessage id="CORE.CANCEL" />
                        ) : (
                          <FormattedMessage id="SUBSCRIPTION.CALCULATOR" />
                        )
                      ) : this.props.isOpen ? (
                        <i className="fi fi-cross-remove-sign" />
                      ) : (
                        <i className="fi fi-calculator" />
                      )
                    }
                  </MediaQuery>
                </ButtonComponent>
              </span>
            </span>
          )}
        </div>
      </ConnectedGroupComponent>
    );
  }
}
