// @flow

import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { push } from 'react-router-redux';

import { fetchYearlyReport } from 'app/redux/reports/reports.actions';

import { YearlyReportComponent } from './yearly-report.component';

const mapStateToProps = ({
  users: {
    current: {
      personal: { email },
    },
  },
}) => ({
  initialValues: { recipientEmail: email },
});

const mapDispatchToProps = (dispatch, { business }) => ({
  onSubmit: body => dispatch(fetchYearlyReport(business, body)),
  onSubmitSuccess: () =>
    dispatch(
      push({
        pathname: `/reports`,
        state: {
          success: true,
        },
      }),
    ),
  onClose: () =>
    dispatch(
      push({
        pathname: `/reports`,
      }),
    ),
});

export const YearlyReportContainer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({ form: 'yearly-report' }),
)(YearlyReportComponent);
