// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

import type { Props } from './subscription-subscribe-form-personal-radio.component.types';
import styles from './subscription-subscribe-form-personal-radio.module.scss';

export class SubscriptionSubscribeFormPersonalRadioComponent extends Component<Props> {
  onChange = (event: SyntheticInputEvent<HTMLInputElement>) => this.props.onChange(event.target.value);

  render() {
    const labelClassNames = classNames(styles.label, {
      [styles.checked]: this.props.checked,
    });

    return (
      <label className={labelClassNames}>
        <input
          type="radio"
          className={styles.input}
          value={this.props.option.id}
          onChange={this.onChange}
          checked={this.props.checked}
        />
        <span>{this.props.option.name}</span>
        <span className={styles.radio} />
      </label>
    );
  }
}
