// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { RegisterSuccessComponent } from 'app/register/shared';

import { RegisterCompanyFormContainer } from './form/register-company-form.container';
import { RegisterCompanySummaryContainer } from './summary/register-company-summary.container';

import type { BodyType, Props, State } from './register-company.component.types';

export class RegisterCompanyComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      step: 'FORM',
    };
  }

  changeStep = (step: string) => this.setState({ step }, () => window.scrollTo(0, 0));

  onSubmit = (body: BodyType) => this.props.onValidate(body).then(() => this.changeStep('SUMMARY'));

  onConfirm = (body: BodyType) =>
    this.props
      .onSubmit(body)
      .then(() => this.changeStep('SUCCESS'))
      .then(() => this.props.reset());

  render() {
    return (
      <>
        {this.state.step === 'FORM' && (
          <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <RegisterCompanyFormContainer pathname={this.props.location.pathname} submitting={this.props.submitting} />
          </form>
        )}
        {this.state.step === 'SUMMARY' && (
          <form onSubmit={this.props.handleSubmit(this.onConfirm)}>
            <RegisterCompanySummaryContainer changeStep={this.changeStep} submitting={this.props.submitting} />
          </form>
        )}
        {this.state.step === 'SUCCESS' && (
          <RegisterSuccessComponent title="REGISTER.SUCCESS_COMPANY_TITLE" changeStep={this.changeStep}>
            <FormattedMessage id="REGISTER.SUCCESS_COMPANY_DESCRIPTION" />
          </RegisterSuccessComponent>
        )}
      </>
    );
  }
}
