// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent, ConnectedInputComponent, ConnectedTextareaComponent } from 'app/shared';

import type { Props } from './questions.component.types';
import styles from '../benefits/benefits.module.scss';

export class QuestionsComponent extends Component<Props> {
  render() {
    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    return (
      <>
        {this.props.fields.map((field, index) => (
          <div key={index}>
            <div className="card-header card-header--with-line">
              <h4 className={classNames('card-title', styles.title)}>
                {`#${index + 1} `}
                <FormattedMessage id="SUBSCRIPTION.QUESTION" />
                <ButtonComponent onClick={() => this.props.fields.remove(index)} theme="outline-danger">
                  <FormattedMessage id="SUBSCRIPTION.REMOVE_QUESTION" />
                </ButtonComponent>
              </h4>
            </div>

            <div className="card-body">
              <div className="row">
                <div className={columnClassNames}>
                  <Field
                    name={`${field}.question`}
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.LABEL.QUESTION' })}
                    required
                  />
                </div>
                <div className={columnClassNames}>
                  <Field
                    name={`${field}.description`}
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.LABEL.DESCRIPTION' })}
                  />
                </div>
                <div className="col-12">
                  <Field
                    name={`${field}.answers`}
                    component={ConnectedTextareaComponent}
                    label={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.LABEL.ANSWER_OPTIONS' })}
                    addon={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.LABEL.ANSWER_OPTIONS_ADDON' })}
                    maxLength={8000}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="card-body card-body--between">
          <button type="button" className={styles.button} onClick={() => this.props.fields.push({})}>
            + <FormattedMessage id="SUBSCRIPTION.ADD_QUESTION" />
          </button>
        </div>
      </>
    );
  }
}
