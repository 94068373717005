// @flow

import React, { PureComponent } from 'react';

// $FlowFixMe
import { ReactComponent as Logo } from 'assets/images/logo.svg';

import styles from './splash.module.scss';

export class SplashComponent extends PureComponent<any> {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.logo}>
          <Logo />
          <span>Loading...</span>
        </div>
      </div>
    );
  }
}
