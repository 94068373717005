import { handleActions } from 'redux-actions';
import * as types from './transactions.action-types';

const defaultState = {
  entities: {},
};

export const generateStatus = {
  OK: 'ok',
  FAILED: 'failed',
  PENDING: 'pending',
  CANCELLED: 'cancelled',
};

export const transactionsReducer = handleActions(
  {
    [types.FETCH_TRANSACTIONS_REQUEST]: (state, { meta: { shouldReset, type } }) => ({
      ...state,
      ...(shouldReset ? { [type]: undefined } : { [type]: { ...state[type], fetching: true } }),
    }),
    [types.FETCH_TRANSACTIONS_SUCCESS]: (state, { meta: { shouldMerge, type }, payload: { data: list, meta } }) => ({
      ...state,
      [type]: {
        ...state[type],
        meta,
        fetching: false,
        list: {
          transactions: shouldMerge ? [...state[type].list.transactions, ...list.transactions] : list.transactions,
          totalAmount: shouldMerge ? state[type].list.totalAmount + list.totalAmount : list.totalAmount,
        },
      },
    }),
    [types.FETCH_TRANSACTIONS_FAILURE]: (state, { meta: { type } }) => ({
      ...state,
      [type]: {
        ...state[type],
        fetching: false,
      },
    }),
    [types.FETCH_TRANSACTION_REQUEST]: (state, { meta: { transaction } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [transaction]: undefined,
      },
    }),
    [types.FETCH_TRANSACTION_SUCCESS]: (state, { meta: { transaction }, payload: { data: entity } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [transaction]: entity,
      },
    }),
    [types.FETCH_LATEST_TRANSACTIONS_REQUEST]: state => ({
      ...state,
      latest: undefined,
    }),
    [types.FETCH_LATEST_TRANSACTIONS_SUCCESS]: (state, { payload: { data: latest } }) => ({
      ...state,
      latest,
    }),
    [types.REFUND_TRANSACTION_REQUEST]: (state, { meta: { transaction } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [transaction]: {
          ...state.entities[transaction],
          refunding: true,
        },
      },
    }),
    [types.REFUND_TRANSACTION_REQUEST]: (state, { meta: { transaction } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [transaction]: {
          ...state.entities[transaction],
          refunding: true,
        },
      },
    }),
    [types.REFUND_TRANSACTION_SUCCESS]: (state, { meta: { transaction }, payload: { data } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [transaction]: data,
      },
    }),
    [types.REFUND_TRANSACTION_FAILURE]: (state, { meta: { transaction } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [transaction]: {
          ...state.entities[transaction],
          refunding: false,
        },
      },
    }),
    [types.GENERATE_TRANSACTIONS_REPORT_REQUEST]: (state, payload) => ({
      ...state,
      generateStatus: generateStatus.PENDING,
    }),
    [types.GENERATE_TRANSACTIONS_REPORT_FAILURE]: (state, payload) => ({
      ...state,
      generateStatus: generateStatus.FAILED,
    }),
    [types.GENERATE_TRANSACTIONS_REPORT_SUCCESS]: (state, { payload }) => {
      const status = payload ? generateStatus.PENDING : generateStatus.OK;

      return {
        ...state,
        generateStatus: status,
      };
    },
    [types.SET_CANCELLED_GENERATE_STATUS]: state => ({
      ...state,
      generateStatus: generateStatus.CANCELLED,
    }),
  },
  defaultState,
);
