// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { HeaderComponent } from 'app/core';
import { ListComponent } from 'app/shared/list/list.component';
import { AlertComponent, ButtonComponent, CardComponent } from 'app/shared';

import { BeneficiariesListPlaceholder } from './placeholder/beneficiaries-list.placeholder';
import { BeneficiariesListTableComponent } from './table/beneficiaries-list-table.component';
import { BeneficiariesListTileComponent } from './tile/beneficiaries-list-tile.component';
import { FiltersContainer } from './filters/filters.container';

import type { Props } from './beneficiaries-list.component.types';

export class BeneficiariesListComponent extends Component<Props> {
  componentDidUpdate(prevProps: Props) {
    if (prevProps.business !== this.props.business) {
      this.props.fetch();
    }
  }

  render() {
    const noResults = this.props.loading || !this.props.beneficiaries.length;

    return (
      <>
        <HeaderComponent
          breadcrumbs={[{ icon: 'multiple-users-silhouette' }]}
          button={
            <ButtonComponent size="large" theme="white" onClick={this.props.onDownload} disabled={noResults}>
              <i className="fi fi-xlsx-file-format-extension" />
              <FormattedMessage id="TRANSACTIONS.DOWNLOAD_AS_XLSX" />
            </ButtonComponent>
          }
        >
          <FormattedMessage id="SIDEBAR.COMPANY_BENEFICIARY_SEARCH" tagName="h2" />
        </HeaderComponent>

        {!!this.props.location.state &&
          !!this.props.location.state.success && (
            <AlertComponent type="success">
              {this.props.location.state.success.type === 'BENEFICIARY_DELETED' &&
                this.props.location.state.success.name && (
                  <FormattedMessage
                    id="BENEFICIARIES.BENEFICIARY_DELETED"
                    values={{ name: <strong>{this.props.location.state.success.name}</strong> }}
                  />
                )}
            </AlertComponent>
          )}

        <CardComponent>
          <FiltersContainer />

          {this.props.loading && <BeneficiariesListPlaceholder />}

          {this.props.loading || (
            <>
              <div className="card-header pt-xl-0">
                <h4 className="card-title">
                  <FormattedMessage id="BENEFICIARIES.BENEFICIARIES_LIST.LIST" />
                </h4>
              </div>

              <div className="card-body">
                <ListComponent
                  list={this.props.beneficiaries}
                  table={BeneficiariesListTableComponent}
                  tile={BeneficiariesListTileComponent}
                />
                {this.props.pagination}
              </div>
            </>
          )}
        </CardComponent>
      </>
    );
  }
}
