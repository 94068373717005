import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';

import { SubscriptionBeneficiariesModifyComponent } from './subscription-beneficiaries-modify.component';

const mapStateToProps = (_, { benefitOptions }) => ({
  initialValues: {
    benefits: benefitOptions.map(benefit => ({
      amount: '',
      id: benefit,
    })),
  },
});

export const SubscriptionBeneficiariesModifyContainer = compose(
  injectIntl,
  connect(mapStateToProps),
  reduxForm({ form: 'modify-beneficiaries' }),
)(SubscriptionBeneficiariesModifyComponent);
