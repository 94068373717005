import { connect } from 'react-redux';

import { SubscriptionBeneficiariesEditableComponent } from './subscription-beneficiaries-editable.component';

const mapStateToProps = ({ dictionaries }, { benefit, type }) => ({
  multiplier: type === 'VOUCHER' ? dictionaries['products/voucher'].multipliers[benefit] : 1,
});

export const SubscriptionBeneficiariesEditableContainer = connect(mapStateToProps)(
  SubscriptionBeneficiariesEditableComponent,
);
