import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';

import { withAuthorization } from 'app/common';

import { fetchBeneficiaryAccounts, updateAccountStatus } from 'app/redux/beneficiaries/beneficiaries.actions';

import { BeneficiaryDetailsAccountsComponent } from './beneficiary-details-accounts.component';
import { updateConfirmation } from 'app/redux/view/view.actions';

const mapStateToProps = ({ beneficiaries: { entities: beneficiaries } }, { beneficiary }) => ({
  accounts: get(beneficiaries, [beneficiary, 'accounts']),
  loading: !get(beneficiaries, [beneficiary, 'accounts']),
});

const mapDispatchToProps = (dispatch, { beneficiary }) => ({
  fetchBeneficiaryAccounts: () => dispatch(fetchBeneficiaryAccounts(beneficiary)),
  onActivate: account =>
    dispatch(
      updateConfirmation({
        onConfirm: () => dispatch(updateAccountStatus(beneficiary, account, 'enabled')),
        title: 'ACCOUNTS.CONFIRM_STATUS_UPDATE',
      }),
    ),
  onDisable: account =>
    dispatch(
      updateConfirmation({
        onConfirm: () => dispatch(updateAccountStatus(beneficiary, account, 'disabled')),
        title: 'ACCOUNTS.CONFIRM_STATUS_UPDATE',
      }),
    ),
});

export const BeneficiaryDetailsAccountsContainer = compose(
  injectIntl,
  withRouter,
  withAuthorization(['companyBeneficiaryAccounts', 'read']),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(BeneficiaryDetailsAccountsComponent);
