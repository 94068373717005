// @flow

import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { ButtonComponent } from 'app/shared';
import styles from './banner.module.scss';
import type { Props, State } from './banner.component.types.js';

import bannerHand from '../../../assets/images/banner_hand.png';
import bannerLogo from '../../../assets/images/banner_logo.png';

const smartumPayHelpDict = {
  en: process.env.REACT_APP_BANNER_URL_EN,
  fi: process.env.REACT_APP_BANNER_URL_FI,
  sv: process.env.REACT_APP_BANNER_URL_SV,
};

export class BannerComponent extends Component<Props, State> {
  state = { isBannerVisible: true };

  closeBanner = () => {
    this.setState({ isBannerVisible: false });
  };

  render() {
    const { locale } = this.props;

    return (
      <>
        {this.state.isBannerVisible && (
          <div className={styles.banner}>
            <div className="row">
              <div className={classNames(styles.bannerColumn, 'col-3')}>
                <img src={bannerHand} alt="banner" className={styles.bannerImage} />
              </div>
              <div className={classNames(styles.bannerColumn, styles.bannerColumnMiddle, 'col-md-6 col-9')}>
                <MediaQuery maxWidth={767}>
                  <FormattedMessage id="BANNER.TEXT" tagName="span" />
                  <a
                    href={smartumPayHelpDict[locale]}
                    className={classNames(styles.bannerButton, styles.bannerButtonMobile)}
                  >
                    <FormattedMessage id="BANNER.BUTTON" />
                    <span className={styles.triangle} />
                  </a>
                </MediaQuery>
                <MediaQuery minWidth={768}>
                  <FormattedMessage id="BANNER.TEXT" tagName="p" />
                </MediaQuery>
              </div>
              <MediaQuery minWidth={768}>
                <div className={classNames(styles.bannerColumn, styles.bannerColumnRight, 'col-md-3')}>
                  <img src={bannerLogo} alt="logo" className={styles.bannerLogo} />
                  <a href={smartumPayHelpDict[locale]} className={styles.bannerButton}>
                    <FormattedMessage id="BANNER.BUTTON" />
                    <span className={styles.triangle} />
                  </a>
                </div>
              </MediaQuery>
            </div>
            <ButtonComponent theme="icon" className={styles.bannerClose} onClick={this.closeBanner}>
              <i className="fi fi-error" />
            </ButtonComponent>
          </div>
        )}
      </>
    );
  }
}
