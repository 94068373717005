import { RSAA } from 'redux-api-middleware';
import URLSearchParams from '@ungap/url-search-params';

import * as types from './downloads.action-types';

export const download = (path, locale, filters) => ({
  [RSAA]: {
    types: [
      types.FETCH_DOWNLOAD_TOKEN_REQUEST,
      {
        type: types.FETCH_DOWNLOAD_TOKEN_SUCCESS,
        meta: {
          filters,
          locale,
          path,
        },
      },
      types.FETCH_DOWNLOAD_TOKEN_FAILURE,
    ],
    endpoint: '/downloads/token',
    method: 'GET',
  },
});

export const downloadUserData = locale => ({
  [RSAA]: {
    types: [
      types.FETCH_DOWNLOAD_TOKEN_REQUEST,
      {
        type: types.FETCH_DOWNLOAD_TOKEN_SUCCESS,
        meta: {
          locale,
          path: 'users/me/export',
        },
      },
      types.FETCH_DOWNLOAD_TOKEN_FAILURE,
    ],
    endpoint: '/users/me/export',
    method: 'POST',
  },
});

export const downloadToken = () => ({
  [RSAA]: {
    types: [
      types.FETCH_DOWNLOAD_METABASE_PDF_REQUEST,
      {
        type: types.FETCH_DOWNLOAD_METABASE_PDF_SUCCESS,
      },
      types.FETCH_DOWNLOAD_METABASE_PDF_FAILURE,
    ],
    endpoint: '/downloads/token',
    method: 'GET',
  },
});

export const downloadMetabasePDF = ({ business }, queryParams) => {
  const searchParams = new URLSearchParams();
  queryParams && Object.entries(queryParams).forEach(([key, value]) => value && searchParams.append(key, value));
  return {
    [RSAA]: {
      types: [
        types.FETCH_DOWNLOAD_METABASE_PDF_REQUEST,
        {
          type: types.FETCH_DOWNLOAD_METABASE_PDF_SUCCESS,
          meta: {
            path: `downloads/billing/${business}?${searchParams}`,
          },
        },
        types.FETCH_DOWNLOAD_METABASE_PDF_FAILURE,
      ],
      endpoint: `/downloads/billing/${business}?${searchParams}`,
      method: 'GET',
    },
  };
};
