// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { PlaceholderComponent, TableComponent } from 'app/shared';

import type { Props } from './transactions-company-table.placeholder.types';

export class TransactionsCompanyTablePlaceholder extends Component<Props> {
  render() {
    return (
      <TableComponent className="table-striped">
        <thead>
          <tr>
            <FormattedMessage id="TRANSACTIONS.ID" tagName="th" />
            <FormattedMessage id="TRANSACTIONS.DEBTOR_CREDITOR" tagName="th" />
            <FormattedMessage id="TRANSACTIONS.MESSAGE" tagName="th" />
            <FormattedMessage id="TRANSACTIONS.DATE" tagName="th" />
            <FormattedMessage id="TRANSACTIONS.AMOUNT" tagName="th" />
          </tr>
        </thead>
        <tbody>
          {this.props.list.map((_, transaction) => (
            <tr key={transaction}>
              <td>
                <PlaceholderComponent width={30} />
              </td>
              <td>
                <PlaceholderComponent width={transaction % 2 === 0 ? 260 : 150} />
              </td>
              <td>
                <PlaceholderComponent width={transaction % 2 === 0 ? 150 : 260} />
              </td>
              <td>
                <PlaceholderComponent width={100} />
              </td>
              <td>
                <PlaceholderComponent width={100} color="purple" />
              </td>
            </tr>
          ))}
        </tbody>
      </TableComponent>
    );
  }
}
