import React from 'react';
import { PUBLIC_API_URL } from 'app/constants';
import type { UseConvertVenueId } from './use-convert-venue-id.hook.types';

const generateSmartIdPath = (value: number) => `/smartid?prefix=ven&version=1&value=${value}`;
const generateShortcodePath = (value: number) => `/shortcode?value=${value}`;
const AbortController = window.AbortController;

export const useConvertVenueId: UseConvertVenueId = (
  convertIdTo,
  { setError, initialVenue = { id: 0, convertedId: 0 } },
) => {
  const [selectedVenue, setSelectedVenue] = React.useState(initialVenue);
  const memoizedSelectedVenue = React.useMemo(() => selectedVenue, [selectedVenue.id, selectedVenue.convertedId]);
  const [isLoading, setIsLoading] = React.useState(false);
  let generatePath;
  if (convertIdTo === 'smartid') {
    generatePath = generateSmartIdPath;
  }
  if (convertIdTo === 'shortcode') {
    generatePath = generateShortcodePath;
  }

  async function getConvertedId(controller: AbortController) {
    try {
      setIsLoading(true);
      const res = await fetch(PUBLIC_API_URL + generatePath(selectedVenue.id), {
        signal: controller.signal,
      });
      const data = await res.json();
      // extract smartumId or shortcode property
      // different response different property
      const convertedId = data.data.id || data.data.shortcode;
      setSelectedVenue({ id: selectedVenue.id, convertedId });
    } catch (err) {
      setError();
    } finally {
      if (!controller.signal.aborted) setIsLoading(false);
    }
  }

  React.useEffect(() => {
    const controller = new AbortController();
    if (selectedVenue.id !== 0 && selectedVenue.convertedId === 0) getConvertedId(controller);
    return () => controller.abort();
  }, [selectedVenue.id, selectedVenue.convertedId]);

  const copyVenueConvertedIdToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(selectedVenue.convertedId);
    } catch (e) {
      setError();
    }
  };

  const onVenueChange = (id: string) => {
    if (id === selectedVenue.id) return;
    setSelectedVenue({ id: parseInt(id, 10), convertedId: 0 });
  };

  return {
    selectedVenue: memoizedSelectedVenue,
    isLoading,
    copyVenueConvertedIdToClipboard,
    onVenueChange,
  };
};
