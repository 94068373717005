// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import type { Props, State } from './accordion.component.types';
import styles from './accordion.module.scss';

export class AccordionComponent extends Component<Props, State> {
  state = { isContentVisible: false };

  accordionContent = () => {
    this.setState(prevState => ({ isContentVisible: !prevState.isContentVisible }));
  };

  render() {
    const iconClasses = classNames('fi fi-plus', {
      [styles.accordionBoxContentOpen]: this.state.isContentVisible,
    });

    return (
      <div className={styles.accordionBox}>
        <div className={styles.accordionBoxTitle}>
          <i className={iconClasses} onClick={this.accordionContent} />
          <FormattedMessage id={this.props.title} tagName="p" />
        </div>
        {this.state.isContentVisible && <div className={styles.accordionBoxContent}>{this.props.children}</div>}
      </div>
    );
  }
}
