import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { withAuthorization, withPagination } from 'app/common';

import { download } from 'app/redux/downloads/downloads.actions';
import { fetchSettlements } from 'app/redux/settlements/settlements.actions';

import { SettlementsListComponent } from './settlements-list.component';

const mapStateToProps = ({ settlements: { list: settlements, meta }, users: { business } }) => ({
  business,
  settlements,
  meta,
  loading: !settlements,
});

const mapDispatchToProps = (dispatch, { intl: { locale } }) => ({
  fetch: params => dispatch(fetchSettlements(params)),
  onDownload: settlementId => dispatch(download(`settlement-transactions/${settlementId}`, locale)),
});

export const SettlementsListContainer = compose(
  withAuthorization(['', 'read']), //to show, change empty string to 'venueSettlements'
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withPagination,
)(SettlementsListComponent);
