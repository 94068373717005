// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent, TileComponent } from 'app/shared';
import { currencyFormatter, dateFormatter } from 'app/utils';
import { Link } from 'react-router-dom';

import type { Props } from './reports-list-tile.component.types';

export class ReportsListTileComponent extends Component<Props> {
  render() {
    const { tile } = this.props;

    return (
      <TileComponent>
        <div className="row pb-3">
          <div className="col col-12">
            <h3>
              <Link to={`/subscriptions/emoney/${tile.id}`}>{tile.name}</Link>
            </h3>
          </div>

          <div className="col">
            <FormattedMessage id="SALDO_REPORTS.BENEFICIARIES" tagName="small" />
            <span className="small">{tile.beneficiariesNumber} pcs</span>
          </div>

          <div className="col text-right">
            <ButtonComponent
              theme="outline-secondary"
              size="small"
              to={{
                pathname: `/reports/${tile.distributionId}`,
                search: this.props.location.search,
                state: {
                  keepScrollPosition: true,
                },
              }}
            >
              <FormattedMessage id="SALDO_REPORTS.SHOW_REPORT" />
            </ButtonComponent>
          </div>
        </div>

        {tile.benefits.map((benefit, index) => (
          <TileComponent key={`${tile.id}-${index}`} className="mb-2">
            <div className="row mb-2">
              <div className="col m-0">
                <FormattedMessage id="SALDO_REPORTS.BENEFIT_NAME" tagName="small" />
                <FormattedMessage id={`PAYMENT.ITEM.${benefit.name}`} tagName="strong" />
              </div>

              <div className="col m-0">
                <FormattedMessage id="SALDO_REPORTS.DATES" tagName="small" />
                <span>{dateFormatter(benefit.dateFrom)}</span>
                <span> - </span>
                <span>{dateFormatter(benefit.dateTo)}</span>
              </div>
            </div>

            <div className="row">
              <div className="col m-0">
                <FormattedMessage id="SALDO_REPORTS.DEPOSIT_AMOUNT" tagName="small" />
                <strong>{currencyFormatter.format(benefit.deposit)}</strong>
              </div>

              <div className="col m-0">
                <FormattedMessage id="SALDO_REPORTS.BALANCE" tagName="small" />
                <strong>{currencyFormatter.format(benefit.balance)}</strong>
              </div>
            </div>
          </TileComponent>
        ))}
      </TileComponent>
    );
  }
}
