// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { CardComponent, StoreLinksComponent } from 'app/shared';

import styles from '../smartum-pay-info.module.scss';

export class HowToPayComponent extends Component<{}> {
  render() {
    const videoUrl = process.env.REACT_APP_SMARTUMPAY_VIDEO;

    return (
      <div className={styles.howToPay}>
        <div className={styles.howToPayTitle}>
          <FormattedMessage id={`SMARTUMPAY.HOW_TO_PAY.HEADER.EMPLOYEE`} tagName="h2" />
        </div>
        <CardComponent>
          <div className="card-header">
            <FormattedMessage id="SMARTUMPAY.HOW_TO_PAY.VIDEO_TITLE" tagName="h3" />
          </div>
          <div className={styles.howToPayCardBody}>
            <FormattedMessage id="SMARTUMPAY.HOW_TO_PAY.VIDEO_DESCRIPTION" tagName="p" />
            <div className={styles.videoWrapper}>
              <iframe
                src={videoUrl}
                title="how-to-pay"
                width="640"
                height="360"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              />
            </div>
          </div>
          <div className="card-header">
            <FormattedMessage id="SMARTUMPAY.HOW_TO_PAY.DOWNLOAD_TITLE" tagName="h3" />
          </div>
          <div className={styles.howToPayCardBody}>
            <FormattedMessage id="SMARTUMPAY.HOW_TO_PAY.DOWNLOAD_DESCRIPTION" tagName="p" />
            <StoreLinksComponent />
          </div>
        </CardComponent>
      </div>
    );
  }
}
