import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';

import { withClear } from 'app/common';
import { withCoreContext } from 'app/core/core.context';

import { TransactionsVisaFiltersComponent } from './transactions-visa-filters.component';

const mapStateToProps = ({ users: { business } }) => ({
  business,
});

export const TransactionsVisaFiltersContainer = compose(
  injectIntl,
  withCoreContext,
  connect(mapStateToProps),
  reduxForm({
    form: 'filters-visa-transactions',
  }),
  withClear,
)(TransactionsVisaFiltersComponent);
