import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { withPagination } from 'app/common';

import { fetchSubscriptions } from 'app/redux/subscriptions/subscriptions.actions';

import { SubscriptionsListComponent } from './subscriptions-list.component';

const mapStateToProps = (
  { users: { business }, subscriptions },
  {
    match: {
      params: { type },
    },
  },
) => ({
  business,
  loading: !get(subscriptions, [type, business, 'meta']),
  subscriptions: get(subscriptions, [type, business, 'list']),
  meta: get(subscriptions, [type, business, 'meta']),
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { type },
    },
  },
) => ({
  fetch: params => dispatch(fetchSubscriptions(type, params)),
});

export const SubscriptionsListContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withPagination,
)(SubscriptionsListComponent);
