// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { shouldFieldArrayUpdate } from 'app/utils';

import { BenefitRowContainer } from '../row/benefit-row.container';

import type { Props } from './benefit-table.component.types';
import styles from './benefit-table.module.scss';

export class BenefitTableComponent extends Component<Props> {
  shouldComponentUpdate = shouldFieldArrayUpdate;

  render() {
    const upperCasedType = this.props.match.params.type.toUpperCase();

    return (
      <div className={styles.table}>
        <div className={classNames(styles.container, styles.headings)}>
          <div className="col-4">
            <FormattedMessage id="SUBSCRIPTION.BENEFIT_OPTION" />
          </div>
          <div className="col-4">
            <FormattedMessage id={`SUBSCRIPTION.${upperCasedType}.QUANTITY`} />
          </div>
          <div className="col-4">
            {this.props.match.params.type === 'voucher' && <FormattedMessage id="SUBSCRIPTION.LABEL.VALUE_IN_EURO" />}
          </div>
        </div>
        {this.props.fields.map((field, index) => (
          <BenefitRowContainer
            field={field}
            form={this.props.meta.form}
            index={index}
            key={index}
            fields={this.props.fields}
          />
        ))}
        {this.props.fields.length < this.props.productsAmount && (
          <div className={styles.add} onClick={() => this.props.fields.push({})}>
            + <FormattedMessage id="SUBSCRIPTION.ADD_BENEFIT_ROW" />
          </div>
        )}
      </div>
    );
  }
}
