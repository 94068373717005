// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { OrdersCreateDeliveryContainer } from 'app/orders/create/form/delivery/order-create-delivery.container';
import { OrdersCreateInvoicingContainer } from 'app/orders/create/form/invoicing/orders-create-invoicing.container';

import { ButtonComponent, ButtonsWrapperComponent, SummaryFieldComponent } from 'app/shared';

import type { Props } from './cards-beneficiaries-order-summary.component.types';

export class CardsBeneficiariesOrderSummaryComponent extends Component<Props> {
  render() {
    return (
      <>
        <div className="card-header">
          <h4 className="card-title">
            <FormattedMessage id="CARDS.BENEFICIARY_ORDER.TITLE" />
          </h4>
        </div>
        <div className="card-body">
          <SummaryFieldComponent className="mb-0">
            <FormattedMessage id="CARDS.BENEFICIARY_ORDER.COMPANY_BENEFICIARY" tagName="strong" />
            {`${this.props.beneficiary.firstName} ${this.props.beneficiary.lastName}`}
          </SummaryFieldComponent>
        </div>

        <div className="card-header card-header--between">
          <h4 className="card-title">
            <FormattedMessage id="ORDERS.INVOICING_DETAILS" />
          </h4>
        </div>
        <div className="card-body">
          <OrdersCreateInvoicingContainer form="order-beneficiary-card" type="beneficiaryCardsOrder" />
        </div>

        <div className="card-header card-header--between">
          <h4 className="card-title">
            <FormattedMessage id="ORDERS.DELIVERY_DETAILS" />
          </h4>
        </div>
        <div className="card-body">
          <OrdersCreateDeliveryContainer beneficiary />
        </div>

        <div className="card-footer">
          <ButtonsWrapperComponent>
            <ButtonComponent theme="outline-secondary" size="large" to={`/beneficiaries/${this.props.beneficiary.id}`}>
              <i className="fi fi-go-back-left-arrow" /> <FormattedMessage id="CORE.BACK" />
            </ButtonComponent>

            <ButtonComponent theme="primary" size="large" className="ml-auto" loading={this.props.submitting}>
              <FormattedMessage id="CORE.NEXT" />
            </ButtonComponent>
          </ButtonsWrapperComponent>
        </div>
      </>
    );
  }
}
