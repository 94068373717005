import { fork } from 'redux-saga/effects';

import { agreementsSaga } from 'app/redux/agreements/agreements.saga';
import { downloadsSaga } from './redux/downloads/downloads.saga';
import { registerSaga } from 'app/redux/register/register.saga';
import { usersSaga } from 'app/redux/users/users.saga';

export function* appSaga() {
  yield fork(agreementsSaga);
  yield fork(downloadsSaga);
  yield fork(registerSaga);
  yield fork(usersSaga);
}
