import { compose } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { withAuthorization } from 'app/common';

import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';
import { fetchUsers } from 'app/redux/users/users.actions';

import { UsersComponent } from './users.component';

const mapStateToProps = ({
  dictionaries,
  users: {
    business,
    current: { businesses },
    entities: users,
  },
}) => ({
  business,
  loading: !users[business] || !dictionaries['user-roles/customer'],
  name: businesses.find(element => element.id === business).name,
  users: get(users, [business, 'list']),
});

const mapDispatchToProps = dispatch => ({
  fetchDictionary: () => dispatch(fetchDictionary('user-roles/customer')),
  fetchUsers: business => dispatch(fetchUsers(business, 'customer')),
});

export const UsersContainer = compose(
  withAuthorization(['customerUsers', 'read']),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(UsersComponent);
