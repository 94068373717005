import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reset } from 'redux-form';
import { get } from 'lodash';

import { withAuthorization } from 'app/common';
import { withPagination } from 'app/transactions/with-pagination/with-pagination.hoc';

import { fetchAccounts, fetchTransactions } from 'app/redux/accounts/accounts.actions';
import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';
import { download } from 'app/redux/downloads/downloads.actions';

import { AccountTransactionsComponent } from 'app/accounts/transactions/account-transactions.component';

const mapStateToProps = ({ accounts, dictionaries, permissions, users: { business, subBusiness, role } }) => ({
  business,
  subBusiness,
  account: get(accounts, ['municipal', 'list'], {}),
  fetching: get(accounts, ['entities', 'municipal-account', 'transactions', 'fetching']),
  loading:
    !get(accounts, ['entities', 'municipal-account', 'transactions']) ||
    !dictionaries['transaction-types'] ||
    get(accounts, ['municipal', 'loading']),
  loadingAccount: get(accounts, ['municipal', 'loading']),
  meta: get(accounts, ['entities', 'municipal-account', 'transactions', 'meta'], {}),
  permissions: permissions[business][role] || {},
  transactions: get(accounts, ['entities', 'municipal-account', 'transactions', 'list'], []),
  types: get(dictionaries, ['transaction-types', 'municipal']),
});

const mapDispatchToProps = (dispatch, { intl: { locale } }) => ({
  fetchAccount: () => dispatch(fetchAccounts('municipal')),
  fetchDictionary: () => dispatch(fetchDictionary('transaction-types')),
  fetchTransactions: params => dispatch(fetchTransactions('municipal-account', params, true)),
  onDownload: () => dispatch(download(`transactions/municipal-account`, locale, 'filter-account-transactions')),
  resetFilters: () => dispatch(reset('filter-account-transactions')),
});

export const MunicipalAccountContainer = compose(
  withAuthorization(['municipalAccount', 'read']),
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withPagination,
)(AccountTransactionsComponent);
