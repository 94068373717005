import { fork, put, takeEvery, select } from 'redux-saga/effects';
import { change } from 'redux-form';

import { FILL_VALUES } from './register.action-types';

export const formValueSelector = (state, form, from) => state.form[form].values[from];

export function* onFillValuesClick({ payload: { form: formName, from, to } }) {
  const _from = from.split('.');
  const fromSection = _from.length > 1 ? _from[0] : from;

  let values = yield select(formValueSelector, formName, fromSection);

  if (_from.length > 1) {
    values = values[_from[1]]
  }
  
  yield put(change(formName, to, values));
}

export function* registerSaga() {
  yield fork(takeEvery, FILL_VALUES, onFillValuesClick);
}
