// @flow

import React, { Component } from 'react';
import MaskedInput from 'react-input-mask';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import uniqueId from 'lodash/uniqueId';
import pick from 'lodash/pick';

import { ConnectedGroupComponent, TooltipComponent } from 'app/shared';

import type { Props } from './connected-input.component.types';
import styles from './connected-input.module.scss';
import { textareaFormatChecker } from '../../../utils/textarea-format-checker/textarea-format-checker';

export class ConnectedInputComponent extends Component<Props> {
  static defaultProps = {
    mask: {},
  };

  render() {
    const id = uniqueId('input_');
    const isInvalid = this.props.meta.error;
    const isSubmitFailed = this.props.meta.submitFailed;

    const inputClassNames = classNames('form-control', styles.input, this.props.className, {
      'form-control-lg': this.props.large,
      'is-invalid': isInvalid,

      [styles.large]: this.props.large,
      [styles.withAddon]: !!this.props.addon || !!this.props.button || !!this.props.append,
      [styles.withIcon]: !!this.props.icon,
    });

    const inputGroupClassNames = classNames('input-group', {
      'is-invalid': isInvalid,

      [styles.focus]: this.props.meta.active,
      [styles.dark]: this.props.dark,
    });

    return (
      <ConnectedGroupComponent id={id} {...this.props}>
        {!!this.props.icon && (
          <span className="input-group-prepend">
            <span className={classNames('input-group-text', styles.icon)}>
              <i className={`fi fi-${this.props.icon}`} />
            </span>
          </span>
        )}

        <div className={inputGroupClassNames} data-name={this.props.input.name}>
          <MaskedInput
            id={id}
            {...this.props.input}
            {...this.props.mask}
            {...pick(this.props, ['disabled', 'onClick', 'pattern', 'placeholder', 'readOnly', 'type', 'maxLength'])}
            inputRef={this.props.inputRef}
            className={inputClassNames}
            value={textareaFormatChecker(this.props.input.value)}
          />

          {!!this.props.addon && (
            <span className="input-group-append m-0">
              <span className="input-group-text">
                <TooltipComponent overlay={this.props.addon} placement="top">
                  <span className={styles.info} />
                </TooltipComponent>
              </span>
            </span>
          )}

          {this.props.append && <span className="input-group-append">{this.props.append}</span>}

          {!!this.props.button && (
            <span className="input-group-append">
              <span className={classNames('input-group-text', styles.button)}>{this.props.button}</span>
            </span>
          )}
        </div>

        {isInvalid && !isSubmitFailed && (
          <div className={styles.invalidInputMessage}>
            <FormattedMessage id={isInvalid} />
          </div>
        )}
      </ConnectedGroupComponent>
    );
  }
}
