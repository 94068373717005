import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { formValues } from 'redux-form';

import { OrdersCreateProductsComponent } from './orders-create-products.component';

export const OrdersCreateProductsContainer = compose(
  injectIntl,
  formValues('products'),
)(OrdersCreateProductsComponent);
