// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import type { Props } from './language-switcher.component.types';
import styles from './language-switcher.module.scss';

export class LanguageSwitcherComponent extends Component<Props> {
  render() {
    return (
      <div className={styles.links}>
        <button
          onClick={() => this.props.updateLocale('fi')}
          className={classNames({
            [styles.active]: this.props.locale === 'fi',
          })}
        >
          <FormattedMessage id="CORE.FINNISH" />
        </button>
        <button
          onClick={() => this.props.updateLocale('sv')}
          className={classNames({
            [styles.active]: this.props.locale === 'sv',
          })}
        >
          <FormattedMessage id="CORE.SWEDISH" />
        </button>
        <button
          onClick={() => this.props.updateLocale('en')}
          className={classNames({
            [styles.active]: this.props.locale === 'en',
          })}
        >
          <FormattedMessage id="CORE.ENGLISH" />
        </button>
      </div>
    );
  }
}
