import { compose } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { injectIntl } from 'react-intl';

import { withCoreContext } from 'app/core/core.context';

import { OrdersCreateInvoicingOptionsComponent } from './orders-create-invoicing-options.component';

const mapStateToProps = (state, { form }) => ({
  invoicingMethod: formValueSelector(form)(state, 'invoicing.invoicingMethod'),
});

export const OrdersCreateInvoicingOptionsContainer = compose(
  injectIntl,
  withCoreContext,
  connect(mapStateToProps),
)(OrdersCreateInvoicingOptionsComponent);
