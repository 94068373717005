// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './pro-tip.module.scss';
import type { Props } from './pro-tip.types';

export class ProTip extends Component<Props> {
  render() {
    return (
      <div className={styles.proTip}>
        <span className={styles.proTipHeader}>
          <FormattedMessage id="CONTRACTS.PRO_TIP_TITLE" />
        </span>
        <FormattedMessage id={`CONTRACTS.PRO_TIP_TEXT.${this.props.role}`} />
      </div>
    );
  }
}
