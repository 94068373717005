// @flow

import React, { Component } from 'react';

import { SidebarGroupComponent, SidebarItemContainer, SidebarTitleComponent } from 'app/core/sidebar/shared';
import { checkPermissions } from 'app/utils';

import { LanguageSwitcherContainer } from 'app/core/sidebar/language-switcher/language-switcher.container';

import type { Props, State } from './sidebar-settings.component.types';
import styles from './sidebar-settings.module.scss';

export class SidebarSettingsComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    const venueUsersPermissions =
      checkPermissions(this.props.permissions, ['venue', 'read']) &&
      checkPermissions(this.props.permissions, ['venueUsers', 'read']);

    return (
      <>
        <SidebarTitleComponent>{this.props.intl.formatMessage({ id: 'NAV.SETTINGS.TITLE' })}</SidebarTitleComponent>
        <SidebarGroupComponent>
          {this.props.isApproved &&
            checkPermissions(this.props.permissions, ['currentBusinessDetails', 'read']) && (
              <SidebarItemContainer
                icon="pencil-edit-button"
                name={this.props.intl.formatMessage({ id: 'NAV.GENERAL.CUSTOMER_INFORMATION' })}
                to="/customer-information"
              />
            )}

          {venueUsersPermissions && (
            <SidebarItemContainer
              icon="multiple-users-silhouette"
              name={this.props.intl.formatMessage({ id: 'NAV.VENUE.VENUE_USERS' })}
              to={`/venues/${this.props.business}/users`}
            />
          )}

          {/* {checkPermissions(this.props.permissions, ['venueCards', 'read']) && (
            <SidebarItemContainer
              icon="credit-card"
              name={this.props.intl.formatMessage({ id: 'NAV.ADDITIONAL.ACTIVATION_CARDS' })}
              to="/cards/activation"
            />
          )} */}

          {checkPermissions(this.props.permissions, ['customerUsers', 'read']) && (
            <SidebarItemContainer
              icon="multiple-users-silhouette"
              name={this.props.intl.formatMessage({ id: 'NAV.ADDITIONAL.USER_MANAGEMENT' })}
              to="/users"
            />
          )}

          {/*  {checkPermissions(this.props.permissions, ['subscription', 'create']) && (
            <SidebarItemContainer
              icon="yearly-limit"
              name={this.props.intl.formatMessage({ id: 'NAV.ADDITIONAL.YEARLY_LIMITS' })}
              to="/yearly-limits"
            />
          )} */}

          <SidebarItemContainer
            icon="speaker"
            name={this.props.intl.formatMessage({ id: 'NAV.ACCOUNT.HELP_CENTER' })}
            onClick={() => window.open(`${this.props.intl.formatMessage({ id: 'NAV.ACCOUNT.HELP_LINK' })}`, '_blank')}
          />

          <SidebarItemContainer
            icon="signing-the-contract"
            name={this.props.intl.formatMessage({ id: 'NAV.SETTINGS.TERMS' })}
            to="/terms"
          />

          <SidebarItemContainer
            icon="language"
            name={this.props.intl.formatMessage({ id: 'NAV.SETTINGS.CHANGE_LANGUAGE' })}
            onClick={() => this.setState({ languageSwitcherOpened: true })}
          />

          {this.state.languageSwitcherOpened && (
            <LanguageSwitcherContainer onClose={() => this.setState({ languageSwitcherOpened: false })} />
          )}

          {/*   <SidebarItemContainer
            icon="arrow"
            name={this.props.intl.formatMessage({ id: 'NAV.SETTINGS.EXTRANET' })}
            to={process.env.REACT_APP_OLD_EXTRANET_URL}
            external={true}
          /> */}

          <SidebarItemContainer
            icon="power-button-off"
            name={this.props.intl.formatMessage({ id: 'NAV.SETTINGS.LOGOUT' })}
            onClick={this.props.onLogout}
            className={styles.logout}
          />
        </SidebarGroupComponent>
      </>
    );
  }
}
