// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { AccountsListTablePlaceholder } from './table/accounts-list-table.placeholder';
import { AccountsListTilePlaceholder } from './tile/accounts-list-tile.placeholder';

import { CardComponent } from 'app/shared';
import { ListComponent } from 'app/shared/list/list.component';

import type { Props } from './accounts-list.placeholder.types';

export class AccountsListPlaceholder extends Component<Props> {
  static defaultProps = {
    accounts: [...new Array(5)],
  };

  render() {
    return (
      <CardComponent>
        <div className="card-header">
          <h4 className="card-title">
            <FormattedMessage id="ACCOUNTS.ACCOUNTS_LIST" />
          </h4>
        </div>
        <div className="card-body">
          <ListComponent
            list={this.props.accounts}
            table={AccountsListTablePlaceholder}
            tile={AccountsListTilePlaceholder}
          />
        </div>
      </CardComponent>
    );
  }
}
