// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { PlaceholderComponent, TileComponent } from 'app/shared';

export class SettlementsListTilePlaceholder extends Component<any> {
  render() {
    return (
      <TileComponent>
        <h3>
          <PlaceholderComponent width={120} height={27} />
        </h3>

        <div className="row">
          <div className="col">
            <FormattedMessage id="SETTLEMENTS.LIST.HEADERS.DATE" tagName="small" />
            <PlaceholderComponent width={100} />
          </div>
          <div className="col">
            <FormattedMessage id="SETTLEMENTS.LIST.HEADERS.GROSS_AMOUNT" tagName="small" />
            <PlaceholderComponent width={80} />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <FormattedMessage id="SETTLEMENTS.LIST.HEADERS.INTERVAL" tagName="small" />
            <PlaceholderComponent width={200} />
          </div>
        </div>
      </TileComponent>
    );
  }
}
