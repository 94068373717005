// @flow

import React, { Component } from 'react';

import {
  SidebarGroupComponent,
  SidebarItemContainer,
  SidebarNestedContainer,
  SidebarTitleComponent,
} from 'app/core/sidebar/shared';
import { checkPermissions } from 'app/utils';
import { hasAccess } from 'app/online-payment/utils/has-access';

import type { Props } from './sidebar-general.component.types';

export class SidebarGeneralComponent extends Component<Props> {
  render() {
    if (
      !checkPermissions(
        this.props.permissions,
        ['voucherCheck', 'read'],
        ['mobilePayment', 'read'],
        ['accountToken', 'read'],
        ['venues', 'read'],
        ['venues', 'create'],
        ['venue', 'update'],
        ([
          ['venue', 'read'],
          ['venueUsers', 'read'],
        ]: [string, 'create' | 'read' | 'update' | 'delete'][]),
        ['subscription', 'create'],
      )
    ) {
      return null;
    }

    const smartumPayPermissions =
      checkPermissions(this.props.permissions, ['accountToken', 'read']) ||
      checkPermissions(this.props.permissions, ['companyTransactions', 'read']);

    return (
      <>
        <SidebarTitleComponent>{this.props.intl.formatMessage({ id: 'NAV.GENERAL.TITLE' })}</SidebarTitleComponent>
        <SidebarGroupComponent>
          {smartumPayPermissions && (
            <SidebarItemContainer
              icon="hand-graving-smartphone"
              name={this.props.intl.formatMessage({ id: 'NAV.GENERAL.SMARTUMPAY' })}
              to="/smartumpay"
            />
          )}

          <SidebarNestedContainer
            icon="commerce"
            name={this.props.intl.formatMessage({ id: 'NAV.PAYMENT.TITLE' })}
            links={[
              {
                icon: 'qr-code',
                name: this.props.intl.formatMessage({ id: 'NAV.PAYMENT.MOBILE_PAYMENT' }),
                to: '/payments/mobile',
                permissions: ['mobilePayment', 'read'],
              },
              {
                icon: 'qr-code',
                name: this.props.intl.formatMessage({ id: 'NAV.PAYMENT.MOBILE_PAYMENT_TOKEN' }),
                to: '/payments/token',
                permissions: ['accountToken', 'read'],
              },
            ]}
          />

          <SidebarNestedContainer
            links={[
              {
                icon: 'external-link-symbol',
                name: this.props.intl.formatMessage({ id: 'NAV.GENERAL.VENUE_SEARCH' }),
                to: 'https://oma.smartum.fi/',
                permissions: ['accountToken', 'read'],
                external: true,
              },
            ]}
          />

          <SidebarNestedContainer
            icon="business"
            name={this.props.intl.formatMessage({ id: 'NAV.VENUE.TITLE' })}
            links={[
              {
                icon: 'business',
                name: this.props.intl.formatMessage({ id: 'NAV.VENUE.VENUE_LIST' }),
                to: '/venues',
                permissions: ['venues', 'read'],
              },
              {
                icon: 'business',
                name: this.props.intl.formatMessage({ id: 'NAV.VENUE.TITLE' }),
                to: `/venues/${this.props.business}/edit`,
                permissions: ['venue', 'update'],
              },
            ]}
            shortcuts={[
              {
                name: this.props.intl.formatMessage({ id: 'VENUES.ADD_NEW_VENUE' }),
                to: '/venues/add',
                permissions: ['venues', 'create'],
              },
            ]}
          />

          <SidebarNestedContainer
            icon="graphic"
            name={this.props.intl.formatMessage({ id: 'NAV.METABASE.TITLE' })}
            links={[
              {
                icon: 'order-vouchers',
                name: this.props.intl.formatMessage({ id: 'NAV.METABASE.SEE_REPORT' }),
                to: '/metabase-dashboard',
                permissions: ['metabaseDashboardBilling', 'read'],
              },
              {
                icon: 'order-vouchers',
                name: this.props.intl.formatMessage({ id: 'NAV.METABASE.PRINT_PDF' }),
                to: '/metabase-download-pdf',
                permissions: ['metabaseDashboardBilling', 'read'],
              },
            ]}
          />

          {hasAccess(this.props.role) && (
            <>
              <SidebarItemContainer
                icon="desktop-payment"
                name={this.props.intl.formatMessage({ id: 'NAV.GENERAL.ONLINE_PAYMENT' })}
                to="/online-payment"
              />
              <SidebarItemContainer
                icon="sms-payment"
                name={this.props.intl.formatMessage({ id: 'NAV.GENERAL.SMS_PAYMENT' })}
                to="/sms-payment"
              />
            </>
          )}

          {checkPermissions(this.props.permissions, ['subscription', 'create']) && (
            <SidebarItemContainer
              icon="interface"
              name={this.props.intl.formatMessage({ id: 'NAV.GENERAL.EMONEY_SUBSCRIPTIONS' })}
              to="/subscriptions/emoney"
            />
          )}
        </SidebarGroupComponent>
      </>
    );
  }
}
