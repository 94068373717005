// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { AsideComponent } from 'app/core';

import styles from './error.module.scss';

export class ErrorComponent extends PureComponent<any> {
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <AsideComponent />
          <div className={classNames('col-12', styles.column)}>
            <div className={styles.section}>
              <FormattedMessage id="ERROR.TITLE" tagName="h1" />
              <FormattedMessage
                id="ERROR.DESC"
                values={{
                  refresh: (
                    <button onClick={() => window.location.reload()}>
                      <FormattedMessage id="ERROR.REFRESH" />
                    </button>
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
