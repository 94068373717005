// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Switch } from 'react-router-dom';

import { debounce } from 'lodash';

import { TransactionDetailsContainer } from '../details/transaction-details.container';
import { TransactionsVisaFiltersContainer } from './filters/transactions-visa-filters.container';
import { TransactionsVisaPlaceholder } from './placeholder/transactions-visa.placeholder';
import { TransactionsVisaTableComponent } from './table/transactions-visa-table.component';
import { TransactionsVisaTileComponent } from './tile/transactions-visa-tile.component';

import { HeaderComponent } from 'app/core';
import { ListComponent } from 'app/shared/list/list.component';
import { ButtonComponent, CardComponent, LoadMoreComponent, ModalComponent } from 'app/shared';
import { currencyFormatter } from 'app/utils';

import { generateStatus } from 'app/redux/transactions/transactions.reducer';

import type { Props } from './transactions-visa.component.types';
import styles from '../transactions.module.scss';

export class TransactionsVisaComponent extends Component<Props> {
  render() {
    const noResults = this.props.loading || !this.props.transactions.length;
    const generateStatusPending = this.props.generateStatus === generateStatus.PENDING;
    const generateStatusFailed = this.props.generateStatus === generateStatus.FAILED;

    return (
      <div>
        <HeaderComponent
          breadcrumbs={[{ icon: 'transactionhistory' }, { name: <FormattedMessage id="SIDEBAR.VISA_TRANSACTIONS" /> }]}
          button={
            <ButtonComponent
              size="large"
              theme="white"
              onClick={() =>
                this.props.onDownload({
                  businessId: this.props.business,
                  filters: this.props.filters,
                })
              }
              disabled={noResults}
            >
              <i className="fi fi-xlsx-file-format-extension" />
              <FormattedMessage id="TRANSACTIONS.DOWNLOAD_AS_XLSX" />
            </ButtonComponent>
          }
        >
          <FormattedMessage id="TRANSACTIONS.VISA_TRANSACTIONS" tagName="h2" />
        </HeaderComponent>

        {generateStatusPending && (
          <ModalComponent title={<FormattedMessage id="TRANSACTIONS.GENERATE_XLS" />} isClosingBlocked />
        )}
        {generateStatusFailed && (
          <ModalComponent
            title={<FormattedMessage id="TRANSACTIONS.GENERATE_XLS_ERROR" />}
            onClose={this.props.onClose}
          />
        )}

        <CardComponent>
          <TransactionsVisaFiltersContainer onChange={debounce(this.props.onFiltersChange, 500)} />

          {this.props.loading && <TransactionsVisaPlaceholder />}

          {this.props.loading || (
            <div className="card-header card-header--between">
              <h4 className="card-title">
                <FormattedMessage
                  id="TRANSACTIONS.COUNTER"
                  values={{ count: this.props.transactions.length, total: this.props.meta.total }}
                />
              </h4>
              {!!this.props.transactions.length && (
                <h6 className={styles.totalAmount}>
                  <FormattedMessage
                    id="TRANSACTIONS.TOTAL_AMOUNT"
                    values={{ total: currencyFormatter.format(this.props.totalAmount) }}
                  />
                </h6>
              )}
            </div>
          )}

          {this.props.loading || (
            <div className="card-body">
              <ListComponent
                list={this.props.transactions}
                table={TransactionsVisaTableComponent}
                tile={TransactionsVisaTileComponent}
                props={{
                  permissions: this.props.permissions,
                }}
              />

              {this.props.transactions.length < this.props.meta.total && (
                <LoadMoreComponent onClick={this.props.onLoadMore} fetching={this.props.fetching} />
              )}
            </div>
          )}
        </CardComponent>

        <Switch>
          <Route
            path={`${this.props.match.url}/:account/:transaction`}
            render={props => <TransactionDetailsContainer pathname={this.props.match.url} {...props} />}
          />
        </Switch>
      </div>
    );
  }
}
