import { SubmissionError } from 'redux-form';

import isEmpty from 'lodash/isEmpty';

export const validationMiddleware = () => next => action => {
  if (!action.error || !(action.payload instanceof Error) || (action.meta && action.meta.noValidation)) {
    return next(action);
  }

  if (400 === action.payload.status) {
    const { errors, message } = action.payload.response;

    next(action);

    if (!isEmpty(errors)) {
      throw new SubmissionError({ ...errors, _error: message });
    } else {
      throw new SubmissionError({
        _error: message,
      });
    }
  }

  return next(action);
};
