// @flow

import React, { Component } from 'react';

import { get } from 'lodash';

import { CardComponent } from 'app/shared';

import { SubscriptionFormPlaceholderContainer } from '../form/placeholder/subscription-form.placeholder.container';
import { SubscriptionFormContainer } from './subscription-form.container';

import type { Props } from './create-subscription.component.types';

export class CreateSubscriptionComponent extends Component<Props> {
  componentDidMount() {
    this.props.fetchDictionary();
  }

  render() {
    const title = get(this.props.location.state, 'title');
    const type = this.props.match.params.type;

    return (
      <div>
        <CardComponent header={false}>
          {this.props.loading && <SubscriptionFormPlaceholderContainer type={type} />}
          {this.props.loading || <SubscriptionFormContainer title={title} />}
        </CardComponent>
      </div>
    );
  }
}
