import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import { withCoreContext } from 'app/core/core.context';

import { SubscriptionFormPlaceholder } from './subscription-form.placeholder';

export const SubscriptionFormPlaceholderContainer = compose(
  injectIntl,
  withCoreContext,
)(SubscriptionFormPlaceholder);
