// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { CardComponent, TableComponent, SelectComponent, ProTip } from 'app/shared';
import { checkPermissions, dateFormatter } from 'app/utils';
import { SIZE_SELECT_OPTIONS, USER_ROLES } from 'app/constants';
import type { Props } from './contract-list.component.types';
import styles from './contract-list.module.scss';
import ContractsPlaceholder from '../placeholder/contracts.placeholder';
import { Link, Route, Switch } from 'react-router-dom';
import { EditContractContainer } from './edit-contract/edit-contract.container';

export class ContractListComponent extends Component<Props> {
  UNSAFE_componentWillMount() {
    this.props.history.push({ search: '' });
  }
  render() {
    const {
      loading,
      contracts,
      history,
      intl,
      permissions,
      onDownload,
      pagination,
      business,
      match,
      role,
    } = this.props;
    const noResults = loading || !contracts.length;

    const handleSizeChange = async (size: number) => {
      history.push(`?size=${size}`);
    };

    const isCompanyBuyer = role === USER_ROLES.ROLE_COMPANY_BUYER;
    const isVenueManager = role === USER_ROLES.ROLE_VENUE_MANAGER;

    let nameTitle = 'CONTRACTS.VENUE';
    if (isVenueManager) {
      nameTitle = 'CONTRACTS.COMPANY';
    }

    return (
      <>
        <CardComponent>
          <div className={classNames('card-header', styles.cardHeader)}>
            <h4 className="card-title">
              <FormattedMessage id={`CONTRACTS.LIST_TITLE.${role}`} />
            </h4>
            <SelectComponent
              options={SIZE_SELECT_OPTIONS}
              label="CORE.SHOW"
              onChange={handleSizeChange}
              selectedValue={10}
            />
          </div>
          <div className="card-body" style={{ overflowX: 'auto' }}>
            <TableComponent className="table-striped">
              <thead>
                <tr>
                  <FormattedMessage id={nameTitle} tagName="th" />
                  <FormattedMessage id="CONTRACTS.BENEFIT" tagName="th" />
                  <FormattedMessage id="CONTRACTS.START_DATE" tagName="th" />
                  <FormattedMessage id="CONTRACTS.END_DATE" tagName="th" />
                  <FormattedMessage id="CONTRACTS.STATUS" tagName="th" />
                </tr>
              </thead>
              <tbody>
                {!noResults &&
                  contracts.map(
                    ({
                      id: contractId,
                      venue,
                      benefitType,
                      startDate,
                      endDate,
                      status,
                      address,
                      phoneNumber,
                      vendorId,
                    }) => {
                      const indicator = status === 'APPROVED' ? styles.statusActive : styles.statusInactive;
                      const disabled = status === 'DELETED';
                      return (
                        <tr key={contractId} className={classNames(disabled && styles.inactive)}>
                          <td>{venue}</td>
                          <td>{intl.formatMessage({ id: `BENEFITS.${benefitType}.TITLE` })}</td>
                          <td>{dateFormatter(startDate)}</td>
                          <td>{endDate && dateFormatter(endDate)}</td>
                          <td>
                            <div className={indicator} />
                            {intl.formatMessage({ id: `CONTRACTS.STATUSES.${status}` })}
                          </td>
                          {checkPermissions(permissions, ['contracts', 'update']) && (
                            <td className={styles.button}>
                              {!disabled && (
                                <Link
                                  to={{
                                    pathname: `${match.url}/${vendorId}/${contractId}/edit`,
                                    state: {
                                      venue,
                                      benefitType,
                                      address,
                                      phoneNumber,
                                      startDate,
                                      endDate,
                                      keepScrollPosition: true,
                                    },
                                    search: this.props.location.search,
                                  }}
                                  className={classNames(styles.editContract, disabled && styles.disabledButton)}
                                >
                                  <FormattedMessage id="CORE.EDIT" />
                                </Link>
                              )}
                            </td>
                          )}
                        </tr>
                      );
                    },
                  )}
                {noResults && <ContractsPlaceholder />}
              </tbody>
            </TableComponent>
            <div className={styles.footer}>
              <div className={styles.pagination}>{pagination}</div>
              <span>
                {!noResults &&
                  !isVenueManager && (
                    <button className="btn btn-primary" onClick={() => onDownload()}>
                      <i className="fi fi-print" /> <FormattedMessage id="CONTRACTS.PRINT" />
                    </button>
                  )}{' '}
                {checkPermissions(permissions, ['contracts', 'create']) && (
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      history.push('/contracts/new-contract');
                    }}
                  >
                    <FormattedMessage id="CONTRACTS.NEW_CONTRACT" />
                  </button>
                )}
              </span>
            </div>
            {!isCompanyBuyer && <ProTip role={role} />}
          </div>
        </CardComponent>
        <Switch>
          <Route
            path={`${match.url}/:vendorId/:contractId/edit`}
            render={props => <EditContractContainer business={business} {...props} />}
          />
        </Switch>
      </>
    );
  }
}
