// @flow

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import get from 'lodash/get';
import isFunction from 'lodash/isFunction';

import { REDIRECTS } from 'app/redux/users/users.saga.js';

import type { ComponentType } from 'react';
import type { Props } from './with-authorization.hoc.types';

export const withAuthorization = (...paths: (string | Function)[][]) => (WrappedComponent: ComponentType<any>) =>
  connect(({ permissions, users: { business, current: { businesses }, role } }, ownProps) => ({
    business,
    businesses,
    role,
    authorized: paths.find(
      // $FlowFixMe
      path =>
        !!get(permissions[business][role], path.map(element => (isFunction(element) ? element(ownProps) : element))),
    ),
  }))(
    class Authorize extends Component<Props> {
      render() {
        const { role } = this.props;

        if (!this.props.authorized) {
          return (
            <Redirect
              to={{
                pathname: role && REDIRECTS[role] ? REDIRECTS[role] : '/',
              }}
            />
          );
        }

        return <WrappedComponent {...this.props} module={this.props.authorized[0]} />;
      }
    },
  );
