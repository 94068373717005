// @flow

import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { push } from 'react-router-redux';

import { withAuthorization } from 'app/common';
import { USER_ROLES } from 'app/constants';

import { editUserRoles } from 'app/redux/users/users.actions';

import { UsersEditRolesComponent } from './users-edit-roles.component';

const mapStateToProps = (
  { dictionaries, users: { entities: usersList, role: userRole } },
  { context, customer, user, userManagementRoles },
) => ({
  initialValues: {
    roles: (usersList[customer].list.find(x => x.id === user) || { roles: [] }).roles,
  },
  roles: (userManagementRoles || dictionaries[`user-roles/${context}`])
    .filter(role => {
      if (userRole === USER_ROLES.ROLE_SERVICE_PROVIDER_MANAGER || userRole === USER_ROLES.ROLE_COMPANY_BUYER) {
        return role.visible;
      }
      return role.editable;
    })
    .map(role => ({
      id: role.name,
      name: `ROLES.${role.name}`,
      editable: role.editable,
    })),
  user: usersList[customer].list.find(x => x.id === user),
});

const mapDispatchToProps = (dispatch, { context, customer, pathname, user }) => ({
  onClose: () => dispatch(push(pathname)),
  onSubmit: (roles, selected) =>
    dispatch(
      editUserRoles(
        customer,
        context,
        user,
        {
          roles: roles.filter(role => role.editable).map(role => ({
            active: selected.includes(role.id),
            role: role.id,
          })),
        },
        selected,
      ),
    ),
  onSubmitSuccess: name =>
    dispatch(
      push({
        pathname,
        state: {
          success: {
            type: 'EDIT_USER_ROLES',
            payload: name,
          },
        },
      }),
    ),
});

export const UsersEditRolesContainer = compose(
  withAuthorization([({ context }) => `${context}Users`, 'update']),
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({ form: 'edit-user-roles' }),
)(UsersEditRolesComponent);
