// @flow

import React, { Component } from 'react';
import { Field, FormSection } from 'redux-form';
import { upperFirst, toLower } from 'lodash';

import { ConnectedInputComponent, ConnectedToggleComponent, ConnectedPhoneNumberInputComponent } from 'app/shared';

import type { Props } from './personal-section.component.types';
import styles from './personal-section.module.scss';

export class PersonalSectionComponent extends Component<Props> {
  render() {
    return (
      <FormSection name={this.props.name}>
        <div className="card-header card-header--between">{this.props.children}</div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-6">
              <Field
                name="firstName"
                component={ConnectedInputComponent}
                label={this.props.intl.formatMessage({ id: 'PROFILE.FIRST_NAME' })}
                placeholder={this.props.intl.formatMessage({ id: 'PROFILE.FIRST_NAME' })}
                required
                normalize={upperFirst}
              />
            </div>
            <div className="col-lg-6">
              <Field
                name="lastName"
                component={ConnectedInputComponent}
                label={this.props.intl.formatMessage({ id: 'PROFILE.LAST_NAME' })}
                placeholder={this.props.intl.formatMessage({ id: 'PROFILE.LAST_NAME' })}
                required
                normalize={upperFirst}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Field
                name="email"
                type="email"
                component={ConnectedInputComponent}
                label={this.props.intl.formatMessage({ id: 'PROFILE.EMAIL' })}
                placeholder={this.props.intl.formatMessage({ id: 'PROFILE.EMAIL' })}
                required
                normalize={toLower}
              />
            </div>
            <div className="col-lg-6">
              <Field
                name="mobilePhone"
                component={ConnectedPhoneNumberInputComponent}
                label={this.props.intl.formatMessage({ id: 'PROFILE.MOBILE_PHONE' })}
                placeholder={this.props.intl.formatMessage({ id: 'PROFILE.MOBILE_PHONE' })}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Field
                name="workPhone"
                component={ConnectedPhoneNumberInputComponent}
                label={this.props.intl.formatMessage({ id: 'PROFILE.WORK_PHONE' })}
                placeholder={this.props.intl.formatMessage({ id: 'PROFILE.WORK_PHONE' })}
              />
            </div>
          </div>
          <div className={styles.marketing}>
            <Field
              name="marketing"
              component={ConnectedToggleComponent}
              normalize={v => !!v}
              label={this.props.intl.formatMessage({ id: 'PROFILE.MARKETING' })}
              helpText={this.props.intl.formatMessage({ id: 'PROFILE.MARKETING_INFO' })}
            />
          </div>
        </div>
      </FormSection>
    );
  }
}
