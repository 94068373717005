// @flow
import React, { Component } from 'react';

import { SubscriptionFormPlaceholderContainer } from '../form/placeholder/subscription-form.placeholder.container';

import { SubscriptionFormContainer } from './subscription-form.container';

import { CardComponent } from 'app/shared';

import type { Props } from './edit-subscription.component.types';

export class EditSubscriptionComponent extends Component<Props> {
  componentDidMount() {
    this.props.fetchDictionary();
    this.props.fetchSubscription();
  }

  render() {
    const type = this.props.match.params.type;

    return (
      <div>
        <CardComponent header={false}>
          {this.props.loading && <SubscriptionFormPlaceholderContainer edit type={type} />}
          {this.props.loading || <SubscriptionFormContainer subscription={this.props.subscription} />}
        </CardComponent>
      </div>
    );
  }
}
