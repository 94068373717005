// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import type { Props } from './connected-group.component.types';
import styles from './connected-group.module.scss';

export class ConnectedGroupComponent extends Component<Props> {
  render() {
    const labelClassNames = classNames(styles.label, {
      [styles.required]: this.props.required,
    });

    return (
      <div className={classNames('form-group', styles.group)}>
        {!!this.props.label && (
          <label className={labelClassNames} htmlFor={this.props.id}>
            {this.props.label}
          </label>
        )}

        {this.props.children}

        {this.props.meta.submitFailed &&
          !!this.props.meta.error && (
            <div className="invalid-feedback">
              <FormattedMessage id={this.props.meta.error} values={this.props.values} />
            </div>
          )}

        {/*{!(this.props.meta.submitFailed && !!this.props.meta.error) &&*/}
        {/*this.props.addon && <small className="form-text text-muted">{this.props.addon}</small>}*/}
      </div>
    );
  }
}
