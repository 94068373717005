import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redux';

import { withAuthorization } from 'app/common';

import { fetchTransaction, refundTransaction } from 'app/redux/transactions/transactions.actions';

import { TransactionDetailsComponent } from './transaction-details.component';

const mapStateToProps = (
  { permissions, transactions: { entities: transactions }, users: { business, role } },
  {
    match: {
      params: { transaction },
    },
  },
) => ({
  loading: !transactions[transaction],
  permissions: permissions[business][role],
  transaction: transactions[transaction],
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { account, transaction },
    },
    number,
    pathname,
  },
) => ({
  fetchTransaction: transaction => dispatch(fetchTransaction(number || account, transaction)),
  onClose: () =>
    dispatch(
      push({
        pathname,
        state: {
          keepScrollPosition: true,
        },
      }),
    ),
  onRefund: () =>
    dispatch(refundTransaction(number || account, transaction)).then(() =>
      dispatch(
        push({
          state: {
            success: {
              type: 'TRANSACTION_REFUNDED',
            },
          },
        }),
      ),
    ),
});

export const TransactionDetailsContainer = compose(
  withAuthorization(['transactionDetails', 'read']),
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(TransactionDetailsComponent);
