// @flow

import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { push } from 'react-router-redux';

import { CreateNewContractComponent } from './create-new-contract.component';
import { createContract, createContractBatch } from '../../../redux/contracts/contracts.actions';
import { fetchAvailableVenues, clearAllVenues } from '../../../redux/venues/venues.actions';
import { withRouter } from 'react-router-dom';

const mapStateToProps = ({ form, venues: { list: venues, allVenues } }) => ({
  availableForms: form,
  venues,
  allVenues,
  waitForFetch: true,
});

const mapDispatchToProps = (dispatch, { business, location: { search }, match }) => ({
  onSubmit: params => {
    const body = {
      ...params,
      vendorId: match.params.vendorId,
    };
    return dispatch(createContract(business, body));
  },
  onSubmitSuccess: () =>
    dispatch(
      push({
        state: {
          success: true,
        },
        search,
      }),
    ),
  onClose: () => {
    return (
      dispatch(
        push({
          pathname: '/contracts/new-contract',
          search,
          state: {
            keepScrollPosition: true,
          },
        }),
      ),
      dispatch(clearAllVenues())
    );
  },
  onSubmitFail: params => {
    if (!params.startDate) {
      dispatch(
        push({
          state: {
            fail: true,
          },
          search,
        }),
      );
    }
  },
  fetchAllVenues: params => dispatch(fetchAvailableVenues(params)),
  onBatchSubmit: contracts => dispatch(createContractBatch(business, contracts)),
});

export const CreateNewContractContainer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({ form: 'new-contract' }),
  withRouter,
)(CreateNewContractComponent);
