// @flow

import React, { Component } from 'react';

import { CardComponent } from 'app/shared';

import { BanknotesOrderFormContainer } from './form/banknotes-order-form.container';
import { BanknotesOrderConfirmContainer } from './confirm/banknotes-order-confirm.container';
import { BanknotesOrderPlaceholderContainer } from './placeholder/banknotes-order.placeholder.container';

import type { BodyType, Props, State, Step } from './banknotes-order.component.types';

export class BanknotesOrderComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      step: 'FORM',
    };
  }

  componentDidMount() {
    this.props.fetchCustomer(this.props.business);
    this.props.fetchDictionaries();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.business !== this.props.business) {
      this.props.fetchCustomer(this.props.business);
    }
  }

  changeStep = (step: Step) => {
    this.setState({ step });
    window.scrollTo(0, 0);
  };

  onSubmit = (body: BodyType) => this.props.onSubmit(body);

  onValidate = (body: BodyType) => this.props.onValidate(body).then(() => this.changeStep('CONFIRM'));

  render() {
    return (
      <>
        {this.props.loading && <BanknotesOrderPlaceholderContainer />}

        {this.props.loading || (
          <CardComponent header={false}>
            <>
              {this.state.step === 'FORM' && (
                <form onSubmit={this.props.handleSubmit(this.onValidate)}>
                  <BanknotesOrderFormContainer submitting={this.props.submitting} />
                </form>
              )}
              {this.state.step === 'CONFIRM' && (
                <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                  <BanknotesOrderConfirmContainer
                    onBack={() => this.changeStep('FORM')}
                    submitting={this.props.submitting}
                    type="banknotesOrders"
                  />
                </form>
              )}
            </>
          </CardComponent>
        )}
      </>
    );
  }
}
