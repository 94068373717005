// @flow

import React, { Component } from 'react';
import MaskedInput from 'react-input-mask';
import Select from 'react-select';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import uniqueId from 'lodash/uniqueId';

import { ConnectedGroupComponent } from 'app/shared';

import type { Props, State } from './connected-url-input.component.types.js.flow';
import styles from '../connected-input/connected-input.module.scss';
import { textareaFormatChecker } from '../../../utils/textarea-format-checker/textarea-format-checker';

const HTTP = 'http://';
const HTTPS = 'https://';

export class ConnectedUrlComponent extends Component<Props, State> {
  static defaultProps = {
    mask: {},
  };

  removePrefix = (value: string) => value.replace(/^https?:\/\//, '');

  state = {
    selectValue: this.props.input.value.includes(HTTP) ? HTTP : HTTPS,
    inputValue: this.removePrefix(this.props.input.value),
  };

  menageInputChange = () => {
    if (!this.state.inputValue) {
      this.props.input.onChange('');
    } else {
      this.props.input.onChange(`${this.state.selectValue}${this.state.inputValue}`);
    }
  };

  handleInputChange = (e: any) => {
    this.setState({ inputValue: e.target.value }, this.menageInputChange);
  };

  handleSelectChange = (option: { label: string, value: string }) => {
    this.setState({ selectValue: option.value }, this.menageInputChange);
  };

  getInputValue = () => {
    return this.removePrefix(this.props.input.value);
  };

  render() {
    const id = uniqueId('input_');
    const isInvalid = this.props.meta.error;
    const isSubmitFailed = this.props.meta.submitFailed;

    const inputClassNames = classNames('form-control', styles.input, this.props.className, {
      'form-control-lg': this.props.large,
      'is-invalid': isInvalid,

      [styles.large]: this.props.large,
      [styles.withAddon]: !!this.props.addon || !!this.props.button || !!this.props.append,
      [styles.withIcon]: !!this.props.icon,
    });

    const inputGroupClassNames = classNames('input-group', {
      'is-invalid': isInvalid,

      [styles.focus]: this.props.meta.active,
      [styles.dark]: this.props.dark,
    });

    return (
      <ConnectedGroupComponent id={id} {...this.props}>
        <div className={inputGroupClassNames} data-name={this.props.input.name}>
          <Select
            id={id}
            options={[
              { label: HTTPS, value: HTTPS },
              { label: HTTP, value: HTTP },
            ]}
            style={{ width: 100, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            value={this.state.selectValue}
            onChange={this.handleSelectChange}
            searchable={false}
            clearable={false}
          />
          <MaskedInput
            id={id}
            className={inputClassNames}
            onChange={this.handleInputChange}
            placeholder={this.props.placeholder}
            value={textareaFormatChecker(this.getInputValue())}
            style={{ borderLeft: 'none' }}
          />
        </div>

        {isInvalid && !isSubmitFailed && (
          <div className={styles.invalidInputMessage}>
            <FormattedMessage id={isInvalid} />
          </div>
        )}
      </ConnectedGroupComponent>
    );
  }
}
