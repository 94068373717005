// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { PlaceholderComponent, TableComponent } from 'app/shared';

import type { Props } from './my-subscriptions-list-table.placeholder.types';

export class MySubscriptionsListTablePlaceholder extends Component<Props> {
  render() {
    return (
      <TableComponent className="table-striped">
        <thead>
          <tr>
            <FormattedMessage id="MY_SUBSCRIPTIONS.LIST_HEADERS.STATUS" tagName="th" />
            <FormattedMessage id="MY_SUBSCRIPTIONS.LIST_HEADERS.LIST" tagName="th" />
            <FormattedMessage id="MY_SUBSCRIPTIONS.LIST_HEADERS.BENEFITS" tagName="th" />
            <FormattedMessage id="MY_SUBSCRIPTIONS.LIST_HEADERS.DATE" tagName="th" />
            <th />
          </tr>
        </thead>
        <tbody>
          {this.props.list.map((_, subscription) => (
            <tr key={subscription}>
              <td>
                <PlaceholderComponent width={16} height={16} radius="50%" />
              </td>
              <td>
                <PlaceholderComponent width={subscription % 2 === 0 ? 250 : 300} />
              </td>
              <td>
                <PlaceholderComponent width={200} />
              </td>
              <td>
                <PlaceholderComponent width={80} />
              </td>
              <td />
            </tr>
          ))}
        </tbody>
      </TableComponent>
    );
  }
}
