// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { PlaceholderComponent, TableComponent } from 'app/shared';

import type { Props } from './subscriptions-list-table.placeholder.types';

export class SubscriptionsListTablePlaceholder extends Component<Props> {
  render() {
    return (
      <TableComponent className="table-striped">
        <thead>
          <tr>
            <FormattedMessage id="SUBSCRIPTION.LIST_HEADERS.NAME" tagName="th" />
            <FormattedMessage id="SUBSCRIPTION.LIST_HEADERS.CLOSING_TIME" tagName="th" />
            <FormattedMessage id="SUBSCRIPTION.LIST_HEADERS.APPROVED_SUBSCRIBED" tagName="th" />
            <FormattedMessage id="SUBSCRIPTION.LIST_HEADERS.TOTAL" tagName="th" />
            <th />
          </tr>
        </thead>
        <tbody>
          {this.props.list.map((_, subscription) => (
            <tr key={subscription}>
              <td>
                <PlaceholderComponent width={subscription % 2 === 0 ? 140 : 160} />
              </td>
              <td>
                <PlaceholderComponent width={subscription % 2 === 0 ? 100 : 120} />
              </td>
              <td>
                <PlaceholderComponent width={subscription % 2 === 0 ? 140 : 180} />
              </td>
              <td>
                <PlaceholderComponent width={subscription % 2 === 0 ? 120 : 110} />
              </td>
              <td className="text-right">
                <PlaceholderComponent width={100} color="purple" />
              </td>
            </tr>
          ))}
        </tbody>
      </TableComponent>
    );
  }
}
