// @flow

import React, { Component } from 'react';
import { Fields, Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import {
  ButtonComponent,
  ConnectedDateRangeComponent,
  ConnectedSelectComponent,
  ConnectedInputComponent,
  CardComponent,
  AlertComponent,
} from 'app/shared';
import { HeaderComponent } from 'app/core';
import { API_URL, USER_ROLES } from 'app/constants';

import type { Props, State, BodyType } from './metabase-download-pdf.component.types';
import styles from './metabase-download-pdf.module.scss';

export class MetabaseDownloadPDFComponent extends Component<Props, State> {
  state = {
    downloading: false,
    isCalendarOpened: false,
    downloadError: null,
  };

  getRoleType = (role: string) => {
    if (this.props.businessType === 'TYPE_SERVICE_PROVIDER') {
      return 'TYPE_SERVICE_PROVIDER';
    }

    const rolesMap = {
      [USER_ROLES.ROLE_SERVICE_PROVIDER_MANAGER]: 'TYPE_SERVICE_PROVIDER',
      [USER_ROLES.ROLE_VENUE_ACCOUNTANT]: 'TYPE_VENUE',
      [USER_ROLES.ROLE_VENUE_CASHIER]: 'TYPE_VENUE',
      [USER_ROLES.ROLE_VENUE_MANAGER]: 'TYPE_VENUE',
    };
    return rolesMap[role] || 'TYPE_SERVICE_PROVIDER';
  };

  componentDidMount() {
    this.props.fetchMetabaseData(this.props.business);
  }

  onCalendarToggle = (state: any) => this.setState({ isCalendarOpened: !!state });

  handleSubmit = (body: BodyType) => {
    this.setState({ downloading: true }, () =>
      this.props
        .onDownloadToken()
        .then(res => {
          this.props
            .onDownloadMetabasePdf(
              {
                business: this.props.business,
                role: this.getRoleType(this.props.role),
              },
              res.payload.data.token,
              body,
            )
            .then(res => {
              if (res.error) {
                if (res.payload.response === 'Data not found') {
                  this.setState({ downloadError: this.props.intl.formatMessage({ id: 'METABASE.DATA_NOT_FOUND' }) });
                } else {
                  this.setState({
                    downloadError: this.props.intl.formatMessage({ id: 'METABASE.SOMETHING_WENT_WRONG' }),
                  });
                }
                this.setState({ downloading: false });
                return;
              }
              window.location.assign(`${API_URL}/${res.meta.path}`);
              this.setState({ downloadError: null });
              setTimeout(() => this.setState({ downloading: false }), 4000);
            });
        })
        .catch(() =>
          this.setState({ downloadError: this.props.intl.formatMessage({ id: 'METABASE.SOMETHING_WENT_WRONG' }) }),
        ),
    );
  };

  render() {
    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    const areOptionsLoaded = !this.props.isLoading && this.props.options;

    return (
      <>
        <HeaderComponent breadcrumbs={[{ icon: 'graphic' }]}>
          <FormattedMessage id="METABASE.PRINT_PDF" tagName="h2" />
        </HeaderComponent>

        {this.state.downloadError && <AlertComponent type="danger">{this.state.downloadError}</AlertComponent>}

        <CardComponent>
          <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
            <div className="card-body">
              <div className="row">
                <div className={columnClassNames}>
                  <Fields
                    names={['startDate', 'endDate']}
                    component={ConnectedDateRangeComponent}
                    relativeNames={['startDate', 'endDate']}
                    onFocusChange={this.onCalendarToggle}
                    label={this.props.intl.formatMessage({ id: 'METABASE.DATE_RANGE' })}
                    parse={value => (value ? value.format('YYYY-MM-DD') : null)}
                    intl={this.props.intl}
                    required
                  />
                </div>

                <div className={columnClassNames}>
                  <Field
                    name="productCode"
                    component={ConnectedSelectComponent}
                    label={this.props.intl.formatMessage({ id: 'METABASE.PRODUCT_CODE' })}
                    options={
                      areOptionsLoaded && this.props.options.product_code.map(option => ({ id: option, name: option }))
                    }
                    none
                  />
                </div>

                <div className={columnClassNames}>
                  <Field
                    name="paymentRef"
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'METABASE.PAYMENT_REF' })}
                    placeholder={this.props.intl.formatMessage({ id: 'METABASE.PAYMENT_REF' })}
                  />
                </div>

                <div className={columnClassNames}>
                  <Field
                    name="settlementId"
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'METABASE.SETTLEMENT_ID' })}
                    placeholder={this.props.intl.formatMessage({ id: 'METABASE.SETTLEMENT_ID' })}
                  />
                </div>

                <div className={columnClassNames}>
                  <Field
                    name="venue"
                    component={ConnectedSelectComponent}
                    label={this.props.intl.formatMessage({ id: 'METABASE.VENUE' })}
                    options={areOptionsLoaded && this.props.options.venue.map(option => ({ id: option, name: option }))}
                    none
                  />
                </div>
              </div>

              <div className={styles.submit}>
                <ButtonComponent loading={this.state.downloading} size="large" theme="primary">
                  {this.props.intl.formatMessage({ id: 'METABASE.PRINT_PDF' })}
                </ButtonComponent>
              </div>
            </div>
          </form>
        </CardComponent>
      </>
    );
  }
}
