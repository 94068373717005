// @flow

import React, { Component } from 'react';
import { Field } from 'redux-form';
import classNames from 'classnames';

import { ButtonComponent, CardComponent, ConnectedInputComponent } from 'app/shared';

import type { BodyType, Props, State } from './password.component.types';
import styles from '../personal/personal.module.scss';

export class PasswordComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      submitted: false,
    };
  }

  onSubmit = (body: BodyType) =>
    this.props
      .onSubmit(body)
      .then(() => this.setState({ submitted: true }))
      .then(() => this.props.reset())
      .then(() => setTimeout(() => this.setState({ submitted: false }), 5000));

  renderValidation = () => {
    const { locale } = this.props.intl;
    if (locale === 'fi') {
      return (
        <div className="description">
          <p>Salasanan on sisällettävä vähintään:</p>
          <ul>
            <li>kahdeksan merkkiä</li>
            <li>numeroita</li>
            <li>isoja kirjaimia</li>
            <li>pieniä kirjaimia</li>
          </ul>
        </div>
      );
    } else if (locale === 'en') {
      return (
        <div className="description">
          <p>The password must contain at least:</p>
          <ul>
            <li>eight characters</li>
            <li>numbers</li>
            <li>uppercase letters</li>
            <li>lowercase letters</li>
          </ul>
        </div>
      );
    } else if (locale === 'sv') {
      return (
        <div className="description">
          <p>Lösenordet bör innehålla:</p>
          <ul>
            <li>åtta tecken</li>
            <li>siffror</li>
            <li>stora bokstäver (versaler)</li>
            <li>små bokstäver (gemener)</li>
          </ul>
        </div>
      );
    }
  };

  render() {
    return (
      <CardComponent>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <div className="card-header">
            <h4 className="card-title">{this.props.intl.formatMessage({ id: 'PROFILE.NEW_PASSWORD' })}</h4>
          </div>
          <div className="card-body">
            <Field
              name="password"
              component={ConnectedInputComponent}
              label={this.props.intl.formatMessage({
                id: 'PROFILE.NEW_PASSWORD',
              })}
              type="password"
            />
            {this.renderValidation()}
            <Field
              name="passwordConfirmation"
              component={ConnectedInputComponent}
              label={this.props.intl.formatMessage({ id: 'PROFILE.CONFIRM_PASSWORD' })}
              type="password"
            />
          </div>
          <div className="card-footer">
            <div className={styles.submit}>
              {this.state.submitted && (
                <>
                  <span className={classNames('fi fi-check', styles.check)} />
                  <p className={styles.message}>{this.props.intl.formatMessage({ id: 'PROFILE.UPDATE_SUCCESS' })}</p>
                </>
              )}

              <ButtonComponent theme="primary" loading={this.props.submitting} size="large">
                {this.props.intl.formatMessage({ id: 'CORE.SAVE' })}
              </ButtonComponent>
            </div>
          </div>
        </form>
      </CardComponent>
    );
  }
}
