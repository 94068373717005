// @flow

import React, { Component } from 'react';

import type { Props, State } from './counter.component.types';

export class CounterComponent extends Component<Props, State> {
  interval: IntervalID;

  constructor(props: Props) {
    super(props);

    this.state = {
      time: this.props.time,
    };
  }

  componentDidMount() {
    this.interval = setInterval(this.tick, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick = () => {
    if (this.state.time > 0) {
      this.setState(prevState => ({
        time: prevState.time - 1,
      }));
    } else {
      if (this.interval) {
        clearInterval(this.interval);
      }

      if (this.props.onFinish) {
        this.props.onFinish();
      }
    }
  };

  render() {
    return <span>{`${Math.floor(this.state.time / 60)}m ${this.state.time % 60}s`}</span>;
  }
}
