import { handleActions } from 'redux-actions';

import * as types from './terms.action-types';

const defaultState = {};

export const termsReducer = handleActions(
  {
    [types.FETCH_TERMS_SUCCESS]: (state, { meta: { name }, payload: { data: terms } }) => ({
      ...state,
      [name]: terms,
    }),
  },
  defaultState,
);
