import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { SubscriptionSubscribeFormQuestionsComponent } from './subscription-subscribe-form-questions.component';

const mapStateToProps = (
  { subscriptions: { entities: subscriptions } },
  {
    match: {
      params: { hash },
    },
  },
) => ({
  questions: subscriptions[hash].questions,
});

export const SubscriptionSubscribeFormQuestionsContainer = compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps),
)(SubscriptionSubscribeFormQuestionsComponent);
