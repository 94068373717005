import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { SmsPaymentInfo } from './sms-payment-info.component';

const mapStateToProps = ({ users: { role } }) => ({
  role,
});

export const SmsPaymentInfoContainer = compose(injectIntl, connect(mapStateToProps))(SmsPaymentInfo);
