// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './select.module.scss';
import Select from 'react-select';
import type { Props, State } from './select.component.types';

export class SelectComponent extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectValue: this.props.selectedValue,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.selectedValue !== prevProps.selectedValue) {
      this.setState({ selectValue: this.props.selectedValue });
    }
  }

  render() {
    const { label, options, onChange } = this.props;

    const handleChange = ({ value }) => {
      this.setState({ selectValue: value });
      onChange(value);
    };

    return (
      <div className={styles.container}>
        {label && <FormattedMessage id={label} />}
        <Select
          options={options.map(option => ({
            label: option.name,
            value: option.id,
          }))}
          value={this.state.selectValue}
          onChange={handleChange}
          clearable={false}
          searchable={this.props.searchable || false}
          className={label ? styles.select : styles['select-no-label']}
        />
      </div>
    );
  }
}
