import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';

import { withAuthorization } from 'app/common';

import { createTransactionPath } from 'app/transactions/utils/create-transaction-path/create-transaction-path.util';

import { validateMobilePayment, createMobilePayment } from 'app/redux/payments/payments.actions';

import { PaymentsMobileComponent } from './payments-mobile.component';
import { push } from 'react-router-redux';

const mapDispatchToProps = dispatch => ({
  onConfirm: body => dispatch(createMobilePayment(body)),
  onConfirmSuccess: transaction =>
    dispatch(
      push({
        pathname: `/transactions/company/${createTransactionPath(transaction)}`,
        state: {
          success: {
            type: 'MOBILE_PAYMENT_CREATED',
          },
        },
      }),
    ),
  onSubmit: body => dispatch(validateMobilePayment(body)),
  onSubmitFail: () => window.scrollTo(0, 0),
  validate: values => {
    if (!values.code) {
      return { code: 'VALIDATION.NOT_BLANK' };
    }
    if (values.code.length !== 16) {
      return { code: 'VALIDATION.INVALID_TOKEN_LENGTH' };
    }
    return {};
  },
});

export const PaymentsMobileContainer = compose(
  withAuthorization(['mobilePayment', 'read']),
  injectIntl,
  connect(
    null,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'mobile',
  }),
)(PaymentsMobileComponent);
