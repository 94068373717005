// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Switch } from 'react-router-dom';

import { MySubscriptionsListPlaceholder } from 'app/subscriptions/my/list/placeholder/my-subscriptions-list.placeholder';
import { MySubscriptionsListTableComponent } from 'app/subscriptions/my/list/table/my-subscriptions-list-table.component';
import { MySubscriptionsListTileComponent } from 'app/subscriptions/my/list/tile/my-subscriptions-list-tile.component';

import { BeneficiaryDetailsSubscriptionsEditContainer } from 'app/beneficiaries/details/subscriptions/edit/beneficiary-details-subscriptions-edit.container';

import { ListComponent } from 'app/shared/list/list.component';
import { CardComponent } from 'app/shared';

import type { Props } from './beneficiary-details-subscriptions.component.types';

export class BeneficiaryDetailsSubscriptionsComponent extends Component<Props> {
  componentDidMount() {
    this.props.fetchBeneficiarySubscriptions();
  }

  render() {
    return (
      <CardComponent>
        <div className="card-header">
          <h4 className="card-title">
            <FormattedMessage id="MY_SUBSCRIPTIONS.TITLE" />
          </h4>
        </div>
        <div className="card-body">
          {this.props.loading && <MySubscriptionsListPlaceholder />}

          {this.props.loading || (
            <>
              <ListComponent
                list={this.props.subscriptions}
                table={MySubscriptionsListTableComponent}
                tile={MySubscriptionsListTileComponent}
                searchable={false}
                props={{
                  url: this.props.match.url,
                }}
              />
              <Switch>
                <Route
                  path={`${this.props.match.url}/:subscriptionBeneficiary`}
                  render={props => (
                    <BeneficiaryDetailsSubscriptionsEditContainer
                      beneficiary={this.props.beneficiary}
                      pathname={this.props.match.url}
                      {...props}
                    />
                  )}
                />
              </Switch>
            </>
          )}
        </div>
      </CardComponent>
    );
  }
}
