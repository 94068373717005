import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';

import { withPagination } from 'app/transactions/with-pagination/with-pagination.hoc';

import { fetchTransactions } from 'app/redux/accounts/accounts.actions';

import { AccountDetailsTransactionsComponent } from './account-details-transactions.component';

const mapStateToProps = (
  { accounts: { entities: accounts }, permissions, users: { business, role } },
  {
    match: {
      params: { number },
    },
  },
) => ({
  loading: !get(accounts, [number, 'transactions']),
  fetching: get(accounts, [number, 'transactions', 'fetching']),
  meta: get(accounts, [number, 'transactions', 'meta']),
  permissions: permissions[business][role] || {},
  transactions: get(accounts, [number, 'transactions', 'list', 'transactions']),
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { number },
    },
  },
) => ({
  fetchTransactions: params => dispatch(fetchTransactions(number, params)),
});

export const AccountDetailsTransactionsContainer = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withPagination,
)(AccountDetailsTransactionsComponent);
