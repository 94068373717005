// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

import type { Props } from './group.component.types';
import styles from 'app/shared/form/connected-group/connected-group.module.scss';
import localStyles from './group.module.scss';

export class GroupComponent extends Component<Props> {
  render() {
    const labelClassNames = classNames(styles.label, {
      [styles.required]: this.props.required,
    });
    return (
      <div className={classNames('form-group', styles.group, !this.props.connected && localStyles.notConnected)}>
        {!!this.props.label && <label className={labelClassNames}>{this.props.label}</label>}

        {this.props.children}
      </div>
    );
  }
}
