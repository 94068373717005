// @flow

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { CardsBeneficiariesOrderContainer } from './beneficiaries/cards-beneficiaries-order.container';
import { CardsListContainer } from './list/cards-list.container';
import { ActivationCardsContainer } from './activation/activation-cards.container';

import type { Props } from './cards.component.types';

export class CardsComponent extends Component<Props> {
  render() {
    return (
      <Switch>
        <Route path={`${this.props.match.url}/activation`} component={ActivationCardsContainer} />
        <Route
          path={`${this.props.match.url}/beneficiaries/:beneficiary/order`}
          component={CardsBeneficiariesOrderContainer}
        />
        <Route exact path={this.props.match.url} component={CardsListContainer} />
      </Switch>
    );
  }
}
