// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { currencyFormatter, dateFormatter } from 'app/utils';

import { ButtonComponent, NoResultsContainer, TableComponent } from 'app/shared';

import { SubscriptionBeneficiariesStatusComponent } from 'app/subscriptions/beneficiaries/status/subscription-beneficiaries-status.component';

import type { Props } from './my-subscriptions-list-table.component.types';

import styles from './my-subscriptions-list-table.module.scss';

export class MySubscriptionsListTableComponent extends Component<Props> {
  render() {
    return (
      <TableComponent className={classNames('table-striped', styles.table)}>
        <thead>
          <tr>
            <FormattedMessage id="MY_SUBSCRIPTIONS.LIST_HEADERS.STATUS" tagName="th" />
            <FormattedMessage id="MY_SUBSCRIPTIONS.LIST_HEADERS.LIST" tagName="th" />
            <FormattedMessage id="MY_SUBSCRIPTIONS.LIST_HEADERS.BENEFITS" tagName="th" />
            <FormattedMessage id="MY_SUBSCRIPTIONS.LIST_HEADERS.DATE" tagName="th" />
            <th />
          </tr>
        </thead>
        <tbody>
          {!!this.props.list.length || (
            <tr>
              <td colSpan={5}>
                <NoResultsContainer searchable={false} />
              </td>
            </tr>
          )}

          {this.props.list.map(subscription => (
            <tr key={subscription.id}>
              <td>
                <SubscriptionBeneficiariesStatusComponent status={subscription.status} />
              </td>
              <td>
                <strong>{subscription.company}</strong>
                <br />
                {subscription.title}
              </td>
              <td>
                {subscription.benefitOptions.map(benefit => (
                  <p key={benefit.id} className="mb-0">
                    <FormattedMessage id={`PRODUCT.${benefit.id}`} />:{' '}
                    <strong>{currencyFormatter.format(benefit.amount)}</strong>
                  </p>
                ))}
              </td>
              <td>
                <small>{dateFormatter(subscription.createdAt)}</small>
              </td>
              <td className="text-right">
                {subscription.editable && (
                  <ButtonComponent
                    theme="outline-secondary"
                    size="small"
                    to={{
                      pathname: `${this.props.url}/${subscription.id}`,
                      state: {
                        keepScrollPosition: true,
                      },
                    }}
                  >
                    <i className="fi fi-pencil-edit-button" />
                  </ButtonComponent>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </TableComponent>
    );
  }
}
