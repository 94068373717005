// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { PlaceholderComponent, TileComponent } from 'app/shared';

export class OrdersListTilePlaceholder extends Component<any> {
  render() {
    return (
      <TileComponent>
        <h3>
          <PlaceholderComponent width={100} height={27} />
        </h3>

        <div className="row">
          <div className="col">
            <FormattedMessage id="ORDER_HISTORY.DATE" tagName="small" />
            <PlaceholderComponent width={120} height={15} />
          </div>

          <div className="col">
            <FormattedMessage id="ORDER_HISTORY.BENEFITS" tagName="small" />
            <PlaceholderComponent width={150} height={18} />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <FormattedMessage id="ORDER_HISTORY.STATUS" tagName="small" />
            <PlaceholderComponent width={80} height={15} />
          </div>
        </div>
      </TileComponent>
    );
  }
}
