// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import { HeaderComponent } from 'app//core';
import { ButtonComponent, CardComponent, ConnectedInputComponent } from 'app/shared';

import type { Props } from './payments-mobile-form.component.types';

export class PaymentsMobileFormComponent extends Component<Props> {
  render() {
    return (
      <>
        <HeaderComponent
          breadcrumbs={[
            {
              icon: 'hand-graving-smartphone',
              to: '/payments/mobile',
            },
          ]}
        >
          <FormattedMessage id="SIDEBAR.MOBILE_PAYMENT" tagName="h2" />
        </HeaderComponent>

        <CardComponent>
          <div className="card-header">
            <h4 className="card-title">
              <FormattedMessage id="MOBILE_PAYMENT.CHARGE_MOBILE_PAYMENT" />
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-12">
                <Field
                  name="code"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'MOBILE_PAYMENT.TOKEN' })}
                  placeholder={this.props.intl.formatMessage({ id: 'MOBILE_PAYMENT.TOKEN' })}
                  addon={this.props.intl.formatMessage({ id: 'MOBILE_PAYMENT.TOKEN_ADDON' })}
                  required
                />
              </div>
            </div>
          </div>
          <div className="card-footer text-right">
            <ButtonComponent theme="primary" size="large" loading={this.props.submitting}>
              <FormattedMessage id="CORE.PROCEED" /> <i className="fi fi-right-arrow-forward" />
            </ButtonComponent>
          </div>
        </CardComponent>
      </>
    );
  }
}
