import { connect } from 'react-redux';

import { TimeoutComponent } from './timeout.component';

import { toggleTimeout } from 'app/redux/timeout/timeout.actions';
import { logoutUser } from 'app/redux/users/users.actions';

const mapStateToProps = ({
  timeout: isActive,
  users: {
    meta: {
      session: { decisionTimeframe },
    },
  },
}) => ({
  isActive,
  decisionTimeframe: decisionTimeframe * 1000,
});

const mapDispatchToProps = dispatch => ({
  onCancel: () => dispatch(logoutUser(true, 'INACTIVITY')),
  onConfirm: () => dispatch(toggleTimeout(false)),
});

export const TimeoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TimeoutComponent);
