import { compose } from 'redux';
import { connect } from 'react-redux';

import { withAuthorization } from 'app/common';

import { YearlyLimitsComponent } from './yearly-limits.component';

import { fetchYearlyLimits } from 'app/redux/yearly-limits/yearly-limits.actions';

const mapStateToProps = ({ yearlyLimits: { entities } }) => ({
  yearlyLimits: entities,
  loading: !entities.length,
});

const mapDispatchToProps = (dispatch, { business }) => ({
  fetchYearlyLimits: () => dispatch(fetchYearlyLimits(business)),
});

export const YearlyLimitsContainer = compose(
  withAuthorization(['currentBusinessDetails', 'update']),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(YearlyLimitsComponent);
