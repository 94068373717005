// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { PlaceholderComponent, TableComponent } from 'app/shared/index';

import type { Props } from './activation-cards-table.placeholder.types';

export class ActivationCardsTablePlaceholder extends Component<Props> {
  render() {
    return (
      <TableComponent className="table-striped">
        <thead>
          <tr>
            <FormattedMessage id="CARDS.STATUS" tagName="th" />
            <FormattedMessage id="CARDS.NUMBER" tagName="th" />
            <FormattedMessage id="CARDS.VALID_THRU" tagName="th" />
          </tr>
        </thead>
        <tbody>
          {this.props.list.map((_, card) => (
            <tr key={card}>
              <td>
                <PlaceholderComponent width={16} height={16} radius="50%" />
              </td>
              <td>
                <PlaceholderComponent width={card % 2 === 0 ? 250 : 250} />
              </td>
              <td>
                <PlaceholderComponent width={16} height={16} radius="50%" />
              </td>
            </tr>
          ))}
        </tbody>
      </TableComponent>
    );
  }
}
