// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent, InfoComponent } from 'app/shared';

export class SubscriptionSubscribeSuccessComponent extends Component<any> {
  render() {
    return (
      <div className="card-body">
        <InfoComponent type="success">
          <FormattedMessage id="SUBSCRIPTION.SUBSCRIBE.SUCCESS" />{' '}
          <FormattedMessage id="SUBSCRIPTION.SUBSCRIBE.SUCCESS_DESC" />
        </InfoComponent>

        <ButtonComponent theme="outline-secondary" to="/" size="large">
          <i className="fi fi-go-back-left-arrow" /> <FormattedMessage id="REGISTER.BACK_TO_HOMEPAGE" />
        </ButtonComponent>
      </div>
    );
  }
}
