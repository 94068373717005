// @flow

import React, { Component } from 'react';

import {
  SidebarGroupComponent,
  SidebarItemContainer,
  SidebarTitleComponent,
  SidebarNestedContainer,
} from 'app/core/sidebar/shared';
import { checkPermissions } from 'app/utils';

import type { Props } from './sidebar-management.component.types';

export class SidebarManagementComponent extends Component<Props> {
  render() {
    if (
      !checkPermissions(
        this.props.permissions,
        ['companyAccounts', 'read'],
        ['companyBeneficiaries', 'read'],
        ['companyOrders', 'read'],
        ['subscriptionReports', 'read'],
        ['contracts', 'read'],
      )
    ) {
      return null;
    }

    return (
      <>
        <SidebarTitleComponent>{this.props.intl.formatMessage({ id: 'NAV.MANAGEMENT.TITLE' })}</SidebarTitleComponent>
        <SidebarGroupComponent>
          <SidebarNestedContainer
            icon="handshake"
            name={this.props.intl.formatMessage({ id: 'NAV.MANAGEMENT.CONTRACTS' })}
            links={[
              {
                icon: 'handshake',
                name: this.props.intl.formatMessage({ id: 'NAV.MANAGEMENT.CONTRACT_LIST' }),
                to: '/contracts',
                permissions: ['contracts', 'read'],
              },
              {
                icon: 'handshake',
                name: this.props.intl.formatMessage({ id: 'NAV.MANAGEMENT.NEW_CONTRACT' }),
                to: '/contracts/new-contract',
                permissions: ['contracts', 'create'],
              },
            ]}
          />

          {checkPermissions(this.props.permissions, ['companyAccounts', 'read']) && (
            <SidebarItemContainer
              icon="account-balance"
              name={this.props.intl.formatMessage({ id: 'NAV.MANAGEMENT.CUSTOMER_ACCOUNTS' })}
              to="/accounts/company"
            />
          )}

          {checkPermissions(this.props.permissions, ['companyOrders', 'read']) && (
            <SidebarItemContainer
              icon="history"
              name={this.props.intl.formatMessage({ id: 'NAV.MANAGEMENT.ORDER_HISTORY' })}
              to="/orders"
            />
          )}

          {checkPermissions(this.props.permissions, ['companyBeneficiaries', 'read']) && (
            <SidebarItemContainer
              icon="multiple-users-silhouette"
              name={this.props.intl.formatMessage({ id: 'NAV.MANAGEMENT.COMPANY_BENEFICIARY_SEARCH' })}
              to="/beneficiaries"
            />
          )}

          {checkPermissions(this.props.permissions, ['subscriptionReports', 'read']) && (
            <SidebarItemContainer
              icon="graphic"
              name={this.props.intl.formatMessage({ id: 'NAV.MANAGEMENT.SALDO_REPORTS' })}
              to="/reports"
            />
          )}
        </SidebarGroupComponent>
      </>
    );
  }
}
