// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import uniqueId from 'lodash/uniqueId';

import { TooltipComponent } from 'app/shared';
import styles from 'app/shared/form/connected-checkbox/connected-checkbox.module.scss';

import type { Props } from './checkbox.component.types';

export class CheckboxComponent extends Component<Props> {
  render() {
    const labelClassNames = classNames(styles.label, {
      [styles.checked]: this.props.checked,
      [styles.disabled]: this.props.disabled,
      [styles.invalid]: this.props.invalid,

      [styles[this.props.theme]]: !!this.props.theme,
    });

    const id = uniqueId('checkbox_');

    const item = (
      <input
        id={id}
        type="checkbox"
        className={styles.input}
        disabled={this.props.disabled}
        onChange={this.props.onChange}
      />
    );

    if (this.props.theme) {
      return (
        <label className={labelClassNames}>
          {item}
          <span className={styles.blank}>{this.props.checked && <i className="fi fi-correct-symbol" />}</span>

          <span className={styles.title}>
            {this.props.translate && <FormattedMessage id={this.props.option.name} />}
            {this.props.translate || this.props.option.name}

            {this.props.infoText && (
              <span className={styles.infoWrapper}>
                <TooltipComponent overlay={this.props.infoText} placement="top">
                  <span className={styles.info} />
                </TooltipComponent>
              </span>
            )}
          </span>

          {!!this.props.option.description && (
            <p className={styles.description}>
              <FormattedMessage id={this.props.option.description} />
            </p>
          )}
        </label>
      );
    }

    return (
      <div className={labelClassNames}>
        {item}
        <label htmlFor={id} className={styles.blank}>
          {this.props.checked && <i className="fi fi-correct-symbol" />}
        </label>
        <label htmlFor={id} className={styles.title}>
          {this.props.translate && <FormattedMessage id={this.props.option.name} />}
          {this.props.translate || this.props.option.name}
        </label>
      </div>
    );
  }
}
