// @flow

import { RSAA } from 'redux-api-middleware';
import { createAction } from 'redux-actions';
import type { Filters } from 'app/transactions/transactions.component.types';
import * as types from './transactions.action-types';

export const fetchTransactions = (type: 'company' | 'municipal' | 'visa', params?: Object) => ({
  [RSAA]: {
    types: [
      {
        type: types.FETCH_TRANSACTIONS_REQUEST,
        meta: {
          params,
          type,
          shouldReset: !params || !params.offset,
        },
      },
      {
        type: types.FETCH_TRANSACTIONS_SUCCESS,
        meta: {
          type,
          shouldMerge: params && params.offset,
        },
      },
      {
        type: types.FETCH_TRANSACTIONS_FAILURE,
        meta: {
          type,
        },
      },
    ],
    endpoint: `/transactions/${type}`,
    method: 'GET',
  },
});

export const fetchTransaction = (account: number | string, transaction: number | string) => ({
  [RSAA]: {
    types: [
      {
        type: types.FETCH_TRANSACTION_REQUEST,
        meta: {
          transaction,
        },
      },
      {
        type: types.FETCH_TRANSACTION_SUCCESS,
        meta: {
          transaction,
        },
      },
      types.FETCH_TRANSACTION_FAILURE,
    ],
    endpoint: `/transactions/account/${account}/${transaction}`,
    method: 'GET',
  },
});

export const fetchLatestTransactions = () => ({
  [RSAA]: {
    types: [
      types.FETCH_LATEST_TRANSACTIONS_REQUEST,
      types.FETCH_LATEST_TRANSACTIONS_SUCCESS,
      types.FETCH_LATEST_TRANSACTIONS_FAILURE,
    ],
    endpoint: '/latest-transactions',
    method: 'GET',
  },
});

export const refundTransaction = (account: number | string, transaction: number | string) => ({
  [RSAA]: {
    types: [
      {
        type: types.REFUND_TRANSACTION_REQUEST,
        meta: {
          transaction,
        },
      },
      {
        type: types.REFUND_TRANSACTION_SUCCESS,
        meta: {
          transaction,
        },
      },
      {
        type: types.REFUND_TRANSACTION_FAILURE,
        meta: {
          transaction,
        },
      },
    ],
    endpoint: `/transactions/account/${account}/${transaction}/refund`,
    method: 'POST',
  },
});

export const generateTransactionsReport = (
  type: 'company' | 'visa',
  id: number,
  filters: Filters,
  newRequest: boolean,
) => ({
  [RSAA]: {
    types: [
      types.GENERATE_TRANSACTIONS_REPORT_REQUEST,
      types.GENERATE_TRANSACTIONS_REPORT_SUCCESS,
      types.GENERATE_TRANSACTIONS_REPORT_FAILURE,
    ],
    endpoint: `/generate/transactions/${type}/${id}`,
    method: 'POST',
    body: {
      filters,
      newRequest,
    },
  },
});

export const setCancelledGenerateStatus = createAction(types.SET_CANCELLED_GENERATE_STATUS);
