// @flow

import React, { Component } from 'react';

import { ModalComponent, PlaceholderComponent } from 'app/shared';

import type { Props } from './transaction-details.placeholder.types';

export class TransactionDetailsPlaceholder extends Component<Props> {
  render() {
    return (
      <ModalComponent onClose={this.props.onClose}>
        <div className={this.props.styles.header}>
          <PlaceholderComponent width={200} height={32} color="purple" />
        </div>
        <div className={this.props.styles.date}>
          <PlaceholderComponent width={100} height={18} />
        </div>
        <p>
          <PlaceholderComponent width={200} height={19} />
        </p>
        <p>
          <PlaceholderComponent width={300} height={19} />
        </p>
        <p>
          <PlaceholderComponent width={200} height={19} />
        </p>
        <p>
          <PlaceholderComponent width={400} height={19} />
        </p>

        <h4 className={this.props.styles.amount}>
          <PlaceholderComponent width={100} height={24} />
        </h4>
      </ModalComponent>
    );
  }
}
