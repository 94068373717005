// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { currencyFormatter } from 'app/utils';

import { AccountTransactionsInformationPlaceholder } from './placeholder/account-transactions-information.placeholder';

import { SummaryFieldComponent } from 'app/shared';

import type { Props } from './account-transactions-information.component.types';

export class AccountTransactionsInformationComponent extends Component<Props> {
  render() {
    if (this.props.loading) return <AccountTransactionsInformationPlaceholder />;

    if (!Object.keys(this.props.account).length) {
      return null;
    }

    return (
      <div className="row">
        <div className="col-sm-6">
          <SummaryFieldComponent equalFormHeights>
            <FormattedMessage id="TRANSACTIONS.TYPE" tagName="strong" />
            <FormattedMessage id={`PRODUCT.${this.props.account.item}`} />
          </SummaryFieldComponent>
        </div>
        <div className="col-sm-6">
          <SummaryFieldComponent equalFormHeights>
            <FormattedMessage id="TRANSACTIONS.ACCOUNT_NUMBER" tagName="strong" />
            {this.props.account.accountNumber}
          </SummaryFieldComponent>
        </div>
        {!!this.props.account.reservation && (
          <div className="col-sm-6">
            <SummaryFieldComponent equalFormHeights>
              <FormattedMessage id="TRANSACTIONS.RESERVATION" tagName="strong" />
              {currencyFormatter.format(this.props.account.reservation)}
            </SummaryFieldComponent>
          </div>
        )}
        <div className="col-sm-6">
          <SummaryFieldComponent equalFormHeights>
            <FormattedMessage id="TRANSACTIONS.BALANCE" tagName="strong" />
            {currencyFormatter.format(this.props.account.balance)}
          </SummaryFieldComponent>
        </div>
      </div>
    );
  }
}
