export const FETCH_CARDS_REQUEST = 'cards/FETCH_CARDS_REQUEST';
export const FETCH_CARDS_SUCCESS = 'cards/FETCH_CARDS_SUCCESS';
export const FETCH_CARDS_FAILURE = 'cards/FETCH_CARDS_FAILURE';

export const CHANGE_CARD_STATUS_REQUEST = 'cards/CHANGE_CARD_STATUS_REQUEST';
export const CHANGE_CARD_STATUS_SUCCESS = 'cards/CHANGE_CARD_STATUS_SUCCESS';
export const CHANGE_CARD_STATUS_FAILURE = 'cards/CHANGE_CARD_STATUS_FAILURE';

export const FETCH_ACTIVATION_CARDS_REQUEST = 'cards/FETCH_ACTIVATION_CARDS_REQUEST';
export const FETCH_ACTIVATION_CARDS_SUCCESS = 'cards/FETCH_ACTIVATION_CARDS_SUCCESS';
export const FETCH_ACTIVATION_CARDS_FAILURE = 'cards/FETCH_ACTIVATION_CARDS_FAILURE';
