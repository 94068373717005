import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Field } from 'redux-form';

import { ButtonComponent, ConnectedInputComponent } from 'app/shared';

import type { Props } from './add-beneficiary-filters.component.types';
import styles from './add-beneficiary-filters.module.scss';

export class AddBeneficiaryFiltersComponent extends Component<Props> {
  render() {
    const rowClassNames = classNames('row', styles.row);

    const columnClassNames = classNames('col-12', 'col-md-6');

    return (
      <>
        <div className={classNames('card-header', styles.searchHeader)}>
          <div className="d-flex align-items-center justify-content-xl-between">
            <FormattedMessage id="BENEFICIARIES.SEARCH" tagName="h5" />
          </div>
        </div>
        <div className={classNames('card-body', styles.searchForm)}>
          <form onSubmit={this.props.handleSubmit}>
            <div className={rowClassNames}>
              <div className={columnClassNames}>
                <Field
                  name="name"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'BENEFICIARIES.LIST_HEADERS.NAME' })}
                />
              </div>
              <div className={columnClassNames}>
                <Field
                  name="ssn"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'BENEFICIARIES.LIST_HEADERS.SSN' })}
                />
              </div>
              <div className={columnClassNames}>
                <Field
                  name="email"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'BENEFICIARIES.LIST_HEADERS.EMAIL' })}
                />
              </div>
              <div className={columnClassNames}>
                <ButtonComponent
                  theme="outline-secondary"
                  size="medium"
                  className={styles.resetButton}
                  onClick={this.props.onClear}
                >
                  <FormattedMessage id="CORE.CLEAR_FILTERS" />
                </ButtonComponent>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}
