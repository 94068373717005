// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { PlaceholderComponent, TableComponent } from 'app/shared';

import styles from '../../table/reports-list-table.module.scss';

type Props = {
  list: null[],
};

export class ReportsListTablePlaceholder extends Component<Props> {
  render() {
    return (
      <TableComponent className="table-striped">
        <thead>
          <tr>
            <FormattedMessage id="SALDO_REPORTS.SUBSCRIPTION_LIST" tagName="th" />
            <FormattedMessage id="SALDO_REPORTS.BENEFIT_OPTIONS" tagName="th" />
            <FormattedMessage id="SALDO_REPORTS.BENEFICIARIES" tagName="th" />
            <th className="text-right">
              <FormattedMessage id="SALDO_REPORTS.DEPOSIT_AMOUNT" />
            </th>
            <th className="text-right">
              <FormattedMessage id="SALDO_REPORTS.BALANCE" />
            </th>
            <th className="text-right">
              <FormattedMessage id="SALDO_REPORTS.USAGE_REPORT" />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.props.list.map((_, index) => (
            <React.Fragment key={index}>
              <tr className={styles.mainRow}>
                <td colSpan={2}>
                  <PlaceholderComponent width={120} height={16} />
                </td>
                <td colSpan={3}>
                  <PlaceholderComponent width={80} height={16} />
                </td>
                <td className="text-right">
                  <PlaceholderComponent width={150} height={32} color="purple" radius={0} />
                </td>
              </tr>
              <tr className={styles.childRow}>
                <td>
                  <PlaceholderComponent width={140} height={16} />
                </td>
                <td colSpan={2}>
                  <PlaceholderComponent width={120} height={16} />
                </td>
                <td className="text-right">
                  <PlaceholderComponent width={40} height={16} />
                </td>
                <td className="text-right">
                  <PlaceholderComponent width={40} height={16} />
                </td>
                <td />
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </TableComponent>
    );
  }
}
