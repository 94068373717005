// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import { Route, Switch, Redirect } from 'react-router-dom';

import { AsideComponent } from 'app/core';
import { LanguageSwitcherContainer } from 'app/auth/language-switcher/language-switcher.container';

import { LoginContainer } from 'app/auth/login/login.container';
import { LoginTokenContainer } from 'app/auth/login-token/login-token.container';
import { LoginWithoutPasswordContainer } from 'app/auth/login-without-password/login-without-password.container';
import { NewPasswordContainer } from 'app/auth/new-password/new-password.container';
import { RequestPasswordContainer } from 'app/auth/request-password/request-password.container';

import styles from './auth.module.scss';

export class AuthComponent extends Component<any> {
  render() {
    const authColClassNames = classNames('col-12 col-lg', styles.column);

    return (
      <div className="container-fluid">
        <div className="row">
          <AsideComponent />
          <div className={authColClassNames}>
            <div className={styles.section}>
              <div className={styles.container}>
                <Switch>
                  <Route path="/login" component={LoginContainer} />
                  <Route path="/login-without-password/:token" component={LoginTokenContainer} />
                  <Route path="/login-without-password" component={LoginWithoutPasswordContainer} />
                  <Route path="/request-password" component={RequestPasswordContainer} />
                  <Route path="/new-password/:passwordToken" component={NewPasswordContainer} />
                  <Redirect to="/login" />
                </Switch>
              </div>
              <footer className={styles.footer}>
                <LanguageSwitcherContainer />
              </footer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
