import { connect } from 'react-redux';

import { LoginTokenComponent } from './login-token.component';

import { loginWithToken } from 'app/redux/users/users.actions';

const mapDispatchToProps = dispatch => ({
  loginWithToken: token => dispatch(loginWithToken(token)),
});

export const LoginTokenContainer = connect(
  null,
  mapDispatchToProps,
)(LoginTokenComponent);
