import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import { withInvoicingOptions } from 'app/orders/with-invoicing-options/with-invoicing-options.hoc';

import { OrdersCreateInvoicingComponent } from './orders-create-invoicing.component';

export const OrdersCreateInvoicingContainer = compose(
  injectIntl,
  withInvoicingOptions,
)(OrdersCreateInvoicingComponent);
