import { RSAA } from 'redux-api-middleware';

import * as types from './orders.action-types';

export const createOrder = (type, body) => ({
  [RSAA]: {
    types: [types.CREATE_ORDER_REQUEST, types.CREATE_ORDER_SUCCESS, types.CREATE_ORDER_FAILURE],
    endpoint: `/orders/${type}`,
    method: 'POST',
    body,
  },
});

export const confirmOrder = (id, context, lang) => ({
  [RSAA]: {
    types: [
      {
        type: types.CONFIRM_ORDER_REQUEST,
        meta: {
          params: {
            context,
            lang,
          },
        },
      },
      types.CONFIRM_ORDER_SUCCESS,
      types.CONFIRM_ORDER_FAILURE,
    ],
    endpoint: `/orders/${id}/confirm`,
    method: 'POST',
  },
});

export const cancelOrder = id => ({
  [RSAA]: {
    types: [
      {
        type: types.CANCEL_ORDER_REQUEST,
        meta: { order: Number(id) },
      },
      {
        type: types.CANCEL_ORDER_SUCCESS,
        meta: { order: Number(id) },
      },
      {
        type: types.CANCEL_ORDER_FAILURE,
        meta: { order: Number(id) },
      },
    ],
    endpoint: `/orders/${id}`,
    method: 'DELETE',
  },
});

export const createBeneficiaryCardOrder = (beneficiary, body) => ({
  [RSAA]: {
    types: [
      types.CREATE_BENEFICIARY_CARD_ORDER_REQUEST,
      types.CREATE_BENEFICIARY_CARD_ORDER_SUCCESS,
      types.CREATE_BENEFICIARY_CARD_ORDER_FAILURE,
    ],
    endpoint: `/orders/beneficiary-card/${beneficiary}`,
    method: 'POST',
    body,
  },
});

export const validateBeneficiaryCardOrder = (beneficiary, body) => ({
  [RSAA]: {
    types: [
      types.VALIDATE_BENEFICIARY_CARD_ORDER_REQUEST,
      types.VALIDATE_BENEFICIARY_CARD_ORDER_SUCCESS,
      types.VALIDATE_BENEFICIARY_CARD_ORDER_FAILURE,
    ],
    endpoint: `/orders/beneficiary-card/${beneficiary}/validate`,
    method: 'POST',
    body,
  },
});

export const createConcreteOrder = (id, type = 'subscription') => ({
  [RSAA]: {
    types: [
      types.CREATE_SPECIFIC_ORDER_REQUEST,
      types.CREATE_SPECIFIC_ORDER_SUCCESS,
      types.CREATE_SPECIFIC_ORDER_FAILURE,
    ],
    endpoint: `/orders/${type}/${id}`,
    method: type === 'cards' ? 'POST' : 'PUT',
  },
});

export const createInvoice = (id, body) => ({
  [RSAA]: {
    types: [types.CREATE_ORDER_INVOICE_REQUEST, types.CREATE_ORDER_INVOICE_SUCCESS, types.CREATE_ORDER_INVOICE_FAILURE],
    endpoint: `/orders/${id}/invoice`,
    method: 'POST',
    body: {
      ...body,
      invoicing:
        body.invoicing.invoicingMethod === 'EINVOICE'
          ? {
              eInvoiceAddress: body.invoicing.eInvoiceAddress,
              eInvoiceIdentifier: body.invoicing.eInvoiceIdentifier,
              invoiceEmail: body.invoicing.invoiceEmail,
              invoicingMethod: body.invoicing.invoicingMethod,
              invoiceReference: body.invoicing.invoiceReference,
            }
          : {
              invoiceEmail: body.invoicing.invoiceEmail,
              invoicingMethod: body.invoicing.invoicingMethod,
              invoiceReference: body.invoicing.invoiceReference,
            },
    },
  },
});

export const fetchOrders = params => ({
  [RSAA]: {
    types: [
      {
        type: types.FETCH_ORDERS_REQUEST,
        meta: { params },
      },
      types.FETCH_ORDERS_SUCCESS,
      types.FETCH_ORDERS_FAILURE,
    ],
    endpoint: '/orders',
    method: 'GET',
  },
});

export const fetchOrder = order => ({
  [RSAA]: {
    types: [
      {
        type: types.FETCH_ORDER_REQUEST,
        meta: { order },
      },
      {
        type: types.FETCH_ORDER_SUCCESS,
        meta: { order },
      },
      types.FETCH_ORDER_FAILURE,
    ],
    endpoint: `/orders/${order}`,
    method: 'GET',
  },
});

export const createBanknotesOrder = (body, lang) => ({
  [RSAA]: {
    types: [
      {
        type: types.CREATE_BANKNOTES_ORDER_REQUEST,
        meta: {
          params: {
            lang,
          },
        },
      },
      types.CREATE_BANKNOTES_ORDER_SUCCESS,
      types.CREATE_BANKNOTES_ORDER_FAILURE,
    ],
    endpoint: '/orders/banknotes',
    method: 'POST',
    body,
  },
});

export const validateBanknotesOrder = body => ({
  [RSAA]: {
    types: [
      types.VALIDATE_BANKNOTES_ORDER_REQUEST,
      types.VALIDATE_BANKNOTES_ORDER_SUCCESS,
      types.VALIDATE_BANKNOTES_ORDER_FAILURE,
    ],
    endpoint: '/orders/banknotes/validate',
    method: 'POST',
    body,
  },
});
