// @flow

import React, { Component } from 'react';
import styles from './loader-fixed.module.scss';

export class LoaderFixedComponent extends Component<{}> {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.spinner} />
      </div>
    );
  }
}
