// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { ButtonComponent, TableComponent, TooltipComponent } from 'app/shared';
import { checkPermissions } from 'app/utils';
// $FlowFixMe
import { ReactComponent as CommuterIcon } from 'assets/icons/commuter.svg';
// $FlowFixMe
import { ReactComponent as CultureIcon } from 'assets/icons/culture.svg';
// $FlowFixMe
import { ReactComponent as ExerciseIcon } from 'assets/icons/exercise.svg';
// $FlowFixMe
import { ReactComponent as LunchIcon } from 'assets/icons/lunch.svg';
// $FlowFixMe
import { ReactComponent as MassageIcon } from 'assets/icons/massage.svg';

import type { Props } from './venues-list-table.component.types';
import styles from './venues-list-table.module.scss';

export class VenuesListTableComponent extends PureComponent<Props> {
  render() {
    const serviceTypeIcon = benefit => {
      const styles = { position: 'absolute', marginLeft: 10 };

      // TooltipComponent needs to have 1 child node
      if (!['commuter', 'culture', 'exercise', 'lunch', 'massage'].includes(benefit)) return <div />;

      return {
        commuter: <CommuterIcon style={styles} />,
        culture: <CultureIcon style={styles} />,
        exercise: <ExerciseIcon style={styles} />,
        lunch: <LunchIcon style={styles} />,
        massage: <MassageIcon style={styles} />,
      }[benefit];
    };

    const getMouseoverTranslation = benefit => {
      if (benefit) {
        return benefit.toUpperCase();
      }
      return '';
    };

    return (
      <>
        <TableComponent className="table-striped">
          <thead>
            <tr>
              <FormattedMessage id="VENUES.NAME" tagName="th" />
              <FormattedMessage id="VENUES.CITY" tagName="th" />
              <FormattedMessage id="VENUES.SERVICE_TYPE" tagName="th" />
              <th />
            </tr>
          </thead>
          <tbody>
            {this.props.list.map(venue => (
              <tr key={venue.id} className={classNames({ [styles.pending]: venue.pending })}>
                <td>
                  <strong>{venue.name}</strong>
                </td>
                <td>{venue.city}</td>
                <td>
                  <TooltipComponent
                    trigger={['hover']}
                    overlay={this.props.intl.formatMessage({
                      id: `VENUES.SERVICE_TYPE_POPOVER.${getMouseoverTranslation(venue.benefit)}`,
                    })}
                    placement={'top'}
                  >
                    {serviceTypeIcon(venue.benefit)}
                  </TooltipComponent>
                </td>
                {venue.pending && (
                  <td className="text-right">
                    <FormattedMessage id="VENUES.PENDING" />
                  </td>
                )}
                {venue.pending || (
                  <td className={styles.icons}>
                    {checkPermissions(this.props.permissions, ['venueUsers', 'read']) && (
                      <ButtonComponent theme="outline-secondary" to={`/venues/${venue.id}/users`} size="small">
                        <FormattedMessage id="VENUES.USERS" /> <i className="fi fi-man-user" />
                      </ButtonComponent>
                    )}

                    {checkPermissions(this.props.permissions, ['venues', 'update']) && (
                      <ButtonComponent theme="outline-secondary" to={`/venues/${venue.id}/edit`} size="small">
                        <i className="fi fi-pencil-edit-button" />
                      </ButtonComponent>
                    )}

                    {checkPermissions(this.props.permissions, ['venues', 'update']) && (
                      <TooltipComponent
                        trigger={['hover']}
                        overlay={this.props.intl.formatMessage({ id: 'VENUES.DELETE_VENUE_BUTTON_TOOLTIP' })}
                        placement={'top'}
                      >
                        <div>
                          <ButtonComponent
                            theme="outline-secondary"
                            onClick={() =>
                              this.props.onOpenTermiationModal({
                                venueId: venue.id,
                                contractTerminationDate: venue.contractTerminationDate,
                              })
                            }
                            size="small"
                            disabled={!!venue.contractTerminated || !!venue.contractTerminationDate}
                          >
                            <i className="fi fi-cross-remove-sign" />
                          </ButtonComponent>
                        </div>
                      </TooltipComponent>
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </TableComponent>
      </>
    );
  }
}
