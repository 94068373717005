import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { replace } from 'react-router-redux';
import { get } from 'lodash';

import { CardsBeneficiariesOrderComponent } from './cards-beneficiaries-order.component';

import { withLocale } from 'app/app.context';
import { withAuthorization } from 'app/common';

import { fetchBeneficiary } from 'app/redux/beneficiaries/beneficiaries.actions';
import { fetchCustomer } from 'app/redux/customers/customers.actions';
import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';
import {
  createBeneficiaryCardOrder,
  validateBeneficiaryCardOrder,
  confirmOrder,
} from 'app/redux/orders/orders.actions';

const mapStateToProps = (
  {
    beneficiaries: { entities: beneficiaries },
    customers: { entities: customers },
    dictionaries,
    users: { business, current },
  },
  {
    match: {
      params: { beneficiary: beneficiaryId },
    },
  },
) => {
  const beneficiary = get(beneficiaries, [beneficiaryId, 'personal']);

  return {
    beneficiary,
    business,
    loading:
      !beneficiary || !customers[business] || !dictionaries['products/card'] || !dictionaries['invoicing-options'],
    initialValues:
      customers[business] && beneficiary
        ? {
            invoicing: {
              invoicingMethod: 'EMAIL',
              invoiceName: customers[business].name,
              invoiceEmail: current.personal.email,
              invoiceReference: customers[business].invoicing.defaultInvoicingReference,
              eInvoiceAddress: customers[business].invoicing.eInvoiceAddress,
              eInvoiceIdentifier: customers[business].invoicing.eInvoiceDeliveryIdentifier,
            },
            invoicingAddress: {
              beneficiary: `${beneficiary.firstName}  ${beneficiary.lastName}`,
              ...beneficiary.deliveryAddress,
            },
          }
        : undefined,
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { beneficiary },
    },
    locale,
  },
) => ({
  fetchBeneficiary: () => dispatch(fetchBeneficiary(beneficiary)),
  fetchCustomer: customer => dispatch(fetchCustomer(customer)),
  fetchDictionaries: () =>
    Promise.all([dispatch(fetchDictionary('products/card')), dispatch(fetchDictionary('invoicing-options'))]),
  onSubmit: body =>
    dispatch(createBeneficiaryCardOrder(beneficiary, body)).then(response =>
      dispatch(confirmOrder(response.payload.data.id, 'cards', locale)).then(() =>
        dispatch(
          replace({
            pathname: `/beneficiaries/${beneficiary}`,
            state: {
              success: {
                type: 'CARDS.ORDER.SUCCESS',
              },
            },
          }),
        ),
      ),
    ),
  onValidate: body => dispatch(validateBeneficiaryCardOrder(beneficiary, body)),
});

export const CardsBeneficiariesOrderContainer = compose(
  withLocale,
  withAuthorization(['beneficiaryCardsOrder', 'create']),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'order-beneficiary-card',
  }),
)(CardsBeneficiariesOrderComponent);
