// @flow

import React, { Component } from 'react';
import { PieChart } from 'react-chartkick';

import type { Props, ChartItem } from './pie-chart.component.types';

export class PieChartComponent extends Component<Props> {
  parseItemDefault = ({ label, value }: ChartItem) => [label, Number(value)];

  parsedDataset = this.props.data.map(this.props.parseItem ? this.props.parseItem : this.parseItemDefault);

  font = { family: '"Work sans", sans-serif', color: '#14191a' };

  datasetOptions = { borderColor: '#fff', hoverBorderColor: '#fff' };

  chartOptions = {
    chartArea: { top: 10 },
    fontName: this.font.family,
    titleTextStyle: {
      color: this.font.color,
      fontSize: 19,
      bold: false,
    },
    titlePosition: 'none',
    legend: {
      alignment: 'center',
      position: this.props.legendPosition,
    },
  };

  render() {
    return (
      <>
        <h5 className="text-center">{this.props.title}</h5>
        <PieChart
          {...this.props}
          dataset={this.datasetOptions}
          library={this.chartOptions}
          data={this.parsedDataset}
          adapter="google"
        />
      </>
    );
  }
}
