import { RSAA } from 'redux-api-middleware';
import * as types from './payments.action-types';

export const createMobilePayment = body => ({
  [RSAA]: {
    types: [
      types.CREATE_MOBILE_PAYMENT_REQUEST,
      types.CREATE_MOBILE_PAYMENT_SUCCESS,
      types.CREATE_MOBILE_PAYMENT_FAILURE,
    ],
    endpoint: '/payments/mobile',
    method: 'POST',
    body,
  },
});

export const validateMobilePayment = body => ({
  [RSAA]: {
    types: [
      types.VALIDATE_MOBILE_PAYMENT_REQUEST,
      types.VALIDATE_MOBILE_PAYMENT_SUCCESS,
      types.VALIDATE_MOBILE_PAYMENT_FAILURE,
    ],
    endpoint: '/payments/mobile/validate',
    method: 'POST',
    body,
  },
});

export const fetchPaymentsAccounts = () => ({
  [RSAA]: {
    types: [
      types.FETCH_PAYMENT_ACCOUNT_REQUEST,
      types.FETCH_PAYMENT_ACCOUNT_SUCCESS,
      types.FETCH_PAYMENT_ACCOUNT_FAILURE,
    ],
    endpoint: '/payments/accounts',
    method: 'GET',
  },
});

export const fetchPaymentToken = accountNumber => ({
  [RSAA]: {
    types: [types.FETCH_PAYMENT_TOKEN_REQUEST, types.FETCH_PAYMENT_TOKEN_SUCCESS, types.FETCH_PAYMENT_TOKEN_FAILURE],
    endpoint: `/payments/accounts/token/${accountNumber}`,
    method: 'GET',
  },
});
