// @flow

import React, { Component } from 'react';
import ClipboardButton from 'react-clipboard.js';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { InputComponent, TooltipComponent } from 'app/shared';

import type { Props } from './subscription-share.component.types';
import styles from './subscription-share.module.scss';

export class SubscriptionShareComponent extends Component<Props> {
  input: ?HTMLInputElement;

  copyButtonRenderer = () =>
    this.props.loading || (
      <TooltipComponent overlay={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.SHARE.COPIED' })} placement="top">
        <ClipboardButton
          data-clipboard-text={`${window.location.origin}/subscribe/${this.props.subscription.hash}`}
          className={classNames('fi fi-link', styles.icon)}
          component="i"
        >
          <span className={styles['button-text']}>
            <FormattedMessage id="CORE.COPY" />
          </span>
        </ClipboardButton>
      </TooltipComponent>
    );

  getLink = () => this.props.loading || `${window.location.origin}/subscribe/${this.props.subscription.hash}`;

  render() {
    return (
      <InputComponent
        inputRef={c => (this.input = c)}
        onClick={() => this.input && this.input.setSelectionRange(0, this.input.value.length)}
        label={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.SHARE.COPY' })}
        button={this.copyButtonRenderer()}
        input={{ value: this.getLink() }}
        className={styles.input}
        readOnly
      />
    );
  }
}
