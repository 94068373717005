import { handleActions } from 'redux-actions';

import * as types from './reports.action-types';

const defaultState = {
  entities: {},
};

export const reportsReducer = handleActions(
  {
    [types.FETCH_REPORTS_REQUEST]: state => ({
      ...state,
      list: undefined,
    }),
    [types.FETCH_REPORTS_SUCCESS]: (state, { payload: { data: list, meta } }) => ({
      ...state,
      list,
      meta,
    }),
    [types.FETCH_REPORT_REQUEST]: (state, { meta: { report } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [report]: undefined,
      },
    }),
    [types.FETCH_REPORT_SUCCESS]: (state, { meta: { report }, payload: { data: entity } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [report]: entity,
      },
    }),
  },
  defaultState,
);
