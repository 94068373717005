import React from 'react';
import type { Props } from './venue-select.component.types';
import { FormattedMessage } from 'react-intl';
import { SelectComponent, ButtonComponent, TooltipComponent, LoaderFixedComponent } from 'app/shared';
import styles from './venue-select.module.scss';
// $FlowFixMe
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { useConvertVenueId } from 'app/common';

const VenueSelect = ({ convertIdTo, fetchVenues, loading: loadingVenues, venues, setError }: Props) => {
  const [selectedVenue, setSelectedVenue] = React.useState({ id: 0 });
  const {
    isLoading: isLoadingConvertVenueId,
    selectedVenue: selectedVenueIds,
    onVenueChange: onVenue,
    copyVenueConvertedIdToClipboard,
  } = useConvertVenueId(convertIdTo, {
    setError,
  });

  React.useEffect(() => {
    fetchVenues();
  }, []);

  React.useEffect(() => {
    if (venues && venues[0].id) {
      const firstVenueWithSmsPayment = venues.find(venue => venue.acceptsSmsPayments);

      // prioritize venue with sms payment enabled
      // use first index as backup
      if (firstVenueWithSmsPayment) {
        onVenue(firstVenueWithSmsPayment.id);
        setSelectedVenue(firstVenueWithSmsPayment);
      } else {
        setSelectedVenue(venues[0]);
      }
    }
  }, [venues]);

  const onVenueChange = (id: string) => {
    const venue = venues.find(venue => venue.id === parseInt(id, 10));

    if (venue.acceptsSmsPayments) {
      onVenue(id);
    }

    setSelectedVenue(venue);
  };

  if (loadingVenues) {
    return <LoaderFixedComponent />;
  }

  const optionList = venues.map(v => ({ id: v.id, name: v.name }));

  const tooltipOverlay = (
    <div style={{ textAlign: 'center' }}>
      <FormattedMessage id="VENUE_SELECT.SMS.COPY_SUCCESS" />
    </div>
  );

  return (
    <div className={styles.container}>
      <div className={styles['flex-1']}>
        <FormattedMessage id="VENUE_SELECT.VENUE" tagName="p" />
        <SelectComponent
          options={optionList}
          onChange={onVenueChange}
          selectedValue={selectedVenue.id}
          searchable={true}
        />
      </div>

      <div className={styles['flex-1']}>
        <FormattedMessage id="VENUE_SELECT.SMS.VENUE_ID" tagName="p" />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={styles['flex-1']}>
            {!selectedVenue.acceptsSmsPayments && (
              <strong>
                <FormattedMessage id="VENUE_SELECT.SMS.NOT_ENABLED" tagName="p" />
              </strong>
            )}
            {selectedVenue.acceptsSmsPayments && !isLoadingConvertVenueId && (
              <strong>{selectedVenueIds.convertedId}</strong>
            )}
            {selectedVenue.acceptsSmsPayments && isLoadingConvertVenueId && <div className={styles.loader} />}
          </div>
          {selectedVenue.acceptsSmsPayments && (
            <div className={styles['flex-1']}>
              <TooltipComponent placement="top" overlay={tooltipOverlay} trigger={['click']}>
                <ButtonComponent
                  disabled={!selectedVenueIds.convertedId}
                  theme="outline-secondary"
                  size="small"
                  onClick={copyVenueConvertedIdToClipboard}
                  className={styles.button}
                >
                  <FormattedMessage id="CORE.COPY" /> <CopyIcon />
                </ButtonComponent>
              </TooltipComponent>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VenueSelect;
