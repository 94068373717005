import { RSAA } from 'redux-api-middleware';
import { getFormValues } from 'redux-form';
import { get } from 'lodash';

import * as types from './beneficiaries.action-types';

export const fetchBeneficiary = id => ({
  [RSAA]: {
    types: [
      types.FETCH_BENEFICIARY_REQUEST,
      { type: types.FETCH_BENEFICIARY_SUCCESS, meta: { id } },
      { type: types.FETCH_BENEFICIARY_FAILURE, meta: { id } },
    ],
    endpoint: `/beneficiaries/${id}`,
    method: 'GET',
  },
});

export const fetchBeneficiaries = (params?: Object) => (dispatch, getState) => {
  const {
    users: { business },
    form,
  } = getState();
  const filters = getFormValues('beneficiaries-filters')({ form }) || {};
  return dispatch({
    [RSAA]: {
      types: [
        {
          type: types.FETCH_BENEFICIARIES_REQUEST,
          meta: {
            business,
            params: {
              ...filters,
              ...params,
            },
          },
        },
        {
          type: types.FETCH_BENEFICIARIES_SUCCESS,
          meta: { business },
        },
        types.FETCH_BENEFICIARIES_FAILURE,
      ],
      endpoint: '/beneficiaries',
      method: 'GET',
    },
  });
};

export const filterBeneficiaries = (params?: Object) => (dispatch, getState) => {
  const {
    users: { business },
  } = getState();

  return dispatch({
    [RSAA]: {
      types: [
        {
          type: types.FILTER_BENEFICIARIES_REQUEST,
          meta: {
            business,
            params: {
              size: 5000,
              ...params,
            },
          },
        },
        {
          type: types.FILTER_BENEFICIARIES_SUCCESS,
          meta: { business },
        },
        types.FILTER_BENEFICIARIES_FAILURE,
      ],
      endpoint: '/beneficiaries',
      method: 'GET',
    },
  });
};

export const fetchBeneficiaryAccounts = id => ({
  [RSAA]: {
    types: [
      types.FETCH_BENEFICIARY_ACCOUNTS_REQUEST,
      { type: types.FETCH_BENEFICIARY_ACCOUNTS_SUCCESS, meta: { id } },
      types.FETCH_BENEFICIARY_ACCOUNTS_FAILURE,
    ],
    endpoint: `/beneficiaries/${id}/accounts`,
    method: 'GET',
  },
});

export const fetchBeneficiaryCards = id => ({
  [RSAA]: {
    types: [
      types.FETCH_BENEFICIARY_CARDS_REQUEST,
      { type: types.FETCH_BENEFICIARY_CARDS_SUCCESS, meta: { id } },
      types.FETCH_BENEFICIARY_CARDS_FAILURE,
    ],
    endpoint: `/beneficiaries/${id}/cards`,
    method: 'GET',
  },
});

export const fetchBeneficiarySubscriptions = id => ({
  [RSAA]: {
    types: [
      types.FETCH_BENEFICIARY_SUBSCRIPTIONS_REQUEST,
      { type: types.FETCH_BENEFICIARY_SUBSCRIPTIONS_SUCCESS, meta: { id } },
      types.FETCH_BENEFICIARY_SUBSCRIPTIONS_FAILURE,
    ],
    endpoint: `/beneficiaries/${id}/subscriptions`,
    method: 'GET',
  },
});

export const updateAccountStatus = (beneficiary, account, status) => ({
  [RSAA]: {
    types: [
      types.UPDATE_ACCOUNT_STATUS_REQUEST,
      {
        type: types.UPDATE_ACCOUNT_STATUS_SUCCESS,
        meta: {
          account,
          beneficiary,
          active: status === 'enabled',
        },
      },
      types.UPDATE_ACCOUNT_STATUS_FAILURE,
    ],
    endpoint: `/accounts/${account}/${status}`,
    method: 'POST',
  },
});

export const changeBeneficiaryCardStatus = (id, status, beneficiary) => ({
  [RSAA]: {
    types: [
      types.CHANGE_BENEFICIARY_CARD_STATUS_REQUEST,
      {
        type: types.CHANGE_BENEFICIARY_CARD_STATUS_SUCCESS,
        meta: {
          active: status === 'enabled',
          beneficiary,
          id,
        },
      },
      types.CHANGE_BENEFICIARY_CARD_STATUS_FAILURE,
    ],
    endpoint: `/cards/${id}/${status}`,
    method: 'POST',
  },
});

export const editBeneficiarySubscription = (body, subscriptionBeneficiary, beneficiary) => ({
  [RSAA]: {
    types: [
      types.EDIT_BENEFICIARY_SUBSCRIPTION_REQUEST,
      { type: types.EDIT_BENEFICIARY_SUBSCRIPTION_SUCCESS, meta: { beneficiary, body, subscriptionBeneficiary } },
      types.EDIT_BENEFICIARY_SUBSCRIPTION_FAILURE,
    ],
    endpoint: `/subscriptions/beneficiaries/${subscriptionBeneficiary}`,
    method: 'PATCH',
    body,
  },
});

export const deleteBeneficiary = beneficiaryId => (dispatch, getState) => {
  const {
    users: { business },
    beneficiaries: { entities: beneficiaries },
  } = getState();

  const beneficiary = get(beneficiaries, [beneficiaryId, 'personal']);

  return dispatch({
    [RSAA]: {
      types: [
        types.DELETE_BENEFICIARY_REQUEST,
        {
          type: types.DELETE_BENEFICIARY_SUCCESS,
          meta: {
            name: `${beneficiary.firstName} ${beneficiary.lastName}`,
          },
        },
        types.DELETE_BENEFICIARY_FAILURE,
      ],
      endpoint: `/customers/${business}/customer/users/${beneficiaryId}/roles`,
      method: 'POST',
      body: {
        roles: [
          {
            active: false,
            role: 'ROLE_COMPANY_BENEFICIARY',
          },
        ],
      },
    },
  });
};
