import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { SubscriptionNavComponent } from './subscription-nav.component';

const mapStateToProps = (
  { subscriptions: { entities: subscriptions } },
  {
    match: {
      params: { subscription },
    },
  },
) => ({
  loading: !subscriptions[subscription],
  subscription: subscriptions[subscription],
});

export const SubscriptionNavContainer = compose(
  withRouter,
  connect(mapStateToProps),
)(SubscriptionNavComponent);
