// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { CardComponent } from 'app/shared';
import { ListComponent } from 'app/shared/list/list.component';

import { MyAccountsListPlaceholder } from 'app/accounts/my/list/placeholder/my-accounts-list.placeholder';
import { MyAccountsListTableContainer } from 'app/accounts/my/list/table/my-accounts-list-table.container';
import { MyAccountsListTileContainer } from 'app/accounts/my/list/tile/my-accounts-list-tile.container';

import type { Props } from './beneficiary-details-accounts.component.types';

export class BeneficiaryDetailsAccountsComponent extends Component<Props> {
  componentDidMount() {
    this.props.fetchBeneficiaryAccounts();
  }

  render() {
    return (
      <CardComponent>
        <div className="card-header">
          <h4 className="card-title">
            <FormattedMessage id="NAV.ACCOUNT.ACCOUNTS_INFORMATION" />
          </h4>
        </div>
        <div className="card-body">
          {this.props.loading && <MyAccountsListPlaceholder />}
          {this.props.loading || (
            <ListComponent
              list={this.props.accounts}
              table={MyAccountsListTableContainer}
              tile={MyAccountsListTileContainer}
              searchable={false}
              props={{
                onActivate: this.props.onActivate,
                onDisable: this.props.onDisable,
                pathname: this.props.location.pathname,
              }}
            />
          )}
        </div>
      </CardComponent>
    );
  }
}
