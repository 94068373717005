import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';

import { withAppContext } from 'app/app.context';

import { LanguageSwitcherComponent } from './language-switcher.component';

const mapStateToProps = (_, { intl: { locale } }) => ({
  initialValues: {
    language: locale,
  },
});

export const LanguageSwitcherContainer = compose(
  withAppContext,
  injectIntl,
  connect(mapStateToProps),
  reduxForm({ form: 'switch-language' }),
)(LanguageSwitcherComponent);
