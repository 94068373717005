import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { withPagination, withAuthorization } from 'app/common';
import { USER_ROLES } from 'app/constants';

import { fetchContracts } from 'app/redux/contracts/contracts.actions';
import { download } from 'app/redux/downloads/downloads.actions';
import { fetchVenueContracts } from 'app/redux/venues/venues.actions';

import { ContractListComponent } from './contract-list.component';

const mapStateToProps = ({
  contracts: { list: contractList, meta: contractsMeta },
  venues: { list: venueList, meta: venuesMeta },
  users: { business, role },
  permissions,
}) => {
  let contracts = contractList;
  let meta = contractsMeta;
  if (role === USER_ROLES.ROLE_VENUE_CASHIER || role === USER_ROLES.ROLE_VENUE_MANAGER) {
    contracts = venueList;
    meta = venuesMeta;
  }
  return {
    business,
    contracts,
    meta,
    loading: !contracts,
    permissions: permissions[business][role],
    role,
  };
};

const mapDispatchToProps = (dispatch, { business, intl: { locale }, role }) => ({
  fetch: params => {
    const fetchListParams = { ...params, ...(!params.size && { size: 10 }) };
    if (role === USER_ROLES.ROLE_VENUE_MANAGER) {
      return dispatch(fetchVenueContracts(fetchListParams));
    }
    return dispatch(fetchContracts(fetchListParams));
  },
  onDownload: () => dispatch(download(`customers/${business}/contracts`, locale)),
});

export const ContractListContainer = compose(
  injectIntl,
  withAuthorization(['contracts', 'read']),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
  withPagination,
)(ContractListComponent);
