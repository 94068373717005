// @flow

import React, { PureComponent } from 'react';
import classNames from 'classnames';
import MediaQuery from 'react-responsive';

import { SidebarContainer } from 'app/core/sidebar/sidebar.container';
import { MobileHeaderContainer } from 'app/core/mobile-header/mobile-header.container';
import { LanguageSwitcherContainer } from 'app/core/language-switcher/language-switcher.container';
import { FooterComponent } from 'app/core';

import type { Props } from './theme.component.types';
import styles from './theme.module.scss';
import { InfoBoxContainer } from '../info-box/info-box.container';
import { BikeBenefitBannerContainer } from '../bike-benefit-banner/bike-benefit-banner.container';
import { PartnerBannerContainer } from '../partner-banner/partner-banner.container';
import { GiftcardBannerContainer } from '../giftcard-banner/giftcard-banner.container';
import { SettlementBannerContainer } from '../settlement-instructions-banner/settlement-banner.container';

import { BannerContainer } from '../banner/banner.container';

export class ThemeComponent extends PureComponent<Props> {
  render() {
    const showVATBanner = endDate => {
      return new Date() <= new Date(endDate);
    };

    return (
      <>
        <main className={classNames(styles.main, this.props.className)}>
          {this.props.sidebarless ? (
            <>
              <div className={styles.header}>
                <LanguageSwitcherContainer />
              </div>
              <div className="container">{this.props.children}</div>
            </>
          ) : (
            <MediaQuery minWidth={577}>
              {matches =>
                matches ? (
                  <div className="container-fluid">
                    <div className={classNames('row', styles.row)}>
                      <SidebarContainer />

                      <div className={classNames('col', styles.container)}>
                        <div className="container-fluid">
                          <BikeBenefitBannerContainer />
                          <PartnerBannerContainer />
                          {showVATBanner('2024-08-31') && (
                            <BannerContainer
                              title="VAT_BANNER.TITLE"
                              description="VAT_BANNER.DESCRIPTION"
                              linkUrl="https://www.smartum.fi/knowledge/miten-yleinen-alv-kannan-muutos-vaikuttaa-laskutukseeni"
                              linkText="VAT_BANNER.LINK"
                            />
                          )}
                          <GiftcardBannerContainer />
                          <SettlementBannerContainer />
                          {this.props.children}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <MobileHeaderContainer />
                    <div className="col-12">
                      <BikeBenefitBannerContainer />
                      <PartnerBannerContainer />
                      {showVATBanner('2024-08-31') && (
                        <BannerContainer
                          title="VAT_BANNER.TITLE"
                          description="VAT_BANNER.DESCRIPTION"
                          linkUrl="https://www.smartum.fi/knowledge/miten-yleinen-alv-kannan-muutos-vaikuttaa-laskutukseeni"
                          linkText="VAT_BANNER.LINK"
                        />
                      )}
                      <GiftcardBannerContainer />
                      <SettlementBannerContainer />
                      {this.props.children}
                    </div>
                  </>
                )
              }
            </MediaQuery>
          )}
        </main>
        <InfoBoxContainer />
        <FooterComponent />
      </>
    );
  }
}
