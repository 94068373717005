// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { SummaryFieldComponent } from 'app/shared';
import { currencyFormatter } from 'app/utils';

import type { Props } from './subscription-subscribe-form-benefits-radio.component.types';
import radioStyles from '../../personal/radio/subscription-subscribe-form-personal-radio.module.scss';
import styles from './subscription-subscribe-form-benefits-radio.module.scss';

export class SubscriptionSubscribeFormBenefitsRadioComponent extends Component<Props> {
  render() {
    return (
      <>
        {this.props.options.map((option, index) => (
          <label
            className={classNames(styles.label, {
              [styles.checked]: this.props.input.value === option.id,
              [radioStyles.checked]: this.props.input.value === option.id,
            })}
            key={index}
          >
            <div className="d-flex justify-content-between align-items-center">
              <h4 className={styles.title}>{option.title}</h4>
              <input
                {...this.props.input}
                onChange={() => this.props.input.onChange(option.id)}
                className={radioStyles.input}
                type="radio"
              />
              <span className={radioStyles.radio} />
            </div>
            <div className="row">
              {option.benefits.map((benefit, index) => (
                <div className="col-12 col-md-6 col-lg-4 col-xl-3 mt-3" key={index}>
                  <SummaryFieldComponent className="mb-0">
                    <FormattedMessage id={`PRODUCT.${benefit.id}`} tagName="strong" />
                    {this.props.type === 'EMONEY' ? (
                      currencyFormatter.format(benefit.amount)
                    ) : (
                      <>
                        <FormattedMessage id="CORE.BOOKS" values={{ books: benefit.amount }} /> ({currencyFormatter.format(
                          this.props.multipliers[benefit.id] * 10 * benefit.amount,
                        )})
                      </>
                    )}
                  </SummaryFieldComponent>
                </div>
              ))}

              {!!option.deductibleAmount && (
                <div className="col-12 col-md-6 col-lg-4 col-xl-3 mt-3">
                  <SummaryFieldComponent className="mb-0">
                    <FormattedMessage id="SUBSCRIPTION.DETAILS.EXCESS" tagName="strong" />
                    {currencyFormatter.format(option.deductibleAmount)}
                  </SummaryFieldComponent>
                </div>
              )}
            </div>
          </label>
        ))}
        {this.props.type === 'EMONEY' &&
          this.props.recurring && (
            <label
              className={classNames(styles.label, {
                [styles.checked]: !this.props.input.value,
                [radioStyles.checked]: !this.props.input.value,
              })}
            >
              <div className="d-flex justify-content-between align-items-center">
                <h4 className={styles.title}>
                  <FormattedMessage id="SUBSCRIPTION.SUBSCRIBE.NO_BENEFIT" />
                </h4>
                <input
                  {...this.props.input}
                  onChange={() => this.props.input.onChange(null)}
                  className={radioStyles.input}
                  type="radio"
                />
                <span className={radioStyles.radio} />
              </div>
              <p className="mt-2 mb-0">
                <FormattedMessage id="SUBSCRIPTION.SUBSCRIBE.NO_BENEFIT_DESC" />
              </p>
            </label>
          )}
      </>
    );
  }
}
