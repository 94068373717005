// @flow

import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { CreateSubscriptionContainer } from './create/create-subscription.container';
import { EditSubscriptionContainer } from './edit/edit-subscription.container';
import { SubscriptionBeneficiariesContainer } from './beneficiaries/subscription-beneficiaries.container';
import { SubscriptionOrderContainer } from './order/subscription-order.container';
import { SubscriptionDetailsContainer } from './details/subscription-details.container';
import { SubscriptionsListContainer } from './list/subscriptions-list.container';

import type { Props } from './subscriptions.component.types';

export class SubscriptionsComponent extends Component<Props> {
  render() {
    return (
      <Switch>
        <Route path={`${this.props.match.url}/:type(emoney|voucher)/create`} component={CreateSubscriptionContainer} />
        <Route
          path={`${this.props.match.url}/:type(emoney|voucher)/:subscription/edit`}
          component={EditSubscriptionContainer}
        />
        <Route
          path={`${this.props.match.url}/:type(emoney|voucher)/:subscription/beneficiaries`}
          component={SubscriptionBeneficiariesContainer}
        />
        <Route
          path={`${this.props.match.url}/:type(emoney|voucher)/:subscription/order/:order?`}
          component={SubscriptionOrderContainer}
        />
        <Route
          path={`${this.props.match.url}/:type(emoney|voucher)/:subscription`}
          component={SubscriptionDetailsContainer}
        />
        <Route path={`${this.props.match.url}/:type(emoney|voucher)`} component={SubscriptionsListContainer} />
        <Redirect to={`${this.props.match.url}/emoney/`} />
      </Switch>
    );
  }
}
