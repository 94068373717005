// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
// $FlowFixMe
import styles from './partner-banner.module.scss';
import { USER_ROLES } from 'app/constants';
import type { Props } from './partner-banner.component.types';

export class PartnerBannerComponent extends Component<Props> {
  render() {
    const { role, intl } = this.props;

    if (
      ![
        USER_ROLES.ROLE_VENUE_MANAGER,
        USER_ROLES.ROLE_SERVICE_PROVIDER_MANAGER,
        USER_ROLES.ROLE_VENUE_ACCOUNTANT,
      ].includes(role)
    ) {
      return null;
    }

    const helpDeskUrl = intl.formatMessage({ id: 'PARTNER_BANNER.HELPDESK_URL' });
    const contractTermsUrl = intl.formatMessage({ id: 'PARTNER_BANNER.CONTRACT_TERMS_URL' });

    return (
      <div className={styles.banner}>
        <div>
          <FormattedMessage id="PARTNER_BANNER.TITLE" tagName="h6" />
          <FormattedMessage
            id="PARTNER_BANNER.DESCRIPTION"
            tagName="p"
            values={{
              helpDeskLink: (
                <a href={helpDeskUrl} target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="PARTNER_BANNER.HELPDESK_LINK" />
                </a>
              ),
              contractTermsLink: (
                <a href={contractTermsUrl} target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="CORE.HERE_2" />
                </a>
              ),
            }}
          />
        </div>
      </div>
    );
  }
}
