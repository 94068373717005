// @flow

import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ThemeComponent } from 'app/core';

import { RegisterCompanyContainer } from './company/register-company.container';
import { RegisterServiceContainer } from './service/register-service.container';

import type { Props } from './register.component.types';
import styles from './register.module.scss';

export class RegisterComponent extends Component<Props> {
  render() {
    return (
      <ThemeComponent className={styles.main} sidebarless>
        <Switch>
          <Route exact path={`${this.props.match.url}/company`} component={RegisterCompanyContainer} />
          <Route exact path={`${this.props.match.url}/service`} component={RegisterServiceContainer} />
          <Redirect to={`${this.props.match.url}/company`} />
        </Switch>
      </ThemeComponent>
    );
  }
}
