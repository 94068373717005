// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

import { LinkComponent } from './link/link.component';

import styles from './nav.module.scss';

export class NavComponent extends Component<any> {
  render() {
    return (
      <section className={classNames('row', styles.nav)}>
        <div className="col-lg-6">
          <LinkComponent
            title="REGISTER.COMPANY.TITLE"
            description="REGISTER.COMPANY.DESCRIPTION"
            to="/register/company"
          />
        </div>
        <div className="col-lg-6">
          <LinkComponent
            title="REGISTER.SERVICE_PROVIDER.TITLE"
            description="REGISTER.SERVICE_PROVIDER.DESCRIPTION"
            to="/register/service"
          />
        </div>
      </section>
    );
  }
}
