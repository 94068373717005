export const CREATE_MOBILE_PAYMENT_REQUEST = 'payments/CREATE_MOBILE_PAYMENT_REQUEST';
export const CREATE_MOBILE_PAYMENT_SUCCESS = 'payments/CREATE_MOBILE_PAYMENT_SUCCESS';
export const CREATE_MOBILE_PAYMENT_FAILURE = 'payments/CREATE_MOBILE_PAYMENT_FAILURE';

export const VALIDATE_MOBILE_PAYMENT_REQUEST = 'payments/VALIDATE_MOBILE_PAYMENT_REQUEST';
export const VALIDATE_MOBILE_PAYMENT_SUCCESS = 'payments/VALIDATE_MOBILE_PAYMENT_SUCCESS';
export const VALIDATE_MOBILE_PAYMENT_FAILURE = 'payments/VALIDATE_MOBILE_PAYMENT_FAILURE';

export const FETCH_PAYMENT_ACCOUNT_REQUEST = 'payments/FETCH_PAYMENT_ACCOUNT_REQUEST';
export const FETCH_PAYMENT_ACCOUNT_SUCCESS = 'payments/FETCH_PAYMENT_ACCOUNT_SUCCESS';
export const FETCH_PAYMENT_ACCOUNT_FAILURE = 'payments/FETCH_PAYMENT_ACCOUNT_FAILURE';

export const FETCH_PAYMENT_TOKEN_REQUEST = 'payments/FETCH_PAYMENT_TOKEN_REQUEST';
export const FETCH_PAYMENT_TOKEN_SUCCESS = 'payments/FETCH_PAYMENT_TOKEN_SUCCESS';
export const FETCH_PAYMENT_TOKEN_FAILURE = 'payments/FETCH_PAYMENT_TOKEN_FAILURE';

export const VALIDATE_COUPON_CHARGE_REQUEST = 'payments/VALIDATE_COUPON_CHARGE_REQUEST';
export const VALIDATE_COUPON_CHARGE_SUCCESS = 'payments/VALIDATE_COUPON_CHARGE_SUCCESS';
export const VALIDATE_COUPON_CHARGE_FAILURE = 'payments/VALIDATE_COUPON_CHARGE_FAILURE';

export const COUPON_CHARGE_REQUEST = 'payments/COUPON_CHARGE_REQUEST';
export const COUPON_CHARGE_SUCCESS = 'payments/COUPON_CHARGE_SUCCESS';
export const COUPON_CHARGE_FAILURE = 'payments/COUPON_CHARGE_FAILURE';
