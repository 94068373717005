import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { push } from 'react-router-redux';

import { returnEmoney } from 'app/redux/accounts/accounts.actions';
import { updateConfirmation } from 'app/redux/view/view.actions';

import { AccountDetailsReturnComponent } from './account-details-return.component';

const mapDispatchToProps = (dispatch, { accountNumber, onClose }) => ({
  onSubmit: body =>
    new Promise((resolve, reject) =>
      dispatch(
        updateConfirmation({
          onClose: resolve,
          onConfirm: () =>
            dispatch(returnEmoney(accountNumber, body))
              .then(() =>
                dispatch(
                  push({
                    state: {
                      success: {
                        type: 'RETURN_MADE',
                      },
                    },
                  }),
                ),
              )
              .then(onClose)
              .catch(reject),
          title: 'ACCOUNT_INFORMATION.CONFIRM_RETURN',
        }),
      ),
    ),
});

export const AccountDetailsReturnContainer = compose(
  injectIntl,
  connect(
    null,
    mapDispatchToProps,
  ),
  reduxForm({ form: 'return-emoney' }),
)(AccountDetailsReturnComponent);
