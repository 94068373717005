import React from 'react';
import ReactDOM from 'react-dom';
import 'react-dates/initialize';

import { createStore, applyMiddleware, compose } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension/logOnlyInProduction';
import { Provider } from 'react-redux';

import { Route } from 'react-router-dom';
import { ConnectedRouter, routerMiddleware } from 'react-router-redux';
import * as remixHistory from 'history';

import { apiMiddleware } from 'redux-api-middleware';
import createSagaMiddleware from 'redux-saga';
import thunkMiddleware from 'redux-thunk';

import {
  authMiddleware,
  bodyMiddleware,
  endpointMiddleware,
  errorsMiddleware,
  paramsMiddleware,
  timeoutMiddleware,
  validationMiddleware,
  trackingMiddleware,
} from 'app/middlewares';

import 'assets/styles/main.scss';

import { AppContainer } from 'app/app.container';
import { appReducer } from 'app/app.reducer';
import { appSaga } from 'app/app.saga';

import { withTracker } from 'app/common';

import registerServiceWorker from 'registerServiceWorker';

// #if process.env.REACT_APP_SENTRY_DSN
import * as Sentry from '@sentry/browser';
// #endif

export const history = remixHistory.createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  trackingMiddleware,
  bodyMiddleware,
  authMiddleware,
  endpointMiddleware,
  paramsMiddleware,
  errorsMiddleware,
  apiMiddleware,
  validationMiddleware,
  errorsMiddleware,
  timeoutMiddleware,
  sagaMiddleware,
  routerMiddleware(history),
  thunkMiddleware,
];

const store = createStore(
  appReducer,
  {
    users: {
      accessToken: localStorage.getItem('accessToken'),
      business: Number(localStorage.getItem('currentBusiness')),
      entities: {},
    },
  },
  compose(applyMiddleware(...middlewares), devToolsEnhancer({})),
);

if (module.hot) {
  module.hot.accept('app/app.reducer', () => {
    store.replaceReducer(appReducer);
  });
}

sagaMiddleware.run(appSaga);

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        {process.env.REACT_APP_GA ? <Route component={withTracker(AppContainer)} /> : <AppContainer />}
      </ConnectedRouter>
    </Provider>,
    document.getElementById('app-root'),
  );
};

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
  });
}

render();

if (module.hot) {
  module.hot.accept('app/app.container', () => {
    render();
  });
}

registerServiceWorker();
