// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

import { FormattedMessage } from 'react-intl';

import { AlertComponent, CardComponent, SummaryFieldComponent } from 'app/shared';

import { BeneficiaryDetailsPersonalPlaceholder } from './placeholder/beneficiary-details-personal.placeholder';

import type { Props } from './beneficiary-details-personal.component.types';

export class BeneficiaryDetailsPersonalComponent extends Component<Props> {
  render() {
    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    return (
      <CardComponent>
        <div className="card-header">
          {!!this.props.location.state &&
            !!this.props.location.state.success && (
              <AlertComponent type="success">
                {this.props.location.state.success.type === 'CARDS.ORDER.SUCCESS' && (
                  <FormattedMessage id="CARDS.ORDER.SUCCESS" />
                )}
              </AlertComponent>
            )}
          <h4 className="card-title">
            <FormattedMessage id="PROFILE.PERSONAL_INFORMATION" />
          </h4>
        </div>
        {this.props.loading && <BeneficiaryDetailsPersonalPlaceholder sidebarCollapsed={this.props.sidebarCollapsed} />}
        {this.props.loading || (
          <div className="card-body">
            <div className="row">
              <div className={columnClassNames}>
                <SummaryFieldComponent>
                  <FormattedMessage id="PROFILE.FIRST_NAME" tagName="strong" />
                  {this.props.beneficiary.firstName}
                </SummaryFieldComponent>
              </div>
              <div className={columnClassNames}>
                <SummaryFieldComponent>
                  <FormattedMessage id="PROFILE.LAST_NAME" tagName="strong" />
                  {this.props.beneficiary.lastName}
                </SummaryFieldComponent>
              </div>
              <div className={columnClassNames}>
                <SummaryFieldComponent>
                  <FormattedMessage id="PROFILE.SSN" tagName="strong" />
                  {this.props.beneficiary.ssn}
                </SummaryFieldComponent>
              </div>
              <div className={columnClassNames}>
                <SummaryFieldComponent>
                  <FormattedMessage id="PROFILE.EMAIL" tagName="strong" />
                  {this.props.beneficiary.email}
                </SummaryFieldComponent>
              </div>
              <div className={columnClassNames}>
                <SummaryFieldComponent>
                  <FormattedMessage id="PROFILE.MOBILE_PHONE" tagName="strong" />
                  {this.props.beneficiary.mobilePhone}
                </SummaryFieldComponent>
              </div>
              <div className={columnClassNames}>
                <SummaryFieldComponent>
                  <FormattedMessage id="PROFILE.WORK_PHONE" tagName="strong" />
                  {this.props.beneficiary.workPhone}
                </SummaryFieldComponent>
              </div>
              <div className={columnClassNames}>
                <SummaryFieldComponent>
                  <FormattedMessage id="REGISTER.MARKETING" tagName="strong" />
                  {this.props.beneficiary.marketing ? (
                    <FormattedMessage id="REGISTER.SELECTED_MARKETING" />
                  ) : (
                    <FormattedMessage id="REGISTER.NOT_SELECTED_MARKETING" />
                  )}
                </SummaryFieldComponent>
              </div>
            </div>
          </div>
        )}
      </CardComponent>
    );
  }
}
