import { get } from 'lodash';

export const createExceededError = (errors, intl) => {
  const exceededError = get(errors, 'exceededBenefits[0]');

  if (exceededError) {
    return {
      id: 'VALIDATION.YEARLY_LIMIT_EXCEEDED_DETAILS',
      type: intl.formatMessage({ id: `PAYMENT.ITEM.${exceededError.type}` }),
      benefitAmount: exceededError.benefitAmount,
      limitLeft: exceededError.limitLeft,
    };
  } else {
    return { id: errors._error };
  }
};
