// @flow

import { Component } from 'react';
import ReactDOM from 'react-dom';
import onClickOutside from 'react-onclickoutside';

import type { Props } from './sidebar-nested-wrapper.component.types';

export const SidebarNestedWrapperComponent = onClickOutside(
  class SidebarNestedWrapper extends Component<Props> {
    portal: ?HTMLElement;
    element: HTMLDivElement;

    constructor(props) {
      super(props);

      this.portal = document.getElementById('portal-root');
      this.element = document.createElement('div');
    }

    componentDidMount() {
      if (this.portal) {
        this.portal.appendChild(this.element);
      }

      window.addEventListener('resize', this.props.onClickOutside);
    }

    componentWillUnmount() {
      if (this.portal) {
        this.portal.removeChild(this.element);
      }

      window.removeEventListener('resize', this.props.onClickOutside);
    }

    handleClickOutside = evt => this.props.onClickOutside();

    render() {
      return ReactDOM.createPortal(this.props.children, this.element);
    }
  },
);
