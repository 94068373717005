import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { replace } from 'react-router-redux';

import { withAuthorization } from 'app/common';

import { fetchCustomer } from 'app/redux/customers/customers.actions';
import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';
import { createBanknotesOrder, validateBanknotesOrder } from 'app/redux/orders/orders.actions';

import { BanknotesOrderComponent } from './banknotes-order.component';

const mapStateToProps = ({ customers: { entities: customers }, dictionaries, users: { business, current } }) => ({
  business,
  loading: !customers[business] || !dictionaries['products/banknote'] || !dictionaries['invoicing-options'],
  initialValues: customers[business]
    ? {
        invoicing: {
          invoicingMethod: '',
          invoiceName: customers[business].name,
          invoiceEmail: current.personal.email,
          invoiceReference: customers[business].invoicing.defaultInvoicingReference,
          eInvoiceAddress: customers[business].invoicing.eInvoiceAddress,
          eInvoiceIdentifier: customers[business].invoicing.eInvoiceDeliveryIdentifier,
        },
        invoicingAddress: {
          company: customers[business].name,
          ...customers[business].addresses.delivery,
        },
        products: [{}],
      }
    : undefined,
});

const mapDispatchToProps = (dispatch, { intl: { locale } }) => ({
  fetchCustomer: customer => dispatch(fetchCustomer(customer)),
  fetchDictionaries: () =>
    Promise.all([dispatch(fetchDictionary('products/banknote')), dispatch(fetchDictionary('invoicing-options'))]),
  onSubmit: body =>
    dispatch(createBanknotesOrder(body, locale)).then(() =>
      dispatch(
        replace({
          pathname: `/orders`,
          state: {
            success: {
              type: 'BANKNOTES.ORDER.SUCCESS',
            },
          },
        }),
      ),
    ),
  onSubmitFail: () => window.scrollTo(0, 0),
  onValidate: body => {
    if (body.invoicing.invoicingMethod !== 'EINVOICE') {
      delete body.invoicing.eInvoiceAddress;
      delete body.invoicing.eInvoiceIdentifier;
    }
    return dispatch(validateBanknotesOrder(body));
  },
});

export const BanknotesOrderContainer = compose(
  injectIntl,
  withAuthorization(['banknotesOrders', 'create']),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'order-banknotes',
  }),
)(BanknotesOrderComponent);
