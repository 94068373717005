// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import Select from 'react-select';

import type { Props, Option } from './language-switcher.component.types';
import styles from './language-switcher.module.scss';

export class LanguageSwitcherComponent extends Component<Props> {
  optionRenderer = (option: Option) => (
    <div className={styles.option}>
      <span className={classNames(styles.flag, styles[option.value])} />
      {option.label}
    </div>
  );

  render() {
    const options = [
      { value: 'en', label: this.props.intl.formatMessage({ id: 'CORE.ENGLISH' }) },
      { value: 'fi', label: this.props.intl.formatMessage({ id: 'CORE.FINNISH' }) },
      { value: 'sv', label: this.props.intl.formatMessage({ id: 'CORE.SWEDISH' }) },
    ];

    return (
      <div className={styles.container}>
        <span className={styles.label}>{this.props.intl.formatMessage({ id: 'CORE.LANGUAGE' })}:</span>
        <Select
          name="locale"
          options={options}
          className={styles.select}
          value={this.props.locale}
          onChange={({ value }) => this.props.updateLocale(value)}
          optionRenderer={this.optionRenderer}
          clearable={false}
          searchable={false}
          autoBlur={false}
        />
      </div>
    );
  }
}
