// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Switch } from 'react-router-dom';

import { ListComponent } from 'app/shared/list/list.component';
import { CardComponent } from 'app/shared';

import { MySubscriptionsListTableComponent } from './table/my-subscriptions-list-table.component';
import { MySubscriptionsListTileComponent } from './tile/my-subscriptions-list-tile.component';

import { BeneficiaryDetailsSubscriptionsEditContainer } from 'app/beneficiaries/details/subscriptions/edit/beneficiary-details-subscriptions-edit.container';

import type { Props } from './my-subscriptions-list.component.types';

export class MySubscriptionsListComponent extends Component<Props> {
  render() {
    return (
      <CardComponent>
        <div className="card-header">
          <h4 className="card-title">
            <FormattedMessage id="MY_SUBSCRIPTIONS.TITLE" />
          </h4>
        </div>
        <div className="card-body">
          <ListComponent
            list={this.props.subscriptions}
            table={MySubscriptionsListTableComponent}
            tile={MySubscriptionsListTileComponent}
            searchable={false}
            props={{
              url: this.props.match.url,
            }}
          />
          <Switch>
            <Route
              path={`${this.props.match.url}/:subscriptionBeneficiary`}
              render={props => (
                <BeneficiaryDetailsSubscriptionsEditContainer pathname={this.props.match.url} {...props} />
              )}
            />
          </Switch>
        </div>
      </CardComponent>
    );
  }
}
