// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import pad from 'zero-fill';

import { CONTINUE_ORDER_WHITELIST } from '../orders-list.component';

import { ButtonComponent, TileComponent } from 'app/shared';
import { currencyFormatter, dateFormatter } from 'app/utils';

import type { Props } from './orders-list-tile.component.types';

export class OrdersListTileComponent extends Component<Props> {
  render() {
    return (
      <TileComponent>
        <h3>
          <Link
            to={{
              pathname: `/orders/${this.props.tile.id}`,
              state: {
                keepScrollPosition: true,
              },
            }}
          >
            {pad(7, this.props.tile.id)}
          </Link>
        </h3>

        <div className="row">
          <div className="col">
            <FormattedMessage id="ORDER_HISTORY.DATE" tagName="small" />
            <span className="small">{dateFormatter(this.props.tile.orderDate)}</span>
          </div>

          <div className="col">
            <FormattedMessage id="ORDER_HISTORY.BENEFITS" tagName="small" />
            <strong>{currencyFormatter.format(this.props.tile.totalAmount)}</strong>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <FormattedMessage id="ORDER_HISTORY.STATUS" tagName="small" />
            <span className="small text-uppercase">
              <FormattedMessage id={`STATUS.ORDER.${this.props.tile.status}`} />
            </span>
          </div>
        </div>

        {this.props.tile.status === 'STATUS_OPEN' &&
          CONTINUE_ORDER_WHITELIST.includes(this.props.tile.type) && (
            <div className="buttons">
              <ButtonComponent
                theme="outline-secondary"
                size="small"
                to={`/subscriptions/${this.props.tile.type}/${this.props.tile.subscriptionId}/order/${
                  this.props.tile.id
                }`}
              >
                <i className="fi fi-pencil-edit-button" />
              </ButtonComponent>
            </div>
          )}
      </TileComponent>
    );
  }
}
