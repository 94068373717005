// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Switch } from 'react-router-dom';

import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';

import { TransactionDetailsContainer } from 'app/transactions/details/transaction-details.container';
import { AccountTransactionsFiltersContainer } from './filters/account-transactions-filters.container';
import { AccountTransactionsPlaceholder } from './placeholder/account-transactions.placeholder';
import { AccountTransactionsTableComponent } from './table/account-transactions-table.component';
import { AccountTransactionsTileComponent } from './tile/account-transactions-tile.component';

import { HeaderComponent } from 'app/core';
import { ListComponent } from 'app/shared/list/list.component';
import { ButtonComponent, CardComponent, LoadMoreComponent } from 'app/shared';

import type { Props } from './account-transactions.component.types';

export class AccountTransactionsComponent extends Component<Props> {
  componentDidMount() {
    this.props.fetchDictionary();
    this.props.fetchAccount();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.business !== this.props.business || !isEqual(prevProps.subBusiness, this.props.subBusiness)) {
      this.props.fetchAccount();
      this.props.fetchTransactions({ limit: 10 });
      this.props.resetFilters();
    }
  }

  render() {
    const noResults = this.props.loading || !this.props.transactions.length;

    const breadcrumbs = this.props.match.params.number
      ? [
          { icon: 'account-balance', to: '/accounts/company' },
          { name: <FormattedMessage id="SIDEBAR.CUSTOMER_ACCOUNTS" />, to: '/accounts/company' },
          {
            name: this.props.account.item && <FormattedMessage id={`PRODUCT.${this.props.account.item}`} />,
          },
        ]
      : [{ icon: 'history' }, { name: <FormattedMessage id="ACCOUNTS.ACCOUNT_TRANSACTIONS" /> }];

    return (
      <>
        <HeaderComponent
          breadcrumbs={breadcrumbs}
          button={
            <ButtonComponent size="large" theme="white" onClick={this.props.onDownload} disabled={noResults}>
              <i className="fi fi-xlsx-file-format-extension" />
              <FormattedMessage id="TRANSACTIONS.DOWNLOAD_AS_XLSX" />
            </ButtonComponent>
          }
        >
          <FormattedMessage id="ACCOUNTS.ACCOUNT_TRANSACTIONS" tagName="h2" />
          {!!this.props.subBusiness && <p>{this.props.subBusiness.name}</p>}
        </HeaderComponent>

        <CardComponent>
          <AccountTransactionsFiltersContainer
            onChange={debounce(this.props.onFiltersChange, 500)}
            types={this.props.types}
            account={{
              account: this.props.account,
              loading: this.props.loadingAccount,
            }}
          />

          {this.props.loading && <AccountTransactionsPlaceholder />}

          {this.props.loading || (
            <>
              <div className="card-header card-header--between">
                <h4 className="card-title">
                  <FormattedMessage
                    id="TRANSACTIONS.COUNTER"
                    values={{ count: this.props.transactions.length, total: this.props.meta.total }}
                  />
                </h4>
              </div>
              <div className="card-body">
                <ListComponent
                  list={this.props.transactions}
                  table={AccountTransactionsTableComponent}
                  tile={AccountTransactionsTileComponent}
                  props={{
                    pathname: this.props.location.pathname,
                    permissions: this.props.permissions,
                  }}
                />

                {this.props.transactions.length < this.props.meta.total && (
                  <LoadMoreComponent onClick={this.props.onLoadMore} fetching={this.props.fetching} />
                )}
              </div>
            </>
          )}
        </CardComponent>

        {(this.props.match.params.number || this.props.account.accountNumber) && (
          <Switch>
            <Route
              path={`${this.props.match.url}/:transaction`}
              render={props => (
                <TransactionDetailsContainer
                  number={this.props.match.params.number || this.props.account.accountNumber}
                  pathname={this.props.match.url}
                  {...props}
                />
              )}
            />
          </Switch>
        )}
      </>
    );
  }
}
