import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { withCoreContext } from 'app/core/core.context';

import { AddBeneficiaryFiltersComponent } from './add-beneficiary-filters.component';

const mapStateToProps = () => ({
  initialValues: {
    name: '',
    email: '',
    ssn: '',
  },
});

export const AddBeneficiaryFiltersContainer = compose(
  withRouter,
  injectIntl,
  withCoreContext,
  connect(mapStateToProps),
  reduxForm({
    form: 'add-beneficiary-filters',
  }),
)(AddBeneficiaryFiltersComponent);
