// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { ButtonComponent, NoResultsContainer, TableComponent } from 'app/shared';

import { checkPermissions, currencyFormatter } from 'app/utils';

import type { Props } from './my-accounts-list-table.component.types';

export class MyAccountsListTableComponent extends Component<Props> {
  render() {
    return (
      <TableComponent className="table-striped">
        <thead>
          <tr>
            <FormattedMessage id="ACCOUNTS.TYPE" tagName="th" />
            <FormattedMessage id="ACCOUNTS.BALANCE" tagName="th" />
            {checkPermissions(this.props.permissions, ['companyBeneficiaryAccounts', 'update']) && (
              <>
                <FormattedMessage id="ACCOUNTS.STATUS" tagName="th" />
                <th />
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {!!this.props.list.length || (
            <tr>
              <td colSpan={4}>
                <NoResultsContainer searchable={false} />
              </td>
            </tr>
          )}

          {this.props.list.map(account => (
            <tr key={account.accountNumber}>
              <td>
                <Link to={`${this.props.pathname || ''}/accounts/${account.accountNumber}`}>
                  <FormattedMessage id={`PAYMENT.ITEM.${account.item}`} />
                </Link>
              </td>
              <td>{currencyFormatter.format(account.balance)}</td>
              {checkPermissions(this.props.permissions, ['companyBeneficiaryAccounts', 'update']) && (
                <>
                  <td>
                    {account.active ? (
                      <FormattedMessage id="ACCOUNTS.ACTIVE" />
                    ) : (
                      <FormattedMessage id="ACCOUNTS.PASSIVE" />
                    )}
                  </td>
                  <td className="text-right">
                    {account.active ? (
                      <ButtonComponent
                        theme="outline-danger"
                        size="small"
                        onClick={() => this.props.onDisable(account.accountNumber)}
                      >
                        <FormattedMessage id="ACCOUNTS.DISABLE" />
                      </ButtonComponent>
                    ) : (
                      <ButtonComponent
                        theme="outline-success"
                        size="small"
                        onClick={() => this.props.onActivate(account.accountNumber)}
                      >
                        <FormattedMessage id="ACCOUNTS.ACTIVATE" />
                      </ButtonComponent>
                    )}
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </TableComponent>
    );
  }
}
