import { connect } from 'react-redux';

import { fetchDashboard } from 'app/redux/metabase/metabase.actions';
import { MetabaseDashboardComponent } from './metabase-dashboard.component';

const mapStateToProps = ({ users: { business, role, businessType }, metabase }) => ({
  business,
  businessType,
  role,
  ...metabase,
});

const mapDispatchToProps = dispatch => ({
  fetchDashboard: body => dispatch(fetchDashboard(body)),
});

export const MetabaseDashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MetabaseDashboardComponent);
