// @flow
import React, { Component } from 'react';
import type { ComponentType } from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';

import { ButtonComponent } from 'app/shared';

import { getFirstAndLastDatesOfCurrentMonth } from '../../utils/get-first-date-last-date-of-current-month/get-first-date-last-date-of-current-month.util';
import type { Props, State, FiltersType } from './with-pagination.hoc.types';
import styles from './with-pagination.module.scss';

export const sizeOptions = [
  { id: 10, name: <FormattedMessage id="TRANSACTIONS.NO_OF_ROWS" values={{ size: 10 }} /> },
  { id: 100, name: <FormattedMessage id="TRANSACTIONS.NO_OF_ROWS" values={{ size: 100 }} /> },
  { id: 1000, name: <FormattedMessage id="TRANSACTIONS.NO_OF_ROWS" values={{ size: 1000 }} /> },
];

export const withPagination = (WrappedComponent: ComponentType<any>): ComponentType<any> =>
  class PaginatedComponent extends Component<Props, State> {
    constructor(props: Props) {
      super(props);

      this.state = {
        initialValues: {
          minDate: getFirstAndLastDatesOfCurrentMonth().firstDate,
          maxDate: getFirstAndLastDatesOfCurrentMonth().lastDate,
          size: sizeOptions[0].id,
        },
        params: {},
        currentPage: 1,
        rowsPerPage: sizeOptions[0].id,
      };
    }

    componentDidMount() {
      this.props.fetchTransactions(this.state.initialValues);
    }

    componentDidUpdate(prevProps: Props) {
      if (
        prevProps.business !== this.props.business ||
        get(prevProps.location, ['state', 'success', 'type']) === 'TRANSACTION_REFUNDED'
      ) {
        this.props.fetchTransactions(this.state.params);
      }
    }

    onFiltersChange = (params: FiltersType) => {
      if (params.size) {
        this.setState({ rowsPerPage: params.size });
      }

      this.setState(
        prev => ({
          ...prev,
          params: {
            ...params,
          },
          currentPage: 1,
        }),
        () => this.props.fetchTransactions(this.state.params),
      );
    };

    onChange = (e: SyntheticInputEvent<HTMLInputElement>) => this.setState({ currentPage: Number(e.target.value) });

    onKeyDown = (e: KeyboardEvent, totalPages: number) => {
      if (e.keyCode === 13) {
        let value = Number(this.state.currentPage);

        if (isNaN(value) || value < 1) {
          value = 1;
        } else if (value > totalPages) {
          value = totalPages;
        }
      }
    };

    paginationRenderer = (totalRows: number) => {
      if (this.props.loading) return this.props.loading;

      if (!totalRows) return null;

      const totalPages = Math.ceil(totalRows / this.state.rowsPerPage);

      return (
        <div className={styles.pagination}>
          {this.state.currentPage > 1 && (
            <ButtonComponent
              theme="secondary"
              size="small"
              onClick={() => this.setState({ currentPage: this.state.currentPage - 1 })}
            >
              <FormattedMessage id="CORE.PAGINATION.PREV" />
            </ButtonComponent>
          )}

          <input
            value={this.state.currentPage}
            onChange={this.onChange}
            onKeyDown={e => this.onKeyDown(e, totalPages)}
            className={styles.input}
          />

          <span className={styles.total}>
            <FormattedMessage id="CORE.PAGINATION.TOTAL" values={{ total: totalPages || 1 }} />
          </span>

          {this.state.currentPage < totalPages && (
            <ButtonComponent
              theme="secondary"
              size="small"
              onClick={() => this.setState({ currentPage: this.state.currentPage + 1 })}
            >
              <FormattedMessage id="CORE.PAGINATION.NEXT" />
            </ButtonComponent>
          )}
        </div>
      );
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          filters={this.state.params}
          renderPagination={this.paginationRenderer}
          currentPage={this.state.currentPage}
          rowsPerPage={this.state.rowsPerPage}
          onFiltersChange={this.onFiltersChange}
        />
      );
    }
  };
