import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { formValueSelector, initialize, reduxForm } from 'redux-form';
import { get } from 'lodash';

import { withCoreContext } from 'app/core/core.context';

import { updateCurrentCustomer, terminateContract } from 'app/redux/customers/customers.actions';

import { CustomerInformationFormComponent } from './customer-information-form.component';

const selector = formValueSelector('edit-customer-information');

const mapStateToProps = ({ users: { business, role }, dictionaries, ...state }, { customer }) => ({
  role,
  business,
  initialValues: customer,
  iban: selector(state, 'bankingInformation.iban'),
  swift: selector(state, 'bankingInformation.swift'),
  swiftCodesOptions: Object.entries(get(dictionaries, ['services', 'swiftCodes'], [])).map(([id, name]) => ({
    id,
    name,
  })),
  eInvoiceOperators: Object.entries(get(dictionaries, ['services', 'eInvoiceOperators'], [])).map(([id, name]) => ({
    id: name,
    name,
  })),
  invoicingMethods: [
    { name: 'INVOICING_METHOD_EMAIL', id: 'INVOICING_METHOD_EMAIL' },
    { name: 'INVOICING_METHOD_ONLINE', id: 'INVOICING_METHOD_ONLINE' },
  ],
  formValues: {
    defaultInvoicingMethod: selector(state, 'invoicing.defaultInvoicingMethod'),
  },
});

const mapDispatchToProps = dispatch => ({
  onSubmit: body =>
    dispatch(updateCurrentCustomer(body)).then(data =>
      dispatch(initialize('edit-customer-information', data.payload.data)),
    ),
  onSubmitFail: () => window.scrollTo(0, 0),
  onTerminateContract: (customerId, body) => dispatch(terminateContract(customerId, body)),
});

export const CustomerInformationFormContainer = compose(
  injectIntl,
  withCoreContext,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'edit-customer-information',
  }),
)(CustomerInformationFormComponent);
