// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Switch } from 'react-router-dom';
import MediaQuery from 'react-responsive';

import { union } from 'lodash';

import { ListComponent } from 'app/shared/list/list.component';
import { AlertComponent, ButtonComponent, ButtonsWrapperComponent, CardComponent, ModalComponent } from 'app/shared';

import { SubscriptionNavContainer } from 'app/subscriptions/nav/subscription-nav.container';
import { SubscriptionBeneficiariesFiltersContainer } from './filters/subscription-beneficiaries-filters.container';
import { SubscriptionBeneficiariesPlaceholder } from './placeholder/subscription-beneficiaries.placeholder';
import { SubscriptionBeneficiariesTableComponent } from './table/subscription-beneficiaries-table.component';
import { SubscriptionBeneficiariesTileComponent } from './tile/subscription-beneficiaries-tile.component';
import { SubscriptionBeneficiariesModifyContainer } from './modify/subscription-beneficiaries-modify.container';
import { SubscriptionBeneficiariesDetailsContainer } from './details/subscription-beneficiaries-details.container';

import { AddBeneficiaryContainer } from './add/add-beneficiary.container';

import styles from './subscription-beneficiaries.module.scss';
import type { Props, State } from './subscription-beneficiaries.component.types';
import type { BodyType } from './modify/subscription-beneficiaries-modify.component.types';

export class SubscriptionBeneficiariesComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isModifyOpened: false,
      isAcceptConfirmationVisible: false,
      isRejectConfirmationVisible: false,
    };
  }

  componentDidMount() {
    this.props.fetchCompanyBeneficiaries();
    this.props.fetchDictionary();
    this.props.fetchSubscription();
  }

  showConfirmationModal = (type: string) => {
    if (type === 'ACCEPT') {
      this.setState({ isAcceptConfirmationVisible: true });
    } else {
      this.setState({ isRejectConfirmationVisible: true });
    }
  };

  hideConfirmationModal = () => {
    this.setState({ isAcceptConfirmationVisible: false, isRejectConfirmationVisible: false });
  };

  changeStatus = (status: string) => {
    this.hideConfirmationModal();
    return this.props.onBulkEdit({ status: status });
  };

  onSubmitModify = ({ benefits, deductibleAmount = '' }: BodyType) =>
    this.props
      .onBulkEdit({
        benefits: benefits.map(
          option =>
            option.amount
              ? {
                  ...option,
                  amount: option.amount.replace(/,/g, '.'),
                }
              : null,
        ),
        ...(isNaN(deductibleAmount.replace(/,/g, '.'))
          ? {}
          : { deductibleAmount: Number(deductibleAmount.replace(/,/g, '.')) }),
      })
      .then(() => this.setState({ isModifyOpened: false }));

  render() {
    const benefitOptions =
      this.props.loading ||
      this.props.subscription.benefitOptions.reduce(
        (prev, next) => union(prev, next.benefits.map(benefit => benefit.id)),
        [],
      );

    const benefits = !this.props.loading ? this.props.subscription.benefits : [];
    const uniqueBenefits = benefits.filter((item, pos) => benefits.indexOf(item) === pos);

    const disabled = this.props.loading || this.props.subscription.status === 'ORDERED';

    const isReadyToOrder =
      this.props.subscription &&
      this.props.subscription.status !== 'ORDERED' &&
      this.props.subscription.summary.beneficiaries.new === 0 &&
      this.props.subscription.summary.beneficiaries.declined !== this.props.subscription.summary.beneficiaries.total;

    const isNotPersonalisedVoucherOrder = this.props.match.params.type !== 'voucher';

    return (
      <>
        <CardComponent header={false}>
          <div className="card-header">
            <SubscriptionNavContainer />

            <SubscriptionBeneficiariesFiltersContainer />
          </div>

          {this.props.loading || (
            <div className="card-header">
              <h4 className="card-title d-flex align-items-center justify-content-xl-between">
                <FormattedMessage
                  id="SUBSCRIPTION.BENEFICIARIES.TITLE"
                  values={{ records: this.props.meta.totalItems }}
                />
              </h4>
            </div>
          )}

          {this.props.loading && <SubscriptionBeneficiariesPlaceholder />}

          {this.props.loading || (
            <div className="card-body">
              {!!this.props.location.state &&
                !!this.props.location.state.success && (
                  <AlertComponent type="success">
                    <FormattedMessage
                      id="SUBSCRIPTION.BENEFICIARIES.ADD_SUCCESS"
                      values={{ name: <strong>{this.props.location.state.success}</strong> }}
                    />
                  </AlertComponent>
                )}

              {!!this.props.location.state &&
                !!this.props.location.state.error && (
                  <AlertComponent type="danger">
                    {this.props.location.state.error.type === 'CREATE_FAILED' && (
                      <FormattedMessage id={this.props.location.state.error.payload} />
                    )}
                  </AlertComponent>
                )}
              <ListComponent
                list={this.props.beneficiaries}
                table={SubscriptionBeneficiariesTableComponent}
                tile={SubscriptionBeneficiariesTileComponent}
                props={{
                  benefitOptions,
                  benefits: uniqueBenefits,
                  disabled,
                  location: this.props.location,
                  url: this.props.match.url,
                  products: this.props.products,
                  onBulkEdit: this.props.onBulkEdit,
                  onDownload: this.props.onDownload,
                  onEdit: this.props.onEdit,
                  onModify: () => this.setState({ isModifyOpened: true }),
                }}
              />

              {this.state.isModifyOpened && (
                <SubscriptionBeneficiariesModifyContainer
                  benefitOptions={benefitOptions}
                  onClose={() => this.setState({ isModifyOpened: false })}
                  onSubmit={this.onSubmitModify}
                />
              )}

              <MediaQuery maxWidth={1199}>
                <div className={styles.box}>
                  {disabled || (
                    <ButtonComponent
                      theme="secondary"
                      size="medium"
                      className="ml-0 ml-xl-0 mr-4"
                      to={`${this.props.match.url}/add`}
                    >
                      <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.ADD" />
                    </ButtonComponent>
                  )}
                  <ButtonComponent
                    theme="outline-secondary"
                    size="small"
                    onClick={this.props.onDownload}
                    disabled={this.props.loading || !this.props.beneficiaries.length}
                  >
                    <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.DOWNLOAD" />
                    <i className="fi fi-xlsx-file-format-extension" />
                  </ButtonComponent>

                  {disabled || (
                    <>
                      <ButtonComponent
                        theme="outline-secondary"
                        size="small"
                        onClick={() => this.setState({ isModifyOpened: true })}
                        disabled={this.props.loading || !this.props.beneficiaries.length}
                        className="ml-2"
                      >
                        <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.MODIFY_ALL" />
                      </ButtonComponent>

                      <ButtonComponent
                        theme="outline-success"
                        size="small"
                        onClick={() => this.showConfirmationModal('ACCEPT')}
                        disabled={this.props.loading || !this.props.beneficiaries.length}
                        className="ml-auto"
                      >
                        <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.APPROVE_ALL" />
                      </ButtonComponent>

                      <ButtonComponent
                        theme="outline-danger"
                        size="small"
                        onClick={() => this.showConfirmationModal('REJECT')}
                        disabled={this.props.loading || !this.props.beneficiaries.length}
                        className="ml-2"
                      >
                        <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.REJECT_ALL" />
                      </ButtonComponent>
                    </>
                  )}
                </div>
              </MediaQuery>

              {this.props.pagination}
            </div>
          )}

          <div className="card-footer">
            <ButtonsWrapperComponent>
              <ButtonComponent
                theme="outline-secondary"
                size="large"
                to={`/subscriptions/${this.props.match.params.type}`}
              >
                <i className="fi fi-go-back-left-arrow" /> <FormattedMessage id="SUBSCRIPTION.BACK_TO_LIST" />
              </ButtonComponent>

              {isReadyToOrder &&
                isNotPersonalisedVoucherOrder && (
                  <ButtonComponent
                    theme="primary"
                    size="large"
                    className="ml-auto"
                    to={`/subscriptions/${this.props.match.params.type}/${this.props.match.params.subscription}/order`}
                  >
                    <FormattedMessage id={'SUBSCRIPTION.TABS.ORDER'} />
                    <i className="fi fi-right-arrow-forward" />
                  </ButtonComponent>
                )}
            </ButtonsWrapperComponent>
          </div>
        </CardComponent>

        {this.props.loading || (
          <Switch>
            <Route
              path={`${this.props.match.url}/add`}
              render={() => (
                <AddBeneficiaryContainer
                  subscription={this.props.match.params.subscription}
                  type={this.props.match.params.type}
                  benefitOptions={benefitOptions}
                  beneficiariesList={this.props.beneficiariesList}
                />
              )}
            />
            <Route
              path={`${this.props.match.url}/:beneficiary`}
              render={() => (
                <SubscriptionBeneficiariesDetailsContainer
                  subscription={this.props.match.params.subscription}
                  type={this.props.match.params.type}
                />
              )}
            />
          </Switch>
        )}

        {this.state.isAcceptConfirmationVisible && (
          <ModalComponent
            title={<FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.APPROVE_ALL" />}
            onClose={this.hideConfirmationModal}
            submitButton={
              <ButtonComponent theme="primary" size="medium" onClick={() => this.changeStatus('APPROVED')}>
                <FormattedMessage id="CORE.YES" />
              </ButtonComponent>
            }
          >
            <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.CONFIRM_APPROVE_ALL" tagName="p" />
          </ModalComponent>
        )}
        {this.state.isRejectConfirmationVisible && (
          <ModalComponent
            title={<FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.REJECT_ALL" />}
            onClose={this.hideConfirmationModal}
            submitButton={
              <ButtonComponent theme="primary" size="medium" onClick={() => this.changeStatus('DECLINED')}>
                <FormattedMessage id="CORE.YES" />
              </ButtonComponent>
            }
          >
            <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.CONFIRM_REJECT_ALL" tagName="p" />
          </ModalComponent>
        )}
      </>
    );
  }
}
