// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { HeaderComponent } from 'app/core';
import { MySubscriptionsListContainer } from './list/my-subscriptions-list.container';

export class MySubscriptionsComponent extends Component<any> {
  render() {
    return (
      <>
        <HeaderComponent breadcrumbs={[{ icon: 'subscription' }]}>
          <FormattedMessage id="SIDEBAR.SUBSCRIPTIONS" tagName="h2" />
        </HeaderComponent>

        <MySubscriptionsListContainer />
      </>
    );
  }
}
