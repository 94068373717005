// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';

import { CardComponent, ModalComponent } from 'app/shared';

import { SubscriptionNavContainer } from 'app/subscriptions/nav/subscription-nav.container';
import { SubscriptionOrderPlaceholder } from './placeholder/subscription-order.placeholder';
import { SubscriptionOrderConfirmContainer } from './confirm/subscription-order-confirm.container';
import { SubscriptionOrderSummaryContainer } from './summary/subscription-order-summary.container';
import { SubscriptionOrderSuccessContainer } from './success/subscription-order-success.container';

import type { Props, State, BodyType } from './subscription-order.component.types';

export class SubscriptionOrderComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      step: 'SUMMARY',
    };
  }

  componentDidMount() {
    if (this.props.match.params.order) {
      this.props.fetchOrder();
    } else {
      this.props.onCreate();
    }

    this.props.fetchSubscription();
    this.props.fetchCustomer(this.props.business);
    this.props.fetchDictionary();
  }

  onConfirm = () => this.props.onConfirm(this.props.order.id).then(() => this.setState({ step: 'SUCCESS' }));

  onInvoice = (body: BodyType) =>
    this.props.onSubmit(this.props.order.id, body).then(() => this.setState({ step: 'CONFIRM' }));

  onSubmit = (body: BodyType) => (this.props.order.dueAmount === 0 ? this.onConfirm() : this.onInvoice(body));

  render() {
    const { order } = this.props;
    const upperCasedType = this.props.match.params.type.toUpperCase();

    if (!this.props.loading && this.props.subscription.status === 'ORDERED' && this.props.match.params.order) {
      return (
        <Redirect
          to={{
            pathname: `/orders/${this.props.match.params.order}`,
            state: {
              error: {
                type: 'SUBSCRIPTION_ORDERED',
              },
            },
          }}
        />
      );
    }

    if (this.state.step === 'SUCCESS') {
      return <SubscriptionOrderSuccessContainer />;
    }

    return (
      <>
        <CardComponent header={false}>
          <div className="card-header">
            <SubscriptionNavContainer />
            {(upperCasedType !== 'EMONEY' || this.state.step === 'CONFIRM' || (order && order.dueAmount === 0)) && (
              <h4 className="card-title">
                <FormattedMessage id={`SUBSCRIPTION.ORDER.${upperCasedType}.TITLE`} />
              </h4>
            )}
          </div>
          {this.props.loading && <SubscriptionOrderPlaceholder />}
          {(this.props.loading || this.props.submitting) && (
            <ModalComponent title={<FormattedMessage id={'SUBSCRIPTION.ORDER.PROCESSING'} />} isClosingBlocked />
          )}
          {this.props.loading || (
            <>
              {this.state.step === 'SUMMARY' && (
                <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                  <SubscriptionOrderSummaryContainer submitting={this.props.submitting} />
                </form>
              )}

              {this.state.step === 'CONFIRM' && (
                <form onSubmit={this.props.handleSubmit(this.onConfirm)}>
                  <SubscriptionOrderConfirmContainer
                    onBack={() => this.setState({ step: 'SUMMARY' })}
                    submitting={this.props.submitting}
                  />
                </form>
              )}
            </>
          )}
        </CardComponent>
      </>
    );
  }
}
