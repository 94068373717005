// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { TileComponent } from 'app/shared';

import type { Props } from './beneficiaries-list-tile.component.types';

export class BeneficiariesListTileComponent extends Component<Props> {
  render() {
    return (
      <TileComponent>
        <h3>
          <Link to={`/beneficiaries/${this.props.tile.id}`}>{this.props.tile.name}</Link>
        </h3>

        <div className="row">
          <div className="col">
            <FormattedMessage id="BENEFICIARIES.LIST_HEADERS.EMAIL" tagName="small" />
            {this.props.tile.email}
          </div>
          <div className="col">
            <FormattedMessage id="BENEFICIARIES.LIST_HEADERS.SSN" tagName="small" />
            {this.props.tile.ssn}
          </div>
        </div>
      </TileComponent>
    );
  }
}
