import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { withAuthorization } from 'app/common';
import { withPagination } from 'app/transactions/with-pagination/with-pagination.hoc';

import { fetchTransactions, setCancelledGenerateStatus } from 'app/redux/transactions/transactions.actions';

import { onGenerateTransactionsReport } from '../utils/generate-transactions-report/generate-transactions-report.util';

import { TransactionsVisaComponent } from './transactions-visa.component';

const mapStateToProps = ({ transactions: { visa, generateStatus }, permissions, users: { business, role } }) => ({
  business,
  loading: !visa,
  meta: visa && visa.meta,
  permissions: permissions[business][role] || {},
  transactions: visa && visa.list && visa.list.transactions,
  totalAmount: visa && visa.list && visa.list.totalAmount,
  generateStatus,
});

const mapDispatchToProps = (dispatch, { intl: { locale } }) => ({
  onClose: () => dispatch(setCancelledGenerateStatus()),
  fetchTransactions: params => dispatch(fetchTransactions('visa', params)),
  onDownload: business => onGenerateTransactionsReport(dispatch, 'visa', business, locale),
});

export const TransactionsVisaContainer = compose(
  withAuthorization(['visaTransactions', 'read']),
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withPagination,
)(TransactionsVisaComponent);
