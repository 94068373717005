// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent, NoResultsContainer, TableComponent } from 'app/shared';

import { SubscriptionBeneficiariesStatusComponent } from 'app/subscriptions/beneficiaries/status/subscription-beneficiaries-status.component';

import type { Props } from './cards-list-table.component.types';

export class CardsListTableComponent extends Component<Props> {
  render() {
    return (
      <TableComponent className="table-striped">
        <thead>
          <tr>
            <FormattedMessage id="CARDS.STATUS" tagName="th" />
            <FormattedMessage id="CARDS.NUMBER" tagName="th" />
            <th />
          </tr>
        </thead>
        <tbody>
          {!!this.props.list.length || (
            <tr>
              <td colSpan={3}>
                <NoResultsContainer searchable={false} />
              </td>
            </tr>
          )}
          {this.props.list.map(card => (
            <tr key={card.id}>
              <td>
                <SubscriptionBeneficiariesStatusComponent status={card.active ? 'APPROVED' : 'DECLINED'} />
              </td>
              <td>{card.cardNumber}</td>
              <td className="text-right">
                {card.active ? (
                  <ButtonComponent theme="outline-danger" size="small" onClick={() => this.props.onDisable(card.id)}>
                    <FormattedMessage id="CARDS.REPORT_MISSING_CARD" />
                  </ButtonComponent>
                ) : (
                  <ButtonComponent theme="outline-success" size="small" onClick={() => this.props.onActivate(card.id)}>
                    <FormattedMessage id="CARDS.ACTIVATE_CARD" />
                  </ButtonComponent>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </TableComponent>
    );
  }
}
