// @flow

import React, { Component } from 'react';
import { Field } from 'redux-form';

import { ConnectedInputComponent } from 'app/shared';

import type { Props } from './add-beneficiary-benefits.component.types';

export class AddBeneficiaryBenefitsComponent extends Component<Props> {
  render() {
    return (
      <>
        {this.props.fields.map((field, index) => {
          const label =
            this.props.type === 'voucher'
              ? `${this.props.intl.formatMessage({
                  id: `PRODUCT.${this.props.benefitOptions[index]}`,
                })} (${this.props.intl.formatMessage({ id: 'SUBSCRIPTION.BENEFICIARIES.BOOKS' })})`
              : this.props.intl.formatMessage({ id: `PRODUCT.${this.props.benefitOptions[index]}` });

          return (
            <div className="col-6" key={index}>
              <Field
                name={`${field}.amount`}
                component={ConnectedInputComponent}
                icon={this.props.type === 'emoney' ? 'euro-symbol' : 'stack'}
                label={label}
                required
              />
            </div>
          );
        })}
      </>
    );
  }
}
