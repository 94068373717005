import { RSAA } from 'redux-api-middleware';
import { get } from 'lodash';

import * as types from './dictionaries.action-types';

const getDictionaryEndpoint = (isPublic, path, users) => {
  const business = get(users, 'business');
  let endpoint = isPublic ? `/dictionaries/public/${path}` : `/dictionaries/${path}`;

  if (path === 'invoicing-options') {
    endpoint = endpoint + `/${business}`;
  }

  return endpoint;
};

const fetchDictionaryRSAA = (path, isPublic, { users }) => ({
  [RSAA]: {
    types: [
      types.FETCH_DICTIONARY_REQUEST,
      {
        type: types.FETCH_DICTIONARY_SUCCESS,
        meta: { path },
      },
      {
        type: types.FETCH_DICTIONARY_FAILURE,
        meta: { path },
      },
    ],
    endpoint: getDictionaryEndpoint(isPublic, path, users),
    method: 'GET',
    ...(isPublic ? { isPublic } : {}),
  },
});

const shouldFetchInvoicingOptions = ({ users, permissions }) => {
  const business = get(users, 'business');
  const businesses = get(users, ['current', 'businesses']);
  const businessType = businesses ? businesses.find(b => b.id === business).type : null;
  const companyOrdersPermissions = get(permissions, [business, 'ROLE_COMPANY_BUYER', 'companyOrders', 'read']);

  return businessType === 'TYPE_COMPANY' && companyOrdersPermissions;
};

const shouldFetchDictionary = ({ dictionaries }, dictionary) =>
  !dictionaries[dictionary] || !Object.keys(dictionaries[dictionary]).length;

export const fetchDictionary = (dictionary, isPublic, force = false) => (dispatch, getState) => {
  if (force || shouldFetchDictionary(getState(), dictionary)) {
    if (dictionary === 'invoicing-options') {
      return shouldFetchInvoicingOptions(getState())
        ? dispatch(fetchDictionaryRSAA(dictionary, isPublic, getState()))
        : Promise.resolve();
    }
    return dispatch(fetchDictionaryRSAA(dictionary, isPublic, getState()));
  } else {
    return Promise.resolve();
  }
};
