import classNames from 'classnames';
import React, { Component } from 'react';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { SidebarContainer } from '../sidebar/sidebar.container';

import styles from './mobile-header.module.scss';

export class MobileHeaderComponent extends Component<{}> {
  render() {
    const menuClassNames = classNames(styles.menu, {
      'is-expanded': !this.props.mobileMenuCollapsed,
    });

    return (
      <>
        <div className={styles.header}>
          <div className="row d-flex align-items-center">
            <div className="col d-flex align-items-center">
              <Logo />
            </div>
            <div className="col text-right">
              <button className={styles.collapse} onClick={this.props.toggleMobileMenu}>
                <i className="fi fi-menu" />
              </button>
            </div>
          </div>
        </div>
        {!this.props.mobileMenuCollapsed ? (
          <div className={menuClassNames}>
            <SidebarContainer />
          </div>
        ) : null}
      </>
    );
  }
}
