import React from 'react';
import type { Props } from './venue-select.component.types';
import { FormattedMessage } from 'react-intl';
import { SelectComponent, ButtonComponent, TooltipComponent, LoaderFixedComponent } from 'app/shared';
import styles from './venue-select.module.scss';
// $FlowFixMe
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { useConvertVenueId } from 'app/common';

const VenueSelect = ({ convertIdTo, fetchVenues, loading: loadingVenues, venues, setError }): Props => {
  const {
    isLoading: isLoadingConvertVenueId,
    selectedVenue,
    onVenueChange,
    copyVenueConvertedIdToClipboard,
  } = useConvertVenueId(convertIdTo, {
    setError,
  });

  React.useEffect(() => {
    fetchVenues();
  }, []);

  React.useEffect(() => {
    if (venues && venues[0].id) {
      onVenueChange(venues[0].id);
    }
  }, [venues]);

  if (loadingVenues) {
    return <LoaderFixedComponent />;
  }

  const optionList = venues.map(v => ({ id: v.id, name: v.name }));

  const tooltipOverlay = (
    <div style={{ textAlign: 'center' }}>
      <FormattedMessage id="VENUE_SELECT.COPY_SUCCESS" />
    </div>
  );

  return (
    <div className={styles.container}>
      <div className={styles['flex-1']}>
        <FormattedMessage id="VENUE_SELECT.VENUE" tagName="p" />
        <SelectComponent
          options={optionList}
          onChange={onVenueChange}
          selectedValue={selectedVenue.id}
          searchable={true}
        />
      </div>

      <div className={styles['flex-1']}>
        <p>venue_ID</p>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!isLoadingConvertVenueId ? (
            <span className={styles['flex-1']}>
              <strong>{selectedVenue.convertedId}</strong>
            </span>
          ) : (
            <div className={styles['flex-1']}>
              <div className={styles.loader} />
            </div>
          )}
          <div className={styles['flex-1']}>
            <TooltipComponent placement="top" overlay={tooltipOverlay} trigger={['click']}>
              <ButtonComponent
                disabled={!selectedVenue.convertedId}
                theme="outline-secondary"
                size="small"
                onClick={copyVenueConvertedIdToClipboard}
                className={styles.button}
              >
                <FormattedMessage id="CORE.COPY" /> <CopyIcon />
              </ButtonComponent>
            </TooltipComponent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VenueSelect;
