import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';

import { fetchCurrentUser } from 'app/redux/users/users.actions';
import { fetchTranslations } from 'app/redux/translations/translations.actions';

import { AppComponent } from './app.component';

const mapStateToProps = ({ error, translations, users: { isAuthenticating } }) => ({
  error,
  translations,
  loading: isEmpty(translations.en) || isEmpty(translations.fi) || isEmpty(translations.sv) || !!isAuthenticating,
});

const mapDispatchToProps = dispatch => ({
  fetchCurrentUser: () => dispatch(fetchCurrentUser(true)),
  fetchTranslations: lang => dispatch(fetchTranslations(lang)),
});

export const AppContainer = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(AppComponent);
