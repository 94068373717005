// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, FormSection } from 'redux-form';

import {
  AlertComponent,
  ButtonComponent,
  ConnectedInputComponent,
  ConnectedToggleComponent,
  ConnectedPhoneNumberInputComponent,
} from 'app/shared';

import { SubscriptionSubscribeFormPersonalRadioComponent } from './radio/subscription-subscribe-form-personal-radio.component';

import type { Props } from './subscription-subscribe-form-personal.component.types';
import styles from '../../subscription-subscribe.module.scss';

const nameValidation = value =>
  value && !/^[a-zA-Z\s,À-úÀ-ÿ-]*$/.test(value) ? 'VALIDATION.VALUE_NOT_ALLOWED' : undefined;

export class SubscriptionSubscribeFormPersonalComponent extends Component<Props> {
  render() {
    const options = [
      {
        id: 'yes',
        name: <FormattedMessage id="SUBSCRIPTION.SUBSCRIBE.ACCOUNT" />,
        hasAccount: true,
      },
      {
        id: 'no',
        name: <FormattedMessage id="SUBSCRIPTION.SUBSCRIBE.NO_ACCOUNT" />,
        hasAccount: false,
      },
    ];

    return (
      <>
        <div className="card-body">
          {this.props.isAuthenticated ||
            this.props.type !== 'EMONEY' || (
              <div className="row">
                {options.map((option, index) => (
                  <div className="col-12 col-lg-6 mb-4" key={index}>
                    <SubscriptionSubscribeFormPersonalRadioComponent
                      option={option}
                      onChange={value => this.props.onChangeHasAccount(option.hasAccount)}
                      checked={option.hasAccount === this.props.hasAccount}
                    />
                  </div>
                ))}
              </div>
            )}

          {this.props.hasAccount && (
            <AlertComponent type="light">
              <ButtonComponent theme="primary" size="medium" onClick={this.props.onLogin}>
                <FormattedMessage id="SUBSCRIPTION.SUBSCRIBE.GO_TO_LOGIN" />
              </ButtonComponent>
            </AlertComponent>
          )}

          {!this.props.hasAccount && (
            <>
              <div className={styles.innerHeader}>
                <h4 className="card-title d-flex align-items-center">
                  <FormattedMessage id="SUBSCRIPTION.SUBSCRIBE.PERSONAL_DETAILS" />

                  {this.props.isAuthenticated &&
                    this.props.type === 'EMONEY' && (
                      <ButtonComponent theme="outline-secondary" className="ml-4" onClick={this.props.onLogout}>
                        <FormattedMessage id="SIDEBAR.LOGOUT" />
                      </ButtonComponent>
                    )}
                </h4>
              </div>
              <FormSection name="beneficiary">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <Field
                      name="firstName"
                      component={ConnectedInputComponent}
                      label={this.props.intl.formatMessage({ id: 'PROFILE.FIRST_NAME' })}
                      disabled={this.props.isAuthenticated && this.props.type === 'EMONEY'}
                      validate={nameValidation}
                      required
                    />
                  </div>
                  <div className="col-12 col-lg-6">
                    <Field
                      name="lastName"
                      component={ConnectedInputComponent}
                      label={this.props.intl.formatMessage({ id: 'PROFILE.LAST_NAME' })}
                      disabled={this.props.isAuthenticated && this.props.type === 'EMONEY'}
                      validate={nameValidation}
                      required
                    />
                  </div>
                  {this.props.type === 'EMONEY' && (
                    <div className="col-12 col-lg-6">
                      <Field
                        name="ssn"
                        component={ConnectedInputComponent}
                        label={this.props.intl.formatMessage({ id: 'PROFILE.SSN' })}
                        disabled={this.props.isAuthenticated && this.props.initialValues.beneficiary.ssn}
                        required
                      />
                    </div>
                  )}
                  <div className="col-12 col-lg-6">
                    <Field
                      name="email"
                      component={ConnectedInputComponent}
                      label={this.props.intl.formatMessage({ id: 'PROFILE.EMAIL' })}
                      disabled={this.props.isAuthenticated && this.props.type === 'EMONEY'}
                      required
                    />
                  </div>
                  <div className="col-12 col-lg-6">
                    <Field
                      name="mobilePhone"
                      component={ConnectedPhoneNumberInputComponent}
                      label={this.props.intl.formatMessage({ id: 'PROFILE.MOBILE_PHONE' })}
                      required
                    />
                  </div>
                  {this.props.type === 'EMONEY' && (
                    <div className="col-12 col-lg-6">
                      <Field
                        name="workPhone"
                        component={ConnectedPhoneNumberInputComponent}
                        label={this.props.intl.formatMessage({ id: 'PROFILE.WORK_PHONE' })}
                      />
                    </div>
                  )}
                </div>
                <div className="mt-4">
                  <Field
                    name="marketing"
                    component={ConnectedToggleComponent}
                    normalize={v => !!v}
                    label={this.props.intl.formatMessage({ id: 'PROFILE.MARKETING' })}
                    helpText={this.props.intl.formatMessage({ id: 'PROFILE.MARKETING_INFO' })}
                  />
                </div>
              </FormSection>
            </>
          )}
        </div>
      </>
    );
  }
}
