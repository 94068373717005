// @flow

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import type { Props } from './link.component.types';
import styles from './link.module.scss';

export class LinkComponent extends Component<Props> {
  render() {
    const { title, description, to } = this.props;

    return (
      <NavLink to={to} className={styles.option} activeClassName={styles.checked}>
        <h2 className={styles.title}>
          <FormattedMessage id={title} />
        </h2>
        <p className={styles.description}>
          <FormattedMessage id={description} />
        </p>
        <span className={styles.checkbox} />
      </NavLink>
    );
  }
}
