// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ModalComponent, PlaceholderComponent, SummaryFieldComponent, TableComponent } from 'app/shared';

import type { Props } from './order-details.placeholder.types';

export class OrderDetailsPlaceholder extends Component<Props> {
  static defaultProps = {
    products: [...new Array(2)],
  };

  render() {
    return (
      <ModalComponent onClose={this.props.onClose} title={<FormattedMessage id="ORDER_HISTORY.ORDER_INFORMATION" />}>
        <div className="row">
          <div className="col-md-6">
            <SummaryFieldComponent>
              <FormattedMessage id="ORDER_HISTORY.NUMBER" tagName="strong" />
              <PlaceholderComponent width={80} />
            </SummaryFieldComponent>
          </div>
          <div className="col-md-6">
            <SummaryFieldComponent>
              <FormattedMessage id="ORDER_HISTORY.DATE" tagName="strong" />
              <PlaceholderComponent width={100} />
            </SummaryFieldComponent>
          </div>
          <div className="col-md-6">
            <SummaryFieldComponent>
              <FormattedMessage id="ORDER_HISTORY.STATUS" tagName="strong" />
              <PlaceholderComponent width={80} />
            </SummaryFieldComponent>
          </div>
          <div className="col-md-6">
            <SummaryFieldComponent>
              <FormattedMessage id="ORDERS.INVOICING_METHOD" tagName="strong" />
              <PlaceholderComponent width={120} />
            </SummaryFieldComponent>
          </div>
        </div>
        <TableComponent className="table-striped">
          <tbody>
            {this.props.products.map((_, product) => (
              <tr key={product}>
                <td>
                  <PlaceholderComponent width={product % 2 === 0 ? 160 : 200} />
                </td>
                <td />
                <td className="text-right">
                  <PlaceholderComponent width={100} />
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={2}>
                <FormattedMessage id="ORDERS.ORDER_TOTAL" />
              </td>
              <td className="text-right">
                <PlaceholderComponent width={100} />
              </td>
            </tr>
          </tfoot>
        </TableComponent>
      </ModalComponent>
    );
  }
}
