import moment from 'moment';

export const getFirstAndLastDatesOfCurrentMonth = () => {
  const firstDate = moment()
    .startOf('month')
    .format('YYYY-MM-DD');
  const lastDate = moment()
    .endOf('month')
    .format('YYYY-MM-DD');

  return {
    firstDate,
    lastDate,
  };
};
