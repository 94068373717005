// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { SubscriptionNavItemComponent } from './item/subscription-nav-item.component';

import type { Props } from './subscription-nav.component.types';
import styles from './subscription-nav.module.scss';

export class SubscriptionNavComponent extends Component<Props> {
  shouldDisableOrder = () => {
    return (
      this.props.loading ||
      this.props.subscription.status === 'ORDERED' ||
      this.props.subscription.summary.beneficiaries.new !== 0 ||
      this.props.subscription.summary.beneficiaries.declined === this.props.subscription.summary.beneficiaries.total
    );
  };

  renderSummaryNavComponent = () => (
    <SubscriptionNavItemComponent
      disabled={this.props.loading}
      to={`/subscriptions/${this.props.match.params.type}/${this.props.match.params.subscription}`}
      exact
    >
      <i className="fi fi-signs" />
      <FormattedMessage id="SUBSCRIPTION.TABS.SHARE" />
    </SubscriptionNavItemComponent>
  );

  render() {
    return (
      <div className={styles.tabs}>
        {!!this.props.match.params.subscription || (
          <SubscriptionNavItemComponent to={`/subscriptions/${this.props.match.params.type}/create`}>
            <i className="fi fi-signs" />
            <FormattedMessage id="SUBSCRIPTION.TABS.CREATE" />
          </SubscriptionNavItemComponent>
        )}

        {this.props.edit ||
          !this.props.match.params.subscription || (
            <SubscriptionNavItemComponent
              disabled={true}
              to={`/subscriptions/${this.props.match.params.type}/${this.props.match.params.subscription}`}
              exact
            >
              <i className="fi fi-signs" />
              <FormattedMessage id="SUBSCRIPTION.TABS.CREATE" />
            </SubscriptionNavItemComponent>
          )}

        {this.props.edit || this.renderSummaryNavComponent()}

        {this.props.edit && (
          <SubscriptionNavItemComponent
            to={`/subscriptions/${this.props.match.params.type}/${this.props.match.params.subscription}/edit`}
          >
            <i className="fi fi-signs" />
            <FormattedMessage id="SUBSCRIPTION.TABS.EDIT" />
          </SubscriptionNavItemComponent>
        )}

        {!this.props.edit || this.renderSummaryNavComponent()}

        <SubscriptionNavItemComponent
          disabled={this.props.loading}
          to={`/subscriptions/${this.props.match.params.type}/${this.props.match.params.subscription}/beneficiaries`}
        >
          <i className="fi fi-business" />
          <FormattedMessage id="SUBSCRIPTION.TABS.BENEFICIARIES" />
        </SubscriptionNavItemComponent>

        {this.props.match.params.type !== 'voucher' && (
          <SubscriptionNavItemComponent
            disabled={this.shouldDisableOrder()}
            blocked={this.props.match.params.order}
            to={`/subscriptions/${this.props.match.params.type}/${this.props.match.params.subscription}/order`}
          >
            <i className="fi fi-shopping-cart" />
            <FormattedMessage id="SUBSCRIPTION.TABS.ORDER" />
          </SubscriptionNavItemComponent>
        )}
      </div>
    );
  }
}
