// @flow

import React, { Component } from 'react';

import { FormattedMessage } from 'react-intl';

import { ListComponent } from 'app/shared/list/list.component';
import { CardComponent } from 'app/shared';

import { SubscriptionsListPlaceholder } from './placeholder/subscriptions-list.placeholder';

import { InitiateSubscriptionContainer } from '../initiate/initiate-subscription.container';

import { SubscriptionsListTileComponent } from './tile/subscriptions-list-tile.component';
import { SubscriptionsListTableComponent } from './table/subscriptions-list-table.component';

import type { Props } from './subscriptions-list.component.types';

export class SubscriptionsListComponent extends Component<Props> {
  componentDidUpdate(prevProps: Props) {
    if (
      (prevProps.business !== this.props.business && !this.props.subscriptions) ||
      (prevProps.match.params.type !== this.props.match.params.type && !this.props.subscriptions)
    ) {
      this.props.fetch();
    }
  }

  render() {
    const paramsType = this.props.match.params.type;
    const upperCasedType = paramsType.toUpperCase();

    return (
      <>
        {paramsType === 'voucher' ? (
          <CardComponent header={false}>
            <div className="card-header">
              <h4 className="card-title">
                <FormattedMessage id={`SUBSCRIPTION.PERSONALIZED_VOUCHERS_REMOVAL.TITLE`} />
              </h4>
            </div>

            <div className="card-body">
              <FormattedMessage id={`SUBSCRIPTION.PERSONALIZED_VOUCHERS_REMOVAL.DESC`} />
            </div>
          </CardComponent>
        ) : (
          <InitiateSubscriptionContainer type={paramsType} />
        )}

        {this.props.loading && <SubscriptionsListPlaceholder type={upperCasedType} />}
        {this.props.loading || (
          <CardComponent>
            <div className="card-header">
              <h4 className="card-title">
                <FormattedMessage id={`SUBSCRIPTION.${upperCasedType}.LIST`} />
              </h4>
            </div>
            <div className="card-body">
              <ListComponent
                list={this.props.subscriptions}
                table={SubscriptionsListTableComponent}
                tile={SubscriptionsListTileComponent}
                props={{ type: paramsType }}
              />

              {this.props.pagination}
            </div>
          </CardComponent>
        )}
      </>
    );
  }
}
