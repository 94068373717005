import { compose } from 'redux';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { get } from 'lodash';

import { withInvoicingOptions } from 'app/orders/with-invoicing-options/with-invoicing-options.hoc';
import { BanknotesOrderConfirmComponent } from './banknotes-order-confirm.component';

const mapStateToProps = ({ dictionaries, form }) => ({
  products: get(dictionaries, ['products/banknote', 'products']),
  values: getFormValues('order-banknotes')({ form }),
});

export const BanknotesOrderConfirmContainer = compose(
  connect(mapStateToProps),
  withInvoicingOptions,
)(BanknotesOrderConfirmComponent);
