import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { withPagination, withAuthorization } from 'app/common';
import { withCoreContext } from 'app/core/core.context';

import { fetchAvailableVenues, clearVenues } from 'app/redux/venues/venues.actions';

import { NewContractComponent } from './new-contract.component';

const mapStateToProps = ({ form, venues: { list: venues, meta }, users: { business, role } }) => ({
  business,
  venues,
  meta,
  loading: !venues,
  waitForFetch: true,
  availableForms: form,
  role,
});

const mapDispatchToProps = (dispatch, { intl: { locale } }, business) => ({
  fetch: params => dispatch(fetchAvailableVenues(params)),
  clearVenues: () => dispatch(clearVenues()),
});

export const NewContractContainer = compose(
  withRouter,
  injectIntl,
  withAuthorization(['contracts', 'create']),
  withCoreContext,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withPagination,
)(NewContractComponent);
