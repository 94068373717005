// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { ButtonComponent, NoResultsContainer, TableComponent } from 'app/shared';

import { currencyFormatter, dateFormatter } from 'app/utils';

import type { Props, State } from './subscriptions-list-table.component.types';

import styles from './subscriptions-list-table.module.scss';

const buttonThemeDict = {
  ORDERED: 'not-active',
  VERIFY_AND_ORDER: 'tertiary',
  VERIFY: 'tertiary',
};

export class SubscriptionsListTableComponent extends Component<Props, State> {
  state = { hoveredButtonId: -1 };

  onMouseEnterHandler = (id: number) => {
    this.setState({ hoveredButtonId: id });
  };

  onMouseLeaveHandler = () => {
    this.setState({ hoveredButtonId: -1 });
  };

  render() {
    return (
      <TableComponent className="table-striped">
        <thead>
          <tr>
            <FormattedMessage id="SUBSCRIPTION.LIST_HEADERS.NAME" tagName="th" />
            <FormattedMessage id="SUBSCRIPTION.LIST_HEADERS.CLOSING_TIME" tagName="th" />
            <FormattedMessage id="SUBSCRIPTION.LIST_HEADERS.APPROVED_SUBSCRIBED" tagName="th" />
            <FormattedMessage id="SUBSCRIPTION.LIST_HEADERS.TOTAL" tagName="th" />
          </tr>
        </thead>
        <tbody>
          {!!this.props.list.length || (
            <tr>
              <td colSpan={5}>
                <NoResultsContainer searchable={false} />
              </td>
            </tr>
          )}

          {this.props.list.map(subscription => {
            const buttonTheme = buttonThemeDict[subscription.status] || 'secondary';
            const isOrdered = subscription.status === 'ORDERED';
            const isButtonHovered = this.state.hoveredButtonId === subscription.id;
            return (
              <tr key={subscription.id}>
                <td>
                  <Link to={`/subscriptions/${this.props.type}/${subscription.id}`}>{subscription.title}</Link>
                </td>
                <td>
                  {dateFormatter(subscription.subscription.subscriptionTo)}
                  <br />
                  {subscription.subscription.distributionDay && (
                    <FormattedMessage
                      id="SUBSCRIPTION.RECURSIVE_DAY"
                      values={{ day: subscription.subscription.distributionDay }}
                      tagName="small"
                    />
                  )}
                </td>
                <td>
                  <span className={styles.green}>{subscription.summary.beneficiaries.approved}</span> /{' '}
                  <span className={styles.red}>{subscription.summary.beneficiaries.total}</span>
                </td>
                <td>
                  {subscription.summary.beneficiaries.approved > 0
                    ? currencyFormatter.format(subscription.summary.totalAmount)
                    : '-'}
                </td>
                <td className="text-right">
                  <span
                    onMouseEnter={() => {
                      this.onMouseEnterHandler(subscription.id);
                    }}
                    onMouseLeave={this.onMouseLeaveHandler}
                  >
                    <ButtonComponent
                      theme={buttonTheme}
                      size="small"
                      to={`/subscriptions/${this.props.type}/${subscription.id}/beneficiaries`}
                    >
                      {(!isOrdered || (isOrdered && !isButtonHovered)) && (
                        <FormattedMessage id={`SUBSCRIPTION.STATUS.${subscription.status}`} />
                      )}
                      {isOrdered && isButtonHovered && <FormattedMessage id={`SUBSCRIPTION.ORDER.VIEW`} />}
                    </ButtonComponent>
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </TableComponent>
    );
  }
}
