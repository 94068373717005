// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { FieldArray } from 'redux-form';

import { OrdersCreateDeliveryContainer } from 'app/orders/create/form/delivery/order-create-delivery.container';
import { OrdersCreateInvoicingContainer } from 'app/orders/create/form/invoicing/orders-create-invoicing.container';
import { OrdersCreateProductsContainer } from 'app/orders/create/form/products/orders-create-products.container';

import { AlertComponent, ButtonComponent, ButtonsWrapperComponent } from 'app/shared';

import type { Props } from './banknotes-order-form.component.types';
import type { Product } from 'app/cards/beneficiaries/confirm/cards-beneficiaries-order-confirm.component.types';

export class BanknotesOrderFormComponent extends Component<Props> {
  productsMapper = ({ amount, id }: Product) => ({
    amount,
    id,
    name: this.props.intl.formatMessage({ id: `PRODUCT.${id}` }),
  });

  render() {
    const hasInvoicigMethod =
      this.props.form && this.props.form.values && !this.props.form.values.invoicing.invoicingMethod;

    return (
      <>
        <div className="card-header">
          <h4 className="card-title">
            <FormattedMessage id="BANKNOTES.BREADCRUMB" />
          </h4>
        </div>
        <div className="card-body">
          <AlertComponent type="light" className="text-left">
            <FormattedMessage id="BANKNOTES.DESC" />
          </AlertComponent>
          <FieldArray
            name="products"
            component={OrdersCreateProductsContainer}
            accounts={this.props.products.map(this.productsMapper)}
            banknotesProducts={this.props.products}
          />
        </div>
        <div className="card-header card-header--between">
          <h4 className="card-title">
            <FormattedMessage id="ORDERS.INVOICING_DETAILS" />
          </h4>
        </div>
        <div className="card-body">
          <OrdersCreateInvoicingContainer form="order-banknotes" type="banknotesOrders" />
        </div>

        <div className="card-header card-header--between">
          <h4 className="card-title">
            <FormattedMessage id="ORDERS.DELIVERY_DETAILS" />
          </h4>
        </div>
        <div className="card-body">
          <OrdersCreateDeliveryContainer />
        </div>

        <div className="card-footer">
          <ButtonsWrapperComponent>
            <ButtonComponent
              theme="primary"
              size="large"
              className="ml-auto"
              loading={this.props.submitting}
              disabled={hasInvoicigMethod}
            >
              <FormattedMessage id="CORE.NEXT" />
            </ButtonComponent>
          </ButtonsWrapperComponent>
        </div>
      </>
    );
  }
}
