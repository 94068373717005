export const FETCH_REPORTS_REQUEST = 'reports/FETCH_REPORTS_REQUEST';
export const FETCH_REPORTS_SUCCESS = 'reports/FETCH_REPORTS_SUCCESS';
export const FETCH_REPORTS_FAILURE = 'reports/FETCH_REPORTS_FAILURE';

export const FETCH_REPORT_REQUEST = 'reports/FETCH_REPORT_REQUEST';
export const FETCH_REPORT_SUCCESS = 'reports/FETCH_REPORT_SUCCESS';
export const FETCH_REPORT_FAILURE = 'reports/FETCH_REPORT_FAILURE';

export const FETCH_YEARLY_REPORT_REQUEST = 'reports/FETCH_YEARLY_REPORT_REQUEST';
export const FETCH_YEARLY_REPORT_SUCCESS = 'reports/FETCH_YEARLY_REPORT_SUCCESS';
export const FETCH_YEARLY_REPORT_FAILURE = 'reports/FETCH_YEARLY_REPORT_FAILURE';
