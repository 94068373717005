// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

import { HeaderComponent } from 'app/core';
import { CardComponent } from 'app/shared';
import { SummaryFieldComponent, SummaryButtonsComponent } from 'app/register/shared';

import type { Props } from './register-company-summary.component.types.flow';

export class RegisterCompanySummaryComponent extends Component<Props> {
  props: Props;

  render() {
    const {
      company: { name, nameExtra, businessId, phone, officialAddress, billingAddress, deliveryAddress },
      personal: { firstName, lastName, email, marketing, mobilePhone, workPhone },
      changeStep,
      submitting,
    } = this.props;

    return (
      <section>
        <HeaderComponent
          breadcrumbs={[
            {
              icon: 'home-icon-silhouette',
            },
            {
              name: <FormattedMessage id="REGISTER.TITLE" />,
              onClick: () => changeStep('FORM'),
            },
            {
              name: <FormattedMessage id="REGISTER.SUMMARY" />,
            },
          ]}
        >
          <FormattedMessage id="REGISTER.SUMMARY_TITLE" tagName="h2" />
        </HeaderComponent>

        <CardComponent>
          <div className="card-header">
            <h4 className="card-title">
              <FormattedMessage id="REGISTER.SECTION_TITLES.BASIC_INFORMATION" />
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="REGISTER.COMPANY_NAME" tagName="h3" />
                <h4>{name}</h4>
              </SummaryFieldComponent>

              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="REGISTER.BUSINESS_ID" tagName="h3" />
                <h4>{businessId}</h4>
              </SummaryFieldComponent>

              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="REGISTER.COMPANY_NAME_SECOND" tagName="h3" />
                <h4>{nameExtra || '-'}</h4>
              </SummaryFieldComponent>

              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="REGISTER.PHONE" tagName="h3" />
                <h4>{phone}</h4>
              </SummaryFieldComponent>
            </div>
          </div>
          <div className="card-header card-header--between">
            <h4 className="card-title">
              <FormattedMessage id="REGISTER.SECTION_TITLES.OFFICIAL_ADDRESS" />
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="REGISTER.FULL_ADDRESS" tagName="h3" />
                <h4>{`${officialAddress.city}, ${officialAddress.address}, ${officialAddress.zipCode}`}</h4>
              </SummaryFieldComponent>
            </div>
          </div>
          <div className="card-header card-header--between">
            <h4 className="card-title">
              <FormattedMessage id="REGISTER.SECTION_TITLES.BILLING_ADDRESS" />
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              {!billingAddress || isEmpty(billingAddress) ? (
                <SummaryFieldComponent className="col-lg-6">
                  <FormattedMessage id="REGISTER.SAME_AS_OFFICIAL_ADDRESS" tagName="h4" />
                </SummaryFieldComponent>
              ) : (
                <>
                  <SummaryFieldComponent className="col-lg-6">
                    <FormattedMessage id="REGISTER.ADDRESSEE" tagName="h3" />
                    <h4>{billingAddress.addressee}</h4>
                  </SummaryFieldComponent>
                  <SummaryFieldComponent className="col-lg-6">
                    <FormattedMessage id="REGISTER.ADDRESS" tagName="h3" />
                    <h4>
                      {[billingAddress.city, billingAddress.address, billingAddress.zipCode]
                        .filter(x => !!x)
                        .join(', ')}
                    </h4>
                  </SummaryFieldComponent>
                </>
              )}
            </div>
          </div>
          <div className="card-header card-header--between">
            <h4 className="card-title">
              <FormattedMessage id="REGISTER.SECTION_TITLES.DELIVERY_ADDRESS" />
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              {!deliveryAddress || isEmpty(deliveryAddress) ? (
                <SummaryFieldComponent className="col-lg-6">
                  <FormattedMessage id="REGISTER.SAME_AS_OFFICIAL_ADDRESS" tagName="h4" />
                </SummaryFieldComponent>
              ) : (
                <SummaryFieldComponent className="col-lg-6">
                  <FormattedMessage id="REGISTER.ADDRESS" tagName="h3" />
                  <h4>
                    {[deliveryAddress.city, deliveryAddress.address, deliveryAddress.zipCode]
                      .filter(x => !!x)
                      .join(', ')}
                  </h4>
                </SummaryFieldComponent>
              )}
            </div>
          </div>
          <div className="card-header card-header--between card-header--with-line">
            <h4 className="card-title">
              <FormattedMessage id="REGISTER.SECTION_TITLES.PERSONAL_INFORMATION" />
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="PROFILE.FULL_NAME" tagName="h3" />
                <h4>{`${firstName} ${lastName}`}</h4>
              </SummaryFieldComponent>

              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="PROFILE.MOBILE_PHONE" tagName="h3" />
                <h4>{mobilePhone}</h4>
              </SummaryFieldComponent>

              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="PROFILE.EMAIL" tagName="h3" />
                <h4>{email}</h4>
              </SummaryFieldComponent>

              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="PROFILE.WORK_PHONE" tagName="h3" />
                <h4>{workPhone || '-'}</h4>
              </SummaryFieldComponent>

              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="REGISTER.MARKETING" tagName="h3" />
                <h4>
                  {marketing ? (
                    <FormattedMessage id="REGISTER.SELECTED_MARKETING" />
                  ) : (
                    <FormattedMessage id="REGISTER.NOT_SELECTED_MARKETING" />
                  )}
                </h4>
              </SummaryFieldComponent>
            </div>
          </div>
          <div className="card-body card-body--between">
            <SummaryButtonsComponent changeStep={changeStep} submitting={submitting} />
          </div>
        </CardComponent>
      </section>
    );
  }
}
