import { RSAA } from 'redux-api-middleware';

import * as types from './contracts.action-types';
import { USER_ROLES } from 'app/constants';

export const fetchContracts = params => (dispatch, getState) => {
  const {
    users: { business, role },
  } = getState();

  let order = {
    sortBy: 'id',
    order: 'desc',
  };

  if (role === USER_ROLES.ROLE_COMPANY_BENEFICIARY) {
    order = {
      sortBy: 'vendorName',
      order: 'asc',
    };
  }

  return dispatch({
    [RSAA]: {
      types: [
        {
          type: types.FETCH_CONTRACTS_REQUEST,
          meta: {
            business,
            params: {
              ...params,
              ...order,
            },
          },
        },
        types.FETCH_CONTRACTS_SUCCESS,
        types.FETCH_CONTRACTS_FAILURE,
      ],
      endpoint: `/customers/${business}/contracts`,
      method: 'GET',
    },
  });
};

export const createContract = (business, body) => ({
  [RSAA]: {
    types: [types.CREATE_CONTRACT_REQUEST, types.CREATE_CONTRACT_SUCCESS, types.CREATE_CONTRACT_FAILURE],
    endpoint: `/customers/${business}/contracts`,
    method: 'POST',
    body,
  },
});

export const editContract = (business, body) => ({
  [RSAA]: {
    types: [types.EDIT_CONTRACT_REQUEST, types.EDIT_CONTRACT_SUCCESS, types.EDIT_CONTRACT_FAILURE],
    endpoint: `/customers/${business}/contracts/${body.contractId}`,
    method: 'PATCH',
    body,
  },
});

export const terminateContract = (business, body) => ({
  [RSAA]: {
    types: [types.TERMINATE_CONTRACT_REQUEST, types.TERMINATE_CONTRACT_SUCCESS, types.TERMINATE_CONTRACT_FAILURE],
    endpoint: `/customers/${business}/contracts/${body.contractId}/terminate`,
    method: 'PATCH',
    body,
  },
});

export const createContractBatch = (business, body) => ({
  [RSAA]: {
    types: [
      types.CREATE_CONTRACT_BATCH_REQUEST,
      types.CREATE_CONTRACT_BATCH_SUCCESS,
      types.CREATE_CONTRACT_BATCH_FAILURE,
    ],
    endpoint: `/customers/${business}/contracts/batch`,
    method: 'POST',
    body,
  },
});
