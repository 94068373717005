import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redux';

import { withAuthorization, withPagination } from 'app/common';
import { download } from 'app/redux/downloads/downloads.actions';
import { fetchReports } from 'app/redux/reports/reports.actions';

import { ReportsListComponent } from './reports-list.component';

const mapStateToProps = ({ reports: { list: reports, meta }, users: { business } }) => ({
  business,
  reports,
  meta,
  loading: !reports,
});

const mapDispatchToProps = (dispatch, { intl: { locale } }) => ({
  fetch: params => dispatch(fetchReports(params)),
  updateBusiness: async () => {
    await dispatch(push({ search: '' }));
    return dispatch(fetchReports());
  },
  onDownload: business => dispatch(download(`reports/${business}`, locale)),
});

export const ReportsListContainer = compose(
  withAuthorization(['companyOrders', 'read']),
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withPagination,
)(ReportsListComponent);
