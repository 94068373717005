// @flow

import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AccountsComponent } from 'app/accounts/accounts.component';
import { AgreementsComponent } from 'app/agreements/agreements.component';
import { BanknotesComponent } from 'app/banknotes/banknotes.component';
import { BeneficiariesComponent } from 'app/beneficiaries/beneficiaries.component';
import { BeneficiaryComponent } from 'app/beneficiary/beneficiary.component';
import { CardsComponent } from 'app/cards/cards.component';
import { CustomerInformationContainer } from 'app/customer-information/customer-information.container';
import { OrdersComponent } from 'app/orders/orders.component';
import { ReportsContainer } from 'app/reports/reports.container';
import { PaymentsComponent } from 'app/payments/payments.component';
import { ProfileContainer } from 'app/profile/profile.container';
import { SettlementsComponent } from 'app/settlements/settlements.component';
import { YearlyLimitsContainer } from 'app/yearly-limits/yearly-limits.container';
import { SubscriptionsContainer } from 'app/subscriptions/subscriptions.container';
import { TransactionsComponent } from 'app/transactions/transactions.component';
import { UsersContainer } from 'app/users/users.container';
import { VenuesComponent } from 'app/venues/venues.component';
import { VouchersComponent } from 'app/vouchers/vouchers.component';
import { OnlinePaymentComponent } from 'app/online-payment/online-payment.component';
import { SmsPaymentComponent } from 'app/sms-payment/sms-payment.component';
import { SmartumPayComponent } from 'app/smartum-pay/smartum-pay.component';
import { MetabaseDashboardContainer } from 'app/metabase-dashboard/metabase-dashboard.container';
import { MetabaseDownloadPDFContainer } from 'app/metabase-download-pdf/metabase-download-pdf.container';
import { ContractsComponent } from 'app/contracts/contracts.component';

import { ConfirmContainer } from './confirm/confirm.container';
import { TimeoutContainer } from './timeout/timeout.container';

import { SplashComponent, ThemeComponent } from 'app/core';
import { CoreContext } from './core.context';
import { USER_ROLES } from 'app/constants';

import type { Props, State } from './core.component.types';

export class CoreComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      sidebarCollapsed: localStorage.getItem('sidebarCollapsed') === 'true',
      toggleSidebar: this.toggleSidebar,
      mobileMenuCollapsed: true,
      toggleMobileMenu: this.toggleMobileMenu,
    };
  }

  componentDidMount() {
    if (this.props.authenticated) {
      this.props.fetchPermissions();
      if (this.props.role === USER_ROLES.ROLE_VENUE_MANAGER) {
        this.props.fetchVenueContracts({ benefitType: 'MASSAGE' });
      }
    }

    if (!this.state.sidebarCollapsed) {
      if (document.body) {
        document.body.classList.add('sidebar-open');
      }
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState(
        state => ({
          mobileMenuCollapsed: true,
        }),
        () => {
          if (document.body) {
            document.body.classList.remove('mobileMenu-open');
          }
        },
      );
    }
    if (prevProps.role !== this.props.role || prevProps.business !== this.props.business) {
      if (this.props.role === USER_ROLES.ROLE_VENUE_MANAGER) {
        this.props.fetchVenueContracts({ benefitType: 'MASSAGE' });
      }
    }
  }

  toggleSidebar = () =>
    this.setState(
      state => ({
        sidebarCollapsed: !state.sidebarCollapsed,
      }),
      () => {
        if (document.body) {
          document.body.classList.toggle('sidebar-open');
        }

        try {
          localStorage.setItem('sidebarCollapsed', this.state.sidebarCollapsed.toString());
        } catch (err) {}
      },
    );

  toggleMobileMenu = () =>
    this.setState(
      state => ({
        mobileMenuCollapsed: !state.mobileMenuCollapsed,
      }),
      () => {
        if (document.body) {
          document.body.classList.toggle('mobileMenu-open');
        }
      },
    );

  render() {
    if (!this.props.authenticated) {
      return (
        <Redirect
          to={{
            pathname: '/login',
          }}
        />
      );
    }

    if (this.props.loading) {
      return <SplashComponent />;
    }

    return (
      <CoreContext.Provider value={this.state}>
        <ThemeComponent>
          <Switch>
            <Route path="/accounts" component={AccountsComponent} />
            <Route path="/terms" component={AgreementsComponent} />
            <Route path="/customer-information" component={CustomerInformationContainer} />
            <Route path="/banknotes" component={BanknotesComponent} />
            <Route path="/beneficiaries" component={BeneficiariesComponent} />
            <Route path="/beneficiary" component={BeneficiaryComponent} />
            <Route path="/cards" component={CardsComponent} />
            <Route path="/orders" component={OrdersComponent} />
            <Route path="/reports" component={ReportsContainer} />
            <Route path="/payments" component={PaymentsComponent} />
            <Route path="/profile" component={ProfileContainer} />
            <Route path="/settlements" component={SettlementsComponent} />
            <Route path="/yearly-limits" component={YearlyLimitsContainer} />
            <Route path="/subscriptions" component={SubscriptionsContainer} />
            <Route path="/transactions" component={TransactionsComponent} />
            <Route path="/vouchers" component={VouchersComponent} />
            <Route path="/users" component={UsersContainer} />
            <Route path="/venues" component={VenuesComponent} />
            <Route path="/smartumpay" component={SmartumPayComponent} />
            <Route path="/online-payment" component={OnlinePaymentComponent} />
            <Route path="/sms-payment" component={SmsPaymentComponent} />
            <Route path="/metabase-dashboard" component={MetabaseDashboardContainer} />
            <Route path="/metabase-download-pdf" component={MetabaseDownloadPDFContainer} />
            <Route path="/contracts" component={ContractsComponent} />
          </Switch>
        </ThemeComponent>
        <ConfirmContainer />
        <TimeoutContainer />
      </CoreContext.Provider>
    );
  }
}
