// @flow

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { EditVenueContainer } from 'app/venues/edit/edit-venue.container';
import { AddVenueContainer } from 'app/venues/add/add-venue.container';
import { VenueUsersContainer } from 'app/venues/users/venue-users.container';
import { VenuesListContainer } from 'app/venues/list/venues-list.container';

import type { Props } from './venues.component.types';

export class VenuesComponent extends Component<Props> {
  render() {
    return (
      <Switch>
        <Route path={`${this.props.match.url}/:id/edit`} component={EditVenueContainer} />
        <Route path={`${this.props.match.url}/add`} component={AddVenueContainer} />
        <Route path={`${this.props.match.url}/:id/users`} component={VenueUsersContainer} />
        <Route path={this.props.match.url} component={VenuesListContainer} exact />
      </Switch>
    );
  }
}
