import { compose } from 'redux';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { injectIntl } from 'react-intl';

import { RegisterServiceSummaryComponent } from './register-service-summary.component';

const mapStateToProps = state => ({
  ...getFormValues('register-service')(state),
  payments: state.dictionaries['services'].payments,
  servicesDictionary: state.dictionaries['services'].services,
});

export const RegisterServiceSummaryContainer = compose(
  injectIntl,
  connect(mapStateToProps),
)(RegisterServiceSummaryComponent);
