import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { getFormValues } from 'redux-form';
import { get } from 'lodash';

import { cancelOrder } from 'app/redux/orders/orders.actions';

import { OrdersCreateSummaryComponent } from './orders-create-summary.component';

const mapStateToProps = ({ form, orders: { entities: orders, orderData } }, { order }) => ({
  canceling: get(orders, [order, 'canceling']),
  values: getFormValues('create-order')({ form }),
  order: orderData,
});

const mapDispatchToProps = (dispatch, { cancelPath, order }) => ({
  onCancel: () => dispatch(cancelOrder(order)).then(() => dispatch(push(cancelPath))),
});

export const OrdersCreateSummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrdersCreateSummaryComponent);
