// @flow

import React, { Component } from 'react';

import { TransactionsLatestTablePlaceholder } from './table/transactions-latest-table.placeholder';
import { TransactionsLatestTilePlaceholder } from './tile/transactions-latest-tile.placeholder';

import { ListComponent } from 'app/shared/list/list.component';

import type { Props } from './transactions-latest.placeholder.types';

export class TransactionsLatestPlaceholder extends Component<Props> {
  static defaultProps = {
    transactions: [...new Array(5)],
  };

  render() {
    return (
      <>
        <div className="card-body">
          <ListComponent
            list={this.props.transactions}
            table={TransactionsLatestTablePlaceholder}
            tile={TransactionsLatestTilePlaceholder}
          />
        </div>
      </>
    );
  }
}
