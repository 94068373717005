import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';

import { withAuthorization, withPermissions } from 'app/common';

import { fetchVenues, clearVenues } from 'app/redux/venues/venues.actions';
import { terminateContract } from 'app/redux/customers/customers.actions';

import { VenuesListComponent } from './venues-list.component';

const mapStateToProps = ({ venues: { list: venues }, users: { business } }) => ({
  venues,
  loading: !venues,
  business,
});

const mapDispatchToProps = dispatch => ({
  fetchVenues: () => dispatch(fetchVenues()),
  clearVenues: () => dispatch(clearVenues()),
  onTerminateContract: (customerId, body) => dispatch(terminateContract(customerId, body)),
});

export const VenuesListContainer = compose(
  withAuthorization(['venues', 'read']),
  injectIntl,
  withPermissions,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'terminate-contract',
  }),
)(VenuesListComponent);
