// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

import type { Props } from './sidebar-user-role.component.types';
import styles from './sidebar-user-role.module.scss';

export class SidebarUserRoleComponent extends Component<Props> {
  render() {
    const roleClassNames = classNames(styles.role, {
      [styles.active]: this.props.role.value === this.props.selected,
    });

    return (
      <button className={roleClassNames} onClick={this.props.onSelect}>
        <span className={styles.name}>{this.props.role.label}</span>

        <span>
          {this.props.intl.formatMessage({
            id: this.props.role.value === this.props.selected ? 'SIDEBAR.ACTIVE' : 'SIDEBAR.SELECT',
          })}
        </span>
      </button>
    );
  }
}
