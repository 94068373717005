import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redux';

import { SubscriptionBeneficiariesDetailsComponent } from './subscription-beneficiaries-details.component';

const mapStateToProps = (
  { dictionaries, subscriptions: { beneficiaries } },
  {
    match: {
      params: { beneficiary },
    },
    subscription,
    type,
  },
) => ({
  beneficiary: beneficiaries[subscription].list.find(({ id }) => id === Number(beneficiary)),
  multipliers: type === 'voucher' ? dictionaries['products/voucher'].multipliers : null,
});

const mapDispatchToProps = (dispatch, { location: { search }, subscription, type }) => ({
  onClose: () =>
    dispatch(
      push({
        search,
        pathname: `/subscriptions/${type}/${subscription}/beneficiaries`,
        state: {
          keepScrollPosition: true,
        },
      }),
    ),
});

export const SubscriptionBeneficiariesDetailsContainer = compose(
  withRouter,
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SubscriptionBeneficiariesDetailsComponent);
