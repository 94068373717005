// @flow

import React, { createContext } from 'react';
import type { ComponentType } from 'react';

export const CoreContext = createContext({
  sidebarCollapsed: false,
  toggleSidebar: () => {},
});

export const withCoreContext = (WrappedComponent: ComponentType<any>) => (props: any) => (
  <CoreContext.Consumer>{context => <WrappedComponent {...props} {...context} />}</CoreContext.Consumer>
);
