import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push, replace } from 'react-router-redux';
import { getFormValues } from 'redux-form';

import { cancelOrder, createConcreteOrder } from 'app/redux/orders/orders.actions';

import { SubscriptionOrderConfirmComponent } from './subscription-order-confirm.component';

const prepareChargedProducts = (chargeProducts, allProducts) =>
  chargeProducts.map(product => ({
    ...product,
    totalAmount: allProducts.reduce((total, item) => {
      if (item.id === product.id) {
        total += item.amount;
      }

      return total;
    }, 0),
  }));

const mapStateToProps = (
  state,
  {
    match: {
      params: { order },
    },
  },
) => ({
  order: state.orders.entities[order],
  values: getFormValues('order-subscription')(state),
  chargedProducts: prepareChargedProducts(
    state.orders.entities[order].chargeProducts,
    state.orders.entities[order].products,
  ),
  showNamingFeeInfo: state.orders.entities[order].totalQuantity * state.orders.entities[order].unitNamingFee < 75,
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { order, type, subscription },
    },
  },
) => ({
  onCancel: () =>
    dispatch(cancelOrder(order)).then(() =>
      dispatch(
        push({
          pathname: `/subscriptions/${type}`,
          state: {
            success: {
              type: 'CANCEL_ORDER',
            },
          },
        }),
      ),
    ),
  onCreate: () =>
    dispatch(createConcreteOrder(subscription))
      .then(({ payload: { data: { id } } }) =>
        dispatch(
          push({
            pathname: `/subscriptions/${type}/${subscription}/order/${id}`,
            state: {
              keepScrollPosition: true,
            },
          }),
        ),
      )
      .catch(({ errors: { _error } }) =>
        dispatch(
          replace({
            pathname: `/subscriptions/${type}/${subscription}/beneficiaries`,
            state: {
              error: {
                type: 'CREATE_FAILED',
                payload: _error,
              },
            },
          }),
        ),
      ),
});

export const SubscriptionOrderConfirmContainer = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SubscriptionOrderConfirmComponent);
