// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { PlaceholderComponent, TableComponent } from 'app/shared';

import type { Props } from './venues-list-table.placeholder.types';
import styles from '../../table/venues-list-table.module.scss';

export class VenuesListTablePlaceholder extends PureComponent<Props> {
  render() {
    return (
      <TableComponent className="table-striped">
        <thead>
          <tr>
            <FormattedMessage id="VENUES.NAME" tagName="th" />
            <FormattedMessage id="VENUES.CITY" tagName="th" />
            <FormattedMessage id="VENUES.SERVICE_TYPE" tagName="th" />
            <th />
          </tr>
        </thead>
        <tbody>
          {this.props.list.map((_, venue) => (
            <tr key={venue}>
              <td>
                <PlaceholderComponent width={venue % 2 === 0 ? 150 : 200} />
              </td>
              <td>
                <PlaceholderComponent width={venue % 2 === 0 ? 260 : 150} />
              </td>
              <td>
                <PlaceholderComponent width={venue % 2 === 0 ? 260 : 150} />
              </td>
              <td className={styles.icons}>
                <PlaceholderComponent width={100} color="purple" />
              </td>
            </tr>
          ))}
        </tbody>
      </TableComponent>
    );
  }
}
