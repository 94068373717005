// @flow

import React, { Component } from 'react';

import { ExternalLinkComponent } from 'app/shared';

import styles from './store-links.module.scss';
import appStore from 'assets/images/appstore.png';
import googlePlay from 'assets/images/googleplay.png';
import appGallery from 'assets/images/appgallery.png';

export class StoreLinksComponent extends Component<any> {
  render() {
    return (
      <div className={styles.storeLinks}>
        <ExternalLinkComponent href={process.env.REACT_APP_APP_STORE} target="_blank">
          <img src={appStore} alt="appstore" />
        </ExternalLinkComponent>
        <ExternalLinkComponent href={process.env.REACT_APP_GOOGLE_PLAY} target="_blank">
          <img src={googlePlay} alt="googleplay" />
        </ExternalLinkComponent>
        <ExternalLinkComponent href={process.env.REACT_APP_APP_GALLERY} target="_blank">
          <img src={appGallery} alt="appgallery" />
        </ExternalLinkComponent>
      </div>
    );
  }
}
