import { handleActions, combineActions } from 'redux-actions';

import get from 'lodash/get';
import { getDefaultRole } from 'app/utils';

import * as types from './users.action-types';
import { EDIT_BENEFICIARY_SUBSCRIPTION_SUCCESS } from 'app/redux/beneficiaries/beneficiaries.action-types';

const defaultState = {
  entities: {},
};

export const usersReducer = handleActions(
  {
    [combineActions(types.LOGIN_SUCCESS, types.LOGIN_WITH_TOKEN_SUCCESS)]: (
      state,
      {
        payload: {
          data: { accessToken, refreshToken },
        },
      },
    ) => ({
      accessToken,
      refreshToken,
      entities: {},
    }),
    [types.REFRESH_TOKEN_SUCCESS]: (
      state,
      {
        payload: {
          data: { accessToken, refreshToken },
        },
      },
    ) => ({
      ...state,
      accessToken,
      refreshToken,
    }),
    [types.REFRESH_TOKEN_FAILURE]: state => ({
      ...state,
      accessToken: undefined,
    }),
    [types.FETCH_CURRENT_USER_REQUEST]: (state, { meta: { initial } }) => ({
      ...state,
      isAuthenticating: initial,
    }),
    [types.FETCH_CURRENT_USER_SUCCESS]: (state, { payload: { data: current, meta, defaultRoles } }) => ({
      ...state,
      meta,
      current: {
        ...current,
        terms: current.terms || [],
      },
      business: !state.business ? (current.businesses.length > 0 ? current.businesses[0].id : null) : state.business,
      isApproved: !current.terms || current.terms.every(term => !term.required || term.approved),
      isAuthenticated: true,
      isAuthenticating: false,
      subBusiness: get(
        state.business ? current.businesses.find(e => e.id === state.business) : current.businesses[0],
        ['subBusinesses', 0],
        null,
      ),
      businessType: get(
        state.business ? current.businesses.find(e => e.id === state.business) : current.businesses[0],
        ['type'],
        null,
      ),
      role: getDefaultRole(defaultRoles, current.businesses),
      defaultRoles,
    }),
    [types.FETCH_CONTRACT_DATA_SUCCESS]: (state, { payload: { data: contractData } }) => ({
      ...state,
      current: {
        ...state.current,
        contractData,
      },
    }),
    [types.UPDATE_PROFILE_SUCCESS]: (state, { payload }) => ({
      ...state,
      current: {
        ...state.current,
        ...(payload ? payload.data : {}),
      },
    }),
    [types.FETCH_CURRENT_USER_FAILURE]: state => ({
      ...state,
      isAuthenticated: false,
      isAuthenticating: false,
    }),
    [types.CHANGE_BUSINESS]: (state, { payload: { business, permissions } }) => ({
      ...state,
      business,
      businessType: get(state.current.businesses.find(e => e.id === business), ['type'], null),
      subBusiness: get(state.current.businesses.find(e => e.id === business), ['subBusinesses', 0], null),
      role: getDefaultRole(state.defaultRoles, state.current.businesses, permissions),
    }),
    [types.CHANGE_ROLE]: (state, { payload: role }) => ({
      ...state,
      role,
    }),
    [types.FETCH_USERS_REQUEST]: (state, { meta: { customer } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [customer]: undefined,
      },
    }),
    [types.FETCH_USERS_SUCCESS]: (state, { meta: { customer }, payload: { data: list } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [customer]: {
          list,
        },
      },
    }),
    [types.ADD_USER_SUCCESS]: (state, { meta: { customer, user } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [customer]: {
          ...state.entities[customer],
          list: [
            ...state.entities[customer].list,
            {
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              roles: [user.role],
            },
          ],
        },
      },
    }),
    [types.EDIT_USER_ROLES_SUCCESS]: (state, { meta: { customer, roles, user: editedUser } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [customer]: {
          ...state.entities[customer],
          list: state.entities[customer].list
            .map(
              user =>
                user.id === editedUser && user.active
                  ? {
                      ...user,
                      roles,
                    }
                  : user,
            )
            .filter(user => !!user.roles.length),
        },
      },
    }),
    [types.APPROVE_TERMS_SUCCESS]: (state, { meta: { key } }) => ({
      ...state,
      current: {
        ...state.current,
        terms: state.current.terms.map(
          term =>
            term.key === key
              ? {
                  ...term,
                  approved: true,
                }
              : term,
        ),
      },
      isApproved: state.current.terms.every(term => !term.required || term.approved || term.key === key),
    }),
    [EDIT_BENEFICIARY_SUBSCRIPTION_SUCCESS]: (state, { meta: { beneficiary, body, subscriptionBeneficiary } }) => ({
      ...state,
      current: !beneficiary
        ? {
            ...state.current,
            subscriptions: [
              ...(body.status === 'DELETED'
                ? state.current.subscriptions.filter(subscription => subscription.id !== subscriptionBeneficiary)
                : state.current.subscriptions.map(
                    subscription =>
                      subscription.id !== subscriptionBeneficiary
                        ? subscription
                        : {
                            ...subscription,
                            benefitOptions: body.benefits,
                            deductibleAmount: body.deductibleAmount,
                            status: body.status,
                          },
                  )),
            ],
          }
        : state.current,
    }),
    [types.HAS_ACCOUNT_CHANGE]: (state, { payload: hasAccount }) => ({
      ...state,
      hasAccount,
    }),
  },
  defaultState,
);
