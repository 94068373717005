// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import type { Props } from './vouchers-amount.component.types';

export const vouchersDict = {
  EXERCISE_VOUCHER: 4,
  NAMED_EXERCISE_VOUCHER: 4,
  EXERCISE_AND_CULTURE_VOUCHER: 5,
  NAMED_EXERCISE_AND_CULTURE_VOUCHER: 5,
  LUNCH_VOUCHER_820: 8.2,
  LUNCH_VOUCHER_870: 8.7,
  LUNCH_VOUCHER_900: 9,
  LUNCH_VOUCHER_970: 9.7,
  LUNCH_VOUCHER_1000: 10,
  LUNCH_VOUCHER_1020: 10.2,
  LUNCH_VOUCHER_1070: 10.7,
  LUNCH_VOUCHER_1090: 10.9,
  LUNCH_VOUCHER_1130: 11.3,
  LUNCH_VOUCHER_1270: 12.7,
  LUNCH_VOUCHER_1350: 13.5,
  SMOOTHIE_VOUCHER: 5.5,
};

export class VouchersAmountComponent extends Component<Props> {
  getVouchersAmount = (amount: number, id: string) => {
    return Math.floor(amount / vouchersDict[id]);
  };

  render() {
    return (
      <FormattedMessage
        id="SUBSCRIPTION.ORDER.VOUCHER.AMOUNT"
        values={{ amount: this.getVouchersAmount(this.props.amount, this.props.id) }}
      />
    );
  }
}
