import { compose } from 'redux';
import { connect } from 'react-redux';

import { withAuthorization } from 'app/common';

import { fetchActivationCards } from 'app/redux/cards/cards.actions';

import { ActivationCardsComponent } from './activation-cards.component';

const mapStateToProps = ({ cards: { list: cards } }) => ({
  cards,
  loading: !cards,
});

const mapDispatchToProps = dispatch => ({
  fetchActivationCards: () => dispatch(fetchActivationCards()),
});

export const ActivationCardsContainer = compose(
  withAuthorization(['', 'read']),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ActivationCardsComponent);
