// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

import uniqueId from 'lodash/uniqueId';

import type { Props } from './radio.component.types';
import styles from './radio.module.scss';

export class RadioComponent extends Component<Props> {
  render() {
    const labelClassNames = classNames(styles.label, {
      [styles.checked]: this.props.checked,
      [styles.invalid]: this.props.invalid,

      [styles[this.props.theme]]: !!this.props.theme,
    });

    const id = uniqueId('radio_');

    const item = (
      <input
        type="radio"
        className={styles.input}
        value={this.props.option.id}
        onChange={this.props.onChange}
        checked={this.props.checked}
        id={id}
      />
    );

    if (this.props.theme) {
      return (
        <label className={labelClassNames}>
          {item}
          <span className={styles.blank}>
            <span className={styles.input} />
          </span>
          <span className={styles.title}>{this.props.option.name}</span>
        </label>
      );
    }

    return (
      <div className={labelClassNames}>
        {item}
        <label htmlFor={id} className={styles.blank}>
          <span className={styles.input} />
        </label>
        <label htmlFor={id} className={styles.title}>
          {this.props.option.name}
        </label>
      </div>
    );
  }
}
