import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import { debounce } from 'lodash';

import { searchParamsSelector } from 'app/utils/search-params-selector/search-params-selector.util';
import { NewContractFiltersComponent } from './new-contract-filters.component';
import { clearVenues } from '../../../redux/venues/venues.actions';

const initialValuesSelector = query => ({
  keyword: '',
  location: '',
  benefitType: 'MASSAGE',
  size: 10,
});

const mapStateToProps = ({ users: { business } }, { location: { search } }) => ({
  initialValues: initialValuesSelector(search),
});

const mapDispatchToProps = (dispatch, { location: { search } }, state) => ({
  onChange: debounce(body => {
    dispatch(
      push({
        search: searchParamsSelector(search, body),
        state: {
          keepScrollPosition: true,
        },
      }),
    );
    if (!body.keyword && !body.location) {
      dispatch(clearVenues());
    }
  }, 500),
});

export const NewContractFiltersContainer = compose(
  withRouter,
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'new-contract-filters',
  }),
)(NewContractFiltersComponent);
