// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form';

import { TitleComponent } from 'app/auth/title/title.component';
import { ButtonComponent, ConnectedInputComponent, AlertComponent } from 'app/shared';

import type { Props } from './login.component.types';
import styles from './login.module.scss';

export class LoginComponent extends Component<Props> {
  render() {
    const submitButtonClassNames = classNames(styles.submit, styles.submitLogin);

    return (
      <section>
        {this.props.invalid && (
          <div className={classNames(styles.alert, styles.error)}>
            <FormattedMessage id="AUTH.LOGIN_PAGE_ERROR" />
          </div>
        )}

        {!!this.props.location.state &&
          !!this.props.location.state.cause && (
            <div className={classNames(styles.alert, styles.success, 'text-center')}>
              {this.props.location.state.cause === 'INACTIVITY' && <FormattedMessage id="AUTH.LOGOUT_INACTIVE" />}
            </div>
          )}

        <TitleComponent>
          <FormattedMessage id="AUTH.LOGIN" />
        </TitleComponent>

        <form onSubmit={this.props.handleSubmit} noValidate>
          <Field
            name="username"
            type="email"
            label={this.props.intl.formatMessage({ id: 'AUTH.EMAIL' })}
            placeholder={this.props.intl.formatMessage({ id: 'AUTH.EMAIL_PLACEHOLDER' })}
            component={ConnectedInputComponent}
            large
          />

          <Field
            name="password"
            type="password"
            label={this.props.intl.formatMessage({ id: 'AUTH.PASSWORD' })}
            placeholder={this.props.intl.formatMessage({ id: 'AUTH.PASSWORD_PLACEHOLDER' })}
            component={ConnectedInputComponent}
            large
          />

          <div className={submitButtonClassNames}>
            <ButtonComponent theme="primary" size="large" loading={this.props.submitting || this.props.submitSucceeded}>
              {this.props.intl.formatMessage({ id: 'AUTH.LOGIN' })}
            </ButtonComponent>
          </div>

          <AlertComponent type="light" className={classNames(styles.infoAlert, 'text-left')}>
            <FormattedMessage id="AUTH.NEW_PASSWORD_INFO" />
          </AlertComponent>

          <div className={styles.buttons}>
            <div>
              <Link to="/request-password" className={styles.button}>
                {this.props.intl.formatMessage({ id: 'AUTH.REQUEST_PASSWORD' })}
              </Link>
            </div>
            <div>
              <Link to="/login-without-password" className={styles.button}>
                {this.props.intl.formatMessage({ id: 'AUTH.LOGIN_WITHOUT_PASSWORD' })}
              </Link>
            </div>
          </div>
        </form>
      </section>
    );
  }
}
