// @flow

import React, { Component } from 'react';

import { PlaceholderComponent, TileComponent } from 'app/shared';

type Props = {};

export class ReportsListTilePlaceholder extends Component<Props> {
  render() {
    return (
      <TileComponent>
        <div className="row pb-3">
          <div className="col col-12">
            <PlaceholderComponent width={240} height={32} color="primary" />
          </div>

          <div className="col">
            <PlaceholderComponent width={110} height={16} color="primary" />
            <div>
              <PlaceholderComponent width={50} height={16} />
            </div>
          </div>

          <div className="col text-right">
            <PlaceholderComponent width={120} height={32} color="purple" />
          </div>
        </div>

        <TileComponent className="mb-2">
          <div className="row mb-2">
            <div className="col m-0">
              <strong>
                <PlaceholderComponent width={90} height={16} />
              </strong>
            </div>

            <div className="col m-0">
              <PlaceholderComponent width={140} height={16} />
            </div>
          </div>

          <div className="row">
            <div className="col m-0">
              <PlaceholderComponent width={110} height={16} color="primary" />
              <div>
                <PlaceholderComponent width={80} height={16} color="primary" />
              </div>
            </div>

            <div className="col m-0">
              <PlaceholderComponent width={40} height={16} color="primary" />
              <div>
                <PlaceholderComponent width={70} height={16} color="primary" />
              </div>
            </div>
          </div>
        </TileComponent>
      </TileComponent>
    );
  }
}
