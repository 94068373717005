// @flow

import React, { Component } from 'react';

import { HeaderComponent } from 'app/core';
import { HowToPayComponent } from './how-to-pay/how-to-pay.component';
import { FaqContainer } from './faq/faq.container';
import { HowToReceiveComponent } from './how-to-receive/how-to-receive.component';

import styles from './smartum-pay-info.module.scss';

export class SmartumPayInfoComponent extends Component<any> {
  render() {
    const isPartner = ['ROLE_VENUE_MANAGER', 'ROLE_VENUE_CASHIER'].includes(this.props.role);
    const isEmployee = this.props.role === 'ROLE_COMPANY_BENEFICIARY';

    return (
      <div className={styles.smartumPayInfo}>
        <HeaderComponent breadcrumbs={[{ icon: 'hand-graving-smartphone' }]} />
        {isPartner && <HowToReceiveComponent />}
        {isEmployee && (
          <>
            <HowToPayComponent />
            <FaqContainer />
          </>
        )}
      </div>
    );
  }
}
