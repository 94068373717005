import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';
import { debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import URLSearchParams from '@ungap/url-search-params';

import { withClear } from 'app/common';
import { withCoreContext } from 'app/core/core.context';
import { searchParamsSelector } from 'app/utils/search-params-selector/search-params-selector.util';

import { FiltersComponent } from './filters.component';

const initialValuesSelector = query => {
  const searchParams = new URLSearchParams(query);

  return {
    name: searchParams.get('name') || '',
    email: searchParams.get('email') || '',
    ssn: searchParams.get('ssn') || '',
    roleStatus: searchParams.get('roleStatus') || '',
    size: searchParams.has('size') ? Number(searchParams.get('size')) : 10,
  };
};

const mapStateToProps = ({ users: { business } }, { location: { search } }) => ({
  business,
  initialValues: initialValuesSelector(search),
});

const mapDispatchToProps = (dispatch, { location: { search } }) => ({
  onChange: debounce(
    body =>
      dispatch(
        push({
          search: searchParamsSelector(search, body),
          state: {
            keepScrollPosition: true,
          },
        }),
      ),
    500,
  ),
});

export const FiltersContainer = compose(
  withRouter,
  injectIntl,
  withCoreContext,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'beneficiaries-filters',
  }),
  withClear,
)(FiltersComponent);
