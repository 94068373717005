// @flow

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { SmsPaymentInfoContainer } from './sms-payment-info/sms-payment-info.container';

export class SmsPaymentComponent extends Component<any> {
  render() {
    return (
      <Switch>
        <Route path={this.props.match.url} component={SmsPaymentInfoContainer} />
      </Switch>
    );
  }
}
