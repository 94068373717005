import { RSAA } from 'redux-api-middleware';

import * as types from './vouchers.action-types';

export const checkVoucherOwner = number => ({
  [RSAA]: {
    types: [types.CHECK_VOUCHER_OWNER_REQUEST, types.CHECK_VOUCHER_OWNER_SUCCESS, types.CHECK_VOUCHER_OWNER_FAILURE],
    endpoint: `/vouchers/check/${number}`,
    method: 'GET',
  },
});
