// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { HeaderComponent } from 'app/core';
import { CardComponent, InfoComponent } from 'app/shared';
import { SummaryButtonsComponent } from 'app/register/shared';

import type { Props } from './register-success.component.types';

export class RegisterSuccessComponent extends Component<Props> {
  render() {
    return (
      <section>
        <HeaderComponent
          breadcrumbs={[
            {
              icon: 'home-icon-silhouette',
            },
            {
              name: <FormattedMessage id="REGISTER.TITLE" />,
              onClick: () => this.props.changeStep('FORM'),
            },
            {
              name: <FormattedMessage id="AUTH.SUCCESS" />,
            },
          ]}
        >
          <FormattedMessage id={this.props.title} tagName="h2" />
        </HeaderComponent>
        <CardComponent>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-12">
                <InfoComponent type="success" centred titleId="REGISTER.SUCCESS">
                  {this.props.children}
                </InfoComponent>
              </div>
            </div>
            <SummaryButtonsComponent success />
          </div>
        </CardComponent>
      </section>
    );
  }
}
