// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { HeaderComponent } from 'app/core';
import { PersonalContainer } from 'app/profile/personal/personal.container';
import { PasswordContainer } from 'app/profile/password/password.container';

import { AlertComponent, ButtonComponent } from 'app/shared';

import type { Props, State } from './profile.component.types';

export class ProfileComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      downloading: false,
    };
  }

  handleDownload = () =>
    this.setState({ downloading: true }, () =>
      this.props.onDownload().then(({ error }) => this.setState({ error, downloading: false })),
    );

  render() {
    const profileRequestButton = this.props.business ? 
      <ButtonComponent
        size="large"
        theme="primary"
        onClick={this.handleDownload}
        loading={this.state.downloading}
      >
        <FormattedMessage id="PROFILE.REQUEST" />
      </ButtonComponent> : null;
      
    return (
      <>
        <HeaderComponent
          breadcrumbs={[{ icon: 'man-user' }]}
          button={ profileRequestButton }
        >
          <FormattedMessage id="PROFILE.TITLE" tagName="h2" />
          <FormattedMessage id="PROFILE.DESCRIPTION" tagName="p" />
        </HeaderComponent>

        {this.state.error && (
          <AlertComponent type="danger">
            <FormattedMessage id="PROFILE.REQUEST_ERROR" />
          </AlertComponent>
        )}

        <PersonalContainer />
        <PasswordContainer />
      </>
    );
  }
}
