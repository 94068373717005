// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Fields } from 'redux-form';

import {
  ConnectedDateRangeComponent,
  ModalComponent,
  NoResultsContainer,
  PieChartComponent,
  ButtonComponent,
} from 'app/shared';
import { currencyFormatter } from 'app/utils';

import { ReportsDetailsPlaceholder } from './placeholder/reports-details.placeholder';

import type { State, Props, DateRange } from './reports-details.component.types';

import styles from './reports-details.module.scss';

export class ReportsDetailsComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isCalendarOpened: false,
    };
  }

  componentDidMount() {
    this.props.fetchReport();
  }

  onCalendarToggle = (state: boolean) => this.setState({ isCalendarOpened: state });

  onCalendarChange = ({ startDate, endDate }: DateRange) =>
    this.props.onFilterChange({
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    });

  onClickPrintButton = () => {
    const { report, startDate, endDate } = this.props;
    sessionStorage.setItem(
      'printableReport',
      JSON.stringify({
        report,
        startDate,
        endDate,
      }),
    );
    return window.open('/print-report');
  };

  onClose = () => {
    sessionStorage.removeItem('printableReport');
    return this.props.onClose();
  };

  render() {
    const total = data => data.reduce((res, { value }) => Number(res + Number(value)), 0);
    const { intl, onClose, report } = this.props;

    if (this.props.loading) {
      return <ReportsDetailsPlaceholder onClose={onClose} />;
    }

    return (
      <ModalComponent
        onClose={this.onClose}
        title={<FormattedMessage id="SALDO_REPORTS.REPORT_DETAILS" />}
        additionalButton={
          <ButtonComponent
            theme="primary"
            onClick={this.onClickPrintButton}
            disabled={!report.benefits.length || !report.venues.length}
          >
            <i className="fi fi-print" />
            <FormattedMessage id="CORE.PRINT_REPORT" />
          </ButtonComponent>
        }
      >
        <form noValidate className={styles.dateRange}>
          <Fields
            names={['startDate', 'endDate']}
            component={ConnectedDateRangeComponent}
            relativeNames={['startDate', 'endDate']}
            onFocusChange={this.onCalendarToggle}
            onDatesChange={this.onCalendarChange}
            parse={value => (value ? value.format('YYYY-MM-DD') : null)}
            intl={this.props.intl}
          />
        </form>

        {!report.benefits.length || !report.venues.length ? <NoResultsContainer searchable={false} /> : null}

        {!!report.benefits.length && !!report.venues.length ? (
          <>
            <PieChartComponent
              title={intl.formatMessage(
                { id: 'SALDO_REPORTS.BENEFITS_CHART_TITLE' },
                { total: currencyFormatter.format(total(report.benefits)) },
              )}
              total={total(report.benefits)}
              data={report.benefits}
              parseItem={({ label, value }) => [intl.formatMessage({ id: `PAYMENT.ITEM.${label}` }), Number(value)]}
              legendPosition="bottom"
            />
            <hr />
            <PieChartComponent
              title={intl.formatMessage({
                id: 'SALDO_REPORTS.VENUES_CHART_TITLE',
              })}
              total={total(report.venues)}
              data={report.venues}
              legendPosition="bottom"
            />
          </>
        ) : null}
      </ModalComponent>
    );
  }
}
