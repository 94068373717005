// @flow

import React, { Component } from 'react';
import { Field } from 'redux-form';

import { ConnectedCheckboxComponent } from 'app/shared';

import type { Event } from 'redux-form/es/types';
import type { Props } from './select-all-component.types';

export class SelectAllComponent extends Component<Props> {
  static defaultProps = {
    condition: () => true,
  };

  onChange = (event: Event, selectAllValue: boolean) => {
    this.props.values.forEach((value, index) => {
      if (this.props.condition(value)) {
        this.props.onChange(index, selectAllValue);
      }
    });
  };

  render() {
    return (
      <Field
        name="selectAll"
        theme="select-all"
        component={ConnectedCheckboxComponent}
        locale={this.props.locale}
        onChange={this.onChange}
        light
      />
    );
  }
}
