// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import type { Props } from './info.component.types';
import styles from './info.module.scss';

export class InfoComponent extends Component<Props> {
  render() {
    const messageId = this.props.titleId || `AUTH.${this.props.type.toUpperCase()}`;
    const useWithoutTitle = this.props.useWithoutTitle;

    const checkClassNames = classNames('fi', styles.check, {
      [styles.loading]: 'processing' === this.props.type,
      'fi-check': 'success' === this.props.type,
      'fi-error': 'error' === this.props.type,
      'fi-alert': 'alert' === this.props.type,
    });

    const containerClassNames = classNames(styles.container, styles[this.props.type], this.props.className, {
      [styles.centred]: this.props.centred,
    });

    return (
      <div className={containerClassNames}>
        <div className={styles.titleContainer}>
          <div className={styles.image}>
            <i className={checkClassNames} />
          </div>
          {!useWithoutTitle && (
            <h2 className={styles.title}>
              <FormattedMessage id={messageId} />
            </h2>
          )}
        </div>
        <p className={useWithoutTitle ? styles.description : styles.descriptionWithTitle}>{this.props.children}</p>
      </div>
    );
  }
}
