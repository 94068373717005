// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './help-link.module.scss';
import type { Props } from './help-link.component.types.js';

import { ExternalLinkComponent } from '../external-link/external-link.component';

export class HelpLinkComponent extends Component<Props> {
  render() {
    const { locale } = this.props.intl;

    const helpLinkDict = {
      en: process.env.REACT_APP_HELP_LINK_EN,
      fi: process.env.REACT_APP_HELP_LINK_FI,
      sv: process.env.REACT_APP_HELP_LINK_SV,
    };

    const helpLink = helpLinkDict[locale || 'en'];

    return (
      <div className={styles.helpInfo}>
        <span className={styles.helpInfoMark}>?</span>
        <div className={styles.helpInfoText}>
          <FormattedMessage id="SUBSCRIPTION.HELP_INFO.TEXT_INFO" tagName="p" />
          <FormattedMessage
            id="SUBSCRIPTION.HELP_INFO.TEXT_WITH_LINK"
            tagName="p"
            values={{
              link: (
                <ExternalLinkComponent href={helpLink} target="_blank">
                  <FormattedMessage id="SUBSCRIPTION.HELP_INFO.LINK_TEXT" />
                </ExternalLinkComponent>
              ),
            }}
          />
        </div>
      </div>
    );
  }
}
