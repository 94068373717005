import get from 'lodash/get';

import { download } from 'app/redux/downloads/downloads.actions';
import { generateTransactionsReport } from 'app/redux/transactions/transactions.actions';
import { refreshToken } from 'app/redux/users/users.actions';

const generateReport = (dispatch, type, business, locale, isFirstRequest) => {
  dispatch(generateTransactionsReport(type, business.businessId, business.filters, isFirstRequest)).then(resp => {
    const payload = get(resp, 'payload');

    if (payload && payload.length === 0) {
      setTimeout(() => {
        generateReport(dispatch, type, business, locale, false);
      }, 5000);
    }

    if (!payload) {
      dispatch(download(`transactions/${type}`, locale, `filter-${type}-transactions`));
    }
  });
};

export const onGenerateTransactionsReport = (dispatch, type, business, locale) => {
  const token = localStorage.getItem('refreshToken');
  dispatch(refreshToken(token)).then(() => generateReport(dispatch, type, business, locale, true));
};
