// @flow

import React, { Component } from 'react';

import { ListComponent } from 'app/shared/list/list.component';
import { CardComponent } from 'app/shared';

import { MyAccountsListTableContainer } from './table/my-accounts-list-table.container';
import { MyAccountsListTileContainer } from './tile/my-accounts-list-tile.container';
import { MyAccountsListPlaceholder } from './placeholder/my-accounts-list.placeholder';

import type { Props } from './my-accounts-list.component.types';

export class MyAccountsListComponent extends Component<Props> {
  componentDidMount() {
    this.props.fetchAccounts();
  }

  render() {
    return (
      <CardComponent>
        <div className="card-body">
          {this.props.loading && <MyAccountsListPlaceholder />}
          {this.props.loading || (
            <ListComponent
              list={this.props.accounts}
              table={MyAccountsListTableContainer}
              tile={MyAccountsListTileContainer}
              searchable={false}
            />
          )}
        </div>
      </CardComponent>
    );
  }
}
