import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { withPermissions } from 'app/common';

import { UsersListTileComponent } from './users-list-tile.component';

export const UsersListTileContainer = compose(
  injectIntl,
  withRouter,
  withPermissions,
)(UsersListTileComponent);
