import { RSAA } from 'redux-api-middleware';

import * as types from './translations.action-types';

const fetchTranslationsRSAA = lang => ({
  [RSAA]: {
    types: [
      types.FETCH_TRANSLATIONS_REQUEST,
      {
        type: types.FETCH_TRANSLATIONS_SUCCESS,
        meta: { lang },
      },
      types.FETCH_TRANSLATIONS_FAILURE,
    ],
    endpoint: `/i18n/${lang}.json`,
    method: 'GET',
  },
});

const shouldFetchTranslations = ({ translations }, lang) => !translations[lang];

export const fetchTranslations = (lang, force = false) => (dispatch, getState) => {
  if (force || shouldFetchTranslations(getState(), lang)) {
    return dispatch(fetchTranslationsRSAA(lang));
  } else {
    return Promise.resolve();
  }
};
