import { compose } from 'redux';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { injectIntl } from 'react-intl';

import { RegisterCompanySummaryComponent } from './register-company-summary.component';

const mapStateToProps = state => getFormValues('register-company')(state);

export const RegisterCompanySummaryContainer = compose(
  injectIntl,
  connect(mapStateToProps),
)(RegisterCompanySummaryComponent);
