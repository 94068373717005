import { combineActions, handleActions } from 'redux-actions';

import * as types from './payments.action-types';

const defaultState = {
  mobilePayment: {},
  token: {},
};

export const paymentsReducer = handleActions(
  {
    [combineActions(types.VALIDATE_MOBILE_PAYMENT_SUCCESS, types.CREATE_MOBILE_PAYMENT_SUCCESS)]: (
      state,
      { payload: { data } },
    ) => ({
      ...state,
      mobilePayment: {
        ...state.mobilePayment,
        ...data,
      },
    }),
    [types.FETCH_PAYMENT_ACCOUNT_SUCCESS]: (state, { payload: { data: accounts } }) => ({
      ...state,
      accounts,
    }),
    [types.FETCH_PAYMENT_TOKEN_REQUEST]: state => ({
      ...state,
      token: {},
    }),
    [types.FETCH_PAYMENT_TOKEN_SUCCESS]: (
      state,
      {
        payload: {
          data: { token },
        },
      },
    ) => ({
      ...state,
      token,
    }),
  },
  defaultState,
);
