// @flow

import React, { PureComponent } from 'react';
import classNames from 'classnames';

import type { Props } from './table.component.types';
import styles from './table.module.scss';

export class TableComponent extends PureComponent<Props> {
  render() {
    return <table className={classNames('table', this.props.className, styles.table)}>{this.props.children}</table>;
  }
}
