// @flow

import React, { Component } from 'react';

import type { Props } from './sidebar-title.component.types';
import styles from './sidebar-title.module.scss';

export class SidebarTitleComponent extends Component<Props> {
  render() {
    return <h5 className={styles.title}>{this.props.children}</h5>;
  }
}
