import { get } from 'lodash';

import * as types from './error.action-types';

export const errorReducer = (state = false, action) => {
  if ((!!action.error && get(action, ['payload', 'response', 'code']) >= 500) || action.type === types.SET_ERROR) {
    return true;
  }

  return state;
};
