// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

import { ConnectedGroupComponent } from 'app/shared';

import { CheckboxComponent } from './checkbox/checkbox.component';

import type { Option, Props } from './connected-checkboxes.component.types';
import styles from './connected-checkboxes.module.scss';

export class ConnectedCheckboxesComponent extends Component<Props> {
  static defaultProps = {
    translate: true,
  };

  getAvailableOptions = () =>
    this.props.isOptionInput ? this.props.options.filter(option => option.available) : this.props.options;

  getAvailableOptionsId = () => this.getAvailableOptions().map(option => option.id);

  getAlwaysEnabledOptions = () => this.getAvailableOptions().filter(option => option.alwaysEnabled);

  updateAllowedValue = () => {
    const availableOptions = this.getAlwaysEnabledOptions().map(option => option.id);
    this.props.input.onChange(availableOptions);
  };

  isChecked = (value: string) => {
    const isValueSelected = this.props.input.value.indexOf(value) > -1;
    const isValueAvailableOption = this.getAvailableOptionsId().indexOf(value) > -1;

    return isValueSelected && (!this.props.isOptionInput || isValueAvailableOption);
  };

  isDisabled = (option: Option) =>
    this.props.isOptionInput ? !option.editable : this.props.disableEdit && !option.editable;

  onChange = (option: Option) => {
    if (!option.alwaysEnabled) {
      if (this.isChecked(option.id)) {
        this.props.input.onChange(this.props.input.value.filter(x => x !== option.id));
      } else {
        this.props.input.onChange([...this.props.input.value, option.id]);
      }
    }

    if (this.props.afterChange) {
      this.props.afterChange(this.isChecked(option.id), option);
    }
  };

  componentDidUpdate(oldProps: Props) {
    if (oldProps.selectedBenefit !== this.props.selectedBenefit) {
      this.updateAllowedValue();
    }
  }

  render() {
    const options = this.getAvailableOptions();

    const optionsClassNames = classNames(styles.options, {
      [styles.extended]: 'extended' === this.props.theme,
      [styles.inline]: 'inline' === this.props.theme,
      [styles.customize]: 'customize' === this.props.theme,
      [styles.exact]: 'inline' || 'customize' !== this.props.theme,
    });

    return (
      <ConnectedGroupComponent {...this.props}>
        <div className={optionsClassNames}>
          {options.map((option, index) => (
            <div className={styles.option} key={index}>
              <CheckboxComponent
                option={option}
                name={this.props.input.name}
                theme={this.props.theme}
                checked={this.isChecked(option.id)}
                disabled={this.isDisabled(option)}
                onChange={() => this.onChange(option)}
                invalid={this.props.meta.submitFailed && !!this.props.meta.error}
                translate={this.props.translate}
                infoText={this.props.displayInfoText && this.props.displayInfoText(option.id)}
              />
            </div>
          ))}
        </div>
      </ConnectedGroupComponent>
    );
  }
}
