import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { fetchAccount } from 'app/redux/accounts/accounts.actions';

import { AccountDetailsComponent } from './account-details.component';

const mapStateToProps = (
  { accounts: { entities: accounts }, permissions, users: { business, role } },
  {
    match: {
      params: { number },
    },
  },
) => ({
  loading: !accounts[number] || accounts[number].loading,
  account: accounts[number],
  permissions: permissions[business][role] || {},
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { number },
    },
  },
) => ({
  fetchAccount: () => dispatch(fetchAccount(number)),
});

export const AccountDetailsContainer = compose(
  injectIntl,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(AccountDetailsComponent);
