import { handleActions } from 'redux-actions';
import * as types from './cards.action-types';

const defaultState = {};

export const cardsReducer = handleActions(
  {
    [types.FETCH_ACTIVATION_CARDS_SUCCESS]: (state, { payload: { data: list } }) => ({
      ...state,
      list,
    }),
    [types.FETCH_CARDS_SUCCESS]: (state, { payload: { data: list } }) => ({
      ...state,
      list,
    }),
    [types.CHANGE_CARD_STATUS_SUCCESS]: (state, { meta: { active, id } }) => ({
      ...state,
      list: state.list.map(card => (card.id === id ? { ...card, active } : card)),
    }),
  },
  defaultState,
);
