// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { ButtonComponent, TileComponent } from 'app/shared';
import { checkPermissions, currencyFormatter } from 'app/utils';

import type { Props } from './my-accounts-list-tile.component.types';

export class MyAccountsListTileComponent extends Component<Props> {
  render() {
    return (
      <TileComponent>
        <h3>
          <Link to={`${this.props.pathname || ''}/accounts/${this.props.tile.accountNumber}`}>
            <FormattedMessage id={`PAYMENT.ITEM.${this.props.tile.item}`} />
          </Link>
        </h3>

        <div className="row">
          <div className="col-md-6 mb-2">
            <FormattedMessage id="ACCOUNTS.BALANCE" tagName="small" />
            {currencyFormatter.format(this.props.tile.balance)}
          </div>
          {checkPermissions(this.props.permissions, ['companyBeneficiaryAccounts', 'update']) && (
            <div className="col-md-6 mb-2">
              <FormattedMessage id="ACCOUNTS.STATUS" tagName="small" />
              {this.props.tile.active ? (
                <FormattedMessage id="ACCOUNTS.ACTIVE" />
              ) : (
                <FormattedMessage id="ACCOUNTS.PASSIVE" />
              )}
            </div>
          )}
        </div>

        {checkPermissions(this.props.permissions, ['companyBeneficiaryAccounts', 'update']) && (
          <div className="buttons">
            {this.props.tile.active ? (
              <ButtonComponent
                theme="outline-danger"
                size="small"
                onClick={() => this.props.onDisable(this.props.tile.accountNumber)}
              >
                <FormattedMessage id="ACCOUNTS.DISABLE" />
              </ButtonComponent>
            ) : (
              <ButtonComponent
                theme="outline-success"
                size="small"
                onClick={() => this.props.onActivate(this.props.tile.accountNumber)}
              >
                <FormattedMessage id="ACCOUNTS.ACTIVATE" />
              </ButtonComponent>
            )}
          </div>
        )}
      </TileComponent>
    );
  }
}
