import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { MyAccountsListTableComponent } from './my-accounts-list-table.component';

const mapStateToProps = ({ permissions, users: { business, role } }) => ({
  permissions: permissions[business][role],
});

export const MyAccountsListTableContainer = compose(
  injectIntl,
  connect(mapStateToProps),
)(MyAccountsListTableComponent);
