// @flow

// import type { SubBusiness } from 'app/core/sidebar/user/sidebar-user.component.types';

// type Props = {
//   business: number,
//   subBusiness: SubBusiness,
// };

export function isBusinessDifferent(
  // $FlowFixMe
  { business: prevBusiness, subBusiness: prevSubBusiness },
  // $FlowFixMe
  { business: nextBusiness, subBusiness: nextSubBusiness },
) {
  const prevSubBusinessId = prevSubBusiness && prevSubBusiness.id;
  const nextSubBusinessId = nextSubBusiness && nextSubBusiness.id;

  return prevBusiness !== nextBusiness || prevSubBusinessId !== nextSubBusinessId;
}
