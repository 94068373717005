import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';

import { withAuthorization } from 'app/common';

import { fetchBeneficiarySubscriptions } from 'app/redux/beneficiaries/beneficiaries.actions';

import { BeneficiaryDetailsSubscriptionsComponent } from './beneficiary-details-subscriptions.component';

const mapStateToProps = ({ beneficiaries: { entities: beneficiaries } }, { beneficiary }) => ({
  loading: !get(beneficiaries, [beneficiary, 'subscriptions']),
  subscriptions: get(beneficiaries, [beneficiary, 'subscriptions']),
});

const mapDispatchToProps = (dispatch, { beneficiary }) => ({
  fetchBeneficiarySubscriptions: () => dispatch(fetchBeneficiarySubscriptions(beneficiary)),
});

export const BeneficiaryDetailsSubscriptionsContainer = compose(
  withRouter,
  injectIntl,
  withAuthorization(['companyBeneficiarySubscriptions', 'read']),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(BeneficiaryDetailsSubscriptionsComponent);
