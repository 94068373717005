// @flow

import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import { TitleComponent } from 'app/auth/title/title.component';
import { BackComponent } from 'app/auth/back/back.component';
import { ButtonComponent, ConnectedInputComponent, InfoComponent } from 'app/shared';

import type { Props, State } from './login-without-password.component.types';
import { submit } from 'app/auth/login/login.module.scss';

export class LoginWithoutPasswordComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      submitted: false,
    };
  }

  onSubmit = ({ username }: { username: string }) =>
    this.props.onSubmit(username).then(() => this.setState({ submitted: true, username }));

  render() {
    return (
      <section>
        <MediaQuery maxWidth={991}>
          <BackComponent to="/login">
            <MediaQuery maxWidth={767}>
              <FormattedMessage id="AUTH.BACK_TO_LOGIN_SHORT" />
            </MediaQuery>
            <MediaQuery minWidth={768}>
              <FormattedMessage id="AUTH.BACK_TO_LOGIN" />
            </MediaQuery>
          </BackComponent>
        </MediaQuery>
        <TitleComponent withBackOption>
          <FormattedMessage id="AUTH.LOGIN_WITHOUT_PASSWORD" />
        </TitleComponent>
        {!this.state.submitted ? (
          <form onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate>
            <Field
              name="username"
              type="email"
              label={this.props.intl.formatMessage({ id: 'AUTH.EMAIL' })}
              placeholder={this.props.intl.formatMessage({ id: 'AUTH.EMAIL_PLACEHOLDER' })}
              component={ConnectedInputComponent}
              large
            />
            <div className={submit}>
              <MediaQuery minWidth={992}>
                <BackComponent to="/login">
                  <FormattedMessage id="AUTH.BACK_TO_LOGIN" />
                </BackComponent>
              </MediaQuery>
              <ButtonComponent theme="primary" size="large" loading={this.props.submitting}>
                {this.props.intl.formatMessage({ id: 'AUTH.REQUEST_MAGIC_LINK' })}
              </ButtonComponent>
            </div>
          </form>
        ) : (
          <div>
            <InfoComponent type="success">
              <FormattedMessage id="AUTH.SENT_MAGIC_LINK" values={{ email: <strong>{this.state.username}</strong> }} />
            </InfoComponent>
            <MediaQuery minWidth={992}>
              <BackComponent to="/login">
                <FormattedMessage id="AUTH.BACK_TO_LOGIN" />
              </BackComponent>
            </MediaQuery>
          </div>
        )}
      </section>
    );
  }
}
