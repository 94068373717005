import { compose } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { withAuthorization } from 'app/common';

import { fetchLatestTransactions } from 'app/redux/transactions/transactions.actions';

import { TransactionsLatestComponent } from './transactions-latest.component';

const mapStateToProps = ({ transactions: { latest }, permissions, users: { business, role } }) => ({
  loading: !latest,
  transactions: get(latest, 'transactions'),
  permissions: permissions[business][role] || {},
});

const mapDispatchToProps = dispatch => ({
  fetchLatestTransactions: () => dispatch(fetchLatestTransactions()),
});

export const TransactionsLatestContainer = compose(
  withAuthorization(['beneficiaryLatestTransactions', 'read']),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(TransactionsLatestComponent);
