// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { Field } from 'redux-form';

import { ButtonComponent, ConnectedInputComponent, ConnectedSelectComponent } from 'app/shared';

import type { Props } from './filters.component.types';
import styles from './filters.module.scss';

export class FiltersComponent extends Component<Props> {
  componentDidUpdate(prevProps: Props) {
    if (prevProps.business !== this.props.business) {
      this.props.reset();
    }
  }

  render() {
    const rowClassNames = classNames('row', styles.row);

    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    const options = [
      { id: 'active', name: this.props.intl.formatMessage({ id: 'BENEFICIARIES.ROLE.ACTIVE' }) },
      { id: 'disabled', name: this.props.intl.formatMessage({ id: 'BENEFICIARIES.ROLE.PASSIVE' }) },
    ];

    const limitOptions = [
      { id: 10, name: <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.NO_OF_ROWS" values={{ limit: 10 }} /> },
      { id: 100, name: <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.NO_OF_ROWS" values={{ limit: 100 }} /> },
      { id: 1000, name: <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.NO_OF_ROWS" values={{ limit: 1000 }} /> },
    ];

    return (
      <>
        <div className="card-header">
          <div className="d-flex align-items-center justify-content-xl-between">
            <h4 className="card-title">
              <FormattedMessage id="BENEFICIARIES.SEARCH" />
            </h4>
            <ButtonComponent
              theme="outline-secondary"
              size="medium"
              className="ml-4 ml-xl-0"
              onClick={this.props.onClear}
            >
              <FormattedMessage id="CORE.CLEAR_FILTERS" />
            </ButtonComponent>
          </div>
        </div>
        <div className="card-body pb-xl-0">
          <form onSubmit={this.props.handleSubmit}>
            <div className={rowClassNames}>
              <div className={columnClassNames}>
                <Field
                  name="name"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'BENEFICIARIES.LIST_HEADERS.NAME' })}
                />
              </div>
              <div className={columnClassNames}>
                <Field
                  name="ssn"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'BENEFICIARIES.LIST_HEADERS.SSN' })}
                />
              </div>
              <div className={columnClassNames}>
                <Field
                  name="email"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'BENEFICIARIES.LIST_HEADERS.EMAIL' })}
                />
              </div>
              <div className={columnClassNames}>
                <Field
                  name="roleStatus"
                  component={ConnectedSelectComponent}
                  options={options}
                  label={this.props.intl.formatMessage({ id: 'BENEFICIARIES.LIST_HEADERS.ROLE' })}
                  none
                />
              </div>
              <div className={classNames(styles.limit, 'ml-auto')}>
                <Field
                  name="size"
                  options={limitOptions}
                  component={ConnectedSelectComponent}
                  label={this.props.intl.formatMessage({ id: 'CORE.PAGINATION.SHOW_ON_PAGE' })}
                />
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}
