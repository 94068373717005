import { RSAA } from 'redux-api-middleware';
import qs from 'querystring';

import * as types from './reports.action-types';

export const fetchReports = params => (dispatch, getState) => {
  const {
    users: { business },
  } = getState();

  return dispatch({
    [RSAA]: {
      types: [
        {
          type: types.FETCH_REPORTS_REQUEST,
          meta: { params },
        },
        types.FETCH_REPORTS_SUCCESS,
        types.FETCH_REPORTS_FAILURE,
      ],
      endpoint: `/reports/${business}`,
      method: 'GET',
    },
  });
};

export const fetchReport = (report, query) => {
  const queryParams = query ? `?${qs.stringify(query)}` : '';

  return {
    [RSAA]: {
      types: [
        {
          type: types.FETCH_REPORT_REQUEST,
          meta: { report },
        },
        {
          type: types.FETCH_REPORT_SUCCESS,
          meta: { report },
        },
        types.FETCH_REPORT_FAILURE,
      ],
      endpoint: `/report/${report}${queryParams}`,
      method: 'GET',
    },
  };
};

export const fetchYearlyReport = (company, body) => ({
  [RSAA]: {
    types: [types.FETCH_YEARLY_REPORT_REQUEST, types.FETCH_YEARLY_REPORT_SUCCESS, types.FETCH_YEARLY_REPORT_FAILURE],
    endpoint: `/report/yearly/${company}`,
    method: 'POST',
    body,
  },
});
