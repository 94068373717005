// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import {
  ButtonComponent,
  ButtonsWrapperComponent,
  GroupComponent,
  PlaceholderComponent,
  SummaryFieldComponent,
} from 'app/shared';

import type { Props } from './cards-beneficiaries-order.placeholder.types';

export class CardsBeneficiariesOrderPlaceholder extends Component<Props> {
  render() {
    const invoiceColumnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    return (
      <>
        <div className="card-header">
          <h4 className="card-title">
            <FormattedMessage id="CARDS.BENEFICIARY_ORDER.TITLE" />
          </h4>
        </div>
        <div className="card-body">
          <SummaryFieldComponent className="mb-0">
            <FormattedMessage id="CARDS.BENEFICIARY_ORDER.COMPANY_BENEFICIARY" tagName="strong" />
            <PlaceholderComponent width={200} />
          </SummaryFieldComponent>
        </div>
        <div className="card-header card-header--between">
          <h4 className="card-title">
            <FormattedMessage id="ORDERS.INVOICING_DETAILS" />
          </h4>
        </div>
        <div className="card-body">
          <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.INVOICING_METHOD' })} required>
            <PlaceholderComponent height={24} radius={8} color="light" />
          </GroupComponent>

          <div className="row">
            <div className={invoiceColumnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.OFFICIAL_NAME' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
            <div className={invoiceColumnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.EMAIL' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
          <div className="row">
            <div className={invoiceColumnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.ORDER_REFERENCE' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
        </div>
        <div className="card-header card-header--between">
          <h4 className="card-title">
            <FormattedMessage id="ORDERS.DELIVERY_DETAILS" />
          </h4>
        </div>
        <div className="card-body">
          <GroupComponent label={this.props.intl.formatMessage({ id: 'BENEFICIARIES.LABEL.NAME' })} required>
            <PlaceholderComponent height={24} radius={8} color="light" />
          </GroupComponent>

          <div className="row">
            <div className={invoiceColumnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.STREET_ADDRESS' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
            <div className={invoiceColumnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.ZIP_CODE' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
          <div className="row">
            <div className={invoiceColumnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.CITY' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <ButtonsWrapperComponent>
            <ButtonComponent theme="outline-secondary" size="large" to={`/beneficiaries/${this.props.beneficiaryId}`}>
              <i className="fi fi-go-back-left-arrow" /> <FormattedMessage id="CORE.BACK" />
            </ButtonComponent>
          </ButtonsWrapperComponent>
        </div>
      </>
    );
  }
}
