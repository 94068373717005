import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';

import { withAuthorization } from 'app/common';

import { checkVoucherOwner } from 'app/redux/vouchers/vouchers.actions';

import { VouchersCheckOwnerComponent } from './vouchers-check-owner.component';

const mapDispatchToProps = dispatch => ({
  onSubmit: ({ voucher }) => dispatch(checkVoucherOwner(voucher)),
});

export const VouchersCheckOwnerContainer = compose(
  withAuthorization(['voucherCheck', 'read']),
  injectIntl,
  connect(
    null,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'check-voucher-owner',
  }),
)(VouchersCheckOwnerComponent);
