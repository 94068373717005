// @flow

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { ReportsListContainer } from './list/reports-list.container';

import type { Props } from './reports.component.types';

export class ReportsComponent extends Component<Props> {
  render() {
    return (
      <Switch>
        <Route path={this.props.match.url} component={ReportsListContainer} />
      </Switch>
    );
  }
}
