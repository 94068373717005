// @flow

import React, { Component } from 'react';

import { TransactionsVisaTablePlaceholder } from './table/transactions-visa-table.placeholder';
import { TransactionsVisaTilePlaceholder } from './tile/transactions-visa-tile.placeholder';

import { PlaceholderComponent } from 'app/shared';
import { ListComponent } from 'app/shared/list/list.component';

import type { Props } from './transactions-visa.placeholder.types';

export class TransactionsVisaPlaceholder extends Component<Props> {
  static defaultProps = {
    transactions: [...new Array(5)],
  };

  render() {
    return (
      <div>
        <div className="card-header card-header--between">
          <h4 className="card-title">
            <PlaceholderComponent width={150} />
          </h4>
        </div>
        <div className="card-body">
          <ListComponent
            list={this.props.transactions}
            table={TransactionsVisaTablePlaceholder}
            tile={TransactionsVisaTilePlaceholder}
          />
        </div>
      </div>
    );
  }
}
