import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { injectIntl } from 'react-intl';

import { logoutUser, changeHasAccount } from 'app/redux/users/users.actions';

import { SubscriptionSubscribeFormPersonalComponent } from './subscription-subscribe-form-personal.component';

const mapStateToProps = ({ users: { isAuthenticated, hasAccount } }) => ({
  isAuthenticated,
  hasAccount: hasAccount || false,
});

const mapDispatchToProps = dispatch => ({
  onLogin: () =>
    dispatch(
      push({
        pathname: '/login',
        state: {
          back: true,
        },
      }),
    ),
  onLogout: () => dispatch(logoutUser(false)),
  onChangeHasAccount: hasAccount => dispatch(changeHasAccount(hasAccount)),
});

export const SubscriptionSubscribeFormPersonalContainer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SubscriptionSubscribeFormPersonalComponent);
