// @flow

import React, { Component } from 'react';
import { Field } from 'redux-form';

import {
  ButtonComponent,
  ConnectedInputComponent,
  ConnectedRadioComponent,
  ConnectedToggleComponent,
  ModalComponent,
  ConnectedPhoneNumberInputComponent,
} from 'app/shared';
import { displayInfoTextForSuitableRole } from 'app/utils/display-info-text-for-suitable-role/display-info-text-for-suitable-role.util';

import type { Props } from './users-add.component.types';

export class UsersAddComponent extends Component<Props> {
  render() {
    const submitButton = (
      <ButtonComponent theme="primary" size="medium" loading={this.props.submitting}>
        {this.props.intl.formatMessage({ id: 'CORE.SAVE' })}
      </ButtonComponent>
    );

    return (
      <ModalComponent
        title={this.props.intl.formatMessage({ id: 'VENUES.ADD_NEW_USER' })}
        onClose={this.props.onClose}
        form={{ onSubmit: this.props.handleSubmit }}
        submitButton={submitButton}
      >
        <div className="form-row">
          {this.props.roles.map((role, index) => (
            <div className="col-lg-6 align-self-end" key={index}>
              <Field
                name="role"
                component={ConnectedRadioComponent}
                label={0 === index ? this.props.intl.formatMessage({ id: 'VENUES.ROLE' }) : undefined}
                option={{
                  id: role.name,
                  name: this.props.intl.formatMessage({ id: `ROLES.${role.name}` }),
                }}
                required
                infoText={displayInfoTextForSuitableRole(role.name, this.props.intl.formatMessage)}
              />
            </div>
          ))}
        </div>
        <div className="form-row">
          <div className="col-lg-6">
            <Field
              name="firstName"
              component={ConnectedInputComponent}
              label={this.props.intl.formatMessage({ id: 'PROFILE.FIRST_NAME' })}
              required
            />
          </div>
          <div className="col-lg-6">
            <Field
              name="lastName"
              component={ConnectedInputComponent}
              label={this.props.intl.formatMessage({ id: 'PROFILE.LAST_NAME' })}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-lg-6">
            <Field
              name="email"
              component={ConnectedInputComponent}
              label={this.props.intl.formatMessage({ id: 'PROFILE.EMAIL' })}
              required
            />
          </div>
          <div className="col-lg-6">
            <Field
              name="mobilePhone"
              component={ConnectedPhoneNumberInputComponent}
              label={this.props.intl.formatMessage({ id: 'PROFILE.MOBILE_PHONE' })}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-lg-6">
            <Field
              name="workPhone"
              component={ConnectedPhoneNumberInputComponent}
              label={this.props.intl.formatMessage({ id: 'PROFILE.WORK_PHONE' })}
            />
          </div>
        </div>
        <div>
          <Field
            name="marketing"
            component={ConnectedToggleComponent}
            format={v => !!v}
            label={this.props.intl.formatMessage({ id: 'PROFILE.MARKETING' })}
            helpText={this.props.intl.formatMessage({ id: 'PROFILE.MARKETING_INFO' })}
          />
        </div>
      </ModalComponent>
    );
  }
}
