// @flow

import React, { Component } from 'react';
import { Field } from 'redux-form';
import classNames from 'classnames';

import { ConnectedInputComponent } from 'app/shared';

import type { Props } from './orders-create-invoicing-options.component.types';

export class OrdersCreateInvoicingOptionsComponent extends Component<Props> {
  render() {
    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    return (
      <div>
        <div className="row">
          <div className={columnClassNames}>
            <Field
              name="invoiceName"
              component={ConnectedInputComponent}
              label={this.props.intl.formatMessage({ id: 'ORDERS.OFFICIAL_NAME' })}
              disabled
              required
            />
          </div>
          <div className={columnClassNames}>
            <Field
              name="invoiceEmail"
              component={ConnectedInputComponent}
              label={this.props.intl.formatMessage({ id: 'ORDERS.EMAIL' })}
              required
            />
          </div>
        </div>
        {this.props.invoicingMethod === 'EINVOICE' && (
          <div className="row">
            <div className={columnClassNames}>
              <Field
                name="eInvoiceIdentifier"
                component={ConnectedInputComponent}
                label={this.props.intl.formatMessage({ id: 'ORDERS.DELIVERY_IDENTIFIER' })}
                addon={this.props.intl.formatMessage({ id: 'ORDERS.DELIVERY_IDENTIFIER_INFO' })}
                required
              />
            </div>
            <div className={columnClassNames}>
              <Field
                name="eInvoiceAddress"
                component={ConnectedInputComponent}
                label={this.props.intl.formatMessage({ id: 'ORDERS.DELIVERY_ADDRESS' })}
                addon={this.props.intl.formatMessage({ id: 'ORDERS.DELIVERY_ADDRESS_INFO' })}
                required
              />
            </div>
          </div>
        )}
        <div className="row">
          <div className={columnClassNames}>
            <Field
              name="invoiceReference"
              component={ConnectedInputComponent}
              label={this.props.intl.formatMessage({ id: 'ORDERS.ORDER_REFERENCE' })}
            />
          </div>
        </div>
      </div>
    );
  }
}
