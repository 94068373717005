export const CREATE_ORDER_REQUEST = 'orders/CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = 'orders/CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = 'orders/CREATE_ORDER_FAILURE';

export const CONFIRM_ORDER_REQUEST = 'orders/CONFIRM_ORDER_REQUEST';
export const CONFIRM_ORDER_SUCCESS = 'orders/CONFIRM_ORDER_SUCCESS';
export const CONFIRM_ORDER_FAILURE = 'orders/CONFIRM_ORDER_FAILURE';

export const CANCEL_ORDER_REQUEST = 'orders/CANCEL_ORDER_REQUEST';
export const CANCEL_ORDER_SUCCESS = 'orders/CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_FAILURE = 'orders/CANCEL_ORDER_FAILURE';

export const CREATE_BENEFICIARY_CARD_ORDER_REQUEST = 'orders/CREATE_BENEFICIARY_CARD_ORDER_REQUEST';
export const CREATE_BENEFICIARY_CARD_ORDER_SUCCESS = 'orders/CREATE_BENEFICIARY_CARD_ORDER_SUCCESS';
export const CREATE_BENEFICIARY_CARD_ORDER_FAILURE = 'orders/CREATE_BENEFICIARY_CARD_ORDER_FAILURE';

export const VALIDATE_BENEFICIARY_CARD_ORDER_REQUEST = 'orders/VALIDATE_BENEFICIARY_CARD_ORDER_REQUEST';
export const VALIDATE_BENEFICIARY_CARD_ORDER_SUCCESS = 'orders/VALIDATE_BENEFICIARY_CARD_ORDER_SUCCESS';
export const VALIDATE_BENEFICIARY_CARD_ORDER_FAILURE = 'orders/VALIDATE_BENEFICIARY_CARD_ORDER_FAILURE';

export const CREATE_SPECIFIC_ORDER_REQUEST = 'orders/CREATE_SPECIFIC_ORDER_REQUEST';
export const CREATE_SPECIFIC_ORDER_SUCCESS = 'orders/CREATE_SPECIFIC_ORDER_SUCCESS';
export const CREATE_SPECIFIC_ORDER_FAILURE = 'orders/CREATE_SPECIFIC_ORDER_FAILURE';

export const CREATE_ORDER_INVOICE_REQUEST = 'orders/CREATE_ORDER_INVOICE_REQUEST';
export const CREATE_ORDER_INVOICE_SUCCESS = 'orders/CREATE_ORDER_INVOICE_SUCCESS';
export const CREATE_ORDER_INVOICE_FAILURE = 'orders/CREATE_ORDER_INVOICE_FAILURE';

export const FETCH_ORDERS_REQUEST = 'orders/FETCH_ORDERS_REQUEST';
export const FETCH_ORDERS_SUCCESS = 'orders/FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILURE = 'orders/FETCH_ORDERS_FAILURE';

export const FETCH_ORDER_REQUEST = 'orders/FETCH_ORDER_REQUEST';
export const FETCH_ORDER_SUCCESS = 'orders/FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAILURE = 'orders/FETCH_ORDER_FAILURE';

export const CREATE_BANKNOTES_ORDER_REQUEST = 'orders/CREATE_BANKNOTES_ORDER_REQUEST';
export const CREATE_BANKNOTES_ORDER_SUCCESS = 'orders/CREATE_BANKNOTES_ORDER_SUCCESS';
export const CREATE_BANKNOTES_ORDER_FAILURE = 'orders/CREATE_BANKNOTES_ORDER_FAILURE';

export const VALIDATE_BANKNOTES_ORDER_REQUEST = 'orders/VALIDATE_BANKNOTES_ORDER_REQUEST';
export const VALIDATE_BANKNOTES_ORDER_SUCCESS = 'orders/VALIDATE_BANKNOTES_ORDER_SUCCESS';
export const VALIDATE_BANKNOTES_ORDER_FAILURE = 'orders/VALIDATE_BANKNOTES_ORDER_FAILURE';
