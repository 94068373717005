// @flow

type Transaction = {
  amount: number,
  from: number,
  id: number,
  to: number,
};

export const createTransactionPath = (transaction: Transaction): string =>
  `${transaction.amount < 0 ? transaction.from : transaction.to}/${transaction.id}`;
