// @flow
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { ButtonComponent, TileComponent } from 'app/shared';
import { currencyFormatter, dateFormatter } from 'app/utils';

import type { Props, State } from './subscriptions-list-tile.component.types';

import styles from './subscriptions-list-tile.module.scss';

const buttonThemeDict = {
  ORDERED: 'not-active',
  VERIFY_AND_ORDER: 'tertiary',
  VERIFY: 'tertiary',
};

export class SubscriptionsListTileComponent extends Component<Props, State> {
  state = { isButtonHovered: false };

  onMouseEnterHandler = () => {
    this.setState({ isButtonHovered: true });
  };

  onMouseLeaveHandler = () => {
    this.setState({ isButtonHovered: false });
  };

  render() {
    const { isButtonHovered } = this.state;
    const status = this.props.tile.status;
    const isOrdered = status === 'ORDERED';
    const buttonTheme = buttonThemeDict[status] || 'secondary';
    return (
      <TileComponent>
        <h3>
          <Link to={`/subscriptions/${this.props.type}/${this.props.tile.id}`}>{this.props.tile.title}</Link>
        </h3>

        <div className="row">
          <div className="col">
            <FormattedMessage id="SUBSCRIPTION.LIST_HEADERS.CLOSING_TIME" tagName="small" />
            {dateFormatter(this.props.tile.subscription.subscriptionTo)} <br />
            {this.props.tile.subscription.distributionDay && (
              <span className="small">
                <FormattedMessage
                  id="SUBSCRIPTION.RECURSIVE_DAY"
                  values={{ day: this.props.tile.subscription.distributionDay }}
                />
              </span>
            )}
          </div>

          <div className="col">
            <FormattedMessage id="SUBSCRIPTION.LIST_HEADERS.APPROVED_SUBSCRIBED" tagName="small" />
            <span className={styles.green}>{this.props.tile.summary.beneficiaries.approved}</span> /{' '}
            <span className={styles.red}>{this.props.tile.summary.beneficiaries.total} </span>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <FormattedMessage id="SUBSCRIPTION.LIST_HEADERS.TOTAL" tagName="small" />
            {currencyFormatter.format(this.props.tile.summary.totalAmount)}
          </div>
        </div>

        <div className="buttons">
          <span onMouseEnter={this.onMouseEnterHandler} onMouseLeave={this.onMouseLeaveHandler}>
            <ButtonComponent
              theme={buttonTheme}
              size="small"
              to={`/subscriptions/${this.props.type}/${this.props.tile.id}/beneficiaries`}
            >
              {(!isOrdered || (isOrdered && !isButtonHovered)) && (
                <FormattedMessage id={`SUBSCRIPTION.STATUS.${status}`} />
              )}
              {isOrdered && isButtonHovered && <FormattedMessage id={`SUBSCRIPTION.ORDER.VIEW`} />}
            </ButtonComponent>
          </span>
        </div>
      </TileComponent>
    );
  }
}
