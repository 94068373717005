// @flow

import React, { Component } from 'react';

import { MyAccountsListTablePlaceholder } from './table/my-accounts-list-table.placeholder';
import { MyAccountsListTilePlaceholder } from './tile/my-accounts-list-tile.placeholder';

import { ListComponent } from 'app/shared/list/list.component';

import type { Props } from './my-accounts-list.placeholder.types';

export class MyAccountsListPlaceholder extends Component<Props> {
  static defaultProps = {
    accounts: [...new Array(5)],
  };

  render() {
    return (
      <ListComponent
        list={this.props.accounts}
        table={MyAccountsListTablePlaceholder}
        tile={MyAccountsListTilePlaceholder}
      />
    );
  }
}
