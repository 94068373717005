// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { checkPermissions, currencyFormatter, dateFormatter } from 'app/utils';

import { TileComponent } from 'app/shared';

import type { Props } from './account-transactions-tile.component.types';
import styles from 'app/transactions/company/table/transactions-company-table.module.scss';

export class AccountTransactionsTileComponent extends Component<Props> {
  render() {
    return (
      <TileComponent>
        <h3>
          {checkPermissions(this.props.permissions, ['transactionDetails', 'read']) ? (
            <Link
              to={{
                pathname: `${this.props.pathname}/${this.props.tile.id}`,
                state: {
                  keepScrollPosition: true,
                },
              }}
            >
              {dateFormatter(this.props.tile.date, true)}
            </Link>
          ) : (
            dateFormatter(this.props.tile.date, true)
          )}
        </h3>

        <div className="row">
          <div className="col-md-8">
            <FormattedMessage id="TRANSACTIONS.PAYER_RECEIVER" tagName="small" />
            {this.props.tile.owner}
          </div>

          <div className="col-md-4">
            <FormattedMessage id="TRANSACTIONS.AMOUNT" tagName="small" />
            <strong
              className={classNames(styles.amount, {
                [styles.income]: this.props.tile.amount > 0,
                [styles.expense]: this.props.tile.amount < 0,
              })}
            >
              {currencyFormatter.format(this.props.tile.amount)}
            </strong>
          </div>
        </div>
      </TileComponent>
    );
  }
}
