export const CREATE_SUBSCRIPTION_REQUEST = 'subscriptions/CREATE_SUBSCRIPTION_REQUEST';
export const CREATE_SUBSCRIPTION_SUCCESS = 'subscriptions/CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_FAILURE = 'subscriptions/CREATE_SUBSCRIPTION_FAILURE';

export const EDIT_SUBSCRIPTION_REQUEST = 'subscriptions/EDIT_SUBSCRIPTION_REQUEST';
export const EDIT_SUBSCRIPTION_SUCCESS = 'subscriptions/EDIT_SUBSCRIPTION_SUCCESS';
export const EDIT_SUBSCRIPTION_FAILURE = 'subscriptions/EDIT_SUBSCRIPTION_FAILURE';

export const CLONE_SUBSCRIPTION_REQUEST = 'subscriptions/CLONE_SUBSCRIPTION_REQUEST';
export const CLONE_SUBSCRIPTION_SUCCESS = 'subscriptions/CLONE_SUBSCRIPTION_SUCCESS';
export const CLONE_SUBSCRIPTION_FAILURE = 'subscriptions/CLONE_SUBSCRIPTION_FAILURE';

export const IMPORT_SUBSCRIPTION_REQUEST = 'subscriptions/IMPORT_SUBSCRIPTION_REQUEST';
export const IMPORT_SUBSCRIPTION_SUCCESS = 'subscriptions/IMPORT_SUBSCRIPTION_SUCCESS';
export const IMPORT_SUBSCRIPTION_FAILURE = 'subscriptions/IMPORT_SUBSCRIPTION_FAILURE';

export const CHECK_IMPORT_SUBSCRIPTION_REQUEST = 'subscriptions/CHECK_IMPORT_SUBSCRIPTION_REQUEST';
export const CHECK_IMPORT_SUBSCRIPTION_SUCCESS = 'subscriptions/CHECK_IMPORT_SUBSCRIPTION_SUCCESS';
export const CHECK_IMPORT_SUBSCRIPTION_FAILURE = 'subscriptions/CHECK_IMPORT_SUBSCRIPTION_FAILURE';

export const SET_CANCELLED_IMPORT_STATUS = 'subscriptions/SET_CANCELLED_IMPORT_STATUS';

export const FETCH_SUBSCRIPTIONS_REQUEST = 'subscriptions/FETCH_SUBSCRIPTIONS_REQUEST';
export const FETCH_SUBSCRIPTIONS_SUCCESS = 'subscriptions/FETCH_SUBSCRIPTIONS_SUCCESS';
export const FETCH_SUBSCRIPTIONS_FAILURE = 'subscriptions/FETCH_SUBSCRIPTIONS_FAILURE';

export const FETCH_ALL_SUBSCRIPTIONS_REQUEST = 'subscriptions/FETCH_ALL_SUBSCRIPTIONS_REQUEST';
export const FETCH_ALL_SUBSCRIPTIONS_SUCCESS = 'subscriptions/FETCH_ALL_SUBSCRIPTIONS_SUCCESS';
export const FETCH_ALL_SUBSCRIPTIONS_FAILURE = 'subscriptions/FETCH_ALL_SUBSCRIPTIONS_FAILURE';

export const FETCH_SUBSCRIPTION_REQUEST = 'subscriptions/FETCH_SUBSCRIPTION_REQUEST';
export const FETCH_SUBSCRIPTION_SUCCESS = 'subscriptions/FETCH_SUBSCRIPTION_SUCCESS';
export const FETCH_SUBSCRIPTION_FAILURE = 'subscriptions/FETCH_SUBSCRIPTION_FAILURE';

export const FETCH_BENEFICIARIES_REQUEST = 'subscriptions/FETCH_BENEFICIARIES_REQUEST';
export const FETCH_BENEFICIARIES_SUCCESS = 'subscriptions/FETCH_BENEFICIARIES_SUCCESS';
export const FETCH_BENEFICIARIES_FAILURE = 'subscriptions/FETCH_BENEFICIARIES_FAILURE';

export const EDIT_BENEFICIARY_REQUEST = 'subscriptions/EDIT_BENEFICIARY_REQUEST';
export const EDIT_BENEFICIARY_SUCCESS = 'subscriptions/EDIT_BENEFICIARY_SUCCESS';
export const EDIT_BENEFICIARY_FAILURE = 'subscriptions/EDIT_BENEFICIARY_FAILURE';

export const ADD_BENEFICIARY_REQUEST = 'subscriptions/ADD_BENEFICIARY_REQUEST';
export const ADD_BENEFICIARY_SUCCESS = 'subscriptions/ADD_BENEFICIARY_SUCCESS';
export const ADD_BENEFICIARY_FAILURE = 'subscriptions/ADD_BENEFICIARY_FAILURE';

export const EDIT_BENEFICIARIES_REQUEST = 'subscriptions/EDIT_BENEFICIARIES_REQUEST';
export const EDIT_BENEFICIARIES_SUCCESS = 'subscriptions/EDIT_BENEFICIARIES_SUCCESS';
export const EDIT_BENEFICIARIES_FAILURE = 'subscriptions/EDIT_BENEFICIARIES_FAILURE';

export const FETCH_PUBLIC_SUBSCRIPTION_REQUEST = 'subscriptions/FETCH_PUBLIC_SUBSCRIPTION_REQUEST';
export const FETCH_PUBLIC_SUBSCRIPTION_SUCCESS = 'subscriptions/FETCH_PUBLIC_SUBSCRIPTION_SUCCESS';
export const FETCH_PUBLIC_SUBSCRIPTION_FAILURE = 'subscriptions/FETCH_PUBLIC_SUBSCRIPTION_FAILURE';

export const SUBSCRIBE_REQUEST = 'subscriptions/SUBSCRIBE_REQUEST';
export const SUBSCRIBE_SUCCESS = 'subscriptions/SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_FAILURE = 'subscriptions/SUBSCRIBE_FAILURE';

export const SEND_EMAILS_REQUEST = 'subscriptions/SEND_EMAILS_REQUEST';
export const SEND_EMAILS_SUCCESS = 'subscriptions/SEND_EMAILS_SUCCESS';
export const SEND_EMAILS_FAILURE = 'subscriptions/SEND_EMAILS_FAILURE';
