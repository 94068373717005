import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';

import { YearlyLimitsFormComponent } from './yearly-limits-form.component';
import { updateYearlyLimits } from 'app/redux/yearly-limits/yearly-limits.actions';

const mapStateToProps = ({ users: { business } }, { yearlyLimits }) => ({
  initialValues: { yearlyLimits },
  business,
});

const mapDispatchToProps = dispatch => ({
  onSubmit: (business, body) => dispatch(updateYearlyLimits(business, body)),
  onSubmitFail: () => window.scrollTo(0, 0),
});

export const YearlyLimitsFormContainer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'yearly-limits',
  }),
)(YearlyLimitsFormComponent);
