import { compose } from 'redux';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import { withAuthorization } from 'app/common';
import { injectIntl } from 'react-intl';

import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';
//import { changeBusiness } from 'app/redux/users/users.actions';
import { fetchVenue } from 'app/redux/venues/venues.actions';

import { EditVenueComponent } from './edit-venue.component';

const mapStateToProps = (
  { dictionaries, users: { business }, venues: { entities: venues } },
  {
    match: {
      params: { id },
    },
  },
) => ({
  business,
  loading: !dictionaries.services || !venues[id],
  venue: venues[id],
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { id },
    },
  },
) => ({
  changeVenue: venue => dispatch(replace(`/venues/${venue}/edit`)),
  fetchDictionary: () => dispatch(fetchDictionary('services', true)),
  fetchVenue: () => dispatch(fetchVenue(id)),
});

export const EditVenueContainer = compose(
  withAuthorization(['venues', 'update'], ['venue', 'update']),
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(EditVenueComponent);
