// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Switch } from 'react-router-dom';
import classNames from 'classnames';

import { HeaderComponent } from 'app/core';
import { ButtonComponent, CardComponent, ListComponent, AlertComponent } from 'app/shared';
import { ReportsDetailsContainer } from 'app/reports/details/reports-details.container';
import { ReportsListPlaceholder } from 'app/reports/list/placeholder/reports-list.placeholder';
import { ReportsListTableComponent } from './table/reports-list-table.component';
import { ReportsListTileComponent } from './tile/reports-list-tile.component';
import { YearlyReportContainer } from 'app/reports/yearly-report/yearly-report.container';

import type { Props } from './reports-list.component.types';
import styles from './reports-list.module.scss';

export class ReportsListComponent extends Component<Props> {
  componentDidUpdate(prevProps: Props) {
    if (prevProps.business !== this.props.business) {
      this.props.updateBusiness();
    }
  }

  render() {
    const noResults = this.props.loading || !this.props.reports.length;

    return (
      <>
        <HeaderComponent
          breadcrumbs={[{ icon: 'graphic' }]}
          button={
            <ButtonComponent
              size="large"
              theme="white"
              onClick={() => this.props.onDownload(this.props.business)}
              disabled={noResults}
            >
              <i className="fi fi-xlsx-file-format-extension" />
              <FormattedMessage id="TRANSACTIONS.DOWNLOAD_AS_XLSX" />
            </ButtonComponent>
          }
        >
          <FormattedMessage id="SIDEBAR.SALDO_REPORTS" tagName="h2" />
        </HeaderComponent>

        {!!this.props.location.state &&
          !!this.props.location.state.success && (
            <AlertComponent type="success">
              <FormattedMessage id="SALDO_REPORTS.YEARLY_REPORT.SUCCESS_TITLE" tagName="h5" />
              <FormattedMessage id="SALDO_REPORTS.YEARLY_REPORT.SUCCESS_MESSAGE" />
            </AlertComponent>
          )}

        <CardComponent>
          <div className={classNames(styles.reportsHeader, 'card-header')}>
            <h4 className="card-title">
              <FormattedMessage id="SALDO_REPORTS.LIST_TITLE" />
            </h4>
            <div className={styles.yearlyReport}>
              <FormattedMessage id="SALDO_REPORTS.YEARLY_REPORT.DESCRIPTION" tagName="p" />
              <ButtonComponent size="small" theme="primary" to={`${this.props.match.url}/order`} disabled={noResults}>
                <FormattedMessage id="SALDO_REPORTS.YEARLY_REPORT.BUTTON" />
              </ButtonComponent>
            </div>
          </div>
          <div className="card-body">
            {this.props.loading && <ReportsListPlaceholder />}
            {!this.props.loading && (
              <>
                <ListComponent
                  list={this.props.reports}
                  table={ReportsListTableComponent}
                  tile={ReportsListTileComponent}
                  searchable={false}
                  props={{
                    location: this.props.location,
                  }}
                />
                {this.props.pagination}
              </>
            )}
          </div>
        </CardComponent>

        <Switch>
          <Route
            path={`${this.props.match.url}/order`}
            render={() => <YearlyReportContainer business={this.props.business} />}
          />
          <Route path={`${this.props.match.url}/:report`} render={props => <ReportsDetailsContainer {...props} />} />
        </Switch>
      </>
    );
  }
}
