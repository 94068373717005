// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { AgreementsAcceptanceContainer } from 'app/agreements/acceptance/agreements-acceptance.container';
import { AlertComponent, ButtonComponent } from 'app/shared';

import { SubscriptionSubscribeFormPersonalContainer } from './personal/subscription-subscribe-form-personal.container';
import { SubscriptionSubscribeFormQuestionsContainer } from './questions/subscription-subscribe-form-questions.container';
import { SubscriptionSubscribeFormBenefitsContainer } from './benefits/subscription-subscribe-form-benefits.container';

import type { Props, State } from './subscription-subscribe-form.component.types';

export class SubscriptionSubscribeFormComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      terms: {
        onChange: () => this.setState(prev => ({ terms: { ...prev.terms, value: !prev.terms.value } })),
        value: false,
      },
    };
  }

  render() {
    const { handleSubmit, error, type, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div className="card-body">
          {error && (
            <AlertComponent type="danger" className="text-left">
              <FormattedMessage id={error.id} values={error} tagName="p" />
            </AlertComponent>
          )}
          <AlertComponent type="light" className="text-left">
            <FormattedMessage id="SUBSCRIPTION.SUBSCRIBE.INSTRUCTION_TITLE" tagName="strong" />
            <p>{this.props.description}</p>
          </AlertComponent>
        </div>
        <SubscriptionSubscribeFormPersonalContainer type={type} initialValues={this.props.initialValues} />
        {(!this.props.hasAccount || (this.props.hasAccount && this.props.isAuthenticated)) && (
          <>
            <SubscriptionSubscribeFormQuestionsContainer />
            <SubscriptionSubscribeFormBenefitsContainer />

            <div className="card-body">
              <AgreementsAcceptanceContainer input={this.state.terms} name="beneficiaries" />

              <div className="text-right">
                <ButtonComponent theme="primary" size="large" loading={submitting} disabled={!this.state.terms.value}>
                  <FormattedMessage id="SUBSCRIPTION.SUBSCRIBE.CONFIRM" />
                </ButtonComponent>
              </div>
            </div>
          </>
        )}
      </form>
    );
  }
}
