// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { HeaderComponent } from 'app/core';
import { CardComponent, AlertComponent } from 'app/shared';
import { isBusinessDifferent } from 'app/utils';

import { YearlyLimitsFormContainer } from './form/yearly-limits-form.container';
import { YearlyLimitsPlaceholder } from './placeholder/yearly-limits.placeholder';

import type { Props } from './yearly-limits.component.types';

export class YearlyLimitsComponent extends Component<Props> {
  componentDidMount() {
    this.props.fetchYearlyLimits();
  }

  componentDidUpdate(prevProps: Props) {
    if (isBusinessDifferent(prevProps, this.props)) {
      this.props.fetchYearlyLimits();
    }
  }

  render() {
    const { loading, yearlyLimits } = this.props;

    return (
      <>
        <HeaderComponent breadcrumbs={[{ icon: 'yearly-limit' }]}>
          <FormattedMessage id="NAV.ADDITIONAL.YEARLY_LIMITS" tagName="h2" />
        </HeaderComponent>
        <AlertComponent type="light" className="text-left">
          <FormattedMessage id="YEARLY_LIMITS.DESCRIPTION" />
        </AlertComponent>
        <CardComponent>
          {loading && <YearlyLimitsPlaceholder />}
          {loading || <YearlyLimitsFormContainer yearlyLimits={yearlyLimits} />}
        </CardComponent>
      </>
    );
  }
}
