import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { SidebarNestedComponent } from './sidebar-nested.component';

const mapStateToProps = ({ permissions, users: { business, role } }) => ({
  permissions: permissions[business][role] || {},
});

export const SidebarNestedContainer = compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps),
)(SidebarNestedComponent);
