// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Select, { Creatable } from 'react-select';
import classNames from 'classnames';

import uniqueId from 'lodash/uniqueId';
import pick from 'lodash/pick';

import { ConnectedGroupComponent, TooltipComponent } from 'app/shared';

import type { Props } from './connected-select.component.types';
import styles from './connected-select.module.scss';

export class ConnectedSelectComponent extends Component<Props> {
  static defaultProps = {
    placeholder: <FormattedMessage id="CORE.SELECT">{txt => [txt, ' ...']}</FormattedMessage>,
  };

  render() {
    const id = uniqueId('select_');
    const isInvalid = this.props.meta.touched && this.props.meta.error;

    const selectClassName = classNames('form-control', styles.select, {
      'is-invalid': isInvalid,

      [styles.withAddon]: !!this.props.addon,
      [styles.loading]: !this.props.options,
    });

    const selectGroupClassNames = classNames('input-group', styles.group, {
      'is-invalid': isInvalid,
    });

    const options = this.props.options
      ? [
          ...(this.props.none
            ? [
                {
                  label: <FormattedMessage id="CORE.NONE" />,
                  value: '',
                },
              ]
            : []),
          ...this.props.options.map(option => ({
            label: option.name,
            value: option.id,
          })),
        ]
      : [];

    const Component = this.props.creatable ? Creatable : Select;

    return (
      <ConnectedGroupComponent id={id} {...this.props}>
        <div className={selectGroupClassNames} data-name={this.props.input.name}>
          {!!this.props.options || (
            <Component
              id={id}
              options={options}
              disabled={true}
              className={selectClassName}
              placeholder={this.props.placeholder}
            />
          )}

          {!!this.props.options && (
            <Component
              id={id}
              autoBlur
              options={options}
              onChange={data =>
                this.props.input.onChange(this.props.multi ? data.map(({ value }) => value) : data.value)
              }
              onBlur={() => this.props.input.onBlur(this.props.input.value)}
              value={this.props.input.value}
              className={selectClassName}
              searchable={!!this.props.multi}
              clearable={false}
              {...pick(this.props, [
                'disabled',
                'isOptionUnique',
                'multi',
                'onNewOptionClick',
                'placeholder',
                'promptTextCreator',
              ])}
            />
          )}

          {!!this.props.addon && (
            <span className="input-group-append m-0">
              <span className="input-group-text">
                <TooltipComponent overlay={this.props.addon} placement="top">
                  <span className={styles.info} />
                </TooltipComponent>
              </span>
            </span>
          )}
        </div>
      </ConnectedGroupComponent>
    );
  }
}
