import React from 'react';
import { HeaderComponent } from 'app/core';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';

import { CardComponent } from 'app/shared';
import { VenueSelectContainer } from '../venue-select/venue-select.container';
import { REDIRECTS } from 'app/redux/users/users.saga';
import { hasAccess } from 'app/online-payment/utils/has-access';
// $FlowFixMe
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import styles from 'app/online-payment/online-payment-info/online-payment-info.module.scss';
import type { Props } from './online-payment-info.types';

export const OnlinePaymentInfo = (props: Props) => {
  const { role, intl } = props;

  if (!hasAccess(role)) {
    return (
      <Redirect
        to={{
          pathname: role && REDIRECTS[role] ? REDIRECTS[role] : '/',
        }}
      />
    );
  }

  return (
    <div>
      <HeaderComponent breadcrumbs={[{ icon: 'desktop-payment' }]} />
      <div className={styles.howToPayTitle}>
        <FormattedMessage id={'ONLINE_PAYMENT.HEADER'} tagName="h2" />
      </div>

      <CardComponent>
        <div className="card-header">
          <FormattedMessage id={'ONLINE_PAYMENT.SECTION_1.TITLE'} tagName="h4" />
        </div>
        <div className={styles.howToPayCardBody}>
          <FormattedMessage id={'ONLINE_PAYMENT.SECTION_1.TEXT'} tagName="p" />

          <div className={styles.externalLinkContainer}>
            <a
              href={intl.formatMessage({ id: 'ONLINE_PAYMENT.SECTION_1.URL' })}
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.formatMessage({ id: 'ONLINE_PAYMENT.SECTION_1.LINK_TEXT' })}
            </a>
            <ArrowRight style={{ marginLeft: '7px' }} />
          </div>
        </div>
        <div className="card-header">
          <FormattedMessage id={'ONLINE_PAYMENT.SECTION_2.TITLE'} tagName="h4" />
        </div>
        <div className={styles.howToPayCardBody}>
          <FormattedMessage id={'ONLINE_PAYMENT.SECTION_2.TEXT_1'} tagName="p" />
          <FormattedMessage id={'ONLINE_PAYMENT.SECTION_2.TEXT_2'} tagName="p" />
        </div>

        <VenueSelectContainer convertIdTo="smartid" />
      </CardComponent>
    </div>
  );
};
