import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { push } from 'react-router-redux';
import { get } from 'lodash';

import { editBeneficiarySubscription } from 'app/redux/beneficiaries/beneficiaries.actions';

import { BeneficiaryDetailsSubscriptionsEditComponent } from './beneficiary-details-subscriptions-edit.component';

const mapStateToProps = (
  {
    beneficiaries: { entities: beneficiaries },
    users: {
      current: { subscriptions },
    },
  },
  {
    beneficiary,
    match: {
      params: { subscriptionBeneficiary },
    },
  },
) => {
  // It will take subscription list depending on which user open edit benefits and find selected one
  const subscription = (beneficiary ? beneficiaries[beneficiary].subscriptions : subscriptions).find(
    ({ id }) => id === Number(subscriptionBeneficiary),
  );

  if (!subscription) {
    return {};
  }

  // It will check if every option is equal to 0 to know if user had chosen option to do not get benefit in next months.
  // If no it will do a deepEqual on `subscription.benefitOptions` and every `subscription.subscriptionBenefitOption`
  // to find selected one.
  // If not option has been founded it mean that there is customized option, so select first option as initial
  const benefitOption = subscription.benefitOptions.every(benefitOption => benefitOption.amount === 0)
    ? null
    : get(
        subscription.subscriptionBenefitOptions.find(
          ({ benefits, deductibleAmount }) =>
            !(
              benefits.length !== subscription.benefitOptions.length ||
              benefits.some(
                benefit =>
                  !subscription.benefitOptions.find(
                    subscriptionBenefit =>
                      subscriptionBenefit.id === benefit.id && subscriptionBenefit.amount === benefit.amount,
                  ),
              )
            ) || deductibleAmount === subscription.deductibleAmount,
        ),
        'id',
      ) || subscription.subscriptionBenefitOptions[0].id;

  return {
    subscription,
    initialValues: {
      benefitOption,
      status: subscription.status,
    },
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    pathname,
    beneficiary,
    match: {
      params: { subscriptionBeneficiary },
    },
  },
) => ({
  onClose: () =>
    dispatch(
      push({
        pathname,
        state: {
          keepScrollPosition: true,
        },
      }),
    ),
  onSubmit: body => dispatch(editBeneficiarySubscription(body, Number(subscriptionBeneficiary), beneficiary)),
});

export const BeneficiaryDetailsSubscriptionsEditContainer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'beneficiary-subscription-edit',
  }),
)(BeneficiaryDetailsSubscriptionsEditComponent);
