// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, matchPath } from 'react-router-dom';
import classNames from 'classnames';
import MediaQuery from 'react-responsive';

import { SidebarItemContainer } from 'app/core/sidebar/shared';
import { checkPermissions } from 'app/utils';
import { ModalComponent } from 'app/shared';

import { SidebarNestedWrapperComponent } from './wrapper/sidebar-nested-wrapper.component';

import type { Props, State } from './sidebar-nested.component.types';
import styles from './sidebar-nested.module.scss';

export class SidebarNestedComponent extends Component<Props, State> {
  item: ?HTMLLIElement;
  menu: ?HTMLDivElement;

  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.location.key !== this.props.location.key) {
      this.onCancel();
    }

    if (document.body) {
      if (prevState.isOpened !== this.state.isOpened) {
        if (this.state.isOpened) {
          document.body.classList.add('nav-open');
        } else {
          document.body.classList.remove('nav-open');
        }
      }
    }
  }

  onToggle = () => this.setState((prev: State) => ({ isOpened: !prev.isOpened }));
  onCancel = () => this.setState({ isOpened: false });

  getCoordinates = () => {
    const { item, menu } = this;

    if (!item) return {};
    if (!menu) return {};

    const itemRect = item.getBoundingClientRect();
    const menuRect = menu.getBoundingClientRect();

    const left = Math.max(itemRect.width - 8, 64);

    const top =
      itemRect.bottom + itemRect.height / 2 < menuRect.height / 2
        ? window.innerHeight - menuRect.height - 16
        : itemRect.top + itemRect.height / 2 - menuRect.height / 2;

    return {
      top,
      left,
    };
  };

  reference = (component: ?HTMLDivElement) => {
    this.menu = component;
    this.forceUpdate();
  };

  render() {
    const links = this.props.links.filter(link => checkPermissions(this.props.permissions, link.permissions));

    const shortcuts =
      this.props.shortcuts &&
      this.props.shortcuts.filter(shortcut => checkPermissions(this.props.permissions, shortcut.permissions));

    if (!links.length) {
      return null;
    }

    if (links.length === 1) {
      return <SidebarItemContainer icon={links[0].icon} name={links[0].name} to={links[0].to} external={links[0].external} />;
    }

    const { location } = this.props;

    return (
      <>
        <SidebarItemContainer
          icon={this.props.icon}
          name={this.props.name}
          className={classNames({
            'ignore-react-onclickoutside': this.state.isOpened,
            [styles.opened]: this.state.isOpened,
          })}
          onClick={this.onToggle}
          isActive={() => links.some(link => matchPath(location.pathname, link.to))}
          liRef={c => (this.item = c)}
        />

        {this.state.isOpened && (
          <MediaQuery minWidth={577}>
            {matches =>
              matches ? (
                <SidebarNestedWrapperComponent onClickOutside={this.onCancel}>
                  <div className={styles.nested} style={this.getCoordinates()} ref={this.reference}>
                    <button className={styles.back} onClick={this.onCancel}>
                      <i className="fi fi-left-arrow-in-circular-button-black-symbol" />
                      <FormattedMessage id="CORE.BACK" />
                    </button>

                    <nav className={styles.nav}>
                      <span className={styles.heading}>
                        <i className={`fi fi-${this.props.icon}`} />
                        {this.props.name}
                      </span>

                      {links.map((link, index) => (
                        <Link to={link.to} key={index}>
                          {link.name}
                          <i className="fi fi-right-arrow-forward" />
                        </Link>
                      ))}
                    </nav>

                    {!!shortcuts &&
                      !!shortcuts.length && (
                        <nav className={classNames(styles.nav, styles.shortcuts)}>
                          <span className={styles.heading}>
                            <i className="fi fi-shortcuts" />
                            <FormattedMessage id="NAV.SHORTCUTS" />
                          </span>

                          {shortcuts.map((link, index) => (
                            <Link to={link.to} key={index}>
                              {link.name}
                              <i className="fi fi-right-arrow-forward" />
                            </Link>
                          ))}
                        </nav>
                      )}
                  </div>
                </SidebarNestedWrapperComponent>
              ) : (
                <ModalComponent onClose={() => this.setState({ isOpened: false })}>
                  <nav className={styles.nav}>
                    <span className={styles.heading}>
                      <i className={`fi fi-${this.props.icon}`} />
                      {this.props.name}
                    </span>
                    {links.map((link, index) => (
                      <Link to={link.to} key={index}>
                        {link.name}
                        <i className="fi fi-right-arrow-forward" />
                      </Link>
                    ))}
                  </nav>
                  {!!shortcuts &&
                    !!shortcuts.length && (
                      <nav className={classNames(styles.nav, styles.shortcuts)}>
                        <span className={styles.heading}>
                          <i className="fi fi-shortcuts" />
                          <FormattedMessage id="NAV.SHORTCUTS" />
                        </span>

                        {shortcuts.map((link, index) => (
                          <Link to={link.to} key={index}>
                            {link.name}
                            <i className="fi fi-right-arrow-forward" />
                          </Link>
                        ))}
                      </nav>
                    )}
                </ModalComponent>
              )
            }
          </MediaQuery>
        )}
      </>
    );
  }
}
