import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { InfoBoxComponent } from './info-box.component';
import { closeInfoBox } from 'app/redux/view/view.actions';

const mapStateToProps = ({ users: { role }, view: { showInfoBox } }) => ({ showInfoBox, role });

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeInfoBox()),
});

export const InfoBoxContainer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(InfoBoxComponent);
