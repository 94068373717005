import { RSAA } from 'redux-api-middleware';

import * as types from './metabase-download-pdf.action-types';

export const fetchMetabaseData = business => ({
  [RSAA]: {
    types: [types.FETCH_METABASE_DATA_REQUEST, types.FETCH_METABASE_DATA_SUCCESS, types.FETCH_METABASE_DATA_FAILURE],
    endpoint: `/metabase-json-data/${business}`,
    method: 'GET',
  },
});
