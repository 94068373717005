import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import { withPermissions } from 'app/common';

import { VenuesListTileComponent } from './venues-list-tile.component';

export const VenuesListTileContainer = compose(
  injectIntl,
  withPermissions,
)(VenuesListTileComponent);
