// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

import { GroupComponent, PlaceholderComponent } from 'app/shared';

import type { Props } from './customer-information.placeholder.types';

export class CustomerInformationPlaceholder extends Component<Props> {
  render() {
    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    return (
      <>
        <div className="card-header">
          <h4 className="card-title">{this.props.intl.formatMessage({ id: 'CUSTOMERS.GENERAL_INFORMATION' })}</h4>
        </div>
        <div className="card-body">
          <div className="row">
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'CUSTOMERS.NAME' })} required>
                <PlaceholderComponent height={42} radius={8} color="light" />
              </GroupComponent>
            </div>
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'CUSTOMERS.BUSINESS_ID' })} required>
                <PlaceholderComponent height={42} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
          <div className="row">
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'CUSTOMERS.PHONE' })} required>
                <PlaceholderComponent height={42} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
        </div>
        <div className="card-header card-header--between">
          <h4 className="card-title">{this.props.intl.formatMessage({ id: 'CUSTOMERS.OFFICIAL_ADDRESS' })}</h4>
        </div>
        <div className="card-body">
          <div className="row">
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'CUSTOMERS.STREET_ADDRESS' })} required>
                <PlaceholderComponent height={42} radius={8} color="light" />
              </GroupComponent>
            </div>
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'CUSTOMERS.ZIP_CODE' })} required>
                <PlaceholderComponent height={42} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
          <div className="row">
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'CUSTOMERS.CITY' })} required>
                <PlaceholderComponent height={42} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
        </div>
      </>
    );
  }
}
