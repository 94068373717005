// @flow

import React, { Component } from 'react';

import type { Props } from './sidebar-group.component.types';
import styles from './sidebar-group.module.scss';

export class SidebarGroupComponent extends Component<Props> {
  render() {
    return <ul className={styles.group}>{this.props.children}</ul>;
  }
}
