// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

import { Field, FieldArray } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import { AlertComponent, ButtonComponent, ConnectedDatePickerComponent, ConnectedInputComponent } from 'app/shared';
import { shouldFieldArrayUpdate } from 'app/utils';

import { BenefitTableContainer } from './table/benefit-table.container';

import type { Props } from './benefits.component.types';
import styles from './benefits.module.scss';

export class BenefitsComponent extends Component<Props> {
  shouldComponentUpdate = shouldFieldArrayUpdate;

  onBenefitAdd = () => this.props.fields.push({ benefits: [{}] });

  onBenefitRemove = (index: number) => this.props.fields.remove(index);

  isMoreThanOneBenefit = () => this.props.fields.length > 1;

  render() {
    return (
      <>
        {this.props.fields.map((field, index) => (
          <div key={index}>
            <div className="card-header card-header--with-line">
              <h4 className={classNames('card-title', styles.title)}>
                <FormattedMessage id="SUBSCRIPTION.BENEFIT_OPTION" /> {`${index + 1}`}
                {this.isMoreThanOneBenefit() && (
                  <ButtonComponent onClick={() => this.onBenefitRemove(index)} theme="outline-danger">
                    <FormattedMessage id="SUBSCRIPTION.REMOVE_OPTION" />
                  </ButtonComponent>
                )}
              </h4>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <Field
                    name={`${field}.title`}
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.LABEL.BENEFIT_OPTION_TITLE' })}
                    required
                  />
                </div>
              </div>

              <FieldArray component={BenefitTableContainer} name={`${field}.benefits`} />

              <AlertComponent type="light" className="text-left">
                <FormattedMessage id="SUBSCRIPTION.INSTRUCTION_BOX.BENEFIT_OPTION" tagName="p" />
              </AlertComponent>
              <div className="row">
                <div
                  className={classNames(
                    'col-12',
                    'col-xxl-4',
                    'col-xl-3',
                    { 'col-lg-3': this.props.sidebarCollapsed },
                    styles.benefitInput,
                  )}
                >
                  <Field
                    name={`${field}.deductibleAmount`}
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.LABEL.DEDUCTIBLE_AMOUNT' })}
                    icon="euro-symbol"
                    type="number"
                  />
                </div>
                <div
                  className={classNames(
                    'col-12',
                    'col-xl-3',
                    { 'col-lg-3': this.props.sidebarCollapsed },
                    styles.deductibleDate,
                    styles.benefitInput,
                  )}
                >
                  <Field
                    name={`${field}.deductibleDate`}
                    component={ConnectedDatePickerComponent}
                    onFocusChange={this.props.onCalendarToggle}
                    label={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.LABEL.DEDUCTIBLE_DATE' })}
                    parse={value => (value ? value.format('YYYY-MM-DD') : null)}
                    intl={this.props.intl}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="card-body card-body--between">
          <button type="button" className={styles.button} onClick={() => this.onBenefitAdd()}>
            + <FormattedMessage id="SUBSCRIPTION.ADD_BENEFIT_OPTION" />
          </button>
        </div>
      </>
    );
  }
}
