import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import { withCoreContext } from 'app/core/core.context';

import { CardsBeneficiariesOrderPlaceholder } from './cards-beneficiaries-order.placeholder';

export const CardsBeneficiariesOrderPlaceholderContainer = compose(
  injectIntl,
  withCoreContext,
)(CardsBeneficiariesOrderPlaceholder);
