import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, reset, formValueSelector } from 'redux-form';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redux';
import { get, debounce } from 'lodash';

import { addBeneficiary } from 'app/redux/subscriptions/subscriptions.actions';
import { filterBeneficiaries } from 'app/redux/beneficiaries/beneficiaries.actions';

import { AddBeneficiaryComponent } from './add-beneficiary.component';

const selectorFactory = state => name => formValueSelector('add-beneficiary')(state, name);

const mapStateToProps = (state, { benefitOptions }) => {
  const beneficiaries = state.beneficiaries;
  const business = state.users.business;
  const selector = selectorFactory(state);

  return {
    beneficiaries: get(beneficiaries, [business, 'list']),
    filteredBeneficiaries: get(beneficiaries, 'filteredUsers'),
    loadingUsers: get(beneficiaries, 'loadingUsers'),
    initialValues: {
      benefits: benefitOptions.map(benefit => ({
        id: benefit,
      })),
    },
    formValues: {
      benefits: selector('benefits'),
      beneficiaryId: selector('beneficiaryId'),
      deductibleAmount: selector('deductibleAmount'),
      beneficiary: selector('beneficiary'),
    },
  };
};

const mapDispatchToProps = (dispatch, { subscription, type, beneficiariesList }) => ({
  onSubmit: body =>
    dispatch(addBeneficiary(subscription, body)).then(() => {
      const name =
        type === 'emoney'
          ? get(beneficiariesList.find(({ id }) => body.beneficiaryId === id), 'name')
          : `${body.beneficiary.firstName} ${body.beneficiary.lastName}`;

      return dispatch(
        push({
          pathname: `/subscriptions/${type}/${subscription}/beneficiaries`,
          state: {
            success: name,
          },
        }),
      );
    }),
  onClose: () =>
    dispatch(
      push({
        pathname: `/subscriptions/${type}/${subscription}/beneficiaries`,
      }),
    ),
  onFiltersChange: debounce(
    values => dispatch(filterBeneficiaries({ name: values.name, email: values.email, ssn: values.ssn })),
    500,
  ),
  onFiltersClear: () =>
    Promise.all([
      dispatch(filterBeneficiaries({ name: '', email: '', ssn: '' })),
      dispatch(reset('add-beneficiary-filters')),
    ]),
});

export const AddBeneficiaryContainer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({ form: 'add-beneficiary' }),
)(AddBeneficiaryComponent);
