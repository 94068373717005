// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { ButtonComponent, NoResultsContainer, TableComponent, ModalComponent } from 'app/shared';

import { SubscriptionBeneficiariesEditableContainer } from '../editable/subscription-beneficiaries-editable.container';
import { SubscriptionBeneficiariesStatusComponent } from '../status/subscription-beneficiaries-status.component';

import styles from './subscription-beneficiaries-table.module.scss';
import type { Props, State } from './subscription-beneficiaries-table.component.types';

export class SubscriptionBeneficiariesTableComponent extends Component<Props, State> {
  state = { isAcceptConfirmationVisible: false, isRejectConfirmationVisible: false };

  showConfirmationModal = (type: string) => {
    if (type === 'ACCEPT') {
      this.setState({ isAcceptConfirmationVisible: true });
    } else {
      this.setState({ isRejectConfirmationVisible: true });
    }
  };

  hideConfirmationModal = () => {
    this.setState({ isAcceptConfirmationVisible: false, isRejectConfirmationVisible: false });
  };

  changeStatus = (status: string) => {
    this.hideConfirmationModal();
    return this.props.onBulkEdit({ status: status });
  };

  render() {
    // 4 is a number of static columns
    const columnsCount = 4 + this.props.benefits.length;
    const buttonsClassNames = classNames('text-right', styles.buttons);

    return (
      <div className="table-responsive">
        <TableComponent className="table-striped">
          <thead>
            <tr>
              <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.NAME" tagName="th" />
              {this.props.benefits.map((benefit, index) => (
                <FormattedMessage id={`PRODUCT.${benefit}`} key={index} tagName="th" />
              ))}
              <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.EXCESS" tagName="th" />
              <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.STATUS" tagName="th" />
              <th />
            </tr>
          </thead>
          <tbody>
            {!!this.props.list.length || (
              <tr>
                <td colSpan={columnsCount}>
                  <NoResultsContainer />
                </td>
              </tr>
            )}

            {this.props.list.map(beneficiary => (
              <tr key={beneficiary.id}>
                <td>
                  {beneficiary.id && beneficiary.user.firstName && beneficiary.user.lastName ? (
                    <Link
                      to={{
                        pathname: `${this.props.url}/${beneficiary.id}`,
                        search: this.props.location.search,
                        state: {
                          keepScrollPosition: true,
                        },
                      }}
                    >
                      {beneficiary.user.firstName} {beneficiary.user.lastName}
                    </Link>
                  ) : (
                    beneficiary.user.email
                  )}
                </td>
                {this.props.benefits.map((benefit, index) => (
                  <td key={index}>
                    <SubscriptionBeneficiariesEditableContainer
                      disabled={this.props.disabled}
                      value={beneficiary.items[benefit] || 0}
                      type={beneficiary.type}
                      benefit={benefit}
                      errorPath={['errors', 'benefits', 0, 'amount']}
                      onSubmit={amount =>
                        this.props.onEdit(beneficiary.id, {
                          benefits: [
                            {
                              amount,
                              id: benefit,
                            },
                          ],
                        })
                      }
                    />
                  </td>
                ))}
                <td>
                  <SubscriptionBeneficiariesEditableContainer
                    disabled={this.props.disabled}
                    value={beneficiary.deductibleAmount || 0}
                    errorPath={['errors', 'deductibleAmount']}
                    onSubmit={deductibleAmount => this.props.onEdit(beneficiary.id, { deductibleAmount })}
                  />
                </td>
                <td>
                  <SubscriptionBeneficiariesStatusComponent status={beneficiary.status} />
                </td>
                <td className={buttonsClassNames}>
                  {this.props.disabled || (
                    <>
                      {beneficiary.status === 'APPROVED' || (
                        <ButtonComponent
                          theme="outline-secondary"
                          size="small"
                          onClick={() => this.props.onEdit(beneficiary.id, { status: 'APPROVED' })}
                          loading={beneficiary.submitting}
                        >
                          <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.APPROVE" />
                        </ButtonComponent>
                      )}
                      {beneficiary.status === 'DECLINED' || (
                        <ButtonComponent
                          theme="outline-secondary"
                          size="small"
                          onClick={() => this.props.onEdit(beneficiary.id, { status: 'DECLINED' })}
                          loading={beneficiary.submitting}
                        >
                          <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.REJECT" />
                        </ButtonComponent>
                      )}
                    </>
                  )}
                </td>
              </tr>
            ))}
            <tr className={styles.footer}>
              <td colSpan={columnsCount}>
                <div className={classNames('d-flex', styles.box)}>
                  {this.props.disabled || (
                    <ButtonComponent
                      theme="secondary"
                      size="small"
                      className="ml-0 ml-xl-0 mr-4"
                      to={`${this.props.url}/add`}
                    >
                      <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.ADD" />
                    </ButtonComponent>
                  )}
                  <ButtonComponent
                    theme="outline-secondary"
                    size="small"
                    onClick={this.props.onDownload}
                    disabled={this.props.loading || !this.props.list.length}
                  >
                    <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.DOWNLOAD" />
                    <i className="fi fi-xlsx-file-format-extension" />
                  </ButtonComponent>
                  {this.props.disabled || (
                    <>
                      <ButtonComponent
                        theme="outline-secondary"
                        size="small"
                        onClick={this.props.onModify}
                        disabled={this.props.loading || !this.props.list.length}
                        className="ml-2"
                      >
                        <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.MODIFY_ALL" />
                      </ButtonComponent>

                      <ButtonComponent
                        theme="outline-success"
                        size="small"
                        onClick={() => this.showConfirmationModal('ACCEPT')}
                        disabled={this.props.loading || !this.props.list.length}
                        className="ml-auto"
                      >
                        <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.APPROVE_ALL" />
                      </ButtonComponent>

                      <ButtonComponent
                        theme="outline-danger"
                        size="small"
                        onClick={() => this.showConfirmationModal('REJECT')}
                        disabled={this.props.loading || !this.props.list.length}
                        className="ml-2"
                      >
                        <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.REJECT_ALL" />
                      </ButtonComponent>
                    </>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </TableComponent>
        {this.state.isAcceptConfirmationVisible && (
          <ModalComponent
            title={<FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.APPROVE_ALL" />}
            onClose={this.hideConfirmationModal}
            submitButton={
              <ButtonComponent theme="primary" size="medium" onClick={() => this.changeStatus('APPROVED')}>
                <FormattedMessage id="CORE.YES" />
              </ButtonComponent>
            }
          >
            <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.CONFIRM_APPROVE_ALL" tagName="p" />
          </ModalComponent>
        )}
        {this.state.isRejectConfirmationVisible && (
          <ModalComponent
            title={<FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.REJECT_ALL" />}
            onClose={this.hideConfirmationModal}
            submitButton={
              <ButtonComponent theme="primary" size="medium" onClick={() => this.changeStatus('DECLINED')}>
                <FormattedMessage id="CORE.YES" />
              </ButtonComponent>
            }
          >
            <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.CONFIRM_REJECT_ALL" tagName="p" />
          </ModalComponent>
        )}
      </div>
    );
  }
}
