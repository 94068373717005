// @flow

import React, { Component } from 'react';
import IframeResizer from 'iframe-resizer';
import { FormattedMessage } from 'react-intl';

import { HeaderComponent } from 'app/core';
import { USER_ROLES } from 'app/constants';
import type { Props } from './metabase-dashboard.component.types';

export class MetabaseDashboardComponent extends Component<Props> {
  getDashboardId = () => {
    if (
      this.props.role === USER_ROLES.ROLE_SERVICE_PROVIDER_MANAGER ||
      this.props.businessType === 'TYPE_SERVICE_PROVIDER'
    ) {
      return process.env.REACT_APP_METABASE_DASHBOARD_ID_SP_MANAGER;
    }

    return process.env.REACT_APP_METABASE_DASHBOARD_ID_VENUE_ACCOUNTANT;
  };

  componentDidMount() {
    this.props.fetchDashboard({
      resource: {
        dashboard: this.getDashboardId(),
      },
      params: {
        id: this.props.business,
      },
      exp: Math.round(Date.now() / 1000) + 10 * 60,
    });
  }

  render() {
    return (
      <>
        <HeaderComponent breadcrumbs={[{ icon: 'graphic' }]}>
          <FormattedMessage id="METABASE.TITLE" tagName="h2" />
        </HeaderComponent>

        {this.props.hasError && <FormattedMessage id="CORE.GENERAL_ERROR_400" tagName="h2" />}

        {this.props.url && (
          <iframe
            id="metabase-iframe"
            title="Metabase"
            src={this.props.url}
            onLoad={() => IframeResizer.iframeResize({}, '#metabase-iframe')}
            frameBorder="0"
            width="100%"
            height="1000"
            allowtransparency="true"
            style={{
              width: '1px',
              minWidth: '100%',
            }}
          />
        )}
      </>
    );
  }
}
