// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import type { Props } from './no-results.component.types.flow';
import styles from './no-results.module.scss';

export class NoResultsComponent extends PureComponent<Props> {
  static defaultProps = {
    searchable: true,
  };

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.icon}>
          <i className="fi fi-computer" />
          <i className="fi fi-magnifying-glass" />
        </div>
        <FormattedMessage id="CORE.NO_RESULTS" tagName="h4" />
        {this.props.searchable ? (
          <FormattedMessage id="CORE.NO_RESULTS_SEARCHABLE_DESC" tagName="p" />
        ) : (
          <FormattedMessage id="CORE.NO_RESULTS_DESC" tagName="p" />
        )}
      </div>
    );
  }
}
