// @flow

import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import type { Breadcrumb, Props } from './breadcrumbs.component.types';
import styles from './breadcrumbs.module.scss';

export class BreadcrumbsComponent extends PureComponent<Props> {
  breadcrumbContent = (breadcrumb: Breadcrumb) =>
    breadcrumb.icon ? <i className={`fi fi-${breadcrumb.icon}`} /> : breadcrumb.name;

  breadcrumbClassName = (breadcrumb: Breadcrumb) => (breadcrumb.icon ? styles.icon : styles.breadcrumb);

  render() {
    return (
      <div className={styles.breadcrumbs}>
        {this.props.breadcrumbs.map((breadcrumb: Breadcrumb, index: number) => {
          if (!breadcrumb.name && !breadcrumb.icon) return null;

          if (breadcrumb.to) {
            const to = breadcrumb.to;

            return (
              <Link key={index} className={this.breadcrumbClassName(breadcrumb)} to={to}>
                {this.breadcrumbContent(breadcrumb)}
              </Link>
            );
          }

          if (breadcrumb.onClick) {
            return (
              <button key={index} onClick={breadcrumb.onClick} className={this.breadcrumbClassName(breadcrumb)}>
                {this.breadcrumbContent(breadcrumb)}
              </button>
            );
          }

          return (
            <span key={index} className={this.breadcrumbClassName(breadcrumb)}>
              {this.breadcrumbContent(breadcrumb)}
            </span>
          );
        })}
      </div>
    );
  }
}
