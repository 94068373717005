// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { TransactionDetailsPlaceholder } from './placeholder/transaction-details.placeholder';

import { AlertComponent, ButtonComponent, ModalComponent } from 'app/shared';
import { checkPermissions, currencyFormatter, dateFormatter } from 'app/utils';

import type { Props, State } from './transaction-details.component.types';
import styles from './transaction-details.module.scss';

export class TransactionDetailsComponent extends Component<Props, State> {
  state = {
    isConfirmationVisible: false,
  };

  componentDidMount() {
    this.props.fetchTransaction(this.props.match.params.transaction);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.match.params.transaction !== this.props.match.params.transaction) {
      this.props.fetchTransaction(this.props.match.params.transaction);
    }
    if (
      prevProps.location.state &&
      this.props.location.state &&
      prevProps.location.state.success !== this.props.location.state.success
    ) {
      this.onCloseConfirmation();
    }
  }

  onClickRefund = () => {
    this.setState({ isConfirmationVisible: true });
  };

  onCloseConfirmation = () => {
    this.setState({ isConfirmationVisible: false });
  };

  render() {
    if (this.props.loading) {
      return <TransactionDetailsPlaceholder onClose={this.props.onClose} styles={styles} />;
    }

    const amountClassNames = classNames(styles.amount, {
      [styles.income]: this.props.transaction.amount > 0,
      [styles.expense]: this.props.transaction.amount < 0,
    });

    const typeClassNames = classNames(styles.type, {
      [styles.positive]: this.props.transaction.amount > 0,
      [styles.negative]: this.props.transaction.amount < 0,
    });

    return (
      <>
        {!this.state.isConfirmationVisible && (
          <ModalComponent
            onClose={this.props.onClose}
            changeButtonsOrder={true}
            submitButton={
              checkPermissions(this.props.permissions, ['companyTransactions', 'update']) &&
              this.props.transaction.refundable && (
                <ButtonComponent theme="link" size="medium" className="ml-3" onClick={this.onClickRefund}>
                  <FormattedMessage id="TRANSACTIONS.REFUND" />
                </ButtonComponent>
              )
            }
          >
            {!!this.props.location.state &&
              !!this.props.location.state.success && (
                <AlertComponent type="success">
                  {this.props.location.state.success.type === 'MOBILE_PAYMENT_CREATED' && (
                    <FormattedMessage id="MOBILE_PAYMENT.CREATE_SUCCESS" />
                  )}

                  {this.props.location.state.success.type === 'TRANSACTION_REFUNDED' && (
                    <FormattedMessage id="TRANSACTIONS.REFUND_SUCCESS" />
                  )}
                </AlertComponent>
              )}

            <div className={styles.header}>
              <h4 className="modal-title">
                {this.props.intl.formatMessage({ id: 'TRANSACTIONS.NUMBER' })} #{this.props.transaction.id}
              </h4>
              <span className={typeClassNames}>
                {this.props.intl.formatMessage({ id: `TRANSACTION_TYPE.${this.props.transaction.type}` })}
              </span>
            </div>
            <div className={styles.date}>
              <i className="fi fi-time" />
              {this.props.transaction.date}
            </div>
            <div className={styles.body}>
              <p>
                <strong>{this.props.intl.formatMessage({ id: 'TRANSACTIONS.DEBTOR' })}:</strong>{' '}
                {this.props.transaction.debitor}
              </p>
              <p>
                <strong>{this.props.intl.formatMessage({ id: 'TRANSACTIONS.CREDITOR' })}:</strong>{' '}
                {this.props.transaction.creditor}
              </p>
              <p>
                <strong>{this.props.intl.formatMessage({ id: 'TRANSACTIONS.ITEM' })}:</strong>{' '}
                {this.props.intl.formatMessage({ id: `PAYMENT.ITEM.${this.props.transaction.item}` })}
              </p>
              {this.props.transaction.referenceId && (
                <p>
                  <strong>{this.props.intl.formatMessage({ id: 'TRANSACTIONS.REFERENCE' })}:</strong>{' '}
                  {this.props.transaction.referenceId}
                </p>
              )}
              {!!this.props.transaction.message && (
                <p>
                  <strong>{this.props.intl.formatMessage({ id: 'TRANSACTIONS.MESSAGE' })}:</strong>{' '}
                  {this.props.transaction.message}
                </p>
              )}
              {this.props.transaction.serviceProducedStart &&
                this.props.transaction.serviceProducedEnd && (
                  <p>
                    <strong>{this.props.intl.formatMessage({ id: 'TRANSACTIONS.SERVICE_PRODUCED' })}:</strong>{' '}
                    {dateFormatter(((this.props.transaction.serviceProducedStart: any): string))} -{' '}
                    {dateFormatter(((this.props.transaction.serviceProducedStart: any): string))}
                  </p>
                )}
              {!!this.props.transaction.refunds && (
                <p>
                  <strong>{this.props.intl.formatMessage({ id: 'TRANSACTIONS.REFUNDS' })}:</strong>{' '}
                  {!!this.props.match.params.account && (
                    <Link
                      to={`${this.props.pathname}/${this.props.match.params.account}/${this.props.transaction.refunds}`}
                    >
                      {this.props.transaction.refunds}
                    </Link>
                  )}
                  {!!this.props.match.params.account || (
                    <Link to={`${this.props.pathname}/${this.props.transaction.refunds}`}>
                      {this.props.transaction.refunds}
                    </Link>
                  )}
                </p>
              )}
              {!!this.props.transaction.refundedBy && (
                <p>
                  <strong>{this.props.intl.formatMessage({ id: 'TRANSACTIONS.REFUNDED_BY' })}:</strong>{' '}
                  {!!this.props.match.params.account && (
                    <Link
                      to={`${this.props.pathname}/${this.props.match.params.account}/${
                        this.props.transaction.refundedBy
                      }`}
                    >
                      {this.props.transaction.refundedBy}
                    </Link>
                  )}
                  {!!this.props.match.params.account || (
                    <Link to={`${this.props.pathname}/${this.props.transaction.refundedBy}`}>
                      {this.props.transaction.refundedBy}
                    </Link>
                  )}
                </p>
              )}
            </div>

            <h4 className={amountClassNames}>{currencyFormatter.format(Math.abs(this.props.transaction.amount))}</h4>

            <div className={styles.bottom}>
              {this.props.transaction.settlement && (
                <p>
                  <strong>{this.props.intl.formatMessage({ id: 'TRANSACTIONS.SETTLEMENT_DATE' })}:</strong>{' '}
                  {dateFormatter(((this.props.transaction.settlement: any): string))}
                </p>
              )}
              {this.props.transaction.municipalAccount && (
                <p>
                  <strong>
                    <FormattedMessage id="TRANSACTIONS.MUNICIPAL_ACCOUNT" />:
                  </strong>{' '}
                  <Link to={`/coupons/${this.props.transaction.municipalAccount}`}>
                    {this.props.transaction.municipalAccount}
                  </Link>
                </p>
              )}
              {this.props.transaction.couponType && (
                <p>
                  <strong>{this.props.intl.formatMessage({ id: 'TRANSACTIONS.SERVICE_COUPON_TYPE' })}:</strong>{' '}
                  {this.props.transaction.couponType}
                </p>
              )}
              {this.props.transaction.priceUnit && (
                <p>
                  <strong>{this.props.intl.formatMessage({ id: 'TRANSACTIONS.PRICE_UNIT' })}:</strong>{' '}
                  {this.props.transaction.priceUnit}
                </p>
              )}
              {this.props.transaction.priceUnitNumber && (
                <p>
                  <strong>{this.props.intl.formatMessage({ id: 'TRANSACTIONS.NUMBER_OF_PRICE_UNITS' })}:</strong>{' '}
                  {this.props.transaction.priceUnitNumber}
                </p>
              )}
              {this.props.transaction.decision && (
                <p>
                  <strong>{this.props.intl.formatMessage({ id: 'TRANSACTIONS.SERVICE_DECISION_VALUE' })}:</strong>{' '}
                  {this.props.transaction.decision}
                </p>
              )}
            </div>
          </ModalComponent>
        )}
        {this.state.isConfirmationVisible && (
          <ModalComponent
            title={this.props.intl.formatMessage({ id: 'CORE.WARNING' })}
            onClose={this.onCloseConfirmation}
            submitButton={
              <ButtonComponent
                theme="primary"
                size="medium"
                loading={this.props.transaction.refunding}
                onClick={this.props.onRefund}
              >
                <FormattedMessage id="TRANSACTIONS.REFUND" />
              </ButtonComponent>
            }
          >
            <FormattedMessage id="CORE.ARE_YOU_SURE" />
          </ModalComponent>
        )}
      </>
    );
  }
}
