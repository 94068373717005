// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Switch } from 'react-router-dom';

import { debounce } from 'lodash';

import { TransactionDetailsContainer } from '../details/transaction-details.container';
import { TransactionsCompanyFiltersContainer } from './filters/transactions-company-filters.container';
import { TransactionsCompanyPlaceholder } from './placeholder/transactions-company.placeholder';
import { TransactionsCompanyTableComponent } from './table/transactions-company-table.component';
import { TransactionsCompanyTileComponent } from './tile/transactions-company-tile.component';

import { HeaderComponent } from 'app/core';
import { ListComponent } from 'app/shared/list/list.component';
import { ButtonComponent, CardComponent, ModalComponent } from 'app/shared';
import { currencyFormatter } from 'app/utils';

import { generateStatus } from 'app/redux/transactions/transactions.reducer';

import type { Props } from './transactions-company.component.types';
import styles from '../transactions.module.scss';

export class TransactionsCompanyComponent extends Component<Props> {
  renderTransactionList() {
    return this.props.transactions.slice(
      this.props.rowsPerPage * (this.props.currentPage - 1),
      this.props.rowsPerPage * this.props.currentPage,
    );
  }

  render() {
    const noResults = this.props.loading || !this.props.transactions.length;
    const generateStatusPending = this.props.generateStatus === generateStatus.PENDING;
    const generateStatusFailed = this.props.generateStatus === generateStatus.FAILED;

    return (
      <>
        <HeaderComponent
          breadcrumbs={[
            { icon: 'transactionhistory' },
            { name: <FormattedMessage id="SIDEBAR.COMPANY_TRANSACTIONS" /> },
          ]}
          button={
            <ButtonComponent
              size="large"
              theme="white"
              onClick={() =>
                this.props.onDownload({
                  businessId: this.props.business,
                  filters: this.props.filters,
                })
              }
              disabled={noResults}
            >
              <i className="fi fi-xlsx-file-format-extension" />
              <FormattedMessage id="TRANSACTIONS.DOWNLOAD_AS_XLSX" />
            </ButtonComponent>
          }
        >
          <FormattedMessage id="TRANSACTIONS.COMPANY_TRANSACTIONS" tagName="h2" />
        </HeaderComponent>

        {generateStatusPending && (
          <ModalComponent title={<FormattedMessage id="TRANSACTIONS.GENERATE_XLS" />} isClosingBlocked />
        )}
        {generateStatusFailed && (
          <ModalComponent
            title={<FormattedMessage id="TRANSACTIONS.GENERATE_XLS_ERROR" />}
            onClose={this.props.onClose}
          />
        )}

        <CardComponent>
          <TransactionsCompanyFiltersContainer onChange={debounce(this.props.onFiltersChange, 500)} />

          {this.props.loading && <TransactionsCompanyPlaceholder />}

          {this.props.loading || (
            <>
              <div className="card-header card-header--between">
                <h4 className="card-title">
                  <FormattedMessage
                    id="TRANSACTIONS.TOTAL_TRANSACTION_COUNTER"
                    values={{ total: this.props.meta.total }}
                  />
                </h4>
                {!!this.props.transactions.length && (
                  <h6 className={styles.totalAmount}>
                    <FormattedMessage
                      id="TRANSACTIONS.TOTAL_AMOUNT"
                      values={{ total: currencyFormatter.format(this.props.totalAmount) }}
                    />
                  </h6>
                )}
              </div>
              <div className="card-body">
                <ListComponent
                  list={this.renderTransactionList()}
                  table={TransactionsCompanyTableComponent}
                  tile={TransactionsCompanyTileComponent}
                  props={{
                    permissions: this.props.permissions,
                  }}
                />
                {this.props.renderPagination(this.props.meta.total)}
              </div>
            </>
          )}
        </CardComponent>

        <Switch>
          <Route
            path={`${this.props.match.url}/:account/:transaction`}
            render={props => <TransactionDetailsContainer pathname={this.props.match.url} {...props} />}
          />
        </Switch>
      </>
    );
  }
}
