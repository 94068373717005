// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

import type { Props } from './sidebar-user-business.component.types';
import styles from './sidebar-user-business.module.scss';

export class SidebarUserBusinessComponent extends Component<Props> {
  render() {
    const businessClassNames = classNames(styles.business, {
      [styles.active]: this.props.business.id === this.props.selected,
    });

    return (
      <button className={businessClassNames} onClick={this.props.onSelect}>
        <span className={styles.name}>{this.props.business.id} · {this.props.business.name}</span>

        <span>
          {this.props.intl.formatMessage({
            id: this.props.business.id === this.props.selected ? 'SIDEBAR.ACTIVE' : 'SIDEBAR.SELECT',
          })}
        </span>
      </button>
    );
  }
}
