// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { HeaderComponent } from 'app/core';
import { CardComponent } from 'app/shared';

import { VenueFormPlaceholderContainer } from 'app/venues/form/placeholder/venue-form.placeholder.container';
import { VenueFormContainer } from './venue-form.container';

import type { Props } from './add-venue.component.types';

export class AddVenueComponent extends Component<Props> {
  componentDidMount() {
    this.props.fetchDictionary();
  }

  render() {
    return (
      <>
        <HeaderComponent
          breadcrumbs={[
            {
              icon: 'business',
              to: '/venues',
            },
            {
              name: <FormattedMessage id="VENUES.ADD_NEW_VENUE" />,
            },
          ]}
        >
          <FormattedMessage id="VENUES.ADD_NEW_VENUE" tagName="h2" />
        </HeaderComponent>

        <CardComponent>
          {this.props.loading && <VenueFormPlaceholderContainer />}
          {this.props.loading || <VenueFormContainer />}
        </CardComponent>
      </>
    );
  }
}
