import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { PaymentsTokenAccountsComponent } from './payments-token-accounts.component';

import { fetchPaymentToken, fetchPaymentsAccounts } from 'app/redux/payments/payments.actions';

const mapStateToProps = ({ payments: { accounts }, users: { business } }) => ({
  accounts,
  loading: !accounts,
  business,
});

const mapDispatchToProps = dispatch => ({
  fetchPaymentsAccounts: () => dispatch(fetchPaymentsAccounts()),
  fetchPaymentToken: accountNumber => dispatch(fetchPaymentToken(accountNumber)),
});

export const PaymentsTokenAccountsContainer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(PaymentsTokenAccountsComponent);
