// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { currencyFormatter } from 'app/utils';

import { ModalComponent, SummaryFieldComponent } from 'app/shared';
import { SubscriptionBeneficiariesStatusComponent } from '../status/subscription-beneficiaries-status.component';

import type { Props } from './subscription-beneficiaries-details.component.types';
import styles from './subscription-beneficiaries-details.module.scss';

export class SubscriptionBeneficiariesDetailsComponent extends Component<Props> {
  render() {
    return (
      <ModalComponent
        onClose={this.props.onClose}
        title={
          this.props.beneficiary.user.id ? (
            <Link
              to={`/beneficiaries/${this.props.beneficiary.user.id}`}
              className={classNames('d-flex align-items-center', styles.link)}
            >
              <SubscriptionBeneficiariesStatusComponent status={this.props.beneficiary.status} />
              <span className={styles.title}>
                {this.props.beneficiary.user.firstName} {this.props.beneficiary.user.lastName}
              </span>
            </Link>
          ) : (
            <div className="d-flex align-items-center">
              <SubscriptionBeneficiariesStatusComponent status={this.props.beneficiary.status} />
              <span className={styles.title}>
                {this.props.beneficiary.user.firstName} {this.props.beneficiary.user.lastName}
              </span>
            </div>
          )
        }
      >
        <div className="row">
          <div className="col-md-6">
            <SummaryFieldComponent>
              <FormattedMessage id="PROFILE.SSN" tagName="strong" />
              {this.props.beneficiary.user.ssn}
            </SummaryFieldComponent>
          </div>
          <div className="col-md-6">
            <SummaryFieldComponent>
              <FormattedMessage id="PROFILE.MOBILE_PHONE" tagName="strong" />
              {this.props.beneficiary.user.mobilePhone}
            </SummaryFieldComponent>
          </div>
          <div className="col-md-6">
            <SummaryFieldComponent>
              <FormattedMessage id="PROFILE.EMAIL" tagName="strong" />
              {this.props.beneficiary.user.email}
            </SummaryFieldComponent>
          </div>
          {!!this.props.beneficiary.user.deliveryAddress && (
            <div className="col-md-6">
              <SummaryFieldComponent>
                <FormattedMessage id="PROFILE.ADDRESS" tagName="strong" />
                {this.props.beneficiary.user.deliveryAddress.address} <br />
                {this.props.beneficiary.user.deliveryAddress.zipCode} {this.props.beneficiary.user.deliveryAddress.city}
              </SummaryFieldComponent>
            </div>
          )}
        </div>

        <h4 className="mb-4">
          <FormattedMessage id="SUBSCRIPTION.SECTION_TITLES.BENEFIT_OPTION" />
        </h4>

        <div className="row">
          {(Object.entries(this.props.beneficiary.items): any).map(([benefit, amount]: [string, number]) => (
            <div className="col-md-6" key={benefit}>
              <SummaryFieldComponent>
                <FormattedMessage id={`PRODUCT.${benefit}`} tagName="strong" />
                {this.props.type === 'voucher' ? (
                  <>
                    <FormattedMessage id="CORE.BOOKS" values={{ books: amount }} />
                    {` (${currencyFormatter.format(amount * 10 * this.props.multipliers[benefit])})`}
                  </>
                ) : (
                  currencyFormatter.format(amount)
                )}
              </SummaryFieldComponent>
            </div>
          ))}

          {this.props.beneficiary.deductibleAmount !== undefined && (
            <div className="col-md-6">
              <SummaryFieldComponent>
                <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.EXCESS" tagName="strong" />
                {currencyFormatter.format(this.props.beneficiary.deductibleAmount)}
              </SummaryFieldComponent>
            </div>
          )}
        </div>

        {!!this.props.beneficiary.additionalInformation.length && (
          <>
            <h4 className="mb-4">
              <FormattedMessage id="SUBSCRIPTION.SECTION_TITLES.ADDITIONAL_INFORMATION" />
            </h4>

            <div className="row">
              {this.props.beneficiary.additionalInformation.map(({ id, name, value }) => (
                <div className="col-md-6" key={id}>
                  <SummaryFieldComponent>
                    <strong>{name}</strong>
                    {value}
                  </SummaryFieldComponent>
                </div>
              ))}
            </div>
          </>
        )}
      </ModalComponent>
    );
  }
}
