import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { PaymentsTokenCodeComponent } from './payments-token-code.component';

import { fetchPaymentToken } from 'app/redux/payments/payments.actions';
import { download } from 'app/redux/downloads/downloads.actions';

const mapStateToProps = ({ payments: { token } }) => ({
  token,
  loading: !token.hash,
});

const mapDispatchToProps = (dispatch, { intl: { locale } }) => ({
  fetchPaymentToken: accountNumber => dispatch(fetchPaymentToken(accountNumber)),
  onDownload: accountNumber => dispatch(download(`payments/accounts/token/${accountNumber}`, locale)),
});

export const PaymentsTokenCodeContainer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(PaymentsTokenCodeComponent);
