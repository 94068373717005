// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { PlaceholderComponent, TileComponent } from 'app/shared';

export class AccountTransactionsTilePlaceholder extends Component<any> {
  render() {
    return (
      <TileComponent>
        <h3>
          <PlaceholderComponent width={80} height={27} />
        </h3>

        <div className="row">
          <div className="col-md-8">
            <FormattedMessage id="TRANSACTIONS.PAYER_RECEIVER" tagName="small" />
            <PlaceholderComponent width={100} height={18} />
          </div>

          <div className="col-md-4">
            <FormattedMessage id="TRANSACTIONS.AMOUNT" tagName="small" />
            <PlaceholderComponent width={50} height={18} color="green" />
          </div>
        </div>
      </TileComponent>
    );
  }
}
