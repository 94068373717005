// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

import type { Props } from './subscription-beneficiaries-status.component.types';
import styles from './subscription-beneficiaries-status.module.scss';

export class SubscriptionBeneficiariesStatusComponent extends Component<Props> {
  render() {
    const classNamesMapper = {
      APPROVED: 'approved',
      NEW: 'pending',
      DECLINED: 'declined',
    };

    const statusClassNames = classNames(styles.status, styles[classNamesMapper[this.props.status]]);

    const iconClassNames = classNames(styles[classNamesMapper[this.props.status]], 'fi', {
      'fi-check': this.props.status === 'APPROVED',
      'fi-error': this.props.status === 'DECLINED',
    });

    return this.props.status === 'NEW' ? <span className={statusClassNames} /> : <i className={iconClassNames} />;
  }
}
