// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { AgreementsDetailsComponent } from 'app/agreements/details/agreements-details.component';

import type { Props, State } from './agreements-acceptance.component.types';
import styles from 'app/shared/form/connected-checkbox/connected-checkbox.module.scss';

export class AgreementsAcceptanceComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpened: false,
    };
  }

  onChange = (isOpened: boolean) => this.setState({ isOpened });

  render() {
    const labelClassNames = classNames(styles.label, styles.terms, styles.extended, {
      [styles.checked]: this.props.input.value,
    });

    const titleClassNames = classNames(styles.title, styles.link);

    const { loading, terms } = this.props;

    return (
      <>
        <label className={labelClassNames}>
          <input type="checkbox" name="terms" {...this.props.input} className={styles.input} />
          <span className={styles.blank}>{this.props.input.value && <i className="fi fi-correct-symbol" />}</span>
          <span className={titleClassNames}>
            <FormattedMessage
              id="CORE.TERMS_AND_CONDITIONS"
              values={{
                link: (
                  <button type="button" onClick={() => this.onChange(true)}>
                    <FormattedMessage id="CORE.CONTRACT_TERMS" />
                  </button>
                ),
              }}
            />
          </span>
        </label>

        {this.state.isOpened && (
          <AgreementsDetailsComponent
            onClose={() => this.onChange(false)}
            fetchTerms={this.props.fetchTerms}
            loading={(loading: any)}
            terms={terms}
            approved
          />
        )}
      </>
    );
  }
}
