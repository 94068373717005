import { handleActions } from 'redux-actions';

import * as types from './customers.action-types';

const defaultState = {
  entities: {},
};

export const customersReducer = handleActions(
  {
    [types.FETCH_CUSTOMER_REQUEST]: (state, { meta: { customer } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [customer]: undefined,
      },
    }),
    [types.FETCH_CUSTOMER_SUCCESS]: (state, { payload: { data: entity } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [entity.id]: entity,
      },
    }),
    [types.FETCH_CURRENT_CUSTOMER_REQUEST]: state => ({
      ...state,
      entities: {
        current: undefined,
      },
    }),
    [types.FETCH_CURRENT_CUSTOMER_SUCCESS]: (state, { payload: { data: current } }) => ({
      ...state,
      entities: {
        ...state.entities,
        current,
      },
    }),
  },
  defaultState,
);
