// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { BenefitContainer } from '../benefit/benefit.container';

import { getAvailableBenefits } from 'app/utils/benefits/benefits.util';

import type { Props } from './benefits.component.types';
import styles from './benefits.module.scss';

export class BenefitsComponent extends Component<Props> {
  availableBenefits = getAvailableBenefits({ industries: this.props.industries, users: this.props.users });

  render() {
    return (
      <React.Fragment>
        <div className={styles.benefits}>
          <div className={styles.radios}>
            {this.availableBenefits.map((industry, index) => (
              <label
                key={index}
                className={classNames(styles.radio, {
                  [styles.active]: this.props.input.value === industry.id,
                  [styles.invalid]: this.props.meta.submitFailed && !!this.props.meta.error,
                })}
                onClick={() => this.props.input.onChange(industry.id)}
              >
                <FormattedMessage id={`BENEFITS.${industry.name}.TITLE`} />
                <span className={styles.input} />
              </label>
            ))}
          </div>
          <BenefitContainer selected={this.props.input.value} />
        </div>
        {this.props.meta.submitFailed &&
          !!this.props.meta.error && (
            <div className={classNames('invalid-feedback', { [styles.errors]: true })}>
              <FormattedMessage id={this.props.meta.error} values={this.props.values} />
            </div>
          )}
      </React.Fragment>
    );
  }
}
