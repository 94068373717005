// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent, ButtonsWrapperComponent, CardComponent, InfoComponent } from 'app/shared';

import type { Props } from './subscription-order-success.component.types';

export class SubscriptionOrderSuccessComponent extends Component<Props> {
  render() {
    const upperCasedType = this.props.match.params.type.toUpperCase();

    return (
      <>
        <CardComponent header={false}>
          <div className="card-body">
            <InfoComponent className="mb-0" type="success" titleId="ORDERS.ORDER_SUCCESS">
              <FormattedMessage id={`SUBSCRIPTION.ORDER.${upperCasedType}.SUCCESS`} />
            </InfoComponent>
          </div>

          <div className="card-footer">
            <ButtonsWrapperComponent>
              <ButtonComponent
                theme="outline-secondary"
                size="large"
                to={`/subscriptions/${this.props.match.params.type}`}
              >
                <i className="fi fi-go-back-left-arrow" />
                <FormattedMessage id="SUBSCRIPTION.BACK_TO_LIST" />
              </ButtonComponent>
            </ButtonsWrapperComponent>
          </div>
        </CardComponent>
      </>
    );
  }
}
