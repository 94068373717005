import { RSAA } from 'redux-api-middleware';

import * as types from './settlements.action-types';

export const fetchSettlements = params => (dispatch, getState) => {
  const {
    users: { business },
  } = getState();

  return dispatch({
    [RSAA]: {
      types: [
        {
          type: types.FETCH_SETTLEMENTS_REQUEST,
          meta: { params },
        },
        types.FETCH_SETTLEMENTS_SUCCESS,
        types.FETCH_SETTLEMENTS_FAILURE,
      ],
      endpoint: `/venues/${business}/settlements`,
      method: 'GET',
    },
  });
};
