// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { PlaceholderComponent, TableComponent } from 'app/shared';

import type { Props } from './users-list-table.placeholder.types';

export class UsersListTablePlaceholder extends PureComponent<Props> {
  render() {
    return (
      <TableComponent className="table-striped">
        <thead>
          <tr>
            <FormattedMessage id="USERS.FULL_NAME" tagName="th" />
            <FormattedMessage id="USERS.EMAIL" tagName="th" />
            <FormattedMessage id="USERS.ROLES" tagName="th" />
            <th />
          </tr>
        </thead>
        <tbody>
          {this.props.list.map((_, user) => (
            <tr key={user}>
              <td>
                <PlaceholderComponent width={user % 2 === 0 ? 150 : 200} />
              </td>
              <td>
                <PlaceholderComponent width={user % 2 === 0 ? 260 : 150} />
              </td>
              <td>
                <PlaceholderComponent width={100} />
              </td>
              <td className="text-right">
                <PlaceholderComponent width={100} color="purple" />
              </td>
            </tr>
          ))}
        </tbody>
      </TableComponent>
    );
  }
}
