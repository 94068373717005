// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { currencyFormatter, dateFormatter } from 'app/utils';

import { ButtonComponent, NoResultsContainer, TableComponent } from 'app/shared';

import type { Props } from './accounts-list-table.component.types';

export class AccountsListTableComponent extends Component<Props> {
  render() {
    return (
      <TableComponent className="table-striped">
        <thead>
          <tr>
            <FormattedMessage id="ACCOUNTS.TYPE" tagName="th" />
            <FormattedMessage id="ACCOUNTS.VALID" tagName="th" />
            <FormattedMessage id="ACCOUNTS.BALANCE" tagName="th" />
            <th />
          </tr>
        </thead>
        <tbody>
          {!!this.props.list.length || (
            <tr>
              <td colSpan={4}>
                <NoResultsContainer searchable={false} />
              </td>
            </tr>
          )}
          {this.props.list.map((account, index) => [
            <tr key={`${index}.0`}>
              <td>
                <FormattedMessage id={`PAYMENT.ITEM.${account.item}`} />
              </td>
              <td />
              <td>{currencyFormatter.format(account.balance)}</td>
              <td className="text-right">
                <ButtonComponent size="small" theme="secondary" to={`/accounts/${account.accountNumber}/transactions`}>
                  <FormattedMessage id="ACCOUNTS.TRANSACTIONS" />
                </ButtonComponent>
              </td>
            </tr>,
            ...account.transfers.map((transfer, innerIndex) => (
              <tr key={`${index}.${innerIndex + 1}`} className="text-muted">
                <td />
                <td>
                  {dateFormatter(transfer.from)} - {dateFormatter(transfer.to)}
                </td>
                <td>{currencyFormatter.format(transfer.balance)}</td>
                <td />
              </tr>
            )),
          ])}
        </tbody>
      </TableComponent>
    );
  }
}
