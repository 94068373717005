// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { get } from 'lodash';

import pad from 'zero-fill';

import { OrderDetailsPlaceholder } from './placeholder/order-details.placeholder';

import {
  AlertComponent,
  ButtonComponent,
  ModalComponent,
  SummaryFieldComponent,
  TableComponent,
  VouchersAmountComponent,
} from 'app/shared';
import { currencyFormatter, dateFormatter } from 'app/utils';

import type { Props } from './order-details.component.types';
import styles from './order-details.module.scss';

export class OrderDetailsComponent extends Component<Props> {
  componentDidMount() {
    this.props.fetchOrder();
  }

  render() {
    const type = get(this.props, 'order.type');
    const orderFee = type === 'voucher' || type === 'banknote' ? 'ORDERS.DELIVERY_FEE' : 'ORDERS.INVOICING_FEE';
    const orderFeeTax =
      type === 'voucher' || type === 'banknote' ? 'ORDERS.DELIVERY_FEE_TAX' : 'ORDERS.INVOICING_FEE_TAX';

    if (this.props.loading) {
      return <OrderDetailsPlaceholder onClose={this.props.onClose} />;
    }

    return (
      <ModalComponent
        onClose={this.props.onClose}
        title={<FormattedMessage id="ORDER_HISTORY.ORDER_INFORMATION" />}
        submitButton={
          this.props.order.status === 'STATUS_OPEN' && (
            <ButtonComponent
              theme="danger"
              size="medium"
              className="ml-3"
              onClick={this.props.onCancel}
              loading={this.props.order.canceling}
            >
              <FormattedMessage id="ORDERS.CANCEL_ORDER" />
            </ButtonComponent>
          )
        }
      >
        <FormattedMessage id="ORDER_HISTORY.ORDER_INFORMATION_DESCRIPTION" tagName="p" />
        {!!this.props.location.state &&
          !!this.props.location.state.error && (
            <AlertComponent type="danger">
              {this.props.location.state.error.type === 'SUBSCRIPTION_ORDERED' && (
                <FormattedMessage id="ORDERS.SUBSCRIPTION_ORDERED_ERROR" />
              )}
            </AlertComponent>
          )}
        <div className="row">
          <div className="col-md-6">
            <SummaryFieldComponent>
              <FormattedMessage id="ORDER_HISTORY.NUMBER" tagName="strong" />
              {pad(7, this.props.order.id)}
            </SummaryFieldComponent>
          </div>
          <div className="col-md-6">
            <SummaryFieldComponent>
              <FormattedMessage id="ORDER_HISTORY.DATE" tagName="strong" />
              <small>{dateFormatter(this.props.order.orderDate)}</small>
            </SummaryFieldComponent>
          </div>
          <div className="col-md-6">
            <SummaryFieldComponent>
              <FormattedMessage id="ORDER_HISTORY.STATUS" tagName="strong" />
              <small className="text-uppercase">
                <FormattedMessage id={`STATUS.ORDER.${this.props.order.status}`} />
              </small>
            </SummaryFieldComponent>
          </div>
          <div className="col-md-6">
            <SummaryFieldComponent>
              <FormattedMessage id="ORDERS.INVOICING_METHOD" tagName="strong" />
              <FormattedMessage id={`INVOICING_METHODS.${this.props.order.invoicing.invoicingMethod}`} />
            </SummaryFieldComponent>
          </div>
        </div>
        <TableComponent className={classNames(styles.summaryTable, 'table-striped')}>
          <thead>
            <tr className={styles.hederRow}>
              <FormattedMessage id="ORDERS.PRODUCT" tagName="th" />
              <FormattedMessage id="ORDERS.QUANTITY" tagName="th" />
              <FormattedMessage id="ORDERS.AMOUNT" tagName="th" />
            </tr>
          </thead>
          <tbody className={styles.orders}>
            {this.props.order.invoiceProducts.map((product, index) => (
              <tr key={index}>
                <td>
                  <FormattedMessage id={`PRODUCT.${product.id}`} />
                </td>
                <td className={styles.type}>
                  1 x {currencyFormatter.format(product.amount)}
                  {(this.props.order.type === 'voucher' || this.props.order.type === 'banknote') && (
                    <small>
                      <VouchersAmountComponent amount={product.amount} id={product.id} />
                    </small>
                  )}
                  {product.commission > 0 && (
                    <FormattedMessage
                      id="PRODUCT.COMMISSION"
                      values={{
                        commission: currencyFormatter.format(product.commission),
                        commissionRate: `(${product.commissionRate}%)`,
                      }}
                      tagName="small"
                    />
                  )}
                  {product.tax > 0 && (
                    <FormattedMessage
                      id="PRODUCT.COMMISSION_TAX"
                      values={{ tax: currencyFormatter.format(product.tax), taxRate: `(${product.taxRate}%)` }}
                      tagName="small"
                    />
                  )}
                </td>
                <td className="text-right">{currencyFormatter.format(product.total)}</td>
              </tr>
            ))}
            <tr>
              <td>
                <FormattedMessage id={orderFee} />
              </td>
              <td />
              <td className="text-right">{currencyFormatter.format(this.props.order.managementFee)}</td>
            </tr>
            <tr>
              <td>
                <FormattedMessage
                  id={orderFeeTax}
                  values={{ taxRate: this.props.order.taxRate ? `(${this.props.order.taxRate}%)` : '' }}
                />
              </td>
              <td />
              <td className="text-right">{currencyFormatter.format(this.props.order.tax)}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={2}>
                <FormattedMessage id="ORDERS.ORDER_TOTAL" />
              </td>
              <td className="text-right">{currencyFormatter.format(this.props.order.grossTotalAmount)}</td>
            </tr>
          </tfoot>
        </TableComponent>
      </ModalComponent>
    );
  }
}
