// @flow

import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { push } from 'react-router-redux';
import URLSearchParams from '@ungap/url-search-params';

import { withAuthorization } from 'app/common';
import { EditContractComponent } from './edit-contract.component';
import { terminateContract, editContract, fetchContracts } from '../../../redux/contracts/contracts.actions';
import { withRouter } from 'react-router-dom';

const mapStateToProps = ({
  form,
  router: {
    location: { state },
  },
}) => {
  return {
    editForm: form,
    initialValues: {
      startDate: state && state.startDate,
      endDate: state && state.endDate,
    },
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    business,
    match: {
      params: { vendorId, contractId },
    },
    location: { search },
  },
) => ({
  onSubmit: params => {
    const body = {
      ...params,
      vendorId,
      contractId,
    };
    return dispatch(editContract(business, body));
  },
  onSubmitSuccess: params => {
    const searchParams: any = {
      page: new URLSearchParams(search).get('page'),
      size: new URLSearchParams(search).get('size'),
    };
    const fetchListParams = {
      ...searchParams,
      ...(!searchParams.size && { size: 10 }),
      ...(!searchParams.page && { page: 1 }),
    };
    return (
      dispatch(
        push({
          state: {
            success: true,
          },
          search,
        }),
      ),
      dispatch(fetchContracts(fetchListParams))
    );
  },
  onSubmitFail: params => {
    if (!params.startDate) {
      dispatch(
        push({
          state: {
            fail: true,
          },
          search,
        }),
      );
    }
  },
  onClose: () =>
    dispatch(
      push({
        pathname: `/contracts`,
        search,
        state: {
          keepScrollPosition: true,
        },
      }),
    ),
  onRemove: () => {
    const body = {
      vendorId,
      contractId,
    };
    return dispatch(terminateContract(business, body));
  },
});

export const EditContractContainer = compose(
  injectIntl,
  withAuthorization(['contracts', 'update']),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({ form: 'edit-contract' }),
  withRouter,
)(EditContractComponent);
