// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, FieldArray } from 'redux-form';
import classNames from 'classnames';

import { AlertComponent, ConnectedSelectComponent, ConnectedTextareaComponent } from 'app/shared';

import type { Props } from './subscription-subscribe-form-questions.component.types';
import styles from './subscription-subscribe-form-questions.module.scss';

export class SubscriptionSubscribeFormQuestionsComponent extends Component<Props> {
  answersMapper = (answers: string) =>
    (answers.split(';').map(answer => ({ id: answer, name: answer })): { id: string, name: string }[]);

  render() {
    const { questions } = this.props;

    if (!questions.length) {
      return null;
    }

    return (
      <>
        <div className="card-header card-header--between">
          <h4 className="card-title">
            <FormattedMessage id="SUBSCRIPTION.SUBSCRIBE.ADDITIONAL_QUESTIONS" />
          </h4>
        </div>
        <div className="card-body">
          <AlertComponent type="light" className="text-left">
            <FormattedMessage id="SUBSCRIPTION.SUBSCRIBE.ADDITIONAL_QUESTIONS_INSTRUCTION" tagName="p" />
          </AlertComponent>

          <div className="row">
            <FieldArray
              name="answers"
              component={
                (({ fields }) =>
                  fields.map((field, index) => {
                    if (!questions[index].answers) {
                      return (
                        <div className="col-12" key={questions[index].id}>
                          <h5 className={styles.questionTitle}>
                            <FormattedMessage
                              id="SUBSCRIPTION.SUBSCRIBE.QUESTION_TITLE"
                              values={{ number: index + 1, content: questions[index].question }}
                            />
                            <span className={styles.requiredMark}>*</span>
                          </h5>
                          <span className={styles.questionDescription}>{questions[index].description}</span>
                          <Field name={`${field}.answer`} component={ConnectedTextareaComponent} required />
                        </div>
                      );
                    }

                    return (
                      <div className={classNames('col-12', styles.question)} key={questions[index].id}>
                        <h5 className={styles.questionTitle}>
                          <FormattedMessage
                            id="SUBSCRIPTION.SUBSCRIBE.QUESTION_TITLE"
                            values={{ number: index + 1, content: questions[index].question }}
                          />
                          <span className={styles.requiredMark}>*</span>
                        </h5>
                        <span className={styles.questionDescription}>{questions[index].description}</span>
                        <Field
                          name={`${field}.answer`}
                          component={ConnectedSelectComponent}
                          options={this.answersMapper(questions[index].answers)}
                          required
                        />
                      </div>
                    );
                  }): Function)
              }
            />
          </div>
        </div>
      </>
    );
  }
}
