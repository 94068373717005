// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { SubscriptionNavContainer } from 'app/subscriptions/nav/subscription-nav.container';

import { GroupComponent, PlaceholderComponent } from 'app/shared';
import { stepsDictionary } from 'app/utils/subscription/subscription.util';

import type { Props } from './subscription-form.placeholder.types';

import styles from '../subscription-form.module.scss';

export class SubscriptionFormPlaceholder extends Component<Props> {
  render() {
    const steps = stepsDictionary[this.props.type];

    return (
      <form>
        <div className="card-header">
          <SubscriptionNavContainer edit={this.props.edit} />

          {steps.map(step => (
            <span key={step} className={styles.link}>
              <FormattedMessage id={`SUBSCRIPTION.SECTION_NAV.${step}`} />
            </span>
          ))}

          <h4 className="card-title mt-4">
            <FormattedMessage id="SUBSCRIPTION.SECTION_TITLES.BASIC_INFORMATION" />
          </h4>
        </div>

        <div className="card-body">
          <div className="row">
            <div className="col-12">
              <GroupComponent label={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.LABEL.TITLE' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
            <div className="col-12">
              <GroupComponent
                label={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.LABEL.MESSAGE_TO_EMPLOYEES' })}
                required
              >
                <PlaceholderComponent height={100} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
