import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import { withPermissions } from 'app/common';

import { VenuesListTableComponent } from './venues-list-table.component';

export const VenuesListTableContainer = compose(
  injectIntl,
  withPermissions,
)(VenuesListTableComponent);
