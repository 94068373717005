// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

import uniqueId from 'lodash/uniqueId';
import pick from 'lodash/pick';

import { GroupComponent } from 'app/shared';

import type { Props } from './input.component.types';
import styles from 'app/shared/form/connected-input/connected-input.module.scss';

export class InputComponent extends Component<Props> {
  render() {
    const id = uniqueId('input_');

    const inputClassNames = classNames('form-control', styles.input, this.props.className, {
      [styles.withAddon]: !!this.props.button,
      [styles.withIcon]: !!this.props.icon,
      [styles.dark]: this.props.dark,
    });

    const inputGroupClassNames = classNames('input-group', {
      [styles.dark]: this.props.dark,
    });

    const iconClassNames = classNames('input-group-text', styles.icon, {
      [styles.dark]: this.props.dark,
    });

    return (
      <GroupComponent id={id} {...this.props}>
        {!!this.props.icon && (
          <span className="input-group-prepend">
            <span className={iconClassNames}>
              <i className={`fi fi-${this.props.icon}`} />
            </span>
          </span>
        )}

        <div className={inputGroupClassNames}>
          <input
            id={id}
            {...this.props.input}
            {...pick(this.props, ['onClick', 'readOnly'])}
            ref={this.props.inputRef}
            className={inputClassNames}
          />

          {!!this.props.button && (
            <span className="input-group-append">
              <span className={classNames('input-group-text', styles.button)}>{this.props.button}</span>
            </span>
          )}
        </div>
      </GroupComponent>
    );
  }
}
