// @flow

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { PaymentsMobileContainer } from './mobile/payments-mobile.container';
import { PaymentsTokenContainer } from './token/payments-token.container';

import type { Props } from './payments.component.types';

export class PaymentsComponent extends Component<Props> {
  render() {
    return (
      <Switch>
        <Route path={`${this.props.match.url}/mobile`} component={PaymentsMobileContainer} />
        <Route path={`${this.props.match.url}/token`} component={PaymentsTokenContainer} />
      </Switch>
    );
  }
}
