import { compose } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { push, replace } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import get from 'lodash/get';

import { withCoreContext } from 'app/core/core.context';
import { withPermissions } from 'app/common';

import { editVenue } from 'app/redux/venues/venues.actions';
import { fillValues } from 'app/redux/register/register.actions';

import { VenueFormComponent } from 'app/venues/form/venue-form.component';

export type SubmissionResultType = 'ERROR' | 'SUCCESS';

const resolveRedirectState = (name, submissionResult: ?SubmissionResultType) => {
  switch (submissionResult) {
    case 'ERROR':
      return {
        error: {
          type: 'VALIDATION_ERROR',
        },
      };
    case 'SUCCESS':
      return {
        success: {
          type: 'EDIT_VENUE',
          payload: name,
        },
      };
    default:
      return null;
  }
};

const makeRedirect = (dispatch, venue, submissionResult) => name =>
  module === 'venues'
    ? dispatch(
        push({
          pathname: '/venues',
          state: resolveRedirectState(name, submissionResult),
        }),
      )
    : dispatch(
        replace({
          pathname: `/venues/${venue.venue.id}/edit`,
          state: resolveRedirectState(name, submissionResult),
        }),
      );

const selector = formValueSelector('edit-venue');

const mapStateToProps = ({ dictionaries, users, ...state }, { venue: initialValues = { venue: {} } }) => {
  const initial = {
    ...initialValues,
    venue: {
      ...initialValues.venue,
      bankAccount: {
        iban: !initialValues.venue.bankAccount ? '' : initialValues.venue.bankAccount.iban,
        swift: !initialValues.venue.bankAccount ? '' : initialValues.venue.bankAccount.swift,
      },
    },
  };

  const removeCardPayment = (payments: object[]) => payments && payments.filter(payment => payment.id !== 'card');

  const industry = dictionaries.services.industries.find(element => element.id === initialValues.venue.benefit);

  return {
    iban: selector(state, 'venue.bankAccount.iban'),
    swift: selector(state, 'venue.bankAccount.swift'),
    initialValues: initial,
    dictionary: dictionaries.services,
    benefit: initialValues.venue.benefit || dictionaries.services.industries[0].id,
    type: initialValues.venue.type,
    paymentMethods: removeCardPayment(get(industry, 'payments')) || removeCardPayment(dictionaries.services.payments),
    userRole: users.role,
  };
};

const mapDispatchToProps = (dispatch, { module, venue }) => ({
  onSubmit: body => dispatch(editVenue(venue.venue.type, venue.venue.id, body)).then(() => body.venue.name),
  onSubmitFail: makeRedirect(dispatch, venue, 'ERROR'),
  onSubmitSuccess: makeRedirect(dispatch, venue, 'SUCCESS'),
  fillValues: (from, to) =>
    dispatch(
      fillValues({
        form: 'edit-venue',
        from,
        to,
      }),
    ),
});

export const VenueFormContainer = compose(
  injectIntl,
  withCoreContext,
  withPermissions,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'edit-venue',
    enableReinitialize: true,
  }),
)(VenueFormComponent);
