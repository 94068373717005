// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { TileComponent } from 'app/shared/index';

import { SubscriptionBeneficiariesStatusComponent } from 'app/subscriptions/beneficiaries/status/subscription-beneficiaries-status.component';

import type { Props } from './activation-cards-tile.component.types';
import styles from 'app/subscriptions/beneficiaries/tile/subscription-beneficiaries-tile.module.scss';

export class ActivationCardsTileComponent extends Component<Props> {
  render() {
    return (
      <TileComponent>
        <div className={styles.heading}>
          <SubscriptionBeneficiariesStatusComponent status={this.props.tile.active ? 'APPROVED' : 'DECLINED'} />
          <h3>{this.props.tile.cardNumber}</h3>
        </div>
        <div className="row">
          <div className="col-6">
            <FormattedMessage id="CARDS.VALID_THRU" tagName="small" />
            {this.props.tile.validTo}
          </div>
        </div>
      </TileComponent>
    );
  }
}
