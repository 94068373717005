// @flow

import React, { Component } from 'react';
import GoogleAnalytics from 'react-ga';
import TagManager from 'react-gtm-module';

import type { ComponentType } from 'react';
import type { Props } from './with-tracker.types';

if (process.env.REACT_APP_GA) {
  GoogleAnalytics.initialize(process.env.REACT_APP_GA);
}

let isTagManagerInitialized = false;
if (process.env.REACT_APP_GTM_ID) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
  };
  TagManager.initialize(tagManagerArgs);
  isTagManagerInitialized = true;
}

export const withTracker = (WrappedComponent: ComponentType<any>, options: Object = {}) =>
  class Tracker extends Component<Props> {
    componentDidMount() {
      this.trackPage(this.props.location.pathname);
    }

    componentDidUpdate(prevProps: Props) {
      const currentPage = prevProps.location.pathname;
      const nextPage = this.props.location.pathname;

      if (currentPage !== nextPage) {
        this.trackPage(nextPage);
      }
    }

    trackPage = (page: string) => {
      GoogleAnalytics.set({ page, ...options });
      GoogleAnalytics.pageview(page);
      if (isTagManagerInitialized) {
        TagManager.dataLayer({ page, ...options });
      }
    };

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
