import { compose } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { injectIntl } from 'react-intl';

import { RegisterServiceFormComponent } from './register-service-form.component';

import { fillValues } from 'app/redux/register/register.actions';

const selector = formValueSelector('register-service');

const mapStateToProps = state => ({
  ...state.dictionaries['services'],
  benefit: selector(state, 'benefit') || 'exercise',
  billingMethod: selector(state, 'billing.invoicingMethod'),
  iban: selector(state, 'bankAccount.iban'),
  swift: selector(state, 'bankAccount.swift'),
  paymentMethods:
    state.dictionaries.services.industries.find(element => element.id === (selector(state, 'benefit') || 'exercise'))
      .payments || state.dictionaries.services.payments,
});

const mapDispatchToProps = dispatch => ({
  fillValues: (from, to) =>
    dispatch(
      fillValues({
        form: 'register-service',
        from,
        to,
      }),
    ),
});

export const RegisterServiceFormContainer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(RegisterServiceFormComponent);
