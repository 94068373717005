// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import uniqueId from 'lodash/uniqueId';

import { ConnectedGroupComponent } from 'app/shared';

import type { Props } from './connected-file.component.types';
import styles from './connected-file.module.scss';

export class ConnectedFileComponent extends Component<Props> {
  onChange = (e: SyntheticInputEvent<HTMLInputElement>) => this.props.input.onChange(e.target.files[0]);

  render() {
    const id = uniqueId('file_');
    const isInvalid = this.props.meta.submitFailed && this.props.meta.error;

    const inputClassNames = classNames('form-control', styles.input, {
      'is-invalid': isInvalid,
    });

    const { onBlur, onChange, value, ...input } = this.props.input;

    return (
      <ConnectedGroupComponent id={id} {...this.props}>
        <label className={inputClassNames}>
          <input id={id} type="file" {...input} onChange={this.onChange} />

          {!!this.props.input.value || (
            <span className={styles.placeholder}>
              <FormattedMessage id="CORE.UPLOAD_PLACEHOLDER" />
            </span>
          )}

          {!!this.props.input.value && <span className={styles.value}>{this.props.input.value.name}</span>}

          <span className={styles.button}>
            <FormattedMessage id="CORE.UPLOAD" />
          </span>
        </label>
      </ConnectedGroupComponent>
    );
  }
}
