// @flow

import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent, ModalComponent } from 'app/shared';

import { AgreementsDetailsPlaceholder } from './placeholder/agreements-details.placeholder';

import type { Props } from './agreements-details.component.types';
import styles from './agreements-details.module.scss';

export class AgreementsDetailsComponent extends Component<Props> {
  componentDidMount() {
    this.props.fetchTerms();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.terms === undefined && this.props.terms === undefined) {
      this.props.fetchTerms();
    }
  }

  render() {
    if (this.props.loading) {
      return <AgreementsDetailsPlaceholder onClose={this.props.onClose} />;
    }

    const submitButton = this.props.approved ||
      this.props.viewOnly || (
        <ButtonComponent theme="primary" size="medium" loading={this.props.loading}>
          <FormattedMessage id="CORE.ACCEPT" />
        </ButtonComponent>
      );

    return (
      <ModalComponent
        onClose={this.props.onClose}
        title={this.props.terms.name}
        form={{ onSubmit: this.props.handleSubmit }}
        submitButton={submitButton}
        isClosingBlocked={!this.props.approved && !this.props.viewOnly}
      >
        <ReactMarkdown source={this.props.terms.content} className={styles.body} />
      </ModalComponent>
    );
  }
}
