// @flow

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { OnlinePaymentInfoContainer } from './online-payment-info/online-payment-info.container';

export class OnlinePaymentComponent extends Component<any> {
  render() {
    return (
      <Switch>
        <Route path={this.props.match.url} component={OnlinePaymentInfoContainer} />
      </Switch>
    );
  }
}
