import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import { withPermissions } from 'app/common';

import { SidebarManagementComponent } from '../management/sidebar-management.component';

export const SidebarManegementContainer = compose(
  injectIntl,
  withPermissions,
)(SidebarManagementComponent);
