import moment from 'moment';

import { compose } from 'redux';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { formValueSelector, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';

import {
  cloneSubscription,
  importSubscription,
  fetchAllSubscriptions,
  checkImportSubscription,
  setCancelledImportStatus,
} from 'app/redux/subscriptions/subscriptions.actions';
import { refreshToken } from 'app/redux/users/users.actions';

import { InitiateSubscriptionComponent } from './initiate-subscription.component';

export const CREATE_TYPE = {
  NEW: 1,
  CLONE: 2,
  IMPORT: 3,
};

const onCheckImportSubscription = (dispatch, id, type) =>
  dispatch(checkImportSubscription(id)).then(resp => {
    if (get(resp, ['payload', 'data', 'id'])) {
      dispatch(
        push({
          pathname: `/subscriptions/${type}/${id}/edit`,
          state: {
            information: {
              id: 'SUBSCRIPTION.IMPORT_EMPTY',
            },
          },
        }),
      );
    } else {
      setTimeout(() => onCheckImportSubscription(dispatch, id, type), 5000);
    }
  });

const mapStateToProps = (
  {
    form,
    users: {
      business,
      current: { businesses },
    },
    subscriptions,
  },
  { type },
) => ({
  initialValues: {
    title: `${businesses.find(businessItem => businessItem.id === business).name} ${moment().format('M/YYYY')}`,
    role: 1,
  },
  business,
  role: formValueSelector('initialize-subscription')({ form }, 'role'),
  loading: !get(subscriptions, [type, business]),
  subscriptions: get(subscriptions, [type, business, 'allList']),
  importStatus: subscriptions.importStatus,
});

const mapDispatchToProps = (dispatch, { intl, type, pathname }) => ({
  onClose: () => dispatch(setCancelledImportStatus()),
  onSubmit: body => {
    const behaviors = {
      [CREATE_TYPE.NEW]: () =>
        dispatch(
          push({
            pathname: `/subscriptions/${type}/create`,
            state: {
              title: body.title,
            },
          }),
        ),
      [CREATE_TYPE.CLONE]: () =>
        dispatch(
          cloneSubscription({
            subscriptionId: body.subscriptionId,
            title: body.title,
          }),
        ).then(({ payload: { data: { id, removedSubscriptionsNumber } } }) =>
          dispatch(
            push({
              pathname: `/subscriptions/${type}/${id}`,
              state: {
                removedSubscriptionsNumber,
              },
            }),
          ),
        ),
      [CREATE_TYPE.IMPORT]: () => {
        const token = localStorage.getItem('refreshToken');
        return dispatch(refreshToken(token)).then(() =>
          dispatch(
            importSubscription({
              ...body,
              description: intl.formatMessage({ id: 'SUBSCRIPTION.INITIAL_DESCRIPTION' }),
            }),
          ).then(({ payload: { data: { id } } }) => {
            onCheckImportSubscription(dispatch, id, type);
          }),
        );
      },
    };

    if (behaviors[body.role]) {
      return behaviors[body.role]();
    }
  },
  validate: values =>
    values.role === CREATE_TYPE.CLONE && !values.subscriptionId ? { subscriptionId: 'VALIDATION.NOT_BLANK' } : {},
  fetchAllSubscriptions: business => dispatch(fetchAllSubscriptions(type, business)),
});

export const InitiateSubscriptionContainer = compose(
  injectIntl,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    enableReinitialize: true,
    form: 'initialize-subscription',
  }),
)(InitiateSubscriptionComponent);
