import { handleActions } from 'redux-actions';

import * as types from './beneficiaries.action-types';

const defaultState = {
  entities: {},
  deleting: false,
};

export const beneficiariesReducer = handleActions(
  {
    [types.FETCH_BENEFICIARIES_REQUEST]: (state, { meta: { business } }) => ({
      ...state,
      [business]: undefined,
    }),
    [types.FETCH_BENEFICIARIES_SUCCESS]: (state, { payload: { data: list, meta }, meta: { business } }) => ({
      ...state,
      [business]: {
        list,
        meta,
      },
    }),
    [types.FILTER_BENEFICIARIES_REQUEST]: state => ({
      ...state,
      loadingUsers: true,
    }),
    [types.FILTER_BENEFICIARIES_SUCCESS]: (state, { payload: { data: list, meta }, meta: { business } }) => ({
      ...state,
      filteredUsers: list,
      loadingUsers: false,
    }),
    [types.FETCH_BENEFICIARY_SUCCESS]: (state, { payload: { data: personal }, meta: { id } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [id]: {
          ...state.entities[id],
          personal,
        },
      },
    }),
    [types.FETCH_BENEFICIARY_FAILURE]: (state, { meta: { id } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [id]: {
          ...state.entities[id],
          personal: null,
        },
      },
    }),
    [types.FETCH_BENEFICIARY_SUBSCRIPTIONS_SUCCESS]: (state, { payload: { data: subscriptions }, meta: { id } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [id]: {
          ...state.entities[id],
          subscriptions,
        },
      },
    }),
    [types.EDIT_BENEFICIARY_SUBSCRIPTION_SUCCESS]: (
      state,
      { meta: { beneficiary, body, subscriptionBeneficiary } },
    ) => ({
      ...state,
      entities: {
        ...state.entities,
        [beneficiary]: beneficiary
          ? {
              ...state.entities[beneficiary],
              subscriptions: [
                ...(body.status === 'DELETED'
                  ? state.entities[beneficiary].subscriptions.filter(
                      subscription => subscription.id !== subscriptionBeneficiary,
                    )
                  : state.entities[beneficiary].subscriptions.map(
                      subscription =>
                        subscription.id !== subscriptionBeneficiary
                          ? subscription
                          : {
                              ...subscription,
                              benefitOptions: body.benefits,
                              deductibleAmount: body.deductibleAmount,
                              status: body.status,
                            },
                    )),
              ],
            }
          : {},
      },
    }),
    [types.FETCH_BENEFICIARY_ACCOUNTS_SUCCESS]: (state, { payload: { data: accounts }, meta: { id } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [id]: {
          ...state.entities[id],
          accounts,
        },
      },
    }),
    [types.FETCH_BENEFICIARY_CARDS_SUCCESS]: (state, { payload: { data: cards }, meta: { id } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [id]: {
          ...state.entities[id],
          cards,
        },
      },
    }),
    [types.CHANGE_BENEFICIARY_CARD_STATUS_SUCCESS]: (state, { meta: { beneficiary, id, active } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [beneficiary]: {
          ...state.entities[beneficiary],
          cards: state.entities[beneficiary].cards.map(card => (card.id === id ? { ...card, active } : card)),
        },
      },
    }),
    [types.UPDATE_ACCOUNT_STATUS_SUCCESS]: (state, { meta: { account: accountNumber, active, beneficiary } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [beneficiary]: {
          ...state.entities[beneficiary],
          accounts: state.entities[beneficiary].accounts.map(
            account =>
              account.accountNumber !== accountNumber
                ? account
                : {
                    ...account,
                    active,
                  },
          ),
        },
      },
    }),
    [types.DELETE_BENEFICIARY_REQUEST]: state => ({
      ...state,
      deleting: true,
    }),
    [types.DELETE_BENEFICIARY_SUCCESS]: state => ({
      ...state,
      deleting: false,
    }),
    [types.DELETE_BENEFICIARY_FAILURE]: state => ({
      ...state,
      deleting: false,
    }),
  },
  defaultState,
);
