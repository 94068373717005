import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { withAuthorization } from 'app/common';
import { withPagination } from 'app/transactions/with-pagination/with-pagination.hoc';

import { fetchAccount, fetchTransactions } from 'app/redux/accounts/accounts.actions';
import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';
import { download } from 'app/redux/downloads/downloads.actions';

import { AccountTransactionsComponent } from './account-transactions.component';
import { injectIntl } from 'react-intl';

const mapStateToProps = (
  { accounts: { entities: accounts }, dictionaries, permissions, users: { business, role } },
  {
    match: {
      params: { number },
    },
  },
) => ({
  account: get(accounts, [number], {}),
  fetching: get(accounts, [number, 'transactions', 'fetching']),
  loading:
    !get(accounts, [number, 'transactions']) ||
    !dictionaries['transaction-types'] ||
    get(accounts, [number, 'loading']),
  loadingAccount: get(accounts, [number, 'loading']),
  meta: get(accounts, [number, 'transactions', 'meta']),
  permissions: permissions[business][role] || {},
  transactions: get(accounts, [number, 'transactions', 'list', 'transactions']),
  totalAmount: get(accounts, [number, 'transactions', 'list', 'totalAmount']),
  types: get(dictionaries, ['transaction-types', 'company']),
});

const mapDispatchToProps = (
  dispatch,
  {
    intl: { locale },
    match: {
      params: { number },
    },
  },
) => ({
  fetchAccount: () => dispatch(fetchAccount(number)),
  fetchDictionary: () => dispatch(fetchDictionary('transaction-types')),
  fetchTransactions: params => dispatch(fetchTransactions(number, params)),
  onDownload: () => dispatch(download(`transactions/account/${number}`, locale, 'filter-account-transactions')),
});

export const AccountTransactionsContainer = compose(
  withAuthorization(['companyAccountsHistory', 'read']),
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withPagination,
)(AccountTransactionsComponent);
