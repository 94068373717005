import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { routerReducer as router } from 'react-router-redux';

import { LOGOUT_REQUEST } from 'app/redux/users/users.action-types';

import { accountsReducer as accounts } from 'app/redux/accounts/accounts.reducer';
import { agreementsReducer as agreements } from 'app/redux/agreements/agreements.reducer';
import { beneficiariesReducer as beneficiaries } from 'app/redux/beneficiaries/beneficiaries.reducer';
import { cardsReducer as cards } from 'app/redux/cards/cards.reducer';
import { customersReducer as customers } from 'app/redux/customers/customers.reducer';
import { dictionariesReducer as dictionaries } from 'app/redux/dictionaries/dictionaries.reducer';
import { errorReducer as error } from 'app/redux/error/error.reducer';
import { ordersReducer as orders } from 'app/redux/orders/orders.reducer';
import { reportsReducer as reports } from 'app/redux/reports/reports.reducer';
import { paymentsReducer as payments } from 'app/redux/payments/payments.reducer';
import { permissionsReducer as permissions } from 'app/redux/permissions/permissions.reducer';
import { registerReducer as register } from 'app/redux/register/register.reducer';
import { settlementsReducer as settlements } from 'app/redux/settlements/settlements.reducer';
import { subscriptionsReducer as subscriptions } from 'app/redux/subscriptions/subscriptions.reducer';
import { timeoutReducer as timeout } from 'app/redux/timeout/timeout.reducer';
import { transactionsReducer as transactions } from 'app/redux/transactions/transactions.reducer';
import { translationsReducer as translations } from 'app/redux/translations/translations.reducer';
import { usersReducer as users } from 'app/redux/users/users.reducer';
import { venuesReducer as venues } from 'app/redux/venues/venues.reducer';
import { viewReducer as view } from 'app/redux/view/view.reducer';
import { yearlyLimitsReducer as yearlyLimits } from 'app/redux/yearly-limits/yearly-limits.reducer';
import { metabaseReducer as metabase } from 'app/redux/metabase/metabase.reducer';
import { metabaseDataReducer as metabaseData } from 'app/redux/metabase-download-pdf/metabase-download-pdf.reducer';
import { contractsReducer as contracts } from 'app/redux/contracts/contracts.reducer';

const rootReducer = combineReducers({
  accounts,
  agreements,
  beneficiaries,
  cards,
  customers,
  dictionaries,
  error,
  orders,
  reports,
  payments,
  permissions,
  register,
  settlements,
  subscriptions,
  timeout,
  transactions,
  translations,
  users,
  venues,
  view,
  yearlyLimits,
  metabase,
  contracts,
  metabaseData,

  form,
  router,
});

export const appReducer = (state, action) => {
  if (action.type === LOGOUT_REQUEST) {
    const { router, translations } = state;

    state = { router, translations };
  }

  return rootReducer(state, action);
};
