// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ExternalLinkComponent } from 'app/shared';

import type { Props } from './smartum-pay-help.component.types';
import styles from '../smartum-pay-info.module.scss';

const smartumPayHelpDict = {
  en: process.env.REACT_APP_SMARTUMPAY_HELP_EN,
  fi: process.env.REACT_APP_SMARTUMPAY_HELP_FI,
  sv: process.env.REACT_APP_SMARTUMPAY_HELP_SV,
};

export class SmartumPayHelpComponent extends Component<Props> {
  render() {
    const { locale } = this.props.intl;

    return (
      <p className={styles.helpInfoText}>
        <strong>
          <FormattedMessage id="SMARTUMPAY.FAQ.HELP.TEXT" />
        </strong>
        <FormattedMessage
          id="SMARTUMPAY.FAQ.HELP.TEXT_WITH_LINK"
          values={{
            link: (
              <ExternalLinkComponent href={smartumPayHelpDict[locale]} target="_blank">
                <FormattedMessage id="SMARTUMPAY.FAQ.HELP.LINK_TEXT" />
              </ExternalLinkComponent>
            ),
          }}
        />
      </p>
    );
  }
}
