import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { OnlinePaymentInfo } from './online-payment-info.component';

const mapStateToProps = ({ users: { role } }) => ({
  role,
});

export const OnlinePaymentInfoContainer = compose(injectIntl, connect(mapStateToProps))(OnlinePaymentInfo);
