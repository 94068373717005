// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ListComponent } from 'app/shared/list/list.component';
import { BeneficiariesListTablePlaceholder } from './table/beneficiaries-list-table.placeholder';
import { BeneficiariesListTilePlaceholder } from './tile/beneficiaries-list-tile.placeholder';

import type { Props } from './beneficiaries-list.placeholder.types';

export class BeneficiariesListPlaceholder extends Component<Props> {
  static defaultProps = {
    beneficiaries: [...new Array(5)],
  };

  render() {
    return (
      <>
        <div className="card-header pt-xl-0">
          <h4 className="card-title">
            <FormattedMessage id="BENEFICIARIES.BENEFICIARIES_LIST.LIST" />
          </h4>
        </div>
        <div className="card-body">
          <ListComponent
            list={this.props.beneficiaries}
            table={BeneficiariesListTablePlaceholder}
            tile={BeneficiariesListTilePlaceholder}
          />
        </div>
      </>
    );
  }
}
