// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import {
  ButtonComponent,
  ConnectedInputComponent,
  ConnectedSelectComponent,
} from 'app/shared';

import type { Props } from './subscription-beneficiaries-filters.component.types';
import styles from './subscription-beneficiaries-filters.module.scss';

export class SubscriptionBeneficiariesFiltersComponent extends Component<Props> {
  render() {
    const options = [
      { id: '', name: <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.ALL" /> },
      { id: 'approved', name: <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.ACCEPTED" /> },
      { id: 'declined', name: <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.REJECTED" /> },
      { id: 'new', name: <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.OPEN" /> },
    ];

    const limitOptions = [
      { id: 10, name: <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.NO_OF_ROWS" values={{ limit: 10 }} /> },
      { id: 100, name: <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.NO_OF_ROWS" values={{ limit: 100 }} /> },
      { id: 1000, name: <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.NO_OF_ROWS" values={{ limit: 1000 }} /> },
    ];

    return (
      <form onSubmit={this.props.handleSubmit}>
        <h4 className="card-title mb-4">
          <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.SEARCH_TITLE" />
        </h4>

        <div className={styles.row}>
          <div className={styles.phrase}>
          <Field
            name="phrase"
            component={ConnectedInputComponent}
            label={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.BENEFICIARIES.NAME' })}
            placeholder={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.BENEFICIARIES.SEARCH_PLACEHOLDER' })}
            button={
              <ButtonComponent theme="secondary" size="small">
                <FormattedMessage id="CORE.SEARCH" />
                <i className="fi fi-magnifying-glass" />
              </ButtonComponent>
            }
          />
          </div>
          <div className={styles.status}>
            <Field
              name="status"
              options={options}
              component={ConnectedSelectComponent}
              onChange={() => {
                setTimeout(this.props.submit, 0);
              }}
              label={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.BENEFICIARIES.STATUS' })}
            />
          </div>

          <div className={classNames(styles.limit, 'ml-auto')}>
            <Field
              name="size"
              options={limitOptions}
              component={ConnectedSelectComponent}
              onChange={() => {
                setTimeout(this.props.submit, 0);
              }}
              label={this.props.intl.formatMessage({ id: 'CORE.PAGINATION.SHOW_ON_PAGE' })}
            />
          </div>
        </div>
      </form>
    );
  }
}
