// @flow

import isArray from 'lodash/isArray';

import type { Permission } from './check-permissions.util.types';

type Condition =
  | [string, 'create' | 'read' | 'update' | 'delete']
  | [string, 'create' | 'read' | 'update' | 'delete'][];

export const checkPermissions = (permissions: { [string]: Permission }, ...conditions: Condition[]): boolean =>
  conditions.some(condition => {
    if (isArray(condition[0])) {
      return condition.every(([group, action]) => permissions[group] && permissions[group][action]);
    }

    const [group, action] = condition;

    // $FlowFixMe
    return permissions[group] && permissions[group][action];
  });
