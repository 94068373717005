import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';

import { cancelOrder } from 'app/redux/orders/orders.actions';

import { SubscriptionOrderSummaryComponent } from './subscription-order-summary.component';

const mapStateToProps = (
  { orders: { entities: orders }, subscriptions: { entities: subscriptions }, form },
  {
    match: {
      params: { order, subscription },
    },
  },
) => ({
  order: orders[order],
  subscription: subscriptions[subscription],
  orderTotal: orders[order].products.map(product => product.amount).reduce((sum, value) => sum + value),
  form: form && form['order-subscription'],
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { order, type },
    },
  },
) => ({
  onCancel: () =>
    dispatch(cancelOrder(order)).then(() =>
      dispatch(
        push({
          pathname: `/subscriptions/${type}`,
          state: {
            success: {
              type: 'CANCEL_ORDER',
            },
          },
        }),
      ),
    ),
});

export const SubscriptionOrderSummaryContainer = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SubscriptionOrderSummaryComponent);
