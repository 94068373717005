// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { GroupComponent } from 'app/shared';

import type { Props } from './subscriptions-initiate-errors.component.types';

const KEYS_MAP = {
  address_city: 'CITY',
  address_street: 'ADDRESS',
  address_zip: 'ZIP_CODE',
  email: 'EMAIL',
  first_name: 'FIRST_NAME',
  last_name: 'LAST_NAME',
  mobile_phone: 'MOBILE_PHONE',
  exercise_and_culture_vouchers: 'EXERCISE_AND_CULTURE_VOUCHER',
  exercise_vouchers: 'EXERCISE_VOUCHER',
  vouchers: 'VOUCHERS',
};

export class SubscriptionsInitiateErrorsComponent extends Component<Props> {
  render() {
    if (!this.props.meta.submitFailed || !this.props.meta.error) {
      return null;
    }

    const { error: errors } = this.props.meta;
    const stringError = typeof errors === 'string';
    const indexes = Object.keys(this.props.meta.error);

    return (
      <GroupComponent>
        {stringError && (
          <div className="invalid-feedback">
            <FormattedMessage id={`COUPONS.IMPORT.${errors}`} />
          </div>
        )}
        {!stringError &&
          indexes.slice(0, 3).map(line => (
            <div className="invalid-feedback" key={line}>
              <strong className="text-dark">
                <FormattedMessage id="COUPONS.IMPORT.VALIDATION.LINE" values={{ line: Number(line) + 1 }} />
              </strong>
              <ul>
                {Object.keys(errors[line]).map(index => (
                  <li key={index}>
                    <FormattedMessage id={`PROFILE.${KEYS_MAP[index]}`} tagName="strong" />:{' '}
                    <FormattedMessage id={errors[line][index]} />
                  </li>
                ))}
              </ul>
            </div>
          ))}

        {indexes.length > 3 &&
          !stringError && (
            <div className="invalid-feedback">
              <FormattedMessage id="COUPONS.IMPORT.VALIDATION.MORE" values={{ number: errors.length - 3 }} />
            </div>
          )}
      </GroupComponent>
    );
  }
}
