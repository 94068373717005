// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { ButtonComponent, TileComponent } from 'app/shared';

import { SubscriptionBeneficiariesEditableContainer } from '../editable/subscription-beneficiaries-editable.container';
import { SubscriptionBeneficiariesStatusComponent } from '../status/subscription-beneficiaries-status.component';

import styles from './subscription-beneficiaries-tile.module.scss';
import type { Props } from './subscription-beneficiaries-tile.component.types';

export class SubscriptionBeneficiariesTileComponent extends Component<Props> {
  render() {
    return (
      <TileComponent>
        <div className={styles.heading}>
          <SubscriptionBeneficiariesStatusComponent status={this.props.tile.status} />
          <h3>
            <Link
              to={{
                pathname: `${this.props.url}/${this.props.tile.id}`,
                search: this.props.location.search,
                state: {
                  keepScrollPosition: true,
                },
              }}
            >
              {this.props.tile.user.firstName} {this.props.tile.user.lastName}
            </Link>
          </h3>
        </div>

        <div className="row">
          {this.props.benefits.map((benefit, index) => (
            <div className="col-md-6 mb-2" key={index}>
              <FormattedMessage id={`PRODUCT.${benefit}`} tagName="small" />
              <SubscriptionBeneficiariesEditableContainer
                disabled={this.props.disabled}
                value={this.props.tile.items[benefit] || 0}
                type={this.props.tile.type}
                benefit={benefit}
                errorPath={['errors', 'benefits', 0, 'amount']}
                onSubmit={amount =>
                  this.props.onEdit(this.props.tile.id, {
                    benefits: [
                      {
                        amount,
                        id: benefit,
                      },
                    ],
                  })
                }
              />
            </div>
          ))}
          <div className="col-md-6 mb-2">
            <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.EXCESS" tagName="small" />
            <SubscriptionBeneficiariesEditableContainer
              disabled={this.props.disabled}
              value={this.props.tile.deductibleAmount || 0}
              errorPath={['errors', 'deductibleAmount']}
              onSubmit={deductibleAmount => this.props.onEdit(this.props.tile.id, { deductibleAmount })}
            />
          </div>
        </div>
        <div className="buttons">
          {this.props.disabled || (
            <>
              {this.props.tile.status === 'APPROVED' || (
                <ButtonComponent
                  theme="outline-secondary"
                  size="small"
                  onClick={() => this.props.onEdit(this.props.tile.id, { status: 'APPROVED' })}
                  loading={this.props.tile.submitting}
                >
                  <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.APPROVE" />
                </ButtonComponent>
              )}
              {this.props.tile.status === 'DECLINED' || (
                <ButtonComponent
                  theme="outline-secondary"
                  size="small"
                  onClick={() => this.props.onEdit(this.props.tile.id, { status: 'DECLINED' })}
                  loading={this.props.tile.submitting}
                >
                  <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.REJECT" />
                </ButtonComponent>
              )}
            </>
          )}
        </div>
      </TileComponent>
    );
  }
}
