// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Route, Switch } from 'react-router-dom';

import { HeaderComponent } from 'app/core';
import { CardComponent, TableComponent } from 'app/shared';

import { AgreementsDetailsContainer } from '../details/agreements-details.container';

import type { Props } from './agreements-list.component.types';

const filterTerms = terms => {
  const keys = {
    SALDO_2019: 'SALDO_2019',
    ORDER_BANKNOTES_2019: 'ORDER_BANKNOTES_2019',
    SERVICE_PROVIDER_2019: 'SERVICE_PROVIDER_2019',
  };
  return terms.filter(term => {
    if (keys[term.key]) {
      return false;
    }
    return true;
  });
};

export class AgreementsListComponent extends Component<Props> {
  static defaultProps = {
    terms: [],
  };

  render() {
    return (
      <>
        <HeaderComponent breadcrumbs={[{ icon: 'signing-the-contract' }]}>
          <FormattedMessage id="NAV.SETTINGS.TERMS" tagName="h2" />
        </HeaderComponent>

        <CardComponent>
          <div className="card-body">
            <TableComponent className="table-stripped">
              <tbody>
                {filterTerms(this.props.terms).map((term, index) => (
                  <tr key={index}>
                    <td>
                      <Link to={`/terms/${term.key}`}>
                        <FormattedMessage id={`TERMS.TITLE.${term.key}`} />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </TableComponent>
          </div>
        </CardComponent>

        <Switch>
          <Route path={`${this.props.match.url}/:key`} component={AgreementsDetailsContainer} />
        </Switch>
      </>
    );
  }
}
