// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

import { ConnectedGroupComponent } from 'app/shared';

import { RadioComponent } from './radio/radio.component';

import type { Props } from './connected-radios.component.types';
import styles from './connected-radios.module.scss';

export class ConnectedRadiosComponent extends Component<Props> {
  render() {
    const optionClassNames = classNames(styles.option, {
      [styles[this.props.theme]]: !!this.props.theme,
    });

    return (
      <ConnectedGroupComponent {...this.props}>
        <div className={styles.options}>
          {this.props.options.map((option, index) => (
            <div className={optionClassNames} key={index}>
              <RadioComponent
                option={option}
                checked={option.id === this.props.input.value}
                onChange={() => this.props.input.onChange(option.id)}
                invalid={this.props.meta.submitFailed && !!this.props.meta.error}
                theme={this.props.theme}
              />
            </div>
          ))}
        </div>
      </ConnectedGroupComponent>
    );
  }
}
