// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent, TileComponent } from 'app/shared';

import { SubscriptionBeneficiariesStatusComponent } from 'app/subscriptions/beneficiaries/status/subscription-beneficiaries-status.component';

import type { Props } from './cards-list-tile.component.types';
import styles from 'app/subscriptions/beneficiaries/tile/subscription-beneficiaries-tile.module.scss';

export class CardsListTileComponent extends Component<Props> {
  render() {
    return (
      <TileComponent>
        <div className={styles.heading}>
          <SubscriptionBeneficiariesStatusComponent status={this.props.tile.active ? 'APPROVED' : 'DECLINED'} />
          <h3>{this.props.tile.cardNumber}</h3>
        </div>
        <div className="buttons">
          {this.props.tile.active ? (
            <ButtonComponent
              theme="outline-danger"
              size="small"
              onClick={() => this.props.onDisable(this.props.tile.id)}
            >
              <FormattedMessage id="CARDS.REPORT_MISSING_CARD" />
            </ButtonComponent>
          ) : (
            <ButtonComponent
              theme="outline-success"
              size="small"
              onClick={() => this.props.onActivate(this.props.tile.id)}
            >
              <FormattedMessage id="CARDS.ACTIVATE_CARD" />
            </ButtonComponent>
          )}
        </div>
      </TileComponent>
    );
  }
}
