// @flow

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { VouchersCheckOwnerContainer } from './check-owner/vouchers-check-owner.container';

import type { Props } from './vouchers.component.types';

export class VouchersComponent extends Component<Props> {
  render() {
    return (
      <Switch>
        <Route path={`${this.props.match.url}/check-owner`} component={VouchersCheckOwnerContainer} />
      </Switch>
    );
  }
}
