import { combineActions, handleActions } from 'redux-actions';
import { get, omit } from 'lodash';

import * as types from './subscriptions.action-types';

const defaultState = {
  beneficiaries: {},
  entities: {},
};

export const importStatus = {
  OK: 'ok',
  FAILED: 'failed',
  PENDING: 'pending',
  CANCELLED: 'cancelled',
};

export const subscriptionsReducer = handleActions(
  {
    [types.FETCH_SUBSCRIPTIONS_REQUEST]: (state, { meta: { business, type } }) => ({
      ...state,
      [type]: {
        ...state[type],
        [business]: {
          ...omit(get(state, [type, business]), ['list', 'meta']),
        },
      },
    }),
    [types.FETCH_SUBSCRIPTIONS_SUCCESS]: (state, { payload: { data: list, meta }, meta: { business, type } }) => ({
      ...state,
      [type]: {
        ...state[type],
        [business]: { ...get(state, [type, business]), list, meta },
      },
    }),
    [types.FETCH_ALL_SUBSCRIPTIONS_REQUEST]: (state, { meta: { business, type } }) => ({
      ...state,
      [type]: {
        ...state[type],
        [business]: {
          ...omit(get(state, [type, business]), 'allList'),
        },
      },
    }),
    [types.FETCH_ALL_SUBSCRIPTIONS_SUCCESS]: (state, { payload: { data }, meta: { business, type } }) => ({
      ...state,
      [type]: {
        ...state[type],
        [business]: {
          ...get(state, [type, business]),
          allList: data.map(({ id, title }) => ({ id, title })),
        },
      },
    }),
    [combineActions(types.FETCH_SUBSCRIPTION_REQUEST, types.FETCH_PUBLIC_SUBSCRIPTION_REQUEST)]: (
      state,
      { meta: { subscription } },
    ) => ({
      ...state,
      entities: {
        ...state.entities,
        [subscription]: undefined,
      },
    }),
    [types.FETCH_SUBSCRIPTION_SUCCESS]: (state, { meta: { subscription }, payload: { data: entity } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [subscription]: entity,
      },
    }),
    [types.FETCH_PUBLIC_SUBSCRIPTION_SUCCESS]: (
      state,
      {
        meta: { subscription },
        payload: {
          data: entity,
          meta: { multipliers },
        },
      },
    ) => ({
      ...state,
      entities: {
        ...state.entities,
        [subscription]: {
          ...entity,
          multipliers,
        },
      },
    }),
    [types.FETCH_BENEFICIARIES_REQUEST]: (state, { meta: { subscription } }) => ({
      ...state,
      beneficiaries: {
        ...state.beneficiaries,
        [subscription]: undefined,
      },
    }),
    [types.FETCH_BENEFICIARIES_SUCCESS]: (state, { meta: { subscription }, payload: { data: list, meta } }) => ({
      ...state,
      beneficiaries: {
        ...state.beneficiaries,
        [subscription]: { list, meta },
      },
    }),
    [types.ADD_BENEFICIARY_SUCCESS]: (state, { meta: { size, subscription }, payload: { data: beneficiary } }) => ({
      ...state,
      beneficiaries: {
        ...state.beneficiaries,
        [subscription]: {
          ...state.beneficiaries[subscription],
          list:
            state.beneficiaries[subscription].list.length < size
              ? [...state.beneficiaries[subscription].list, beneficiary]
              : state.beneficiaries[subscription].list,
          meta: {
            ...state.beneficiaries[subscription].meta,
            total:
              state.beneficiaries[subscription].meta.totalItems % size === 0
                ? state.beneficiaries[subscription].meta.total + 1
                : state.beneficiaries[subscription].meta.total,
            totalItems: state.beneficiaries[subscription].meta.totalItems + 1,
          },
        },
      },
      entities: {
        ...state.entities,
        [subscription]: {
          ...state.entities[subscription],
          summary: {
            ...state.entities[subscription].summary,
            beneficiaries: {
              ...state.entities[subscription].summary.beneficiaries,
              approved: state.entities[subscription].summary.beneficiaries.approved + 1,
              total: state.entities[subscription].summary.beneficiaries.total + 1,
            },
          },
        },
      },
    }),
    [types.EDIT_BENEFICIARY_REQUEST]: (state, { meta: { beneficiary, subscription } }) => ({
      ...state,
      beneficiaries: {
        ...state.beneficiaries,
        [subscription]: {
          ...state.beneficiaries[subscription],
          list: state.beneficiaries[subscription].list.map(
            element =>
              element.id === beneficiary
                ? {
                    ...element,
                    submitting: true,
                  }
                : element,
          ),
        },
      },
    }),
    [types.EDIT_BENEFICIARY_SUCCESS]: (
      state,
      {
        meta: {
          beneficiary,
          body: { benefits = [], deductibleAmount, status },
          subscription,
        },
      },
    ) => ({
      ...state,
      entities: {
        ...state.entities,
        [subscription]: {
          ...state.entities[subscription],
          summary: {
            ...state.entities[subscription].summary,
            beneficiaries: {
              ...state.entities[subscription].summary.beneficiaries,
              ...(status
                ? ((prev, next, state) => ({ [prev]: --state[prev], [next]: ++state[next] }))(
                    state.beneficiaries[subscription].list.find(({ id }) => id === beneficiary).status.toLowerCase(),
                    status.toLowerCase(),
                    state.entities[subscription].summary.beneficiaries,
                  )
                : {}),
            },
          },
        },
      },
      beneficiaries: {
        ...state.beneficiaries,
        [subscription]: {
          ...state.beneficiaries[subscription],
          list: state.beneficiaries[subscription].list.map(
            element =>
              element.id === beneficiary
                ? {
                    ...element,
                    ...{
                      deductibleAmount: deductibleAmount || element.deductibleAmount,
                      status: status || element.status,
                    },
                    items: {
                      ...element.items,
                      ...benefits.reduce((prev, next) => ({ ...prev, [next.id]: next.amount }), {}),
                    },
                    submitting: false,
                  }
                : element,
          ),
        },
      },
    }),
    [types.EDIT_BENEFICIARY_FAILURE]: (state, { meta: { beneficiary, subscription } }) => ({
      ...state,
      beneficiaries: {
        ...state.beneficiaries,
        [subscription]: {
          ...state.beneficiaries[subscription],
          list: state.beneficiaries[subscription].list.map(
            element =>
              element.id === beneficiary
                ? {
                    ...element,
                    submitting: false,
                  }
                : element,
          ),
        },
      },
    }),
    [types.EDIT_BENEFICIARIES_REQUEST]: (state, { meta: { subscription } }) => ({
      ...state,
      beneficiaries: {
        ...state.beneficiaries,
        [subscription]: {
          ...state.beneficiaries[subscription],
          list: state.beneficiaries[subscription].list.map(element => ({
            ...element,
            submitting: true,
          })),
        },
      },
    }),
    [types.EDIT_BENEFICIARIES_SUCCESS]: (
      state,
      {
        meta: {
          body: { benefits = [], deductibleAmount, status },
          subscription,
        },
      },
    ) => ({
      ...state,
      entities: {
        ...state.entities,
        [subscription]: {
          ...state.entities[subscription],
          summary: {
            ...state.entities[subscription].summary,
            beneficiaries: {
              ...(status
                ? {
                    approved: 0,
                    declined: 0,
                    new: 0,
                    total: state.entities[subscription].summary.beneficiaries.total,
                    [status.toLowerCase()]: state.entities[subscription].summary.beneficiaries.total,
                  }
                : state.entities[subscription].summary.beneficiaries),
            },
          },
        },
      },
      beneficiaries: {
        ...state.beneficiaries,
        [subscription]: {
          ...state.beneficiaries[subscription],
          list: state.beneficiaries[subscription].list.map(element => ({
            ...element,
            ...{ deductibleAmount: deductibleAmount || element.deductibleAmount, status: status || element.status },
            items: {
              ...element.items,
              ...benefits.reduce((prev, next) => ({ ...prev, [next.id]: next.amount }), {}),
            },
            submitting: false,
          })),
        },
      },
    }),
    [types.EDIT_BENEFICIARIES_FAILURE]: (state, { meta: { subscription } }) => ({
      ...state,
      beneficiaries: {
        ...state.beneficiaries,
        [subscription]: {
          ...state.beneficiaries[subscription],
          list: state.beneficiaries[subscription].list.map(element => ({
            ...element,
            submitting: false,
          })),
        },
      },
    }),
    [types.IMPORT_SUBSCRIPTION_SUCCESS]: (state, payload) => ({
      ...state,
      importStatus: importStatus.PENDING,
    }),
    [types.CHECK_IMPORT_SUBSCRIPTION_SUCCESS]: (state, payload) => {
      const status = get(payload, ['payload', 'data', 'id']) ? importStatus.OK : importStatus.PENDING;
      return {
        ...state,
        importStatus: status,
      };
    },
    [types.CHECK_IMPORT_SUBSCRIPTION_FAILURE]: (state, payload) => ({
      ...state,
      importStatus: importStatus.FAILED,
    }),
    [types.SET_CANCELLED_IMPORT_STATUS]: state => ({
      ...state,
      importStatus: importStatus.CANCELLED,
    }),
  },
  defaultState,
);
