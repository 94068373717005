// @flow

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { AccountDetailsContainer } from './details/account-details.container';
import { AccountsListContainer } from './list/accounts-list.container';
import { AccountTransactionsContainer } from './transactions/account-transactions.container';
import { MunicipalAccountContainer } from './municipal/accounts-municipal.container';
import { MyAccountsContainer } from './my/my-accounts.container';

import type { Props } from './accounts.component.types';

export class AccountsComponent extends Component<Props> {
  render() {
    return (
      <Switch>
        <Route
          path={`${this.props.match.url}/:number([0-9]{10,19})/transactions`}
          component={AccountTransactionsContainer}
        />
        <Route path={`${this.props.match.url}/municipal`} component={MunicipalAccountContainer} />
        <Route path={`${this.props.match.url}/:number([0-9]{10,19})`} component={AccountDetailsContainer} />
        <Route path={`${this.props.match.url}/:type(company)`} component={AccountsListContainer} />
        <Route path={this.props.match.url} component={MyAccountsContainer} exact />
      </Switch>
    );
  }
}
