// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, Fields } from 'redux-form';
import classNames from 'classnames';
import MediaQuery from 'react-responsive';

import { currencyFormatter } from 'app/utils';

import { ButtonComponent, ConnectedInputComponent, ConnectedRadioComponent, SummaryFieldComponent } from 'app/shared';
import { LUNCH_TYPE, calculateDeductibleAmount, getScopedNumber, lunchValuesRange } from './benefit-calculator.util';

import type { Event } from 'redux-form/es/types';
import type { Props } from './benefit-calculator.types';
import styles from './benefit-calculator.module.scss';

export class BenefitCalculatorComponent extends Component<Props> {
  createHandleBlur = (name: string, min: number, max: number, fractions: number = 0) => (e: Event, value: string) => {
    e.preventDefault();

    if (value === '') {
      return;
    }

    const number = getScopedNumber(value, min, max);
    this.props.blur(name, number.toFixed(fractions));
  };

  onAmountBlur = this.createHandleBlur('amount', lunchValuesRange.AMOUNT_MIN, lunchValuesRange.AMOUNT_MAX, 2);

  onMultiplierBlur = this.createHandleBlur(
    'multiplier',
    lunchValuesRange.MULTIPLIER_MIN,
    lunchValuesRange.MULTIPLIER_MAX,
  );

  componentDidMount() {
    if (!this.props.amount) {
      this.props.clearCalculator(this.props.form);
    }
  }

  render() {
    const columnClassNames = size =>
      classNames(`col-${size}`, `col-xl-${size}`, {
        [`col-lg-${size}`]: this.props.sidebarCollapsed,
      });

    const lunchOption = {
      id: LUNCH_TYPE.BENEFIT,
      name: this.props.intl.formatMessage({ id: 'SUBSCRIPTION.BENEFIT' }),
    };

    const deductibleOption = {
      id: LUNCH_TYPE.DEDUCTIBLE,
      name: this.props.intl.formatMessage({ id: 'SUBSCRIPTION.DEDUCTIBLE' }),
    };

    return (
      <div className="col-12 mt-3">
        <div className={styles.calculator}>
          <div className="col-8 col-lg-12">
            <div className={styles.radios}>
              <div className="align-self-end">
                <Field
                  name="role"
                  theme="simple"
                  component={ConnectedRadioComponent}
                  label={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.CALCULATE_LUNCH' })}
                  option={lunchOption}
                />
              </div>
              <div className="align-self-end">
                <Field name="role" theme="simple" component={ConnectedRadioComponent} option={deductibleOption} />
              </div>
            </div>
            <Field
              name="role"
              component={
                (({ input }) =>
                  input.value === LUNCH_TYPE.BENEFIT ? (
                    <FormattedMessage id="SUBSCRIPTION.BENEFIT_PRICE" tagName="small" />
                  ) : (
                    <FormattedMessage id="SUBSCRIPTION.DEDUCTIBLE_PRICE" tagName="small" />
                  ): Function)
              }
            />
          </div>
          <MediaQuery maxWidth={991}>
            <div className="col-4 text-right">
              <ButtonComponent className="mt-2" theme="primary" size="small" onClick={this.props.submit}>
                <FormattedMessage id="CORE.SAVE" />
              </ButtonComponent>
            </div>
          </MediaQuery>
          <div className="col-lg-8">
            <div className="row">
              <div className={columnClassNames(8)}>
                <Field
                  name="amount"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.AMOUNT' })}
                  placeholder={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.PLACEHOLDER.AMOUNT' })}
                  parse={v => v.replace(/,/g, '.')}
                  onBlur={this.onAmountBlur}
                />
              </div>
              <div className={columnClassNames(4)}>
                <Field
                  name="multiplier"
                  placeholder="0"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'SUBSCRIPTION.MULTIPLIER' })}
                  parse={v => v.replace(/,/g, '.')}
                  onBlur={this.onMultiplierBlur}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 mt-3">
            <div className="row text-lg-right">
              <SummaryFieldComponent className="col-6 mb-0">
                <FormattedMessage id="SUBSCRIPTION.DEDUCTIBLE_AMOUNT" tagName="strong" />
                <Fields
                  names={['amount', 'multiplier', 'role']}
                  component={({ amount, multiplier, role }) =>
                    currencyFormatter.format(
                      calculateDeductibleAmount(role.input.value, amount.input.value, multiplier.input.value),
                    )
                  }
                />
              </SummaryFieldComponent>
              <SummaryFieldComponent className="col-6 mb-0">
                <FormattedMessage id="CORE.TOTAL" tagName="strong" />
                <Fields
                  names={['amount', 'multiplier']}
                  component={({ amount, multiplier }) =>
                    currencyFormatter.format((amount.input.value || 0) * (multiplier.input.value || 0))
                  }
                />
              </SummaryFieldComponent>
              <MediaQuery minWidth={992}>
                <div className="col-12">
                  <ButtonComponent className="mt-2" theme="primary" size="small" onClick={this.props.submit}>
                    <FormattedMessage id="CORE.SAVE" />
                  </ButtonComponent>
                </div>
              </MediaQuery>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
