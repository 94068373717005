// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

import { NavLink } from 'react-router-dom';

import type { Props } from './subscription-nav-item.component.types';

import styles from './subscription-nav-item.module.scss';

export class SubscriptionNavItemComponent extends Component<Props> {
  render() {
    const navItemClassNames = classNames(styles.element, {
      [styles.disabled]: this.props.disabled,
      [styles.active]: this.props.blocked && !this.props.disabled,
    });

    if (this.props.disabled || this.props.blocked) {
      return <span className={navItemClassNames}>{this.props.children}</span>;
    }

    return (
      <NavLink activeClassName={styles.active} className={styles.element} to={this.props.to} exact={this.props.exact}>
        {this.props.children}
      </NavLink>
    );
  }
}
