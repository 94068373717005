// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { HeaderComponent } from 'app/core';
import { UsersListContainer } from 'app/common/users';
import { isBusinessDifferent } from 'app/utils';

import { UsersPlaceholder } from './placeholder/users.placeholder';

import type { Props } from './users.component.types';

export class UsersComponent extends Component<Props> {
  componentDidMount() {
    this.props.fetchDictionary();
    this.props.fetchUsers(this.props.business);
  }

  componentDidUpdate(prevProps: Props) {
    if (isBusinessDifferent(prevProps, this.props)) {
      this.props.fetchUsers(this.props.business);
    }
  }

  render() {
    if (this.props.loading) {
      return <UsersPlaceholder />;
    }

    return (
      <>
        <HeaderComponent breadcrumbs={[{ icon: 'multiple-users-silhouette' }]}>
          <FormattedMessage id="USERS.USERS_TITLE" values={{ business: this.props.name }} tagName="h2" />
        </HeaderComponent>

        <UsersListContainer context="customer" customer={this.props.business} users={this.props.users} />
      </>
    );
  }
}
