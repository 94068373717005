import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';

import get from 'lodash/get';

import { fetchTerms } from 'app/redux/terms/terms.actions';
import { approveTerms } from 'app/redux/users/users.actions';

import { AgreementsDetailsComponent } from './agreements-details.component';

const mapStateToProps = (
  {
    agreements: { terms },
    users: {
      current: { terms: settings },
    },
  },
  {
    intl: { locale },
    match: {
      params: { key },
    },
  },
) => ({
  approved: settings.find(term => term.key === key).approved,
  viewOnly: settings.find(term => term.key === key).viewOnly,
  loading: !terms[key],
  terms: get(terms, [key, locale]),
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { key },
    },
  },
) => ({
  fetchTerms: () => dispatch(fetchTerms(key)),
  onSubmit: () => dispatch(approveTerms(key)),
  onClose: () =>
    dispatch(
      push({
        pathname: '/terms',
        state: {
          keepScrollPosition: true,
        },
      }),
    ),
});

export const AgreementsDetailsContainer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({ form: 'accept-terms' }),
)(AgreementsDetailsComponent);
