// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { PlaceholderComponent, TileComponent } from 'app/shared';

export class SubscriptionBeneficiariesTilePlaceholder extends Component<any> {
  render() {
    return (
      <TileComponent>
        <h3>
          <PlaceholderComponent width={160} height={27} />
        </h3>

        <div className="row">
          <div className="col">
            <FormattedMessage id="PAYMENT.ITEM.EXERCISE_EMONEY" tagName="small" />
            <PlaceholderComponent width={80} height={18} />
          </div>
          <div className="col">
            <FormattedMessage id="SUBSCRIPTION.BENEFICIARIES.EXCESS" tagName="small" />
            <PlaceholderComponent width={80} height={18} />
          </div>
        </div>
      </TileComponent>
    );
  }
}
