// @flow

import { connect } from 'react-redux';

import type { ComponentType } from 'react';

const mapStateToProps = ({ permissions, users: { business, role } }) => ({
  business,
  permissions: permissions && permissions[business] && permissions[business][role] ? permissions[business][role] : {}
});

export const withPermissions = (WrappedComponent: ComponentType<any>) => connect(mapStateToProps)(WrappedComponent);
