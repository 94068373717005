import { RSAA } from 'redux-api-middleware';

import * as types from './users.action-types';

export const loginUser = ({ username, password }) => ({
  [RSAA]: {
    types: [types.LOGIN_REQUEST, types.LOGIN_SUCCESS, types.LOGIN_FAILURE],
    endpoint: '/login',
    method: 'POST',
    isPublic: true,
    body: {
      username,
      password,
    },
  },
});

export const refreshToken = refreshToken => ({
  [RSAA]: {
    types: [
      types.REFRESH_TOKEN_REQUEST,
      types.REFRESH_TOKEN_SUCCESS,
      {
        type: types.REFRESH_TOKEN_FAILURE,
        meta: {
          noValidation: true,
        },
      },
    ],
    endpoint: '/refresh-token',
    method: 'POST',
    isPublic: true,
    body: {
      refreshToken,
    },
  },
});

export const logoutUser = (redirect = true, cause) => ({
  [RSAA]: {
    types: [
      {
        type: types.LOGOUT_REQUEST,
        meta: {
          redirect,
          state: {
            cause,
          },
        },
      },
      types.LOGOUT_SUCCESS,
      types.LOGOUT_FAILURE,
    ],
    endpoint: '/logout',
    method: 'POST',
  },
});

const fetchCurrentUserRSAA = initial => ({
  [RSAA]: {
    types: [
      {
        type: types.FETCH_CURRENT_USER_REQUEST,
        meta: { initial },
      },
      types.FETCH_CURRENT_USER_SUCCESS,
      types.FETCH_CURRENT_USER_FAILURE,
    ],
    endpoint: '/users/me',
    method: 'GET',
  },
});

const shouldFetchCurrentUser = ({ users }) => !('isAuthenticated' in users);

export const fetchCurrentUser = (initial = false) => (dispatch, getState) => {
  if (!initial || shouldFetchCurrentUser(getState())) {
    return dispatch(fetchCurrentUserRSAA(initial));
  } else {
    return Promise.resolve();
  }
};

export const fetchContractData = customerId => ({
  [RSAA]: {
    types: [types.FETCH_CONTRACT_DATA_REQUEST, types.FETCH_CONTRACT_DATA_SUCCESS, types.FETCH_CONTRACT_DATA_FAILURE],
    endpoint: `/subclasses/${customerId}`,
    method: 'GET',
  },
});

export const requestNewPassword = username => ({
  [RSAA]: {
    types: [types.REQUEST_NEW_PASSWORD_REQUEST, types.REQUEST_NEW_PASSWORD_SUCCESS, types.REQUEST_NEW_PASSWORD_FAILURE],
    endpoint: '/users/restore-password',
    method: 'POST',
    isPublic: true,
    body: {
      username,
    },
  },
});

export const resetPassword = (passwordToken, body) => ({
  [RSAA]: {
    types: [types.RESET_PASSWORD_REQUEST, types.RESET_PASSWORD_SUCCESS, types.RESET_PASSWORD_FAILURE],
    endpoint: `/users/restore-password/${passwordToken}`,
    method: 'POST',
    isPublic: true,
    body,
  },
});

export const generateMagicLink = username => ({
  [RSAA]: {
    types: [types.GENERATE_MAGIC_LINK_REQUEST, types.GENERATE_MAGIC_LINK_SUCCESS, types.GENERATE_MAGIC_LINK_FAILURE],
    endpoint: '/login/token',
    method: 'POST',
    isPublic: true,
    body: {
      username,
    },
  },
});

export const loginWithToken = token => ({
  [RSAA]: {
    types: [types.LOGIN_WITH_TOKEN_REQUEST, types.LOGIN_WITH_TOKEN_SUCCESS, types.LOGIN_WITH_TOKEN_FAILURE],
    endpoint: `/login/${token}`,
    method: 'PATCH',
    isPublic: true,
  },
});

export const updateProfile = (section, body) => ({
  [RSAA]: {
    types: [types.UPDATE_PROFILE_REQUEST, types.UPDATE_PROFILE_SUCCESS, types.UPDATE_PROFILE_FAILURE],
    endpoint: `/users/me/${section}`,
    method: 'PATCH',
    body,
  },
});

export const changeBusiness = business => (dispatch, getState) => {
  const { permissions } = getState();

  if (permissions[business]) {
    return dispatch({ type: types.CHANGE_BUSINESS, payload: { business, permissions } });
  } else {
    return Promise.resolve();
  }
};

export const changeRole = role => (dispatch, getState) => {
  const {
    permissions,
    users: { business },
  } = getState();

  if (permissions[business][role]) {
    return dispatch({ type: types.CHANGE_ROLE, payload: role });
  } else {
    return Promise.resolve();
  }
};

export const editColumnsSettings = (list, body) => ({
  [RSAA]: {
    types: [
      {
        type: types.EDIT_COLUMNS_SETTINGS_REQUEST,
        meta: { body, list },
      },
      types.EDIT_COLUMNS_SETTINGS_SUCCESS,
      types.EDIT_COLUMNS_SETTINGS_FAILURE,
    ],
    endpoint: `/users/columns-metadata/${list}`,
    method: 'PUT',
    body,
  },
});

export const approveTerms = key => ({
  [RSAA]: {
    types: [
      types.APPROVE_TERMS_REQUEST,
      {
        type: types.APPROVE_TERMS_SUCCESS,
        meta: { key },
      },
      types.APPROVE_TERMS_FAILURE,
    ],
    endpoint: `/users/me/terms/${key}/status`,
    method: 'PUT',
    body: {
      status: 'APPROVED',
    },
  },
});

export const fetchUsers = (customer, context) => ({
  [RSAA]: {
    types: [
      {
        type: types.FETCH_USERS_REQUEST,
        meta: { customer },
      },
      {
        type: types.FETCH_USERS_SUCCESS,
        meta: { customer },
      },
      types.FETCH_USERS_FAILURE,
    ],
    endpoint: `/customers/${customer}/${context}/users`,
    method: 'GET',
  },
});

export const addUser = (customer, context, body) => ({
  [RSAA]: {
    types: [
      types.ADD_USER_REQUEST,
      {
        type: types.ADD_USER_SUCCESS,
        meta: {
          user: body,
          customer,
        },
      },
      types.ADD_USER_FAILURE,
    ],
    endpoint: `/customers/${customer}/${context}/users`,
    method: 'POST',
    body,
  },
});

export const editUserRoles = (customer, context, user, body, roles) => ({
  [RSAA]: {
    types: [
      types.EDIT_USER_ROLES_REQUEST,
      {
        type: types.EDIT_USER_ROLES_SUCCESS,
        meta: {
          customer,
          user,
          roles,
        },
      },
      types.EDIT_USER_ROLES_FAILURE,
    ],
    endpoint: `/customers/${customer}/${context}/users/${user}/roles`,
    method: 'POST',
    body,
  },
});

export const changeHasAccount = hasAccount => dispatch => {
  return dispatch({ type: types.HAS_ACCOUNT_CHANGE, payload: hasAccount });
};
