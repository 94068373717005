// @flow

import React, { Component } from 'react';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';

import { SidebarGroupComponent, SidebarItemContainer } from 'app/core/sidebar/shared';
import { ModalComponent } from 'app/shared';

import { SidebarUserBusinessComponent } from './business/sidebar-user-business.component';
import { SidebarUserRoleComponent } from './role/sidebar-user-role.component';

import type { Props, State } from './sidebar-user.component.types';
import styles from './sidebar-user.module.scss';

export class SidebarUserComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {
    !this.props.roles && this.props.setError();
    try {
      localStorage.setItem('currentBusiness', String(this.props.business.id));
    } catch (err) {}
  };

  onBusinessChange = (value: number) => {
    try {
      localStorage.setItem('currentBusiness', String(value));
    } catch (err) {}

    return this.props.onBusinessChange(value, this.props.businessChangeLocked);
  };

  getDefaultRole = (roles: Array<{ label: string, value: string }>) => {
    let visibleRole = this.props.role;
    if (roles.length > 0) {
      for (let i = 0; i < roles.length; i++) {
        if (this.props.role === roles[i].value) {
          return (visibleRole = this.props.role);
        } else {
          visibleRole = roles[0].value;
        }
      }
    }

    return visibleRole;
  };

  render() {
    const businesses = this.props.businesses.map(business => ({
      value: business.id,
      label: `${business.id} · ${business.name}`,
    }));
    const roles = this.props.roles
      ? Object.keys(this.props.roles).map(role => ({
          value: role,
          label: this.props.intl.formatMessage({ id: `ROLES.${role}` }),
        }))
      : [];

    return (
      <>
        <div className={styles.header}>
          <div className={styles.user}>
            <div className={styles.name}>
              <p className={styles.title}>{this.props.intl.formatMessage({ id: 'SIDEBAR.SUBSCRIBER' })}</p>
              <p>
                {this.props.firstName} {this.props.lastName}
              </p>
              <FormattedMessage id="SIDEBAR.USER_ID" values={{ userId: this.props.userId }}>
                {text => <p className={styles.userId}>{text}</p>}
              </FormattedMessage>
            </div>
            {this.props.isApproved && (
              <ul className={styles.profile}>
                <SidebarItemContainer
                  name={this.props.intl.formatMessage({ id: 'SIDEBAR.PROFILE' })}
                  icon="profile"
                  to="/profile"
                  isCollapsed
                />
              </ul>
            )}
          </div>

          {this.props.isApproved && (
            <>
              <div className={styles.icons}>
                <SidebarGroupComponent>
                  {(this.props.businesses.length > 1 ||
                    (this.props.business && !this.props.business.subBusinesses)) && (
                    <SidebarItemContainer
                      name={this.props.intl.formatMessage({ id: 'SIDEBAR.SWITCH_COMPANY' })}
                      onClick={() => this.setState({ isCompanySwitcherOpened: true })}
                      icon="switch-company"
                    />
                  )}
                  {!!this.props.roles && (
                    <SidebarItemContainer
                      name={this.props.intl.formatMessage({ id: 'SIDEBAR.SWITCH_ROLE' })}
                      onClick={() => this.setState({ isRoleSwitcherOpened: true })}
                      icon="switch-role"
                    />
                  )}
                </SidebarGroupComponent>
              </div>
              <div className={styles.buttons}>
                {(this.props.businesses.length || (this.props.business && !this.props.business.subBusinesses)) && (
                  <div className={styles.select} data-name="business">
                    <Select
                      name="company"
                      value={this.props.business.id}
                      options={businesses}
                      onChange={({ value }) => this.onBusinessChange(value)}
                      clearable={false}
                      searchable={true}
                      autoBlur={false}
                    />
                  </div>
                )}

                {!!this.props.roles && (
                  <div className={styles.select} data-name="role">
                    <Select
                      name="role"
                      value={this.getDefaultRole(roles)}
                      options={roles}
                      onChange={({ value }) => this.props.onRoleChange(value)}
                      clearable={false}
                      searchable={false}
                      autoBlur={false}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        {this.state.isCompanySwitcherOpened && (
          <ModalComponent
            title={this.props.intl.formatMessage({ id: 'SIDEBAR.SWITCH_COMPANY' })}
            subtitle={this.props.intl.formatMessage({ id: 'SIDEBAR.SWITCH_COMPANY_DESC' })}
            onClose={() => this.setState({ isCompanySwitcherOpened: false })}
          >
            {this.props.businesses.map(business => (
              <SidebarUserBusinessComponent
                key={business.id}
                business={business}
                intl={this.props.intl}
                selected={this.props.business.id}
                onSelect={() =>
                  this.onBusinessChange(business.id).then(() => this.setState({ isCompanySwitcherOpened: false }))
                }
              />
            ))}
          </ModalComponent>
        )}

        {this.state.isRoleSwitcherOpened && (
          <ModalComponent
            title={this.props.intl.formatMessage({ id: 'SIDEBAR.SWITCH_ROLE' })}
            subtitle={this.props.intl.formatMessage({ id: 'SIDEBAR.SWITCH_ROLE_DESC' })}
            onClose={() => this.setState({ isRoleSwitcherOpened: false })}
          >
            {roles.map(role => (
              <SidebarUserRoleComponent
                key={role.value}
                role={role}
                intl={this.props.intl}
                selected={this.props.role}
                onSelect={() => {
                  this.props.onRoleChange(role.value);
                  this.setState({ isRoleSwitcherOpened: false });
                }}
              />
            ))}
          </ModalComponent>
        )}
      </>
    );
  }
}
