// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
// $FlowFixMe
import { ReactComponent as Bike } from 'assets/icons/bike.svg';
import styles from './bike-benefit-banner.module.scss';
import { USER_ROLES } from 'app/constants';
import type { Props } from './bike-benefit-banner.component.types';

export class BikeBenefitBannerComponent extends Component<Props> {
  render() {
    const role = this.props.role;

    if (role !== USER_ROLES.ROLE_COMPANY_BUYER && role !== USER_ROLES.ROLE_COMPANY_BENEFICIARY) {
      return null;
    }

    const buyerLink =
      'https://www.smartum.fi/pyoraetu/tyonantajille?utm_campaign=Py%C3%B6r%C3%A4etu&utm_source=smartum-extranet&utm_medium=banner&utm_content=employer';
    const beneficiaryLink =
      'https://www.smartum.fi/pyoraetu/tyontekijalle?utm_campaign=Py%C3%B6r%C3%A4etu&utm_source=smartum-extranet&utm_medium=banner&utm_content=employee';

    return (
      <div className={styles.banner}>
        <div>
          <FormattedMessage id="BIKE_BENEFIT_BANNER.TITLE" tagName="h6" />
          <FormattedMessage id="BIKE_BENEFIT_BANNER.DESCRIPTION" tagName="p" />
          <a
            href={role === USER_ROLES.ROLE_COMPANY_BENEFICIARY ? beneficiaryLink : buyerLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="BIKE_BENEFIT_BANNER.LINK" />
          </a>
        </div>
        <div className={styles.icon}>
          <Bike style={{ height: '48px', width: '48px' }} />
        </div>
      </div>
    );
  }
}
