// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { ButtonComponent, TableComponent } from 'app/shared';
import { checkPermissions } from 'app/utils';
import { USER_ROLES } from 'app/constants';

import type { Props } from './users-list-table.component.types';
import styles from './users-list-table.module.scss';

export class UsersListTableComponent extends PureComponent<Props> {
  render() {
    return (
      <TableComponent className="table-striped">
        <thead>
          <tr>
            <FormattedMessage id="USERS.FULL_NAME" tagName="th" />
            <FormattedMessage id="USERS.EMAIL" tagName="th" />
            <FormattedMessage id="USERS.ROLES" tagName="th" />
            <th />
          </tr>
        </thead>
        <tbody>
          {this.props.list.map((user, index) => {
            const canUserEditRole =
              user.active &&
              checkPermissions(this.props.permissions, [`${this.props.context}Users`, 'update']) &&
              !user.roles.includes(USER_ROLES.ROLE_VENUE_ACCOUNTANT);

            return (
              <tr key={index} className={classNames({ [styles.pending]: !user.active })}>
                <td>
                  <strong>
                    {user.firstName} {user.lastName}
                  </strong>
                </td>
                <td>{user.email}</td>
                <td>{user.roles.map(role => this.props.intl.formatMessage({ id: `ROLES.${role}` })).join(', ')}</td>
                <td className="text-right">
                  {user.active || <FormattedMessage id="USERS.PENDING" />}
                  {canUserEditRole && (
                    <ButtonComponent
                      theme="outline-secondary"
                      to={`${this.props.match.url}/${user.id}/roles`}
                      size="small"
                    >
                      <FormattedMessage id="USERS.EDIT_ROLES" /> <i className="fi fi-pencil-edit-button" />
                    </ButtonComponent>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </TableComponent>
    );
  }
}
