// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { uniqueId } from 'lodash';
import classNames from 'classnames';

import { ConnectedGroupComponent, TooltipComponent } from 'app/shared';

import type { Props } from './connected-checkbox.component.types';
import styles from './connected-checkbox.module.scss';

export class ConnectedCheckboxComponent extends Component<Props> {
  render() {
    const labelClassNames = classNames(styles.label, {
      [styles.checked]: this.props.input.value,
      [styles.selectAll]: this.props.theme === 'select-all',
    });

    const titleClassNames = classNames(styles.title, {
      [styles.required]: this.props.required,
    });

    const blankClassNames = classNames(this.props.light ? styles.light : styles.blank);

    const optionName = uniqueId('checkbox_');

    return (
      <ConnectedGroupComponent {...this.props}>
        <div className={labelClassNames}>
          {this.props.theme === 'select-all' && (
            <>
              {this.props.input.value && (
                <label htmlFor={optionName} className={styles.selectAllTitle}>
                  <FormattedMessage id="CORE.DESELECT_ALL" />
                </label>
              )}
              {this.props.input.value || (
                <label htmlFor={optionName} className={styles.selectAllTitle}>
                  <FormattedMessage id="CORE.SELECT_ALL" />
                </label>
              )}
            </>
          )}

          <input
            type="checkbox"
            checked={this.props.input.value}
            {...this.props.input}
            id={optionName}
            className={styles.input}
          />

          <label htmlFor={optionName} className={classNames(blankClassNames, this.props.isEnhanced ? styles.enhancedCheckbox : '')}>
            {this.props.input.value && <i className="fi fi-correct-symbol" />}
          </label>

          {!!this.props.title && (
            <label htmlFor={optionName} className={classNames(titleClassNames, this.props.isEnhanced ? styles.enhancedLabel : '')}>
              <FormattedMessage id={this.props.title} />
            </label>
          )}

          {!!this.props.addon && (
            <span className="input-group-append m-0">
              <span className="input-group-text border-0">
                <TooltipComponent overlay={this.props.addon} placement="top">
                  <span className={styles.info} />
                </TooltipComponent>
              </span>
            </span>
          )}
        </div>
      </ConnectedGroupComponent>
    );
  }
}
