// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import { ButtonComponent, TableComponent, ConnectedInputComponent, AlertComponent } from 'app/shared';

import styles from '../yearly-limits.module.scss';

import type { Props, FormBody } from './yearly-limits-form.component.types';

export class YearlyLimitsFormComponent extends Component<Props> {
  onSubmit = (body: FormBody) => this.props.onSubmit(this.props.business, body);

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <div className="card-header">
          <h4 className="card-title">{this.props.intl.formatMessage({ id: 'YEARLY_LIMITS.LIMIT_BENEFIT_OPTIONS' })}</h4>
          {!this.props.submitting &&
            this.props.submitSucceeded && (
              <div className={styles.message}>
                <AlertComponent type="success">
                  <FormattedMessage id="YEARLY_LIMITS.UPDATE_SUCCESS" />
                </AlertComponent>
              </div>
            )}
        </div>
        <div className="card-body">
          <TableComponent className="table-striped">
            <thead>
              <tr>
                <FormattedMessage id="YEARLY_LIMITS.BENEFIT_OPTION" tagName="th" />
                <FormattedMessage id="YEARLY_LIMITS.VALUE_IN_EURO" tagName="th" />
              </tr>
            </thead>
            <tbody>
              {this.props.yearlyLimits.map(({ id, amount }, index) => (
                <tr key={id}>
                  <FormattedMessage id={`YEARLY_LIMITS.BENEFITS.${id}`}>
                    {txt => <td className={styles.valueLabel}>{txt}</td>}
                  </FormattedMessage>
                  <td className={styles.valueInput}>
                    <Field
                      name={`yearlyLimits[${index}].amount`}
                      component={ConnectedInputComponent}
                      icon="euro-symbol"
                      type="number"
                      min="0"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </TableComponent>
        </div>
        <div className="card-footer">
          <div className={styles.submit}>
            <ButtonComponent theme="primary" loading={this.props.submitting} size="large">
              {this.props.intl.formatMessage({ id: 'CORE.SAVE' })}
            </ButtonComponent>
          </div>
        </div>
      </form>
    );
  }
}
