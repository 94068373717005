// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { checkPermissions, currencyFormatter, dateFormatter } from 'app/utils';

import { NoResultsContainer, TableComponent } from 'app/shared';

import type { Props } from './account-transactions-table.component.types';
import styles from 'app/transactions/company/table/transactions-company-table.module.scss';

export class AccountTransactionsTableComponent extends Component<Props> {
  render() {
    return (
      <TableComponent className="table-striped">
        <thead>
          <tr>
            <FormattedMessage id="TRANSACTIONS.DATE" tagName="th" />
            <FormattedMessage id="TRANSACTIONS.PAYER_RECEIVER" tagName="th" />
            <FormattedMessage id="TRANSACTIONS.AMOUNT" tagName="th" />
          </tr>
        </thead>
        <tbody>
          {!!this.props.list.length || (
            <tr>
              <td colSpan={3}>
                <NoResultsContainer />
              </td>
            </tr>
          )}

          {this.props.list.map(transaction => (
            <tr key={transaction.id}>
              <td>
                {checkPermissions(this.props.permissions, ['transactionDetails', 'read']) ? (
                  <Link
                    to={{
                      pathname: `${this.props.pathname}/${transaction.id}`,
                      state: {
                        keepScrollPosition: true,
                      },
                    }}
                  >
                    {dateFormatter(transaction.date, true)}
                  </Link>
                ) : (
                  dateFormatter(transaction.date, true)
                )}
              </td>
              <td>{transaction.owner}</td>
              <td>
                <strong
                  className={classNames(styles.amount, {
                    [styles.income]: transaction.amount > 0,
                    [styles.expense]: transaction.amount < 0,
                  })}
                >
                  {currencyFormatter.format(transaction.amount)}
                </strong>
              </td>
            </tr>
          ))}
        </tbody>
      </TableComponent>
    );
  }
}
