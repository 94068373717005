//@flow
import React from 'react';

import type { Props } from './calendar-close-button.component.types';

import styles from './calendar-close-button.module.scss';

export const CalendarCloseButtonComponent = (props: Props) => {
  return <i {...props} className={`fi fi-error close ${styles['calendar-close-button']}`} />;
};
