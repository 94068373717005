// @flow

import React, { Component } from 'react';

import type { Props } from './buttons-wrapper.component.types';
import styles from './buttons-wrapper.module.scss';

export class ButtonsWrapperComponent extends Component<Props> {
  render() {
    return <div className={styles.wrapper}>{this.props.children}</div>;
  }
}
