// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { CardComponent } from 'app/shared';

import styles from '../smartum-pay-info.module.scss';
import phone from 'assets/images/phone.png';
import { MobileAppInfoContainer } from '../mobile-app-info/mobile-app-info.container';
import { SmartumPayHelpContainer } from '../smartum-pay-help/smartum-pay-help.container';

const instructionsArray = [
  {
    title: 'SMARTUMPAY.HOW_TO_RECEIVE.STEP_1.TITLE',
    content: 'SMARTUMPAY.HOW_TO_RECEIVE.STEP_1.DESCRIPTION',
  },
  {
    title: 'SMARTUMPAY.HOW_TO_RECEIVE.STEP_2.TITLE',
    content: 'SMARTUMPAY.HOW_TO_RECEIVE.STEP_2.DESCRIPTION',
  },
  {
    title: 'SMARTUMPAY.HOW_TO_RECEIVE.STEP_3.TITLE',
    content: 'SMARTUMPAY.HOW_TO_RECEIVE.STEP_3.DESCRIPTION',
  },
];

export class HowToReceiveComponent extends Component<{}> {
  render() {
    return (
      <div className={styles.howToPay}>
        <div className={styles.howToPayTitle}>
          <FormattedMessage id={'SMARTUMPAY.HOW_TO_RECEIVE.HEADER'} tagName="h2" />
        </div>
        <MobileAppInfoContainer />
        <CardComponent>
          <div className="card-body">
            <div className={styles.howToReceive}>
              <div className="row">
                <div className={classNames(styles.howToReceiveImage, 'col-md-4 col-12')}>
                  <img src={phone} alt="phone" />
                </div>
                <div className={classNames(styles.howToReceiveSteps, 'col-md-8 col-12')}>
                  <FormattedMessage id={'SMARTUMPAY.HOW_TO_RECEIVE.TITLE'} tagName="h5" />
                  <ol>
                    {instructionsArray.map(item => (
                      <li key={item.title}>
                        <FormattedMessage id={item.title} tagName="h6" />
                        <FormattedMessage id={item.content} tagName="p" />
                      </li>
                    ))}
                  </ol>
                </div>
              </div>
            </div>
            <SmartumPayHelpContainer />
          </div>
        </CardComponent>
      </div>
    );
  }
}
