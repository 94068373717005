// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { HeaderComponent } from 'app/core';
import { ContractListContainer } from './contract-list/contract-list.container';
import { NewContractContainer } from './new-contract/new-contract.container';
import { Route, Switch } from 'react-router-dom';

export class ContractsComponent extends Component<any> {
  render() {
    return (
      <>
        <HeaderComponent breadcrumbs={[{ icon: 'handshake', to: '/contracts' }]}>
          <FormattedMessage id="CONTRACTS.TITLE" tagName="h2" />
        </HeaderComponent>
        <Switch>
          <Route path="/contracts/new-contract" component={NewContractContainer} />
          <Route path="/contracts" component={ContractListContainer} />
        </Switch>
      </>
    );
  }
}
