import { RSAA } from 'redux-api-middleware';
import { getFormValues } from 'redux-form';

import * as types from './venues.action-types';

export const fetchVenues = () => ({
  [RSAA]: {
    types: [types.FETCH_VENUES_REQUEST, types.FETCH_VENUES_SUCCESS, types.FETCH_VENUES_FAILURE],
    endpoint: '/venues',
    method: 'GET',
  },
});

export const fetchAvailableVenues = params => (dispatch, getState) => {
  const {
    form,
    users: { business },
  } = getState();
  const filters = getFormValues('new-contract-filters')({ form }) || {};

  let actionTypes = [
    {
      type: types.FETCH_AVAILABLE_VENUES_REQUEST,
      meta: {
        business,
        params: {
          ...filters,
          ...params,
        },
      },
    },
    types.FETCH_AVAILABLE_VENUES_SUCCESS,
    types.FETCH_AVAILABLE_VENUES_FAILURE,
  ];

  if (params.size === 1500) {
    actionTypes = [
      {
        type: types.FETCH_ALL_AVAILABLE_VENUES_REQUEST,
        meta: {
          business,
          params: {
            ...filters,
            ...params,
          },
        },
      },
      types.FETCH_ALL_AVAILABLE_VENUES_SUCCESS,
      types.FETCH_ALL_AVAILABLE_VENUES_FAILURE,
    ];
  }

  return dispatch({
    [RSAA]: {
      types: actionTypes,
      endpoint: '/venues/public/search',
      method: 'GET',
    },
  });
};

export const fetchVenue = id => ({
  [RSAA]: {
    types: [
      {
        type: types.FETCH_VENUE_REQUEST,
        meta: {
          venue: id,
        },
      },
      types.FETCH_VENUE_SUCCESS,
      types.FETCH_VENUE_FAILURE,
    ],
    endpoint: `/venues/${id}`,
    method: 'GET',
  },
});

export const addVenue = body => ({
  [RSAA]: {
    types: [types.ADD_VENUE_REQUEST, types.ADD_VENUE_SUCCESS, types.ADD_VENUE_FAILURE],
    endpoint: '/venues',
    method: 'POST',
    body,
  },
});

export const editVenue = (type, venue, body) => ({
  [RSAA]: {
    types: [
      types.EDIT_VENUE_REQUEST,
      {
        type: types.EDIT_VENUE_SUCCESS,
        meta: {
          venue: body,
          id: venue,
        },
      },
      types.EDIT_VENUE_FAILURE,
    ],
    endpoint: `/venues/${type}/${venue}`,
    method: 'PATCH',
    body,
  },
});

export const fetchVenueContracts = params => (dispatch, getState) => {
  const {
    users: { business },
  } = getState();

  let actionTypes = [
    {
      type: types.FETCH_VENUE_CONTRACTS_REQUEST,
      meta: {
        params: {
          ...params,
          sortBy: 'customerName',
          order: 'asc',
        },
      },
    },
    types.FETCH_VENUE_CONTRACTS_SUCCESS,
    types.FETCH_VENUE_CONTRACTS_FAILURE,
  ];

  if (!!params.benefitType) {
    actionTypes = [
      {
        type: types.FETCH_VENUE_MASSAGE_CONTRACTS_REQUEST,
        meta: {
          params: {
            ...params,
            sortBy: 'customerName',
            order: 'asc',
          },
        },
      },
      types.FETCH_VENUE_MASSAGE_CONTRACTS_SUCCESS,
      types.FETCH_VENUE_MASSAGE_CONTRACTS_FAILURE,
    ];
  }

  return dispatch({
    [RSAA]: {
      types: actionTypes,
      endpoint: `/venues/${business}/contracts`,
      method: 'GET',
    },
  });
};

export const clearVenues = () => ({
  type: types.CLEAR_VENUES,
});
export const clearAllVenues = () => ({
  type: types.CLEAR_ALL_VENUES,
});
