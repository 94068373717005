// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { PlaceholderComponent, TileComponent } from 'app/shared';

export class SubscriptionsListTilePlaceholder extends Component<any> {
  render() {
    return (
      <TileComponent>
        <h3>
          <PlaceholderComponent width={60} height={27} />
        </h3>

        <div className="row">
          <div className="col">
            <FormattedMessage id="SUBSCRIPTION.LIST_HEADERS.CLOSING_TIME" tagName="small" />
            <PlaceholderComponent width={100} height={18} />
          </div>

          <div className="col">
            <FormattedMessage id="SUBSCRIPTION.LIST_HEADERS.APPROVED_SUBSCRIBED" tagName="small" />
            <PlaceholderComponent width={80} height={15} />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <FormattedMessage id="SUBSCRIPTION.LIST_HEADERS.TOTAL" tagName="small" />
            <PlaceholderComponent width={150} height={18} />
          </div>
        </div>

        <div className="buttons">
          <PlaceholderComponent width={60} height={38} color="purple" />
        </div>
      </TileComponent>
    );
  }
}
