// @flow

import React, { Component } from 'react';

import { ListComponent } from 'app/shared';

import { ReportsListTablePlaceholder } from './table/reports-list-table.placeholder';
import { ReportsListTilePlaceholder } from './tile/reports-list-tile.placeholder';

type Props = {
  reports: null[],
};

export class ReportsListPlaceholder extends Component<Props> {
  static defaultProps = {
    reports: [...new Array(5)],
  };

  render() {
    return (
      <ListComponent list={this.props.reports} table={ReportsListTablePlaceholder} tile={ReportsListTilePlaceholder} />
    );
  }
}
