import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { AgreementsListComponent } from './agreements-list.component';

const mapStateToProps = ({
  users: {
    current: { terms },
  },
}) => ({
  terms,
});

export const AgreementsListContainer = compose(
  injectIntl,
  connect(mapStateToProps),
)(AgreementsListComponent);
