// @flow

import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import { FormattedMessage } from 'react-intl';

import { InfoComponent } from 'app/shared';
import { TitleComponent } from 'app/auth/title/title.component';
import { BackComponent } from 'app/auth/back/back.component';

import type { Props, State } from './login-token.component.types';

export class LoginTokenComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loginFailed: false,
    };
  }

  componentDidMount() {
    this.props
      .loginWithToken(this.props.match.params.token)
      .then(action => action.error && this.setState({ loginFailed: true }));
  }

  render() {
    return (
      <section>
        <MediaQuery maxWidth={991}>
          <BackComponent to="/login">
            <MediaQuery maxWidth={767}>
              <FormattedMessage id="AUTH.BACK_TO_LOGIN_SHORT" />
            </MediaQuery>
            <MediaQuery minWidth={768}>
              <FormattedMessage id="AUTH.BACK_TO_LOGIN" />
            </MediaQuery>
          </BackComponent>
        </MediaQuery>
        <TitleComponent withBackOption>
          <FormattedMessage id="AUTH.LOGIN_WITHOUT_PASSWORD" />
        </TitleComponent>

        {this.state.loginFailed || (
          <InfoComponent type="processing">
            <FormattedMessage id="AUTH.LOGIN_PROCESSING" />
          </InfoComponent>
        )}

        {this.state.loginFailed && (
          <div>
            <InfoComponent type="error">
              <FormattedMessage id="AUTH.LOGIN_WITHOUT_PASSWORD_ERROR" />
            </InfoComponent>
            <MediaQuery minWidth={992}>
              <BackComponent to="/login">
                <FormattedMessage id="AUTH.BACK_TO_LOGIN" />
              </BackComponent>
            </MediaQuery>
          </div>
        )}
      </section>
    );
  }
}
