import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

export const API_URL =
  {
    'next.extranet.smartum.fi': 'https://extranet-api.smartum.fi/api',
    'extranet.smartum.fi': 'https://extranet-api.smartum.fi/api',
    'extranet.staging.smartum.fi': 'https://extranet-api.staging.smartum.fi/api',
  }[window.location.host] || process.env.REACT_APP_API_URL;

export const PUBLIC_API_URL =
  {
    'next.extranet.smartum.fi': 'https://api.smartum.fi',
    'extranet.smartum.fi': 'https://api.smartum.fi',
    'extranet.staging.smartum.fi': 'https://api.staging.smartum.fi',
  }[window.location.host] || 'https://api.staging.smartum.fi';

export const SIZE_SELECT_OPTIONS = [
  { id: 10, name: <FormattedMessage id="CORE.PER_PAGE" values={{ number: 10 }} /> },
  { id: 20, name: <FormattedMessage id="CORE.PER_PAGE" values={{ number: 20 }} /> },
  { id: 30, name: <FormattedMessage id="CORE.PER_PAGE" values={{ number: 30 }} /> },
];

export const USER_ROLES = {
  ROLE_COMPANY_BUYER: 'ROLE_COMPANY_BUYER',
  ROLE_SERVICE_PROVIDER_MANAGER: 'ROLE_SERVICE_PROVIDER_MANAGER',
  ROLE_COMPANY_BENEFICIARY: 'ROLE_COMPANY_BENEFICIARY',
  ROLE_VENUE_CASHIER: 'ROLE_VENUE_CASHIER',
  ROLE_VENUE_MANAGER: 'ROLE_VENUE_MANAGER',
  ROLE_VENUE_ACCOUNTANT: 'ROLE_VENUE_ACCOUNTANT',
};

export const firstDayOfCurrentYear = moment()
  .startOf('year')
  .format('YYYY-MM-DD');

export const lastDayOfCurrentYear = moment()
  .endOf('year')
  .format('YYYY-MM-DD');
