// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import { ButtonComponent, ModalComponent, ConnectedRadiosComponent } from 'app/shared/index';

import type { Props } from './language-switcher.component.types';

export class LanguageSwitcherComponent extends Component<Props> {
  onSubmit = ({ language }: { language: 'en' | 'fi' | 'sv' }) => {
    this.props.onClose();
    this.props.updateLocale(language);
  };

  render() {
    const options = [
      {
        id: 'fi',
        name: <FormattedMessage id="CORE.FINNISH" />,
      },
      {
        id: 'sv',
        name: <FormattedMessage id="CORE.SWEDISH" />,
      },
      {
        id: 'en',
        name: <FormattedMessage id="CORE.ENGLISH" />,
      },
    ];

    return (
      <>
        <ModalComponent
          title={this.props.intl.formatMessage({ id: 'SIDEBAR.CHANGE_LANGUAGE' })}
          subtitle={this.props.intl.formatMessage({ id: 'SIDEBAR.CHANGE_LANGUAGE_DESC' })}
          onClose={this.props.onClose}
          form={{ onSubmit: this.props.handleSubmit(this.onSubmit) }}
          submitButton={
            <ButtonComponent theme="primary" size="medium">
              {this.props.intl.formatMessage({ id: 'CORE.SAVE' })}
            </ButtonComponent>
          }
        >
          <Field
            name="language"
            component={ConnectedRadiosComponent}
            label={this.props.intl.formatMessage({ id: 'CORE.LANGUAGE' })}
            options={options}
            theme="solid"
          />
        </ModalComponent>
      </>
    );
  }
}
