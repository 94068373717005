// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { HeaderComponent, SplashComponent, ThemeComponent } from 'app/core';
import { CardComponent, AlertComponent } from 'app/shared';

import { SubscriptionSubscribeFormComponent } from './form/subscription-subscribe-form.component';
import { SubscriptionSubscribeSuccessComponent } from './success/subscription-subscribe-success.component';

import { createExceededError } from './subscription-subscribe.util';

import type { BodyProps, Props, State } from './subscription-subscribe.component.types';
import styles from './subscription-subscribe.module.scss';

export class SubscriptionSubscribeComponent extends Component<Props, State> {
  state = { error: null };

  componentDidMount() {
    this.props.fetchSubscription();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isAuthenticated && !this.props.isAuthenticated) {
      this.props.fetchSubscription();
    }
  }

  onSubmit = (body: BodyProps) => {
    if (this.props.isAuthenticated) {
      return this.onSubmitAuthenticated(body);
    } else {
      return this.onSubmitUnauthenticated(body);
    }
  };

  onSubmitAuthenticated = (body: BodyProps) => {
    return this.props.onSubmitAuthenticated(body).catch(error => {
      const { errors } = error;
      const exceededError = createExceededError(errors, this.props.intl);

      this.setState({ error: exceededError });

      throw error;
    });
  };

  onSubmitUnauthenticated = (body: BodyProps) => {
    return this.props.onSubmitUnauthenticated(body).catch(error => {
      const { errors } = error;
      const exceededError = createExceededError(errors, this.props.intl);

      this.setState({ error: exceededError });

      throw error;
    });
  };

  render() {
    if (this.props.loading) {
      return <SplashComponent />;
    }

    if (!this.props.subscription.hash) {
      return (
        <ThemeComponent className={styles.main} sidebarless>
          <HeaderComponent
            breadcrumbs={[
              {
                icon: 'home-icon-silhouette',
              },
              {
                name: <FormattedMessage id="SUBSCRIPTION.SUBSCRIBE.BREADCRUMB" />,
              },
            ]}
          >
            <FormattedMessage id="SUBSCRIPTION.SUBSCRIBE.TITLE" tagName="h2" />
          </HeaderComponent>
          <AlertComponent type="danger" className="text-left">
            <FormattedMessage id="SUBSCRIPTION.SUBSCRIBE.NOT_EXIST" tagName="p" />
          </AlertComponent>
        </ThemeComponent>
      );
    }

    return (
      <ThemeComponent className={styles.main} sidebarless>
        <HeaderComponent
          breadcrumbs={[
            {
              icon: 'home-icon-silhouette',
            },
            {
              name: <FormattedMessage id="SUBSCRIPTION.SUBSCRIBE.BREADCRUMB" />,
            },
          ]}
        >
          <FormattedMessage id="SUBSCRIPTION.SUBSCRIBE.TITLE" tagName="h2" />
          <p>
            {this.props.subscription.company.name} - {this.props.subscription.title}
          </p>
        </HeaderComponent>
        {this.props.subscription.editable && (
          <CardComponent>
            {this.props.submitSucceeded && <SubscriptionSubscribeSuccessComponent />}

            {this.props.submitSucceeded || (
              <SubscriptionSubscribeFormComponent
                error={this.state.error}
                handleSubmit={this.props.handleSubmit(this.onSubmit)}
                submitting={this.props.submitting}
                type={this.props.subscription.type}
                initialValues={this.props.initialValues}
                description={this.props.subscription.description}
                isAuthenticated={this.props.isAuthenticated}
                hasAccount={this.props.hasAccount}
              />
            )}
          </CardComponent>
        )}

        {!this.props.subscription.editable && (
          <AlertComponent type="danger" className="text-left">
            <FormattedMessage id="SUBSCRIPTION.SUBSCRIBE.EXPIRED" tagName="p" />
          </AlertComponent>
        )}
      </ThemeComponent>
    );
  }
}
