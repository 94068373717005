// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { HeaderComponent } from 'app/core';
import { AlertComponent, CardComponent } from 'app/shared';
import { isBusinessDifferent } from 'app/utils';

import { CustomerInformationFormContainer } from './form/customer-information-form.container';
import { CustomerInformationPlaceholder } from './placeholder/customer-information.placeholder';
import styles from './customer-information.module.scss';

import type { Props, State } from './customer-information.component.types';

export class CustomerInformationComponent extends Component<Props, State> {
  state = {
    terminationMessage: null,
  };

  componentDidMount() {
    this.props.fetchCustomer();
    this.props.fetchServiceProviderDictionary();
  }

  componentDidUpdate(prevProps: Props) {
    if (isBusinessDifferent(prevProps, this.props)) {
      this.props.fetchCustomer();
    }
  }

  onSuccessTermination = () => {
    window.scrollTo(0, 0);
    this.setState({
      terminationMessage: {
        type: 'SUCCESS',
        text: this.props.intl.formatMessage({ id: 'VENUES.CONTRACT_TERMINATION_SUCCESS' }),
      },
    });
  };

  render() {
    return (
      <>
        <HeaderComponent breadcrumbs={[{ icon: 'pencil-edit-button' }]}>
          <FormattedMessage id="NAV.GENERAL.CUSTOMER_INFORMATION" tagName="h2" />
          {this.props.customer &&
            this.props.customer.contractTerminationDate && (
              <p className={styles.terminationCotractText}>
                <FormattedMessage
                  id="CUSTOMERS.CONTRACT_TERMINATION_STATUS_TEXT"
                  values={{
                    date: this.props.customer.contractTerminated
                      ? this.props.customer.contractTerminated
                      : this.props.customer.contractTerminationDate,
                  }}
                />
                <span className={styles.terminationCotractStatus}>
                  {this.props.customer.contractTerminated
                    ? this.props.intl.formatMessage({ id: 'CUSTOMERS.CONTRACT_TERMINATION_PROCESSED' })
                    : this.props.intl.formatMessage({ id: 'CUSTOMERS.CONTRACT_TERMINATION_IN_PROGRESS' })}
                </span>
              </p>
            )}
        </HeaderComponent>

        {this.state.terminationMessage && (
          <AlertComponent type={this.state.terminationMessage.type === 'SUCCESS' ? 'success' : 'danger'}>
            {this.state.terminationMessage.text}
          </AlertComponent>
        )}

        <CardComponent>
          {this.props.loading && (
            <CustomerInformationPlaceholder intl={this.props.intl} sidebarCollapsed={this.props.sidebarCollapsed} />
          )}
          {this.props.loading || (
            <CustomerInformationFormContainer
              onSuccessTermination={this.onSuccessTermination}
              customer={this.props.customer}
              fetchCustomer={this.props.fetchCustomer}
            />
          )}
        </CardComponent>
      </>
    );
  }
}
