// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import MediaQuery from 'react-responsive';

import { ButtonComponent, NoResultsContainer } from 'app/shared/index';

import type { Props, State } from './list.component.types';
import styles from './list.module.scss';

export class ListComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isCollapsed: true,
    };
  }

  render() {
    const { table: Table, tile: Tile } = this.props;

    if (this.props.collapsible && this.state.isCollapsed) {
      return (
        <div className={styles.collapsed}>
          <FormattedMessage id="CORE.COLLAPSE.RECORDS" values={{ records: this.props.list.length }} />
          <ButtonComponent
            size="small"
            theme="secondary"
            className="ml-3"
            onClick={() => this.setState({ isCollapsed: false })}
          >
            <FormattedMessage id="CORE.COLLAPSE.DISPLAY_ALL" />
          </ButtonComponent>
        </div>
      );
    }

    return (
      <>
        {this.props.collapsible &&
          !this.state.isCollapsed && (
            <ButtonComponent
              size="small"
              theme="secondary"
              className={styles.hide}
              onClick={() => this.setState({ isCollapsed: true })}
            >
              <FormattedMessage id="CORE.COLLAPSE.HIDE_ALL" />
            </ButtonComponent>
          )}
        <MediaQuery minWidth={1200}>
          {matches =>
            matches ? (
              <Table list={this.props.list} {...this.props.props} />
            ) : this.props.list.length ? (
              <div className={styles.tiles}>
                {this.props.list.map((tile, index) => (
                  <div className={styles.tile} key={index}>
                    <Tile tile={tile} {...this.props.props} />
                  </div>
                ))}
              </div>
            ) : (
              <NoResultsContainer searchable={this.props.searchable} />
            )
          }
        </MediaQuery>
      </>
    );
  }
}
