import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { find } from 'lodash';

import { downloadToken, downloadMetabasePDF } from 'app/redux/downloads/downloads.actions';
import { fetchMetabaseData } from 'app/redux/metabase-download-pdf/metabase-download-pdf.actions';
import { firstDayOfCurrentYear, lastDayOfCurrentYear } from 'app/constants';

import { MetabaseDownloadPDFComponent } from './metabase-download-pdf.component';

const mapStateToProps = ({ users: { business, businessType, defaultRoles }, metabaseData }) => ({
  business,
  businessType,
  role: find(defaultRoles, ['businessId', business]).defaultRole,
  ...metabaseData,
});

const mapDispatchToProps = (dispatch, { intl: { locale } }) => ({
  fetchMetabaseData: business => dispatch(fetchMetabaseData(business)),
  onDownloadToken: () => dispatch(downloadToken()),
  onDownloadMetabasePdf: (businessData, token, { startDate, endDate, productCode, settlementId, paymentRef, venue }) =>
    dispatch(
      downloadMetabasePDF(businessData, {
        type: businessData.role,
        token,
        locale,
        date_range: `${startDate}~${endDate}`,
        product_code: productCode,
        settlement_id: settlementId,
        payment_ref: paymentRef,
        venue,
      }),
    ),
});

const validate = (values, props) => {
  const errors = {};
  if (!values.startDate || !values.endDate) {
    errors.startDate = props.intl.formatMessage({ id: 'VALIDATION.NOT_BLANK' });
  }
  return errors;
};

export const MetabaseDownloadPDFContainer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'metabase-pdf',
    validate,
    initialValues: {
      startDate: firstDayOfCurrentYear,
      endDate: lastDayOfCurrentYear,
    },
  }),
)(MetabaseDownloadPDFComponent);
