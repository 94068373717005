import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { SubscriptionSubscribeFormBenefitsComponent } from './subscription-subscribe-form-benefits.component';

const mapStateToProps = (
  { subscriptions: { entities: subscriptions } },
  {
    match: {
      params: { hash },
    },
  },
) => ({
  company: subscriptions[hash].company.name,
  distributionDay: subscriptions[hash].subscription.distributionDay,
  multipliers: subscriptions[hash].multipliers,
  options: subscriptions[hash].benefitOptions,
  type: subscriptions[hash].type,
});

export const SubscriptionSubscribeFormBenefitsContainer = compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps),
)(SubscriptionSubscribeFormBenefitsComponent);
