// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { HeaderComponent } from 'app/core';
import { CardComponent, PlaceholderComponent, SummaryFieldComponent, TableComponent } from 'app/shared';

export class AccountDetailsPlaceholder extends Component<any> {
  render() {
    return (
      <>
        <HeaderComponent breadcrumbs={[{ icon: 'account-balance', to: '/accounts' }]}>
          <FormattedMessage id="ACCOUNT_INFORMATION.TITLE" tagName="h2" />
        </HeaderComponent>

        <CardComponent>
          <div className="card-header">
            <h4 className="card-title">
              <FormattedMessage id="ACCOUNT_INFORMATION.BALANCE" />
            </h4>
          </div>
          <div className="card-body">
            <SummaryFieldComponent>
              <PlaceholderComponent width={160} height={18} />
              <div>
                <PlaceholderComponent width={80} height={19} />
              </div>
            </SummaryFieldComponent>

            <TableComponent className="table-striped">
              <thead>
                <tr>
                  <FormattedMessage id="ACCOUNTS.VALID" tagName="th" />
                  <FormattedMessage id="ACCOUNTS.BALANCE" tagName="th" />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <PlaceholderComponent width={80} /> - <PlaceholderComponent width={80} />
                  </td>
                  <td>
                    <PlaceholderComponent width={120} />
                  </td>
                </tr>
              </tbody>
            </TableComponent>
          </div>
        </CardComponent>
      </>
    );
  }
}
