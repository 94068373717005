// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { PlaceholderComponent, TableComponent } from 'app/shared';

import type { Props } from './payments-token-accounts.placeholder.types';

export class PaymentsTokenAccountsPlaceholder extends Component<Props> {
  static defaultProps = {
    accounts: [...new Array(5)],
  };

  render() {
    return (
      <TableComponent className="table-striped">
        <thead>
          <tr>
            <FormattedMessage id="MOBILE_PAYMENT_TOKEN.BENEFIT_TYPE" tagName="th" />
            <th />
          </tr>
        </thead>
        <tbody>
          {this.props.accounts.map((_, account) => (
            <tr key={account} className={this.props.styles.row}>
              <td>
                <PlaceholderComponent width={account % 2 === 0 ? 100 : 150} /> <br />
                <PlaceholderComponent width={account % 2 === 0 ? 50 : 75} />
              </td>
              <td className={this.props.styles.icons}>
                <PlaceholderComponent width={100} color="purple" />
              </td>
            </tr>
          ))}
        </tbody>
      </TableComponent>
    );
  }
}
