// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { NoResultsContainer, TableComponent } from 'app/shared';

import type { Props } from './beneficiaries-list-table.component.types';

export class BeneficiariesListTableComponent extends Component<Props> {
  render() {
    return (
      <TableComponent className="table-striped">
        <thead>
          <tr>
            <FormattedMessage id="BENEFICIARIES.LIST_HEADERS.NAME" tagName="th" />
            <FormattedMessage id="BENEFICIARIES.LIST_HEADERS.EMAIL" tagName="th" />
            <FormattedMessage id="BENEFICIARIES.LIST_HEADERS.SSN" tagName="th" />
          </tr>
        </thead>
        <tbody>
          {!!this.props.list.length || (
            <tr>
              <td colSpan="3">
                <NoResultsContainer />
              </td>
            </tr>
          )}
          {this.props.list.map(beneficiary => (
            <tr key={beneficiary.id}>
              <td>
                <Link to={`/beneficiaries/${beneficiary.id}`}>{beneficiary.name}</Link>
              </td>
              <td>{beneficiary.email}</td>
              <td>{beneficiary.ssn}</td>
            </tr>
          ))}
        </tbody>
      </TableComponent>
    );
  }
}
