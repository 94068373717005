// @flow

import React, { Component } from 'react';
import Textarea from 'react-textarea-autosize';
import classNames from 'classnames';

import uniqueId from 'lodash/uniqueId';

import { ConnectedGroupComponent, TooltipComponent } from 'app/shared';

import type { Props } from './connected-textarea.component.types';
import styles from './connected-textarea.module.scss';
import { textareaFormatChecker } from '../../../utils/textarea-format-checker/textarea-format-checker';

export class ConnectedTextareaComponent extends Component<Props> {
  render() {
    const id = uniqueId('textarea_');

    const textareaClassNames = classNames('form-control', styles.textarea, {
      'is-invalid': this.props.meta.touched && !!this.props.meta.error,
    });

    const counterClassNames = classNames(styles.counter, {
      [styles.counter__limit]: this.props.input.value && this.props.input.value.length === this.props.maxLength,
    });

    return (
      <ConnectedGroupComponent id={id} {...this.props}>
        {!!this.props.addon && !this.props.meta.active && (
          <span className={styles.addon}>
            <TooltipComponent overlay={this.props.addon} placement="top">
              <span className={styles.info} />
            </TooltipComponent>
          </span>
        )}

        {this.props.showCounter && (
          <div className={counterClassNames}>
            {this.props.input.value.length}/{this.props.maxLength}
          </div>
        )}
        <Textarea
          id={id}
          {...this.props.input}
          minRows={4}
          maxLength={this.props.maxLength || 500}
          className={textareaClassNames}
          value={textareaFormatChecker(this.props.input.value)}
        />
      </ConnectedGroupComponent>
    );
  }
}
