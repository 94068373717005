// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { CardsListPlaceholder } from './placeholder/cards-list.placeholder';
import { CardsListTableComponent } from './table/cards-list-table.component';
import { CardsListTileComponent } from './tile/cards-list-tile.component';

import { HeaderComponent } from 'app//core';
import { ListComponent } from 'app/shared/list/list.component';
import { CardComponent } from 'app/shared';

import type { Props } from './cards-list.component.types';

export class CardsListComponent extends Component<Props> {
  componentDidMount() {
    this.props.fetchCards();
  }

  render() {
    return (
      <>
        <HeaderComponent breadcrumbs={[{ icon: 'credit-card' }]}>
          <FormattedMessage id="SIDEBAR.CARDS" tagName="h2" />
        </HeaderComponent>

        <CardComponent>
          <div className="card-body">
            {this.props.loading && <CardsListPlaceholder />}
            {this.props.loading || (
              <ListComponent
                list={this.props.cards}
                table={CardsListTableComponent}
                tile={CardsListTileComponent}
                searchable={false}
                props={{
                  onActivate: this.props.onActivate,
                  onDisable: this.props.onDisable,
                }}
              />
            )}
          </div>
        </CardComponent>
      </>
    );
  }
}
