import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import { withCoreContext } from 'app/core/core.context';

import { OrdersCreatePlaceholder } from './orders-create.placeholder';

export const OrdersCreatePlaceholderContainer = compose(
  injectIntl,
  withCoreContext,
)(OrdersCreatePlaceholder);
