import { RSAA } from 'redux-api-middleware';

import * as types from './permissions.action-types';

export const fetchPermissions = () => ({
  [RSAA]: {
    types: [types.FETCH_PERMISSIONS_REQUEST, types.FETCH_PERMISSIONS_SUCCESS, types.FETCH_PERMISSIONS_FAILURE],
    endpoint: '/users/me/permissions',
    method: 'GET',
  },
});
