// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { currencyFormatter, dateFormatter } from 'app/utils';

import { ButtonComponent, TileComponent } from 'app/shared';

import type { Props } from './accounts-list-tile.component.types';

export class AccountsListTileComponent extends Component<Props> {
  render() {
    return (
      <TileComponent>
        <FormattedMessage id={`PAYMENT.ITEM.${this.props.tile.item}`} tagName="h3" />

        <div className="row">
          <div className="col">
            <FormattedMessage id="ACCOUNTS.BALANCE" tagName="small" />
            {currencyFormatter.format(this.props.tile.balance)}
          </div>
        </div>

        {this.props.tile.transfers.map((transfer, index) => (
          <div className="row" key={index}>
            <div className="col">
              <FormattedMessage id="ACCOUNTS.VALID" tagName="small" />
              <span className="small">
                {dateFormatter(transfer.from)} - {dateFormatter(transfer.to)}
              </span>
            </div>

            <div className="col">
              <FormattedMessage id="ACCOUNTS.BALANCE" tagName="small" />
              {currencyFormatter.format(transfer.balance)}
            </div>
          </div>
        ))}

        <div className="buttons">
          <ButtonComponent
            size="small"
            theme="secondary"
            to={`/accounts/${this.props.tile.accountNumber}/transactions`}
          >
            <FormattedMessage id="ACCOUNTS.TRANSACTIONS" />
          </ButtonComponent>
        </div>
      </TileComponent>
    );
  }
}
