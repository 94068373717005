// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import { HeaderComponent } from 'app/core';
import { AlertComponent, ButtonComponent, CardComponent, ConnectedInputComponent } from 'app/shared';

import type { BodyProps, Props, State } from './vouchers-check-owner.component.types';

export class VouchersCheckOwnerComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  onSubmit = (body: BodyProps) =>
    this.props.onSubmit(body).then(({ payload = { data: {} } }) => this.setState({ owner: payload.data.owner }));

  render() {
    return (
      <>
        <HeaderComponent
          breadcrumbs={[
            {
              icon: 'discount-voucher',
            },
            {
              name: <FormattedMessage id="SIDEBAR.CHECK_VOUCHER_OWNER" />,
            },
          ]}
        >
          <FormattedMessage id="SIDEBAR.CHECK_VOUCHER_OWNER" tagName="h2" />
        </HeaderComponent>

        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <CardComponent>
            <div className="card-header">
              <h4 className="card-title">
                <FormattedMessage id="SIDEBAR.CHECK_VOUCHER_OWNER" />
              </h4>
            </div>
            <div className="card-body">
              <AlertComponent type={this.props.submitSucceeded ? 'success' : 'light'}>
                {this.props.submitSucceeded &&
                  this.state.owner && (
                    <FormattedMessage
                      id="CHECK_VOUCHER_OWNER.OWNER_SUCCESS"
                      values={{ name: <strong>{this.state.owner}</strong> }}
                    />
                  )}

                {this.props.submitSucceeded &&
                  !this.state.owner && <FormattedMessage id="CHECK_VOUCHER_OWNER.NO_OWNER_SUCCESS" />}

                {this.props.submitSucceeded || <FormattedMessage id="CHECK_VOUCHER_OWNER.DESC" />}
              </AlertComponent>

              <Field
                name="voucher"
                component={ConnectedInputComponent}
                label={this.props.intl.formatMessage({ id: 'CHECK_VOUCHER_OWNER.SERIAL_NUMBER' })}
                placeholder={this.props.intl.formatMessage({ id: 'CHECK_VOUCHER_OWNER.SERIAL_NUMBER' })}
                required
              />
            </div>
            <div className="card-footer text-right">
              <ButtonComponent theme="primary" size="large" loading={this.props.submitting}>
                <FormattedMessage id="CORE.PROCEED" />
              </ButtonComponent>
            </div>
          </CardComponent>
        </form>
      </>
    );
  }
}
