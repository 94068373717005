import { compose } from 'redux';
import { connect } from 'react-redux';

import { withAuthorization, withPagination } from 'app/common';

import { fetchOrders } from 'app/redux/orders/orders.actions';

import { OrdersListComponent } from './orders-list.component';

const mapStateToProps = ({ orders: { list: orders, meta }, users: { business } }) => ({
  business,
  orders,
  loading: !orders,
  meta,
});

const mapDispatchToProps = dispatch => ({
  fetch: params => dispatch(fetchOrders(params)),
});

export const OrdersListContainer = compose(
  withAuthorization(['companyOrders', 'read']),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withPagination,
)(OrdersListComponent);
