// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { PieChartComponent, ButtonComponent, TableComponent } from 'app/shared';
import { currencyFormatter } from 'app/utils';

import type { Props } from './printable-report.component.types';

import styles from './printable-report.module.scss';

export class PrintableReportComponent extends Component<Props> {
  render() {
    const printableReport = JSON.parse(sessionStorage.getItem('printableReport') || '{}');
    const total = data => data.reduce((res, { value }) => Number(res + Number(value)), 0);
    const totalBenefits = total(printableReport.report.benefits);
    const totalVenues = total(printableReport.report.venues);
    const { intl } = this.props;

    return (
      <div className={styles.printArea}>
        <ButtonComponent className={styles.printButton} theme="primary" onClick={window.print}>
          <i className="fi fi-print" />
          <FormattedMessage id="CORE.PRINT_REPORT" />
        </ButtonComponent>

        {!!printableReport.report.benefits.length && !!printableReport.report.venues.length ? (
          <>
            <FormattedMessage id="SALDO_REPORTS.REPORT_DETAILS" tagName="h2" />
            <FormattedMessage
              id="SALDO_REPORTS.REPORT_DATES"
              tagName="p"
              values={{
                startDate: printableReport.startDate,
                endDate: printableReport.endDate,
              }}
            />

            <PieChartComponent
              title={intl.formatMessage(
                { id: 'SALDO_REPORTS.BENEFITS_CHART_TITLE' },
                { total: currencyFormatter.format(totalBenefits) },
              )}
              total={totalBenefits}
              data={printableReport.report.benefits}
              parseItem={({ label, value }) => [intl.formatMessage({ id: `PAYMENT.ITEM.${label}` }), Number(value)]}
              legendPosition="top"
            />
            <TableComponent className="table-striped">
              <tbody className={styles.dataTable}>
                {printableReport.report.benefits.map(benefit => (
                  <tr key={benefit.label}>
                    <td>
                      <FormattedMessage id={`PAYMENT.ITEM.${benefit.label}`} />
                    </td>
                    <td>{benefit.value.toFixed(2)}</td>
                    <td>{((benefit.value / totalBenefits) * 100).toFixed(2)}%</td>
                  </tr>
                ))}
              </tbody>
            </TableComponent>
            <hr />
            <PieChartComponent
              title={intl.formatMessage({
                id: 'SALDO_REPORTS.VENUES_CHART_TITLE',
              })}
              total={totalVenues}
              data={printableReport.report.venues}
              legendPosition="top"
            />
            <TableComponent className="table-striped">
              <tbody className={styles.dataTable}>
                {printableReport.report.venues.map(venue => (
                  <tr key={venue.label}>
                    <td>{venue.label}</td>
                    <td>{venue.value.toFixed(2)}</td>
                    <td>{((venue.value / totalVenues) * 100).toFixed(2)}%</td>
                  </tr>
                ))}
              </tbody>
            </TableComponent>
          </>
        ) : null}
      </div>
    );
  }
}
