// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { HeaderComponent } from 'app/core';

import { PaymentsTokenAccountsContainer } from './accounts/payments-token-accounts.container';
import { PaymentsTokenCodeContainer } from './code/payments-token-code.container';

import type { Account, Props, State } from './payments-token.component.types';

export class PaymentsTokenComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  onAccountSelect = (selected?: Account) => this.setState({ selected });

  render() {
    return (
      <div>
        <HeaderComponent breadcrumbs={[{ icon: 'qr-code' }]}>
          <FormattedMessage id="SIDEBAR.MOBILE_PAYMENT_TOKEN" tagName="h2" />
        </HeaderComponent>

        {!!this.state.selected || <PaymentsTokenAccountsContainer onAccountSelect={this.onAccountSelect} />}
        {!!this.state.selected && (
          <PaymentsTokenCodeContainer onBack={() => this.onAccountSelect()} selected={this.state.selected} />
        )}
      </div>
    );
  }
}
