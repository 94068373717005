import { compose } from 'redux';
import { connect } from 'react-redux';

import { withAuthorization } from 'app/common';

import { fetchAccounts } from 'app/redux/accounts/accounts.actions';

import { AccountsListComponent } from './accounts-list.component';

const mapStateToProps = ({
  permissions,
  users: { business, role },
  accounts: {
    company: { list: accounts },
  },
}) => ({
  accounts,
  loading: !accounts,
  permissions: permissions[business][role],
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { type },
    },
  },
) => ({
  fetchAccounts: () => dispatch(fetchAccounts(type)),
});

export const AccountsListContainer = compose(
  withAuthorization([props => `${props.match.params.type.toLowerCase()}Accounts`, 'read']),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(AccountsListComponent);
