// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { AgreementsAcceptanceContainer } from 'app/agreements/acceptance/agreements-acceptance.container';
import {
  ButtonComponent,
  ButtonsWrapperComponent,
  CardComponent,
  SummaryFieldComponent,
  TableComponent,
} from 'app/shared';
import { currencyFormatter } from 'app/utils';

import type { Props, State } from './orders-create-summary.component.types';
import styles from './orders-create-summary.module.scss';

export class OrdersCreateSummaryComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      terms: {
        onChange: () => this.setState(prev => ({ terms: { ...prev.terms, value: !prev.terms.value } })),
        value: false,
      },
    };
  }

  render() {
    const formattedProducts = this.props.values.products
      ? this.props.values.products.map(({ id, amount }) => ({ id, amount: amount.replace(',', '.') }))
      : false;

    const amount = formattedProducts
      ? formattedProducts.reduce((prev, next) => prev + Number(next.amount), 0)
      : this.props.values.amount && this.props.values.amount.replace(',', '.');

    return (
      <CardComponent>
        <div className="card-header">
          <h4 className="card-title">
            <FormattedMessage id="ORDERS.ORDER_DETAILS" />
          </h4>
        </div>
        <div className="card-body">
          {!!formattedProducts && (
            <TableComponent className="table-striped">
              <thead>
                <tr className={styles.hederRow}>
                  <FormattedMessage id="ORDERS.PRODUCT" tagName="th" />
                  <FormattedMessage id="ORDERS.QUANTITY" tagName="th" />
                  <FormattedMessage id="ORDERS.AMOUNT" tagName="th" />
                </tr>
              </thead>
              <tbody>
                {this.props.order.invoiceProducts.map(product => [
                  <tr key={product.id}>
                    <td>
                      <FormattedMessage id={`PAYMENT.ITEM.${product.id}`} />
                    </td>
                    <td className={styles.amount}>
                      <div>1 x {currencyFormatter.format(product.amount)}</div>
                      {product.commission > 0 && (
                        <>
                          <FormattedMessage
                            id="SUBSCRIPTION.ORDER.COMMISSION"
                            values={{
                              commission: currencyFormatter.format(product.commission),
                              commissionRate: `(${product.commissionRate}%)`,
                            }}
                            tagName="small"
                          />
                          <br />
                        </>
                      )}
                      {product.tax > 0 && (
                        <FormattedMessage
                          id="SUBSCRIPTION.ORDER.COMMISSION_TAX"
                          values={{ tax: currencyFormatter.format(product.tax), taxRate: `(${product.taxRate}%)` }}
                          tagName="small"
                        />
                      )}
                    </td>
                    <td className={styles.amounts}>{currencyFormatter.format(product.total)}</td>
                  </tr>,
                ])}
                <tr>
                  <td>
                    <FormattedMessage id="ORDERS.INVOICING_FEE" />
                  </td>
                  <td />
                  <td className={styles.amounts}>{currencyFormatter.format(this.props.order.managementFee)}</td>
                </tr>
                <tr>
                  <td>
                    <FormattedMessage
                      id="ORDERS.INVOICING_FEE_TAX"
                      values={{ taxRate: this.props.order.taxRate ? `(${this.props.order.taxRate}%)` : '' }}
                    />
                  </td>
                  <td />
                  <td className={styles.amounts}>{currencyFormatter.format(this.props.order.tax)}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <FormattedMessage id="ORDERS.ORDER_TOTAL" />
                  </td>
                  <td />
                  <td className={styles.amounts}>
                    {currencyFormatter.format(this.props.order.grossInvoiceProductsTotalAmount)}
                  </td>
                </tr>
              </tfoot>
            </TableComponent>
          )}

          {!!this.props.values.amount && (
            <SummaryFieldComponent>
              <FormattedMessage id="ORDERS.SERVICE_COUPON_EMONEY" tagName="strong" />
              {currencyFormatter.format(((amount: any): string))}
            </SummaryFieldComponent>
          )}
        </div>
        <div className="card-header card-header--between">
          <h4 className="card-title">
            <FormattedMessage id="ORDERS.INVOICE" />
          </h4>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-6">
              <SummaryFieldComponent>
                <FormattedMessage id="ORDERS.INVOICING_METHOD" tagName="strong" />
                <FormattedMessage id={`INVOICING.${this.props.values.invoicing.invoicingMethod}.NAME`} />
              </SummaryFieldComponent>
            </div>
            <div className="col-lg-6">
              <SummaryFieldComponent>
                <FormattedMessage id="ORDERS.ORDER_REFERENCE" tagName="strong" />
                {this.props.values.invoicing.invoiceReference}
              </SummaryFieldComponent>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <SummaryFieldComponent>
                <FormattedMessage id="ORDERS.OFFICIAL_NAME" tagName="strong" />
                {this.props.values.invoicing.invoiceName}
              </SummaryFieldComponent>
            </div>
            <div className="col-lg-6">
              <SummaryFieldComponent>
                <FormattedMessage id="ORDERS.EMAIL" tagName="strong" />
                {this.props.values.invoicing.invoiceEmail}
              </SummaryFieldComponent>
            </div>
          </div>
          {this.props.values.invoicing.invoicingMethod === 'EINVOICE' && (
            <div className="row">
              <div className="col-lg-6">
                <SummaryFieldComponent>
                  <FormattedMessage id="ORDERS.DELIVERY_IDENTIFIER" tagName="strong" />
                  {this.props.values.invoicing.eInvoiceIdentifier}
                </SummaryFieldComponent>
              </div>
              <div className="col-lg-6">
                <SummaryFieldComponent>
                  <FormattedMessage id="ORDERS.DELIVERY_ADDRESS" tagName="strong" />
                  {this.props.values.invoicing.eInvoiceAddress}
                </SummaryFieldComponent>
              </div>
            </div>
          )}
        </div>
        <div className="card-footer">
          {!!this.props.terms && <AgreementsAcceptanceContainer input={this.state.terms} name={this.props.terms} />}

          <ButtonsWrapperComponent>
            <ButtonComponent theme="outline-secondary" size="large" onClick={this.props.onBack}>
              <i className="fi fi-go-back-left-arrow" /> <FormattedMessage id="CORE.BACK" />
            </ButtonComponent>

            <ButtonComponent
              theme="link"
              size="large"
              onClick={this.props.onCancel}
              loading={this.props.canceling}
              className="ml-3"
            >
              <FormattedMessage id="ORDERS.CANCEL_ORDER" />
            </ButtonComponent>

            <ButtonComponent
              size="large"
              theme="primary"
              disabled={!!this.props.terms ? !this.state.terms.value : false}
              loading={this.props.submitting}
              className="ml-auto"
            >
              <FormattedMessage id="CORE.CONFIRM" />
            </ButtonComponent>
          </ButtonsWrapperComponent>
        </div>
      </CardComponent>
    );
  }
}
