import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { MySubscriptionsListComponent } from './my-subscriptions-list.component';

const mapStateToProps = ({
  users: {
    current: { subscriptions },
  },
}) => ({
  subscriptions,
});

export const MySubscriptionsListContainer = compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps),
)(MySubscriptionsListComponent);
