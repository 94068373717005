import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { withPermissions } from 'app/common';

import { UsersListTableComponent } from './users-list-table.component';

export const UsersListTableContainer = compose(
  injectIntl,
  withRouter,
  withPermissions,
)(UsersListTableComponent);
