import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';

import { withAuthorization, withPagination } from 'app/common';

import { fetchBeneficiaries } from 'app/redux/beneficiaries/beneficiaries.actions';
import { download } from 'app/redux/downloads/downloads.actions';

import { BeneficiariesListComponent } from './beneficiaries-list.component';

const mapStateToProps = ({ beneficiaries, users: { business } }) => ({
  business,
  beneficiaries: get(beneficiaries, [business, 'list']),
  loading: !beneficiaries[business],
  meta: get(beneficiaries, [business, 'meta']),
});

const mapDispatchToProps = (dispatch, { intl: { locale } }) => ({
  fetch: params => dispatch(fetchBeneficiaries(params)),
  onDownload: () => dispatch(download('beneficiaries', locale, 'beneficiaries-filters')),
});

export const BeneficiariesListContainer = compose(
  withAuthorization(['companyBeneficiaries', 'read']),
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
  withPagination,
)(BeneficiariesListComponent);
