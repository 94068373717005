import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Fields } from 'redux-form';

import { ButtonComponent, ConnectedDateRangeComponent, ModalComponent, AlertComponent } from 'app/shared';

import type { Props } from './create-new-contract.component.types';
import { getFormValues } from 'redux-form';
import styles from './create-new-contract.module.scss';

export class CreateNewContractComponent extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isMakeNewContractOpen: false,
    };

    this.filters = getFormValues('new-contract-filters')({ form: this.props.availableForms }) || {};
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.isBatch) {
      this.props.fetchAllVenues({ size: 1500, benefitType: this.filters.benefitType, keyword: this.filters.keyword });
    }
  }

  onCalendarToggle = (state: any) => this.setState({ isMakeNewContractOpen: !!state });

  render() {
    const {
      location: { state },
    } = this.props;

    const dates = getFormValues('new-contract')({ form: this.props.availableForms }) || {};
    const success = !!this.props.location.state && !!this.props.location.state.success;
    const fail = !!this.props.location.state && !!this.props.location.state.fail;

    const contracts =
      this.props.allVenues &&
      this.props.allVenues.map(venue => ({
        ...dates,
        vendorId: venue.id,
      }));

    const submitMethod =
      state && state.isBatch
        ? this.props.handleSubmit(() => this.props.onBatchSubmit({ contracts }))
        : this.props.handleSubmit;

    const submitButton = (
      <ButtonComponent theme="primary" size="medium" loading={this.props.submitting} disabled={!dates.startDate}>
        {this.props.intl.formatMessage({ id: 'CORE.CONFIRM' })}
      </ButtonComponent>
    );

    const showSingleContractContent = state && !success && !fail && !state.isBatch;
    const showBatchContractContent = state && !success && !fail && state.isBatch;

    return (
      <ModalComponent
        title={<FormattedMessage id="CONTRACTS.NEW_CONTRACT" />}
        onClose={this.props.onClose}
        form={{ onSubmit: submitMethod }}
        submitButton={!success && !fail && submitButton}
        titleCenter
      >
        {success && (
          <AlertComponent type="success">
            <FormattedMessage id="CONTRACTS.SUCCESS_TITLE" tagName="h5" />
          </AlertComponent>
        )}
        {fail && (
          <AlertComponent type="danger">
            <FormattedMessage id="CONTRACTS.FAIL_TITLE" tagName="h5" />
            <FormattedMessage id="CONTRACTS.FAIL_MESSAGE" />
          </AlertComponent>
        )}
        {showSingleContractContent && (
          <div className={styles.subtitle}>
            {state.venue}
            <br />
            {state.address} | {state.phoneNumber}
            <br />
            <FormattedMessage
              id="CONTRACTS.BENEFIT_TYPE"
              values={{ type: this.props.intl.formatMessage({ id: `BENEFITS.${state.benefitType}.TITLE` }) }}
            />
          </div>
        )}
        {showBatchContractContent && (
          <div className={styles.subtitle}>
            <div className={styles.subtitle}>
              <FormattedMessage id="CONTRACTS.ALL_VENUES" values={{ venue: this.filters.keyword.toUpperCase() }} />
              <br />
              <FormattedMessage
                id="CONTRACTS.BENEFIT_TYPE"
                values={{ type: this.props.intl.formatMessage({ id: `BENEFITS.${this.filters.benefitType}.TITLE` }) }}
              />
            </div>
          </div>
        )}
        {!success &&
          !fail && (
            <Fields
              names={['startDate', 'endDate']}
              component={ConnectedDateRangeComponent}
              relativeNames={['startDate', 'endDate']}
              onFocusChange={this.onCalendarToggle}
              label={this.props.intl.formatMessage({ id: 'CONTRACTS.PERIOD' })}
              parse={value => (value ? value.format('YYYY-MM-DD') : null)}
              intl={this.props.intl}
              required
            />
          )}
      </ModalComponent>
    );
  }
}
