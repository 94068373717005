import { RSAA } from 'redux-api-middleware';

import * as types from './metabase.action-types';

export const fetchDashboard = body => ({
  [RSAA]: {
    types: [types.FETCH_DASHBOARD_REQUEST, types.FETCH_DASHBOARD_SUCCESS, types.FETCH_DASHBOARD_FAILURE],
    endpoint: '/metabase/billing-dashboard-url',
    method: 'POST',
    body,
  },
});
