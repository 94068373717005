import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { changeBusiness, changeRole } from 'app/redux/users/users.actions';
import { unlockBusinessChange, updateConfirmation } from 'app/redux/view/view.actions';
import { setError } from 'app/redux/error/error.actions';
import { SidebarUserComponent } from './sidebar-user.component';

const mapStateToProps = ({
  users: {
    business,
    current: {
      businesses,
      userId,
      personal: { firstName, lastName },
    },
    isApproved,
    subBusiness,
    role,
  },
  view: { businessChangeLocked },
  permissions: { [business]: roles },
}) => ({
  businessChangeLocked,
  businesses,
  firstName,
  isApproved,
  lastName,
  subBusiness,
  business: businesses.find(e => e.id === business),
  role,
  roles,
  userId,
});

const mapDispatchToProps = dispatch => ({
  onBusinessChange: (business, isLocked) =>
    isLocked
      ? dispatch(
          updateConfirmation({
            onConfirm: () => Promise.all([dispatch(changeBusiness(business)), dispatch(unlockBusinessChange())]),
            title: 'CORE.PROMPT',
          }),
        )
      : Promise.all([dispatch(changeBusiness(business)), dispatch(unlockBusinessChange())]),
  onRoleChange: role => dispatch(changeRole(role)),
  setError: () => dispatch(setError()),
});

export const SidebarUserContainer = compose(
  withRouter,
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SidebarUserComponent);
