import { handleActions } from 'redux-actions';
import { FETCH_TRANSLATIONS_SUCCESS } from './translations.action-types';

const defaultState = {};

export const translationsReducer = handleActions(
  {
    [FETCH_TRANSLATIONS_SUCCESS]: (state, { payload, meta: { lang } }) => ({ ...state, [lang]: payload }),
  },
  defaultState,
);
